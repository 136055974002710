import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomizedCakeActions from '../../../redux/Customize-Cake/actions';
import ReactPagination from '../../common/React-Pagination';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../common/SortingOrders';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
    ]); 

    const { CakeyDirectCustomizedCakeOrders } = useSelector(state => state.CustomizeCakeReducer);

    useEffect(() => {
        dispatch({ type: CustomizedCakeActions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (CakeyDirectCustomizedCakeOrders?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(CakeyDirectCustomizedCakeOrders.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search)) ||
                    (val.DeliverySession && val.DeliverySession.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [CakeyDirectCustomizedCakeOrders, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: CustomizedCakeActions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST });
        setsearch('');
        setSorting([
            { table: 0, field: "OD", type: "D" }
        ]);
    };

    const ViewDetails = (e, cake) => {
        e.preventDefault();
        history.push('/Helpdesk-C-Direct-Customized-Cake-Order-Details', { Id: cake._id });
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = filteredData;
        let result = SortingOrders({ type, field, filterData });
        setFilteredData(result);
    };

    useEffect(() => {
        (CakeyDirectCustomizedCakeOrders.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CakeyDirectCustomizedCakeOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Cakey Direct Customised Cake Orders List</h6>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="search Id/Status..."
                                                    onChange={handleSearchbar}
                                                    value={search || ''}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Session</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake, i) =>
                                                                    <tr className={(cake.Ticket_Raised === 'n' && cake.Status !== 'Cancelled') ? "odd cursor font-weight-bold" : "odd cursor"} key={i} onClick={(e) => ViewDetails(e, cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td>{cake.DeliveryDate}</td>
                                                                        <td>{cake.DeliverySession}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};