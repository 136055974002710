import React from 'react';
import Sidenavigation from '../../HelpdeskC/Layouts/Sidenavigation';
import Topnavigation from '../../HelpdeskC/Layouts/Topnavigation';
import Content from '../../CommonFolders/Tickets-List/Content';
import { Helmet } from "react-helmet";

export default function HelpdeskCTicketsList() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Tickets</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content Page='HelpdeskC' />
                {/* page content end  */}

            </main>

        </div>
    );
};