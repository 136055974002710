import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Topper/actions';
import CakeArrayActions from '../../../../redux/CakeArray/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NumberValidation } from '../../../../utils/Validation';
import { BiRupee } from 'react-icons/bi';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    HampersName: yup.string().required('required'),
    Price: yup.string().required('required'),
    Title: yup.string().required('required'),
    StartDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    EndDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    DeliveryStartDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    DeliveryEndDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    Occasion: yup.string().required('required'),
    EggOrEggless: yup.string().required('required'),
    Weight: yup.string().required('required'),
    WeightUnit: yup.string().required('required'),
    // Product_Contains: yup.string().required("required"),
    Description: yup.string().required("required"),
    // HamperImage: yup.mixed().test('required', 'Please select a file', value => {
    //     return value && value.length;
    // })
});

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    let [productContains, setProductContains] = useState([]);
    let [productContainsList, setProductContainsList] = useState([]);
    let [HamperImage, setHamperImage] = useState();
    let [HamperImageUrl, setHamperImageUrl] = useState();
    let [Message, setMessage] = useState('');
    let [BookingStart, setBookingStart] = useState();
    let [DeliveryStart, setDeliveryStart] = useState();
    let [additionalHamperImages, setAdditionalHamperImages] = useState([]);
    let [additionalHamperImagesUrl, setAdditionalHamperImagesUrl] = useState([]);

    //get logged in vendor details
    const { token } = useSelector((state) => state.authReducer);

    const { CreateHamperStatus } = useSelector(state => state.TopperReducer);

    const { CakeType } = useSelector(state => state.CakeArrayReducer);

    const { register, handleSubmit, reset, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_CAKE_TYPE });
        // eslint-disable-next-line
    }, []);

    const handleSelectImage = (e) => {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setHamperImage(HamperImage = result?.FinalImages[0]);
                if (HamperImage) {
                    setHamperImageUrl(URL.createObjectURL(HamperImage));
                };
            }
        }
    };

    const handleAddSpecialityCake = (e) => {
        e.preventDefault();
        setProductContains('');
        setProductContainsList([...productContainsList, productContains]);
    };

    const RemoveAddedSpecialityCakeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...productContainsList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setProductContainsList(splicedList);
    };

    // const handleAddCakeType = (e) => {
    //     e.preventDefault();
    //     if (cakeTypeNameList.length === 0) {
    //         setCakeTypeNameList([e.target.value]);
    //     } else {
    //         if (!cakeTypeNameList.includes(e.target.value)) {
    //             setCakeTypeNameList([...cakeTypeNameList, e.target.value]);
    //         };
    //     };
    // };

    // const RemoveAddedCakeTypeName = (e, index) => {
    //     e.preventDefault();
    //     var splicedList = [...cakeTypeNameList];
    //     if (index !== -1) {
    //         splicedList.splice(index, 1);
    //     };
    //     setCakeTypeNameList(splicedList);
    // };

    const onSubmit = (data) => {
        setLoader(true);
        if (token !== undefined) {
            if ((!HamperImage || productContainsList.length === 0)) {
                if (productContainsList.length === 0) {
                    setMessage("Contents Field is required");
                    setLoader(false);
                }
                else {
                    setMessage('Hamper Image is required')
                    setLoader(false);
                }

            } else if (new Date(data.StartDate) > new Date(data.EndDate)) {
                setMessage("End Date should be greater than Start Date")
                setLoader(false)
            }
            else {
                setMessage('')
                const formData = new FormData();
                formData.append('VendorID', token?.result?._id);
                formData.append('Vendor_ID', token?.result?.Id);
                formData.append('VendorName', token?.result?.VendorName);
                formData.append('VendorPhoneNumber1', token?.result?.PhoneNumber1);
                token?.result?.PhoneNumber2 && formData.append('VendorPhoneNumber2', token?.result?.PhoneNumber2);
                formData.append('VendorAddress', token?.result?.Address);
                formData.append('GoogleLocation', JSON.stringify(token?.result?.GoogleLocation));
                formData.append('HampersName', data.HampersName);
                formData.append('Title', data.Title);
                formData.append('Occasion', data.Occasion);
                formData.append('EggOrEggless', data.EggOrEggless);
                formData.append('Weight', data.Weight + data.WeightUnit);
                formData.append('StartDate', moment(moment(data.StartDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('EndDate', moment(moment(data.EndDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('DeliveryStartDate', moment(moment(data.DeliveryStartDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('DeliveryEndDate', moment(moment(data.DeliveryEndDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('Price', data.Price);
                formData.append('Product_Contains', JSON.stringify(productContainsList));
                formData.append('Description', data.Description);
                formData.append('HamperImage', HamperImage);
                if (additionalHamperImages.length > 0) {
                    for (let i = 0; i < additionalHamperImages.length; i++) {
                        formData.append("AdditionalHamperImage", additionalHamperImages[i]);
                    }
                };
                dispatch({ type: actions.CREATE_HAMBPERS, payload: formData });
            }
        }
    };

    //validate add cake status
    useEffect(() => {
        if (CreateHamperStatus?.statusCode === 400) {
            setLoader(false);
        } else if (CreateHamperStatus?.statusCode === 200) {
            setLoader(false);
            reset();
            setHamperImage();
            setHamperImageUrl();
            setAdditionalHamperImages();
            setAdditionalHamperImagesUrl();
            setProductContainsList([]);
            // setCakeTypeNameList([]);
        };
        // eslint-disable-next-line 
    }, [CreateHamperStatus]);

    const handleAdditionalHamperImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = additionalHamperImages.find(val => val.name === files[i].name &&
                            val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        }
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = additionalHamperImages.find(val => val.name === files[i].name &&
                        val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };
            }
            // var selected = e.target.files;
            if (selectedImages.length === 1) {
                setAdditionalHamperImages(additionalHamperImages = additionalHamperImages.concat(selectedImages[0]));
            } else {
                for (let i = 0; i < selectedImages.length; i++) {
                    setAdditionalHamperImages(additionalHamperImages = additionalHamperImages.concat(selectedImages[i]));
                };
            }
            //Display the images
            var imageArray = additionalHamperImages.map(file => {
                return URL.createObjectURL(file);
            });
            setAdditionalHamperImagesUrl(imageArray);
        }
    };

    //remove selected image
    function handleRemoveAdditionalHamperImages(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...additionalHamperImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setAdditionalHamperImagesUrl(additionalHamperImagesUrl = spliceImage);
        //for store in database
        var SelectedFile = [...additionalHamperImages];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setAdditionalHamperImages(additionalHamperImages = SelectedFile);
    };

    useEffect(() => {
        (CakeType.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CakeType]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-header"><h6>Add Hampers</h6></div>
                                <div className="ms-panel-body">
                                    <form className="needs-validation clearfix" noValidate="">
                                        <div className="form-row">
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Hamper Name</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" className={errors?.HampersName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='HampersName' {...register('HampersName')} placeholder="Hamper Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Title</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" className={errors?.Title ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='Title' {...register('Title')} placeholder="Title" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Occasion</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" className={errors?.Occasion ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='Occasion' {...register('Occasion')} placeholder="Occasion" />

                                                    {/* <div className='display-flex w-100'> */}
                                                    {/* {CakeArray.CakeType.message === 'No Records Found' ?
                                                            <select className='form-control'>
                                                                <option className='text-center'>No Records Found</option>
                                                            </select> :
                                                            <select defaultValue='' className="form-control" placeholder="types of cakes" name='Occasion' onChange={handleAddCakeType}>
                                                                <option value='' disabled='disabled'>-- Select --</option>
                                                                {CakeArray.CakeType.map((val, i) =>
                                                                    <>{val.Type.includes('Occasion') ?
                                                                        <> {val.SubType.map((s) =>
                                                                            <option value={s}>{s}</option>
                                                                        )} </> : <></>
                                                                    } </>
                                                                )}
                                                            </select>
                                                        } */}
                                                    {/* </div>
                                                    {cakeTypeNameList.length > 0 &&
                                                        <>
                                                            {cakeTypeNameList.map((value, index) =>
                                                                <div key={index}>
                                                                    <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeTypeName(e, index)}>X</button></div>
                                                                </div>
                                                            )}
                                                        </>
                                                    } */}


                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Egg Or Eggless</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.EggOrEggless ? "form-control required-field" : "form-control"} defaultValue='' name='EggOrEggless' {...register('EggOrEggless')} >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value="Egg">Egg</option>
                                                        <option value="Eggless">Eggless</option>
                                                        {/* <option value="Egg and Eggless">Egg and Eggless</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Booking Start Date</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="date" maxLength={4} max={"9999-12-31"} min={moment().add(1, 'days').format('YYYY-MM-DD').toString()} className={errors?.StartDate ? "form-control required-field" : "form-control"}
                                                        name='StartDate' {...register('StartDate')} onChange={(e) => setBookingStart(e.target.value)} />
                                                </div>
                                                <p className='text-danger'>{errors?.StartDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Booking End Date</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="date" maxLength={4} max={"9999-12-31"} min={BookingStart} disabled={BookingStart ? false : true} className={errors?.EndDate ? "form-control required-field" : "form-control"}
                                                        name='EndDate' {...register('EndDate')} />
                                                </div>
                                                <p className='text-danger'>{errors?.EndDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b> Delivery Start Date</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="date" maxLength={4} max={"9999-12-31"} min={BookingStart} disabled={BookingStart ? false : true} className={errors?.DeliveryStartDate ? "form-control required-field" : "form-control"}
                                                        name='DeliveryStartDate' {...register('DeliveryStartDate')} onChange={(e) => setDeliveryStart(e.target.value)} />
                                                </div>
                                                <p className='text-danger'>{errors?.DeliveryStartDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Delivery End Date</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="date" maxLength={4} max={"9999-12-31"} min={DeliveryStart} disabled={DeliveryStart ? false : true} className={errors?.DeliveryEndDate ? "form-control required-field" : "form-control"}
                                                        name='DeliveryEndDate' {...register('DeliveryEndDate')} />
                                                </div>
                                                <p className='text-danger'>{errors?.DeliveryEndDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Price</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.Price ? "form-control required-field" : "form-control"} name='Price' {...register('Price')} placeholder="Price" />
                                                    <button className={errors?.Price ? "RupeeSign required-field" : "RupeeSign"} disabled><BiRupee className="sidenavigationIcon" /></button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Weight(All Product Weight Included)</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.Weight ? "form-control required-field" : "form-control"} name='Weight' {...register('Weight')} placeholder="Weight" />
                                                        <select className={errors?.Weight ? "form-control w-50 required-field" : "form-control w-50"} defaultValue='g' id="validationCustom22" name='WeightUnit' {...register('WeightUnit')}>
                                                            <option value="g">grams</option>
                                                            <option value="kg">kg</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom18"><b>Contains</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input type="text" className="form-control text-capitalize" placeholder="e.g. One Fruit And Nut Dry Cake (300 gm)" name='Product_Contains' {...register('Product_Contains')} value={productContains} onChange={(e) => setProductContains(e.target.value)} />
                                                        <button className='add-button rounded disabled-add' disabled={productContains.length === 0 ? true : false} onClick={handleAddSpecialityCake}>Add</button>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        {productContainsList?.map((value, index) =>
                                                            <div key={index}>
                                                                <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedSpecialityCakeName(e, index)}>X</button></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom18"><b>Description (400 Characters Only)</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <textarea
                                                        maxLength={400}
                                                        type="text"
                                                        className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"}
                                                        placeholder="Description"
                                                        name='Description'
                                                        {...register('Description')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Hamper Image <span className='text-danger'>*</span></label>
                                                <div className="col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='HamperImage' {...register('HamperImage')} onChange={handleSelectImage} />
                                                        <label className={errors?.HamperImage && !HamperImage ? "custom-file-label required-field" : "custom-file-label"}>Upload Image...</label>
                                                    </div>
                                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                </div>
                                            </div>
                                            {HamperImageUrl &&
                                                <div className='display-flex'>
                                                    <div className='display-flex'>
                                                        <div className="imgPre">
                                                            <img width='90px' className='update-select-cake' src={HamperImageUrl} alt="Cake" />
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {/* {HamperImageUrl &&
                                                <div className="col-md-12 mb-3">
                                                    <div className="ms-panel-body tabledata-background">
                                                        <img className="d-block align-center" src={HamperImageUrl} alt="First slide" />
                                                    </div>
                                                </div>
                                            } */}
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Additional Hamper Images </label>
                                                <div className="col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='files' multiple onChange={handleAdditionalHamperImages} />
                                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label>
                                                    </div>
                                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                    <div className='display-flex'>
                                                        <div className='display-flex'>
                                                            {additionalHamperImagesUrl?.map((value, index) =>
                                                                <div className="imgPre" key={value}>
                                                                    <img width='90px' className='update-select-cake' src={value} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveAdditionalHamperImages(e, index)}>X</button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {loader ? <CircleDotSpinner /> :
                                            <>
                                                <p className='text-danger bottom-error-message'>{Message}</p>
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit" onClick={handleSubmit(onSubmit)}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="btn btn-secondary ml-4"
                                                    type="submit"
                                                    onClick={() => history.push('/vendor-hampers-list')}
                                                >
                                                    Cancel
                                                </button>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    );
}

export default Content;