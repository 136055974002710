import React, { useState, useEffect } from 'react';
import Breadcrumb from './Breadcrumb';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OtherActions from '../../../../redux/OtherProducts/actions';
import CakeDessertWeight from './CakeArray/CakeDessertWeight';
import { Modal } from "react-bootstrap";
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import MacaroonsBox from './CakeArray/MacaroonsBox';
// import Alert from '../../../common/Alert';
import CommonActions from '../../../../redux/Common/actions';
import { DiscountValidation, NumberValidation } from '../../../../utils/Validation';
import { BiRupee } from 'react-icons/bi';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    ProductName: yup.string().required('required'),
    ProductCommonName: yup.string().required('required'),
    // Flavour: yup.string().required("required"),
    MinTimeForDelivery: yup.string().required("required"),
    BestUsedBefore: yup.string().required('required'),
    ToBeStoredIn: yup.string().required('required'),
    Description: yup.string().required('required'),
    HowGoodAreYouWithTheCake: yup.string().required('required'),
    HowManyTimesHaveYouBakedThisParticularCake: yup.string().required('required'),
    Discount: yup.string().required('required'),
    EggOrEggless: yup.string().optional()
});

export default function Content() {

    const state = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    var [CakeDessertsWeight, setCakeDessertsWeight] = useState(["200g", "250g", "300g", "350g", "400g", "450g", "500g"]);
    var [macaroonsPiece, setMacaroonsPiece] = useState(['4', '6', '10']);
    var [disabledButton, setDisabledButton] = useState([]);
    var [disabledButton2, setDisabledButton2] = useState([]);
    var [cakeDessertWeightPrice, setCakeDessertWeightPrice] = useState([]);
    var [cakeDessertWeightAndPriceList, setCakeDessertWeightAndPriceList] = useState([]);
    var [macaroonsBoxPrice, setMacaroonsBoxPrice] = useState([]);
    var [macaroonsBoxPriceList, setMacaroonsBoxPriceList] = useState([]);
    var [flavours, setFlavours] = useState('');
    var [flavourList, setFlavourList] = useState([]);
    var [productImage, setProductImage] = useState([]);
    var [productImageUrl, setProductImageUrl] = useState([]);
    var [additionalProductImage, setAdditionalProductImage] = useState([]);
    var [additionalProductImageUrl, setAdditionalProductImageUrl] = useState([]);

    const [show, setShow] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [newWeight, setNewWeight] = useState('');
    const [newWeightUnit, setNewWeightUnit] = useState('g');
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    // eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [timer, setTimer] = useState("");
    var [FieldErr, setFieldErr] = useState("");

    //get logged in vendor details
    const VendorDetails = useSelector((state) => state.authReducer);

    const OtherProduct = useSelector((state) => state.OtherProductReducer);

    const { response } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, reset, formState, setError } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: { alert: false, message: null, status: null }
        });
    };

    const handleCDWSelectButton = (e, i) => {
        e.preventDefault();
        if (disabledButton.length === 0) {
            setDisabledButton(disabledButton = [i]);
        } else {
            setDisabledButton(disabledButton = [...disabledButton, i]);
        }
    };

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        setShowMessage('');
    };

    const handleCancel = (e) => {
        history.push('/vendor-cakes-list');
    };

    const handleAddFlavours = (e) => {
        e.preventDefault();
        setFlavours('');
        setFlavourList([...flavourList, flavours?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())]);
    };

    const RemoveAddedFlavoursName = (e, index) => {
        e.preventDefault();
        var splicedList = [...flavourList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setFlavourList(splicedList);
    };

    const handleCDWRemoveButton = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);
        //for remove the name and price object in array
        const array2 = [...cakeDessertWeightAndPriceList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setCakeDessertWeightAndPriceList(array2);
    };

    const handleCDWInputValue = (e, value) => {
        e.preventDefault();
        let WeightValidate = [], ChangedObject;
        // eslint-disable-next-line
        cakeDessertWeightAndPriceList.filter(val => {
            if (val.Weight === value) {
                WeightValidate.push('y');
                if (e.target.name === 'MinCount') {
                    ChangedObject = { Weight: val.Weight, MinCount: e.target.value, PricePerUnit: val.PricePerUnit }
                } else {
                    ChangedObject = { Weight: val.Weight, MinCount: val.MinCount, PricePerUnit: e.target.value }
                }
            } else {
                WeightValidate.push('n');
            }
        });
        if (WeightValidate.includes('y')) {
            setCakeDessertWeightPrice(cakeDessertWeightPrice = {
                ...cakeDessertWeightPrice,
                Weight: ChangedObject.Weight,
                MinCount: ChangedObject.MinCount,
                PricePerUnit: ChangedObject.PricePerUnit
            });
        } else {
            if (e.target.name === 'MinCount') {
                setCakeDessertWeightPrice(cakeDessertWeightPrice = {
                    ...cakeDessertWeightPrice,
                    Weight: value,
                    MinCount: e.target.value,
                    PricePerUnit: "0"
                });
            } else {
                setCakeDessertWeightPrice(cakeDessertWeightPrice = {
                    ...cakeDessertWeightPrice,
                    Weight: value,
                    MinCount: "0",
                    PricePerUnit: e.target.value,
                });
            }
        }
        var WeightandPrice = {
            Weight: cakeDessertWeightPrice.Weight,
            MinCount: cakeDessertWeightPrice.MinCount,
            PricePerUnit: cakeDessertWeightPrice.PricePerUnit,
        }
        //for add name and price in single array
        var temp = [];
        temp[disabledButton.length - 1] = WeightandPrice;
        if (disabledButton.length === 1) {
            setCakeDessertWeightAndPriceList(cakeDessertWeightAndPriceList = temp);
        } else {
            const tempflavour = temp;
            setCakeDessertWeightAndPriceList(cakeDessertWeightAndPriceList = [...cakeDessertWeightAndPriceList, Object.assign({}, tempflavour[disabledButton.length - 1])]);
            var index = [];
            cakeDessertWeightAndPriceList.filter((val, i) => {
                if (val.Weight === value) {
                    return index = [...index, i];
                } else {
                    return null;
                }
            });
            var array = [...cakeDessertWeightAndPriceList];
            // eslint-disable-next-line
            var ind = array.findIndex((r, i) => {
                for (let j = 0; j < index.length - 1; j++) {
                    return i === index[j]
                }
            });
            if (ind !== -1) {
                array.splice(ind, 1);
            };
            setCakeDessertWeightAndPriceList(array);
        }
    };

    const handleAddOtherWeight = (e, field) => {
        e.preventDefault();
        setShow(true);
        setFieldName(field);
        setNewWeight('');
    };

    const handleAddNew = (e) => {
        e.preventDefault();
        var ValidateWeight = [], ValidatePiece = [];
        if (fieldName === 'Cake Dessert Weight Per Unit') {
            if (newWeight && newWeightUnit) {
                // eslint-disable-next-line
                cakeDessertWeightAndPriceList.filter(val => {
                    (val.Weight === (newWeight + newWeightUnit).toString()) ?
                        ValidateWeight.push('y') : ValidateWeight.push('n')
                });
                if (ValidateWeight.includes('y')) {
                    setShowMessage("Weight Already Exist");
                } else {
                    if (!CakeDessertsWeight.includes((newWeight + newWeightUnit).toString())) {
                        setCakeDessertsWeight(CakeDessertsWeight = [...CakeDessertsWeight, newWeight + newWeightUnit]);
                    }
                    const NewW = (newWeight + newWeightUnit).toString();
                    var index = CakeDessertsWeight.findIndex(val => val === NewW);
                    (disabledButton.length === 0) ? setDisabledButton([index]) : setDisabledButton([...disabledButton, index])
                    setShowMessage('');
                    setShow(false);
                }
            } else {
                setShowMessage("Enter new weight");
            }
        } else if (fieldName === `${state.state} Pieces Per Box`) {
            if (newWeight) {
                // eslint-disable-next-line
                macaroonsBoxPriceList.filter(val => {
                    (val.Piece === newWeight) ? ValidatePiece.push('y') : ValidatePiece.push('n')
                });
                if (ValidatePiece.includes('y')) {
                    setShowMessage('Macaroons Piece Already Exist');
                } else {
                    (!macaroonsPiece.includes(newWeight)) &&
                        setMacaroonsPiece(macaroonsPiece = [...macaroonsPiece, newWeight]);
                    var index2 = macaroonsPiece.findIndex(val => val === newWeight);
                    (disabledButton2.length === 0) ?
                        setDisabledButton2([index2]) : setDisabledButton2([...disabledButton2, index2]);
                    setShowMessage('');
                    setShow(false);
                }
            } else {
                setShowMessage("Enter new piece")
            }
        }
    };

    const handleMPSelectButton = (e, i) => {
        e.preventDefault();
        if (disabledButton2.length === 0) {
            setDisabledButton2(disabledButton2 = [i]);
        } else {
            setDisabledButton2(disabledButton2 = [...disabledButton2, i]);
        };
    };

    const handleMPRemoveButton = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton2];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton2(disabledButton2 = array);
        //for remove the name and price object in array
        const array2 = [...macaroonsBoxPriceList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setMacaroonsBoxPriceList(array2);
    };

    const handleMPInputValue = (e, value) => {
        e.preventDefault();
        let PieceValidate = [], ChangedObject;
        // eslint-disable-next-line
        macaroonsBoxPriceList.filter(val => {
            if (val.Piece === value) {
                PieceValidate.push('y');
                if (e.target.name === 'MinCount') {
                    ChangedObject = { Piece: val.Piece, MinCount: e.target.value, PricePerBox: val.PricePerBox }
                } else {
                    ChangedObject = { Piece: val.Piece, MinCount: val.MinCount, PricePerBox: e.target.value }
                }
            } else {
                PieceValidate.push('n');
            }
        });
        if (PieceValidate.includes('y')) {
            setMacaroonsBoxPrice(macaroonsBoxPrice = {
                ...macaroonsBoxPrice,
                Piece: ChangedObject.Piece,
                MinCount: ChangedObject.MinCount,
                PricePerBox: ChangedObject.PricePerBox
            });
        } else {
            if (e.target.name === 'MinCount') {
                setMacaroonsBoxPrice(macaroonsBoxPrice = {
                    ...macaroonsBoxPrice,
                    Piece: value,
                    MinCount: e.target.value,
                    PricePerBox: "0"
                });
            } else {
                setMacaroonsBoxPrice(macaroonsBoxPrice = {
                    ...macaroonsBoxPrice,
                    Piece: value,
                    MinCount: "0",
                    PricePerBox: e.target.value,
                });
            }
        }
        var PieceandPrice = {
            Piece: macaroonsBoxPrice.Piece,
            MinCount: macaroonsBoxPrice.MinCount,
            PricePerBox: macaroonsBoxPrice.PricePerBox,
        }
        //for add name and price in single array
        var temp = [];
        temp[disabledButton2.length - 1] = PieceandPrice;
        if (disabledButton2.length === 1) {
            setMacaroonsBoxPriceList(macaroonsBoxPriceList = temp);
        } else {
            const tempflavour = temp;
            setMacaroonsBoxPriceList(macaroonsBoxPriceList = [...macaroonsBoxPriceList, Object.assign({}, tempflavour[disabledButton2.length - 1])]);
            var index = [];
            macaroonsBoxPriceList.filter((val, i) => {
                if (val.Piece === value) {
                    return index = [...index, i];
                } else {
                    return null;
                }
            });
            var array = [...macaroonsBoxPriceList];
            // eslint-disable-next-line
            var ind = array.findIndex((r, i) => {
                for (let j = 0; j < index.length - 1; j++) {
                    return i === index[j]
                }
            });
            if (ind !== -1) {
                array.splice(ind, 1);
            };
            setMacaroonsBoxPriceList(array);
        }
    };

    const handleAddOtherMPBox = (e, field) => {
        e.preventDefault();
        setShow(true);
        setFieldName(field);
    };

    const handleAddProductImages = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setProductImage(productImage = [result?.FinalImages[0]]);
                //Display the images
                var imageArray = productImage.map(file => {
                    return URL.createObjectURL(file);
                });
                setProductImageUrl(imageArray);
            }
        }
    };
    const handleAddAdditionalProductImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = additionalProductImage.find(val => val.name === files[i].name &&
                            val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        }
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = additionalProductImage.find(val => val.name === files[i].name &&
                        val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };

            }
            // var selected = e.target.files;
            if (selectedImages.length === 1) {
                setAdditionalProductImage(additionalProductImage = additionalProductImage.concat(selectedImages[0]));
            } else {
                for (let i = 0; i < selectedImages.length; i++) {
                    setAdditionalProductImage(additionalProductImage = additionalProductImage.concat(selectedImages[i]));
                };
            }
            //Display the images
            var imageArray = additionalProductImage.map(file => {
                return URL.createObjectURL(file);
            });
            setAdditionalProductImageUrl(imageArray);
        }
    };

    //remove selected image
    function handleRemoveProductImage(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...productImageUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setProductImageUrl(productImageUrl = spliceImage);
        //for store in database
        var SelectedFile = [...productImage];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setProductImage(productImage = SelectedFile);
    };

    function handleRemoveAdditionalProductImage(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...additionalProductImageUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setAdditionalProductImageUrl(additionalProductImageUrl = spliceImage);
        //for store in database
        var SelectedFile = [...additionalProductImage];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setAdditionalProductImage(additionalProductImage = SelectedFile);
    };

    const onSubmit = (data) => {
        setFieldErr(FieldErr = "")

        if (VendorDetails.token !== undefined) {
            if ((flavourList.length === 0 || productImage.length === 0)) {
                if (flavourList.length === 0) {
                    setMessage('Flavour List are Mandatory');
                    setLoader(false)
                } else if (productImage.length === 0) {
                    setMessage('Product Image is Mandatory');
                    setLoader(false)
                }
            } else if ((state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Cake Dessert' || state.state === 'macaroons' || state.state === 'Tea Cake' ||
                state.state === 'Cup Cake' || state.state === 'Cheese Cake') && !data.EggOrEggless) {
                setError('EggOrEggless')
                setMessage('')
            }
            else {
                setMessage('');
                const formData = new FormData();
                formData.append("CakeSubType", state.state);
                formData.append("ProductName", data.ProductName);
                formData.append("ProductCommonName", data.ProductCommonName);
                formData.append("Flavour", JSON.stringify(flavourList));
                if (state.state === 'Brownie' || state.state === 'Cookies' ||
                    state.state === 'Tea Cake' || state.state === 'Cheese Cake') {
                    if (data.Shape) {
                        formData.append("Shape", data.Shape)
                    } else {
                        setFieldErr(FieldErr = "Shape Field required")
                    }


                }
                if (state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Chocolates' ||
                    state.state === 'Tea Cake' || state.state === 'Cheese Cake') {
                    if (data.MinWeightPerKg && data.PricePerKg) {

                        formData.append("Type", 'Kg');
                        formData.append("MinWeightPerKg", JSON.stringify({ Weight: data.MinWeightPerKg, PricePerKg: data.PricePerKg }));
                    } else {
                        if (!data.MinWeightPerKg) {
                            setFieldErr(FieldErr = "Minimum Weight Field is required")
                        } else {
                            setFieldErr(FieldErr = "Price per Kg Field is required")
                        }
                    }

                } else if (state.state === 'macaroons' || state.state === 'Cup Cake') {
                    if (macaroonsBoxPriceList.length === 0) {
                        setFieldErr(FieldErr = "Minimum Pieces and Price Per Box Field is Required")
                    } else {
                        formData.append("Type", 'Box');
                        formData.append("MinWeightPerBox", JSON.stringify(macaroonsBoxPriceList));
                    }

                } else if (state.state === 'Cake Dessert') {
                    if (cakeDessertWeightAndPriceList.length === 0) {
                        setFieldErr(FieldErr = "Minimum Weight and Price Per Unit Field is Required")
                    } else {
                        formData.append("Type", 'Unit');
                        formData.append("MinWeightPerUnit", JSON.stringify(cakeDessertWeightAndPriceList));
                    }

                } else {
                    return null;
                };
                for (let i = 0; i < productImage?.length; i++) {
                    formData.append("ProductImage", productImage[i]);
                };
                if (additionalProductImage.length > 0) {
                    for (let i = 0; i < additionalProductImage.length; i++) {
                        formData.append("AdditionalProductImages", additionalProductImage[i]);
                    }
                };
                // if (state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Cake Dessert' ||
                //     state.state === 'macaroons' || state.state === 'Tea Cake' || state.state === 'Cup Cake' || state.state === 'Cheese Cake') {

                if (data.EggOrEggless) {
                    formData.append("EggOrEggless", data.EggOrEggless)
                }
                //     } else {
                //         setFieldErr(FieldErr = "Egg Or Eggless Field required")
                //     }
                // }
                if (state.state === 'Brownie') {
                    if (data.ToppersPossible && data.CakeBase) {
                        formData.append("ToppersPossible", data.ToppersPossible);
                        formData.append("CakeBase", data.CakeBase);
                    } else {
                        if (!data.ToppersPossible) {
                            setFieldErr(FieldErr = "Toppers Possible Field is required")
                        } else {
                            setFieldErr(FieldErr = "Product Base Field is required")
                        }
                    }
                }
                formData.append("MinTimeForDelivery", data.MinTimeForDelivery + data.MinTimeForDeliveryUnit);
                formData.append("BestUsedBefore", data.BestUsedBefore + data.BestUsedBeforeUnit);
                formData.append("ToBeStoredIn", data.ToBeStoredIn);
                if (state.state === 'Cake Dessert' || state.state === 'Chocolates' || state.state === 'macaroons' || state.state === 'Cup Cake') {
                    data.KeepTheCakeInRoomTemperature ?
                        formData.append("KeepTheCakeInRoomTemperature", data.KeepTheCakeInRoomTemperature + data.KeepTheCakeInRoomTemperatureUnit)
                        : setFieldErr(FieldErr = "Minimum Time at Room Temperature before use Field is required")
                }
                formData.append("Description", data.Description);
                formData.append("HowGoodAreYouWithTheCake", data.HowGoodAreYouWithTheCake);
                formData.append("HowManyTimesHaveYouBakedThisParticularCake", data.HowManyTimesHaveYouBakedThisParticularCake);
                formData.append("Discount", data.Discount);
                formData.append("VendorID", VendorDetails.token.result._id);
                formData.append("Vendor_ID", VendorDetails.token.result.Id);
                formData.append("VendorName", VendorDetails.token.result.VendorName);
                formData.append("VendorPhoneNumber1", VendorDetails.token.result.PhoneNumber1);
                VendorDetails.token.result.PhoneNumber2 && formData.append("VendorPhoneNumber2", VendorDetails.token.result.PhoneNumber2);
                formData.append("VendorAddress", VendorDetails.token.result.Address);
                formData.append("GoogleLocation", JSON.stringify(VendorDetails.token.result.GoogleLocation));

                if (!FieldErr) {
                    setLoader(true);
                    dispatch({ type: OtherActions.ADD_OTHER_PRODUCT, payload: formData });
                    setTimeout(() => { dispatch({ type: OtherActions.ADD_OTHER_PRODUCT_STATUS, payload: { AddOtherProductStatus: [] } }); }, 5000);
                }
            }
        };
    };

    //validate add cake status
    useEffect(() => {
        if (OtherProduct.AddOtherProductStatus.statusCode === 400) {
            setMessage(OtherProduct.AddOtherProductStatus.message);
            setLoader(false);
            setFieldErr('');

        } else if (OtherProduct.AddOtherProductStatus.statusCode === 200) {
            setLoader(false);
            reset();
            setDisabledButton([]);
            setDisabledButton2([]);
            setFlavourList([]);
            setProductImage([]);
            setProductImageUrl([]);
            setCakeDessertWeightAndPriceList([]);
            setMacaroonsBoxPriceList([]);
            setAdditionalProductImage([]);
            setAdditionalProductImageUrl([]);
            setFieldErr('');
        };
        // eslint-disable-next-line
    }, [OtherProduct]);

    useEffect(() => {
        if (VendorDetails.token?.result.EggOrEggless === 'Egg' || VendorDetails.token?.result.EggOrEggless === 'Eggless') {
            reset({ 'EggOrEggless': VendorDetails.token.result.EggOrEggless });
        };
        if (response.alert) {
            setShowAlert(true);
            setTimer(setInterval(() => {
                clearNotification()
            }, 4000))
        };
        // eslint-disable-next-line
    }, [VendorDetails, response]);

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb />
            </div>
            <form>
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header"><h6>Product Info</h6></div>
                            <div className="ms-panel-body tabledata-background">
                                <div className="form-row">
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Product Type <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className="form-control text-capitalize" defaultValue={state.state === 'macaroons' ? 'Macarons' : state.state} disabled />
                                        </div>
                                    </div>
                                    <div className="col-md-8 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Product Name <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className={errors?.ProductName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='ProductName' {...register('ProductName')} placeholder="Product Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom24" className='font-weight-bold'>Product Common Name <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className={errors?.ProductCommonName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='ProductCommonName' {...register('ProductCommonName')} id="validationCustom24" placeholder="Product Common Name" />
                                        </div>
                                    </div>
                                    {state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Cake Dessert' || state.state === 'macaroons' || state.state === 'Tea Cake' ||
                                        state.state === 'Cup Cake' || state.state === 'Cheese Cake' ?
                                        <>{VendorDetails.token?.result.EggOrEggless === 'Egg' || VendorDetails.token?.result.EggOrEggless === 'Eggless' ?
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom24" className='font-weight-bold'>Egg Or Eggless <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" id="validationCustom24" defaultValue={VendorDetails.token.result.EggOrEggless} disabled />
                                                </div>
                                            </div> :
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom22" className='font-weight-bold'>Egg Or Eggless <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.EggOrEggless ? "form-control required-field text-capitalize" : "form-control text-capitalize"} defaultValue='' name='EggOrEggless' {...register('EggOrEggless')}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value="Egg">Egg</option>
                                                        <option value="Eggless">Eggless</option>
                                                    </select>
                                                </div>
                                            </div>
                                        } </> : <></>
                                    }
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>Flavour <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className="input-group">
                                                <div className='display-flex w-100'>
                                                    <input type="text" className="form-control text-capitalize" placeholder="e.g. Vanilla" name='Flavour' {...register('Flavour')} value={flavours} onChange={(e) => setFlavours(e.target.value)} />
                                                    <button className='add-button rounded disabled-add' disabled={flavours.length === 0 ? true : false} onClick={handleAddFlavours}>Add</button>
                                                </div>
                                                <div className='col-md-12'>
                                                    {flavourList?.map((value, index) =>
                                                        <div key={index}>
                                                            <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedFlavoursName(e, index)}>X</button></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Tea Cake' || state.state === 'Cheese Cake' ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Shape <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className="form-control" defaultValue='' name='Shape' {...register('Shape')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value="Round">Round</option>
                                                    <option value="Square">Square</option>
                                                </select>
                                            </div>
                                        </div> : <></>
                                    }
                                    {state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Tea Cake' || state.state === 'Cheese Cake' || state.state === 'Chocolates' ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Weight <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className="form-control" defaultValue='' name='MinWeightPerKg' {...register('MinWeightPerKg')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    {state.state === 'Chocolates' || state.state === 'Cookies' ?
                                                        <option value="250g">250g</option>
                                                        : <></>
                                                    }

                                                    <option value="500g">500g</option>
                                                    <option value="750g">750g</option>
                                                    <option value="1kg">1kg</option>
                                                    <option value="1.5kg">1.5kg</option>
                                                    <option value="2kg">2kg</option>
                                                    <option value="2.5kg">2.5kg</option>
                                                    <option value="3kg">3kg</option>
                                                </select>
                                            </div>
                                        </div> : <></>
                                    }
                                    {state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Chocolates' ||
                                        state.state === 'Tea Cake' || state.state === 'Cheese Cake' ?
                                        <div className="col-md-6 mb-3">
                                            {state.state === 'Brownie' || state.state === 'Cookies' || state.state === 'Chocolates' || state.state === 'Tea Cake' || state.state === 'Cheese Cake' ?
                                                <label htmlFor="validationCustom22" className='font-weight-bold'>Price/kg <span className='text-danger'>*</span></label> : <></>
                                            }
                                            <div className="input-group">
                                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={NumberValidation} className="form-control" name='PricePerKg' {...register('PricePerKg')} id="validationCustom24" placeholder="e.g. 300" />
                                                <button className="RupeeSign" disabled><BiRupee className="sidenavigationIcon" /></button>
                                            </div>
                                        </div> : <></>
                                    }
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>Discount (%) <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={DiscountValidation} className={errors?.Discount ? "form-control required-field" : "form-control"} name='Discount' {...register('Discount')} id="validationCustom25" placeholder='e.g. 5' />
                                            <button className="RupeeSign font-weight-bold" disabled><b>%</b></button>
                                        </div>
                                    </div>
                                    {state.state === 'Cake Dessert' &&
                                        <div className={disabledButton?.length === 0 ? "col-md-6 mb-3" : "col-md-8 mb-3"}>
                                            <CakeDessertWeight option={CakeDessertsWeight} className='option-control' handleCDWRemoveButton={handleCDWRemoveButton}
                                                handleCDWInputValue={handleCDWInputValue} handleCDWSelectButton={handleCDWSelectButton} disabledButton={disabledButton}
                                                handleAddOtherWeight={handleAddOtherWeight} cakeDessertWeightAndPriceList={cakeDessertWeightAndPriceList}
                                            />
                                        </div>
                                    }
                                    {state.state === 'macaroons' || state.state === 'Cup Cake' ?
                                        <div className={disabledButton2?.length === 0 ? "col-md-6 mb-3" : "col-md-8 mb-3"}>
                                            <MacaroonsBox option={macaroonsPiece} Name={state.state} className='option-control' handleMPRemoveButton={handleMPRemoveButton}
                                                handleMPInputValue={handleMPInputValue} handleMPSelectButton={handleMPSelectButton} disabledButton2={disabledButton2}
                                                handleAddOtherMPBox={handleAddOtherMPBox} macaroonsBoxPriceList={macaroonsBoxPriceList}
                                            />
                                        </div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header"><h6>Other Details</h6></div>
                            <div className="ms-panel-body tabledata-background">
                                <div className="form-row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Minimum Time For Delivery <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={2} onInput={(e) => {
                                                    if (parseInt(e.target.value) === 0) {
                                                        e.target.value = 1
                                                    } else {
                                                        if (e.target.value.length > e.target.maxLength) {
                                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                        } else if (e.target.value < e.target.min) {
                                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                        }
                                                    }
                                                }} className={errors?.MinTimeForDelivery ? "form-control required-field" : "form-control"} name='MinTimeForDelivery' {...register('MinTimeForDelivery')} placeholder="e.g. 2" />
                                                <select className={errors?.MinTimeForDelivery ? "form-control required-field w-50" : "form-control"} name='MinTimeForDeliveryUnit' {...register('MinTimeForDeliveryUnit')} defaultValue='days' id="validationCustom22" >
                                                    <option value="hours">hours</option>
                                                    <option value="days">days</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {state.state === 'Brownie' ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Product Base <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className="form-control" defaultValue='' id="validationCustom22" name='CakeBase' {...register('CakeBase')} >
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value="Butter">Butter</option>
                                                    <option value="Oil">Oil</option>
                                                </select>
                                            </div>
                                        </div> : <></>
                                    }
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>Best before <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength) {
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                    } else if (e.target.value < e.target.min) {
                                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                    }
                                                }} className={errors?.BestUsedBefore ? "form-control required-field" : "form-control"} name='BestUsedBefore' {...register('BestUsedBefore')} id="validationCustom24" placeholder="e.g. 2" />
                                                <select className={errors?.BestUsedBefore ? "form-control required-field w-50" : "form-control w-50"} name='BestUsedBeforeUnit' {...register('BestUsedBeforeUnit')} defaultValue='days' id="validationCustom22" >
                                                    <option value="hours">hours</option>
                                                    <option value="days">days</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>To Be Stored In <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className={errors?.ToBeStoredIn ? "form-control required-field" : "form-control"} name='ToBeStoredIn' {...register('ToBeStoredIn')} defaultValue='' id="validationCustom22" >
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="Freezer">Freezer</option>
                                                <option value="Chiller">Chiller</option>
                                                <option value="Air Tight Box">Air Tight Box</option>
                                                <option value="Room Temperature">Room Temperature</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.state === 'Cake Dessert' || state.state === 'Chocolates' || state.state === 'macaroons' || state.state === 'Cup Cake' ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Time at Room Temperature before use <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <div className='display-flex w-100'>
                                                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={(e) => {
                                                        if (e.target.value.length > e.target.maxLength) {
                                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                        } else if (e.target.value < e.target.min) {
                                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                        }
                                                    }} className="form-control" name='KeepTheCakeInRoomTemperature' {...register('KeepTheCakeInRoomTemperature')} id="validationCustom24" placeholder="Keep The Cake In Room Temperature" />
                                                    <select className="form-control w-50" defaultValue='days' id="validationCustom22" name='KeepTheCakeInRoomTemperatureUnit' {...register('KeepTheCakeInRoomTemperatureUnit')}>
                                                        <option value="mins">mins</option>
                                                        <option value="hours">hours</option>
                                                        {/* <option value="days">days</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div> : <></>
                                    }
                                    {state.state === 'Brownie' ?
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Toppers Possible<span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className="form-control" defaultValue='' id="validationCustom22" name='ToppersPossible' {...register('ToppersPossible')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value="y">Yes</option>
                                                    <option value="n">No</option>
                                                </select>
                                            </div>
                                        </div> : <></>
                                    }
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>How good are you in this {state.state === 'macaroons' ? 'Macarons' : state.state} <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className={errors?.HowGoodAreYouWithTheCake ? "form-control required-field" : "form-control"} name='HowGoodAreYouWithTheCake' {...register('HowGoodAreYouWithTheCake')} defaultValue='' id="validationCustom22" >
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="Beginner">Beginner</option>
                                                <option value="Good">Good</option>
                                                <option value="Excellent">Excellent</option>
                                                <option value="Specialist">Specialist</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>How many times have you Baked this {state.state === 'macaroons' ? 'Macarons' : state.state} <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className={errors?.HowManyTimesHaveYouBakedThisParticularCake ? "form-control required-field" : "form-control"} name='HowManyTimesHaveYouBakedThisParticularCake' {...register('HowManyTimesHaveYouBakedThisParticularCake')} defaultValue='' id="validationCustom22" >
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="Never">Never</option>
                                                <option value="1-3 times">1-3 times</option>
                                                <option value="4-7 times">4-7 times</option>
                                                <option value="7-10 times">7-10 times</option>
                                                <option value="More than 10 times">More than 10 times</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body tabledata-background">
                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Product Image <span className='text-danger'>*</span></label>
                                <div className="col-md-12 mb-3">
                                    <div className="custom-file">
                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='files' onChange={handleAddProductImages} />
                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Product Images...</label>
                                    </div>
                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    <div className='display-flex'>
                                        <div className='display-flex'>
                                            {productImageUrl.map((value, index) =>
                                                <div className="imgPre" key={value}>
                                                    <img width='90px' className='update-select-cake' src={value} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveProductImage(e, index)}>X</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Additional Product Image</label>
                                <div className="col-md-12 mb-3">
                                    <div className="custom-file">
                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='files' multiple onChange={handleAddAdditionalProductImages} />
                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Product Images...</label>
                                    </div>
                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    <div className='display-flex'>
                                        <div className='display-flex'>
                                            {additionalProductImageUrl?.map((value, index) =>
                                                <div className="imgPre" key={value}>
                                                    <img width='90px' className='update-select-cake' src={value} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveAdditionalProductImage(e, index)}>X</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body tabledata-background">
                                <label htmlFor="validationCustom12" className='font-weight-bold'>Product Description (400 Characters Only) <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <textarea rows={5} maxLength={400} className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"} name='Description' {...register('Description')} placeholder="Message" />
                                </div>
                                {loader ? <CircleDotSpinner /> :
                                    <div>
                                        <p className='text-danger bottom-error-message'>{message}</p>
                                        <p className='text-danger bottom-error-message'>{FieldErr}</p>
                                        <button className="btn btn-primary" type="submit" onClick={handleSubmit(onSubmit)}>Save</button>
                                        <button className="btn btn-secondary ml-4" onClick={handleCancel}>Close</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form >
            <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="text-center tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1>Enter {fieldName}</h1>
                    <form>
                        <div className="col-md-12 mt-4 mb-3 px-md-2">
                            <div className="input-group">
                                <div className='display-flex w-100'>
                                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={5} onInput={(e) => {
                                        if (e.target.value.length > e.target.maxLength) {
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                        } else if (e.target.value < e.target.min) {
                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                        }
                                    }} className="form-control" id="validationCustom24" onChange={(e) => setNewWeight(e.target.value)}
                                        placeholder={fieldName === 'Cake Dessert Weight Per Unit' ? "e.g. 700" : fieldName === 'Macaroons Pieces Per Box' ? 'e.g. 15' : ''} />
                                    {fieldName === 'Cake Dessert Weight Per Unit' ?
                                        <select className="form-control w-50" defaultValue='g' id="validationCustom22" onChange={(e) => setNewWeightUnit(e.target.value)}>
                                            <option value="g">grams</option>
                                            <option value="kg">kg</option>
                                        </select> : fieldName === 'Macaroons Pieces Per Box' ?
                                            <input value='Pieces' className="form-control w-50" disabled /> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className='text-danger text-left'>{showMessage}</p>
                        <button type="submit" onClick={handleAddNew} className="btn btn-primary shadow-none mr-3">Ok</button>
                        <button type="submit" onClick={handleClose} className="btn btn-secondary shadow-none">Cancel</button>
                    </form>
                </Modal.Body>
            </Modal>
            {/* {showAlert &&
                <Alert response={response} />
            } */}
        </div>
    )
}

