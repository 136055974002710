import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CakeArrayActions from '../../../../redux/CakeArray/actions';
import actions from '../../../../redux/cake/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Preloader from '../../../layouts/Preloader';
import FlavourList from '../Vendor-Cake-Edit/CakeArray/FlavourList';
import ShapesListWithWeight from '../Vendor-Cake-Edit/CakeArray/ShapesListWithWeight';
import WeightsList from '../Vendor-Cake-Edit/CakeArray/WeightList';
import EditImageUrl from '../Vendor-Cake-Edit/EditImageUrl';
import BodyContent3 from './BodyContent3';
import Breadcrumb from './Breadcrumb';
import { BiRupee } from 'react-icons/bi';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NumberValidation } from '../../../../utils/Validation';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    BasicCakePrice: yup.string().required("required"),
    Discount: yup.string().required("required"),
    BasicEgglessCostPerKg: yup.string().optional()
});

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const CakeId = state?.state?.CakeId;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(true);
    const [show4, setShow4] = useState(true);
    const [loader, setLoader] = useState(false);

    let [oldadditionalCakeImages, setOldAdditionalCakeImages] = useState([]);
    let [mainCakeImage, setMainCakeImage] = useState();
    let [mainCakeImageUrl, setMainCakeImageUrl] = useState();
    let [additionalCakeImages, setAdditionalCakeImages] = useState([]);
    let [additionalCakeImagesUrl, setAdditionalCakeImagesUrl] = useState([]);
    let [flavoursPrice, setFlavoursPrice] = useState([]);
    let [shapesPrice, setShapesPrice] = useState([]);
    // let [newShapesPrice, setNewShapesPrice] = useState([]);
    let [cakeFlavourList, setCakeFlavourList] = useState([]);
    let [cakeShapeList, setCakeShapeList] = useState([]);
    let [cakeWeightList, setCakeWeightList] = useState([]);
    let [oldSampleImages, setOldSampleImages] = useState([]);
    let [sampleImages, setSampleImages] = useState([]);
    let [sampleImagesUrl, setSampleImagesUrl] = useState([]);
    let [disabledButton, setDisabledButton] = useState([]);
    let [disabledButton2, setDisabledButton2] = useState([]);
    let [disabledButton4, setDisabledButton4] = useState([]);
    let [message, setMessage] = useState("");
    let [ErrMessage, setErrMessage] = useState([]);

    //get flavour, shape, weight, article list
    const { Flavours, WeightList, Shapes } = useSelector(state => state.CakeArrayReducer);

    //get status for edit cake details
    const { UpdatecakeStatus, CakeDetails } = useSelector((state) => state.CakesReducer);

    //get logged in vendor details
    const VendorDetails = useSelector((state) => state.authReducer);

    const CustomFlavourList = CakeDetails?.CustomFlavourList;
    const MinWeightList = CakeDetails?.MinWeightList;
    const CustomShapeList = CakeDetails?.CustomShapeList;

    const { register, handleSubmit, formState, setError } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        dispatch({ type: actions.GET_SINGLE_CAKEDETAILS, payload: CakeId });
        // eslint-disable-next-line 
    }, [CakeId]);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_ALL_FLAVOURS_LIST });
        dispatch({ type: CakeArrayActions.GET_ALL_SHAPES_LIST });
        dispatch({ type: CakeArrayActions.GET_WEIGHT_LIST });
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        //set old sample images to variable
        setOldSampleImages(CakeDetails?.CustomShapeList?.SampleImages);
        let ImagesArray = [];
        for (let i = 0; i < CakeDetails?.AdditionalCakeImages?.length; i++) {
            ImagesArray.push({ Url: CakeDetails?.AdditionalCakeImages[i], type: 'Old', File: CakeDetails?.AdditionalCakeImages[i] });
        }
        setAdditionalCakeImagesUrl(ImagesArray)
        // setAdditionalCakeImagesUrl(CakeDetails?.AdditionalCakeImages)
        setOldAdditionalCakeImages(CakeDetails?.AdditionalCakeImages)
        setMainCakeImageUrl(CakeDetails?.MainCakeImage)
    }, [CakeDetails]);

    useEffect(() => {
        //flavour list
        if (Flavours) {
            const ind = [], price = [];
            // eslint-disable-next-line
            Flavours?.map((val, index) => {
                for (let i = 0; i < CustomFlavourList?.length; i++) {
                    if (val.Name.includes(CustomFlavourList[i].Name)) {
                        ind.push(index);
                        price.push({ Price: CustomFlavourList[i]?.Price, index: index });
                    }
                }
            });
            setDisabledButton(ind);
            setFlavoursPrice(price);
            setCakeFlavourList(CustomFlavourList)
        };
    }, [Flavours, CustomFlavourList]);

    useEffect(() => {

        //weight list
        if (WeightList) {
            const ind = [];
            // eslint-disable-next-line
            WeightList?.map((val, index) => {
                for (let i = 0; i < MinWeightList?.length; i++) {
                    if ((val.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) === (MinWeightList[i]?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                        ind.push(index);
                    }
                }
            });
            setDisabledButton4(ind);
            setCakeWeightList(MinWeightList)
        };
    }, [WeightList, MinWeightList]);

    useEffect(() => {
        if (Shapes) {
            const ind = [], price = [], ErrValidate = [];
            // eslint-disable-next-line
            Shapes?.filter((val, index) => {
                // eslint-disable-next-line
                for (let i = 0; i < CustomShapeList?.Info?.length; i++) {
                    if (val.Name.includes(CustomShapeList?.Info[i]?.Name)) {
                        ind.push(index);
                        price.push({
                            MinWeight: CustomShapeList?.Info[i]?.MinWeight,
                            Price: CustomShapeList?.Info[i]?.Price, index: index
                        });
                        ErrValidate.push({ Name: CustomShapeList?.Info[i]?.Name, Validation: false });
                    }
                }
            });
            setDisabledButton2(ind);
            setShapesPrice(price);
            setCakeShapeList(CustomShapeList?.Info);
            setErrMessage(ErrValidate);
        };

    }, [Shapes, CustomShapeList]);

    const handleSelectButton = (i, Name) => {
        const newPrice = { Price: '', index: i };
        setFlavoursPrice([...flavoursPrice, newPrice]);
        if (disabledButton.length === 0) {
            setDisabledButton(disabledButton = [i]);
            setCakeFlavourList(cakeFlavourList = [{ Name: Name, Price: '' }]);
        } else {
            setDisabledButton(disabledButton = [...disabledButton, i]);
            setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, { Name: Name, Price: '' }]);
        }
    };

    //for remove flavour index value in single array
    const handleRemoveButton = (i) => {
        //for remove the index value in array
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);

        //for remove the name and price object in array
        const array2 = [...cakeFlavourList];
        let indexValue;
        Flavours.filter((val, ind) => {
            return array2.filter((v, inde) => {
                if (i === ind) {
                    if (val.Name === v.Name) {
                        indexValue = inde
                    }
                }
                return false;
            })
        })
        if (indexValue !== -1) {
            array2.splice(indexValue, 1);
        }
        setCakeFlavourList(array2);

        const array3 = [...flavoursPrice];
        if (index !== -1) {
            array3.splice(index, 1);
        }
        setFlavoursPrice(array3);
    };

    //add price for flavour
    const handleInputValue = (e, value) => {
        let CakeFlavourArray = [...cakeFlavourList];
        let FlavourInd = CakeFlavourArray.findIndex(val => val.Name === value);
        if (FlavourInd !== -1) {
            CakeFlavourArray[FlavourInd].Price = e.target.value;
        } else {
            CakeFlavourArray.push({ Name: value, Price: e.target.value });
        };
        setCakeFlavourList(cakeFlavourList = CakeFlavourArray);
        //for add name and price in single array
        // var temp = [];
        // temp[disabledButton.length - 1] = { Name: value, Price: e.target.value };
        // if (disabledButton.length === 1) {
        //     setCakeFlavourList(cakeFlavourList = temp);
        // } else {
        //     const tempflavour = temp;
        //     setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, Object.assign({}, tempflavour[disabledButton.length - 1])]);
        //     var index = [];
        //     cakeFlavourList.filter((val, i) => {
        //         if (val.Name === value) {
        //             return index = [...index, i];
        //         } else {
        //             return null;
        //         }
        //     });
        //     var array = [...cakeFlavourList];
        //     // eslint-disable-next-line
        //     var ind = array.findIndex((r, i) => {
        //         for (let j = 0; j < index.length - 1; j++) {
        //             return i === index[j]
        //         }
        //     });
        //     if (ind !== -1) {
        //         array.splice(ind, 1);
        //     };
        //     setCakeFlavourList(array);
        // }
    };

    //for add shape index value in single array
    const handleSelectButton2 = (e, i, Name) => {
        e.preventDefault();
        const newPrice = { MinWeight: "0", Price: '0', index: i };
        setShapesPrice([...shapesPrice, newPrice]);
        // setCakeShapeList([...cakeShapeList, value.Name]);
        if (disabledButton2.length === 0) {
            setDisabledButton2(disabledButton2 = [i]);
            setCakeShapeList(cakeShapeList = [{ Name: Name, MinWeight: '', Price: '' }]);
            // setErrMessage([{ Name: Name, Validation: false }]);
        } else {
            setDisabledButton2(disabledButton2 = [...disabledButton2, i]);
            setCakeShapeList(cakeShapeList = [...cakeShapeList, { Name: Name, MinWeight: '', Price: '' }]);
        }
        setErrMessage([...ErrMessage, { Name: Name, Validation: false }]);
    };

    //for remove shape index value in single array
    const handleRemoveButton2 = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton2];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton2(disabledButton2 = array);

        //for remove the name and price object in array
        const array2 = [...cakeShapeList];
        let indexValue;
        Shapes.filter((val, ind) => {
            return array2.filter((v, inde) => {
                if (i === ind) {
                    if (val.Name === v.Name) {
                        indexValue = inde
                    }
                }
                return false;
            })
        })
        if (indexValue !== -1) {
            array2.splice(indexValue, 1);
        }
        setCakeShapeList(array2);

        let NewShapesPriceList = [], FinalIndexValues = [];
        array2.filter((val, i) => {
            // eslint-disable-next-line
            return Shapes.filter((v, ind) => {
                let inde;
                if (val.Name === v.Name) {
                    inde = ind
                }
                let newData = { MinWeight: val.MinWeight, Price: val.Price, index: inde };
                FinalIndexValues.push(inde);
                if (newData.index !== undefined) {
                    NewShapesPriceList.push(newData);
                }
            });
        });
        setShapesPrice(NewShapesPriceList);
        setDisabledButton2(disabledButton2 = FinalIndexValues);
    };

    //add price for shapes
    const handleInputValue2 = (e, value) => {
        e.preventDefault()
        let ErrMessageArray = [...ErrMessage];
        let ErrMessageInd = ErrMessageArray?.findIndex(val => val.Name === value);
        if (e.target.name === 'MinWeight') {
            if (parseFloat(e.target.value) < parseFloat(CakeDetails?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                if (ErrMessageInd !== -1) {
                    ErrMessageArray[ErrMessageInd].Validation = true;
                }
            } else {
                if (ErrMessageInd !== -1) {
                    ErrMessageArray[ErrMessageInd].Validation = false;
                }
            }
        }
        setErrMessage(ErrMessage = ErrMessageArray);
        let CakeShapeArray = [...cakeShapeList];
        let Index = CakeShapeArray.findIndex(val => val.Name === value);
        if (e.target.name === 'MinWeight') {
            CakeShapeArray[Index].MinWeight = e.target.value;
        } else {
            CakeShapeArray[Index].Price = e.target.value;
        };
        setShapesPrice(shapesPrice = [...shapesPrice, CakeShapeArray[Index]]);
        let FinalMinWeight;
        if (CakeShapeArray[Index]?.MinWeight) {
            FinalMinWeight = CakeShapeArray[Index]?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] + "kg"
        } else {
            FinalMinWeight = ""
        };
        CakeShapeArray[Index].MinWeight = FinalMinWeight;
        setCakeShapeList(CakeShapeArray);
        // if (e.target.name === 'MinWeight') {
        //     if (CakeDetails?.MinWeight) {
        //         if (parseFloat(e.target.value) < parseFloat(CakeDetails?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
        //             setErrMessage(ErrMessage = "less than minimumweight");
        //         } else {
        //             setErrMessage(ErrMessage = "");
        //         }
        //     } else {
        //         setErrMessage(ErrMessage = "Minimum Weight is required");
        //     }
        // };
        // if (ErrMessage !== "less than minimumweight" || ErrMessage !== "Minimum Weight is required") {
        //     let PieceValidate = [], ChangedObject;
        //     // eslint-disable-next-line
        //     cakeShapeList?.filter(val => {
        //         if (val.Name === value) {
        //             PieceValidate.push('y');
        //             if (e.target.name === 'MinWeight') {
        //                 ChangedObject = { Name: val.Name, MinWeight: e.target.value, Price: val.Price }
        //             } else {
        //                 ChangedObject = { Name: val.Name, MinWeight: val.MinWeight, Price: e.target.value }
        //             }
        //         } else {
        //             PieceValidate.push('n');
        //         }
        //     });
        //     if (PieceValidate.includes('y')) {
        //         setNewShapesPrice(newShapesPrice = {
        //             ...newShapesPrice,
        //             Name: ChangedObject.Name,
        //             MinWeight: ChangedObject.MinWeight,
        //             Price: ChangedObject.Price
        //         });
        //     } else {
        //         if (e.target.name === 'MinWeight') {
        //             setNewShapesPrice(newShapesPrice = {
        //                 ...newShapesPrice,
        //                 Name: value,
        //                 MinWeight: e.target.value,
        //                 Price: "0"
        //             });
        //         } else {
        //             setNewShapesPrice(newShapesPrice = {
        //                 ...newShapesPrice,
        //                 Name: value,
        //                 MinWeight: "0 Kg",
        //                 Price: e.target.value,
        //             });
        //         }
        //     }
        //     let FinalMinWeight;
        //     if (newShapesPrice?.MinWeight) {
        //         FinalMinWeight = newShapesPrice?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] + "kg"
        //     } else {
        //         FinalMinWeight = "0 kg"
        //     }
        //     var PieceandPrice = {
        //         Name: newShapesPrice.Name,
        //         MinWeight: FinalMinWeight,
        //         Price: newShapesPrice.Price,
        //     }
        //     //for add name and price in single array
        //     var temp = [];
        //     temp[disabledButton2.length - 1] = PieceandPrice;
        //     if (disabledButton2.length === 1) {
        //         setCakeShapeList(cakeShapeList = temp);
        //     } else {
        //         const tempflavour = temp;
        //         setCakeShapeList(cakeShapeList = [...cakeShapeList, Object.assign({}, tempflavour[disabledButton2.length - 1])]);
        //         var index = [];
        //         cakeShapeList.filter((val, i) => {
        //             if (val.Name === value) {
        //                 return index = [...index, i];
        //             } else {
        //                 return null;
        //             }
        //         });
        //         var array = [...cakeShapeList];
        //         // eslint-disable-next-line
        //         var ind = array.findIndex((r, i) => {
        //             for (let j = 0; j < index.length - 1; j++) {
        //                 return i === index[j]
        //             }
        //         });
        //         if (ind !== -1) {
        //             array.splice(ind, 1);
        //         };
        //         setCakeShapeList(array);
        //     }
        // } else {
        //     setErrMessage(ErrMessage = "Add valid Shape Weight")
        // }
    };

    //for add weight index value in single array
    const handleSelectButton3 = (value, i) => {
        setCakeWeightList([...cakeWeightList, value.Weight]);
        if (disabledButton4.length === 0) {
            setDisabledButton4(disabledButton4 = [i]);
        } else {
            setDisabledButton4(disabledButton4 = [...disabledButton4, i]);
        }
    };

    // for remove weight index value in single array
    const handleRemoveButton3 = (i) => {
        //for remove the index value in array
        const array = [...disabledButton4];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton4(disabledButton4 = array);
        //for remove the name and price object in array
        const array2 = [...cakeWeightList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setCakeWeightList(array2);
    };

    //selected files - sample images
    const handleSelectSampleImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = sampleImages.find(val => val.name === files[i].name &&
                            val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        }
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = sampleImages.find(val => val.name === files[i].name &&
                        val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };

            }
            // var selected = e.target.files;
            if (selectedImages.length === 1) {
                setSampleImages(sampleImages = sampleImages.concat(selectedImages[0]));
            } else {
                for (let i = 0; i < selectedImages.length; i++) {
                    setSampleImages(sampleImages = sampleImages.concat(selectedImages[i]));
                };
            }
            //Display the images
            var imageArray = sampleImages.map(file => {
                return URL.createObjectURL(file);
            });
            setSampleImagesUrl(imageArray);
        }
    };

    //remove selected images- sample images
    function handleRemoveSampleImages(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...sampleImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setSampleImagesUrl(sampleImagesUrl = spliceImage);
        //for store in database
        var SelectedFile = [...sampleImages];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setSampleImages(sampleImages = SelectedFile);
    };

    //remove old sample images
    const handleRemoveOldSampleImages = (e) => {
        e.preventDefault();
        setOldSampleImages(oldSampleImages.filter((val) => {
            if (val !== e.target.value) {
                return val;
            } else {
                return null;
            }
        }));
    };

    const handleSelectMainCakeImages = (files) => {
        if (files.length !== 0) {
            setMainCakeImage(mainCakeImage = files[0]);
            setMainCakeImageUrl(mainCakeImageUrl = URL.createObjectURL(mainCakeImage));
        }
    };

    const handleRemoveMainCakeImages = (e) => {
        e.preventDefault();
        setMainCakeImage();
        setMainCakeImageUrl();
    };

    //selected files
    const handleAdditionalCakeImages = (files) => {
        var selectedImages = [];
        for (let i = 0; i < files.length; i++) {
            let fileDuplicateValidation = additionalCakeImages.find(val => val.name === files[i].name &&
                val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
            if (!fileDuplicateValidation) {
                selectedImages.push((files[i]));
            }
        };
        //Send the image files to back end
        if (selectedImages.length === 1) {
            setAdditionalCakeImages(additionalCakeImages = additionalCakeImages.concat(selectedImages[0]));
        } else {
            for (let i = 0; i < selectedImages.length; i++) {
                setAdditionalCakeImages(additionalCakeImages = additionalCakeImages.concat(selectedImages[i]));
            }
        }
        //Display the images
        var imageArray = additionalCakeImages.map(file => {
            return { Url: URL.createObjectURL(file), type: 'New', File: file };
        });
        if (additionalCakeImagesUrl?.length > 0) {
            let NewArray = additionalCakeImagesUrl?.filter(val => {
                if (val.type === 'Old') {
                    return val;
                }
                return false;
            });
            setAdditionalCakeImagesUrl([...NewArray, ...imageArray]);
        } else {
            setAdditionalCakeImagesUrl(imageArray);
        }
    };

    //remove selected image
    function handleRemoveAdditionalCakeImages(e, index, value) {
        e.preventDefault();
        let FileDetails = value;
        if (FileDetails?.type === 'New') {
            //for store in database
            var SelectedFile = [...additionalCakeImages];
            let imageInd = SelectedFile.findIndex(val => val.name === FileDetails?.File?.name && val.size === FileDetails?.File?.size && val.type === FileDetails?.File?.type);
            if (imageInd !== -1) {
                SelectedFile.splice(imageInd, 1);
            };
            setAdditionalCakeImages(additionalCakeImages = SelectedFile);
        } else {
            setOldAdditionalCakeImages(oldadditionalCakeImages.filter((v, ind) => v !== FileDetails?.Url))
        }
        //for display
        var spliceImage = [...additionalCakeImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setAdditionalCakeImagesUrl(additionalCakeImagesUrl = spliceImage);
    };

    // const handleApprove = (e) => {
    //     e.preventDefault();
    //     // setLoader(true);
    //     let FinalValidate, FinalShapeArray = [], ShapeArray = [];
    //     // if (cakeShapeList.length !== 0) {
    //     //     ShapeArray = cakeShapeList.filter(val => {
    //     //         if (val.Price !== '' && val.MinWeight !== '') {
    //     //             return val;
    //     //         }
    //     //         return false;
    //     //     });
    //     // }
    //     // for (let i = 0; i < ShapeArray.length; i++) {
    //     //     FinalShapeArray.push({ Name: ShapeArray[i].Name, MinWeight: ShapeArray[i].MinWeight, Price: ShapeArray[i].Price });
    //     // };
    //     if (FinalShapeArray.length > 0) {
    //         let MessageValidate = [];
    //         FinalShapeArray.filter(val => {
    //             if (parseFloat(val.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) < parseFloat(state?.state?.cake?.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
    //                 MessageValidate.push("y");
    //             } else {
    //                 MessageValidate.push("n");
    //             }
    //             return false;
    //         });
    //         if (MessageValidate.includes("y")) {
    //             setMessage("Custom Shapes Weight should be greater than or equal to Minimum Weight");
    //         } else {
    //             setMessage("");
    //             FinalValidate = "Done";
    //         }
    //     } else {
    //         FinalValidate = "Done";
    //     };
    //     if (FinalValidate === 'Done') {
    //         setShowPopup(true)
    //     }
    // };

    const onSubmit = (data) => {
        if (mainCakeImageUrl && mainCakeImageUrl.length !== 0) {
            let FinalFlavourArray = [], FinalShapeArray = [], FlavourArray = [], ShapeArray = [], FinalValidate;
            if (cakeFlavourList.length !== 0) {
                FlavourArray = cakeFlavourList.filter(val => {
                    if (val.Price) {
                        return val;
                    }
                    return false;
                });
            }
            if (cakeShapeList.length !== 0) {
                ShapeArray = cakeShapeList.filter(val => {
                    if (val.Price && val.MinWeight) {
                        return val;
                    }
                    return false;
                });
            }
            for (let i = 0; i < FlavourArray.length; i++) {
                FinalFlavourArray.push({ Name: FlavourArray[i].Name, Price: FlavourArray[i].Price });
            };
            for (let i = 0; i < ShapeArray.length; i++) {
                FinalShapeArray.push({ Name: ShapeArray[i].Name, MinWeight: ShapeArray[i].MinWeight, Price: ShapeArray[i].Price });
            };
            if (FinalShapeArray.length > 0) {
                let MessageValidate = [];
                FinalShapeArray.filter(val => {
                    if (parseFloat(val.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) < parseFloat(CakeDetails?.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                        MessageValidate.push("y");
                    } else {
                        MessageValidate.push("n");
                    }
                    return false;
                });
                if (MessageValidate.includes("y")) {
                    setMessage("Custom Shapes Weight should be greater than or equal to Minimum Weight");
                } else {
                    setMessage("");
                    FinalValidate = "Done";
                }
            } else {
                FinalValidate = "Done";
            };
            if (FinalValidate === 'Done') {
                if (CakeDetails?.IsEgglessOptionAvailable === 'y' && !data.BasicEgglessCostPerKg) {
                    setError('BasicEgglessCostPerKg')
                    setLoader(false)
                }
                else if (VendorDetails?.token !== undefined) {
                    setLoader(true);
                    const formData = new FormData();
                    formData.append("BasicCakePrice", data.BasicCakePrice);
                    data.BasicEgglessCostPerKg && formData.append("BasicEgglessCostPerKg", data.BasicEgglessCostPerKg);
                    formData.append("Stock", data.Stock);
                    formData.append("Discount", parseFloat(data.Discount));
                    if (FinalFlavourArray.length > 0) {
                        formData.append("CustomFlavourList", JSON.stringify(FinalFlavourArray));
                    };
                    if (FinalShapeArray.length > 0) {
                        formData.append("CustomShapeList", JSON.stringify(FinalShapeArray));
                    };
                    if (cakeWeightList.length > 0) {
                        formData.append("MinWeightList", JSON.stringify(cakeWeightList));
                    };

                    if (oldadditionalCakeImages.length !== 0) {
                        formData.append("OldCakeAdditionalImages", JSON.stringify(oldadditionalCakeImages));
                    }
                    if (oldSampleImages.length === 0 || oldSampleImages === undefined) {
                        if (sampleImages.length > 0) {
                            for (let i = 0; i < sampleImages.length; i++) {
                                formData.append("SampleImages", sampleImages[i]);
                            };
                        }
                    } else {
                        if (sampleImages.length > 0) {
                            for (let i = 0; i < sampleImages.length; i++) {
                                formData.append("SampleImages", sampleImages[i]);
                            };
                        };
                        if (oldSampleImages.length > 0) {
                            formData.append("OldSampleImages", JSON.stringify(oldSampleImages));
                        }
                    };
                    formData.append("NewMainCakeImage", mainCakeImage);
                    if (!mainCakeImage) {
                        formData.append("OldMainImages", mainCakeImageUrl);
                    }
                    if (additionalCakeImages.length > 0) {
                        for (let i = 0; i < additionalCakeImages.length; i++) {
                            formData.append("NewCakeAdditionalImages", additionalCakeImages[i]);
                        }
                    };
                    if (data.DefaultCakeEggOrEggless === 'Egg')

                        formData.append("VendorID", VendorDetails.token.result._id);
                    formData.append("Vendor_ID", VendorDetails.token.result.Id);
                    formData.append("VendorName", VendorDetails.token.result.VendorName);
                    formData.append("VendorPhoneNumber1", VendorDetails.token.result.PhoneNumber1);
                    formData.append("VendorPhoneNumber2", VendorDetails.token.result.PhoneNumber2);
                    formData.append("VendorAddress", VendorDetails.token.result.Address);
                    formData.append("GoogleLocation", JSON.stringify(VendorDetails.token.result.GoogleLocation));
                    dispatch({ type: actions.UPDATE_CAKES, payload: { reqBody: formData, reqParamid: CakeDetails?._id, history: history } });
                }
            }
        } else {
            setMessage("Main Cake Image is required field");
        }
    }

    //validate add new cake status
    useEffect(() => {
        if (UpdatecakeStatus?.statusCode === 400 || UpdatecakeStatus?.statusCode === 200) {
            setLoader(false);
        };
        // eslint-disable-next-line
    }, [UpdatecakeStatus]);

    useEffect(() => {
        (CakeDetails.length === 0 || Flavours.length === 0 || Shapes?.length === 0 || WeightList?.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
        if (Flavours?.message === 'No Records Found') { setShow1(false) };
        if (Shapes?.message === 'No Records Found') { setShow2(false) };
        if (WeightList?.message === 'No Records Found') { setShow4(false) };
        // eslint-disable-next-line
    }, [Flavours, Shapes, WeightList, CakeDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb CakeDetails={CakeDetails?._id} />
                    </div>
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-header">
                                <h6>Update Cake&apos;s Details ({CakeDetails?.Id})</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <BodyContent3 errors={errors} register={register} handleSelectMainCakeImages={handleSelectMainCakeImages}
                            mainCakeImageUrl={mainCakeImageUrl} handleRemoveMainCakeImages={handleRemoveMainCakeImages}
                            handleAdditionalCakeImages={handleAdditionalCakeImages} additionalCakeImagesUrl={additionalCakeImagesUrl}
                            handleRemoveAdditionalCakeImages={handleRemoveAdditionalCakeImages} mainCakeImage={mainCakeImage}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <form className="needs-validation clearfix" noValidate="">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom23" className='font-weight-bold'>Cake Name <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" disabled defaultValue={CakeDetails?.CakeName} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom23" className='font-weight-bold'>Egg Or Eggless <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" disabled defaultValue={CakeDetails?.DefaultCakeEggOrEggless} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom23" className='font-weight-bold'>Minimum Weight <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" disabled defaultValue={CakeDetails?.MinWeight} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <FlavourList className="option-control"
                                                option={Flavours} disabledButton={disabledButton} show={show1}
                                                handleSelectButton={handleSelectButton} handleRemoveButton={handleRemoveButton}
                                                handleInputValue={handleInputValue} flavoursPrice={flavoursPrice}
                                                cakeFlavourList={cakeFlavourList} CakeDetails={CakeDetails}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <WeightsList className="option-control" option={WeightList} show={show4}
                                                disabledButton={disabledButton4} handleSelectButton={handleSelectButton3}
                                                handleRemoveButton={handleRemoveButton3} cakeWeightList={cakeWeightList}
                                                minWeight={CakeDetails?.MinWeight}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <ShapesListWithWeight className="option-control" option={Shapes} show={show2}
                                                disabledButton={disabledButton2} handleSelectButton={handleSelectButton2}
                                                handleRemoveButton={handleRemoveButton2} cakeShapeList={cakeShapeList}
                                                handleInputValue={handleInputValue2} shapesPrice={shapesPrice} ErrMessage={ErrMessage}
                                                CakeDetails={CakeDetails} MinWeight={CakeDetails?.MinWeight}
                                            />
                                        </div>
                                        {cakeShapeList?.length > 0 &&
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Sample Images for Shapes</label>
                                                <div className="custom-file">
                                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='SampleImages' {...register('SampleImages')} multiple onChange={handleSelectSampleImages} />
                                                    <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Cake Images...</label>
                                                </div>
                                                <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                <div className='display-flex'>
                                                    <EditImageUrl display='sample' divClassName="display-flex" imagesurl={oldSampleImages} deleteFun={(e) => handleRemoveOldSampleImages(e)} />
                                                    <div className='display-flex'>
                                                        {sampleImagesUrl.map((value, index) =>
                                                            <div className="imgPre" key={value}>
                                                                <img width='90px' className='update-select-cake' src={value} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveSampleImages(e, index)}>X</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom22" className='font-weight-bold'>Cake Price per kg <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                {/* <div className='display-flex w-100'> */}
                                                <input type="number" onWheel={() => document.activeElement.blur()} className={errors?.BasicCakePrice ? "form-control required-field" : "form-control"} name='BasicCakePrice' {...register('BasicCakePrice')} defaultValue={CakeDetails?.BasicCakePrice} placeholder="e.g. 300" />
                                                <button className={errors?.BasicCakePrice ? "RupeeSign required-field" : "RupeeSign"} disabled><BiRupee className="sidenavigationIcon" /></button>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                        {CakeDetails?.IsEgglessOptionAvailable === 'y' &&

                                            <div className="col-md-4 mb-3">
                                                <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Eggless cake cost per kg <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    {/* <div className='display-flex w-100'> */}
                                                    <input type="number" onWheel={() => document.activeElement.blur()} className={errors?.BasicEgglessCostPerKg ? "form-control required-field" : "form-control"} name='BasicCakePrice' {...register('BasicEgglessCostPerKg')} defaultValue={CakeDetails?.BasicEgglessCostPerKg} placeholder="e.g. 300" />
                                                    <button className={errors?.BasicEgglessCostPerKg ? "RupeeSign required-field" : "RupeeSign"} disabled><BiRupee className="sidenavigationIcon" /></button>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom23" className='font-weight-bold'>Stock <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className="form-control" name='Stock' {...register('Stock')} defaultValue={CakeDetails?.Stock}>
                                                    <option value="OutOfStock">Out Of Stock</option>
                                                    <option value="InStock">In Stock</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom23" className='font-weight-bold'>Discount (%) <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={2} onInput={NumberValidation} className={errors?.Discount ? "form-control required-field" : "form-control"} name='BasicCakePrice' {...register('Discount')} defaultValue={CakeDetails?.Discount} placeholder="e.g. 300" />
                                                <button className={errors?.Discount ? "RupeeSign required-field" : "RupeeSign"} disabled><b>%</b></button>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 mb-3 px-md-2">
                                        <label htmlFor="validationCustom25"><b>Occasion</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <select defaultValue='' className="form-control" placeholder="types of cakes" name='Occasion' {...register('Occasion')} onChange={handleAddCakeType}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    {CakeArray.CakeType.map((val, i) =>
                                                        <>{val.Type === 'Occasion Cakes' ?
                                                            <> {val.SubType.map((s) =>
                                                                <option value={s}>{s}</option>
                                                            )} </> : <></>
                                                        } </>
                                                    )}
                                                </select>
                                            </div>
                                            {cakeTypeNameList.map((value, index) =>
                                                <div key={index}>
                                                    <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeTypeName(e, index)}>X</button></div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                        {/* <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustom18"><b>Start Date</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="date" className={errors?.StartDate ? "form-control required-field" : "form-control"} name='StartDate' {...register('StartDate')} defaultValue={Toppers?.HamperDetails?.StartDate.split("-").reverse().join("-")} />
                                        </div>
                                    </div> */}
                                        {/* <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustom18"><b>End Date</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="date" className={errors?.EndDate ? "form-control required-field" : "form-control"} name='EndDate' {...register('EndDate')} defaultValue={Toppers?.HamperDetails?.EndDate.split("-").reverse().join("-")} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label htmlFor="validationCustom18"><b>Weight(All Product Weight Included)</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <input type="number" min={0} maxLength={4} onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength) {
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                    } else if (e.target.value < e.target.min) {
                                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                    }
                                                }} className={errors?.Weight ? "form-control required-field" : "form-control"} name='Weight' {...register('Weight')} placeholder="Weight" defaultValue={Toppers?.HamperDetails?.Weight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]} />
                                                <select className={errors?.Weight ? "form-control w-50 required-field" : "form-control w-50"} id="validationCustom22" name='WeightUnit' {...register('WeightUnit')} defaultValue={Toppers?.HamperDetails?.Weight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1]}>
                                                    <option value="g">grams</option>
                                                    <option value="kg">kg</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Price <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="number" min={0} maxLength={4} onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength) {
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                } else if (e.target.value < e.target.min) {
                                                    e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                }
                                            }} className={errors?.Price ? "form-control required-field" : "form-control"} name='Price' {...register('Price')} placeholder="Price" defaultValue={Toppers?.HamperDetails?.Price} />
                                            <button className={errors?.Price ? "RupeeSign required-field" : "RupeeSign"} disabled><i className="fas fa-rupee-sign"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Contents <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <input type="text" className={productContainsList.length === 0 ? "form-control required-field text-capitalize" : "form-control text-capitalize"} placeholder="e.g. One Fruit And Nut Dry Cake (300 gm)" name='Product_Contains' {...register('Product_Contains')} value={productContains} onChange={(e) => setProductContains(e.target.value)} />
                                                <button className='add-button rounded disabled-add' disabled={productContains.length === 0 ? true : false} onClick={handleAddSpecialityCake}>Add</button>
                                            </div>
                                            <div className='col-md-12'>
                                                {productContainsList?.map((value, index) =>
                                                    <div key={index}>
                                                        <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedSpecialityCakeName(e, index)}>X</button></div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Description <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <textarea type="text" className={errors?.Description ? "form-control required-field" : "form-control"} name='Description' {...register('Description')} placeholder="e.g. 10" defaultValue={Toppers?.HamperDetails?.Description} />
                                        </div>
                                    </div> */}

                                    </div>
                                    {loader ? <div><CircleDotSpinner /></div> :
                                        <>
                                            <div className=" mb-1">
                                                <label className='text-success' htmlFor="validationCustom18"><b><span className='text-success'>Note:</span> </b> If you update this cake then it will be send to admin for Approval.</label>
                                            </div>
                                            <p className='text-danger'>{message}</p>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-3"
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Update
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-secondary"
                                                onClick={() => history.push('/Vendor-cake-details', { cakeId: CakeDetails?._id })}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <Modal className="modal-min" show={showPopup} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={() => setShowPopup(false)}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Confirmation</h1>

                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <>
                                    <div >
                                        <div className=" mb-1">
                                            <label className='float-left' htmlFor="validationCustom18"><b>If you update this cake then it will be send to admin for Approval. Are you sure you want to update this cake?</b> <span className='text-danger'>*</span></label>
                                        </div>
                                    </div>
                                    <p className='text-danger text-left font-weight-bold'>{popupMessage}</p>
                                    <button type="submit" className="btn btn-primary mr-3" onClick={handleSubmit(onSubmit)}>Update</button>
                                    <button type="submit" className="btn btn-secondary" onClick={() => history.push('/vendor-cakes-list')}>Cancel</button>
                                </>}
                        </Modal.Body>
                    </Modal> */}
                </div>
            }
        </>
    )
}