import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/cake/actions';
import VendorCakeListActions from '../../../../redux/VendorCakeList/actions';
import CakeArrayActions from '../../../../redux/CakeArray/actions';
import FlavourList from './CakeArray/FlavourList';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
// import Alert from '../../../common/Alert';
import CommonActions from '../../../../redux/Common/actions';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import WeightList from './CakeArray/WeightList';
import Preloader from '../../../layouts/Preloader';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal } from "react-bootstrap";
import BodyContent1 from './BodyContent1';
import BodyContent2 from './BodyContent2';
import BodyContent3 from './BodyContent3';
import CakeShapesWithWeight from './CakeArray/ShapesListWithWeight';
import FileSizeValidation from '../../../common/FileSizeValidation';
import { LettersValidation } from '../../../../utils/Validation';

const validationSchema = yup.object().shape({
    // CakeType: yup.string().required('required'),
    CakeName: yup.string().required('required'),
    CakeCommonName: yup.string().required('required'),
    BasicFlavour: yup.string().required("required"),
    BasicShape: yup.string().required("required"),
    MinWeight: yup.string().required('required'),
    BasicCakePrice: yup.string().required('required'),
    DefaultCakeEggOrEggless: yup.string().required('required'),
    IsTierCakePossible: yup.string().required('required'),
    ThemeCakePossible: yup.string().required('required'),
    ToppersPossible: yup.string().required('Gender is required'),
    MinTimeForDeliveryOfDefaultCake: yup.string().required('required'),
    MinTimeForDeliveryOf0Point5Kgto2KgCake: yup.string().required('required'),
    MinTimeForDeliveryOf2Point5Kgto4KgCake: yup.string().required('required'),
    MinTimeForDeliveryOf4Kgto5KgCake: yup.string().required('required'),
    MinTimeForDeliveryOfAbove5KgCake: yup.string().required('required'),
    BasicCustomisationPossible: yup.string().required('required'),
    FullCustomisationPossible: yup.string().required('required'),
    CakeBase: yup.string().required('required'),
    CakeCream: yup.string().required('required'),
    BestUsedBefore: yup.string().required('required'),
    ToBeStoredIn: yup.string().required('required'),
    KeepTheCakeInRoomTemperature: yup.string().required('required'),
    Description: yup.string().required('required'),
    HowGoodAreYouWithTheCake: yup.string().required('required'),
    HowManyTimesHaveYouBakedThisParticularCake: yup.string().required('required'),
    Discount: yup.string().required('required'),
    BasicEgglessCostPerKg: yup.string().optional(),
    IsEgglessOptionAvailable: yup.string().optional()
    // Tax: yup.string().required('required'),
});

export default function AddCakeContent() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    // eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [timer, setTimer] = useState("");
    const [loader, setLoader] = useState(false);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(true);
    const [show4, setShow4] = useState(true);
    var [ErrMessage, setErrMessage] = useState([]);
    var [EgglessOptionAvailable, setEgglessOptionAvailable] = useState('');

    const [showEgglessOption, setShowEgglessOption] = useState(false);
    const [showEgglessCost, setShowEgglessCost] = useState(false);
    // const [showMinTimeField, setShowMinTimeField] = useState([]);
    const [showTierCakeField, setShowTierCakeField] = useState('');
    // const [showCustomFields, setShowCustomFields] = useState(false);
    const [showButterCreamField, setShowButterCreamField] = useState(false);
    const [showOthersField, setShowOthersField] = useState(false);
    const [NewCakeCream, setNewCakeCream] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [minWeight, setMinWeight] = useState('');
    const [cakeCreamValidate, setCakeCreamValidate] = useState('');
    // const [basicCustomizationValidate, setBasicCustomizationValidate] = useState('');
    const [showFlavourPopup, setShowFlavourPopup] = useState(false);
    const [selectedFlavour, setSelectedFlavour] = useState('');
    const [newFlavourName, setNewFlavourName] = useState('');
    const [showShapePopup, setShowShapePopup] = useState(false);
    const [showCakeBasePopup, setShowCakebasePopup] = useState(false);
    const [NewCakeBase, setNewCakeBase] = useState('');
    const [CakeBaseValidate, setCakeBaseValidate] = useState('');
    const [selectedShape, setSelectedShape] = useState('');
    const [newShapeName, setNewShapeName] = useState('');
    const [tierFieldValidation, setTierFieldValidation] = useState(false);
    const [eggOrEgglessValue, setEggOrEgglessValue] = useState('');

    var [sampleImages, setSampleImages] = useState([]);
    var [sampleImagesUrl, setSampleImagesUrl] = useState([]);
    var [mainCakeImage, setMainCakeImage] = useState();
    var [mainCakeImageUrl, setMainCakeImageUrl] = useState();
    var [additionalCakeImages, setAdditionalCakeImages] = useState([]);
    var [additionalCakeImagesUrl, setAdditionalCakeImagesUrl] = useState([]);
    var [disabledButton, setDisabledButton] = useState([]);
    var [disabledButton2, setDisabledButton2] = useState([]);
    var [disabledButton4, setDisabledButton4] = useState([]);
    var [disabledButton5, setDisabledButton5] = useState([]);
    var [disabledButton6, setDisabledButton6] = useState([]);
    var [cakeFlavourList, setCakeFlavourList] = useState([]);
    var [cakeShapeList, setCakeShapeList] = useState([]);
    var [cakeShapePrice, setCakeShapePrice] = useState([]);
    var [cakeWeightList, setCakeWeightList] = useState([]);
    var [tierCakeWeightandPriceList, setTierCakeWeightandPriceList] = useState([]);
    var [tierCakeDeliveryTimeList, setTierCakeDeliveryTimeList] = useState([]);
    var [tierCakeWeightandPrice, setTierCakeWeightandPrice] = useState({
        Tier: '',
        Weight: '',
        Price: ''
    });
    var [tierCakeDeliveryTime, setTierCakeDeliveryTime] = useState({
        Tier: '',
        Time: '',
        Unit: ''
    });
    var [message, setMessage] = useState("");
    var [cakeTypeArray, setCakeTypeArray] = useState([]);
    var [cakeSubTypeArray, setCakeSubTypeArray] = useState([]);

    let [CakeCreamArray, setCakeCreamArray] = useState(["Butter Cream", "Whipping Cream", "Others"]);
    let [CakeBaseArray, setCakeBaseArray] = useState(["Butter", "Oil", "Others"]);

    var [OtherFlavourErrMessage, setOtherFlavourErrMessage] = useState("")
    var [OtherShapeErrMessage, setOtherShapeErrMessage] = useState("")
    var [OtherCakeCreamErrMessage, setOtherCakeCreamErrMessage] = useState("")

    //get logged in vendor details
    const { token } = useSelector((state) => state.authReducer);

    //get flavour , shape , weight, article list
    const AllList = useSelector(state => state.CakeArrayReducer);

    //get added cake status
    const CakesList = useSelector((state) => state.CakesReducer);

    const { response } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, reset, formState, setValue, getValues, clearErrors } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: { alert: false, message: null, status: null }
        });
    };
    //dispatch apis
    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_ALL_FLAVOURS_LIST });
        dispatch({ type: CakeArrayActions.GET_ALL_SHAPES_LIST });
        dispatch({ type: CakeArrayActions.GET_WEIGHT_LIST });
        dispatch({ type: CakeArrayActions.GET_CAKE_TYPE });
        // eslint-disable-next-line 
    }, []);

    //selected files
    const handleAdditionalCakeImages = (files) => {
        var selectedImages = [];
        for (let i = 0; i < files.length; i++) {
            let fileDuplicateValidation = additionalCakeImages.find(val => val.name === files[i].name &&
                val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
            if (!fileDuplicateValidation) {
                selectedImages.push((files[i]));
            }
        };
        //Send the image files to back end
        if (selectedImages.length === 1) {
            setAdditionalCakeImages(additionalCakeImages = additionalCakeImages.concat(selectedImages[0]));
        } else {
            for (let i = 0; i < selectedImages.length; i++) {
                setAdditionalCakeImages(additionalCakeImages = additionalCakeImages.concat(selectedImages[i]));
            }
        }
        //Display the images
        var imageArray = additionalCakeImages.map(file => {
            return URL.createObjectURL(file);
        });
        setAdditionalCakeImagesUrl(imageArray);
    };

    //remove selected image
    function handleRemoveAdditionalCakeImages(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...additionalCakeImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setAdditionalCakeImagesUrl(additionalCakeImagesUrl = spliceImage);
        //for store in database
        var SelectedFile = [...additionalCakeImages];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setAdditionalCakeImages(additionalCakeImages = SelectedFile);
    };

    const handleSelectMainCakeImages = (files) => {
        if (files.length !== 0) {
            setMainCakeImage(mainCakeImage = files[0]);
            setMainCakeImageUrl(mainCakeImageUrl = URL.createObjectURL(mainCakeImage));
        }
    };

    const handleRemoveMainCakeImages = (e) => {
        e.preventDefault();
        setMainCakeImage();
        setMainCakeImageUrl();
    };
    //close the page
    const handleClose = (e) => {
        e.preventDefault();
        if (token !== undefined) {
            dispatch({ type: VendorCakeListActions.GET_VENDOR_CAKE_LIST, payload: token?.result?._id });
        }
        history.push('/vendor-cakes-list');
    };

    //for add flavour index value in single array
    const handleSelectButton = (i, Name) => {
        if (disabledButton.length === 0) {
            setDisabledButton(disabledButton = [i]);
            setCakeFlavourList(cakeFlavourList = [{ Name: Name, Price: '' }]);
        } else {
            setDisabledButton(disabledButton = [...disabledButton, i]);
            setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, { Name: Name, Price: '' }]);
        }
    };

    //for remove flavour index value in single array
    const handleRemoveButton = (i) => {
        //for remove the index value in array
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);
        //for remove the name and price object in array
        const array2 = [...cakeFlavourList];
        let indexValue;
        AllList.Flavours.filter((val, ind) => {
            return array2.filter((v, inde) => {
                if (i === ind) {
                    if (val.Name === v.Name) {
                        indexValue = inde
                    }
                }
                return false;
            })
        })
        if (indexValue !== -1) {
            array2.splice(indexValue, 1);
        }
        setCakeFlavourList(array2);
    };

    //get price for flavour
    const handleInputValue = (e, value) => {
        let CakeFlavourArray = [...cakeFlavourList];
        let FlavourInd = CakeFlavourArray?.findIndex(val => val.Name === value);
        if (FlavourInd !== -1) {
            CakeFlavourArray[FlavourInd].Price = e.target.value;
        } else {
            CakeFlavourArray.push({ Name: value, Price: e.target.value });
        };
        setCakeFlavourList(cakeFlavourList = CakeFlavourArray);
        //for add name and price in single array
        // var temp = [];
        // temp[disabledButton.length - 1] = { Name: value, Price: e.target.value };
        // if (disabledButton.length === 1) {
        //     setCakeFlavourList(cakeFlavourList = temp);
        // } else {
        //     const tempflavour = temp;
        //     setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, Object.assign({}, tempflavour[disabledButton.length - 1])]);
        //     var index = [];
        //     cakeFlavourList.filter((val, i) => {
        //         if (val.Name === value) {
        //             return index = [...index, i];
        //         } else {
        //             return null;
        //         }
        //     });
        //     var array = [...cakeFlavourList];
        //     // eslint-disable-next-line
        //     var ind = array.findIndex((r, i) => {
        //         for (let j = 0; j < index.length - 1; j++) {
        //             return i === index[j]
        //         }
        //     });
        //     if (ind !== -1) {
        //         array.splice(ind, 1);
        //     };
        //     setCakeFlavourList(array);
        // }
    };

    //for add shape index value in single array
    const handleSelectButton2 = (e, i, Name) => {
        e.preventDefault();
        if (disabledButton2.length === 0) {
            setDisabledButton2(disabledButton2 = [i]);
            setCakeShapeList(cakeShapeList = [{ Name: Name, MinWeight: '', Price: '' }]);
            setErrMessage([{ Name: Name, Validation: false }]);
        } else {
            setDisabledButton2(disabledButton2 = [...disabledButton2, i]);
            setCakeShapeList(cakeShapeList = [...cakeShapeList, { Name: Name, MinWeight: '', Price: '' }]);
            setErrMessage([...ErrMessage, { Name: Name, Validation: false }]);
        }
    };

    //for remove shape index value in single array
    const handleRemoveButton2 = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton2];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton2(disabledButton2 = array);
        //for remove the name and price object in array
        // const array2 = [...cakeShapeList];
        // if (index !== -1) {
        //     array2.splice(index, 1);
        // }
        // setCakeShapeList(array2);
        const array2 = [...cakeShapeList];
        let indexValue;
        AllList.Shapes.filter((val, ind) => {
            return array2.filter((v, inde) => {
                if (i === ind) {
                    if (val.Name === v.Name) {
                        indexValue = inde
                    }
                }
                return false;
            })
        })
        if (indexValue !== -1) {
            array2.splice(indexValue, 1);
        }
        setCakeShapeList(array2);
    };

    //get price for flavour
    const handleInputValue2 = (e, value) => {
        e.preventDefault();
        let ErrMessageArray = [...ErrMessage];
        let ErrMessageInd = ErrMessageArray?.findIndex(val => val.Name === value);
        if (e.target.name === 'MinWeight') {
            if (minWeight?.value) {
                if (parseFloat(e.target.value) < parseFloat(minWeight?.value?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                    if (ErrMessageInd !== -1) {
                        ErrMessageArray[ErrMessageInd].Validation = true;
                    }
                    // setErrMessage(ErrMessage = "less than minimumweight");
                } else {
                    if (ErrMessageInd !== -1) {
                        ErrMessageArray[ErrMessageInd].Validation = false;
                    }
                    // setErrMessage(ErrMessage = "");
                }
            } else {
                ErrMessageArray[ErrMessageInd].Validation = true;
                // setErrMessage(ErrMessage = "Minimum Weight is required");
            }
            setErrMessage(ErrMessage = ErrMessageArray);
        };
        // if (!ErrMessage.find(val => val.Validation === true)) {
        // if (ErrMessage !== "less than minimumweight" || ErrMessage !== "Minimum Weight is required") {
        let CakeShapeArray = [...cakeShapeList];
        let Index = CakeShapeArray.findIndex(val => val.Name === value);
        if (e.target.name === 'MinWeight') {
            CakeShapeArray[Index].MinWeight = e.target.value;
        } else {
            CakeShapeArray[Index].Price = e.target.value;
        };
        setCakeShapePrice(cakeShapePrice = CakeShapeArray[Index]);
        let FinalMinWeight;
        if (cakeShapePrice?.MinWeight) {
            FinalMinWeight = cakeShapePrice?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] + "kg"
        } else {
            FinalMinWeight = ""
        };
        CakeShapeArray[Index].MinWeight = FinalMinWeight;
        setCakeShapeList(CakeShapeArray);
        // let WeightValidate = [], ChangedObject;
        // eslint-disable-next-line
        // cakeShapeList?.filter(val => {
        //     if (val.Name === value) {
        //         WeightValidate.push('y');
        //         if (e.target.name === 'MinWeight') {
        //             ChangedObject = { Name: val.Name, MinWeight: e.target.value, Price: val.Price }
        //         } else {
        //             ChangedObject = { Name: val.Name, MinWeight: val.MinWeight, Price: e.target.value }
        //         }
        //     } else {
        //         WeightValidate.push('n');
        //     }
        // });
        // if (WeightValidate.includes('y')) {
        //     setCakeShapePrice(cakeShapePrice = {
        //         ...cakeShapePrice,
        //         Name: ChangedObject.Name,
        //         MinWeight: ChangedObject.MinWeight,
        //         Price: ChangedObject.Price
        //     });
        // } 
        // else {
        //     if (e.target.name === 'MinWeight') {
        //         setCakeShapePrice(cakeShapePrice = {
        //             ...cakeShapePrice,
        //             Name: value,
        //             MinWeight: e.target.value,
        //             Price: "0"
        //         });
        //     } else {
        //         setCakeShapePrice(cakeShapePrice = {
        //             ...cakeShapePrice,
        //             Name: value,
        //             MinWeight: "0 Kg",
        //             Price: e.target.value,
        //         });
        //     }
        // }
        // let FinalMinWeight;
        // if (cakeShapePrice?.MinWeight) {
        //     FinalMinWeight = cakeShapePrice?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] + "kg"
        // } else {
        //     FinalMinWeight = "0 kg"
        // };
        // var WeightandPrice = {
        //     Name: cakeShapePrice?.Name,
        //     MinWeight: FinalMinWeight,
        //     Price: cakeShapePrice?.Price,
        // }
        // var temp = [];
        // temp[disabledButton2.length - 1] = WeightandPrice;
        // if (disabledButton2.length === 1) {
        //     setCakeShapeList(cakeShapeList = temp);
        // } else {
        //     const tempflavour = temp;
        //     setCakeShapeList(cakeShapeList = [...cakeShapeList, Object.assign({}, tempflavour[disabledButton2.length - 1])]);
        //     var index = [];
        //     cakeShapeList.filter((val, i) => {
        //         if (val.Name === value) {
        //             return index = [...index, i];
        //         } else {
        //             return null;
        //         }
        //     });
        //     var array = [...cakeShapeList];
        //     // eslint-disable-next-line
        //     var ind = array.findIndex((r, i) => {
        //         for (let j = 0; j < index.length - 1; j++) {
        //             return i === index[j]
        //         }
        //     });
        //     if (ind !== -1) {
        //         array.splice(ind, 1);
        //     };
        //     setCakeShapeList(array);
        // }

        // } else {
        //     ErrMessageArray[ErrMessageInd].Validation = true;
        //     setErrMessage(ErrMessage = ErrMessageArray);
        //     // setErrMessage(ErrMessage = "Add valid Shape Weight")
        // }
    };

    //for add weight index value in single array
    const handleSelectButton4 = (value, i) => {
        setCakeWeightList(cakeWeightList = [...cakeWeightList, value.Weight]);
        if (disabledButton4.length === 0) {
            setDisabledButton4(disabledButton4 = [i]);
        } else {
            setDisabledButton4(disabledButton4 = [...disabledButton4, i]);
        };
    };

    //for remove weight index value in single array
    const handleRemoveButton4 = (i) => {
        //for remove the index value in array
        const array = [...disabledButton4];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton4(disabledButton4 = array);
        //for remove the name and price object in array
        const array2 = [...cakeWeightList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setCakeWeightList(array2);
    };

    //for add flavour index value in single array
    const handleSelectButton5 = (e, i) => {
        e.preventDefault();
        if (disabledButton5.length === 0) {
            setDisabledButton5(disabledButton5 = [i]);
        } else {
            setDisabledButton5(disabledButton5 = [...disabledButton5, i]);
        }
    };

    //for remove flavour index value in single array
    const handleRemoveButton5 = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton5];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton5(disabledButton5 = array);
        //for remove the name and price object in array
        const array2 = [...tierCakeWeightandPriceList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setTierCakeWeightandPriceList(array2);
    };

    //get price for flavour
    const handleInputValue4 = (e, value) => {
        e.preventDefault();
        let WeightValidate = [], ChangedObject;
        // eslint-disable-next-line
        tierCakeWeightandPriceList.filter(val => {
            if (val.Tier === value) {
                WeightValidate.push('y');
                if (e.target.name === 'Price') {
                    ChangedObject = { Tier: val.Tier, Price: e.target.value, Weight: val.Weight }
                } else {
                    ChangedObject = { Tier: val.Tier, Price: val.Price, Weight: e.target.value }
                }
            } else {
                WeightValidate.push('n');
            }
        });
        if (WeightValidate.includes('y')) {
            setTierCakeWeightandPrice(tierCakeWeightandPrice = {
                ...tierCakeWeightandPrice,
                Tier: ChangedObject.Tier,
                Price: ChangedObject.Price,
                Weight: ChangedObject.Weight
            });
        } else {
            setTierCakeWeightandPrice(tierCakeWeightandPrice = {
                ...tierCakeWeightandPrice,
                Tier: value,
                [e.target.name]: e.target.value,
            });
        }
        var WeightandPrice = {
            Tier: tierCakeWeightandPrice.Tier,
            Price: tierCakeWeightandPrice.Price,
            Weight: (tierCakeWeightandPrice.Weight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[0] + "kg"
        }
        //for add name and price in single array
        var temp = [];
        temp[disabledButton5.length - 1] = WeightandPrice;
        if (disabledButton5.length === 1) {
            setTierCakeWeightandPriceList(tierCakeWeightandPriceList = temp);
        } else {
            const tempflavour = temp;
            setTierCakeWeightandPriceList(tierCakeWeightandPriceList = [...tierCakeWeightandPriceList, Object.assign({}, tempflavour[disabledButton5.length - 1])]);
            var index = [];
            tierCakeWeightandPriceList.filter((val, i) => {
                if (val.Tier === value) {
                    return index = [...index, i];
                } else {
                    return null;
                }
            });
            var array = [...tierCakeWeightandPriceList];
            // eslint-disable-next-line
            var ind = array.findIndex((r, i) => {
                for (let j = 0; j < index.length - 1; j++) {
                    return i === index[j]
                }
            });
            if (ind !== -1) {
                array.splice(ind, 1);
            };
            setTierCakeWeightandPriceList(array);
        }
    };

    //for add flavour index value in single array
    const handleSelectButton6 = (e, i) => {
        e.preventDefault();
        if (disabledButton6.length === 0) {
            setDisabledButton6(disabledButton6 = [i]);
        } else {
            setDisabledButton6(disabledButton6 = [...disabledButton6, i]);
        }
    };

    //for remove flavour index value in single array
    const handleRemoveButton6 = (e, i) => {
        e.preventDefault();
        //for remove the index value in array
        const array = [...disabledButton6];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton6(disabledButton6 = array);
        //for remove the name and price object in array
        const array2 = [...tierCakeDeliveryTimeList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setTierCakeDeliveryTimeList(array2);
    };

    //get price for flavour
    const handleInputValue5 = (e, value) => {
        e.preventDefault();
        let WeightValidate = [], ChangedObject;
        // eslint-disable-next-line
        tierCakeDeliveryTimeList.filter(val => {
            if (val.Weight === value) {
                WeightValidate.push('y');
                if (e.target.name === 'Time') {
                    ChangedObject = { Tier: val.Tier, Time: e.target.value, Unit: val.Unit }
                } else {
                    ChangedObject = { Tier: val.Tier, Time: val.Time, Unit: e.target.value }
                }
            } else {
                WeightValidate.push('n');
            }
        });
        if (WeightValidate.includes('y')) {
            setTierCakeDeliveryTime(tierCakeDeliveryTime = {
                ...tierCakeDeliveryTime,
                Tier: ChangedObject.Tier,
                Time: ChangedObject.Time,
                Unit: ChangedObject.Unit
            });
        } else {
            setTierCakeDeliveryTime(tierCakeDeliveryTime = {
                ...tierCakeDeliveryTime,
                Tier: value,
                [e.target.name]: e.target.value,
            });
        }
        var DeliveryTime = {
            Tier: tierCakeDeliveryTime.Tier,
            MinTime: tierCakeDeliveryTime.Time + tierCakeDeliveryTime.Unit
        };
        //for add name and price in single array
        var temp = [];
        temp[disabledButton6.length - 1] = DeliveryTime;
        if (disabledButton6.length === 1) {
            setTierCakeDeliveryTimeList(tierCakeDeliveryTimeList = temp);
        } else {
            const tempflavour = temp;
            setTierCakeDeliveryTimeList(tierCakeDeliveryTimeList = [...tierCakeDeliveryTimeList, Object.assign({}, tempflavour[disabledButton6.length - 1])]);
            var index = [];
            tierCakeDeliveryTimeList.filter((val, i) => {
                if (val.Tier === value) {
                    return index = [...index, i];
                } else {
                    return null;
                }
            });
            var array = [...tierCakeDeliveryTimeList];
            // eslint-disable-next-line
            var ind = array.findIndex((r, i) => {
                for (let j = 0; j < index.length - 1; j++) {
                    return i === index[j]
                }
            });
            if (ind !== -1) {
                array.splice(ind, 1);
            };
            setTierCakeDeliveryTimeList(array);
        }
    };

    const handleSelectSampleImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = sampleImages.find(val => val.name === files[i].name &&
                            val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        }
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = sampleImages.find(val => val.name === files[i].name &&
                        val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };

            }
            // var selected = e.target.files;
            if (selectedImages.length === 1) {
                setSampleImages(sampleImages = sampleImages.concat(selectedImages[0]));
            } else {
                for (let i = 0; i < selectedImages.length; i++) {
                    setSampleImages(sampleImages = sampleImages.concat(selectedImages[i]));
                };
            }
            //Display the images
            var imageArray = sampleImages.map(file => {
                return URL.createObjectURL(file);
            });
            setSampleImagesUrl(imageArray);
        }
    };

    //remove selected image
    function handleRemoveSampleImages(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...sampleImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setSampleImagesUrl(sampleImagesUrl = spliceImage);
        //for store in database
        var SelectedFile = [...sampleImages];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setSampleImages(sampleImages = SelectedFile);
    };

    const handleShowEgglessField = (e) => {
        setEggOrEgglessValue(e.target.value);
        if (e.target.value === 'Egg') {
            setShowEgglessOption(true);
            setShowEgglessCost(false);
        } else {
            setShowEgglessOption(false);
            setShowEgglessCost(false);
            setValue('BasicEgglessCostPerKg', '')
            setValue('IsEgglessOptionAvailable', 'n')
        }
    };

    const handleShowEgglessCostField = (e) => {
        if (e.target.value === 'y') {
            setShowEgglessCost(true)
            setEgglessOptionAvailable('yes')
        } else {
            setShowEgglessCost(false)
            setEgglessOptionAvailable('no')

        }
    };

    const handleShowTierCakeField = (e) => { setShowTierCakeField(e.target.value); };

    const handleGetSelectedCakeCream = (e) => {
        setCakeCreamValidate(e.target.value);
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
        if (e.target.value === 'Butter Cream') {
            setShowButterCreamField(true);
            setShowOthersField(false);
        } else if (e.target.value === 'Others') {
            setShowOthersField(true);
            setShowButterCreamField(false);
        } else {
            setShowButterCreamField(false);
            setShowOthersField(false);
        }
    };

    const handleAddNewCakeCream = (e) => {
        e.preventDefault();
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
        if (NewCakeCream !== '') {
            let ExistOrNot = CakeCreamArray?.filter(val => val.toLowerCase() === NewCakeCream.toLowerCase())
            // if (CakeCreamArray.includes(NewCakeCream)) {
            if (ExistOrNot?.length !== 0) {
                setOtherCakeCreamErrMessage('Cake Cream Already Exist')
            } else {
                setShowOthersField(false);
                setCakeCreamArray([...CakeCreamArray, NewCakeCream]);
                reset({ 'CakeCream': NewCakeCream });
                setOtherCakeCreamErrMessage('')
                setNewCakeCream('')

            }
            // setShowOthersField(false);
        } else {
            setOtherCakeCreamErrMessage('*required');
        }
    };

    const handleGetSelectedCakeBase = (e) => {
        setCakeBaseValidate(e.target.value);
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
        if (e.target.value === 'Others') {
            setShowCakebasePopup(true);
        } else {
            setShowCakebasePopup(false);
        }
    };
    const handleAddNewCakeBase = (e) => {
        e.preventDefault();
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
        if (NewCakeBase !== '') {
            let ExistOrNot = CakeBaseArray.filter(val => val.toLowerCase() === NewCakeBase.toLowerCase())
            // if (CakeBaseArray.includes(NewCakeBase)) {
            if (ExistOrNot?.length !== 0) {
                setPopupMessage('Cake Base Already Exist');
            } else {
                setShowCakebasePopup(false);
                setCakeBaseArray([...CakeBaseArray, NewCakeBase]);
                reset({ 'CakeBase': NewCakeBase });
                setPopupMessage('')
                setNewCakeBase('')

            }
            // setShowCakebasePopup(false);
        } else {
            setPopupMessage('*required');
        }
    };

    const handleAddCakeTypeArray = (e) => {
        setValue('CakeType', '')
        if (!cakeTypeArray.includes(e)) {
            if (e.toLowerCase().includes('tier')) {
                setTierFieldValidation(true);
                setShowTierCakeField("y")
                reset({ "IsTierCakePossible": "y" });
                clearErrors("IsTierCakePossible")
            }
            setCakeTypeArray(cakeTypeArray = [...cakeTypeArray, e]);
        };
    };

    const RemoveAddedCakeType = (e, index) => {
        e.preventDefault();
        reset({ 'CakeType': '' });
        if (!cakeTypeArray.includes('tier')) {
            setTierFieldValidation(false);
            setShowTierCakeField("");
            reset({ "IsTierCakePossible": "" });
        };
        var splicedList = [...cakeTypeArray];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setCakeTypeArray(cakeTypeArray = splicedList);
        if (cakeTypeArray.length === 0) {
            setCakeSubTypeArray([]);
        }
    };

    const handleAddSuTypeArray = (e) => {
        reset({ 'CakeSubType': '' });
        if (e?.value.toLowerCase().includes('tier')) {
            // if (e.target.value.toLowerCase().includes('tier')) {
            let NewSTList = [];
            cakeSubTypeArray.filter(val => {
                if (!val.toLowerCase().includes('tier')) {
                    NewSTList.push(val);
                }
                return false;
            });
            setCakeSubTypeArray(cakeSubTypeArray = [...NewSTList, e?.value]);
        } else {
            if (!cakeSubTypeArray.includes(e?.value)) {
                setCakeSubTypeArray(cakeSubTypeArray = [...cakeSubTypeArray, e?.value]);
            };
        }
    };

    const RemoveAddedSubCakeType = (e, index) => {
        e.preventDefault();
        reset({ 'CakeSubType': '' });
        var splicedList = [...cakeSubTypeArray];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setCakeSubTypeArray(splicedList);
    };

    const handleSelectedMinWeight = (e) => {
        setMinWeight(e);
        // setErrMessage("");
        reset({ 'MinWeight': e?.value });
        if (cakeShapeList?.length !== 0) {
            let ErrArray = [...ErrMessage];
            // eslint-disable-next-line
            cakeShapeList.map(val => {
                let ShapeValidateInd = ErrArray?.findIndex(v => v.Name === val.Name);
                if (ShapeValidateInd !== -1 && val?.MinWeight) {
                    if (parseFloat(val?.MinWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) >= parseFloat(e?.value.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                        ErrArray[ShapeValidateInd].Validation = false;
                    } else {
                        ErrArray[ShapeValidateInd].Validation = true;
                    }
                };
            });
            setErrMessage(ErrArray);
        };
        if (cakeWeightList?.length !== 0) {
            let GreaterList = [], ind = [];
            cakeWeightList.filter((val, i) => {
                if (parseFloat(val.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) > parseFloat(e?.value.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                    GreaterList.push(val);
                    ind.push(i)
                }
                return false;
            });
            setCakeWeightList(GreaterList);
            setDisabledButton4(disabledButton4 = ind);
        };
    };

    const handleClosePopup3 = () => {
        setShowShapePopup(false);
        setOtherShapeErrMessage("")
        reset({ 'BasicShape': '' });
    };

    const handleClosePopup1 = () => {
        setShowOthersField(false);
        setNewCakeCream('')
        setOtherCakeCreamErrMessage('');
        reset({ 'CakeCream': '' });
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
    };

    const handleClosePopup2 = () => {
        setShowFlavourPopup(false);
        setOtherFlavourErrMessage("")
        reset({ 'BasicFlavour': '' });
    };

    const handleClosePopup4 = () => {
        setShowCakebasePopup(false);
        setPopupMessage('')
        setNewCakeBase('');
        reset({ 'CakeBase': '' });
        if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
            setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
        } else {
            setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
        }
    };

    const onSubmit = (data) => {
        if (token !== undefined) {
            if (cakeTypeArray.length === 0 || !mainCakeImage) {
                setLoader(false);
                if (cakeTypeArray.length === 0) {
                    setMessage("Cake Type is Required")
                } else if (!mainCakeImage) {
                    setMessage("Main Cake Image is Required")
                }
            } else if ((token?.result?.EggOrEggless === 'Egg and Eggless') &&
                (data.DefaultCakeEggOrEggless === 'Egg' && !data.IsEgglessOptionAvailable)) {

                setMessage("Is Eggless Option Available field is required")

            }
            else if (data.IsEgglessOptionAvailable === 'y' && !data.BasicEgglessCostPerKg && showEgglessCost === true) {
                setMessage('Basic Eggless Cost Per Kg field is required')
            }
            // else if(cakeShapeList.length > 0 && sampleImages.length === 0){
            //     setLoader(false);
            //     setMessage("Cake Shape's Sample Image is Mandatory");
            // }
            else {
                let FinalValidate;
                if (cakeShapeList.length > 0) {
                    // let MessageValidate = [];
                    // cakeShapeList.filter(val => {
                    //     if (parseFloat(val.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) < parseFloat(data.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                    //         MessageValidate.push("y");
                    //     } else {
                    //         MessageValidate.push("n");
                    //     }
                    //     return false;
                    // });
                    // if (MessageValidate.includes("y")) {
                    //     setMessage("Custom Shapes Weight should be greater than or equal to Minimum Weight");
                    // } else {
                    //     setMessage("");
                    //     FinalValidate = "Done";
                    // }
                    if (ErrMessage.find(val => val.Validation === true)) {
                        setMessage("Custom Shapes Weight should be greater than or equal to Minimum Weight");
                    } else {
                        FinalValidate = "Done";
                        setMessage("");
                        let CakeShapeArrayValidate = [];
                        //eslint-disable-next-line
                        cakeShapeList.filter(val => {
                            if (val.MinWeight && val.Price) {
                                return CakeShapeArrayValidate.push(val);
                            }
                        });
                        setCakeShapeList(cakeShapeList = CakeShapeArrayValidate);
                    };
                } else {
                    FinalValidate = "Done";
                };
                if (cakeFlavourList.length > 0) {
                    let CakeFlavourArrayValidate = [];
                    //eslint-disable-next-line
                    cakeFlavourList.filter(val => {
                        if (val.Price) {
                            return CakeFlavourArrayValidate.push(val);
                        }
                    });
                    setCakeFlavourList(cakeFlavourList = CakeFlavourArrayValidate);
                };
                if (FinalValidate === 'Done') {
                    setMessage("");
                    const formData = new FormData();
                    formData.append("CakeName", data.CakeName);
                    formData.append("CakeType", JSON.stringify(cakeTypeArray));
                    cakeSubTypeArray.length > 0 && formData.append("CakeSubType", JSON.stringify(cakeSubTypeArray));
                    formData.append("CakeCommonName", data.CakeCommonName);
                    formData.append("BasicFlavour", data.BasicFlavour);
                    formData.append("BasicShape", data.BasicShape);
                    formData.append("MinWeight", data.MinWeight);
                    formData.append("BasicCakePrice", data.BasicCakePrice);
                    if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
                        formData.append("DefaultCakeEggOrEggless", token?.result?.EggOrEggless);
                    } else {
                        formData.append("DefaultCakeEggOrEggless", data.DefaultCakeEggOrEggless);

                    }
                    if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
                        formData.append("IsEgglessOptionAvailable", 'n');
                    }
                    else {
                        if (data.DefaultCakeEggOrEggless === 'Egg') {
                            formData.append("IsEgglessOptionAvailable", data.IsEgglessOptionAvailable);
                        } else {
                            formData.append("IsEgglessOptionAvailable", 'n');
                        }
                        if (data.IsEgglessOptionAvailable === 'y') {
                            formData.append("BasicEgglessCostPerKg", data.BasicEgglessCostPerKg);
                        } else {
                            formData.append("BasicEgglessCostPerKg", '0')
                        }
                    };
                    if (cakeFlavourList.length > 0) {
                        formData.append("CustomFlavourList", JSON.stringify(cakeFlavourList));
                    };
                    if (cakeShapeList.length > 0) {
                        formData.append("CustomShapeList", JSON.stringify(cakeShapeList));
                    };
                    if (cakeWeightList.length > 0) {
                        let FinalWeight = [];
                        for (let i = 0; i < cakeWeightList.length; i++) {
                            if (parseFloat(data.MinWeight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) <
                                parseFloat(cakeWeightList[i].match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) {
                                FinalWeight.push(cakeWeightList[i]);
                            }
                        };

                        formData.append("MinWeightList", JSON.stringify(FinalWeight));
                    };
                    if (sampleImages.length > 0) {
                        for (let i = 0; i < sampleImages.length; i++) {
                            formData.append("SampleImages", sampleImages[i]);
                        };
                    };
                    if (tierFieldValidation) {
                        formData.append("IsTierCakePossible", 'y');
                    } else {
                        formData.append("IsTierCakePossible", data.IsTierCakePossible);
                    }
                    formData.append("ThemeCakePossible", data.ThemeCakePossible);
                    formData.append("ToppersPossible", data.ToppersPossible);
                    formData.append("MinTimeForDeliveryOfDefaultCake", data.MinTimeForDeliveryOfDefaultCake);
                    formData.append("MinTimeForDeliveryOfABelow2KgCake", data.MinTimeForDeliveryOf0Point5Kgto2KgCake);
                    formData.append("MinTimeForDeliveryOfA2to4KgCake", data.MinTimeForDeliveryOf2Point5Kgto4KgCake);
                    formData.append("MinTimeForDeliveryOfA4to5KgCake", data.MinTimeForDeliveryOf4Kgto5KgCake);
                    formData.append("MinTimeForDeliveryOfAAbove5KgCake", data.MinTimeForDeliveryOfAbove5KgCake);
                    formData.append("BasicCustomisationPossible", data.BasicCustomisationPossible);
                    formData.append("FullCustomisationPossible", data.FullCustomisationPossible);
                    formData.append("CakeBase", data.CakeBase);
                    formData.append("CakeCream", data.CakeCream);
                    data.ButterCreamType && formData.append("ButterCreamType", data.ButterCreamType);
                    formData.append("BestUsedBefore", data.BestUsedBefore + ' ' + data.BestUsedBeforeUnit);
                    formData.append("ToBeStoredIn", data.ToBeStoredIn);
                    formData.append("KeepTheCakeInRoomTemperature", data.KeepTheCakeInRoomTemperature + ' ' + data.KeepTheCakeInRoomTemperatureUnit);
                    data.OtherInstructions && formData.append("OtherInstructions", data.OtherInstructions);
                    formData.append("Description", data.Description);
                    formData.append("HowGoodAreYouWithTheCake", data.HowGoodAreYouWithTheCake);
                    formData.append("HowManyTimesHaveYouBakedThisParticularCake", data.HowManyTimesHaveYouBakedThisParticularCake);
                    formData.append("Discount", data.Discount);
                    formData.append("Tax", "0");
                    formData.append("MainCakeImage", mainCakeImage);
                    if (additionalCakeImages.length > 0) {
                        for (let i = 0; i < additionalCakeImages.length; i++) {
                            formData.append("AdditionalCakeImages", additionalCakeImages[i]);
                        }
                    };
                    formData.append("VendorID", token?.result?._id);
                    formData.append("Vendor_ID", token?.result?.Id);
                    formData.append("VendorName", token?.result?.VendorName);
                    formData.append("VendorPhoneNumber1", token?.result?.PhoneNumber1);
                    token?.result?.PhoneNumber2 && formData.append("VendorPhoneNumber2", token?.result?.PhoneNumber2);
                    formData.append("VendorAddress", token?.result?.Address);
                    formData.append("GoogleLocation", JSON.stringify(token?.result?.GoogleLocation));
                    //     ________________________________________
                    setLoader(true);

                    dispatch({ type: actions.Add_CAKES, payload: formData });
                    setTimeout(() => { dispatch({ type: actions.Add_CAKES_STATUS, payload: { addCakeStatus: [] } }); }, 5000);
                    //    _____________________________________________
                } else {
                    setLoader(false);
                }
                // };
            }
        }
    };
    //validate add cake status
    useEffect(() => {
        if (CakesList?.addCakeStatus?.statusCode === 400) {
            setMessage(CakesList?.addCakeStatus?.message);
            setLoader(false);
        } else if (CakesList?.addCakeStatus?.statusCode === 200) {
            setLoader(false);
            setEggOrEgglessValue('')
            reset();
            setValue('MinWeight', '')
            setValue('BasicFlavour', '')
            setValue('BasicShape', '')
            setValue('CakeCream', '')
            setDisabledButton([]);
            setDisabledButton2([]);
            setDisabledButton4([]);
            setDisabledButton5([]);
            setDisabledButton6([]);
            setSampleImages([]);
            setSampleImagesUrl([]);
            setMainCakeImage();
            setMainCakeImageUrl();
            setAdditionalCakeImages([]);
            setAdditionalCakeImagesUrl([]);
            setCakeFlavourList([]);
            setCakeShapeList([]);
            setCakeWeightList([]);
            setCakeTypeArray([]);
            setCakeSubTypeArray([]);
            setShowEgglessOption(false);
            setShowEgglessCost(false);
            setSelectedFlavour('');
            setSelectedShape('');
            setMinWeight('');
        };
    }, [CakesList, reset, setValue]);

    const handleAddFlavour = (e) => {
        if (e?.value !== 'Other') {
            setSelectedFlavour(e);
            setValue('BasicFlavour', e?.value);
        }
        let DefaultFlavourInCustom = cakeFlavourList?.findIndex(val => val.Name === e?.value);
        if (DefaultFlavourInCustom !== -1) {
            const array = [...disabledButton];
            if (DefaultFlavourInCustom !== -1) {
                array.splice(DefaultFlavourInCustom, 1);
            };
            setDisabledButton(disabledButton = array);
            const array2 = [...cakeFlavourList];
            let indexValue;
            //eslint-disable-next-line
            array2.filter((v, inde) => {
                if (e?.value === v.Name) {
                    indexValue = inde
                }
            });
            if (indexValue !== -1) {
                array2.splice(indexValue, 1);
            }
            setCakeFlavourList(array2);
        };
        if (e?.value === 'Other') {
            setShowFlavourPopup(true);
        }
        // setSelectedFlavour(e.target.value);
        // if (e.target.value === 'Other') {
        //     setShowFlavourPopup(true);
        // }
    };

    const handleAddShape = (e) => {
        if (e?.value !== 'Other') {
            setValue('BasicShape', e?.value)
            setSelectedShape(e);
        }
        let DefaultShapeInCustom = cakeShapeList?.findIndex(val => val.Name === e?.value);
        if (DefaultShapeInCustom !== -1) {
            const array = [...disabledButton2];
            if (DefaultShapeInCustom !== -1) {
                array.splice(DefaultShapeInCustom, 1);
            };
            setDisabledButton2(disabledButton2 = array);
            const array2 = [...cakeShapeList];
            let indexValue;
            //eslint-disable-next-line
            array2.filter((v, inde) => {
                if (e?.value === v.Name) {
                    indexValue = inde
                }
            });
            if (indexValue !== -1) {
                array2.splice(indexValue, 1);
            }
            setCakeShapeList(array2);
        };
        if (e?.value === 'Other') {
            setShowShapePopup(true);
        }
        // setSelectedShape(e.target.value);
        // if (e.target.value === 'Other') {
        //     setShowShapePopup(true);
        // }
    };

    const handleAddNewFlavourName = (e) => {
        e.preventDefault();
        if (newFlavourName === "") {
            setOtherFlavourErrMessage("*required")
        } else {
            if (AllList?.Flavours?.message === 'No Records Found') {
                dispatch({ type: CakeArrayActions.ADD_NEW_FLAVOUR, payload: newFlavourName });
                reset({ 'BasicFlavour': '' });
                setShowFlavourPopup(false);
                setOtherFlavourErrMessage("");
                setNewFlavourName("")
            } else {
                const newList = AllList.Flavours?.filter(val => {
                    if (val.Name.toUpperCase() === newFlavourName.toUpperCase()) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: CakeArrayActions.ADD_NEW_FLAVOUR, payload: newFlavourName });
                    reset({ 'BasicFlavour': '' });
                    setShowFlavourPopup(false);
                    setNewFlavourName("")
                } else {
                    reset({ 'BasicFlavour': newFlavourName?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) });
                    setShowFlavourPopup(false);
                    setNewFlavourName("")
                }
            };
            setOtherFlavourErrMessage("");
        }
    };

    const handleAddNewShapeName = (e) => {
        e.preventDefault();
        if (newShapeName === "") {
            setOtherShapeErrMessage("*required")
        } else {
            if (AllList.Shapes?.message === 'No Records Found') {
                dispatch({ type: CakeArrayActions.ADD_NEW_SHAPE, payload: newShapeName });
                reset({ 'BasicShape': '' });
                setShowShapePopup(false);
                setOtherShapeErrMessage("")
            } else {
                const newList = AllList.Shapes?.filter(val => {
                    if (val.Name.toUpperCase() === newShapeName.toUpperCase()) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: CakeArrayActions.ADD_NEW_SHAPE, payload: newShapeName });
                    reset({ 'BasicShape': '' });
                    setShowShapePopup(false);
                    setOtherShapeErrMessage("")
                    setNewShapeName("")
                } else {
                    reset({ 'BasicShape': newShapeName?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) });
                    setShowShapePopup(false);
                    setOtherShapeErrMessage("")
                    setNewShapeName("")
                }
            };
            setOtherShapeErrMessage("")
        }
    };

    //loading and validate the list is empty or not
    useEffect(() => {
        if (AllList.Flavours.length === 0 || AllList.Shapes.length === 0 || AllList.CakeType.length === 0 || token === undefined || AllList.WeightList.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            if (token?.result?.EggOrEggless === 'Egg' || token?.result?.EggOrEggless === 'Eggless') {
                setValue('DefaultCakeEggOrEggless', token?.result?.EggOrEggless);
            } else {
                setValue('DefaultCakeEggOrEggless', eggOrEgglessValue);
            }
            if (response.alert) {
                setShowAlert(true);
                setTimer(setInterval(() => {
                    clearNotification()
                }, 4000))
            };
        }
        if (AllList.Flavours.message === 'No Records Found') {
            setShow1(false);
        }
        if (AllList.Shapes.message === 'No Records Found') {
            setShow2(false);
        }
        if (AllList.WeightList.message === 'No Records Found') {
            setShow4(false);
        }
        // eslint-disable-next-line
    }, [AllList, response, token]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <form>
                            <div className="col-md-12">
                                <BodyContent1 errors={errors} register={register} handleShowEgglessCostField={handleShowEgglessCostField}
                                    showEgglessOption={showEgglessOption} showEgglessCost={showEgglessCost}
                                    EgglessOptionAvailable={EgglessOptionAvailable}
                                    handleShowEgglessField={handleShowEgglessField} token={token}
                                    AllList={AllList.CakeType} handleAddCakeTypeArray={handleAddCakeTypeArray}
                                    cakeTypeArray={cakeTypeArray} RemoveAddedCakeType={RemoveAddedCakeType}
                                    handleAddSuTypeArray={handleAddSuTypeArray} cakeSubTypeArray={cakeSubTypeArray}
                                    RemoveAddedSubCakeType={RemoveAddedSubCakeType} handleSelectedMinWeight={handleSelectedMinWeight}
                                    minWeight={minWeight} FlavourList={AllList.Flavours} handleAddFlavour={handleAddFlavour}
                                    selectedFlavour={selectedFlavour} ShapesList={AllList.Shapes} handleAddShape={handleAddShape}
                                    selectedShape={selectedShape} reset={reset}
                                />
                                <div className="col-md-12">
                                    <BodyContent3 errors={errors} register={register} handleSelectMainCakeImages={handleSelectMainCakeImages}
                                        mainCakeImageUrl={mainCakeImageUrl} handleRemoveMainCakeImages={handleRemoveMainCakeImages}
                                        handleAdditionalCakeImages={handleAdditionalCakeImages} additionalCakeImagesUrl={additionalCakeImagesUrl}
                                        handleRemoveAdditionalCakeImages={handleRemoveAdditionalCakeImages} mainCakeImage={mainCakeImage}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <BodyContent2 errors={errors} register={register}
                                        // handleBasicCustomization={handleBasicCustomization}
                                        handleShowTierCakeField={handleShowTierCakeField} showTierCakeField={showTierCakeField}
                                        disabledButton5={disabledButton5} handleSelectButton5={handleSelectButton5}
                                        handleRemoveButton5={handleRemoveButton5} handleInputValue4={handleInputValue4}
                                        disabledButton6={disabledButton6} handleSelectButton6={handleSelectButton6}
                                        handleRemoveButton6={handleRemoveButton6} handleInputValue5={handleInputValue5}
                                        handleGetSelectedCakeCream={handleGetSelectedCakeCream} handleGetSelectedCakeBase={handleGetSelectedCakeBase}
                                        showButterCreamField={showButterCreamField} CakeBaseArray={CakeBaseArray}
                                        CakeCreamArray={CakeCreamArray} minWeight={minWeight}
                                        cakeCreamValidate={cakeCreamValidate} CakeBaseValidate={CakeBaseValidate}
                                        tierFieldValidation={tierFieldValidation}
                                    // basicCustomizationValidate={basicCustomizationValidate}

                                    />
                                </div>
                                {/* {showCustomFields && */}
                                <div className="col-md-12">
                                    <div className="ms-panel">
                                        <div className="ms-panel-body tabledata-background">
                                            {/* <p className='text-danger m-2'>Note : You should add custom flavour, shape, sample shape images and weight list. </p> */}
                                            <div className='form-row m-3' >
                                                <div className="col-md-5 mb-3">
                                                    <FlavourList className="option-control"
                                                        option={AllList.Flavours} disabledButton={disabledButton} show={show1}
                                                        handleSelectButton={handleSelectButton} handleRemoveButton={handleRemoveButton}
                                                        handleInputValue={handleInputValue} cakeFlavourList={cakeFlavourList}
                                                        register={register} errors={errors} selectedFlavour={selectedFlavour}
                                                    />
                                                </div>
                                                <div className="col-md-7 mb-3">
                                                    <CakeShapesWithWeight className="option-control" option={AllList.Shapes} show={show2}
                                                        disabledButton={disabledButton2} handleSelectButton={handleSelectButton2}
                                                        handleRemoveButton={handleRemoveButton2} cakeShapeList={cakeShapeList}
                                                        register={register} errors={errors} handleInputValue={handleInputValue2} ErrMessage={ErrMessage}
                                                        selectedShape={selectedShape} minWeight={minWeight}
                                                    />
                                                </div>
                                                {cakeShapeList.length > 0 &&
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Sample Images for Shapes </label>
                                                        <div className="custom-file">
                                                            <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='SampleImages' {...register('SampleImages')} multiple onChange={handleSelectSampleImages} />
                                                            <label className={errors?.SampleImages && sampleImages.length === 0 ? "custom-file-label required-field" : "custom-file-label"} htmlFor="validatedCustomFile">Upload Cake Images...</label>
                                                        </div>
                                                        <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                        <div className='display-flex'>
                                                            <div className='display-flex'>
                                                                {sampleImagesUrl?.map((value, index) =>
                                                                    <div className="imgPre" key={value}>
                                                                        <img width='90px' className='update-select-cake' src={value} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveSampleImages(e, index)}>X</button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-md-5 mb-3">
                                                    <WeightList className="option-control" option={AllList.WeightList} show={show4}
                                                        disabledButton={disabledButton4} handleSelectButton={handleSelectButton4}
                                                        handleRemoveButton={handleRemoveButton4} cakeWeightList={cakeWeightList}
                                                        register={register} errors={errors} getValues={getValues} minWeight={minWeight}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <label htmlFor="validationCustom12" className='font-weight-bold'>Other Instructions for Customers</label>
                                            <div className="input-group">
                                                <textarea rows={3} id="validationCustom12" className="form-control text-capitalize" placeholder="Instructions" name='OtherInstructions' {...register('OtherInstructions')} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <label htmlFor="validationCustom12" className='font-weight-bold'>Cake Description (400 Characters Only) <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <textarea rows={5} maxLength={400} className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"} name='Description' {...register('Description')} placeholder="Message" />
                                            </div>
                                            {loader ? <CircleDotSpinner /> :
                                                <div>
                                                    <p className='text-danger bottom-error-message'>{message}</p>
                                                    <button className="btn btn-primary" type="submit" onClick={handleSubmit(onSubmit)}>Save</button>
                                                    <button className="btn btn-secondary ml-4" onClick={handleClose}>Close</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Modal className="modal-min" show={showOthersField} onHide={handleClosePopup1} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClosePopup1}><span aria-hidden="true">×</span></button>
                            <h1>Enter Cake Cream</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-capitalize" placeholder="Cake cream" onChange={(e) => setNewCakeCream(e.target.value)} />
                                    </div>
                                </div>
                                <p className='text-danger'>{OtherCakeCreamErrMessage}</p>
                                <button type="submit" onClick={handleAddNewCakeCream} className="btn btn-primary shadow-none mr-3">Ok</button>
                                <button type="button" onClick={handleClosePopup1} className="btn btn-secondary shadow-none">Cancel</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showFlavourPopup} onHide={handleClosePopup2} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClosePopup2}><span aria-hidden="true">×</span></button>
                            <h1>Enter Flavour</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text"
                                            className="form-control text-capitalize"
                                            placeholder="Cake Flavour" onInput={LettersValidation} onChange={(e) => setNewFlavourName(e.target.value)} />
                                    </div>
                                    <p className="text-danger">{OtherFlavourErrMessage}</p>
                                </div>
                                <button type="submit" onClick={handleAddNewFlavourName} className="btn btn-primary shadow-none mr-3">Ok</button>
                                <button type="button" onClick={handleClosePopup2} className="btn btn-secondary shadow-none">Cancel</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showShapePopup} onHide={handleClosePopup3} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClosePopup3}><span aria-hidden="true">×</span></button>
                            <h1>Enter Shape</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-capitalize" placeholder="Cake Shape" onInput={LettersValidation} onChange={(e) => setNewShapeName(e.target.value)} />
                                    </div>
                                </div>
                                <p className='text-danger'>{OtherShapeErrMessage}</p>
                                <button type="submit" onClick={handleAddNewShapeName} className="btn btn-primary shadow-none mr-3">Ok</button>
                                <button type="button" onClick={handleClosePopup3} className="btn btn-secondary shadow-none">Cancel</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showCakeBasePopup} onHide={handleClosePopup4} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClosePopup4}><span aria-hidden="true">×</span></button>
                            <h1>Enter Cake Base</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-capitalize" placeholder="Cake Base" onChange={(e) => setNewCakeBase(e.target.value)} />
                                    </div>
                                </div>
                                <p className='text-danger'>{popupMessage}</p>
                                <button type="submit" onClick={handleAddNewCakeBase} className="btn btn-primary shadow-none mr-3">Ok</button>
                                <button type="button" onClick={handleClosePopup4} className="btn btn-secondary shadow-none">Cancel</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    {/* {showAlert &&
                        <Alert response={response} />
                    } */}
                </div>
            }
        </>
    );
};