import React from 'react';
import { float2D } from '../../../utils/Validation';

export default function PricePerUnit({ WeightAndPrice }) {

    return (
        <div className="col-sm-12">
             <h4 className="section-title bold">Minimum Weight per Unit</h4>
            <table id="data-table-5" className="table-price-align w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                <thead>
                    <tr role="row" className='tr-price-align'>
                        <th>Weight</th>
                        <th>Minimum Unit Count</th>
                        <th>Price per Unit</th>
                    </tr>
                </thead>
                {WeightAndPrice.map((cake, index) =>
                    <tbody key={index}>
                        <tr className='odd price-align2-row'>
                            <th className="sorting_1 id-column" scope="row">{cake.Weight}</th>
                            <td>{cake.MinCount}</td>
                            <td className='no-price-align-details'>Rs. {float2D(cake.PricePerUnit)}</td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    )
};