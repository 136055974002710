const actions = {
    GET_VENDORS_STATEMENTOFACCOUNTS_LIST: 'GET_VENDORS_STATEMENTOFACCOUNTS_LIST',
    SET_VENDORS_STATEMENTOFACCOUNTS_LIST: 'SET_VENDORS_STATEMENTOFACCOUNTS_LIST',
    GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS: 'GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS',
    SET_VENDOR_STATEMENTOFACCOUNTS_DETAILS: 'SET_VENDOR_STATEMENTOFACCOUNTS_DETAILS',
    GET_DAILY_VENDOR_PAYMENT_REPORTS: 'GET_DAILY_VENDOR_PAYMENT_REPORTS',
    SET_DAILY_VENDOR_PAYMENT_REPORTS: 'SET_DAILY_VENDOR_PAYMENT_REPORTS',
    GET_COUPONCODE_LIST: 'GET_COUPONCODE_LIST',
    SET_COUPONCODE_LIST: 'SET_COUPONCODE_LIST',
    GET_CANCELLED_ORDERS_LIST: 'GET_CANCELLED_ORDERS_LIST',
    SET_CANCELLED_ORDERS_LIST: 'SET_CANCELLED_ORDERS_LIST',
    GET_REFUND_REPORTS: 'GET_REFUND_REPORTS',
    SET_REFUND_REPORTS: 'SET_REFUND_REPORTS',
    GET_ACCOUNTS_DASHBOARD_COUNT: 'GET_ACCOUNTS_DASHBOARD_COUNT',
    SET_ACCOUNTS_DASHBOARD_COUNT: 'SET_ACCOUNTS_DASHBOARD_COUNT',
    GET_ACCOUNTS_INPROGRESS_TICKETS: 'GET_ACCOUNTS_INPROGRESS_TICKETS',
    SET_ACCOUNTS_INPROGRESS_TICKETS: 'SET_ACCOUNTS_INPROGRESS_TICKETS',
    GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS: 'GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS',
    SET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS: 'SET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS',
    GET_SALES_REPORT_BY_DATE: 'GET_SALES_REPORT_BY_DATE',
    SET_SALES_REPORT_BY_DATE: 'SET_SALES_REPORT_BY_DATE',
    GET_SALES_REPORT_BY_MONTH: 'GET_SALES_REPORT_BY_MONTH',
    SET_SALES_REPORT_BY_MONTH: 'SET_SALES_REPORT_BY_MONTH',
    GET_MISCELLANEOUS_REFUND_REPORT: 'GET_MISCELLANEOUS_REFUND_REPORT',
    SET_MISCELLANEOUS_REFUND_REPORT: 'SET_MISCELLANEOUS_REFUND_REPORT',
};

export default actions;