import actions from "./actions";

const initState = {
    updateAdmin: [],
    adminId: '',
    StatusCount: [],
    TotalUsers: [],
    RecentOrders: [],
    RecentOrdersN: [],
    RecentOrdersY: [],
    ProfileUpdateStatus: [],
    GetAdminDetailsbyEmail: [],
    NotificationCount: [],
    CakeBanners: [],
    NotificationList: [],
    InternalUsersList: [],
    InternalUserDetails: [],
    AdminResponse: [],
    Customer_Ratings: [],
};

const adminReducer = (state = initState, action) => {

    switch (action.type) {
        case actions.SET_ADMIN_DETAILS:
            return {
                ...state,
                updateAdmin: action.payload.updateAdmin,
                adminId: action.payload.adminId,
            };
        case actions.SET_ADMINORDER_STATUS_COUNT:
            return {
                ...state,
                StatusCount: action.payload.StatusCount

            };
        case actions.SET_TOTAL_USERS:
            return {
                ...state,
                TotalUsers: action.payload.TotalUsers,
            }
        case actions.SET_ADMIN_RECENTORDER_N:
            return {
                ...state,
                RecentOrdersN: action.payload.RecentOrdersN,
            }
        case actions.SET_ADMIN_RECENTORDER_Y:
            return {
                ...state,
                RecentOrdersY: action.payload.RecentOrdersY,
            }
        case actions.SET_ADMIN_RECENTORDER:
            return {
                ...state,
                RecentOrders: action.payload.RecentOrders,
            }
        case actions.SET_PROFILE_UPDATE_STATUS:
            return {
                ...state,
                ProfileUpdateStatus: action.payload.ProfileUpdateStatus
            }
        case actions.SET_ADMIN_DETAILS_BY_EMAIL:
            return {
                ...state,
                GetAdminDetailsbyEmail: action.payload.GetAdminDetailsbyEmail
            }
        case actions.SET_NOTIFICATION_COUNT:
            return {
                ...state,
                NotificationCount: action.payload.NotificationCount
            }
        case actions.SET_CAKE_BANNERS:
            return {
                ...state,
                CakeBanners: action.payload.CakeBanners
            }
        case actions.SET_ADMIN_NOTIFICATION_LIST:
            return {
                ...state,
                NotificationList: action.payload.NotificationList
            }
        case actions.SET_INTERNAL_USERS_LIST:
            return {
                ...state,
                InternalUsersList: action.payload
            }
        case actions.SET_INTERNAL_USER_DETAILS:
            return {
                ...state,
                InternalUserDetails: action.payload
            }
        case actions.ADMIN_RESPONSE:
            return {
                ...state,
                AdminResponse: action.payload
            }
        case actions.SET_CUSTOMER_RATINGS_LIST:
            return {
                ...state,
                Customer_Ratings: action.payload
            }

        default: return state;
    }
}

export default adminReducer;