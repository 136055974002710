const actions={
    GET_ALL_CATEGORIES : "GET_ALL_CATEGORIES",
    SET_ALL_CATEGORIES : "SET_ALL_CATEGORIES",
    ADD_NEW_CATEGORY :  "ADD_NEW_CATEGORY",
    ADD_CATEGORY_STATUS : "ADD_CATEGORY_STATUS",
    ADD_NEW_SUBCATEGORY : "ADD_NEW_SUBCATEGORY",
    ADD_SUBCATEGORY_STATUS : "ADD_SUBCATEGORY_STATUS",
    DELETE_CATEGORY : "DELETE_CATEGORY",
    REMOVE_CATEGORY : "REMOVE_CATEGORY",
    DELETE_SUBCATEGORY : "DELETE_SUBCATEGORY",
    REMOVE_SUBCATEGORY : "REMOVE_SUBCATEGORY",
    UPDATE_CATEGORY : 'UPDATE_CATEGORY',
    UPDATE_CATEGORY_STATUS : 'UPDATE_CATEGORY_STATUS'
    
};
export default actions;