import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useHistory } from 'react-router-dom';

export default function VendorCakesListSlider({ cakeList }) {

  const [finalData, setFinalData] = useState([]);

  const history = useHistory();

  const handleClick = (cake) => {
    history.push('/admin-cake-details', { page: 'vendordetails', cakeId: cake?._id })
  }

  useEffect(() => {
    if (cakeList?.message === 'No Records Found') {
      setFinalData([]);
    } else {
      setFinalData(cakeList)
    }
  }, [cakeList]);

  return (
    <>

      {finalData.length > 0 &&
        <div>
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                paritialVisibilityGutter: 60
              },
              tablet: {
                breakpoint: { max: 1024, min: 600 },
                items: 2,
                paritialVisibilityGutter: 50
              },
              mobile: {
                breakpoint: { max: 600, min: 560 },
                items: 1,
                paritialVisibilityGutter: 30
              }
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {finalData.map((cake) =>
              <div className="cake-carousel-item vendor-cakes-main-div" key={cake.Id}>
                <img src={cake.MainCakeImage} alt="cake" className="vendor-cakes-images" />
                <h4 className="text-center vendor-cakes-cake-name">{cake.CakeName}</h4>
                <p className="text-center"><b>Price :</b> <i className="fas fa-rupee-sign" /> {cake.BasicCakePrice}</p>
                <a href="/admin-cake-details" className="text-info" onClick={() => handleClick(cake)}><u>Read more</u></a>
              </div>
            )}
          </Carousel>
        </div>

      }
    </>
  )
};