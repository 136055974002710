import React from "react";
import { Modal } from "react-bootstrap";
import commonActions from '../../redux/Common/actions';
import { useSelector, useDispatch } from "react-redux";
// import { FiAlertTriangle } from 'react-icons/fi';
import { GoAlert } from 'react-icons/go';

export const FileErrorModel = () => {

    const dispatch = useDispatch();

    const { fileValidation } = useSelector(state => state.commonReducer);

    const handleClose = () => {
        dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: false });
    };

    return (
        <div>
            {fileValidation &&
                <Modal className="modal-min" show={fileValidation} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="text-center tabledata-background">
                        <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <GoAlert className="alert-icon" />
                        {/* <h1 className="text-center pb-2 pt-2">Alert</h1> */}
                        <div className="mt-3 mb-3 text-center">
                            <h5>Uploaded image size is too large. Maximum image size is 3mb</h5>
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none"
                                onClick={handleClose}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}