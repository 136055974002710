import React from 'react';
import Sidenavigation from '../layout/Sidenavigation';
import Topnavigation from '../layout/Topnavigation';
import Content from '../sections/Helpdesk-CakeyDirect-GeneralOrder-List/Content';
import { Helmet } from "react-helmet";

export default function HelpdeskVCakeyDirectGeneralOrderList() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Cakey Direct - General Orders</title>
            </Helmet>

            {/* sidebar start */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content statrt  */}
                <Content/>
                {/* page content end  */}

            </main>

        </div>
    );
};