import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { float2D } from '../../../../../utils/Validation';

export default function StatementOfAccounts(props) {

    const { filteredData, selectedMonth, handleSelectedMonth } = props;

    let [totalAmount, setTotalAmount] = useState();

    useEffect(() => {
        if (!filteredData || filteredData?.length === 0) {
            setTotalAmount(0);
        } else {
            var PriceList = [];
            filteredData.filter(val => {
                if (val.Type === 'Sales') {
                    PriceList.push(val.FinalTotal);
                }
                return null;
            });
            if (PriceList?.length === 0) {
                setTotalAmount(0);
            } else if (PriceList.length === 1) {
                setTotalAmount(PriceList[0]);
            } else {
                setTotalAmount(Math.abs(PriceList.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)));
            }
        };
    }, [filteredData]);

    return (
        <div className="ms-panel-body tabledata-background tab-pane-content">
            <div className="table-responsive Scrollbar-Style">
                <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"></div>
                        <div className="col-sm-12 col-md-6 mb-2">
                            <div id="data-table-4_filter" className="dataTables_filter">
                                <label className="font-weight-bold">Select Month :
                                    <input
                                        type='month'
                                        className="form-control form-control-sm"
                                        aria-controls="data-table-4"
                                        value={selectedMonth}
                                        onChange={handleSelectedMonth} max={moment().format('YYYY-MM')}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                <thead>
                                    <tr role="row">
                                        <th scope="col">S. No</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Order ID</th>
                                        <th scope="col">Sales Amount</th>
                                        <th scope="col">Share Of Cakey</th>
                                        <th scope="col">Final Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData?.length > 0 ?
                                        <>
                                            {filteredData.map((order, i) =>
                                                <> {order.Type === 'Sales' &&
                                                    <tr className="odd">
                                                        <th className='id-column' scope="row">{i + 1}</th>
                                                        <td>{order.Date}</td>
                                                        <td>{order.Order_ID}</td>
                                                        <td className='vendor-report-statement-alignment'>{float2D(parseFloat(order.FinalTotal) + parseFloat(order.CakeyShare || 0))}</td>
                                                        <td className='vendor-report-share-of-cakey-alignment' >{float2D(order.CakeyShare || 0.00)}</td>
                                                        <td className='vendor-report-statement-alignment'>{float2D(order.FinalTotal)}</td>
                                                    </tr>
                                                }
                                                </>
                                            )}
                                        </> :
                                        <tr>
                                            <td colSpan={5} className='font-weight-bold'>--</td>
                                            <td className='font-weight-bold'>--</td>
                                        </tr>
                                    }
                                    <tr className="odd">
                                        <th ></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <td className='font-weight-bold'>Total Sales Amount Of {moment(selectedMonth?.slice(5, 7), 'MM').format('MMMM')}</td>
                                        <td className='font-weight-bold vendor-statement-alignment'>{float2D(totalAmount)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};