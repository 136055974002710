import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AccountsActions from '../../../../redux/Accounts/actions';
import TableSearch from '../../../common/TableSearch';
import Pagination from '../../../common/Pagination';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);

    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const { VendorPaymentReports } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        dispatch({
            type: AccountsActions.GET_DAILY_VENDOR_PAYMENT_REPORTS,
            // payload: { StartDate: '08-10-2022', EndDate: 'today' }
            payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY') }
        });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (VendorPaymentReports?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(VendorPaymentReports.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Vendor_ID && val.Vendor_ID.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.Payment && val.Payment.toLowerCase().includes(search)) ||
                    (val.PaymentType && val.PaymentType.toLowerCase().includes(search)) ||
                    (val.Payment_Date && val.Payment_Date.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [VendorPaymentReports, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({
                type: AccountsActions.GET_DAILY_VENDOR_PAYMENT_REPORTS,
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY') }
            });
        } else {
            if (startDate && endDate) {
                setButtonChange(true);
                dispatch({
                    type: AccountsActions.GET_DAILY_VENDOR_PAYMENT_REPORTS,
                    payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
                });
            };
        };
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            setButtonChange(true);
            dispatch({
                type: AccountsActions.GET_DAILY_VENDOR_PAYMENT_REPORTS,
                payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
            });
        }else{
            dispatch({
                type: AccountsActions.GET_DAILY_VENDOR_PAYMENT_REPORTS,
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY') }
            });
        }
    };

    useEffect(() => {
        (VendorPaymentReports.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [VendorPaymentReports]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Vendor Payments Report</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate || ''}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate || ''}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => setPostPerPage(e.target.value)}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search Id/Status..." onChange={handleSearchbar} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Vendor ID</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Payment</th>
                                                                <th scope="col">Payment Type</th>
                                                                <th scope="col">Payment Date</th>
                                                                <th scope="col">Payment Sent On</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{val.Vendor_ID}</th>
                                                                        <td>{val.VendorName}</td>
                                                                        <td className='accounts-payment-alignment'>{float2D(val.Payment)}</td>
                                                                        <td>{val.PaymentType}</td>
                                                                        <td>{val.Payment_Date}</td>
                                                                        <td>{val.Created_On}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <Pagination
                                            postsPerPage={postsPerPage}
                                            totalPosts={filteredData.length}
                                            paginate={paginate}
                                            PreviouPage={() => setCurrentPage(currentPage - 1)}
                                            NextPage={() => setCurrentPage(currentPage + 1)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};