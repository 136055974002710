import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Vendor/actions';
import VendorCakeListActions from '../../../redux/VendorCakeList/actions';
import Preloader from '../../layouts/Preloader';
import VendorCakesListSlider from './VendorCakesListSlider';
import InstituteNameList from './InstituteNameList';
import CakeTypeList from './CakeTypeList';
import SpacialityCakeList from './SpecialityCakeList';
import Breadcrumb from './Breadcrumb';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import { FaPencilAlt } from 'react-icons/fa';

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const [buttonValue, setButtonValue] = useState('');
    const [loader, setLoader] = useState('');

    // for get vendors cakes list 
    const { CakesList } = useSelector(state => state.VendorCakeListReducer);

    const { VendorDetails2, VendorResponse } = useSelector(state => state.VendorReducer);

    //dispatch the vendors cake list api
    useEffect(() => {
        dispatch({ type: VendorCakeListActions.GET_VENDOR_CAKE_LIST, payload: Id });
        dispatch({ type: actions.GET_VENDOR_DETAILS2, payload: Id });
        // eslint-disable-next-line 
    }, [Id]);

    const handleBlockandUnlockPopup = (e) => {
        e.preventDefault();
        setButtonValue(e.target.value);
        setShow(true);
    };

    const handleBlockorUnblock = (e) => {
        e.preventDefault();
        setLoader(true);
        if (buttonValue === 'Block') {
            dispatch({ type: actions.BLOCK_AND_UNBLOCK_VENDOR, payload: { id: VendorDetails2?._id, body: 'Blocked' } });
        } else {
            dispatch({ type: actions.BLOCK_AND_UNBLOCK_VENDOR, payload: { id: VendorDetails2?._id, body: 'Approved' } });
        };
    };

    const handleClose = () => { setShow(false) };

    const handleEdit = (e) => {
        e.preventDefault();
        dispatch({ type: actions.SET_VENDOR_DETAILS_BY_ID, payload: [] });
        history.push('/admin-vendor-detail-edit', { type: 'Admin', VendorId: Id });
    };

    useEffect(() => {
        if (VendorResponse.statusCode === 200 || VendorResponse.statusCode === 400) {
            setShow(false);
            setLoader(false);
        }
    }, [VendorResponse]);

    //loading
    useEffect(() => {
        if (CakesList.length === 0 || VendorDetails2.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
    }, [CakesList, VendorDetails2]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="ms-profile-overview">
                        <div className="ms-profile-cover">
                            <div className='profile-img-div'>
                                <img className="ms-profile-img" src={VendorDetails2?.ProfileImage ? VendorDetails2?.ProfileImage : "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg"} alt="people" />
                            </div>
                            <div className="ms-profile-user-info">
                                <h4 className="ms-profile-username text-white">{VendorDetails2?.VendorName}</h4>
                                <h2 className="ms-profile-role">{VendorDetails2?.Email}</h2>
                            </div>
                            <div className="ms-profile-user-buttons">
                                <button
                                    className="btn btn-primary edit-profile"
                                    onClick={handleEdit}
                                >
                                    <FaPencilAlt /> Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">BASIC INFORMATION</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Register ID</th>
                                                <td className={VendorDetails2?.Status === 'Blocked' ? 'text-danger' : ''}><b>{VendorDetails2?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email ID</th>
                                                <td>{VendorDetails2?.Email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Preferred Name on the App</th>
                                                <td>{VendorDetails2?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{VendorDetails2?.PreferredNameOnTheApp}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Gender</th>
                                                <td>{VendorDetails2?.Gender}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Date of Birth</th>
                                                <td>{VendorDetails2?.DateOfBirth}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{VendorDetails2?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Main Mobile Number</th>
                                                <td>{VendorDetails2?.PhoneNumber1}</td>
                                            </tr>
                                            {VendorDetails2?.PhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Alternative Mobile Number</th>
                                                    <td>{VendorDetails2?.PhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Address Line 1</th>
                                                <td><span className="long-line-break">{VendorDetails2?.DoorNo}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Address Line 2</th>
                                                <td><span className="long-line-break">{VendorDetails2?.Address}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Pincode</th>
                                                <td><span className="long-line-break">{VendorDetails2?.Pincode}</span></td>
                                            </tr>
                                            {VendorDetails2?.GoogleLocation &&
                                                <tr>
                                                    <th scope="row">Google Location</th>
                                                    <td>
                                                        <b>Latitude</b> : {VendorDetails2?.GoogleLocation?.Latitude} <br />
                                                        <b>Longitude</b> : {VendorDetails2?.GoogleLocation?.Longitude}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">BANK DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Bank Name</th>
                                                <td>{VendorDetails2?.BankName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Branch</th>
                                                <td>{VendorDetails2?.Branch}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Account Number</th>
                                                <td>{VendorDetails2?.AccountNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">IFSC Code</th>
                                                <td>{VendorDetails2?.IFSCCode}</td>
                                            </tr>
                                            {VendorDetails2?.UPIId ?
                                                <tr>
                                                    <th scope="row">UPI ID</th>
                                                    <td>{VendorDetails2?.UPIId}</td>
                                                </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title"> IDENTITY AND LICENSE DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Aadhaar Number</th>
                                                <td>{VendorDetails2?.AadhaarNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">PAN Number</th>
                                                <td>{VendorDetails2?.PANNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">FSSAI Number</th>
                                                <td>{VendorDetails2?.FSSAINumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">FSSAI Expiry Date</th>
                                                <td>{VendorDetails2?.FSSAIExpiryDate}</td>
                                            </tr>
                                            {VendorDetails2?.GSTNumber ?
                                                <tr>
                                                    <th scope="row">GST Number</th>
                                                    <td>{VendorDetails2?.GSTNumber}</td>
                                                </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">OTHER DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Learning Type</th>
                                                <td>{VendorDetails2?.LearningType}</td>
                                            </tr>
                                            {(VendorDetails2?.InstitutionName?.length > 0 && VendorDetails2?.LearningType !== 'Self Learning') &&
                                                <tr>
                                                    <th scope="row">Institution Name</th>
                                                    <td><span className="long-line-break"><InstituteNameList NameList={VendorDetails2?.InstitutionName} /></span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Total Duration of Learning</th>
                                                <td>{VendorDetails2?.TotalDurationOfLearning}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Maximum Capacity to Bake in a Week (in kgs)</th>
                                                <td>{VendorDetails2?.HowManyCakesCanYouMakeInaWeek}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Current Average Sales Per Month (in kgs)</th>
                                                <td>{VendorDetails2?.CurrentAverageSalePerMonth}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Baking Experience</th>
                                                <td>{VendorDetails2?.YearsOfExperienceAsBaker}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Number of Days You Can Bake In a Week</th>
                                                <td>{VendorDetails2?.HowManyDaysCanYouWorkInaWeek}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Type of Baker</th>
                                                <td>{VendorDetails2?.JobType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Can You Bake Cakes Above 5kg</th>
                                                <td>{VendorDetails2?.CanYouMakeSingleCakeAbove5Kgs === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Do You Make Tier Cake</th>
                                                <td>{VendorDetails2?.CanYouMakeTierCakes === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Comfortable on Working With Mobile Apps</th>
                                                <td>{VendorDetails2?.AreYouFamiliarOnWorkingWithApps === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Types of Cake/Other Product</th>
                                                <td><span className="long-line-break"><CakeTypeList NameList={VendorDetails2?.CakeTypesYouBake} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Your Speciality Cakes</th>
                                                <td><span className="long-line-break"><SpacialityCakeList NameList={VendorDetails2?.YourSpecialityCakes} /></span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Self Rating</th>
                                                <td>{VendorDetails2?.Ratings} Star</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Do You Bake Regular Cake With Fondant Topper</th>
                                                <td>{VendorDetails2?.CanYouMakeARegularCakeWithFondantAsToppers === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">ABOUT VENDOR</h2>
                                    <p>{VendorDetails2?.Description}</p>
                                    <div className="registered-vendor-close">
                                        {VendorDetails2?.Status === 'Approved' ?
                                            <button
                                                className="btn btn-primary mr-3"
                                                type="submit"
                                                value='Block'
                                                onClick={(e) => handleBlockandUnlockPopup(e)}
                                            >
                                                Block
                                            </button> :
                                            <button
                                                className="btn btn-primary mr-3"
                                                type="submit"
                                                value='Unblock'
                                                onClick={(e) => handleBlockandUnlockPopup(e)}
                                            >
                                                Unblock
                                            </button>
                                        }
                                        <button
                                            className="btn btn-secondary "
                                            type="submit"
                                            onClick={() => history.push('/admin-vendor-List')}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {CakesList?.message !== 'No Records Found' &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h6 className='pl-2'>CAKES LIST</h6>
                                        <hr />
                                        <VendorCakesListSlider cakeList={CakesList} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <div>
                                <h6 className='text-center mb-4'>Are you sure you want to {buttonValue} the vendor?</h6>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleBlockorUnblock}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            No
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div >
            }
        </>
    );
}
export default Content;