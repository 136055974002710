import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CakeOrderActions from '../../../../redux/VendorOrderList/actions';
import HamperOrderActions from '../../../../redux/HampersOrder/actions';
import ProductOrderActions from '../../../../redux/OtherProducts/actions';
import TicketsActions from '../../../../redux/Tickets/actions';
import Preloader from '../../../layouts/Preloader';
import CakeOrderDetails from './CakeOrderDetails';
import HamperOrderDetails from './HamperOrderDetails';
import OtherProductOrderDetailsPage from './OtherProductOrderDetails';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { useForm } from 'react-hook-form';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showRaiseTicketPopup, setShowRaiseTicketPopup] = useState(false);
    const [loader, setLoader] = useState(false);

    const { register, resetField, handleSubmit, formState: { errors } } = useForm();

    const { GetVendorOrderDetails } = useSelector(state => state.VendorOrderReducer);

    const { OtherProductOrderDetails } = useSelector(state => state.OtherProductReducer);

    const { HamperDetails } = useSelector(state => state.HamperOrderReducer);

    const { Ticket_Response } = useSelector(state => state.TicketReducer);

    const { token } = useSelector((state) => state.authReducer);

    let Type = state.state.Type;
    let Id = state.state.Id;

    useEffect(() => {
        if (Type === 'Cake Order') {
            dispatch({
                type: CakeOrderActions.GET_VENDOR_ORDER_DETAILS,
                payload: Id
            });
        } else if (Type === 'Hamper Order') {
            dispatch({
                type: HamperOrderActions.GET_HAMPER_DETAILS,
                payload: Id
            });
        } else {
            dispatch({
                type: ProductOrderActions.GET_OTHER_PRODUCT_ORDER_DETAILS,
                payload: Id
            });
        };
        //eslint-disable-next-line
    }, [Type, Id]);

    const handleClose = (e) => {
        e.preventDefault();
        history.push('/Helpdesk-C-Cancelled-Orders-With-15Mins-List');
    };

    const RaiseTicketPopup = (e) => {
        e.preventDefault();
        setShowRaiseTicketPopup(true);
    };

    const handleCloseRaiseTicketPopup = () => {
        resetField('TicketDescription');
        setShowRaiseTicketPopup(false);
    };

    const handleRaiseTicket = (data) => {
        let formData, Accepted_By, Ticket_Raised_For, Last_Intimate, FinalOrderDetails;
        if (Type === 'Cake Order') {
            FinalOrderDetails = {
                OrderID: GetVendorOrderDetails?._id,
                Order_ID: GetVendorOrderDetails?.Id,
                Order_Status: GetVendorOrderDetails?.Status,
                UserID: GetVendorOrderDetails?.UserID,
                User_ID: GetVendorOrderDetails?.User_ID
            };
        } else if (Type === 'Hamper Order') {
            FinalOrderDetails = {
                OrderID: HamperDetails?._id,
                Order_ID: HamperDetails?.Id,
                Order_Status: HamperDetails?.Status,
                UserID: HamperDetails?.UserID,
                User_ID: HamperDetails?.User_ID
            };
        } else {
            FinalOrderDetails = {
                OrderID: OtherProductOrderDetails?._id,
                Order_ID: OtherProductOrderDetails?.Id,
                Order_Status: OtherProductOrderDetails?.Status,
                UserID: OtherProductOrderDetails?.UserID,
                User_ID: OtherProductOrderDetails?.User_ID
            };
        };
        Accepted_By = 'HelpdeskC_Accepted_By';
        Ticket_Raised_For = 'Customer';
        Last_Intimate = ['HelpdeskV', 'Accounts'];
        formData = {
            TypeOfUser: token?.result?.TypeOfUser,
            CauseOfTicket: 'Cancellation Within 15mins',
            TicketDescription: data.TicketDescription,
            TypeOfOrder: Type,
            OrderID: FinalOrderDetails?.OrderID,
            Order_ID: FinalOrderDetails?.Order_ID,
            Order_Status: FinalOrderDetails?.Order_Status,
            UserID: FinalOrderDetails?.UserID,
            User_ID: FinalOrderDetails?.User_ID,
            Ticket_Raised_For: Ticket_Raised_For,
            Last_Intimate: Last_Intimate,
            Ticket_Status: 'In Progress',
            Ticket_Raised_By: {
                ID: token?.result?._id,
                Name: token?.result?.Name,
                Email: token?.result?.Email,
                TypeOfUser: token?.result?.TypeOfUser
            },
            [Accepted_By]: {
                ID: token?.result?._id,
                Name: token?.result?.Name,
                Email: token?.result?.Email,
                TypeOfUser: token?.result?.TypeOfUser
            },
            Ticket_Accessed_By: {
                HelpdeskV: 'y', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'y'
            }
        };
        setLoader(true);
        dispatch({ type: TicketsActions.RAISE_NEW_TICKET, payload: formData });
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400 || Ticket_Response.statusCode === 200) {
            setLoader(false);
            resetField('TicketDescription');
            setShowRaiseTicketPopup(false);
            if (Type === 'Cake Order') {
                dispatch({ type: CakeOrderActions.GET_VENDOR_ORDER_DETAILS, payload: Id });
            } else if (Type === 'Hamper Order') {
                dispatch({ type: HamperOrderActions.GET_HAMPER_DETAILS, payload: Id });
            } else {
                dispatch({ type: ProductOrderActions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: Id });
            };
        } 
        // else if (Ticket_Response.statusCode === 200) {
        //     setLoader(false);
        //     resetField('TicketDescription');
        //     setShowRaiseTicketPopup(false);
        //     if (Type === 'Cake Order') {
        //         dispatch({ type: CakeOrderActions.GET_VENDOR_ORDER_DETAILS, payload: Id });
        //     } else if (Type === 'Hamper Order') {
        //         dispatch({ type: HamperOrderActions.GET_HAMPER_DETAILS, payload: Id });
        //     } else {
        //         dispatch({ type: ProductOrderActions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: Id });
        //     };
        // };
        // eslint-disable-next-line 
    }, [Ticket_Response, Type, Id]);

    useEffect(() => {
        if (Type === 'Cake Order') {
            (GetVendorOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        } else if (Type === 'Hamper Order') {
            (HamperDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        } else {
            (OtherProductOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        }
    }, [GetVendorOrderDetails.length, HamperDetails.length, OtherProductOrderDetails.length, Type]);
//
    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    {Type === 'Cake Order' ?
                        <CakeOrderDetails
                            GetVendorOrderDetails={GetVendorOrderDetails}
                            handleClose={handleClose}
                            Page={Page}
                            RaiseTicketPopup={RaiseTicketPopup}
                        /> :
                        Type === 'Hamper Order' ?
                            <HamperOrderDetails
                                HamperDetails={HamperDetails}
                                handleClose={handleClose}
                                Page={Page}
                                RaiseTicketPopup={RaiseTicketPopup}
                            /> :
                            <OtherProductOrderDetailsPage
                                OtherProductOrderDetails={OtherProductOrderDetails}
                                handleClose={handleClose}
                                Page={Page}
                                RaiseTicketPopup={RaiseTicketPopup}
                            />
                    }
                </div>
            }
            <Modal className="modal-min" show={showRaiseTicketPopup} onHide={handleCloseRaiseTicketPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleCloseRaiseTicketPopup}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Raise Ticket</h1>
                    <div>
                        <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                            <label htmlFor="validationCustom18"><b>Ticket Description</b> <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <textarea
                                    className={errors.TicketDescription ? 'form-control required-field text-capitalize' : 'form-control text-capitalize'}
                                    placeholder='Type here...'
                                    {...register('TicketDescription', { required: { value: true, message: 'required' } })}
                                />
                            </div>
                        </div>
                        <div>
                            <p className="text-success text-center intimation-message">This will be auto intimated to Vendor Helpdesk and Accounts</p>
                        </div>
                        {loader ?
                            <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSubmit(handleRaiseTicket)}
                                >
                                    Raise Ticket
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleCloseRaiseTicketPopup}
                                >
                                    Close
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};