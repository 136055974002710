import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPagination from '../../../../common/React-Pagination';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../../../common/SortingOrders';
import { float2D } from '../../../../../utils/Validation';

export default function DeliveredOrders(props) {

    const { CakeOrderList, HamperOrderList, OtherOrderList } = props;

    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(10);

    let [CurrentOrderList, setCurrentOrderList] = useState([]);
    let [OrderType, setOrderType] = useState('CakeOrders');

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
    ]);

    useEffect(() => {
        let CurrentList = [];
        if (OrderType === "CakeOrders") {
            if (CakeOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                CakeOrderList.filter(val => {
                    if (val.Status === "Delivered") {
                        CurrentList.push(val);
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
            }
        };
    }, [OrderType, CakeOrderList]);

    const handleOrderType = (e) => {
        setOrderType(OrderType = e.target.value);
        setSorting([{ table: 0, field: "OD", type: "D" }]);
        let CurrentList = [];
        if (OrderType === "CakeOrders") {
            if (CakeOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                CakeOrderList.filter(val => {
                    if (val.Status === "Delivered") {
                        CurrentList.push(val);
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
            };
        } else if (OrderType === "HamperOrders") {
            if (HamperOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                HamperOrderList.filter(val => {
                    if (val.Status === "Delivered") {
                        CurrentList.push(val);
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
            };
        } else if (OrderType === 'OtherProductsOrder') {
            if (OtherOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                OtherOrderList.filter(val => {
                    if (val.Status === "Delivered") {
                        CurrentList.push(val);
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
            };
        } else {
            return null;
        }
    };

    const indexOfFirstPost = (currentPage * postsPerPage) % CurrentOrderList?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = CurrentOrderList.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = CurrentOrderList;
        let result = SortingOrders({ type, field, filterData });
        setCurrentOrderList(result);
    };

    const ViewOrderDetails = (orders) => {
        if (orders.CakeName) {
            history.push('/Vendor-OrderDetails', { page: 'reports', OrderId: orders._id });
        } else if (orders.HampersName) {
            history.push('/vendor-hamper-order-details', { page: 'reports', OrderId: orders._id });
        } else if (orders.ProductName) {
            history.push('/vendor-product-order-details', { page: 'reports', Id: orders._id });
        } else {
            return null;
        }
    };

    return (
        <div className="ms-panel-body tabledata-background tab-pane-content ">
            <div className="table-responsive Scrollbar-Style">
                <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row ">
                        <div className="col-sm-12 col-md-6 ">
                            <div id="data-table-4_filter" className="dataTables_filter d-flex">
                                <label className="font-weight-bold pt-1">Select Order Type : </label>
                                <select
                                    name="OrderType"
                                    defaultValue={OrderType}
                                    className="form-control form-control-sm  ml-2"
                                    onChange={handleOrderType}
                                >
                                    <option value="CakeOrders">Cake Orders</option>
                                    <option value="OtherProductsOrder">Other Products Orders</option>
                                    <option value="HamperOrders">Hamper Orders</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-sm-12">
                            <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info">
                                <thead>
                                    <tr role="row">
                                        <th scope="col">Order ID</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                        <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                        <th scope="col">Order Status</th>
                                        <th scope="col">Price</th>
                                    </tr>
                                </thead>
                                <>
                                    {CurrentOrderList.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td colSpan={7} className='font-weight-bold no-records-found'>No Records Found</td>
                                            </tr>
                                        </tbody> :
                                        <tbody>
                                            {currentdata.map((order) =>
                                                <tr className='odd cursor' key={order._id} onClick={() => ViewOrderDetails(order)}>
                                                    <th className='id-column' scope="row">{order.Id}</th>
                                                    <td><span className={order.CakeName || order.HampersName || order.ProductName ? '' : 'font-weight-bold text-success'}>{order.CakeName ? order.CakeName : order.HampersName ? order.HampersName : order.ProductName ? order.ProductName : 'Customised Cake'}</span></td>
                                                    <td> {order.UserName}</td>
                                                    <td> {order.Created_On}</td>
                                                    <td>{order.DeliveryDate}</td>
                                                    <td><span className={(order.Status?.toUpperCase())?.replace(/ /g, '')}>{order.Status}</span></td>
                                                    {(order.CakeID && order?.Vendor_Price && order?.TypeOfCustomizedCake === 'Cakey') ?
                                                        <td className='sales-alignment'>{float2D(order?.Vendor_Total)}</td> :
                                                        (order.CakeID && order?.Vendor_Price && order?.TypeOfCustomizedCake === 'Vendor') ?
                                                            <td className='sales-alignment'>{order?.NewTotal ? float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax)) : (float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td> :
                                                            order.ProductID ?
                                                                <td className='sales-alignment'>{order?.NewTotal ? (float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax))) : (float2D(parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td> :
                                                                order.HamperID ?
                                                                    <td className='sales-alignment'>{float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst)))}</td> :
                                                                    <td className='sales-alignment'>{order?.NewTotal ? float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax)) : (float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    }
                                </>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {CurrentOrderList.length > postsPerPage &&
                <ReactPagination
                    paginate={paginate}
                    pageCount={Math.ceil(CurrentOrderList?.length / postsPerPage)}
                    currentPage={currentPage}
                    currentdata={currentdata}
                    totalPosts={CurrentOrderList.length}
                />
            }
        </div>
    )
};