import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { RiRefreshLine } from 'react-icons/ri';
import PageRefresh from '../../../common/PageRefresh';


function Breadcrumb(props) {

    const { handlePageRefresh } = props;

    const [changeIcon, setChangeIcon] = useState(false);

    const handleChangeIcon = (e) => {
        e.preventDefault();
        setChangeIcon(true);
        setTimeout(() => { setChangeIcon(false) }, 1000);
    };

    return (
        <Row>
            <Col md={10} sm={12}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Vendor Rejected Orders</li>
                    </ol>
                </nav>
            </Col>
            <Col md={2} sm={12} className='d-flex justify-content-end mt-2'>
                <div onClick={handleChangeIcon}>
                    {changeIcon ?
                        <div>
                            <button className='refresh-styles'><RiRefreshLine className="h5 mt-1" /></button>
                        </div> :
                        <PageRefresh handlePageRefresh={handlePageRefresh} />
                    }
                </div>
            </Col>
        </Row>
    );
};

export default Breadcrumb;