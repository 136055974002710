import React, { useEffect, useState } from 'react';
import { float2D } from '../../../../utils/Validation';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import Breadcrumb from '../Breadcrumb';

export default function TicketsDetails(props) {

    const { state, TicketDetails, handleShowOrderDetailsPopup, handleShowVendorDetailsPopup,
        OrderDetailsByTypeOfOrder, handleShowUserDetailsPopup } = props;

    let Price_Sent_To_Manager = TicketDetails?.Price_Sent_To_Manager;

    let [helpdeskVPriceTax, setHelpdeskVPriceTax] = useState(0);

    useEffect(() => {
        if (Price_Sent_To_Manager === "Yes" && OrderDetailsByTypeOfOrder?.HelpdeskV_Price) {
            let Weight;
            ((OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1])?.toLowerCase() === 'g') ? Weight = [parseFloat(OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) / 1000] :
                Weight = OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi);
            let Total = ((parseInt(OrderDetailsByTypeOfOrder.ItemCount || 1) * parseFloat(parseFloat(OrderDetailsByTypeOfOrder?.Vendor_Price) * JSON.parse(parseFloat(Weight[0]).toFixed(2)))) +
                parseFloat(OrderDetailsByTypeOfOrder?.ExtraCharges || 0)) - parseFloat(OrderDetailsByTypeOfOrder?.Discount || 0) + parseFloat(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice || 0);
            let Tax = parseFloat(Total) * parseFloat(OrderDetailsByTypeOfOrder?.Tax) / 100;
            setHelpdeskVPriceTax(Tax);
        };
    }, [OrderDetailsByTypeOfOrder, Price_Sent_To_Manager]);

    return (
        <>
            <div className="col-md-12">
                <Breadcrumb Page={state?.state?.Page} Tab={state?.state?.Tab} />
            </div>
            <div className="col-md-12">
                <div className="ms-panel tabledata-background">
                    <div className="ms-panel-header">
                        <h6>Ticket Details</h6>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel ms-panel-fh">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">TICKET INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                <tr>
                                    <th scope="row">Ticket ID</th>
                                    <td><b>{TicketDetails?.Id}</b></td>
                                </tr>
                                {TicketDetails?.Ticket_Status &&
                                    <tr>
                                        <th scope="row">Ticket Status</th>
                                        <td><span className={((TicketDetails?.Ticket_Status === 'Aborted') ? "CANCELLED" : (TicketDetails?.Ticket_Status?.toUpperCase())?.replace(/ /g, ''))}>{TicketDetails?.Ticket_Status}</span></td>
                                    </tr>
                                }
                                <tr>
                                    <th scope="row">Cause of Ticket</th>
                                    <td>{TicketDetails?.CauseOfTicket === 'Fully Customized Cake' ? 'Fully Customised Cake' :
                                        TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor' ? 'Customised Cake through Vendor' :
                                            TicketDetails?.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' ? 'Cakey Direct-Fully Customised Cake' :
                                                TicketDetails?.CauseOfTicket
                                    }</td>
                                </tr>
                                {TicketDetails?.IsVendorAvailable &&
                                    <tr>
                                        <th scope="row">Vendor Availability</th>
                                        <td><span className='text-danger'><b>{TicketDetails?.IsVendorAvailable === 'No Vendors' ? 'Vendors are not Available' : ''}</b></span></td>
                                    </tr>
                                }

                                {TicketDetails?.TypeOfMiscellaneous &&
                                    <tr>
                                        <th scope="row">Type of Miscellaneous</th>
                                        <td><b>{TicketDetails?.TypeOfMiscellaneous}</b></td>
                                    </tr>
                                }
                                {TicketDetails?.TypeOfOrder &&
                                    <tr>
                                        <th scope="row">Type of Order</th>
                                        <td>{TicketDetails?.TypeOfOrder === 'Customized Cake Order' ? 'Customised Cake Order' : TicketDetails?.TypeOfOrder}</td>
                                    </tr>
                                }
                                {OrderDetailsByTypeOfOrder?.CakeSubType &&
                                    <tr>
                                        <th scope="row">Product SubType</th>
                                        <td><b>{OrderDetailsByTypeOfOrder?.CakeSubType === 'macaroons' ? 'Macarons' : OrderDetailsByTypeOfOrder?.CakeSubType}</b></td>
                                    </tr>
                                }

                                {TicketDetails?.Order_ID &&
                                    <tr>
                                        <th scope="row">Order ID</th>
                                        <td className='cursor' onClick={handleShowOrderDetailsPopup}>
                                            <span className='text-primary mr-2 '> Click to View</span>
                                            <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.Order_ID}</button>
                                        </td>
                                        {/* <td>{TicketDetails?.Order_ID}</td> */}
                                    </tr>
                                }
                                {/* {TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' &&
                                    <tr>
                                        <th scope="row">Payment Status</th>
                                        <td><b>{OrderDetailsByTypeOfOrder?.PaymentStatus}</b></td>
                                    </tr>
                                } */}
                                {TicketDetails?.Order_Status &&
                                    <tr>
                                        <th scope="row">Order Status</th>
                                        <td className={'font-weight-bold'}>{OrderDetailsByTypeOfOrder?.Status === 'Cancelled' ? OrderDetailsByTypeOfOrder?.Status : TicketDetails?.Order_Status}</td>
                                    </tr>
                                }
                                {TicketDetails?.CauseOfTicket === 'Change Requests' &&
                                    <>
                                        {TicketDetails?.Flavour?.length > 0 &&
                                            <tr>
                                                <th scope="row">Requested Flavour</th>
                                                <td><FlavourList FlavoursList={TicketDetails?.Flavour} /></td>
                                            </tr>
                                        }
                                        {TicketDetails?.Shape &&
                                            <tr>
                                                <th scope="row">Requested Shape</th>
                                                <td>{`${TicketDetails?.Shape?.Name} - Additional Rs.${TicketDetails?.Shape?.Price}/Kg`}</td>
                                            </tr>
                                        }
                                    </>
                                }
                                {(TicketDetails?.CauseOfTicket === 'Cakey Direct-General Order' && TicketDetails?.Weight) &&
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{TicketDetails?.Weight}</td>
                                    </tr>
                                }
                                {TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor' &&
                                    <>
                                        {TicketDetails?.Price &&
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td>{float2D(TicketDetails?.Price)}</td>
                                            </tr>
                                        }
                                        {TicketDetails?.Total &&
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td>{float2D(TicketDetails?.Total)}</td>
                                            </tr>
                                        }
                                    </>
                                }
                                {TicketDetails?.Product_ID &&
                                    <tr>
                                        <th scope="row">Product ID</th>
                                        <td>{TicketDetails?.Product_ID}</td>
                                    </tr>
                                }
                                {TicketDetails?.Vendor_ID &&
                                    <tr>
                                        <th scope="row">Vendor ID</th>
                                        <td className='cursor' onClick={handleShowVendorDetailsPopup}>
                                            <span className='text-primary mr-2 '> Click to View</span>
                                            <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.Vendor_ID}</button>

                                        </td>
                                        {/* <td>{TicketDetails?.Vendor_ID}</td> */}
                                    </tr>
                                }
                                {TicketDetails?.User_ID &&
                                    <tr>
                                        <th scope="row">Customer ID</th>
                                        <td className='cursor' onClick={handleShowUserDetailsPopup}>
                                            <span className='text-primary mr-2 '> Click to View</span>
                                            <button className='btn btn-sm btn-success mt-0'>{TicketDetails?.User_ID}</button>

                                        </td>
                                        {/* <td>{TicketDetails?.Vendor_ID}</td> */}
                                    </tr>
                                }
                                {((TicketDetails?.CauseOfTicket === 'Vendor Request' || TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor') &&
                                    TicketDetails?.VendorName) &&
                                    <tr>
                                        <th scope="row">Vendor Name</th>
                                        <td>{TicketDetails?.VendorName}</td>
                                    </tr>
                                }

                                <tr>
                                    <th scope="row">Ticket Raised On</th>
                                    <td>{TicketDetails?.Ticket_Raised_On}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Ticket Raised By</th>
                                    <td>{TicketDetails?.Ticket_Raised_By?.Name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {TicketDetails?.HelpdeskC_Accepted_By &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">CUSTOMER HELPDESK</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Ticket Accepted By</th>
                                        <td>{TicketDetails?.HelpdeskC_Accepted_By?.Name} - {TicketDetails?.HelpdeskC_Accepted_By?.Email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Ticket Accepted On</th>
                                        <td>{TicketDetails?.HelpdeskC_Accepted_On}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {TicketDetails?.HelpdeskV_Accepted_By &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">VENDOR HELPDESK</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Ticket Accepted By</th>
                                        <td>{TicketDetails?.HelpdeskV_Accepted_By?.Name} - {TicketDetails?.HelpdeskV_Accepted_By?.Email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Ticket Accepted On</th>
                                        <td>{TicketDetails?.HelpdeskV_Accepted_On}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {(TicketDetails?.HelpdeskV_Input || TicketDetails?.Ticket_Approved_Status || OrderDetailsByTypeOfOrder?.Vendor_Price ||
                TicketDetails?.Refund_Status || TicketDetails?.Payment_Intimated_On || TicketDetails?.Customer_Approved_Status ||
                TicketDetails?.Customer_Approved_Status || TicketDetails?.Customer_Paid_Status || TicketDetails?.PaymentType ||
                TicketDetails?.Vendor_Approved_Status || TicketDetails?.HelpdeskV_Remarks || TicketDetails?.Ticket_Closed_On ||
                (TicketDetails?.Ticket_Solutions && TicketDetails?.Ticket_Solutions?.length > 0) || TicketDetails?.HelpdeskC_Notes) &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">TICKET PROCESS</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    {TicketDetails?.HelpdeskV_Input &&
                                        <tr>
                                            <th scope="row">Vendor Helpdesk Input</th>
                                            <td>{TicketDetails?.HelpdeskV_Input}</td>
                                        </tr>
                                    }
                                    {TicketDetails?.Ticket_Approved_Status &&
                                        <tr>
                                            <th scope="row">Ticket Approved Status</th>
                                            <td><span className={(TicketDetails?.Ticket_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Ticket_Approved_Status}</span></td>
                                        </tr>
                                    }
                                    {TicketDetails?.Ticket_Approved_Status_Updated_On &&
                                        <tr>
                                            <th scope="row">Ticket Approved Status Updated On</th>
                                            <td>{TicketDetails?.Ticket_Approved_Status_Updated_On}</td>
                                        </tr>
                                    }
                                    {TicketDetails?.Ticket_Approved_Status_Updated_By &&
                                        <tr>
                                            <th scope="row">Ticket Approved Status Updated By</th>
                                            <td>{TicketDetails?.Ticket_Approved_Status_Updated_By?.Email} - {TicketDetails?.Ticket_Approved_Status_Updated_By?.TypeOfUser}</td>
                                        </tr>
                                    }
                                    {(TicketDetails?.Cancellation_Charges >= 0 && TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' && TicketDetails?.Ticket_Approved_Status === 'Approved') ?
                                        <tr>
                                            <th scope="row">Cancellation Charges</th>
                                            <td className='font-weight-bold'>{float2D(TicketDetails?.Cancellation_Charges)}</td>
                                        </tr> : <></>
                                    }
                                    {(TicketDetails?.Difference_In_Price >= 0 && TicketDetails?.CauseOfTicket === 'Change Requests' && TicketDetails?.Ticket_Approved_Status === 'Approved') &&
                                        <tr>
                                            <th scope="row">Difference in Price</th>
                                            <td className='font-weight-bold'> {float2D(TicketDetails?.Difference_In_Price)}</td>
                                        </tr>
                                    }
                                    {((TicketDetails?.Order_Status === 'Sent' || TicketDetails?.Price_Sent_To_Manager || OrderDetailsByTypeOfOrder?.Vendor_Price) && TicketDetails?.CauseOfTicket !== 'Order Cancellation by Vendor') &&
                                        <>
                                            <tr>
                                                <th scope="row">Vendor Price per Kg</th>
                                                <td className='font-weight-bold'> {float2D(OrderDetailsByTypeOfOrder?.Vendor_Price)}</td>
                                            </tr>
                                            {OrderDetailsByTypeOfOrder?.Weight &&
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Extra Charges</th>
                                                <td> {float2D(OrderDetailsByTypeOfOrder?.ExtraCharges)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td> {float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                                {/* <td>{float2D(0)}</td> */}
                                            </tr>
                                            {OrderDetailsByTypeOfOrder?.DeliveryCharge &&
                                                <tr>
                                                    <th scope="row">Delivery Charge</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Vendor Total</th>
                                                <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.Vendor_Total)}</td>
                                            </tr>
                                        </>
                                    }
                                    {(TicketDetails?.Refund_Status && (TicketDetails?.CauseOfTicket !== 'Order Cancellation by Vendor')) &&
                                        <>
                                            {TicketDetails?.Total_Amount &&
                                                <tr>
                                                    <th scope="row">Total Amount</th>
                                                    <td>{float2D(TicketDetails?.Total_Amount)}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Refund_Amount && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Amount</th>
                                                    <td>{float2D(TicketDetails?.Refund_Amount)}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.RefundType && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Type</th>
                                                    <td>{TicketDetails?.RefundType}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Refund_Status && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Status</th>
                                                    <td className='font-weight-bold'>{TicketDetails?.Refund_Status}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.RefundType === 'Coupon' &&
                                                <>
                                                    <tr>
                                                        <th scope="row">Coupon Type</th>
                                                        <td>{TicketDetails?.CouponType}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Coupon Value</th>
                                                        <td>{TicketDetails?.CouponType === 'Percentage' ? TicketDetails?.CouponValue + '%' : float2D(TicketDetails?.CouponValue)}</td>
                                                    </tr>
                                                </>
                                            }
                                            {(TicketDetails?.Refund_On && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund On</th>
                                                    <td>{TicketDetails?.Refund_On}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Refund_By && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund By</th>
                                                    <td>{TicketDetails?.Refund_By?.Name} - {TicketDetails?.Refund_By?.Email}</td>
                                                </tr>
                                            }

                                        </>
                                    }
                                    {(TicketDetails?.Payment_Intimated_On || TicketDetails?.Customer_Approved_Status) &&
                                        <>
                                            <tr>
                                                <th scope="row">Customer Approved Status</th>
                                                <td><span className={(TicketDetails?.Customer_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Customer_Approved_Status}</span></td>
                                            </tr>
                                            {TicketDetails?.Customer_Paid_Status &&
                                                <tr>
                                                    <th scope="row">Customer Paid Status</th>
                                                    <td>{TicketDetails?.Customer_Paid_Status}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.PaymentType &&
                                                <tr>
                                                    <th scope="row">Payment Type</th>
                                                    <td>{TicketDetails?.PaymentType}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Payment_Intimated_By &&
                                                <tr>
                                                    <th scope="row">Payment Link Sent By</th>
                                                    <td>{TicketDetails?.Payment_Intimated_By?.Name} - {TicketDetails?.Payment_Intimated_By?.Email}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Payment_Intimated_On &&
                                                <tr>
                                                    <th scope="row">Payment Link Sent On</th>
                                                    <td>{TicketDetails?.Payment_Intimated_On}</td>
                                                </tr>
                                            }
                                        </>
                                    }
                                    {(TicketDetails?.CauseOfTicket === 'Fully Customized Cake' && TicketDetails?.Customer_Approved_Status === 'Approved') &&
                                        <>
                                            <tr>
                                                <th scope="row">Customer Approved Status</th>
                                                <td><span className={(TicketDetails?.Customer_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Customer_Approved_Status}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Paid Status</th>
                                                <td>{TicketDetails?.Customer_Paid_Status}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{TicketDetails?.PaymentType}</td>
                                            </tr>
                                        </>
                                    }
                                    {TicketDetails?.Vendor_Approved_Status &&
                                        <tr>
                                            <th scope="row">Vendor Approved Status</th>
                                            <td><span className={(TicketDetails?.Vendor_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Vendor_Approved_Status}</span></td>
                                        </tr>
                                    }
                                    {TicketDetails?.Vendor_Approved_Status_Updated_By &&
                                        <tr>
                                            <th scope="row">Vendor Approved Status Updated By</th>
                                            <td>{TicketDetails?.Vendor_Approved_Status_Updated_By?.Name} - {TicketDetails?.Vendor_Approved_Status_Updated_By?.Email}</td>
                                        </tr>
                                    }
                                    {TicketDetails?.Vendor_Approved_Status_Updated_On &&
                                        <tr>
                                            <th scope="row">Vendor Approved Status Updated On</th>
                                            <td>{TicketDetails?.Vendor_Approved_Status_Updated_On}</td>
                                        </tr>
                                    }
                                    {TicketDetails?.HelpdeskV_Remarks &&
                                        <tr>
                                            <th scope="row">Vendor Helpdesk Remarks</th>
                                            <td>{TicketDetails?.HelpdeskV_Remarks}</td>
                                        </tr>
                                    }
                                    {(TicketDetails?.Ticket_Solutions && TicketDetails?.Ticket_Solutions?.length > 0) &&
                                        <TicketSolution Ticket_Solutions={TicketDetails?.Ticket_Solutions} />
                                    }
                                    {TicketDetails?.HelpdeskC_Notes &&
                                        <tr>
                                            <th scope="row">Customer Helpdesk Notes</th>
                                            <td>{TicketDetails?.HelpdeskC_Notes}</td>
                                        </tr>
                                    }
                                    {TicketDetails?.Ticket_Status === 'Closed' &&
                                        <>
                                            <tr>
                                                <th scope="row">Ticket Closed By</th>
                                                <td>{TicketDetails?.Ticket_Closed_By?.Name} - {TicketDetails?.Ticket_Closed_By?.Email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ticket Closed On</th>
                                                <td>{TicketDetails?.Ticket_Closed_On}</td>
                                            </tr>
                                        </>
                                    }
                                    {TicketDetails?.Ticket_Status === 'Aborted' &&
                                        <>
                                            <tr>
                                                <th scope="row">Reason for Abort the Ticket</th>
                                                <td><span className="long-line-break">{TicketDetails?.AbortReason}</span></td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Ticket Aborted By</th>
                                                <td>{TicketDetails?.Updated_By?.Name} - {TicketDetails?.Updated_By?.Email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ticket Aborted On</th>
                                                <td>{TicketDetails?.Updated_On}</td>
                                            </tr>
                                        </>

                                    }
                                    {TicketDetails?.Manager_Approved_Status &&
                                        <>
                                            <tr>
                                                <th scope="row">Manager Approved Status</th>
                                                <td><span className={(TicketDetails?.Manager_Approved_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Manager_Approved_Status}</span></td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Manager Approved Status Updated On</th>
                                                <td>{TicketDetails?.Manager_Approved_Status_Updated_On}</td>
                                            </tr>
                                        </>
                                    }
                                    {TicketDetails?.HelpdeskC_Remarks &&
                                        <tr>
                                            <th scope="row">Customer Helpdesk Remarks</th>
                                            <td>{TicketDetails?.HelpdeskC_Remarks}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {(TicketDetails?.Price_Sent_To_Manager === "Yes" && OrderDetailsByTypeOfOrder?.HelpdeskV_Price) &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">VENDOR HELPDESK PRICE</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Vendor Helpdesk Price per Kg</th>
                                        <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.HelpdeskV_Price)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Extra Charges</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                        {/* <td>{float2D(0)}</td> */}
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                    </tr>
                                    {OrderDetailsByTypeOfOrder?.Margin?.Percentage?.toString() ?
                                        <>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                <td>{float2D(parseFloat(helpdeskVPriceTax) / 2)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                <td>{float2D(parseFloat(helpdeskVPriceTax) / 2)}</td>
                                            </tr>
                                        </> :
                                        <>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Gst)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Sgst)}</td>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        <th scope="row">Vendor Helpdesk Total</th>
                                        <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.HelpdeskV_Total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {((TicketDetails?.CauseOfTicket !== 'Order Cancellation by Vendor' && (TicketDetails?.CauseOfTicket !== 'Order Cancellation by Customer') && TicketDetails?.CauseOfTicket !== 'Cancellation Within 15mins') && ((TicketDetails?.Final_Price_Given_By_Manager === "Yes" && OrderDetailsByTypeOfOrder?.Price) ||
                OrderDetailsByTypeOfOrder?.Price)) &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">FINAL PRICE</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    {(OrderDetailsByTypeOfOrder?.Margin && (OrderDetailsByTypeOfOrder?.Above5KG === 'y')) &&
                                        <tr>
                                            <th scope="row">Margin per Kg</th>
                                            <td>{OrderDetailsByTypeOfOrder?.Margin?.Percentage}% - Rs. {float2D(OrderDetailsByTypeOfOrder?.Margin?.Amount)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Price per Kg</th>
                                        <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.Price)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Extra Charges</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">CGST</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Gst)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">SGST</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Sgst)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.Total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {((TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' || TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins') && TicketDetails?.TypeOfOrder === 'Cake Order') &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">FINAL PRICE</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Product Count</th>
                                        <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price per Kg</th>
                                        <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewPrice) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewPrice) : float2D(OrderDetailsByTypeOfOrder?.Price))}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Extra Charges</th>
                                        {OrderDetailsByTypeOfOrder?.ExtraCharges === '0' || OrderDetailsByTypeOfOrder?.ExtraCharges === undefined ?
                                            <td>0.00</td> :
                                            <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewExtraCharges) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewExtraCharges) : float2D(OrderDetailsByTypeOfOrder?.ExtraCharges))}</span></td>
                                        }
                                    </tr>
                                    {(OrderDetailsByTypeOfOrder?.NewTopperCharges || OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice) &&
                                        <tr>
                                            <th scope="row">Topper Price</th>
                                            <td>{(float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) : float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                        </tr>
                                    }
                                    {/* {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' &&
                                        <tr>
                                            <th scope="row">Margin</th>
                                            <td><span>{float2D(OrderDetailsByTypeOfOrder?.FinalMargin)}</span></td>
                                        </tr>

                                    } */}
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewDiscount) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewDiscount) : float2D(OrderDetailsByTypeOfOrder?.Discount))}</span></td>
                                    </tr>
                                    {(OrderDetailsByTypeOfOrder?.CouponValue && !OrderDetailsByTypeOfOrder?.NewTotal) &&
                                        <tr>
                                            <th scope="row">Coupon Value</th>
                                            <td>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewDeliveryCharges) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewDeliveryCharges) : float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge))}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">CGST</th>
                                        <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewTax) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTax / 2) : float2D(OrderDetailsByTypeOfOrder?.Gst))}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">SGST</th>
                                        <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewTax) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTax / 2) : float2D(OrderDetailsByTypeOfOrder?.Sgst))}</span></td>
                                    </tr>

                                    <tr>
                                        <th scope="row">Total</th>
                                        <td><span><b>{((float2D(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTotal) : float2D(OrderDetailsByTypeOfOrder?.Total))}</b></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {(((TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' || TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins') && TicketDetails?.TypeOfOrder === 'Product Order')) &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">PRICE INFO</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    {(OrderDetailsByTypeOfOrder?.CakeSubType !== "macaroons" &&
                                        OrderDetailsByTypeOfOrder?.CakeSubType !== "Cup Cake" &&
                                        (OrderDetailsByTypeOfOrder?.CakeSubType !== "Cake Dessert")) &&
                                        <tr>
                                            <th scope="row">Product Count</th>
                                            <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                        </tr>
                                    }
                                    <>
                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                                            <>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td>{(parseFloat(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerKg?.PricePerKg) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerKg?.PricePerKg) : float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg)}</td>
                                                </tr>
                                            </>
                                        }
                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                            <>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price per Unit</th>
                                                    <td>{(parseFloat(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerUnit?.PricePerUnit) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerUnit?.PricePerUnit) : float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</td>
                                                    {/* <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</td> */}
                                                </tr>
                                            </>
                                        }
                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                            <>
                                                <tr>
                                                    <th scope="row">Piece per Box</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.Piece}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price per Box</th>
                                                    <td>{(parseFloat(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerBox?.PricePerBox) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewProductMinWeightPerBox?.PricePerBox) : float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</td>
                                                    {/* <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</td> */}
                                                </tr>
                                            </>

                                        }
                                        {(OrderDetailsByTypeOfOrder?.NewTopperCharges || OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice) &&
                                            <tr>
                                                <th scope="row">Topper Price</th>
                                                <td>{(float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) : float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th scope="row">Discount</th>
                                            <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewDiscount) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewDiscount) : float2D(OrderDetailsByTypeOfOrder?.Discount))}</span></td>
                                        </tr>
                                        {(OrderDetailsByTypeOfOrder?.CouponValue && !OrderDetailsByTypeOfOrder?.NewTotal) &&
                                            <tr>
                                                <th scope="row">Coupon Value</th>
                                                <td>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th scope="row">Delivery Charge</th>
                                            <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewDeliveryCharges) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewDeliveryCharges) : float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge))}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">CGST</th>
                                            <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewTax) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTax / 2) : float2D(OrderDetailsByTypeOfOrder?.Gst))}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">SGST</th>
                                            <td><span>{((float2D(OrderDetailsByTypeOfOrder?.NewTax) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTax / 2) : float2D(OrderDetailsByTypeOfOrder?.Sgst))}</span></td>
                                        </tr>

                                        <tr>
                                            <th scope="row">Total</th>
                                            <td><span><b>{((float2D(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ? float2D(OrderDetailsByTypeOfOrder?.NewTotal) : float2D(OrderDetailsByTypeOfOrder?.Total))}</b></span></td>
                                        </tr>
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {((TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' || TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins') && TicketDetails?.TypeOfOrder === 'Hamper Order') &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">FINAL PRICE</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Product Count</th>
                                        <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Price)}</td>
                                    </tr>
                                    {(OrderDetailsByTypeOfOrder?.CouponValue) &&
                                        <tr>
                                            <th scope="row">Coupon Value</th>
                                            <td>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">CGST</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Gst)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">SGST</th>
                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Sgst)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td className='font-weight-bold'>{float2D(OrderDetailsByTypeOfOrder?.Total)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {((TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor')) &&
                // ||(TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' && TicketDetails?.TypeOfOrder === 'Product Order')) &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">PRICE INFO</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    {(OrderDetailsByTypeOfOrder?.CakeSubType !== "macaroons" &&
                                        OrderDetailsByTypeOfOrder?.CakeSubType !== "Cup Cake" &&
                                        (OrderDetailsByTypeOfOrder?.CakeSubType !== "Cake Dessert")) &&
                                        <tr>
                                            <th scope="row">Product Count</th>
                                            <td>{OrderDetailsByTypeOfOrder?.ItemCount}</td>
                                        </tr>
                                    }
                                    <>
                                        {TicketDetails?.TypeOfOrder === 'Cake Order' &&
                                            <>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                                </tr>
                                                {OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey' ?
                                                    <>
                                                        <tr>
                                                            <th scope="row">Existing Price per Kg</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Vendor_Price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Margin</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Margin?.Amount)}</td>
                                                        </tr>
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <th scope="row">Existing Price per Kg</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Price)}</td>
                                                        </tr>

                                                    </>

                                                }
                                                {TicketDetails?.NewPrice &&
                                                    <tr>
                                                        <th scope="row">New Price per Kg</th>
                                                        <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                    </tr>
                                                }

                                            </>
                                        }

                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Brownie" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cookies" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Chocolates" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Tea Cake' ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === 'Cheese Cake') &&
                                            <>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') ? 'Existing Price per Kg' : 'Price per Kg'}</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerKg?.PricePerKg)}</td>
                                                </tr>
                                                {TicketDetails?.NewPrice &&
                                                    <tr>
                                                        <th scope="row">New Price per Kg</th>
                                                        <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "Cake Dessert") &&
                                            <>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.ProductCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') ? 'Existing Price per Unit' : 'Price per Unit'}</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerUnit?.PricePerUnit)}</td>
                                                </tr>
                                                {TicketDetails?.NewPrice &&
                                                    <tr>
                                                        <th scope="row">New Price per Unit</th>
                                                        <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                    </tr>
                                                }
                                            </>
                                        }
                                        {(OrderDetailsByTypeOfOrder?.CakeSubType === "macaroons" ||
                                            OrderDetailsByTypeOfOrder?.CakeSubType === "Cup Cake") &&
                                            <>
                                                <tr>
                                                    <th scope="row">Piece per Box</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.Piece}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.ProductCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">{(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') ? 'Existing Price per Box' : 'Price per Box'}</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.ProductMinWeightPerBox?.PricePerBox)}</td>
                                                </tr>
                                                {TicketDetails?.NewPrice &&
                                                    <tr>
                                                        <th scope="row">New Price per Box</th>
                                                        <td>{float2D(TicketDetails?.NewPrice)}</td>
                                                    </tr>
                                                }
                                            </>

                                        }
                                        {((TicketDetails?.TypeOfOrder === 'Cake Order' || TicketDetails?.TypeOfOrder === 'Product Order') && TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') &&
                                            <>
                                                <>
                                                    {OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice &&
                                                        <tr>
                                                            <th scope="row">Existing Topper Charges</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                        </tr>
                                                    }
                                                    {OrderDetailsByTypeOfOrder?.ExtraCharges &&
                                                        <tr>
                                                            <th scope="row">Existing Extra Charges</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.ExtraCharges)}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <th scope="row">Existing Discount</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                                    </tr>
                                                    {OrderDetailsByTypeOfOrder?.CouponValue &&
                                                        <tr>
                                                            <th scope="row">Coupon Value</th>
                                                            <td><b>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</b></td>
                                                        </tr>
                                                    }

                                                    {OrderDetailsByTypeOfOrder?.DeliveryCharge &&
                                                        <tr>
                                                            <th scope="row">Existing Delivery Charges</th>
                                                            <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                        </tr>
                                                    }
                                                    <tr>
                                                        <th scope="row">Existing CGST</th>
                                                        <td>{float2D((OrderDetailsByTypeOfOrder?.Gst))}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Existing SGST</th>
                                                        <td>{float2D((OrderDetailsByTypeOfOrder?.Sgst))}</td>
                                                    </tr>
                                                </>

                                            </>
                                        }
                                        {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') &&
                                            <>
                                                {(OrderDetailsByTypeOfOrder?.NewTopperCharges || OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice) &&
                                                    <tr>
                                                        <th scope="row">Topper Price</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) ? float2D(OrderDetailsByTypeOfOrder?.NewTopperCharges) : float2D(OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td>{float2D(OrderDetailsByTypeOfOrder?.Discount)}</td>
                                                </tr>
                                                {OrderDetailsByTypeOfOrder?.DeliveryCharge &&
                                                    <tr>
                                                        <th scope="row">Delivery Charges</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.DeliveryCharge)}</td>
                                                    </tr>
                                                }
                                                {OrderDetailsByTypeOfOrder?.CouponValue &&
                                                    <tr>
                                                        <th scope="row">Coupon Value</th>
                                                        <td>{float2D(OrderDetailsByTypeOfOrder?.CouponValue)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">CGST</th>
                                                    <td>{float2D((OrderDetailsByTypeOfOrder?.Gst))}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">SGST</th>
                                                    <td>{float2D((OrderDetailsByTypeOfOrder?.Sgst))}</td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <th scope="row">{(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') ? 'Existing Total' : 'Total'}</th>
                                            <td><b>{float2D(OrderDetailsByTypeOfOrder?.Total)}</b></td>
                                        </tr>

                                    </>
                                    {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' && TicketDetails?.CostDifference &&
                                        TicketDetails?.FinalCost && TicketDetails?.VendorCallConversation && TicketDetails?.NewExtraCharges &&
                                        TicketDetails?.NewTopperCharges && TicketDetails?.NewTax && TicketDetails?.NewDeliveryCharges &&
                                        TicketDetails?.NewDiscount
                                    ) &&
                                        <>
                                            <tr>
                                                <th scope="row">New Total</th>
                                                <td><b>{float2D(TicketDetails?.FinalCost)}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Difference in Total</th>
                                                <td><b>{float2D((parseFloat(OrderDetailsByTypeOfOrder?.Total)) + (parseFloat(OrderDetailsByTypeOfOrder?.CouponValue || 0)) - parseFloat(TicketDetails?.FinalCost))}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Call Conversation</th>
                                                <td>{TicketDetails?.VendorCallConversation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">New Extra Charges</th>
                                                <td>{float2D(TicketDetails?.NewExtraCharges)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">New Topper Charges</th>
                                                <td>{float2D(TicketDetails?.NewTopperCharges)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">New Discount</th>
                                                <td>{float2D(TicketDetails?.NewDiscount)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">New Delivery Charges</th>
                                                <td>{float2D(TicketDetails?.NewDeliveryCharges)}</td>
                                            </tr>

                                            <tr>
                                                <th scope="row">New CGST</th>
                                                <td>{float2D((TicketDetails?.NewTax) / 2)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">New SGST</th>
                                                <td>{float2D((TicketDetails?.NewTax) / 2)}</td>
                                            </tr>
                                            {(TicketDetails?.Refund_Amount && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Amount</th>
                                                    <td>{float2D(TicketDetails?.Refund_Amount)}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.RefundType && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Type</th>
                                                    <td>{TicketDetails?.RefundType}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.RefundType === 'Coupon' && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <>
                                                    <tr>
                                                        <th scope="row">Coupon Type</th>
                                                        <td>{TicketDetails?.CouponType}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Coupon Value</th>
                                                        <td>{TicketDetails?.CouponType === 'Percentage' ? TicketDetails?.CouponValue + '%' : 'Rs. ' + float2D(TicketDetails?.CouponValue)}</td>
                                                    </tr>
                                                </>

                                            }
                                            {(TicketDetails?.Refund_Status && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund Status</th>
                                                    <td className='font-weight-bold'>{TicketDetails?.Refund_Status}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Refund_On && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund On</th>
                                                    <td>{TicketDetails?.Refund_On}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Refund_By && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') &&
                                                <tr>
                                                    <th scope="row">Refund By</th>
                                                    <td>{TicketDetails?.Refund_By?.Name} - {TicketDetails?.Refund_By?.Email}</td>
                                                </tr>
                                            }
                                        </>

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            {TicketDetails?.Final_Solution &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">FINAL SOLUTION</h4>
                            <hr />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <div>
                                        <span className='font-weight-bold mr-1'>Solution: </span>
                                        <span>{TicketDetails?.Final_Solution?.Solution}</span>
                                    </div>
                                    <div>
                                        <span className='font-weight-bold mr-1 mt-2'>Entered By: </span>
                                        <span> {TicketDetails?.Final_Solution?.Solution_Entered_By?.Name} - {TicketDetails?.Final_Solution?.Solution_Entered_By?.Email}</span>
                                    </div>
                                    <span className='font-weight-bold mr-1 mt-2'>Entered On: </span>
                                    <span> {TicketDetails?.Final_Solution?.Solution_Entered_On}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

function TicketSolution({ Ticket_Solutions }) {
    return (
        <div>
            <hr />
            {Ticket_Solutions.map((val, i) =>
                <div className="col-xl-12 col-md-12" key={i}>
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <div>
                                <span className='font-weight-bold mr-1'>Solution: </span>
                                <span>{val.Solution}</span>
                            </div>
                            <div>
                                <span className='font-weight-bold mr-1 mt-2'>Entered By: </span>
                                <span> {val.Solution_Entered_By?.Name} - {val.Solution_Entered_By?.Email}</span>
                            </div>
                            <span className='font-weight-bold mr-1 mt-2'>Entered On: </span>
                            <span> {val.Solution_Entered_On}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};