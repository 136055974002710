import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/VendorOrderList/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import ReactPagination from '../../common/React-Pagination';

function Content() {

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [selectedDate, setSelectedDate] = useState();

    const { LoginSessionOrders } = useSelector(state => state.VendorOrderReducer);
    // const List = useSelector(state => state.VendorOrderReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_VENDOR_LOGIN_SESSION_ORDERS });
        setSelectedDate(moment(new Date()).format('YYYY-MM-DD'));
        // eslint-disable-next-line 
    }, []);

    //for get search input values
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //for validate the list is empty or not
    useEffect(() => {
        if (LoginSessionOrders?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            // eslint-disable-next-line
            setFilteredData(LoginSessionOrders.reverse().filter(val => {
                if (moment(moment(selectedDate, 'YYYY-MM-DD')).format('DD-MM-YYYY') === moment(moment(val.Login_At, 'DD-MM-YYYY hh:mm A')).format('DD-MM-YYYY')) {
                    if (search === '') {
                        return val;
                    } else if ((val.VendorID && val.VendorID.toLowerCase().includes(search)) ||
                        (val.Login_At && val.Login_At.toLowerCase().includes(search)) ||
                        (val.Logout_At && val.Logout_At.toLowerCase().includes(search))
                    ) {
                        return val;
                    } else {
                        return null;
                    }
                } else {
                    setFilteredData([]);
                }
            }));
        };
    }, [LoginSessionOrders, search, selectedDate]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        setSelectedDate(moment(new Date()).format('YYYY-MM-DD'));
        //eslint-disable-next-line
        setFilteredData(LoginSessionOrders.reverse().filter(val => {
            if (moment().format('DD-MM-YYYY') === moment(moment(val.Login_At, 'DD-MM-YYYY hh:mm A')).format('DD-MM-YYYY')) {
                if (search === '') {
                    return val;
                } else if ((val.VendorID && val.VendorID.toLowerCase().includes(search)) ||
                    (val.Login_At && val.Login_At.toLowerCase().includes(search)) ||
                    (val.Logout_At && val.Logout_At.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            } else {
                setFilteredData([]);
            }
        }));
    };

    //loading
    useEffect(() => {
        if (LoginSessionOrders?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true)
        }
    }, [LoginSessionOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Vendor Login History</h6>
                                    <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer date-selector w-25">
                                        <input
                                            type='date'
                                            maxLength={4}
                                            max={moment().format('YYYY-MM-DD')}
                                            className='form-control'
                                            value={selectedDate || ''}
                                            onChange={(e) => setSelectedDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-4_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table w-100 thead-primary dataTable no-footer" role="grid" >
                                                        <thead>
                                                            <tr>
                                                                <th>Vendor ID</th>
                                                                <th>Vendor Name</th>
                                                                <th>Login At</th>
                                                                <th>Logout At</th>
                                                                <th>No Of Orders</th>
                                                                <th>Total Value of Orders</th>
                                                                <th>Delivered Orders</th>
                                                                <th>Pending Orders</th>
                                                                <th>Cancelled Orders by Customers</th>
                                                                <th>Cancelled Orders by Vendors</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata?.map((user) =>
                                                                    <tr key={user._id} >
                                                                        <th className='id-column' scope="row">{user.Vendor_ID}</th>
                                                                        <td>{user.VendorName}</td>
                                                                        <td>{user.Login_At}</td>
                                                                        <td>{user.Logout_At}</td>
                                                                        <td>{user.No_Of_Orders}</td>
                                                                        <td>{user.Total_Value_Of_Orders}</td>
                                                                        <td>{user.Delivered_Orders}</td>
                                                                        <td>{user.Pending_Orders}</td>
                                                                        <td>{user.Cancelled_Orders_By_User}</td>
                                                                        <td>{user.Cancelled_Orders_By_Vendor}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={10} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Content;