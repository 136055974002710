import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CakeOrderActions from '../../../redux/VendorOrderList/actions';
import HamperOrderActions from '../../../redux/HampersOrder/actions';
import ProductOrderActions from '../../../redux/OtherProducts/actions';
import Preloader from '../../layouts/Preloader';
import CakeOrderDetails from './CakeOrderDetails';
import HamperOrderDetails from './HamperOrderDetails';
import OtherProductOrderDetailsPage from './OtherProductOrderDetails';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const [fetchSuccess, setFetchSuccess] = useState(false);

    let Type = state.state.Type;
    let Id = state.state.Id;

    const { GetVendorOrderDetails } = useSelector(state => state.VendorOrderReducer);

    const { OtherProductOrderDetails } = useSelector(state => state.OtherProductReducer);

    const { HamperDetails } = useSelector(state => state.HamperOrderReducer);

    useEffect(() => {
        if (Type === 'Cake Order') {
            dispatch({
                type: CakeOrderActions.GET_VENDOR_ORDER_DETAILS,
                payload: Id
            });
        } else if (Type === 'Hamper Order') {
            dispatch({
                type: HamperOrderActions.GET_HAMPER_DETAILS,
                payload: Id
            });
        } else {
            dispatch({
                type: ProductOrderActions.GET_OTHER_PRODUCT_ORDER_DETAILS,
                payload: Id
            });
        };
        //eslint-disable-next-line
    }, [Type, Id]);

    const handleClose = (e) => {
        e.preventDefault();
        (Page === 'Manager') ? history.push('/Manager-Current-Order-List') : Page === 'Accounts' ?
            history.push('/Accounts-Current-Orders-List') : history.push('/Management-Current-Order-List')
    };

    useEffect(() => {
        if (Type === 'Cake Order') {
            (GetVendorOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        } else if (Type === 'Hamper Order') {
            (HamperDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        } else {
            (OtherProductOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        }
    }, [GetVendorOrderDetails.length, HamperDetails.length, OtherProductOrderDetails.length, Type]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    {Type === 'Cake Order' ?
                        <CakeOrderDetails
                            GetVendorOrderDetails={GetVendorOrderDetails}
                            handleClose={handleClose}
                            Page={Page}
                        /> :
                        Type === 'Hamper Order' ?
                            <HamperOrderDetails
                                HamperDetails={HamperDetails}
                                handleClose={handleClose}
                                Page={Page}
                            /> :
                            <OtherProductOrderDetailsPage
                                OtherProductOrderDetails={OtherProductOrderDetails}
                                handleClose={handleClose}
                                Page={Page}
                            />
                    }
                </div>
            }
        </>
    )
};