import actions from "./actions";

const initialState = {
    VendorsLoginHistory: [],
    HelpdeskLoginHistory: [],
    VendorsWorkHrs: [],
    HelpdeskWorkHours: [],
    Inactive_Vendors: [],
};

const LoginHistoryAndWorkHoursReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE:
            return {
                ...state,
                VendorsLoginHistory: action.payload
            }
        case actions.SET_VENDORS_WORK_HRS_WITH_DATE_RANGE:
            return {
                ...state,
                VendorsWorkHrs: action.payload
            }
        case actions.SET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE:
            return {
                ...state,
                HelpdeskLoginHistory : action.payload
            }
        case actions.SET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE :
            return {
                ...state,
                HelpdeskWorkHours : action.payload
            }
        case actions.SET_INACTIVE_VENDORS_LIST :
            return {
                ...state,
                Inactive_Vendors : action.payload
            }
        default:
            return state;
    }
};

export default LoginHistoryAndWorkHoursReducer;