const actions = {
    GET_FLAVOUR_LIST : 'GET_FLAVOUR_LIST',
    SET_FLAVOUR_LIST : 'SET_FLAVOUR_LIST',
    ADD_NEW_FLAVOUR : 'ADD_NEW_FLAVOUR',
    ADD_NEW_FLAVOUR_STATUS : 'ADD_NEW_FLAVOUR_STATUS',
    GET_SHAPE_LIST : 'GET_SHAPES_LIST',
    SET_SHAPE_LIST : 'SET_SHAPES_LIST',
    ADD_NEW_SHAPE : 'ADD_NEW_SHAPE',
    ADD_NEW_SHAPE_STATUS : 'ADD_NEW_SHAPE_STATUS',
    GET_ARTICLE_LIST : 'GET_ARTICLE_LIST',
    SET_ARTICLE_LIST : 'SET_ARTICLE_LIST',
    ADD_NEW_ARTICLE : 'ADD_NEW_ARTICLE',
    ADD_NEW_ARTICLE_STATUS : 'ADD_NEW_ARTICLE_STATUS',
    GET_WEIGHT_LIST : 'GET_WEIGHT_LIST',
    SET_WEIGHT_LIST : 'SET_WEIGHT_LIST',
    ADD_NEW_WEIGHT : 'ADD_NEW_WEIGHT',
    ADD_NEW_WEIGHT_STATUS : 'ADD_NEW_WEIGHT_STATUS',
    GET_CAKETOPPINGS_LIST : 'GET_CAKETOPPINGS_LIST',
    SET_CAKETOPPINGS_LIST : 'SET_CAKETOPPINGS_LIST',
    DELETE_FLAVOUR : 'DELETE_FLAVOUR',
    DELETE_FLAVOUR_STATUS : 'DELETE_FLAVOUR_STATUS',
    DELETE_SHAPE : 'DELETE_SHAPE',
    DELETE_SHAPE_STATUS : 'DELETE_SHAPE_STATUS',
    DELETE_WEIGHT : 'DELETE_WEIGHT',
    DELETE_WEIGHT_STATUS : 'DELETE_WEIGHT_STATUS',
    DELETE_ARTICLE : 'DELETE_ARTICLE',
    DELETE_ARTICLE_STATUS : 'DELETE_ARTICLE_STATUS',
    GET_CAKE_TYPE: 'GET_CAKE_TYPE',
    SET_CAKE_TYPE: 'SET_CAKE_TYPE',
    ADD_CAKE_TYPE: 'ADD_CAKE_TYPE',
    DELETE_CAKE_TYPE: 'DELETE_CAKE_TYPE',
    UPDATE_CAKE_TYPE_IMAGE: 'UPDATE_CAKE_TYPE_IMAGE',
    GET_ALL_FLAVOURS_LIST: 'GET_ALL_FLAVOURS_LIST',
    SET_ALL_FLAVOURS_LIST: 'SET_ALL_FLAVOURS_LIST',
    GET_ALL_SHAPES_LIST: 'GET_ALL_SHAPES_LIST',
    SET_ALL_SHAPES_LIST: 'SET_ALL_SHAPES_LIST',
    CAKE_ARRAY_RESPONSE: 'CAKE_ARRAY_RESPONSE',
}

export default actions;