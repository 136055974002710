import actions from "./actions";

const initState = {
    OrdersCount: [],
    NotRespondOrders: [],
    AddHelpdeskMemberStatus: [],
    HelpdeskMembersList: [],
    DeleteHelpdeskStatus: [],
    DeliveryOrdersList: [],
    CurrentOrdersList: [],
    conversationList: [],
    ChatMessage: [],
    helpDeskVusersList: [],
    InternalUsersNotifications: [],
    HelpdeskResponse: [],
    alseenListByType: [],
    HelpdeskVDashboardCount: [],
    HelpdeskCDashboardCount: [],
    ManagerDashboardCount: [],
    ChatNotificationsList: [],
    NotRespondOtherProductOrders: [],
    NotRespondHamperOrders: [],
    NotRespondCustomizedCakeOrders: [],
};

const HelpdeskReducer = (state = initState, action) => {
    switch (action.type) {

        case actions.SET_LAST_SEEN_LIST_BY_ID:
            return {
                ...state,
                alseenListByType: action.payload
            }
        case actions.SET_HELPDESK_V_USERS_LIST:
            return {
                ...state,
                helpDeskVusersList: action.payload
            }
        case actions.SET_MESSAGES:
            return {
                ...state,
                ChatMessage: action.payload
            }
        case actions.SET_CONVERSATION:
            return {
                ...state,
                conversationList: action.payload
            }
        case actions.SET_ORDERS_COUNT:
            return {
                ...state,
                OrdersCount: action.payload.OrdersCount
            }
        case actions.SET_NOT_RESPOND_ORDERS:
            return {
                ...state,
                NotRespondOrders: action.payload.NotRespondOrders
            }
        case actions.ADD_NEW_HELPDESK_MEMBER_STATUS:
            return {
                ...state,
                AddHelpdeskMemberStatus: action.payload.AddHelpdeskMemberStatus
            }
        case actions.SET_HELPDESK_MEMBERS_LIST:
            return {
                ...state,
                HelpdeskMembersList: action.payload.HelpdeskMembersList
            }
        case actions.DELETE_HELPDESK_MEMBER_STATUS:
            return {
                ...state,
                DeleteHelpdeskStatus: action.payload.DeleteHelpdeskStatus
            }
        case actions.SET_DELIVERY_ORDERS_LIST:
            return {
                ...state,
                DeliveryOrdersList: action.payload
            }
        case actions.SET_CURRENT_ORDERS_LIST:
            return {
                ...state,
                CurrentOrdersList: action.payload
            }
        case actions.SET_INTERNAL_USERS_NOTIFICATIONS:
            return {
                ...state,
                InternalUsersNotifications: action.payload
            }
        case actions.HELPDESK_RESPONSE:
            return {
                ...state,
                HelpdeskResponse: action.payload
            }
        case actions.SET_HELPDESKV_DASHBOARD_COUNT:
            return {
                ...state,
                HelpdeskVDashboardCount: action.payload
            }
        case actions.SET_HELPDESKC_DASHBOARD_COUNT:
            return {
                ...state,
                HelpdeskCDashboardCount: action.payload
            }
        case actions.SET_MANAGER_DASHBOARD_COUNT:
            return {
                ...state,
                ManagerDashboardCount: action.payload
            }
        case actions.SET_CHAT_NOTIFICATIONS_LIST:
            return {
                ...state,
                ChatNotificationsList: action.payload
            }
        case actions.SET_NOT_RESPOND_OTHER_PRODUCT_ORDERS:
            return {
                ...state,
                NotRespondOtherProductOrders: action.payload
            }
        case actions.SET_NOT_RESPOND_HAMPER_ORDERS:
            return {
                ...state,
                NotRespondHamperOrders: action.payload
            }
        case actions.SET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS:
            return {
                ...state,
                NotRespondCustomizedCakeOrders: action.payload
            }

        default: return state;
    }
}

export default HelpdeskReducer;