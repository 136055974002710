import React from 'react';
import Sidenavigation from '../layout/Sidenavigation';
import Topnavigation from '../layout/Topnavigation';
import Ordertable from "../section/VendorOrderList/Ordertable";
import { Helmet } from "react-helmet";

export default function VendorOrderList() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme">
            {/* title  */}
            <Helmet>
                <title>Orders</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end  */}

            <main className="body-content">

                {/* Topnavigation start  */}
                <Topnavigation />
                {/* Topnavigation end  */}
                
                {/* page content start  */}
                <Ordertable />
                {/* page content end  */}
            </main>
        </div>
    );
};