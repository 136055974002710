import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/CakeArray/actions";
import Preloader from "../../layouts/Preloader";
import CircleDotSpinner from "../../common/CircleDotSpinner";
import { LettersValidation, NumberValidation } from "../../../utils/Validation";
import Flavour from "./CakeArray/Flavour";
import Shape from "./CakeArray/Shape";
import Weight from "./CakeArray/Weight";
import Breadcrumb from "./Breadcrumb";

export default function Content() {

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [deleteShow1, setDeleteShow1] = useState(false);
    const [deleteShow2, setDeleteShow2] = useState(false);
    const [deleteShow3, setDeleteShow3] = useState(false);
    const [flavourName, setFlavourName] = useState('');
    const [flavourId, setFlavourId] = useState('');
    const [shapeName, setShapeName] = useState('');
    const [shapeId, setShapeId] = useState('');
    const [weightName, setWeightName] = useState('');
    const [weightId, setWeightId] = useState('');
    const [loader, setLoader] = useState(false);

    const NewFlavour = useRef();
    const NewShape = useRef();
    const NewWeight = useRef();

    //get flavour, shapes, weight list
    const { FlavourList, ShapeList, WeightList, CakeArrayResponse } = useSelector(state => state.CakeArrayReducer);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: actions.GET_FLAVOUR_LIST });
        dispatch({ type: actions.GET_SHAPE_LIST });
        dispatch({ type: actions.GET_WEIGHT_LIST });
        // eslint-disable-next-line 
    }, []);

    //for close modals
    const handleClose = () => {
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setDeleteShow1(false);
        setDeleteShow2(false);
        setDeleteShow3(false);
        setLoader(false);
        setMessage("");
    };

    //add new flavour
    const handleAddNewFlavour = (e) => {
        e.preventDefault();
        if (NewFlavour.current.value) {
            setLoader(true);
            dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: NewFlavour.current.value });
        } else {
            setMessage('*required');
        };
    };

    //add new shape
    const handleAddNewShape = (e) => {
        e.preventDefault();
        if (NewShape.current.value) {
            setLoader(true);
            dispatch({ type: actions.ADD_NEW_SHAPE, payload: NewShape.current.value });
        } else {
            setMessage('*required');
        };
    };

    //add new weight
    const handleAddNewWeight = (e) => {
        e.preventDefault();
        if (NewWeight.current.value) {
            if (parseFloat(NewWeight.current.value) > 0) {
                setLoader(true);
                dispatch({ type: actions.ADD_NEW_WEIGHT, payload: NewWeight.current.value });
            } else {
                setMessage('Enter Valid Weight');
            }
        } else {
            setMessage('*required');
        };
    };

    //open delete flavour popup
    const handleDeleteFlavourPopup = (e, a) => {
        e.preventDefault();
        setDeleteShow1(true);
        setFlavourName(a.Name);
        setFlavourId(a._id);
    };

    //delete flavour
    const handleDeleteFlavour = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({ type: actions.DELETE_FLAVOUR, payload: flavourId });
    };

    //open delete shape popup
    const handleDeleteShapePopup = (e, a) => {
        e.preventDefault();
        setDeleteShow2(true);
        setShapeName(a.Name);
        setShapeId(a._id);
    };

    //delete shape
    const handleDeleteShape = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({ type: actions.DELETE_SHAPE, payload: shapeId });
    };

    //open delete weight popup
    const handleDeleteWeightPopup = (e, a) => {
        e.preventDefault();
        setDeleteShow3(true);
        setWeightName(a.Weight);
        setWeightId(a._id);
    };

    //delete weight
    const handleDeleteWeight = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({ type: actions.DELETE_WEIGHT, payload: weightId });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_FLAVOUR_LIST });
        dispatch({ type: actions.GET_SHAPE_LIST });
        dispatch({ type: actions.GET_WEIGHT_LIST });
    };

    const handleShowPopup = (e, Type) => {
        e.preventDefault();
        if (Type === 'Flavour') {
            setShow1(true);
        } else if (Type === 'Shape') {
            setShow2(true);
        } else if (Type === 'Weight') {
            setShow3(true);
        };
    };

    useEffect(() => {
        if (CakeArrayResponse?.statusCode === 200 || CakeArrayResponse?.statusCode === 400) {
            setLoader(false);
            setDeleteShow1(false);
            setDeleteShow2(false);
            setDeleteShow3(false);
            setShow1(false);
            setShow2(false);
            setShow3(false);
        };
    }, [CakeArrayResponse]);

    //loading
    useEffect(() => {
        if (FlavourList.length === 0 && ShapeList.length === 0 && WeightList.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
        // eslint-disable-next-line
    }, [FlavourList, ShapeList, WeightList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                        </div>
                        <Flavour
                            List={FlavourList}
                            show={(e) => handleShowPopup(e, 'Flavour')}
                            handleDeletePopup={handleDeleteFlavourPopup}
                        />
                        <Shape
                            List={ShapeList}
                            show={(e) => handleShowPopup(e, 'Shape')}
                            handleDeletePopup={handleDeleteShapePopup}
                        />
                        <Weight
                            List={WeightList}
                            show={(e) => handleShowPopup(e, 'Weight')}
                            handleDeletePopup={handleDeleteWeightPopup}
                        />
                    </div>
                </div>
            }
            {/* Add new flavour Popup */}
            <Modal className="modal-min" show={show1} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Add New Flavour</h1>
                    <div>
                        <label htmlFor="validationCustom23" className='font-weight-bold'>Flavour Name<span className="text-danger">*</span></label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control text-capitalize"
                                ref={NewFlavour}
                                onInput={LettersValidation}
                                placeholder='Enter here...'
                            />
                        </div>
                        <p className="text-danger">{message}</p>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleAddNewFlavour}
                                >
                                    Add
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Add new shape Popup */}
            <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Add New Shape</h1>
                    <div>
                        <label htmlFor="validationCustom23" className='font-weight-bold'>Shape Name<span className="text-danger">*</span></label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control text-capitalize"
                                ref={NewShape}
                                onInput={LettersValidation}
                                placeholder='Enter here...'
                            />
                        </div>
                        <p className="text-danger">{message}</p>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleAddNewShape}
                                >
                                    Add
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Add new weight Popup */}
            <Modal className="modal-min" show={show3} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Add New Weight</h1>
                    <div>
                        <label htmlFor="validationCustom23" className='font-weight-bold'>Weight<span className="text-danger">*</span></label>
                        <div className="input-group">
                            <input
                                type="number"
                                onWheel={() => document.activeElement.blur()}
                                min={0}
                                maxLength={5}
                                onInput={NumberValidation}
                                className="form-control"
                                ref={NewWeight}
                                placeholder='Enter here...'
                            />
                            <button disabled>Kg</button>
                        </div>
                        <p className="text-danger">{message}</p>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleAddNewWeight}
                                >
                                    Add
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete flavour Popup */}
            <Modal className="modal-min" show={deleteShow1} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div>
                        <h6 className='text-center mb-4'>Are you sure you want to delete the falvour "{flavourName}" ?</h6>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleDeleteFlavour}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary ml-4"
                                    onClick={handleClose}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete shape Popup */}
            <Modal className="modal-min" show={deleteShow2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div>
                        <h6 className='text-center mb-4'>Are you sure you want to delete the shape "{shapeName}" ?</h6>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleDeleteShape}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary ml-4"
                                    onClick={handleClose}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete weight Popup */}
            <Modal className="modal-min" show={deleteShow3} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div>
                        <h6 className='text-center mb-4'>Are you sure you want to delete the weight "{weightName}" ?</h6>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleDeleteWeight}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary ml-4"
                                    onClick={handleClose}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};