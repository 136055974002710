import { all } from "redux-saga/effects";
import registeredUserSaga from '../redux/users/sagas';
import AuthSaga from "./Auth/sagas";
import CakeListSaga from "./cake/sagas"
import ForgotpasswordSaga from "./ForgotPassword/sagas";
import VendorListSaga from "./Vendor/sagas";
import OrderListSaga from "./Order/sagas";
import Adminsaga from "./Admin/sagas";
import VendorOrderListSaga from "./VendorOrderList/sagas"
import VendorCakeListSaga from "./VendorCakeList/sagas";
import CatagoryListSaga from "./Category/sagas";
import CakeArraySaga from "./CakeArray/sagas";
import CustomizeCakeSaga from "./Customize-Cake/sagas";
import HelpdeskSaga from "./Helpdesk/sagas";
import TopperSaga from './Topper/sagas';
import HamperOrderSaga from "./HampersOrder/sagas";
import OtherProductSaga from './OtherProducts/sagas';
import SampleSaga from './Sample/sagas';
import TicketSaga from "./Tickets/sagas";
import TicketSaga2 from "./Tickets/sagas2";
import LoginHistoryAndWorkHoursSagas from "./LoginHistoryAndWorkHours/sagas";
import AccountsSagas from "./Accounts/sagas";
import CommonSaga from "./Common/sagas";

export default function* rootSaga() {
    yield all([
        registeredUserSaga(),
        AuthSaga(),
        ForgotpasswordSaga(),
        CakeListSaga(),
        VendorListSaga(),
        OrderListSaga(),
        Adminsaga(),
        VendorOrderListSaga(),
        VendorCakeListSaga(),
        CatagoryListSaga(),
        CakeArraySaga(),
        CustomizeCakeSaga(),
        HelpdeskSaga(),
        TopperSaga(),
        HamperOrderSaga(),
        OtherProductSaga(),
        SampleSaga(),
        TicketSaga(),
        TicketSaga2(),
        LoginHistoryAndWorkHoursSagas(),
        AccountsSagas(),
        CommonSaga(),
    ]);
};