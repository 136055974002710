/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import Breadcrumb from './Breadcrumb';
import VendorActions from '../../../../redux/Vendor/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import moment from 'moment';
import generator from 'generate-password';
import CommonActions from '../../../../redux/Common/actions';
// import Alert from "../../common/Alert";
import { Modal } from "react-bootstrap";
import CakeArrayActions from '../../../../redux/CakeArray/actions';
import BankNameActions from '../../../../redux/VendorCakeList/actions';
// import Carousel from "react-multi-carousel";
import Preloader from '../../../layouts/Preloader';
import PlacesAutocomplete from 'react-places-autocomplete';
import { AadharNumberValidation, NumberValidation } from '../../../../utils/Validation';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { GOOGLE_API_KEY } from '../../../../utils/constants';
import FileSizeValidation from '../../../common/FileSizeValidation';
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    }),
}

const useremail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object().shape({
    VendorName: yup.string().required('Name is required'),
    PreferredNameOnTheApp: yup.string().required('Prefered Name is required'),
    PhoneNumber1: yup.string().required("Main Phonenumber is required")
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, "to short").max(10, "to long"),
    Email: yup.string().required('Email is required')
        .matches(useremail, 'email is not valid'),
    DateOfBirth: yup.date().required('dob is required').max(new Date(), 'Please choose past date'),
    DoorNumber: yup.string().required('dob is required'),
    Pincode: yup.string().required('required').max(6, 'Pincode is not valid').min(6, 'Pincode is not valid'),
    Address: yup.string().required('Address is required'),
    Description: yup.string().required('Description is required'),
    EggOrEggless: yup.string().required('required'),
    Gender: yup.string().required('Gender is required'),
    YearsOfExperienceAsBaker: yup.string().required('required'),
    AadhaarNumber: yup.string().required('required'),
    PANNumber: yup.string().required('required'),
    FSSAINumber: yup.string().required('required'),
    FSSAIExpiryDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    JobType: yup.string().required('required'),
    BankName: yup.string().required('required'),
    Branch: yup.string().required('required'),
    AccountNumber: yup.string().required('required'),
    IFSCCode: yup.string().required('required'),
    Ratings: yup.string().required('required'),
    AreYouFamiliarOnWorkingWithApps: yup.string().required('required'),
    LearningType: yup.string().required('required'),
    TotalDurationOfLearning: yup.string().required('required'),
    CurrentAverageSalePerMonth: yup.string().required('required'),
    HowManyCakesCanYouMakeInaWeek: yup.string().required('required'),
    HowManyDaysCanYouWorkInaWeek: yup.string().required('required'),
    CanYouMakeSingleCakeAbove5Kgs: yup.string().required('required'),
    CanYouMakeTierCakes: yup.string().required('required'),
    // CakeTypesYouBake: yup.string().required('required'),
    CanYouMakeARegularCakeWithFondantAsToppers: yup.string().required('required'),
});

const FondantTopperImages = [
    // 'https://res.cloudinary.com/mindmadetech/image/upload/v1661510684/CakeyLogo/Rainbowthemecake_hsda6o.webp',
    // 'https://res.cloudinary.com/mindmadetech/image/upload/v1661509799/CakeyLogo/f8d86df002785836ad7fb570be7e7288_zo5t4d.jpg',
    'https://sugitechnologies.com/images/AppImages/171979585_10225893625708421_2982526219153819858_n_wfqzcp.jpg']

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    // eslint-disable-next-line
    const { token } = useSelector(state => state.authReducer);
    //eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [show, setShow] = useState(false);
    const [timer, setTimer] = useState("");
    const [instituteShow, setInstituteShow] = useState('');
    const [showImageSelect, setShowImageSelect] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const [instituteNameList, setInstituteNameList] = useState([]);
    const [cakeTypeName, setCakeTypeName] = useState('');
    const [showOthersPopup, setShowOthersPopup] = useState(false);
    const [cakeTypeNameList, setCakeTypeNameList] = useState([]);
    const [showMessage, setShowMessage] = useState("");
    const [specialityCakeName, setSpecialityCakeName] = useState('');
    const [specialityCakeNameList, setSpecialityCakeNameList] = useState([]);
    const [showImageError, setShowImageError] = useState("");
    const [learningDuration, setLearningDuration] = useState('years');
    const [experienceDuration, setExperienceDuration] = useState('years');
    const [loader, setLoader] = useState(false);
    const [coordinates, setCoordinates] = useState({
        Latitude: null,
        Longitude: null
    });
    // const [invalidDOB, setInvalidDOB] = useState(false);
    // const [invalidExpiryDate, setInvalidExpiryDate] = useState(false);
    // const [invalidCakeType, setInvalidCakeType] = useState(false);
    // const [invalidSpecialityCake, setInvalidSpecialityCake] = useState(false);

    var [SelectProfile, setSelectProfile] = useState();
    var [SelectProfileUrl, setSelectProfileUrl] = useState();
    var [address, setAddress] = useState('');

    let [enteredAddress, setEnteredAddress] = useState();
    let [bankNameList, setBankNameList] = useState([]);
    let [selectedBankName, setSelectedBankName] = useState();
    let [cakeTypeList, setCakeTypeList] = useState([]);

    const Status = useSelector(state => state.VendorReducer);

    const { CakeType } = useSelector(state => state.CakeArrayReducer);

    const { response } = useSelector(state => state.commonReducer);

    const { BankName } = useSelector(state => state.VendorCakeListReducer);

    const { register, handleSubmit, reset, setValue, formState, resetField, clearErrors } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: { alert: false, message: null, status: null }
        });
    };

    useEffect(() => {
        if (response.alert) {
            setShowAlert(true);
            setTimer(setInterval(() => {
                clearNotification()
            }, 4000))
        }
        // eslint-disable-next-line
    }, [response]);

    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_CAKE_TYPE });
        dispatch({ type: BankNameActions.GET_BANKNAME });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (BankName?.length !== 0) {
            if (BankName?.message === 'No Records Found') {
                setBankNameList([]);
            } else {
                let NewArray = [];
                BankName.map(val => {
                    return NewArray.push({ label: val.BankName, value: val.BankName });
                });
                setBankNameList(NewArray);
            };
        } else {
            setBankNameList([]);
        };
    }, [BankName]);

    const handleSelectBankName = (e) => {
        setSelectedBankName(e);
        setValue('BankName', e?.value);
    };

    useEffect(() => {
        if (CakeType?.length === 0) {
            setCakeTypeList([]);
        } else {
            if (CakeType?.message === 'No Records Found') {
                setCakeTypeList([]);
            } else {
                let CakeTypeArray = [];
                //eslint-disable-next-line
                CakeType.map(val => {
                    if (val.SubType?.length === 0) {
                        return CakeTypeArray.push({ label: val.Type, value: val.Type });
                    } else {
                        let SubTypeArray = [];
                        val.SubType.map((s, i) => {
                            return SubTypeArray.push({ label: `${i + 1}. ${s.Name}`, value: `${val.Type} - ${s.Name}` })
                        });
                        CakeTypeArray.push({ label: val.Type, options: SubTypeArray });
                    }
                });
                CakeTypeArray.push({ label: 'Others', value: 'Others' });
                setCakeTypeList(CakeTypeArray);
            }
        };
    }, [CakeType]);

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        setShowOthersPopup(false);
    };

    const handleChange = (value) => {
        setAddress(address = value);
        if (address.length === 0) {
            setCoordinates({
                Latitude: null,
                Longitude: null
            })
        }
    };

    const handleSelect = (value) => {
        setAddress(value)
    };

    // const handleAddAddress = (e) => {
    //     setShow(true);
    //     setAddress(e.target.value);
    // };

    const handleGetLatLng = async (e) => {
        e.preventDefault();
        setEnteredAddress(address);
        await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`)
            .then((res) => res.json())
            .then((data) => {
                setValue("Address", address);
                clearErrors("Address");
                setShow(false);
                setCoordinates({
                    Latitude: data.results[0].geometry.location.lat,
                    Longitude: data.results[0].geometry.location.lng
                });
                setAddress('');
            }).catch(err => console.log(err));
    };

    //get selected image 
    const handleSelectProfile = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setSelectProfile(SelectProfile = result?.FinalImages[0]);
                if (SelectProfile) {
                    setSelectProfileUrl(URL.createObjectURL(SelectProfile));
                };
            }
        }
    };

    const handleShowAddressPopup = (e) => {
        e.preventDefault();
        setShow(true);
    };

    //add institution list
    const handleAddInstitute = (e) => {
        e.preventDefault();
        setInstituteName('');
        setInstituteNameList([...instituteNameList, instituteName?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())]);
    };

    //remove institution name from institution list
    const RemoveAddedInstituteName = (e, index) => {
        e.preventDefault();
        var splicedList = [...instituteNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setInstituteNameList(splicedList);
    };

    //add cake type list
    const handleAddCakeType = (e) => {
        if (e?.value === 'Others') {
            // if (e.target.value === 'Others') {
            setShowOthersPopup(true);
        } else {
            if (cakeTypeNameList.length === 0) {
                setCakeTypeNameList([e?.value]);
                // setCakeTypeNameList([e.target.value]);
            } else {
                if (!cakeTypeNameList.includes(e?.value)) {
                    // if (!cakeTypeNameList.includes(e.target.value)) {
                    // setCakeTypeNameList([...cakeTypeNameList, e.target.value]);
                    setCakeTypeNameList([...cakeTypeNameList, e?.value]);
                };
            };
        };
        resetField('CakeTypesYouBake');
    };

    const handleAddNewCakeType = (e) => {
        e.preventDefault();
        if (cakeTypeName) {
            if (cakeTypeNameList.includes(cakeTypeName)) {
                setShowOthersPopup(true);
                setShowMessage("Types of Cake already exist");
            } else {
                setShowOthersPopup(false);
                setCakeTypeNameList([...cakeTypeNameList, cakeTypeName]);
                reset({ "CakeTypesYouBake": '' });
            }
        } else {
            setShowOthersPopup(true);
            setShowMessage("Types of other product is required");
        }
    };

    //remove cake type from list
    const RemoveAddedCakeTypeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...cakeTypeNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setCakeTypeNameList(splicedList);
    };

    //add speciality cake list
    const handleAddSpecialityCake = (e) => {
        e.preventDefault();
        setSpecialityCakeName('');
        setSpecialityCakeNameList([...specialityCakeNameList, specialityCakeName?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())]);
    };

    //remove speciality cake from list
    const RemoveAddedSpecialityCakeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...specialityCakeNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setSpecialityCakeNameList(splicedList);
    };

    //vendor registration
    const onSubmit = (data) => {
        if (cakeTypeNameList.length === 0 || specialityCakeNameList.length === 0) {
            if (cakeTypeNameList.length === 0) {
                // setInvalidCakeType(true);
                setShowImageError('Types of Cake is Required');
            } else if (specialityCakeNameList.length === 0) {
                // setInvalidSpecialityCake(true);
                setShowImageError('Speciality Cake is Required');
            }
        } else {
            // setInvalidCakeType(false);
            // setInvalidSpecialityCake(false);
            if (!SelectProfile) {
                setLoader(false)
                setShowImageError('Profile Image is required')
            } else {
                setShowImageError('')
                var Password = generator.generate({
                    length: 10,
                    numbers: true,
                    uppercase: true,
                    lowercase: true,
                    symbols: false
                });
                const formData = new FormData();
                formData.append('ProfileImage', SelectProfile);
                formData.append('VendorName', data.VendorName);
                formData.append('PreferredNameOnTheApp', data.PreferredNameOnTheApp);
                formData.append('Email', data.Email);
                formData.append('Gender', data.Gender);
                formData.append('DateOfBirth', moment(moment(data.DateOfBirth, 'YYYY-MM-DD')).format('DD-MM-YYYY'));
                formData.append('EggOrEggless', data.EggOrEggless);
                formData.append('DoorNo', data.DoorNumber);
                formData.append('Pincode', data.Pincode);
                formData.append('Address', data.Address);
                formData.append('GoogleLocation', JSON.stringify(coordinates));
                formData.append('PhoneNumber1', data.PhoneNumber1);
                if (data.PhoneNumber2) {
                    formData.append('PhoneNumber2', data.PhoneNumber2);
                }
                formData.append('BankName', data.BankName);
                formData.append('Branch', data.Branch);
                formData.append('AccountNumber', data.AccountNumber);
                formData.append('IFSCCode', data.IFSCCode.toUpperCase());
                if (data.UPIId) {
                    formData.append('UPIId', data.UPIId);
                }
                formData.append('AadhaarNumber', data.AadhaarNumber);
                formData.append('PANNumber', data.PANNumber.toUpperCase());
                if (data.GSTNumber) {
                    formData.append('GSTNumber', data.GSTNumber.toUpperCase());
                }
                formData.append('FSSAINumber', data.FSSAINumber.toUpperCase());
                formData.append('FSSAIExpiryDate', moment(moment(data.FSSAIExpiryDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'));
                formData.append('LearningType', data.LearningType);
                if (data.LearningType === 'At Institution') {
                    for (let i = 0; i < instituteNameList.length; i++) {
                        formData.append('InstitutionName', instituteNameList[i]);
                    }
                };
                formData.append('TotalDurationOfLearning', data.TotalDurationOfLearning + ' ' + learningDuration);
                formData.append('HowManyCakesCanYouMakeInaWeek', data.HowManyCakesCanYouMakeInaWeek + 'Kg');
                formData.append('CurrentAverageSalePerMonth', data.CurrentAverageSalePerMonth + 'Kg');
                formData.append('YearsOfExperienceAsBaker', data.YearsOfExperienceAsBaker + ' ' + experienceDuration);
                formData.append('HowManyDaysCanYouWorkInaWeek', data.HowManyDaysCanYouWorkInaWeek);
                formData.append('JobType', data.JobType);
                formData.append('CanYouMakeSingleCakeAbove5Kgs', data.CanYouMakeSingleCakeAbove5Kgs);
                formData.append('CanYouMakeTierCakes', data.CanYouMakeTierCakes);
                formData.append('AreYouFamiliarOnWorkingWithApps', data.AreYouFamiliarOnWorkingWithApps);
                for (let i = 0; i < cakeTypeNameList.length; i++) {
                    formData.append('CakeTypesYouBake', cakeTypeNameList[i]);
                };
                for (let i = 0; i < specialityCakeNameList.length; i++) {
                    formData.append('YourSpecialityCakes', specialityCakeNameList[i]);
                };
                formData.append('Ratings', data.Ratings);
                formData.append('CanYouMakeARegularCakeWithFondantAsToppers', data.CanYouMakeARegularCakeWithFondantAsToppers);
                formData.append('Description', data.Description);
                formData.append('Password', Password);
                formData.append('Creaded_By_TypeOfUser', token.type)
                formData.append('Creaded_By_Name', token?.result?.Name ? token.result.Name : token.result.AdminName)
                formData.append('Creaded_By_Email', token.result.Email);
                // ______________________________
                setLoader(true);
                dispatch({ type: VendorActions.REGISTER_VENDOR, payload: formData });
                setTimeout(() => { dispatch({ type: VendorActions.REGISTER_VENDOR_STATUS, payload: { RegisterVendorStatus: [] } }); }, 5000);
                // _______________________________________
            }
        }
    };
    //validate status for regitered vendor
    useEffect(() => {
        if (Status.RegisterVendorStatus !== undefined) {
            if (Status.RegisterVendorStatus.statusCode === 200) {
                setLoader(false);
                reset();
                setSelectProfile();
                setSelectProfileUrl();
                setInstituteNameList([]);
                setCakeTypeNameList([]);
                setSpecialityCakeNameList([]);
                setShowImageSelect('');
                setEnteredAddress();
            } else {
                setLoader(false);
            }
        };
        // eslint-disable-next-line
    }, [Status]);

    //loading
    useEffect(() => {
        (CakeType.length === 0 || BankName.length === 0) ? setFetchSuccess(false) :
            setFetchSuccess(true);
    }, [CakeType, BankName]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="ms-profile-overview">
                        <div className="ms-profile-cover">
                            <div className='profile-img-div'>
                                <img
                                    className="ms-profile-img"
                                    src={SelectProfile === undefined ? "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" : SelectProfileUrl}
                                    alt="people"
                                />
                                <div className='profile-upload-i'>
                                    <input
                                        type='file'
                                        accept='image/jpg,image/jpeg,image/png'
                                        className='profile-img-upload'
                                        name='file'
                                        onChange={handleSelectProfile}
                                    />
                                    <i className="fa fa-upload"></i>
                                </div>
                            </div>
                            <div className="ms-profile-user-info register-header">
                                <h4 className="ms-profile-username text-white ">Vendor Registration</h4>
                            </div>
                        </div>
                    </div>
                    <form>
                        <div className="row">
                            <div className="col-xl-12 col-md-12">
                                <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header"><h6>Basic Information</h6></div>
                                    <div className="ms-panel-body tabledata-background">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Email</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.Email ? "form-control required-field text-lowercase" : "form-control text-lowercase"}
                                                        name='Email'
                                                        {...register('Email')}
                                                        placeholder="Email"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Preferred Name on the App</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.VendorName ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        placeholder="Prefered Name"
                                                        name='VendorName'
                                                        {...register('VendorName')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Vendor Name</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.PreferredNameOnTheApp ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        placeholder="Vendor Name"
                                                        name='PreferredNameOnTheApp'
                                                        {...register('PreferredNameOnTheApp')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Gender</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={errors?.Gender ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        defaultValue=''
                                                        name='Gender'
                                                        {...register('Gender')}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Male'>Male</option>
                                                        <option value='Female'>Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Date of Birth</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type='date'
                                                        className={errors?.DateOfBirth ? "form-control required-field" : "form-control"}
                                                        maxLength={4}
                                                        max={new Date()?.toISOString()?.slice(0, 10)}
                                                        pattern="[1-9][0-9]{3}"
                                                        name='DateOfBirth'
                                                        {...register('DateOfBirth')}
                                                    />
                                                </div>
                                                <p className='text-danger'>{errors?.DateOfBirth?.message === 'Please choose past date' ? 'Please choose past date' : ''}</p>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Egg or Eggless</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={errors?.EggOrEggless ? "form-control required-field" : "form-control"}
                                                        defaultValue=''
                                                        name='EggOrEggless'
                                                        {...register('EggOrEggless')}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Egg'>Only Egg</option>
                                                        <option value='Eggless'>Only Eggless</option>
                                                        <option value='Egg and Eggless'>Egg and Eggless</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Address Line 1</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.DoorNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Door No/ Block No"
                                                        name='DoorNumber'
                                                        {...register('DoorNumber')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Address Line 2</b> <span className='text-danger'>(Click add button to enter address)</span> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            className={errors?.Address ? "form-control required-field disabled-form-control" : "form-control disabled-form-control"}
                                                            name='Address'
                                                            {...register('Address')}
                                                            placeholder="Address"
                                                        // onChange={() => setShow(true)}
                                                        />
                                                        <button className='add-button rounded disabled-add' type='button' onClick={handleShowAddressPopup}>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Pincode</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        maxLength={6}
                                                        onInput={NumberValidation}
                                                        className={errors?.Pincode ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Pincode"
                                                        name='Pincode'
                                                        {...register('Pincode')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Main Mobile Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={NumberValidation} className={errors?.PhoneNumber1 ? "form-control required-field" : "form-control"} placeholder="Phonenumber" name='PhoneNumber1' {...register('PhoneNumber1')} />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Alternative Mobile Number</b></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={10}
                                                        onInput={NumberValidation}
                                                        className={errors?.PhoneNumber2 ? "form-control required-field" : "form-control"}
                                                        placeholder="Alternative Phonenumber"
                                                        name='PhoneNumber2'
                                                        {...register('PhoneNumber2')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6> Bank Details </h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Bank Name</b> <span className='text-danger'>*</span></label>
                                                <Select
                                                    className={errors?.BankName ? "required-field text-uppercase" : "text-uppercase"}
                                                    styles={customStyles}
                                                    options={bankNameList}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    name='BankName'
                                                    {...register('BankName')}
                                                    onChange={handleSelectBankName}
                                                    value={selectedBankName}
                                                />
                                                {/* <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        {BankName.message === 'No Records Found' ?
                                                            <select className='form-control'>
                                                                <option className='text-center'>No Records Found</option>
                                                            </select> :
                                                            <select
                                                                defaultValue=''
                                                                className={errors?.BankName ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                                placeholder="Bank Name"
                                                                name='BankName'
                                                                {...register('BankName')}
                                                            >
                                                                <option value='' disabled='disabled'>-- Select --</option>
                                                                {BankName.map((val, i) =>
                                                                    <option key={i} className='font-weight-bold' value={val.BankName}>{val.BankName}</option>
                                                                )}
                                                            </select>
                                                        }
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Branch</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.Branch ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Branch"
                                                        name='Branch'
                                                        {...register('Branch')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Account Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.AccountNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Account Number"
                                                        name='AccountNumber'
                                                        {...register('AccountNumber')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>IFSC Code</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.IFSCCode ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="IFSC Code"
                                                        name='IFSCCode'
                                                        {...register('IFSCCode')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>UPI ID</b></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control text-lowercase"
                                                        placeholder="UPI ID"
                                                        name='UPIId'
                                                        {...register('UPIId')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6> Vendor Identity and License Details </h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Aadhaar Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        maxLength={14}
                                                        onInput={AadharNumberValidation}
                                                        className={errors?.AadhaarNumber ? "form-control required-field" : "form-control"}
                                                        placeholder="Aadhaar Number"
                                                        name='AadhaarNumber'
                                                        {...register('AadhaarNumber')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label><b>PAN Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.PANNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="PAN Number"
                                                        name='PANNumber'
                                                        {...register('PANNumber')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label><b>FSSAI Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        min={0}
                                                        maxLength={14}
                                                        onInput={NumberValidation}
                                                        className={errors?.FSSAINumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="FSSAI Number"
                                                        name='FSSAINumber'
                                                        {...register('FSSAINumber')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="start"><b>FSSAI Expiry Date</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="date"
                                                        className={errors?.FSSAIExpiryDate ? "form-control required-field" : "form-control"}
                                                        placeholder="FSSAI Expiry Date"
                                                        maxLength={4}
                                                        max={"9999-12-31"}
                                                        min={new Date()?.toISOString()?.slice(0, 10)}
                                                        name='FSSAIExpiryDate'
                                                        {...register('FSSAIExpiryDate')}
                                                    />
                                                </div>
                                                <p className='text-danger'>{errors?.FSSAIExpiryDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom24"><b>GST Number</b></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className="form-control text-uppercase"
                                                        placeholder="GST Number"
                                                        name='GSTNumber'
                                                        {...register('GSTNumber')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-header">
                                        <h6> Other Details </h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Learning Type</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={errors?.LearningType && !instituteShow ? "form-control required-field" : "form-control"}
                                                        defaultValue=''
                                                        name='LearningType'
                                                        {...register('LearningType')}
                                                        onChange={(e) => setInstituteShow(e.target.value)}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Self Learning'>Self Learning</option>
                                                        <option value='At Institution'>At Institution</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {instituteShow === 'At Institution' &&
                                                <div className="col-md-8 mb-3">
                                                    <label htmlFor="validationCustom25"><b>Institution Name</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <div className='display-flex w-100'>
                                                            <input
                                                                type="text"
                                                                className="form-control text-capitalize"
                                                                placeholder="Institution name"
                                                                value={instituteName}
                                                                onChange={(e) => setInstituteName(e.target.value)}
                                                            />
                                                            <button
                                                                className='add-button rounded disabled-add'
                                                                disabled={instituteNameList.length === 5 || instituteName.length === 0 ? true : false}
                                                                onClick={handleAddInstitute}
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            {instituteNameList.map((value, index) =>
                                                                <div key={index}>
                                                                    <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedInstituteName(e, index)}>X</button></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Total Duration of Learning</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="number"
                                                            onWheel={() => document.activeElement.blur()}
                                                            min={0}
                                                            maxLength={2}
                                                            onInput={NumberValidation}
                                                            className={errors?.TotalDurationOfLearning ? "form-control required-field" : "form-control"}
                                                            placeholder="example: 6"
                                                            name='TotalDurationOfLearning'
                                                            {...register('TotalDurationOfLearning')}
                                                        />
                                                        <select className='form-control w-50' defaultValue='days' onChange={(e) => setLearningDuration(e.target.value)}>
                                                            <option value='years'>years</option>
                                                            <option value='months'>months</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Maximum Capacity to Bake in a Week (in kgs)</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={2}
                                                        onInput={NumberValidation}
                                                        className={errors?.HowManyCakesCanYouMakeInaWeek ? "form-control required-field" : "form-control"}
                                                        placeholder="Example: 5"
                                                        name='HowManyCakesCanYouMakeInaWeek'
                                                        {...register('HowManyCakesCanYouMakeInaWeek')}
                                                    />
                                                    <button disabled>Kg</button>
                                                </div>

                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Current Average Sales per Month (in kgs)</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={3}
                                                        onInput={NumberValidation}
                                                        className={errors?.CurrentAverageSalePerMonth ? "form-control required-field" : "form-control"}
                                                        placeholder="Example: 5"
                                                        name='CurrentAverageSalePerMonth'
                                                        {...register('CurrentAverageSalePerMonth')}
                                                    />
                                                    <button disabled>Kg</button>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Baking Experience</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="number"
                                                            onWheel={() => document.activeElement.blur()}
                                                            min={0}
                                                            maxLength={2}
                                                            onInput={NumberValidation}
                                                            className={errors?.YearsOfExperienceAsBaker ? "form-control required-field" : "form-control"}
                                                            placeholder="Example : 3"
                                                            name='YearsOfExperienceAsBaker'
                                                            {...register('YearsOfExperienceAsBaker')}
                                                        />
                                                        <select className='form-control w-50' defaultValue='days' onChange={(e) => setExperienceDuration(e.target.value)}>
                                                            <option value='years'>years</option>
                                                            <option value='months'>months</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Number of Days You Can Bake In a Week</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.HowManyDaysCanYouWorkInaWeek ? "form-control required-field" : "form-control"} name='HowManyDaysCanYouWorkInaWeek' {...register('HowManyDaysCanYouWorkInaWeek')}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Type of Baker</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={errors?.JobType ? "form-control required-field" : "form-control"}
                                                        defaultValue=''
                                                        name='JobType'
                                                        {...register('JobType')}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Part Time'>Part Time</option>
                                                        <option value='Full Time'>Full Time</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Can You Bake Cakes Above 5kg</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.CanYouMakeSingleCakeAbove5Kgs ? "form-control required-field" : "form-control"} defaultValue='' name='CanYouMakeSingleCakeAbove5Kgs' {...register('CanYouMakeSingleCakeAbove5Kgs')}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='y'>Yes</option>
                                                        <option value='n'>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Do You Make Tier Cake</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.CanYouMakeTierCakes ? "form-control required-field" : "form-control"} defaultValue='' name='CanYouMakeTierCakes' {...register('CanYouMakeTierCakes')}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='y'>Yes</option>
                                                        <option value='n'>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Comfortable on Working With Mobile Apps</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.AreYouFamiliarOnWorkingWithApps ? "form-control required-field" : "form-control"} defaultValue='' name='AreYouFamiliarOnWorkingWithApps' {...register('AreYouFamiliarOnWorkingWithApps')}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='y'>Yes</option>
                                                        <option value='n'>No</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Types of Cake/Other Product</b> <span className='text-danger'>*</span></label>
                                                <Select
                                                    className={errors?.CakeTypesYouBake && cakeTypeNameList.length === 0 ? "required-field" : ""}
                                                    styles={customStyles}
                                                    options={cakeTypeList}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    name='CakeTypesYouBake'
                                                    {...register('CakeTypesYouBake')}
                                                    onChange={handleAddCakeType}
                                                />
                                                <div className="input-group">
                                                    {/* <div className='display-flex w-100'>
                                                        {CakeType.message === 'No Records Found' ?
                                                            <select className='form-control'>
                                                                <option className='text-center'>No Records Found</option>
                                                            </select> :
                                                            <select defaultValue='' className={errors?.CakeTypesYouBake && cakeTypeNameList.length === 0 ? "form-control required-field" : "form-control"} placeholder="types of cakes" name='CakeTypesYouBake' {...register('CakeTypesYouBake')} onChange={handleAddCakeType}>
                                                                <option value='' disabled='disabled'>-- Select --</option>
                                                                {CakeType.map((val, i) =>
                                                                    <>{val.SubType.length === 0 ?
                                                                        <option className='font-weight-bold' value={val.Type}>{val.Type}</option> :
                                                                        <optgroup label={val.Type}>
                                                                            {val.SubType.map((s, ind) =>
                                                                                <option value={`${val.Type} - ${s.Name}`}>{ind + 1}. {s.Name}</option>
                                                                            )}
                                                                        </optgroup>
                                                                    }</>
                                                                )}
                                                                <option value='Others' className='font-weight-bold'>Others</option>
                                                            </select>
                                                        }
                                                    </div> */}
                                                    {cakeTypeNameList.map((value, index) =>
                                                        <div key={index}>
                                                            <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeTypeName(e, index)}>X</button></div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <p className='text-danger'>{(invalidCakeType && cakeTypeNameList.length === 0) ? 'Cake Type is required' : ''}</p> */}
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Your Speciality Cakes</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="text"
                                                            className="form-control text-capitalize"
                                                            placeholder="speciality cakes"
                                                            name='YourSpecialityCakes'
                                                            {...register('YourSpecialityCakes')}
                                                            value={specialityCakeName}
                                                            onChange={(e) => setSpecialityCakeName(e.target.value)}
                                                        />
                                                        <button className='add-button rounded disabled-add' disabled={specialityCakeNameList.length === 5 || specialityCakeName.length === 0 ? true : false} onClick={handleAddSpecialityCake}>Add</button>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        {specialityCakeNameList.map((value, index) =>
                                                            <div key={index}>
                                                                <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedSpecialityCakeName(e, index)}>X</button></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <p className='text-danger'>{(invalidSpecialityCake && specialityCakeNameList.length === 0) ? 'Speciality cakes is required' : ''}</p> */}
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Self Rating</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.Ratings ? "form-control required-field fa-select" : "form-control fa-select"} name='Ratings' {...register('Ratings')} defaultValue=''>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value="1">1 - Beginner &#160; &#160; &#xf005;</option>
                                                        <option value="2">2 - Average &#160; &#160; &#160; &#xf005; &#xf005;</option>
                                                        <option value="3">3 - Good &#160; &#160; &#160; &#160; &#160; &#xf005; &#xf005; &#xf005;</option>
                                                        <option value="4">4 - Expert &#160; &#160; &#160; &#160; &#xf005; &#xf005; &#xf005; &#xf005;</option>
                                                        <option value="5">5 - Premium &#160; &#160; &#xf005; &#xf005; &#xf005; &#xf005; &#xf005;</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-5 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Do You Bake Regular Cake With Fondant Topper</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.CanYouMakeARegularCakeWithFondantAsToppers && !showImageSelect ? "form-control required-field" : "form-control"} defaultValue='' name='CanYouMakeARegularCakeWithFondantAsToppers' {...register('CanYouMakeARegularCakeWithFondantAsToppers')} onChange={(e) => setShowImageSelect(e.target.value)}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='y'>Yes</option>
                                                        <option value='n'>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3 px-md-2">
                                                <h6 className='pl-2'>Example for Regular Cake with Fondant Toppers</h6>
                                                <hr />
                                                <div>
                                                    <img className="cake-carousel-item" src={FondantTopperImages[0]} alt="cake" height={300} width={300} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom25"><b>Description (400 Characters Only)</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <textarea
                                                        type="text"
                                                        maxLength={400}
                                                        className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"}
                                                        placeholder="Description"
                                                        name='Description'
                                                        {...register('Description')}
                                                    />
                                                </div>
                                                <p className='text-danger'>{showImageError}</p>
                                            </div>
                                            {loader ? <CircleDotSpinner /> :
                                                <>
                                                    <button className="btn btn-primary" type="submit" onClick={handleSubmit(onSubmit)} >Register</button>
                                                    <button className="btn btn-secondary ml-4" type="submit" onClick={() => history.push('/helpdeskV-vendors-list')}>Cancel</button>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <Modal className="modal-min" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1>Enter your Address</h1>
                            <form>
                                <div className="ms-form-group has-icon">
                                    <PlacesAutocomplete value={address ? address : enteredAddress} onChange={handleChange} onSelect={handleSelect}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input className='form-control' {...getInputProps({ placeholder: 'Type here ...' })} />
                                                {loading ? <div>Loading...</div> : null}
                                                {suggestions.map((suggestion, i) => {
                                                    const style = suggestion.active ? { backgroundColor: '#d4d2d2', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                    return (
                                                        <div key={i} {...getSuggestionItemProps(suggestion, { style })}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <button type="submit" onClick={handleGetLatLng} className="btn btn-primary shadow-none">Ok</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showOthersPopup} onHide={() => setShowOthersPopup(false)} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1>Enter Other Product</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-capitalize" placeholder="Types of other product" onChange={(e) => setCakeTypeName(e.target.value)} />
                                    </div>
                                </div>
                                <p className='text-danger'>{showMessage}</p>
                                <button
                                    type="submit"
                                    onClick={handleAddNewCakeType}
                                    className="btn btn-primary mr-3"
                                >
                                    Ok
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleClose}
                                    className="btn btn-secondary"
                                >
                                    Cancel
                                </button>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div >
            }
        </>
    );
}


