import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import { float2D } from '../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory()
    const state = useLocation();

    const Id = state.state.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const { TicketDetails } = useSelector(state => state.TicketReducer);

    useEffect(() => {
        dispatch({
            type: actions.GET_TICKET_DETAILS,
            payload: Id
        });
        //eslint-disable-next-line
    }, [Id]);

    const handleCloseDetails = (e) => {
        e.preventDefault();
        state.state.Page === 'Manager' ? history.push('/Manager-Unclosed-Ticket-List') :
            state.state.Page === 'HelpdeskC' ? history.push('/Helpdesk-C-Unclosed-Tickets-List') :
                state.state.Page === 'Management' ? history.push('/Management-Unclosed-Tickets-List') : history.push('/helpdeskV-Unclosed-Tickets-List');
    };

    useEffect(() => {
        (TicketDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [TicketDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={state.state.Page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6>Ticket Details</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">Ticket Info</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Ticket ID</th>
                                                <td><b>{TicketDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cause Of Ticket</th>
                                                <td>{TicketDetails?.CauseOfTicket}</td>
                                            </tr>
                                            {TicketDetails?.TypeOfOrder &&
                                                <tr>
                                                    <th scope="row">Type Of Order</th>
                                                    <td>{TicketDetails?.TypeOfOrder}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Order_ID &&
                                                <tr>
                                                    <th scope="row">Order ID</th>
                                                    <td>{TicketDetails?.Order_ID}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Order Status</th>
                                                <td>
                                                    <span className={(TicketDetails?.Order_Status?.toUpperCase())?.replace(/ /g, '')}>{TicketDetails?.Order_Status}</span>
                                                </td>
                                            </tr>
                                            {TicketDetails?.Product_ID &&
                                                <tr>
                                                    <th scope="row">Product ID</th>
                                                    <td>{TicketDetails?.Product_ID}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Vendor_ID &&
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td>{TicketDetails?.Vendor_ID}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Ticket Raised On</th>
                                                <td>{TicketDetails?.Ticket_Raised_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ticket Raised By</th>
                                                <td>{TicketDetails?.Ticket_Raised_By?.Name}</td>
                                            </tr>
                                            {TicketDetails?.HelpdeskV_Input &&
                                                <tr>
                                                    <th scope="row">Helpdesk V Input</th>
                                                    <td>{TicketDetails?.HelpdeskV_Input}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Ticket_Approved_Status &&
                                                <tr>
                                                    <th scope="row">Ticket Approved Status</th>
                                                    <td>{TicketDetails?.Ticket_Approved_Status}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Ticket_Approved_Status_Updated_On &&
                                                <tr>
                                                    <th scope="row">Ticket Approved Status Updated On</th>
                                                    <td>{TicketDetails?.Ticket_Approved_Status_Updated_On}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Ticket_Approved_Status_Updated_By &&
                                                <tr>
                                                    <th scope="row">Ticket Approved Status Updated By</th>
                                                    <td>{TicketDetails?.Ticket_Approved_Status_Updated_By?.Email} - {TicketDetails?.Ticket_Approved_Status_Updated_By?.TypeOfUser}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Chancellation_Charges && TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') &&
                                                <tr>
                                                    <th scope="row">Chancellation Charges</th>
                                                    <td>{float2D(TicketDetails?.Chancellation_Charges)}</td>
                                                </tr>
                                            }
                                            {(TicketDetails?.Difference_In_Price && TicketDetails?.CauseOfTicket === 'Change Requests') &&
                                                <tr>
                                                    <th scope="row">Chancellation Charges</th>
                                                    <td>{float2D(TicketDetails?.Difference_In_Price)}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.HelpdeskC_Notes &&
                                                <tr>
                                                    <th scope="row">Helpdesk C Notes</th>
                                                    <td>{TicketDetails?.HelpdeskC_Notes}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Updated_By &&
                                                <tr>
                                                    <th scope="row">Updated By</th>
                                                    <td>{TicketDetails?.Updated_By?.Email} - {TicketDetails?.Updated_By?.TypeOfUser}</td>
                                                </tr>
                                            }
                                            {TicketDetails?.Updated_On &&
                                                <tr>
                                                    <th scope="row">Updated On</th>
                                                    <td>{TicketDetails?.Updated_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">Ticket Description</h4>
                                    <hr />
                                    <p>{TicketDetails?.TicketDescription}</p>
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleCloseDetails}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};