import React from "react";

export default function ChangeRequest(props) {

    const { TicketDetails, handleIntimatePopup, auth, handleEnterInputsPopup,
        handleAcceptTicket, state, handleShowCloseTicketPopup, handleIntimateToCustomerForChangeRequestPopup,
        handleIntimateToCustomerForChangeRequestApprovedPopup } = props;
    // handleAcceptTicket, state, handleHelpdeskCNotes, loader, handleShowCloseTicketPopup } = props;

    // const [notes, setNotes] = useState('');
    // const [notesValidate, setNotesValidate] = useState(false);

    // const handleNotes = (e) => {
    //     setNotes(e.target.value);
    //     (e.target.value?.length > 0) && setNotesValidate(false);
    // };

    // const handleUpdateNotes = (e) => {
    //     e.preventDefault();
    //     if (notes.length > 0) {
    //         setNotesValidate(false);
    //         handleHelpdeskCNotes(notes);
    //     } else {
    //         setNotesValidate(true);
    //     };
    // };

    return (
        <>
            {(!TicketDetails?.HelpdeskC_Accepted_By && state?.state?.Page === "HelpdeskC") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(!TicketDetails?.HelpdeskV_Accepted_By && state?.state?.Page === "HelpdeskV") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV') &&
                TicketDetails?.CauseOfTicket === 'Change Requests' && !TicketDetails?.Ticket_Approved_Status) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterInputsPopup}
                >
                    Enter Inputs
                </button>
            }
            {(!TicketDetails?.HelpdeskC_Notes && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                ((!TicketDetails?.Customer_Approved_Status && TicketDetails?.Ticket_Approved_Status === 'Not Approved') ||
                    ((TicketDetails?.Customer_Approved_Status && TicketDetails?.Customer_Approved_Status !== 'Pending') && TicketDetails?.Ticket_Approved_Status === 'Approved'))) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleIntimateToCustomerForChangeRequestPopup}
                >
                    {TicketDetails?.Ticket_Approved_Status === 'Not Approved' ? 'Intimate To Customer' :
                        'Enter Notes'}
                </button>
                // <>
                // <div>
                //     <hr />
                //     <label htmlFor="validationCustom18"><b className="text-danger">Enter your Notes here</b> <span className='text-danger'>*</span></label>
                //     <div className="input-group">
                //         <textarea className={notesValidate ? 'form-control text-capitalize enter-notes-border required-field' : 'form-control enter-notes-border text-capitalize'} placeholder='Type here...' onChange={handleNotes} />
                //     </div>
                //     {loader ? <CircleDotSpinner /> :
                //         <span className='text-success cursor' onClick={handleUpdateNotes}><u>Click here to submit your notes</u></span>
                //     }
                // </div><br />
                // </>
            }
            {(!TicketDetails?.HelpdeskC_Notes && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                (!TicketDetails?.Customer_Approved_Status && TicketDetails?.Ticket_Approved_Status === 'Approved')) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimateToCustomerForChangeRequestApprovedPopup}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.HelpdeskC_Notes && TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {
                (TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV')
                    && TicketDetails?.Ticket_Approved_Status) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                !TicketDetails?.Customer_Approved_Status && !TicketDetails?.Ticket_Approved_Status) &&
                // !TicketDetails?.Customer_Approved_Status && TicketDetails?.Ticket_Approved_Status !== 'Not Approved') &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    // disabled={(TicketDetails?.Difference_In_Price === 0) ? true : false}
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
        </>
    )
}