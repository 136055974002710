import actions from "./actions";

const initialState = {
    AddOtherProductStatus: [],
    NewOtherProductList: [],
    OtherProductDetails: [],
    ApprovedProductList: [],
    VendorOtherProducts: [],
    VendorOtherOrders: [],
    OtherProductOrderDetails: [],
    UpdateStatusResponse: [],
    AcceptOrCancelStatus: [],
    AllOtherProductOrders: [],
    DeleteOtherProductStatus:[],
    OtherProductResponse: [],
};

const OtherProductReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.ADD_OTHER_PRODUCT_STATUS:
            return {
                ...state,
                AddOtherProductStatus: action.payload.AddOtherProductStatus
            }
        case actions.SET_NEW_OTHER_PRODUCT_LIST:
            return {
                ...state,
                NewOtherProductList: action.payload.NewOtherProductList
            }
        case actions.SET_OTHER_PRODUCT_DETAILS:
            return {
                ...state,
                OtherProductDetails: action.payload.OtherProductDetails
            }
        case actions.SET_APPROVED_PRODUCT_LIST:
            return {
                ...state,
                ApprovedProductList: action.payload.ApprovedProductList
            }
        case actions.SET_OTHER_PRODUCT_BY_VENDOR:
            return {
                ...state,
                VendorOtherProducts: action.payload.VendorOtherProducts
            }
        case actions.SET_VENDOR_OTHER_PRODUCT_ORDERS_LIST:
            return {
                ...state,
                VendorOtherOrders: action.payload.VendorOtherOrders
            }
        case actions.SET_OTHER_PRODUCT_ORDER_DETAILS:
            return {
                ...state,
                OtherProductOrderDetails: action.payload.OtherProductOrderDetails
            }
        case actions.UPDATE_OTHER_PRODUCT_ORDER_STATUS_RESPONSE:
            return {
                ...state,
                UpdateStatusResponse: action.payload.UpdateStatusResponse
            }
        case actions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER_STATUS:
            return {
                ...state,
                AcceptOrCancelStatus: action.payload.AcceptOrCancelStatus
            }
        case actions.SET_ALL_OTHER_PRODUCT_ORDERS_LIST:
            return {
                ...state,
                AllOtherProductOrders: action.payload.AllOtherProductOrders
            }

            case actions.SET_DELETE_OTHER_PRODUCT_STATUS :
                return {
                  ...state,
                  DeleteOtherProductStatus : action.payload.DeleteOtherProductStatus
                }
            case actions.OTHER_PRODUCT_RESPONSE :
                return {
                  ...state,
                  OtherProductResponse : action.payload
                }
        default:
            return state
    }
};

export default OtherProductReducer;