import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/Admin/actions';
import TableSearch from '../../../common/TableSearch';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import ReactPagination from '../../../common/React-Pagination';

export default function Content() {

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { Customer_Ratings } = useSelector(state => state.adminReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_CUSTOMER_RATINGS_LIST });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (Customer_Ratings.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(Customer_Ratings.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.Vendor_ID && val.Vendor_ID.toLowerCase().includes(search)) ||
                    (val.Customer_Ratings && val.Customer_Ratings.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [Customer_Ratings, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        setsearch('');
        dispatch({ type: actions.GET_CUSTOMER_RATINGS_LIST });
    };

    useEffect(() => {
        (Customer_Ratings.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [Customer_Ratings]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Customer Ratings</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search here..." onChange={handleSearchbar} value={search || ''} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Customer Mobile Number</th>
                                                                <th scope="col">Ratings</th>
                                                                <th scope="col">Ratings Updated On</th>
                                                                <th scope="col">Product ID</th>
                                                                <th scope="col">Vendor ID</th>
                                                                <th scope="col">Vendor Name</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((value) =>
                                                                    <tr key={value._id}>
                                                                        <td>{value.Id}</td>
                                                                        <td>{value.UserName}</td>
                                                                        <td>{value.UserPhoneNumber}</td>
                                                                        <td><span className={(value.Customer_Ratings?.toUpperCase())?.replace(/ /g, '')}>{value.Customer_Ratings}</span></td>
                                                                        <td>{value.Ratings_Updated_On ?? '-'}</td>
                                                                        <td>{(value.Cake_ID && value.CustomizeCake === 'n') ? value.Cake_ID : value.Other_Product_ID ? value.Other_Product_ID :
                                                                            value.Hamper_ID ? value.Hamper_ID : '-'}</td>
                                                                        <td>{value.Vendor_ID}</td>
                                                                        <td>{value.VendorName}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};