import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../../common/MenuLink';
import Dropdown from '../../common/dropdown';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import Alert from '../../common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { MdDashboard } from 'react-icons/md';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { RiCake2Fill, RiFileSearchFill } from 'react-icons/ri';
import { GiBalloons } from 'react-icons/gi';
import { MdChat } from 'react-icons/md';
import { IoGift } from 'react-icons/io5';

export default function Sidenavigation() {

    //get the current path
    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'NCO' || Filtered_Dates?.Page === 'OPO')) &&
                (pathName !== '/Vendor-OrderList' && pathName !== '/Vendor-OrderDetails' && pathName !== '/vendor-product-order-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'CCO')) && (pathName !== '/Vendor-customize-cake-list' && pathName !== '/Vendor-customize-cake-details' && pathName !== '/Vendor-customize-cake-invoice')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'HO')) && (pathName !== '/vendor-hamper-orders-list' && pathName !== '/vendor-hamper-order-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'VRCO')) && (pathName !== '/orders-report' && pathName !== '/Vendor-OrderDetails')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'VROPO')) && (pathName !== '/orders-report' && pathName !== '/vendor-product-order-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'VRHO')) && (pathName !== '/orders-report' && pathName !== '/vendor-hamper-order-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'VDRO-OD' || Filtered_Dates?.Page === 'VDRO-DD')) && (pathName !== '/vendordashboard' && pathName !== '/Vendor-OrderDetails')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'VDRCCO-OD' || Filtered_Dates?.Page === 'VDRCCO-DD')) && (pathName !== '/vendordashboard' && pathName !== '/Vendor-customize-cake-details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        };
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            {/* dashboard start  */}
                            <MenuLink to="/vendordashboard">
                                <MdDashboard className="sidenavigationIcon" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                            {/* dashboard end  */}
                        </li>
                        {/* orders dropdown start  */}
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-cake-update' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-OrderDetails' || pathName === '/Vendor-customize-cake-invoice' ||
                                    pathName === '/Vendor-topper-details' || pathName === '/vendor-hamper-order-details' ||
                                    pathName === '/vendor-hampers-details' || pathName === '/vendor-product-details' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/vendor-product-edit'
                                    ? '/Vendor-OrderList' : '#'}
                                className={(pathName === '/Vendor-OrderList' || pathName === '/Vendor-customize-cake-list' ||
                                    pathName === '/vendor-hamper-orders-list' || pathName === '/Vendor-OrderDetails' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-customize-cake-invoice' || pathName === '/vendor-hamper-order-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Vendor-OrderList" >Cakes &amp; Other Product Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Vendor-customize-cake-list" >Customised Cakes Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/vendor-hamper-orders-list" >Hamper Orders</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        {/* orders dropdown end  */}
                        {/* cakes dropdown start  */}
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-cake-update' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-OrderDetails' || pathName === '/Vendor-customize-cake-invoice' ||
                                    pathName === '/Vendor-topper-details' || pathName === '/vendor-hamper-order-details' ||
                                    pathName === '/vendor-hampers-details' || pathName === '/vendor-product-details' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/vendor-product-edit'
                                    ? '/vendor-cakes-list' : '#'}
                                className={(pathName === '/add-main' || pathName === '/vendor-cakes-list' || pathName === '/add-cake' ||
                                    pathName === '/add-others' || pathName === '/vendor-cakes-list' || pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-product-edit' || pathName === '/vendor-product-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <RiCake2Fill className="sidenavigationIcon" /><span className='pl-3'>Cakes &amp; Other Products</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/add-main" >Add Cake &amp; Other Product</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/vendor-cakes-list" >Cakes &amp; Other Products List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        {/* cakes dropdown end  */}
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-cake-update' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-OrderDetails' || pathName === '/Vendor-customize-cake-invoice' ||
                                    pathName === '/Vendor-topper-details' || pathName === '/vendor-hamper-order-details' ||
                                    pathName === '/vendor-hampers-details' || pathName === '/vendor-product-details' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/vendor-product-edit'
                                    ? '/vendor-hampers-list' : '#'}
                                className={(pathName === '/Add-hampers' || pathName === '/vendor-hampers-list' ||
                                    pathName === '/vendor-hampers-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <IoGift className="sidenavigationIcon" /><span className='pl-3'>Hampers</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Add-hampers" >Add Hamper</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/vendor-hampers-list" >Hampers List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        {/* cake toppers dropdown start  */}
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-cake-update' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-OrderDetails' || pathName === '/Vendor-customize-cake-invoice' ||
                                    pathName === '/Vendor-topper-details' || pathName === '/vendor-hamper-order-details' ||
                                    pathName === '/vendor-hampers-details' || pathName === '/vendor-product-details' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/vendor-product-edit'
                                    ? '/Vendor-toppers-list' : '#'}
                                className={(pathName === '/Add-topper' || pathName === '/Vendor-toppers-list' ||
                                    pathName === '/Vendor-topper-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <GiBalloons className="sidenavigationIcon" /><span className='pl-3'>Toppers</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Add-topper" >Add Topper</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Vendor-toppers-list" >Toppers List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Vendor-cake-details' ||
                                    pathName === '/vendor-cake-update' || pathName === '/Vendor-customize-cake-details' ||
                                    pathName === '/Vendor-OrderDetails' || pathName === '/Vendor-customize-cake-invoice' ||
                                    pathName === '/Vendor-topper-details' || pathName === '/vendor-hamper-order-details' ||
                                    pathName === '/vendor-hampers-details' || pathName === '/vendor-product-details' ||
                                    pathName === '/vendor-product-order-details' || pathName === '/vendor-product-edit'
                                    ? '/orders-report' : '#'}
                                className={(pathName === '/orders-report' || pathName === '/login-time' || pathName === '/payments') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <RiFileSearchFill className="sidenavigationIcon" /><span className='pl-3'>Reports</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/orders-report" >Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/login-time" >Login Time</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/payments" >Payments</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Chat">
                                <MdChat className="sidenavigationIcon" /><span className='pl-3'>Chat </span>
                            </MenuLink>
                        </li>
                    </ul >
                </Scrollbar>
            </div >
        </>
    );
};