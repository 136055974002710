import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions'
import { API_URL } from '../../utils/constants';

const SampleSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_PINCODE_LIST, GetPincodeList),
        yield takeEvery(actions.GET_MONTHWISE_SALES_COUNT, GetMonthwiseSalesCount),
        yield takeEvery(actions.UPDATE_VENDOR_ACTIVE, updateVendorActive),
    ]);
};

//get vendor cakes list by id
const GetPincodeList = function* () {

    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/pincode`)
        );

        yield put({
            type: actions.SET_PINCODE_LIST,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

const updateVendorActive = function* (data) {
    const { payload } = data;

    try {

         yield call(() =>
            axios.put(`${API_URL}/update/vendorLogout/${payload}`)
        );

    } catch (err) {
        console.log(err.response.data);
    }
};

const GetMonthwiseSalesCount = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/sales/${payload.StartDate}/${payload.EndDate}/${payload.Type}`)
        );

        yield put({
            type: actions.SET_MONTHWISE_SALES_COUNT,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

export default SampleSaga;