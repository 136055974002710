import React from 'react';
import { Modal } from 'react-bootstrap';

export default function VendorDetailsPopup(props) {

    const {
        handleCloseDetailsPopup,
        showVendorDetailsPopup,
        VendorDetails
    } = props;

    return (
        <div>
            <Modal show={showVendorDetailsPopup} onHide={handleCloseDetailsPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button
                        type="button"
                        className="close change-password-close"
                        onClick={handleCloseDetailsPopup}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="bold text-center">Vendor Details</h4>
                            {VendorDetails.length === 0 ?
                                <div className='h6 text-center mt-4'>Loading...</div> :
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Vendor ID</th>
                                            <td><b>{VendorDetails?.Id}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Name</th>
                                            <td><span className="long-line-break">{VendorDetails?.VendorName}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Address</th>
                                            <td><span className="long-line-break">{VendorDetails?.DoorNo}, {VendorDetails?.Address}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Main Mobile Number</th>
                                            <td>{VendorDetails?.PhoneNumber1}</td>
                                        </tr>
                                        {VendorDetails?.PhoneNumber2 &&
                                            <tr>
                                                <th scope="row">Vendor Alternative Mobile Number</th>
                                                <td>{VendorDetails?.PhoneNumber2}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
};