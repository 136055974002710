import React from 'react';

export default function VendorRequest(props) {

    const { TicketDetails, auth, handleIntimatePopup, state, handleEnterSolutionPopup,
        handleManagerRemarksPopup, handleShowCloseTicketPopup } = props;

    return (
        <>
            {!TicketDetails?.Final_Solution &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterSolutionPopup}
                >
                    Enter Solution
                </button>
            }
            {(state?.state?.Page === 'Manager' && !TicketDetails?.Final_Solution) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleManagerRemarksPopup}
                >
                    Remarks
                </button>
            }
            {(state?.state?.Page === 'HelpdeskV' && TicketDetails?.Final_Solution && TicketDetails?.Ticket_Status !== "Closed") &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {
                (TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email
                    && TicketDetails?.Ticket_Status !== "Closed" && !TicketDetails?.Final_Solution) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
        </>
    )
};