import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import OrderActions from '../../../../../redux/VendorOrderList/actions';
import OtherActions from '../../../../../redux/OtherProducts/actions';
import HamperActions from '../../../../../redux/HampersOrder/actions';
import Preloader from '../../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import DeliveredOrders from './DeliveredOrders';
import OrderList from './OrderList';
import PendingOrders from './PendingOrders';

export default function Content() {

    const dispatch = useDispatch();

    const [key, setKey] = useState('Order List');
    const [CakeOrderList, setCakeOrderList] = useState([]);
    const [OtherOrderList, setOtherOrderList] = useState([]);
    const [HamperOrderList, setHamperOrderList] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(true);

    const { token } = useSelector((state) => state.authReducer);

    const { Vendor_Order_list } = useSelector(state => state.VendorOrderReducer);

    const { VendorOtherOrders } = useSelector(state => state.OtherProductReducer);

    const { VendorHamperOrders } = useSelector(state => state.HamperOrderReducer);

    const tokenId = token?.result?._id;

    useEffect(() => {
        dispatch({ type: OrderActions.GET_VENDOR_ORDER_LIST, payload: tokenId });
        dispatch({ type: OtherActions.GET_VENDOR_OTHER_PRODUCT_ORDERS_LIST, payload: tokenId });
        dispatch({ type: HamperActions.GET_VENDOR_HAMPER_ORDERS_LIST, payload: tokenId });
        // eslint-disable-next-line
    }, [tokenId]);

    useEffect(() => {
        (Vendor_Order_list?.message === 'No Orders') ?
            setCakeOrderList([]) : setCakeOrderList(Vendor_Order_list);
    }, [Vendor_Order_list]);

    useEffect(() => {
        (VendorOtherOrders?.message === 'No Records Found') ?
            setOtherOrderList([]) : setOtherOrderList(VendorOtherOrders);
    }, [VendorOtherOrders]);

    useEffect(() => {
        (VendorHamperOrders?.message === 'No Records Found') ?
            setHamperOrderList([]) : setHamperOrderList(VendorHamperOrders);
    }, [VendorHamperOrders]);

    useEffect(() => {
        (Vendor_Order_list.length === 0 || VendorOtherOrders.length === 0 || VendorHamperOrders.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
    }, [Vendor_Order_list, VendorOtherOrders, VendorHamperOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Orders</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3 tab-style"
                                    >
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Order List"
                                            title="Orders List"
                                        >
                                            <OrderList
                                                CakeOrderList={CakeOrderList}
                                                OtherOrderList={OtherOrderList}
                                                HamperOrderList={HamperOrderList}
                                            />
                                        </Tab>
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Pending Orders"
                                            title="Pending Orders"
                                        >
                                            <PendingOrders
                                                CakeOrderList={CakeOrderList}
                                                HamperOrderList={HamperOrderList}
                                                OtherOrderList={OtherOrderList}
                                            />
                                        </Tab>
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Delivered Orders"
                                            title="Delivered Orders"
                                        >
                                            <DeliveredOrders
                                                CakeOrderList={CakeOrderList}
                                                HamperOrderList={HamperOrderList}
                                                OtherOrderList={OtherOrderList}
                                            />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}