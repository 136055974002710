import actions from "./actions";

const initialState = {
  registeredUsers: [],
  UserDetails: [],
};

const registeredUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ALL_USERS:
      return {
        ...state,
        registeredUsers: action.payload
      }
    case actions.SET_USER_DETAILS:
      return {
        ...state,
        UserDetails: action.payload.UserDetails
      }
    default:
      return state;
  }
};

export default registeredUserReducer;