import React from 'react';

export default function OrderCancellationByVendor(props) {
    const { handleUpdateCostDifferencePopup, handleEnterInputByHelpdeskV, TicketDetails, state,
        auth, handleAcceptTicket, handleEnterInputPopupVendorCancel, handleIntimatePopup,
        handleShowCloseTicketPopup, OrderDetailsByTypeOfOrder, handleVendorCancelIntimateToAccountsPopup,
    } = props;
    // handleIntimatePopup

    return (
        <>
            {(!TicketDetails?.HelpdeskC_Accepted_By && state?.state?.Page === "HelpdeskC") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && state?.state?.Page === "HelpdeskV" &&
                !TicketDetails?.VendorCallConversation && TicketDetails?.IsVendorAvailable !== 'No Vendors') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleUpdateCostDifferencePopup}
                >
                    Select Vendor
                </button>
            }

            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status && OrderDetailsByTypeOfOrder.Status !== 'Cancelled') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterInputPopupVendorCancel}
                >
                    Enter Inputs
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status && OrderDetailsByTypeOfOrder.Status === 'Cancelled' && TicketDetails?.Ticket_Status !== 'Aborted') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterInputPopupVendorCancel}
                >
                    Abort
                </button>
            }

            {/* {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.FinalCost && (!TicketDetails?.Ticket_Approved_Status || TicketDetails?.Ticket_Approved_Status) &&
                (TicketDetails?.Refund_Amount !== "0" || TicketDetails?.Refund_Status !== 'Success' || TicketDetails?.Refund_Status !== 'Coupon Generated') && !TicketDetails?.HelpdeskV_Remarks) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            } */}

            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.Ticket_Status === 'In Progress' && (TicketDetails?.FinalCost || TicketDetails?.IsVendorAvailable === 'No Vendors') &&
                // (!TicketDetails?.Ticket_Approved_Status || TicketDetails?.Ticket_Approved_Status) && ((OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') && (!TicketDetails?.HelpdeskV_Remarks) && (TicketDetails?.Refund_By?.TypeOfUser !== 'Accounts'))
                (!TicketDetails?.Ticket_Approved_Status || (TicketDetails?.Ticket_Approved_Status && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery')) && ((!TicketDetails?.HelpdeskV_Remarks) && (TicketDetails?.Refund_By?.TypeOfUser !== 'Accounts' && TicketDetails?.Refund_Status !== 'No Refund'))
            ) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleVendorCancelIntimateToAccountsPopup}
                >
                    Intimate to Accounts
                </button>
            }
            {/* {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.FinalCost &&
                // (!TicketDetails?.Ticket_Approved_Status || TicketDetails?.Ticket_Approved_Status) && ((OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery') && (!TicketDetails?.HelpdeskV_Remarks) && (TicketDetails?.Refund_By?.TypeOfUser !== 'Accounts'))
                (!TicketDetails?.Ticket_Approved_Status || (TicketDetails?.Ticket_Approved_Status && OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() !== 'cash on delivery')) && ((!TicketDetails?.HelpdeskV_Remarks) && (TicketDetails?.Refund_By?.TypeOfUser !== 'Accounts' && TicketDetails?.Refund_Status !== 'No Refund'))
            ) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleVendorCancelIntimateToAccountsPopup}
                >
                    Intimate
                </button>
            } */}
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }

            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && ((TicketDetails?.Ticket_Status === 'Aborted' && !TicketDetails?.HelpdeskV_Remarks) || (TicketDetails?.Ticket_Approved_Status && (TicketDetails?.Refund_Amount === "0" ||
                    TicketDetails?.Refund_Status === 'Success' || TicketDetails?.Refund_Status === 'Coupon Generated' ||
                    OrderDetailsByTypeOfOrder?.PaymentStatus !== 'Paid') && !TicketDetails?.HelpdeskV_Remarks))) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterInputByHelpdeskV}
                >
                    Remarks
                </button>
            }

            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && ((TicketDetails?.AbortReason && TicketDetails?.HelpdeskV_Remarks) || (TicketDetails?.Ticket_Approved_Status && (TicketDetails?.Refund_Amount === "0" ||
                    TicketDetails?.Refund_Status === 'Success' || TicketDetails?.Refund_Status === 'Coupon Generated' || (OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() === 'cash on delivery' && TicketDetails?.Refund_Status === 'Pending')) && TicketDetails?.HelpdeskV_Remarks)) && (TicketDetails?.Ticket_Status !== 'Closed')) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }


        </>
    )
}