import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import VendorActions from '../../../redux/Vendor/actions';
import userActions from '../../../redux/users/actions';
import CustomizedCakeActions from '../../../redux/Customize-Cake/actions';
import OrderActions from '../../../redux/Order/actions';
import CommonActions from '../../../redux/Common/actions';
import TicketsDetails from './Details/Ticket-Details';
import OrderCancellationByCustomer from './Details/OrderCancellationByCustomer';
import ChangeRequest from './Details/ChangeRequest';
import HelpdeskCFullyCustomizedCake from './Details/HelpdeskCFullyCustomizedCake';
import TicketIntimationPopup from './Popups/TicketIntimationPopup';
import EnterInputPopup from './Popups/EnterInputPopup';
import EnterInputPopupVendorCancel from './Popups/EnterInputVendorCancelOrderPopup';
import OrderDetailsPopup from './Popups/OrderDetailsPopup';
import VendorDetailsPopup from './Popups/VendorDetailsPopup';
import Preloader from '../../layouts/Preloader';
import { useForm } from "react-hook-form";
import HelpdeskCFullyCustomizedCakeAssignPopup from './Popups/HelpdeskCFullyCustomizedCakeAssignPopup';
import HelpdeskVEditPricePopup from './Popups/HelpdeskVEditPricePopup';
import ManagerEditPricePopup from './Popups/ManagerEditPricePopup';
import CloseTicketPopup from './Popups/CloseTicketPopup';
import Miscellaneous from './Details/Miscellaneous';
import EnterSolutionPopup from './Popups/EnterSolutionPopup';
import ManagerRemarksPopup from './Popups/ManagerRemarksPopup';
import OrderCancellationByVendor from './Details/OrderCancellationByVendor';
import UpdateCostDifferencePopup from './Popups/HelpdeskVUpdateCostDifferencePopup'
import cakeActions from '../../../redux/cake/actions'
import OtherActions from '../../../redux/OtherProducts/actions'
import VendorRequest from './Details/VendorRequest';
import UpdateCustomerApproval from './Popups/UpdateCustomerApproval';
import HelpdeskVCakeyDirectFullyCustomizedCake from './Details/HelpdeskVCakeyDirectFullyCustomisedCake';
import HelpdeskVFullyCustomizedCakeSelectVendorPopup from './Popups/HelpdeskVFullyCustomizedCakeSelectVendorPopup';
import VendorApprovedStatusPopup from './Popups/VendorApprovedStatusPopup';
import HelpdeskVRemarksPopup from './Popups/HelpdeskVRemarksPopup';
import CustomizedCakeThroughVendor from './Details/CustomizedCakeThroughVendor';
import HelpdeskVEditPriceDirectVendorCustomizedCakePopup from './Popups/HelpdeskVEditPrice-DirectVendorCustomizedCakePopup';
import IntimateToCustomerForPayment from './Popups/IntimateToCustomerForPaymentPopup';
import HelpdeskVRemarksForVendorCCPopup from './Popups/HelpdeskVRemarksForVendorCCPopup';
import CakeyDirectGeneralOrder from './Details/CakeyDirectGeneralOrder';
import HelpdeskVCakeyGeneralOrderSelectVendorPopup from './Popups/HelpdeskVCakeyGeneralOrderSelectVendorPopup';
import HelpdeskVRemarksForVendorCancelPopup from './Popups/HelpdeskVRemaksForVendorCancel';
import CancellationWithin15Mins from './Details/CancellationWithin15Mins';
import IntimateToCustomerForCancel from './Popups/IntimateToCustomerForCancelPopup';
import EnterNotesForChangeRequest from './Popups/EnterNotesForChangeRequest';
import IntimationForChangeRequestApprovedPopup from './Popups/IntimationForChangeRequestApprovedPopup';
import CustomizedCakeThroughVendorEnterNotesPopup from './Popups/CustomizedCakeThroughVendorEnterNotesPopup';
import { PushNotification } from '../../common/PushNotification';
import IntimateToAccountsForCustomerCancel from './Popups/IntimateToAccountsForCustomerCancelPopup';
import EnterNotesForCustomerCancel from './Popups/EnterNotesForCustomerCancelPopup';
import HelpdeskVVendorCancelIntimateToAccountsPopup from './Popups/HelpdeskV_VendorCancel_IntimateToAccountsPopup';
import calculateDistance from '../../common/DistanceCalculation';
import { GOOGLE_API_KEY } from '../../../../src/utils/constants';
import UserDetailsPopup from './Popups/UserDetailsPopup';
import IntimationToManagerForMiscellaneous from './Popups/IntimateToManagerForMiscellaneousPopup';
import MiscellaneousApprovalByManager from './Popups/MiscellaneousApprovalByManagerPopup';
import IntimateToAccountsForMiscellaneous from './Popups/IntimateToAccountsForMiscellaneousPopup';
import HelpdeskCRemarksForMiscellaneousPopup from './Popups/HelpdeskCRemarksForMiscellaneousPopup';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory()
    const state = useLocation();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showIntimateListPopup, setShowIntimateListPopup] = useState(false);
    const [showhandleUpdateCostDifferencePopup, setShowhandleUpdateCostDifferencePopup] = useState(false)
    const [showEnterInputPopup, setShowEnterInputPopup] = useState(false);
    const [showHelpdeskVEnterInputPopup, setShowHelpdeskVEnterInputPopup] = useState(false);
    const [showOrderDetailsPopup, setShowOrderDetailsPopup] = useState(false);
    const [showVendorDetailsPopup, setShowVendorDetailsPopup] = useState(false);
    const [showUserDetailsPopup, setShowUserDetailsPopup] = useState(false);
    const [showHelpdeskCFullyCustomizedCakeAssignPopup, setShowHelpdeskCFullyCustomizedCakeAssignPopup] = useState(false);
    const [showHelpdeskVEditPricePopup, setShowHelpdeskVEditPricePopup] = useState(false);
    const [showManagerEditPricePopup, setShowManagerEditPricePopup] = useState(false);
    const [showCloseTicketPopup, setShowCloseTicketPopup] = useState(false);
    const [showEnterSolutionPopup, setShowEnterSolutionPopup] = useState(false);
    const [showManagerRemarksPopup, setShowManagerRemarksPopup] = useState(false);
    const [showUpdateCustomerApprovalPopup, setShowUpdateCustomerApprovalPopup] = useState(false);
    const [showHelpdeskVSelectVendorPopup, setShowHelpdeskVSelectVendorPopup] = useState(false);
    const [showUpdateVendorApprovedStatusPopup, setShowUpdateVendorApprovedStatusPopup] = useState(false);
    const [showHelpdeskVRemarksPopup, setShowHelpdeskVRemarksPopup] = useState(false);
    const [showHelpdeskVRemarksForVendorCancel, setShowHelpdeskVRemarksForVendorCancel] = useState(false);
    const [showHelpdeskVEditPriceForVendorCCPopup, setShowHelpdeskVEditPriceForVendorCCPopup] = useState(false);
    const [showIntimateToCustomerPopup, setShowIntimateToCustomerPopup] = useState(false);
    const [showIntimateToVendorPopup, setShowIntimateToVendorPopup] = useState(false);
    const [showHelpdeskVCakeyGOSelectVendorPopup, setShowHelpdeskVCakeyGOSelectVendorPopup] = useState(false);
    const [showIntimateToCustomerForCancelPopup, setShowIntimateToCustomerForCancelPopup] = useState(false);
    const [showIntimateToCustomerForChangeRequestPopup, setShowIntimateToCustomerForChangeRequestPopup] = useState(false);
    const [showIntimateToCustomerForChangeRequestApprovedPopup, setShowIntimateToCustomerForChangeRequestApprovedPopup] = useState(false);
    const [showEnterNotesForCCThroughVendorPopup, setShowEnterNotesForCCThroughVendorPopup] = useState(false);
    const [showIntimateToAccountsForCustomerCancelPopup, setShowIntimateToAccountsForCustomerCancelPopup] = useState(false);
    const [showEnterNotesForCustomerCancelPopup, setShowEnterNotesForCustomerCancelPopup] = useState(false);
    const [showVendorCancelIntimateToAccountsPopup, setShowVendorCancelIntimateToAccountsPopup] = useState(false);
    const [showIntimateToManagerForMiscellaneous, setShowIntimateToManagerForMiscellaneous] = useState(false);
    const [showMiscellaneousApprovalByManagerPopup, setShowMiscellaneousApprovalByManagerPopup] = useState(false);
    const [showHelpdeskCRemarksForMiscellaneous, setShowHelpdeskCRemarksForMiscellaneous] = useState(false);
    const [showIntimateToAccountsForMiscellaneous, setShowIntimateToAccountsForMiscellaneous] = useState(false);
    const [enableIntimate, setEnableIntimate] = useState(true);
    const [loader, setLoader] = useState(false);
    let [NoVendor, setNoVendor] = useState('');

    let [ticket_Accessed_By, setTicket_Accessed_By] = useState({
        HelpdeskV: 'n', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n', Customer: 'n', Vendor: 'n'
    });
    let [last_Intimate, setLast_Intimate] = useState([]);
    let [priceType, setPriceType] = useState('Price');

    const { TicketDetails, Ticket_Response, OrderDetailsByTypeOfOrder } = useSelector(state => state.TicketReducer);

    const { VendorDetails, vendorsList } = useSelector(state => state.VendorReducer);

    const { CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    const { Tax, DeliveryCharge } = useSelector(state => state.OrderReducer)

    const { CakeDetails } = useSelector(state => state.CakesReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const auth = useSelector((state) => state.authReducer);

    const { register, handleSubmit, formState: { errors }, setError, clearErrors, setValue, resetField, reset } = useForm();

    const { register: register1, handleSubmit: handleSubmit1, formState: { errors1 }, reset: reset2 } = useForm();

    const Id = state?.state?.Id;
    const TicketAccessedBy = TicketDetails?.Ticket_Accessed_By;
    const OrderID = TicketDetails?.OrderID;
    // const TypeOfUser = TicketDetails?.TypeOfOrder;
    const LoggedIn_User = {
        ID: auth?.token?.result?._id, Name: auth?.token?.result?.Name,
        Email: auth?.token?.result?.Email, TypeOfUser: auth?.token?.result?.TypeOfUser
    };
    var [ProductList, setProductList] = useState([]);
    const TypeOfOrder = TicketDetails?.TypeOfOrder;
    const ProductSubType = OrderDetailsByTypeOfOrder?.CakeSubType;
    const CakeID = OrderDetailsByTypeOfOrder?.CakeID;
    const CauseOfTicket = TicketDetails?.CauseOfTicket;
    const UserID = TicketDetails?.UserID;

    const { AdminCakesList } = useSelector(state => state.CakesReducer);
    const { ApprovedProductList } = useSelector(state => state.OtherProductReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_TICKET_DETAILS, payload: Id });
        dispatch({
            type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
            payload: { Id: OrderID, TypeOfOrder: TypeOfOrder?.toLowerCase()?.replace(/ /g, '') }
        });
        dispatch({ type: OrderActions.GET_TAX });
        dispatch({ type: OrderActions.GET_DELIVERY_CHARGE });
        //eslint-disable-next-line
    }, [Id, OrderID, TypeOfOrder]);

    useEffect(() => {
        if (TypeOfOrder === 'Cake Order') {
            dispatch({ type: cakeActions.GET_ADMIN_CAKES_LIST })
        } else if (TypeOfOrder === 'Product Order') {
            dispatch({ type: OtherActions.GET_APPROVED_PRODUCT_LIST });
        }
        //eslint-disable-next-line
    }, [TypeOfOrder]);

    useEffect(() => {
        if (CauseOfTicket === 'Cakey Direct-General Order' && CakeID) {
            dispatch({ type: cakeActions.GET_SINGLE_CAKEDETAILS, payload: CakeID });
        };
        //eslint-disable-next-line
    }, [CauseOfTicket, CakeID]);

    useEffect(() => {
        if (UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: UserID });
        };
        //eslint-disable-next-line
    }, [UserID]);

    useEffect(() => {
        (TicketAccessedBy) && setTicket_Accessed_By(TicketAccessedBy);
    }, [TicketAccessedBy]);

    //close details page
    const handleCloseDetails = (e) => {
        e.preventDefault();
        if (state?.state?.Tab && state?.state?.Tab === 'Dashboard') {
            state?.state?.Page === 'HelpdeskC' ? history.push('/Helpdesk-C-Dashboard') :
                state?.state?.Page === 'Manager' ? history.push('/Manager-Dashboard') :
                    state?.state?.Page === 'HelpdeskV' ? history.push('/helpdeskVdashboard') : history.push('/Management-Dashboard');
        } else if (state?.state?.Tab && state?.state?.Tab === 'All') {
            state?.state?.Page === 'Manager' ? history.push('/Manager-All-Tickets-List') : history.push('/Management-All-Tickets-List');
        } else if (state?.state?.Tab && state?.state?.Tab === 'UnclosedTickets') {
            state?.state?.Page === 'Manager' ? history.push('/Manager-Unclosed-Ticket-List') :
                state?.state?.Page === 'HelpdeskC' ? history.push('/Helpdesk-C-Unclosed-Tickets-List') :
                    state?.state?.Page === 'Management' ? history.push('/Management-Unclosed-Tickets-List') : history.push('/helpdeskV-Unclosed-Tickets-List');
        }
        else {
            state?.state?.Page === 'HelpdeskC' ? history.push('/Helpdesk-C-Tickets-List') :
                state?.state?.Page === 'Manager' ? history.push('/Manager-Tickets-List') :
                    state?.state?.Page === 'HelpdeskV' ? history.push('/helpdeskV-Tickets-List') : history.push('/Management-Tickets-List');
        }
    };

    //accept ticket
    const handleAcceptTicket = () => {
        let Ticket_Accepted_By, Intimated_By;
        if (state?.state?.Page === "HelpdeskC") {
            Ticket_Accepted_By = 'HelpdeskC_Accepted_By';
            (TicketDetails?.Ticket_Raised_By?.TypeOfUser === 'Customer') ? Intimated_By = 'Customer' : Intimated_By = 'HelpdeskV';
        } else {
            Ticket_Accepted_By = 'HelpdeskV_Accepted_By';
            (TicketDetails?.Ticket_Raised_By?.TypeOfUser === 'Vendor') ? Intimated_By = 'Vendor' : Intimated_By = 'HelpdeskC';
        };
        const data = {
            [Ticket_Accepted_By]: LoggedIn_User,
            Intimated_By: Intimated_By
        };
        dispatch({ type: actions.ACCEPT_TICKET, payload: { Id: Id, data: data } });
    };

    //set intimation popup -> true
    const handleIntimatePopup = (e) => {
        e.preventDefault();
        setShowIntimateListPopup(true);
        if (TicketDetails?.CauseOfTicket === 'Miscellaneous') {
            setEnableIntimate(false);
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Manager: 'y' });
            setLast_Intimate(last_Intimate = ['Manager']);
        };
        if (state?.state?.Page === 'Manager') {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: TicketDetails?.VendorID } });
        };
    };

    //close intimation popup
    const handleCloseIntimatePopup = () => {
        setShowIntimateListPopup(false);
        setLast_Intimate([]);
        setTicket_Accessed_By(TicketAccessedBy);
        setEnableIntimate(true);
    };

    //intimation change
    const handleChangedAccess = (e, selected) => {
        let fieldName = selected;
        if (e.target.checked) {
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [fieldName]: 'y' });
            setLast_Intimate(last_Intimate = [...last_Intimate, fieldName]);
        } else {
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [fieldName]: 'n' });
            if (last_Intimate.includes(fieldName)) {
                let Array = [...last_Intimate];
                Array.splice(last_Intimate.indexOf(fieldName), 1);
                setLast_Intimate(last_Intimate = Array);
            }
        };
        last_Intimate.length === 0 ? setEnableIntimate(true) : setEnableIntimate(false);
    };

    //update intimation
    const handleUpdateIntimate = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TICKET, payload: {
                Id: TicketDetails?._id,
                data: {
                    Updated_By: LoggedIn_User,
                    Ticket_Accessed_By: ticket_Accessed_By,
                    Last_Intimate: last_Intimate,
                }
            }
        });
    };

    //set enter input popup -> true
    const handleEnterInputsPopup = (e) => {
        e.preventDefault();
        setShowEnterInputPopup(true);
        dispatch({
            type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
            payload: { Id: OrderID, TypeOfOrder: TypeOfOrder?.toLowerCase()?.replace(/ /g, '') }
        });
    };

    //close enter input popup
    const handleCloseEnterInputPopup = () => {
        // e.preventDefault();
        setShowEnterInputPopup(false);
        reset();
    };

    //update ticket status -> cause: Order Cancellation By Customer
    const handleUpdateStatus = (data) => {
        let formData;
        let Last_Intimate = ['HelpdeskC', 'Manager'];
        for (let i = 0; i < Last_Intimate?.length; i++) {
            let Access = Last_Intimate[i];
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [Access]: 'y' });
        };

        if (state?.state?.Page === "HelpdeskV") {
            let Refund_Amount, Refund_Status;
            if (data.Ticket_Approved_Status === 'Approved') {
                let Total = (parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ?
                    parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) : (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue));
                Refund_Amount = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ?
                    (parseFloat(Total) - parseFloat(data.Cancellation_Charges || 0))?.toString() : "0";
                Refund_Status = OrderDetailsByTypeOfOrder?.PaymentStatus === 'Paid' ? "Pending" : "Cash On Delivery";
            };
            formData = {
                HelpdeskV_Input: data.Input,
                Ticket_Approved_Status: data.Ticket_Approved_Status,
                Cancellation_Charges: data.Cancellation_Charges,
                Last_Intimate: Last_Intimate,
                Ticket_Accessed_By: ticket_Accessed_By,
                Ticket_Approved_Status_Updated_By: LoggedIn_User,
                Refund_Status: Refund_Status,
                Refund_Amount: Refund_Amount,
            };
        };
        setLoader(true);
        dispatch({ type: actions.TICKET_APPROVAL_STATUS_UPDATE, payload: { Id: TicketDetails?._id, data: formData } });
    };

    //update ticket status -> cause: Change Request
    const handleUpdateChangeRequestStatus = (data, cakeFlavours, cakeShape) => {
        let formData, Difference_In_Price_Gst = 0, Total_Difference_In_Price = 0;
        let Last_Intimate = ['HelpdeskC', 'Manager'];
        for (let i = 0; i < Last_Intimate?.length; i++) {
            let Access = Last_Intimate[i];
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [Access]: 'y' });
        };

        if (data.Difference_In_Price > 0) {
            if (parseInt(Tax[0]?.Total_GST) > 0) {
                Difference_In_Price_Gst = (data.Difference_In_Price * parseInt(Tax[0]?.Total_GST)) / 100;
                Total_Difference_In_Price = data.Difference_In_Price + parseFloat(Difference_In_Price_Gst);
            } else {
                Total_Difference_In_Price = data.Difference_In_Price;
            };
        };
        if (state?.state?.Page === "HelpdeskV") {
            formData = {
                HelpdeskV_Input: data.Input,
                Ticket_Approved_Status: data.Ticket_Approved_Status,
                Difference_In_Price: data.Difference_In_Price,
                Difference_In_Price_Gst: Difference_In_Price_Gst,
                Total_Difference_In_Price: Total_Difference_In_Price,
                Flavour: cakeFlavours,
                Shape: cakeShape,
                Ticket_Approved_Status_Updated_By: LoggedIn_User,
                Last_Intimate: Last_Intimate,
                Ticket_Accessed_By: ticket_Accessed_By,
                CauseOfTicket: TicketDetails?.CauseOfTicket
            };
        };
        setLoader(true);
        dispatch({ type: actions.TICKET_APPROVAL_STATUS_UPDATE, payload: { Id: TicketDetails?._id, data: formData } });
    };

    //set order details popup -> true
    const handleShowOrderDetailsPopup = (e) => {
        e.preventDefault();
        dispatch({
            type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
            payload: { Id: TicketDetails?.OrderID, TypeOfOrder: TicketDetails?.TypeOfOrder?.toLowerCase()?.replace(/ /g, '') }
        });
        setShowOrderDetailsPopup(true);
    };

    //set vendor details popup -> true 
    const handleShowVendorDetailsPopup = (e) => {
        e.preventDefault();
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: TicketDetails?.VendorID }
        });
        setShowVendorDetailsPopup(true);
    };

    const handleShowUserDetailsPopup = (e) => {
        e.preventDefault();
        setShowUserDetailsPopup(true);
    };

    //close details popup
    const handleCloseDetailsPopup = () => {
        setShowOrderDetailsPopup(false);
        // dispatch({ type: actions.SET_ORDER_DETAILS_BY_TYPEOFORDER, payload: [] });
        setShowVendorDetailsPopup(false);
        setShowUserDetailsPopup(false);
        // dispatch({ type: VendorActions.SET_VENDOR_DETAILS_BY_ID, payload: [] });
    };

    //update helpdesk C notes
    const handleHelpdeskCNotes = (notes) => {
        setLoader(true);
        let formData;
        if (TicketDetails?.CauseOfTicket === 'Change Requests') {
            formData = {
                HelpdeskC_Notes: notes,
                Updated_By: LoggedIn_User,
                CauseOfTicket: TicketDetails?.CauseOfTicket,
                Ticket_Approved_Status: TicketDetails?.Ticket_Approved_Status ? TicketDetails?.Ticket_Approved_Status : '',
                UserID: TicketDetails?.UserID,
                User_ID: TicketDetails?.User_ID
            };
        } else {
            let Last_Intimate = ['HelpdeskV'];
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, HelpdeskV: 'y' });
            formData = {
                HelpdeskC_Notes: notes,
                Updated_By: LoggedIn_User,
                Ticket_Accessed_By: ticket_Accessed_By,
                Last_Intimate: Last_Intimate,
                CauseOfTicket: TicketDetails?.CauseOfTicket,
            };
        };
        dispatch({ type: actions.HELPDESKC_NOTES, payload: { Id: TicketDetails?._id, data: formData } });
    };

    const handleOrderAssignToVendorPopup = (e) => {
        e.preventDefault();
        setShowHelpdeskCFullyCustomizedCakeAssignPopup(true);
        dispatch({ type: VendorActions.GET_ALL_VENDORS });
        // dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
    };

    const handleCloseAssignPopup = (e) => {
        setShowHelpdeskCFullyCustomizedCakeAssignPopup(false);
        setShowHelpdeskVCakeyGOSelectVendorPopup(false);
        // dispatch({ type: userActions.SET_USER_DETAILS, payload: { UserDetails: [] } });
    };
    const handleOrderAssignToVendor = (vendor, data) => {
        // e.preventDefault();
        if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') {
            let Last_Intimate = ['HelpdeskC', 'Manager'];
            for (let i = 0; i < Last_Intimate?.length; i++) {
                let Access = Last_Intimate[i];
                setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [Access]: 'y' });
            };
            let formData;
            if (OrderDetailsByTypeOfOrder?.TypeOfCustomizedCake === 'Cakey') {
                formData = {
                    VendorID: vendor._id,
                    Vendor_ID: vendor.Id,
                    VendorName: vendor.VendorName,
                    VendorCallConversation: data.VendorCallConversation,
                    CostDifference: '0',
                    FinalCost: OrderDetailsByTypeOfOrder?.Total,
                    NewPrice: OrderDetailsByTypeOfOrder?.Vendor_Price,
                    NewTax: (parseFloat(OrderDetailsByTypeOfOrder?.Gst) + parseFloat(OrderDetailsByTypeOfOrder?.Sgst)),
                    NewDiscount: OrderDetailsByTypeOfOrder?.Discount,
                    PriceType: priceType,
                    NewExtraCharges: OrderDetailsByTypeOfOrder?.ExtraCharges,
                    NewDeliveryCharges: OrderDetailsByTypeOfOrder?.DeliveryCharge,
                    NewTopperCharges: OrderDetailsByTypeOfOrder?.Toppers?.TopperPrice || 0,
                    Last_Intimate: Last_Intimate,
                    Ticket_Accessed_By: ticket_Accessed_By,
                    FinalMargin: data.FinalMargin
                }
            } else {
                if (NoVendor === 'No Vendors') {
                    formData = {
                        IsVendorAvailable: NoVendor,
                        Last_Intimate: Last_Intimate,
                        Ticket_Accessed_By: ticket_Accessed_By
                    }
                } else {
                    formData = {
                        VendorID: vendor._id,
                        Vendor_ID: vendor.Id,
                        VendorName: vendor.VendorName,
                        VendorCallConversation: data.VendorCallConversation,
                        CostDifference: data.CostDifference,
                        FinalCost: data.FinalCost,
                        NewPrice: data.NewPrice,
                        NewTax: data.NewTax,
                        NewDiscount: data.NewDiscount,
                        PriceType: priceType,
                        NewExtraCharges: data.NewExtraCharges,
                        NewDeliveryCharges: data.NewDeliveryCharges,
                        NewTopperCharges: data.NewTopperCharges,
                        Last_Intimate: Last_Intimate,
                        Ticket_Accessed_By: ticket_Accessed_By
                    }
                }
            }
            setLoader(true);
            dispatch({
                type: actions.HELPDESKV_UPDATE_VENDOR_AND_FINAL_COST,
                payload: { Id: TicketDetails?._id, data: formData }
            });
        } else {
            let formData = {
                OrderID: TicketDetails?.OrderID,
                VendorID: vendor?.value?._id,
                Vendor_ID: vendor?.value?.Id,
                VendorName: vendor?.value?.VendorName,
                VendorPhoneNumber1: vendor?.value?.PhoneNumber1,
                VendorPhoneNumber2: vendor?.value?.PhoneNumber2,
                VendorAddress: vendor?.value?.Address,
                GoogleLocation: vendor?.value?.GoogleLocation,
                Assigned_By: LoggedIn_User,

            };
            setLoader(true);
            dispatch({
                type: actions.HELPDESKC_FULLY_CUSTOMIZED_CAKE_ASSIGN_TO_VENDOR,
                payload: { Id: TicketDetails?._id, data: formData }
            });
        }
    };

    const handleHeldpeskVEditPricePopup = (e) => {
        e.preventDefault();
        setShowHelpdeskVEditPricePopup(true);
        dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: TicketDetails?.OrderID });
    };

    const handleCloseEditPricePopup = (e) => {
        setShowHelpdeskVEditPricePopup(false);
        setShowHelpdeskVEditPriceForVendorCCPopup(false);
    };

    const handleCustomizedCakeEditedPrice = (e, pricePerKg, taxValue, Tax, finalTotal, DeliveryChargeValue) => {
        e.preventDefault();
        let Ticket_Accessed_By = TicketDetails?.Ticket_Accessed_By;
        Ticket_Accessed_By.Manager = 'y';
        let formData = {
            HelpdeskV_Price: pricePerKg?.toString(),
            Gst: (parseFloat(taxValue) > 0) ? (parseFloat(taxValue) / 2)?.toString() : "0",
            Sgst: (parseFloat(taxValue) > 0) ? (parseFloat(taxValue) / 2)?.toString() : "0",
            Tax: (Tax.length > 0 && Tax[0]?.Total_GST) ? Tax[0]?.Total_GST : "0",
            HelpdeskV_Total: finalTotal?.toString(),
            DeliveryCharge: DeliveryChargeValue?.toString(),
            Ticket_Accessed_By: Ticket_Accessed_By,
            Last_Intimate: ['Manager'],
            OrderID: TicketDetails?.OrderID
        };
        setLoader(true);
        dispatch({
            type: actions.FULLY_CUSTOMIZED_CAKE_PRICE_INVOICE_SEND_BY_HELPDESKV,
            payload: { Id: TicketDetails?._id, OrderID: TicketDetails?.OrderID, TypeOfOrder: TypeOfOrder?.toLowerCase()?.replace(/ /g, ''), data: formData }
        });
    };

    const handleManagerEditPricePopup = (e) => {
        e.preventDefault();
        setShowManagerEditPricePopup(true);
        dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: TicketDetails?.OrderID });
    };

    const handleCloseManagerEditPricePopup = (e) => {
        setShowManagerEditPricePopup(false);
    };

    const handleShowCloseTicketPopup = (e) => {
        e.preventDefault();
        setShowCloseTicketPopup(true);
        if (TicketDetails?.CauseOfTicket === 'Vendor Request') {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: TicketDetails?.VendorID } });
        } else if (TicketDetails?.CauseOfTicket === 'Miscellaneous') {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
        }
        // if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
        //     dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
        // };
    };

    const handleCloseTicketPopup = (e) => {
        setShowCloseTicketPopup(false);
        // if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
        //     dispatch({ type: userActions.SET_USER_DETAILS, payload: { UserDetails: [] } });
        // };
    };

    const handleCloseTicket = (e) => {
        e.preventDefault();
        if (TicketDetails?.CauseOfTicket === 'Vendor Request' && VendorDetails?.Notification_Id) {
            let pushMessageData = {
                type: 'vendor',
                registration_ids: [VendorDetails?.Notification_Id],
                title: 'Cakey',
                body: "Your Request is Processed and Closed",
            }
            PushNotification(pushMessageData);
        };
        if (TicketDetails?.CauseOfTicket === 'Miscellaneous' && UserDetails?.Notification === 'y' && UserDetails?.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: "Your Request is Processed and Closed",
            }
            PushNotification(pushMessageData);
        };
        if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
            let formData = {
                OrderID: TicketDetails?.OrderID,
                TypeOfOrder: TicketDetails?.TypeOfOrder,
                CauseOfTicket: TicketDetails?.CauseOfTicket,
                Cancelled_By: 'Customer',
                ReasonForCancel: TicketDetails?.HelpdeskC_Notes,
                Order_Cancelled_By: LoggedIn_User,
                Status: (TicketDetails?.Ticket_Approved_Status === 'Approved') ? 'Cancelled' : OrderDetailsByTypeOfOrder?.Status,
                Refund_Type: TicketDetails?.RefundType,
                Refund_Status: TicketDetails?.Refund_Status,
                Refund_By: TicketDetails?.Refund_By,
                Refund_On: TicketDetails?.Refund_On,
                Ticket_Approved_Status: TicketDetails?.Ticket_Approved_Status,
            };
            setLoader(true);
            dispatch({ type: actions.CLOSE_TICKET, payload: { Id: TicketDetails?._id, data: formData } });
        } else if (TicketDetails?.CauseOfTicket === 'Miscellaneous' || TicketDetails?.CauseOfTicket === 'Change Requests' ||
            TicketDetails?.CauseOfTicket === 'Vendor Request' || TicketDetails?.CauseOfTicket === 'Fully Customized Cake' ||
            TicketDetails?.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' ||
            TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor' ||
            TicketDetails?.CauseOfTicket === 'Cakey Direct-General Order' ||
            TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' || TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins') {
            let formData = {
                CauseOfTicket: TicketDetails?.CauseOfTicket,
                Order_Cancelled_By: LoggedIn_User
            };
            setLoader(true);
            dispatch({ type: actions.CLOSE_TICKET, payload: { Id: TicketDetails?._id, data: formData } });
        }
    };

    const handleEnterSolutionPopup = (e) => {
        e.preventDefault();
        setShowEnterSolutionPopup(true);
    };

    const handleCloseEnterSolutionPopup = (e) => {
        setShowEnterSolutionPopup(false);
        setShowManagerRemarksPopup(false);
    };

    const handleUpdateSolution = (solution) => {
        let Ticket_Intimation = [];
        if (TicketDetails?.CauseOfTicket === 'Vendor Request') {
            (LoggedIn_User?.TypeOfUser === 'Helpdesk V') ? Ticket_Intimation = ['Manager'] :
                (LoggedIn_User?.TypeOfUser === 'Manager') ? Ticket_Intimation = ['HelpdeskV'] :
                    Ticket_Intimation = ['HelpdeskV', 'Manager'];
        } else {
            (LoggedIn_User?.TypeOfUser === 'Helpdesk C') ? Ticket_Intimation = ['Manager'] :
                (LoggedIn_User?.TypeOfUser === 'Manager') ? Ticket_Intimation = ['HelpdeskC'] :
                    Ticket_Intimation = ['HelpdeskC', 'Manager'];
        }
        let TOU;
        (LoggedIn_User?.TypeOfUser === 'Helpdesk C') ? TOU = 'Customer Helpdesk' : (LoggedIn_User?.TypeOfUser === 'Helpdesk V') ? TOU = 'Vendor Helpdesk' : TOU = LoggedIn_User?.TypeOfUser
        let formData = {
            Solution: solution,
            Solution_Entered_By: LoggedIn_User,
            Ticket_Intimation: Ticket_Intimation,
            For_Display: `New Solution given by ${TOU}`
        };
        setLoader(true);
        dispatch({
            type: actions.ENTER_SOLUTION_FOR_TICKETS,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleManagerRemarksPopup = (e) => {
        e.preventDefault();
        setShowManagerRemarksPopup(true);
    };

    const handleManagerRemarks = (solution) => {
        let Last_Intimate = (TicketDetails?.CauseOfTicket === 'Vendor Request') ? ['HelpdeskV'] : ['HelpdeskC'];
        let formData = {
            Solution: solution,
            Solution_Entered_By: LoggedIn_User,
            Last_Intimate: Last_Intimate
        };
        setLoader(true);
        dispatch({
            type: actions.MANAGER_REMARKS,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleCustomizedCakeEditedPriceByManager = (e, cakeyShare, taxValue, Tax, finalTotal) => {
        e.preventDefault();
        let Ticket_Accepted_By = TicketDetails?.Ticket_Accessed_By;
        Ticket_Accepted_By.HelpdeskC = 'y';
        let formData = {
            Price: (cakeyShare?.Final_Total).toString(),
            Gst: (parseFloat(taxValue) / 2).toString(),
            Sgst: (parseFloat(taxValue) / 2).toString(),
            Tax: Tax[0]?.Total_GST,
            Total: (finalTotal).toString(),
            Ticket_Accessed_By: Ticket_Accepted_By,
            Margin: {
                Percentage: cakeyShare?.Percentage,
                Amount: cakeyShare?.Margin,
            },
            Last_Intimate: ['HelpdeskC'],
            OrderID: TicketDetails?.OrderID,
        };
        setLoader(true);
        dispatch({
            type: actions.FULLY_CUSTOMIZED_CAKE_FINAL_PRICE_SEND_BY_MANAGER,
            payload: { Id: TicketDetails?._id, OrderID: TicketDetails?.OrderID, TypeOfOrder: TypeOfOrder?.toLowerCase()?.replace(/ /g, ''), data: formData }
        });
    };

    const handleUpdateCustomerApproval = (e) => {
        e.preventDefault();
        setShowUpdateCustomerApprovalPopup(true);
        dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
    };

    const handleCloseUpdateApprovalPopup = (e) => {
        setShowUpdateCustomerApprovalPopup(false);
        // dispatch({ type: userActions.SET_USER_DETAILS, payload: { UserDetails: [] } });
    };

    const handleIntimateToCustomer = (data) => {
        let formData = {
            HelpdeskC_Notes: data.Input,
            Ticket_Approved_Status: data.Ticket_Approved_Status,
            Ticket_Approved_Status_Updated_By: LoggedIn_User,
            OrderID: TicketDetails?.OrderID
        };
        setLoader(true);
        dispatch({
            type: actions.UPDATE_CUSTOMER_APPROVAL_BY_HELPDESKC,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleHelpdeskVSelectVendorPopup = (e) => {
        e.preventDefault();
        setShowHelpdeskVSelectVendorPopup(true);
        dispatch({ type: VendorActions.GET_ALL_VENDORS });
    };

    const handleCloseHelpdeskVSelectVendor = () => {
        setShowHelpdeskVSelectVendorPopup(false);
    };

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            setShowIntimateListPopup(false);
            setLast_Intimate([]);
            setTicket_Accessed_By(TicketAccessedBy);
            setLoader(false);
            setShowEnterInputPopup(false);
            setShowHelpdeskCFullyCustomizedCakeAssignPopup(false);
            setShowHelpdeskVEditPricePopup(false);
            setShowCloseTicketPopup(false);
            setShowEnterSolutionPopup(false);
            setShowManagerRemarksPopup(false);
            setShowManagerEditPricePopup(false);
            setShowUpdateCustomerApprovalPopup(false);
            setShowhandleUpdateCostDifferencePopup(false);
            setShowHelpdeskVEnterInputPopup(false)
            setShowHelpdeskVSelectVendorPopup(false);
            setShowUpdateVendorApprovedStatusPopup(false);
            setShowHelpdeskVRemarksPopup(false);
            setShowHelpdeskVRemarksForVendorCancel(false);
            setShowHelpdeskVEditPriceForVendorCCPopup(false);
            setShowIntimateToCustomerPopup(false);
            setShowIntimateToVendorPopup(false);
            setShowHelpdeskVCakeyGOSelectVendorPopup(false);
            setShowIntimateToCustomerForCancelPopup(false);
            setShowIntimateToCustomerForChangeRequestPopup(false);
            setShowIntimateToCustomerForChangeRequestApprovedPopup(false);
            setShowEnterNotesForCCThroughVendorPopup(false);
            setShowIntimateToAccountsForCustomerCancelPopup(false);
            setShowEnterNotesForCustomerCancelPopup(false);
            setShowVendorCancelIntimateToAccountsPopup(false);
            setShowIntimateToManagerForMiscellaneous(false);
            setShowMiscellaneousApprovalByManagerPopup(false);
            setShowIntimateToAccountsForMiscellaneous(false);
            setShowHelpdeskCRemarksForMiscellaneous(false);
            setPriceType('Price')
            reset();
            setNoVendor('');
        }
        //eslint-disable-next-line
    }, [Ticket_Response, TicketAccessedBy]);

    const handleUpdateCostDifferencePopup = (e) => {
        e.preventDefault();

        setShowhandleUpdateCostDifferencePopup(true);
        dispatch({ type: VendorActions.GET_ALL_VENDORS });

        if (TypeOfOrder === 'Cake Order') {
            if (AdminCakesList?.length === 0) {
                setProductList([]);
            } else {
                if (AdminCakesList?.message === 'No Records Found') {
                    setProductList([]);
                } else {
                    let List = [];
                    if (AdminCakesList) {
                        //eslint-disable-next-line
                        AdminCakesList.filter(val => {
                            // if ((OrderDetailsByTypeOfOrder?.VendorID !== val.VendorID) && (OrderDetailsByTypeOfOrder?.EggOrEggless === val.DefaultCakeEggOrEggless)) {
                            if ((OrderDetailsByTypeOfOrder?.VendorID !== val.VendorID) && (val?.Status !== 'Suspended')) {
                                return List.push({
                                    label: `${val.Id} - ${val.CakeName} - ${val.VendorName} - ${val.DefaultCakeEggOrEggless} - 
                                ${(val.DefaultCakeEggOrEggless === 'Egg' || val.DefaultCakeEggOrEggless === 'Eggless') &&
                                        val.BasicCakePrice} ${val.BasicEgglessCostPerKg ? ' - ' + val.BasicEgglessCostPerKg : ''}`,
                                    value: val
                                })
                            }
                        });
                    }
                    setProductList(ProductList = List);
                    setPriceType('Price')
                }
            };
        } else if (TypeOfOrder === 'Product Order') {
            if (ApprovedProductList?.length === 0) {
                setProductList([]);
            } else {
                if (ApprovedProductList?.message === 'No Records Found') {
                    setProductList([]);
                } else {
                    let List = [];
                    //eslint-disable-next-line
                    ApprovedProductList.filter(val => {
                        if (((OrderDetailsByTypeOfOrder?.VendorID !== val.VendorID) && (val?.Status !== 'Suspended'))) {
                            if (val.CakeSubType === ProductSubType) {
                                if (val?.CakeSubType === "Brownie" ||
                                    val?.CakeSubType === "Cookies" ||
                                    val?.CakeSubType === "Chocolates" ||
                                    val?.CakeSubType === 'Tea Cake' ||
                                    val?.CakeSubType === 'Cheese Cake'
                                ) {
                                    List.push({
                                        label: `${val.Id} - ${val.ProductName} - ${val.VendorName} - ${val.MinWeightPerKg.Weight} - ${val.MinWeightPerKg.PricePerKg} `,
                                        value: val
                                    });
                                    setPriceType('PricePerKg')
                                } else if (val?.CakeSubType === "Cake Dessert") {
                                    if (val?.MinWeightPerUnit?.length > 0) {
                                        //eslint-disable-next-line
                                        val.MinWeightPerUnit.map((price) => {
                                            List.push({
                                                label: `${val.Id} - ${val.ProductName} - ${val.VendorName} - ${price.Weight} - ${price.PricePerUnit} `,
                                                value: val
                                            });
                                        })
                                    } else {
                                        List = []
                                    }
                                    setPriceType('PricePerUnit')
                                } else if (val?.CakeSubType === "macaroons" ||
                                    val?.CakeSubType === "Cup Cake"
                                ) {
                                    if (val?.MinWeightPerBox?.length > 0) {
                                        val.MinWeightPerBox.map((price) => {
                                            return List.push({
                                                label: `${val.Id} - ${val.ProductName}- ${val.VendorName} - ${price.Piece} - ${price.PricePerBox} `,
                                                value: val
                                            });
                                        })
                                    } else {
                                        List = []
                                    }
                                    setPriceType('PricePerBox')
                                }
                            }
                        }
                    });
                    setProductList(ProductList = List);
                }
            };
        }
        if ((ProductList?.length === 0 && TypeOfOrder === 'Product Order')) {
            setNoVendor("No Vendors")
        } else if (ProductList?.length === 0 && TypeOfOrder === 'Cake Order') {
            setNoVendor('Cake Order')
        } else {
            setNoVendor('Vendor Available')
        }
    }

    const handleCloseCostDifferencePopup = () => {
        setShowhandleUpdateCostDifferencePopup(false);
        setPriceType('Price');
        setNoVendor('')
    }

    const handleSubmitCallConversation = (e, selectedVendor, callConversation) => {
        e.preventDefault();
        let Last_Intimate = ['Manager'];
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Manager: 'y' });
        let formData = {
            VendorID: selectedVendor?.value?._id,
            Vendor_ID: selectedVendor?.value?.Id,
            VendorName: selectedVendor?.value?.VendorName,
            VendorCallConversation: callConversation,
            Updated_By: LoggedIn_User,
            Last_Intimate: Last_Intimate,
            Ticket_Accessed_By: ticket_Accessed_By
        }
        setLoader(true);
        dispatch({
            type: actions.HELPDESKV_CUSTOMIZED_CAKE_SELECT_VENDOR,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleVendorApprovedStatusPopup = (e) => {
        e.preventDefault();
        setShowUpdateVendorApprovedStatusPopup(true);
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: TicketDetails?.VendorID }
        });
    };

    const handleCloseVendorApprovedStatusPopup = () => {
        setShowUpdateVendorApprovedStatusPopup(false);
    };

    const handleUpdateVendorApprovalStatus = (e, status, Vendor) => {
        e.preventDefault();
        let formData;
        if (status === "Approved") {
            let Last_Intimate = ['HelpdeskC', 'HelpdeskV'];
            for (let i = 0; i < Last_Intimate.length; i++) {
                let access = Last_Intimate[i];
                setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, [access]: 'y' });
            };
            let pushMessageData = {
                type: 'vendor',
                registration_ids: [Vendor?.Notification_Id],
                title: 'Cakey',
                body: "New Cake Order Directly from CAKEY",
            }
            PushNotification(pushMessageData);
            formData = {
                Vendor_Approved_Status: status,
                Vendor_Approved_Status_Updated_By: LoggedIn_User,
                OrderID: TicketDetails?.OrderID,
                VendorID: Vendor?._id,
                Vendor_ID: Vendor?.Id,
                VendorName: Vendor?.VendorName,
                VendorPhoneNumber1: Vendor?.PhoneNumber1,
                VendorPhoneNumber2: Vendor?.PhoneNumber2,
                VendorAddress: Vendor?.Address,
                GoogleLocation: Vendor?.GoogleLocation,
                Last_Intimate: Last_Intimate,
                Ticket_Accessed_By: ticket_Accessed_By,
                CauseOfTicket: TicketDetails?.CauseOfTicket,
            };
        } else {
            let Last_Intimate = ['HelpdeskV'];
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, HelpdeskV: 'y' });
            formData = {
                Vendor_Approved_Status: status,
                Vendor_Approved_Status_Updated_By: LoggedIn_User,
                Last_Intimate: Last_Intimate,
                Ticket_Accessed_By: ticket_Accessed_By
            };
        }
        setLoader(true);
        dispatch({
            type: actions.HELPDESKV_CUSTOMIZED_CAKE_UPDATE_VENDOR_APPROVE_STATUS,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleHelpdeskVRemarksPopup = (e) => {
        e.preventDefault();
        setShowHelpdeskVRemarksPopup(true);
    };

    const handleCloseHelpdeskVRemarksPopup = (e) => {
        setShowHelpdeskVRemarksPopup(false);
        setShowIntimateToVendorPopup(false);
        setShowHelpdeskVRemarksForVendorCancel(false);
        // dispatch({
        //     type: VendorActions.SET_VENDOR_DETAILS_BY_ID,
        //     payload: []
        // });
    };

    const handleHelpdeskVRemarks = (remarks) => {
        let formData = {
            HelpdeskV_Remarks: remarks
        };
        setLoader(true);
        dispatch({ type: actions.HELPDESKV_REMARKS, payload: { Id: TicketDetails?._id, data: formData } });
    };

    const handleHelpdeskVRemarksForVendorCancel = (remarks) => {
        let formData = {
            HelpdeskV_Remarks: remarks,
            OrderID: TicketDetails?.OrderID,
            Refund_Status: TicketDetails?.Refund_Status,
            Refund_On: TicketDetails?.Refund_On,
            Refund_By: TicketDetails?.Refund_By,
            Ticket_Approved_Status: TicketDetails?.Ticket_Approved_Status,
            TypeOfOrder: TicketDetails?.TypeOfOrder,
        };
        setLoader(true);
        dispatch({ type: actions.UPDATE_HELPDESKV_REMARKS_FOR_VENDOR_CANCEL, payload: { Id: TicketDetails?._id, data: formData } });
    };

    const handleHeldpeskVEditPriceForVendorCCPopup = async (e) => {
        e.preventDefault();
        if (Tax?.length !== 0 && OrderDetailsByTypeOfOrder) {
            let VendorDeliveryCharge = 0, coordinates = {};
            if (OrderDetailsByTypeOfOrder?.DeliveryInformation === 'Delivery' && OrderDetailsByTypeOfOrder?.DeliveryAddress) {
                await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${OrderDetailsByTypeOfOrder?.DeliveryAddress}&key=${GOOGLE_API_KEY}`)
                    .then((res) => res.json())
                    .then((data) => {
                        coordinates = {
                            Latitude: data.results[0].geometry.location.lat,
                            Longitude: data.results[0].geometry.location.lng
                        };
                    }).catch(err => console.log(err));
                const Distance = calculateDistance(OrderDetailsByTypeOfOrder?.GoogleLocation?.Latitude, OrderDetailsByTypeOfOrder?.GoogleLocation?.Longitude,
                    coordinates?.Latitude, coordinates?.Longitude);
                if (DeliveryCharge?.message === 'No Records Found') {
                    VendorDeliveryCharge = 0;
                } else {
                    if (Distance > 2) {
                        let FinalDistance = Distance - 2;
                        let DistanceDividedByKm = parseFloat(FinalDistance) / parseInt(DeliveryCharge[0]?.Km);
                        VendorDeliveryCharge = parseFloat(DistanceDividedByKm) * parseInt(DeliveryCharge[0]?.Amount);
                    } else {
                        VendorDeliveryCharge = 0;
                    };
                };
            } else {
                VendorDeliveryCharge = 0;
            };
            let formData = {
                Type: "Kg",
                Price: OrderDetailsByTypeOfOrder?.Vendor_Price,
                ItemCount: OrderDetailsByTypeOfOrder?.ItemCount || "1",
                Flavour: OrderDetailsByTypeOfOrder?.Flavour,
                Shape: { Name: OrderDetailsByTypeOfOrder?.Shape, Price: "0" },
                Weight: OrderDetailsByTypeOfOrder?.Weight,
                Discount: OrderDetailsByTypeOfOrder?.Discount,
                DeliveryCharge: VendorDeliveryCharge,
                // DeliveryCharge: OrderDetailsByTypeOfOrder?.DeliveryCharge,
                Tax: Tax[0]?.Total_GST || "0",
                CalculationFor: "Ticket"
            };
            dispatch({ type: CommonActions.GET_INVOICE_CALCULATION, payload: { type: "CCO", data: formData } })
        };
        setShowHelpdeskVEditPriceForVendorCCPopup(true);
    };

    const handleCustomizedCakePriceByHelpdeskV = (e, price) => {
        e.preventDefault();
        let Last_Intimate = ['HelpdeskC'];
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, HelpdeskC: 'y' });
        let formData = {
            Price: price?.Price,
            DeliveryCharge: price?.DeliveryCharge,
            Gst: parseFloat(price.TaxAmount) / 2,
            Sgst: parseFloat(price.TaxAmount) / 2,
            Tax: Tax[0]?.Total_GST,
            Total: price?.Total,
            OrderID: TicketDetails?.OrderID,
            Last_Intimate: Last_Intimate,
            Ticket_Accessed_By: ticket_Accessed_By
        };
        setLoader(true);
        dispatch({
            type: actions.VENDOR_CUSTOMIZED_CAKE_UPDATE_TAX_BY_HELPDESKV,
            payload: { Id: TicketDetails?._id, OrderID: TicketDetails?.OrderID, TypeOfOrder: TypeOfOrder?.toLowerCase()?.replace(/ /g, ''), data: formData }
        });
    };

    const handleIntimateToCustomerForPaymentPopup = (e) => {
        e.preventDefault();
        setShowIntimateToCustomerPopup(true);
        if (TicketDetails?.UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID })
        }
    };

    const handleCloseIntimateToCustomerPopup = (e) => {
        e.preventDefault();
        setShowIntimateToCustomerPopup(false);
        setShowIntimateToCustomerForCancelPopup(false);
        // dispatch({ type: userActions.SET_USER_DETAILS, payload: { UserDetails: [] } });
    };

    const handleIntimateToCustomerForPayment = (e) => {
        e.preventDefault();
        let formData = {
            Ticket_Approved_Status: "Approved",
            Ticket_Approved_Status_Updated_By: LoggedIn_User,
            OrderID: TicketDetails?.OrderID,
        };
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TO_CUSTOMER_FOR_PAYMENT,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleIntimateToVendorForOrderPopup = (e) => {
        e.preventDefault();
        setShowIntimateToVendorPopup(true);
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: TicketDetails?.VendorID }
        });
    };

    const handleHelpdeskVRemarksForVendorCC = (remarks) => {
        let Status;
        (TicketDetails?.Customer_Approved_Status?.toLowerCase() === 'approved') ? Status = "Ordered" : Status = "Cancelled";
        let formData = {
            HelpdeskV_Remarks: remarks,
            Customer_Approved_Status: TicketDetails?.Customer_Approved_Status,
            Status: Status,
            OrderID: TicketDetails?.OrderID
        };
        if (VendorDetails?.Notification_Id) {
            let bodyContent;
            (TicketDetails?.Customer_Approved_Status?.toLowerCase() === 'approved') ? bodyContent = 'New Customised Cake Order Received' :
                bodyContent = "Customised Cake Request is Cancelled"
            let pushMessageData = {
                type: 'vendor',
                registration_ids: [VendorDetails?.Notification_Id],
                title: 'Cakey',
                body: bodyContent,
            }
            PushNotification(pushMessageData);
        }
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TO_VENDOR_FOR_VENDORCC_TO_ORDER,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleHelpdeskVSelectVendorForGOPopup = (e) => {
        e.preventDefault();
        setShowHelpdeskVCakeyGOSelectVendorPopup(true);
        dispatch({ type: VendorActions.GET_ALL_VENDORS });
    };

    useEffect(() => {
        (TicketDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [TicketDetails]);

    const handleEnterInputPopupVendorCancel = () => {
        dispatch({
            type: VendorActions.GET_VENDOR_DETAILS_BY_ID,
            payload: { Id: TicketDetails?.VendorID }
        });
        setShowHelpdeskVEnterInputPopup(true);
    }

    const handleCloseHelpdeskVEnterInputPopup = () => {
        setShowHelpdeskVEnterInputPopup(false);
        reset();

    }
    const handleEnterInputByHelpdeskV = () => {
        setShowHelpdeskVRemarksForVendorCancel(true);
    };

    const handleIntimatetoCustomerPopup = (e) => {
        e.preventDefault();
        setShowIntimateToCustomerForCancelPopup(true);
        if (TicketDetails?.UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID })
        }
    };

    const handleIntimateToCustomerForCancel = (UserName) => {
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TO_CUSTOMER_FOR_CANCEL,
            payload: {
                Id: TicketDetails?._id, data: {
                    UserID: TicketDetails?.UserID,
                    User_ID: TicketDetails?.User_ID,
                    OrderID: TicketDetails?.OrderID,
                    Order_ID: TicketDetails?.Order_ID,
                    UserName: UserName
                }
            }
        });
    };

    const handleIntimateToCustomerForChangeRequestPopup = (e) => {
        e.preventDefault();
        if (TicketDetails?.UserID && TicketDetails?.Ticket_Approved_Status === 'Not Approved') {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
        }
        setShowIntimateToCustomerForChangeRequestPopup(true);
    };

    const handleCloseIntimateToCustomerForChangeRequestPopup = () => {
        setShowIntimateToCustomerForChangeRequestPopup(false);
        setShowIntimateToCustomerForChangeRequestApprovedPopup(false);
        setShowEnterNotesForCustomerCancelPopup(false);
        setShowIntimateToManagerForMiscellaneous(false);
        setShowMiscellaneousApprovalByManagerPopup(false);
    };

    const handleIntimateToCustomerForChangeRequestApprovedPopup = (e) => {
        e.preventDefault();
        if (TicketDetails?.UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID })
        }
        setShowIntimateToCustomerForChangeRequestApprovedPopup(true);
    };

    const handleIntimateToCustomerForChangeRequestApprove = () => {
        let formData;
        let Last_Intimate = ['HelpdeskC', 'Accounts'];
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, 'Accounts': 'y' });
        formData = {
            Difference_In_Price: TicketDetails?.Total_Difference_In_Price,
            Payment_Intimated_By: LoggedIn_User,
            OrderID: TicketDetails?.OrderID,
            Ticket_ID: TicketDetails?.Id,
            PaymentType: OrderDetailsByTypeOfOrder?.PaymentType,
            Last_Intimate: Last_Intimate,
            Ticket_Accessed_By: ticket_Accessed_By
        };
        setLoader(true);
        dispatch({
            type: actions.SEND_PAYMENT_LINK_FOR_CHANGE_REQUEST,
            payload: { Id: TicketDetails?._id, data: formData }
        });
    };

    const handleEnterNotesForCCThroughVendorPopup = (e) => {
        e.preventDefault();
        setShowEnterNotesForCCThroughVendorPopup(true);
    };

    const handleCloseEnterNotesForCCThroughVendorPopup = (e) => {
        setShowEnterNotesForCCThroughVendorPopup(false);
    };

    const handleIntimateToVendorForCCOrder = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TO_VENDOR_FOR_CC_ORDERS, payload: {
                Id: TicketDetails?._id,
                data: {
                    Updated_By: LoggedIn_User,
                    Ticket_Accessed_By: ticket_Accessed_By,
                    Last_Intimate: last_Intimate,
                    OrderID: TicketDetails?.OrderID,
                    VendorID: TicketDetails?.VendorID,
                    CauseOfTicket: TicketDetails?.CauseOfTicket
                }
            }
        });
    };

    const handleIntimatetoAccountsForCustomerCancelPopup = (e) => {
        e.preventDefault();
        setShowIntimateToAccountsForCustomerCancelPopup(true);
        if (TicketDetails?.UserID) {
            dispatch({ type: userActions.GET_USER_DETAILS, payload: TicketDetails?.UserID });
        }
        if (TicketDetails?.VendorID) {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: TicketDetails?.VendorID } });
        }
    };

    const handleCloseIntimatetoAccountsForCustomerCancelPopup = () => {
        setShowIntimateToAccountsForCustomerCancelPopup(false);
        // dispatch({ type: userActions.SET_USER_DETAILS, payload: [] });
        // dispatch({ type: VendorActions.SET_VENDOR_DETAILS_BY_ID, payload: [] });
    };

    const handleIntimateToAccountsForCustomerCancel = () => {
        // e.preventDefault();
        let formData;
        if (TicketDetails?.Ticket_Approved_Status === 'Approved') {
            setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Accounts: 'y' });
            let Last_Intimate = ['Accounts'];
            let Refund_Amount = parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount || 0) + parseFloat(TicketDetails?.Refund_Amount);
            formData = {
                Updated_By: LoggedIn_User,
                Ticket_Accessed_By: ticket_Accessed_By,
                Last_Intimate: Last_Intimate,
                OrderID: TicketDetails?.OrderID,
                TypeOfOrder: TicketDetails?.TypeOfOrder,
                Cancelled_By: "Customer",
                ReasonForCancel: TicketDetails?.TicketDescription,
                Cancellation_Charges: TicketDetails?.Cancellation_Charges,
                Refund_Amount: Refund_Amount,
                Order_Cancelled_By: LoggedIn_User,
                Status: "Cancelled",
                Ticket_Approved_Status: TicketDetails?.Ticket_Approved_Status
            }
        } else {
            formData = {
                Updated_By: LoggedIn_User,
                OrderID: TicketDetails?.OrderID,
                Order_ID: TicketDetails?.Order_ID,
                UserID: TicketDetails?.UserID,
            }
            // setTimeout(() => {
            //     dispatch({
            //         type: CommonActions.SET_ALERT,
            //         payload: { alert: true, message: "Intimation Succeeds", status: 'success' }
            //     });
            // }, 2000);
        };
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TO_ACCOUNTS_AND_CUSTOMER_FOR_CUSTOMER_CANCEL, payload: {
                Id: TicketDetails?._id,
                data: formData
            }
        });
    };

    const handleEnterNotesForCustomerCancelPopup = (e) => {
        e.preventDefault();
        setShowEnterNotesForCustomerCancelPopup(true);
    };

    const handleVendorCancelIntimateToAccountsPopup = (e) => {
        e.preventDefault();
        setShowVendorCancelIntimateToAccountsPopup(true);
    };

    const handleCloseVendorCancelIntimateToAccountsPopup = () => {
        setShowVendorCancelIntimateToAccountsPopup(false);
    };

    const handleIntimateToAccountsForVendorCancel = (e) => {
        e.preventDefault();
        setLoader(true);
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Accounts: 'y' });
        let Last_Intimate = ['Accounts'];
        dispatch({
            type: actions.INTIMATE_TICKET, payload: {
                Id: TicketDetails?._id,
                data: {
                    Updated_By: LoggedIn_User,
                    Ticket_Accessed_By: ticket_Accessed_By,
                    Last_Intimate: Last_Intimate,
                }
            }
        });
    };

    const handleIntimatetoManagerForMiscellaneous = (e) => {
        e.preventDefault();
        setShowIntimateToManagerForMiscellaneous(true);
    };

    const handleIntimateToManager = (e) => {
        e.preventDefault();
        setLast_Intimate(last_Intimate = ['Manager']);
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Manager: 'y' });
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TICKET, payload: {
                Id: TicketDetails?._id,
                data: { Updated_By: LoggedIn_User, Ticket_Accessed_By: ticket_Accessed_By, Last_Intimate: last_Intimate }
            }
        });
    };

    const handleMiscellaneousApprovalByManagerPopup = (e) => {
        e.preventDefault();
        setShowMiscellaneousApprovalByManagerPopup(true);
    };

    const handleHelpdeskCRemarksForMiscellaneousPopup = (e) => {
        e.preventDefault();
        setShowHelpdeskCRemarksForMiscellaneous(true);
    };

    const handleIntimateToAccountsForMiscellaneous = (e) => {
        e.preventDefault();
        setShowIntimateToAccountsForMiscellaneous(true);
    };

    const handleCloseMiscellaneousPopups = (e) => {
        setShowHelpdeskCRemarksForMiscellaneous(false);
        setShowIntimateToAccountsForMiscellaneous(false);
    };

    const handleUpdateManagerApprovalStatusForMiscellaneous = (data) => {
        setLoader(true);
        dispatch({ type: actions.MISCELLANEOUS_APPROVAL_BY_MANAGER, payload: { Id: TicketDetails?._id, data: data } });
    };

    const handleIntimateToAccountsForMiscellaneousRefund = (e) => {
        e.preventDefault();
        setLast_Intimate(last_Intimate = ['Accounts']);
        setTicket_Accessed_By(ticket_Accessed_By = { ...ticket_Accessed_By, Accounts: 'y' });
        setLoader(true);
        dispatch({
            type: actions.INTIMATE_TICKET, payload: {
                Id: TicketDetails?._id,
                data: { Updated_By: LoggedIn_User, Ticket_Accessed_By: ticket_Accessed_By, Last_Intimate: last_Intimate }
            }
        });
    };

    const handleHelpdeskCRemarksForMiscellaneous = (remarks) => {
        setLoader(true);
        dispatch({
            type: actions.HELPDESKC_REMARKS_FOR_MISCELLANEOUS, payload: {
                Id: TicketDetails?._id, data: {
                    HelpdeskC_Remarks: remarks
                }
            }
        });
    };

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <TicketsDetails
                            state={state} TicketDetails={TicketDetails}
                            handleShowOrderDetailsPopup={handleShowOrderDetailsPopup}
                            handleShowVendorDetailsPopup={handleShowVendorDetailsPopup}
                            OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                            handleShowUserDetailsPopup={handleShowUserDetailsPopup}
                        />

                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">TICKET DESCRIPTION</h4>
                                    <hr />
                                    <p>{TicketDetails?.TicketDescription}</p>
                                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status && OrderDetailsByTypeOfOrder?.Status === 'Cancelled' && TicketDetails?.Ticket_Status !== "Aborted"
                                        && TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') &&
                                        <p className='text-success Note-message pt-3'><span className="text-danger Note-message ">Note: </span>This Order is Cancelled By Customer. Please Abort this ticket</p>
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' &&
                                        <OrderCancellationByCustomer
                                            TicketDetails={TicketDetails} state={state}
                                            handleAcceptTicket={handleAcceptTicket} auth={auth}
                                            handleEnterInputsPopup={handleEnterInputsPopup}
                                            handleIntimatePopup={handleIntimatePopup}
                                            handleHelpdeskCNotes={handleHelpdeskCNotes}
                                            OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                            handleIntimatetoAccountsForCustomerCancelPopup={handleIntimatetoAccountsForCustomerCancelPopup}
                                            handleEnterNotesForCustomerCancelPopup={handleEnterNotesForCustomerCancelPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Change Requests' &&
                                        <ChangeRequest
                                            TicketDetails={TicketDetails} auth={auth} state={state}
                                            handleIntimatePopup={handleIntimatePopup}
                                            handleEnterInputsPopup={handleEnterInputsPopup}
                                            handleAcceptTicket={handleAcceptTicket}
                                            handleIntimateToCustomerForChangeRequestPopup={handleIntimateToCustomerForChangeRequestPopup}
                                            // loader={loader}
                                            // handleHelpdeskCNotes={handleHelpdeskCNotes}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                            handleIntimateToCustomerForChangeRequestApprovedPopup={handleIntimateToCustomerForChangeRequestApprovedPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Fully Customized Cake' &&
                                        <HelpdeskCFullyCustomizedCake
                                            TicketDetails={TicketDetails} auth={auth} state={state}
                                            handleIntimatePopup={handleIntimatePopup} handleAcceptTicket={handleAcceptTicket}
                                            handleOrderAssignToVendorPopup={handleOrderAssignToVendorPopup}
                                            handleHeldpeskVEditPricePopup={handleHeldpeskVEditPricePopup}
                                            handleManagerEditPricePopup={handleManagerEditPricePopup}
                                            handleUpdateCustomerApproval={handleUpdateCustomerApproval}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Miscellaneous' &&
                                        <Miscellaneous
                                            TicketDetails={TicketDetails} auth={auth} state={state}
                                            handleIntimatePopup={handleIntimatePopup}
                                            handleEnterSolutionPopup={handleEnterSolutionPopup}
                                            handleManagerRemarksPopup={handleManagerRemarksPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                            handleIntimatetoManagerForMiscellaneous={handleIntimatetoManagerForMiscellaneous}
                                            handleMiscellaneousApprovalByManagerPopup={handleMiscellaneousApprovalByManagerPopup}
                                            handleHelpdeskCRemarksForMiscellaneousPopup={handleHelpdeskCRemarksForMiscellaneousPopup}
                                            handleIntimateToAccountsForMiscellaneous={handleIntimateToAccountsForMiscellaneous}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' &&
                                        <OrderCancellationByVendor
                                            TicketDetails={TicketDetails} auth={auth} state={state}
                                            handleUpdateCostDifferencePopup={handleUpdateCostDifferencePopup}
                                            handleIntimatePopup={handleIntimatePopup} handleAcceptTicket={handleAcceptTicket}
                                            handleEnterInputPopupVendorCancel={handleEnterInputPopupVendorCancel}
                                            handleEnterInputByHelpdeskV={handleEnterInputByHelpdeskV}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                            OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                                            NoVendor={NoVendor}
                                            handleVendorCancelIntimateToAccountsPopup={handleVendorCancelIntimateToAccountsPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Vendor Request' &&
                                        <VendorRequest
                                            TicketDetails={TicketDetails} auth={auth} state={state}
                                            handleIntimatePopup={handleIntimatePopup}
                                            handleEnterSolutionPopup={handleEnterSolutionPopup}
                                            handleManagerRemarksPopup={handleManagerRemarksPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' &&
                                        <HelpdeskVCakeyDirectFullyCustomizedCake
                                            TicketDetails={TicketDetails} auth={auth} handleIntimatePopup={handleIntimatePopup}
                                            handleHelpdeskVSelectVendorPopup={handleHelpdeskVSelectVendorPopup}
                                            state={state} handleVendorApprovedStatusPopup={handleVendorApprovedStatusPopup}
                                            handleHelpdeskVRemarksPopup={handleHelpdeskVRemarksPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Customized Cake through Vendor' &&
                                        <CustomizedCakeThroughVendor
                                            TicketDetails={TicketDetails} handleIntimateToCustomerForPaymentPopup={handleIntimateToCustomerForPaymentPopup}
                                            handleHeldpeskVEditPriceForVendorCCPopup={handleHeldpeskVEditPriceForVendorCCPopup}
                                            handleIntimatePopup={handleIntimatePopup} state={state} auth={auth} loader={loader}
                                            handleAcceptTicket={handleAcceptTicket} handleHelpdeskCNotes={handleHelpdeskCNotes}
                                            handleIntimateToVendorForOrderPopup={handleIntimateToVendorForOrderPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                            handleEnterNotesForCCThroughVendorPopup={handleEnterNotesForCCThroughVendorPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Cakey Direct-General Order' &&
                                        <CakeyDirectGeneralOrder
                                            TicketDetails={TicketDetails} auth={auth} handleIntimatePopup={handleIntimatePopup}
                                            handleHelpdeskVSelectVendorForGOPopup={handleHelpdeskVSelectVendorForGOPopup}
                                            state={state} handleVendorApprovedStatusPopup={handleVendorApprovedStatusPopup}
                                            handleHelpdeskVRemarksPopup={handleHelpdeskVRemarksPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                        />
                                    }
                                    {TicketDetails?.CauseOfTicket === 'Cancellation Within 15mins' &&
                                        <CancellationWithin15Mins
                                            TicketDetails={TicketDetails} auth={auth} handleIntimatePopup={handleIntimatePopup}
                                            state={state} handleAcceptTicket={handleAcceptTicket}
                                            handleIntimatetoCustomerPopup={handleIntimatetoCustomerPopup}
                                            handleShowCloseTicketPopup={handleShowCloseTicketPopup}
                                        />
                                    }
                                    <button
                                        className='btn btn-secondary float-start'
                                        type="submit"
                                        onClick={handleCloseDetails}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TicketIntimationPopup
                        showIntimateListPopup={showIntimateListPopup} handleUpdateIntimate={handleUpdateIntimate}
                        handleCloseIntimatePopup={handleCloseIntimatePopup}
                        auth={auth} loader={loader} enableIntimate={enableIntimate}
                        handleChangedAccess={handleChangedAccess} TicketDetails={TicketDetails}
                        VendorDetails={VendorDetails} handleIntimateToVendorForCCOrder={handleIntimateToVendorForCCOrder}
                    />
                    <EnterInputPopup
                        showEnterInputPopup={showEnterInputPopup} loader={loader} errors={errors}
                        handleCloseEnterInputPopup={handleCloseEnterInputPopup} register={register}
                        CauseOfTicket={TicketDetails?.CauseOfTicket} handleSubmit={handleSubmit}
                        handleUpdateStatus={handleUpdateStatus} setError={setError} setValue={setValue}
                        clearErrors={clearErrors} resetField={resetField} TicketDetails={TicketDetails}
                        handleUpdateChangeRequestStatus={handleUpdateChangeRequestStatus}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder} reset={reset}
                        Ticket_Response={Ticket_Response}
                    />
                    <HelpdeskCFullyCustomizedCakeAssignPopup
                        showHelpdeskCFullyCustomizedCakeAssignPopup={showHelpdeskCFullyCustomizedCakeAssignPopup}
                        handleCloseAssignPopup={handleCloseAssignPopup} vendorsList={vendorsList}
                        handleOrderAssignToVendor={handleOrderAssignToVendor} Ticket_Response={Ticket_Response}
                        loader={loader} UserDetails={UserDetails}
                    />
                    <HelpdeskVEditPricePopup
                        showHelpdeskVEditPricePopup={showHelpdeskVEditPricePopup} CustomizedCakeDeatils={CustomizedCakeDeatils}
                        handleCloseEditPricePopup={handleCloseEditPricePopup} loader={loader}
                        handleCustomizedCakeEditedPrice={handleCustomizedCakeEditedPrice}
                    />
                    <ManagerEditPricePopup
                        showManagerEditPricePopup={showManagerEditPricePopup}
                        handleCloseManagerEditPricePopup={handleCloseManagerEditPricePopup}
                        CustomizedCakeDeatils={CustomizedCakeDeatils} loader={loader}
                        handleCustomizedCakeEditedPriceByManager={handleCustomizedCakeEditedPriceByManager}
                    />
                    <EnterSolutionPopup
                        showEnterSolutionPopup={showEnterSolutionPopup}
                        handleCloseEnterSolutionPopup={handleCloseEnterSolutionPopup}
                        handleUpdateSolution={handleUpdateSolution} loader={loader}
                    />
                    <ManagerRemarksPopup
                        showManagerRemarksPopup={showManagerRemarksPopup}
                        handleCloseEnterSolutionPopup={handleCloseEnterSolutionPopup}
                        handleManagerRemarks={handleManagerRemarks}
                    />
                    <UpdateCustomerApproval
                        showUpdateCustomerApprovalPopup={showUpdateCustomerApprovalPopup}
                        handleCloseUpdateApprovalPopup={handleCloseUpdateApprovalPopup} loader={loader}
                        handleIntimateToCustomer={handleIntimateToCustomer} Ticket_Response={Ticket_Response}
                        UserDetails={UserDetails}
                    />
                    <HelpdeskVFullyCustomizedCakeSelectVendorPopup
                        showHelpdeskVSelectVendorPopup={showHelpdeskVSelectVendorPopup}
                        handleCloseHelpdeskVSelectVendor={handleCloseHelpdeskVSelectVendor}
                        vendorsList={vendorsList} ExistingVendorID={TicketDetails?.VendorID}
                        handleSubmitCallConversation={handleSubmitCallConversation} loader={loader}
                    />
                    <VendorApprovedStatusPopup
                        showUpdateVendorApprovedStatusPopup={showUpdateVendorApprovedStatusPopup}
                        handleCloseVendorApprovedStatusPopup={handleCloseVendorApprovedStatusPopup}
                        TicketDetails={TicketDetails} VendorDetails={VendorDetails} loader={loader}
                        handleUpdateVendorApprovalStatus={handleUpdateVendorApprovalStatus}
                    />
                    <HelpdeskVRemarksPopup
                        showHelpdeskVRemarksPopup={showHelpdeskVRemarksPopup}
                        handleCloseHelpdeskVRemarksPopup={handleCloseHelpdeskVRemarksPopup}
                        handleHelpdeskVRemarks={handleHelpdeskVRemarks} loader={loader}
                    />
                    <HelpdeskVRemarksForVendorCancelPopup
                        showHelpdeskVRemarksForVendorCancel={showHelpdeskVRemarksForVendorCancel}
                        handleCloseHelpdeskVRemarksPopup={handleCloseHelpdeskVRemarksPopup}
                        handleHelpdeskVRemarksForVendorCancel={handleHelpdeskVRemarksForVendorCancel} loader={loader}
                    />
                    <HelpdeskVEditPriceDirectVendorCustomizedCakePopup
                        showHelpdeskVEditPriceForVendorCCPopup={showHelpdeskVEditPriceForVendorCCPopup}
                        handleCloseEditPricePopup={handleCloseEditPricePopup} TicketDetails={TicketDetails}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder} loader={loader}
                        handleCustomizedCakePriceByHelpdeskV={handleCustomizedCakePriceByHelpdeskV}
                    />
                    <IntimateToCustomerForPayment
                        showIntimateToCustomerPopup={showIntimateToCustomerPopup} loader={loader}
                        handleCloseIntimateToCustomerPopup={handleCloseIntimateToCustomerPopup}
                        handleIntimateToCustomerForPayment={handleIntimateToCustomerForPayment}
                        UserDetails={UserDetails}
                    />
                    <HelpdeskVRemarksForVendorCCPopup
                        showIntimateToVendorPopup={showIntimateToVendorPopup} loader={loader}
                        handleCloseHelpdeskVRemarksPopup={handleCloseHelpdeskVRemarksPopup}
                        handleHelpdeskVRemarksForVendorCC={handleHelpdeskVRemarksForVendorCC}
                    />
                    <HelpdeskVCakeyGeneralOrderSelectVendorPopup
                        showHelpdeskVCakeyGOSelectVendorPopup={showHelpdeskVCakeyGOSelectVendorPopup}
                        handleCloseAssignPopup={handleCloseAssignPopup} vendorsList={vendorsList}
                        Ticket_Response={Ticket_Response} loader={loader} CakeDetails={CakeDetails}
                        handleSubmitCallConversation={handleSubmitCallConversation}
                    />
                    <OrderDetailsPopup
                        handleCloseDetailsPopup={handleCloseDetailsPopup}
                        showOrderDetailsPopup={showOrderDetailsPopup} TypeOfOrder={TicketDetails?.TypeOfOrder}
                        OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                        CauseOfTicket={TicketDetails?.CauseOfTicket}
                    />
                    <VendorDetailsPopup
                        showVendorDetailsPopup={showVendorDetailsPopup} VendorDetails={VendorDetails}
                        handleCloseDetailsPopup={handleCloseDetailsPopup}
                    />
                    <UserDetailsPopup
                        handleCloseDetailsPopup={handleCloseDetailsPopup} showUserDetailsPopup={showUserDetailsPopup}
                    />
                    <CloseTicketPopup
                        showCloseTicketPopup={showCloseTicketPopup} handleCloseTicketPopup={handleCloseTicketPopup}
                        handleCloseTicket={handleCloseTicket} loader={loader} UserDetails={UserDetails} TicketDetails={TicketDetails}
                    />
                    {/* HelpdeskV Vendor Cancel order  start*/}
                    <UpdateCostDifferencePopup
                        showhandleUpdateCostDifferencePopup={showhandleUpdateCostDifferencePopup}
                        vendorsList={vendorsList} register1={register1} errors1={errors1} handleSubmit1={handleSubmit1}
                        reset={reset2} TicketDetails={TicketDetails}
                        handleCloseCostDifferencePopup={handleCloseCostDifferencePopup}
                        handleOrderAssignToVendor={handleOrderAssignToVendor}
                        NoVendor={NoVendor}
                        handleEnterInputPopupVendorCancel={handleEnterInputPopupVendorCancel}
                        ProductList={ProductList}
                    />
                    <EnterInputPopupVendorCancel
                        showHelpdeskVEnterInputPopup={showHelpdeskVEnterInputPopup}
                        TicketDetails={TicketDetails} LoggedIn_User={LoggedIn_User} OrderDetailsByTypeOfOrder={OrderDetailsByTypeOfOrder}
                        VendorDetails={VendorDetails} Ticket_Response={Ticket_Response}
                        handleCloseHelpdeskVEnterInputPopup={handleCloseHelpdeskVEnterInputPopup}
                    />
                    <IntimateToCustomerForCancel
                        showIntimateToCustomerForCancelPopup={showIntimateToCustomerForCancelPopup}
                        loader={loader}
                        handleCloseIntimateToCustomerPopup={handleCloseIntimateToCustomerPopup}
                        handleIntimateToCustomerForCancel={handleIntimateToCustomerForCancel}
                        UserDetails={UserDetails}
                    />
                    <EnterNotesForChangeRequest
                        showIntimateToCustomerForChangeRequestPopup={showIntimateToCustomerForChangeRequestPopup}
                        handleCloseIntimateToCustomerForChangeRequestPopup={handleCloseIntimateToCustomerForChangeRequestPopup}
                        loader={loader} handleHelpdeskCNotes={handleHelpdeskCNotes}
                        UserDetails={UserDetails}
                    />
                    <IntimationForChangeRequestApprovedPopup
                        handleCloseIntimateToCustomerForChangeRequestPopup={handleCloseIntimateToCustomerForChangeRequestPopup}
                        showIntimateToCustomerForChangeRequestApprovedPopup={showIntimateToCustomerForChangeRequestApprovedPopup}
                        UserDetails={UserDetails} handleIntimateToCustomerForChangeRequestApprove={handleIntimateToCustomerForChangeRequestApprove}
                        loader={loader}
                    />
                    <CustomizedCakeThroughVendorEnterNotesPopup
                        handleCloseEnterNotesForCCThroughVendorPopup={handleCloseEnterNotesForCCThroughVendorPopup}
                        showEnterNotesForCCThroughVendorPopup={showEnterNotesForCCThroughVendorPopup}
                        loader={loader} handleHelpdeskCNotes={handleHelpdeskCNotes}
                    />
                    <IntimateToAccountsForCustomerCancel
                        showIntimateToAccountsForCustomerCancelPopup={showIntimateToAccountsForCustomerCancelPopup}
                        handleCloseIntimatetoAccountsForCustomerCancelPopup={handleCloseIntimatetoAccountsForCustomerCancelPopup}
                        loader={loader} handleIntimateToAccountsForCustomerCancel={handleIntimateToAccountsForCustomerCancel}
                        UserDetails={UserDetails}
                    />
                    <EnterNotesForCustomerCancel
                        showEnterNotesForCustomerCancelPopup={showEnterNotesForCustomerCancelPopup}
                        handleCloseIntimateToCustomerForChangeRequestPopup={handleCloseIntimateToCustomerForChangeRequestPopup}
                        loader={loader} handleHelpdeskCNotes={handleHelpdeskCNotes}
                        UserDetails={UserDetails}
                    />
                    <HelpdeskVVendorCancelIntimateToAccountsPopup
                        handleCloseVendorCancelIntimateToAccountsPopup={handleCloseVendorCancelIntimateToAccountsPopup}
                        showVendorCancelIntimateToAccountsPopup={showVendorCancelIntimateToAccountsPopup}
                        handleIntimateToAccountsForVendorCancel={handleIntimateToAccountsForVendorCancel}
                        loader={loader}
                    />
                    <IntimationToManagerForMiscellaneous
                        showIntimateToManagerForMiscellaneous={showIntimateToManagerForMiscellaneous}
                        handleCloseIntimateToCustomerForChangeRequestPopup={handleCloseIntimateToCustomerForChangeRequestPopup}
                        handleIntimateToManager={handleIntimateToManager} loader={loader}
                    />
                    <MiscellaneousApprovalByManager
                        showMiscellaneousApprovalByManagerPopup={showMiscellaneousApprovalByManagerPopup}
                        handleCloseIntimateToCustomerForChangeRequestPopup={handleCloseIntimateToCustomerForChangeRequestPopup}
                        handleUpdateManagerApprovalStatusForMiscellaneous={handleUpdateManagerApprovalStatusForMiscellaneous}
                        loader={loader}
                    />
                    <IntimateToAccountsForMiscellaneous
                        showIntimateToAccountsForMiscellaneous={showIntimateToAccountsForMiscellaneous}
                        handleCloseMiscellaneousPopups={handleCloseMiscellaneousPopups}
                        handleIntimateToAccountsForMiscellaneousRefund={handleIntimateToAccountsForMiscellaneousRefund}
                    />
                    <HelpdeskCRemarksForMiscellaneousPopup
                        showHelpdeskCRemarksForMiscellaneous={showHelpdeskCRemarksForMiscellaneous}
                        handleCloseMiscellaneousPopups={handleCloseMiscellaneousPopups}
                        handleHelpdeskCRemarksForMiscellaneous={handleHelpdeskCRemarksForMiscellaneous}
                        loader={loader}
                    />
                </div>
            }
        </>
    )
};