import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from '../Vendor/actions';
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const VendorListSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ALL_VENDORS, getAllVendors),
        yield takeEvery(actions.ADD_VENDORS, AddVendor),
        yield takeEvery(actions.GET_SINGLE_VENDORS, getVendorDetails),
        yield takeEvery(actions.UPDATE_VENDOR, UpdateVendor),
        yield takeEvery(actions.GET_VENDOR_RECENT_ORDER, getVendorRecentOrder),
        yield takeEvery(actions.GET_VENDORORDER_STATUS_COUNT, getVendorOrderStatusCount),
        yield takeEvery(actions.REGISTER_VENDOR, RegisterVendor),
        yield takeEvery(actions.APPROVE_VENDOR, ApproveVendor),
        yield takeEvery(actions.GET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID, GetVendorsNewCustomizeCakeList),
        yield takeEvery(actions.GET_LOGIN_SESSION, GetLoginSession),
        yield takeEvery(actions.UPLOAD_PROFILE_IMAGE, UploadProfileImage),
        yield takeEvery(actions.BLOCK_AND_UNBLOCK_VENDOR, BlockAndUnblockVendor),
        yield takeEvery(actions.GET_VENDOR_DETAILS_BY_ID, GetVendorDetailsById),
        yield takeEvery(actions.GET_VENDOR_DETAILS2, GetVendorDetails2),
        yield takeEvery(actions.GET_VENDOR_DASHBOARD_COUNT, GetVendorDashboardCount),
    ]);
};

//get all vendors list
const getAllVendors = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/alllist`
            )
        );
        yield put({
            type: actions.SET_ALL_VENDORS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//add new vendor
const AddVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/vendors/new`, payload
            )
        );
        yield put({
            type: actions.ADD_VENDORS_STATUS,
            payload: { addVendorStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get single vendor details
const getVendorDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/listbyemail/${payload}`
            )
        );
        yield put({
            type: actions.SET_SINGLE_VENDORS,
            payload: { vendorsDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//update vendor details
const UpdateVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/vendors/update/${payload.id}`, payload.body
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.SET_UPDATE_VENDORPROFILE_STATUS,
            payload: { UpdateVendorProfileStatus: result.data }
        });
        yield put({ type: actions.GET_VENDOR_DETAILS2, payload: payload.id });
        // yield put({ type: actions.GET_SINGLE_VENDORS, payload: payload.Email });
        yield delay(2000);
        yield put({ type: actions.SET_UPDATE_VENDORPROFILE_STATUS, payload: { UpdateVendorProfileStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    };
};

//update vendor details
const UploadProfileImage = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/vendors/profileimage/upload/${payload.id}`, payload.body
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_VENDOR_DETAILS2, payload: payload.id });
        // yield put({ type: actions.GET_SINGLE_VENDORS, payload: payload.Email });
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get recent orders based on vendorid and status
const getVendorRecentOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbyvendorstatus/${payload.id}/${payload.Status}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_RECENT_ORDER,
            payload: { RecentOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor orders status count
const getVendorOrderStatusCount = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/countbyvendorid/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDORORDER_STATUS_COUNT,
            payload: { StatusCount: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor's new customized cakes list
const GetVendorsNewCustomizeCakeList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/listbystatus/${payload.id}/${payload.status}`
            )
        );
        yield put({
            type: actions.SET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID,
            payload: { NewCustomizeCake: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//register vendor
const RegisterVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/vendors/register`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.REGISTER_VENDOR_STATUS,
            payload: { RegisterVendorStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//approve registered vendor
const ApproveVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/vendors/new/${payload.id}`, {
                VendorName: payload.VendorName,
                Email: payload.Email,
                Password: payload.Password,
                Status: 'Approved'
            }
            )
        );
        yield put({
            type: actions.APPROVE_VENDOR_STATUS,
            payload: { ApproveVendorStatus: result.data }
        });
        if (result.data.statusCode === 200) {
            localStorage.setItem('ApproveVendorStatus', 'true');
        };

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetLoginSession = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/loginsession/${payload.id}/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_LOGIN_SESSION,
            payload: { LoginSession: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const BlockAndUnblockVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/vendors/block/${payload.id}`, {
                Status: payload.body
            }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_VENDOR_DETAILS2, payload: payload.id });
        yield put({ type: actions.VENDOR_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.VENDOR_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorDetailsById = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/details/${payload.Id}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_DETAILS_BY_ID,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorDetails2 = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_DETAILS2,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorDashboardCount = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/Vendor/dashboardCount/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_DASHBOARD_COUNT,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default VendorListSaga;