import moment from "moment";

export default function SortingOrders (props) {

    const { type, field, filterData } = props;

    let FinalData = [];

    if(field === 'OD' && type === 'A'){
        FinalData = filterData.sort((a, b) => { return moment(a.Created_On, 'DD-MM-YYYY hh:mm A').diff(moment(b.Created_On, 'DD-MM-YYYY hh:mm A')) });
    }else if(field === 'OD' && type === 'D'){
        let result = filterData.sort((a, b) => { return moment(a.Created_On, 'DD-MM-YYYY hh:mm A').diff(moment(b.Created_On, 'DD-MM-YYYY hh:mm A')) });
        FinalData = result.reverse();
    }else if(field === 'DD' && type === 'A'){
        FinalData = filterData.sort((a, b) => { return moment(a.DeliveryDate, 'DD-MM-YYYY').diff(moment(b.DeliveryDate, 'DD-MM-YYYY')) });
    }else if(field === 'DD' && type === 'D'){
        let result = filterData.sort((a, b) => { return moment(a.DeliveryDate, 'DD-MM-YYYY').diff(moment(b.DeliveryDate, 'DD-MM-YYYY')) });
        FinalData = result.reverse();
    }else if(field === 'CD' && type === 'A'){
        FinalData = filterData.sort((a, b) => { return moment(a.Status_Updated_On, 'DD-MM-YYYY').diff(moment(b.Status_Updated_On, 'DD-MM-YYYY')) });
    }else if(field === 'CD' && type === 'D'){
        let result = filterData.sort((a, b) => { return moment(a.Status_Updated_On, 'DD-MM-YYYY').diff(moment(b.Status_Updated_On, 'DD-MM-YYYY')) });
        FinalData = result.reverse();
    };
    return FinalData;

};