import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../common/MenuLink';
import Dropdown from '../common/dropdown';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../common/Alert';
import { MdDashboard } from 'react-icons/md';
import { BsFileEarmarkTextFill, BsBank2 } from 'react-icons/bs';
import { RiCake2Fill, RiCustomerServiceFill } from 'react-icons/ri';
import { FaUsers, FaShapes, FaFileInvoice, FaChartLine } from 'react-icons/fa';
import { ImUsers } from 'react-icons/im';
import { IoGift } from 'react-icons/io5';
import { CgListTree } from 'react-icons/cg';
import { IoReceipt } from 'react-icons/io5';

export default function Sidenavigation() {

    //for get the path name
    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'A5KG' || Filtered_Dates?.Page === 'B5KG')) &&
                (pathName !== '/orders' && pathName !== '/Admin-OrderDetails')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'AOPO')) && (pathName !== '/Admin-product-orders-list' && pathName !== '/Admin-product-orders-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'CCA5KG' || Filtered_Dates?.Page === 'CCB5KG')) &&
                    (pathName !== '/Customize-cake-list' && pathName !== '/Customize-cake-details')) ||
                ((Filtered_Dates?.Page && (Filtered_Dates?.Page === 'AHO')) && (pathName !== '/Admin-hamper-orders-list' && pathName !== '/Admin-hamper-order-details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        };
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            <MenuLink to="/Dashboard" >
                                <MdDashboard className="sidenavigationIcon" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                        </li>
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/admin-vendor-list-detail' || pathName === '/Admin-OrderDetails' ||
                                    pathName === '/customer-details' || pathName === '/registered-vendor-details' ||
                                    pathName === '/admin-cake-details' || pathName === '/new-cake-details' ||
                                    pathName === '/Customize-cake-details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/Admin-hamper-details' || pathName === '/Admin-hamper-order-details' ||
                                    pathName === '/Admin-statement-of-accounts-details' || pathName === '/Admin-product-details' ||
                                    pathName === '/Admin-product-orders-details' || pathName === '/Admin-InternalUser-Details' ||
                                    pathName === '/admin-cake-update' ?
                                    '/orders' : '#'}
                                className={(pathName === '/orders' || pathName === '/Admin-product-orders-list' ||
                                    pathName === '/Customize-cake-list' || pathName === '/Admin-hamper-orders-list' ||
                                    pathName === '/Admin-OrderDetails' || pathName === '/Customize-cake-details' ||
                                    pathName === '/Admin-hamper-order-details' || pathName === '/Admin-product-orders-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/orders" >Orders List</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Admin-product-orders-list" >Other Product Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Customize-cake-list" >Customised Cake Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Admin-hamper-orders-list" >Hamper Orders</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/admin-vendor-list-detail' || pathName === '/Admin-OrderDetails' ||
                                    pathName === '/customer-details' || pathName === '/registered-vendor-details' ||
                                    pathName === '/admin-cake-details' || pathName === '/new-cake-details' ||
                                    pathName === '/Customize-cake-details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/Admin-hamper-details' || pathName === '/Admin-hamper-order-details' ||
                                    pathName === '/Admin-statement-of-accounts-details' || pathName === '/Admin-product-details' ||
                                    pathName === '/Admin-product-orders-details' || pathName === '/Admin-InternalUser-Details' ||
                                    pathName === '/admin-cake-update' ?
                                    '/admin-cake-list' : '#'}
                                className={(pathName === '/new-cakes-list' || pathName === '/admin-cake-list' ||
                                    pathName === '/admin-cake-details' || pathName === '/Admin-product-details' || pathName === '/new-cake-details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <RiCake2Fill className="sidenavigationIcon" /><span className='pl-3'>Cakes &amp; Other Products</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/new-cakes-list" >New Cakes &amp; Other Products</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/admin-cake-list" >Approved Cakes &amp; Other Products</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/admin-vendor-list-detail' || pathName === '/Admin-OrderDetails' ||
                                    pathName === '/customer-details' || pathName === '/registered-vendor-details' ||
                                    pathName === '/admin-cake-details' || pathName === '/new-cake-details' ||
                                    pathName === '/Customize-cake-details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/Admin-hamper-details' || pathName === '/Admin-hamper-order-details' ||
                                    pathName === '/Admin-statement-of-accounts-details' || pathName === '/Admin-product-details' ||
                                    pathName === '/Admin-product-orders-details' || pathName === '/Admin-InternalUser-Details' ||
                                    pathName === '/admin-cake-update' ?
                                    '/admin-vendor-List' : '#'}
                                className={(pathName === '/admin-vendor-List' || pathName === '/Login-history' ||
                                    pathName === '/admin-vendor-list-detail' || pathName === '/admin-vendor-detail-edit') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <FaUsers className="sidenavigationIcon" /><span className='pl-3'>Vendors</span>
                            </MenuLink>}
                            body={
                                <ul className='active'  >
                                    {/* <li>
                                        <MenuLink to="/default-register" >Vendor Registration</MenuLink>
                                    </li> */}
                                    <li>
                                        <MenuLink to="/admin-vendor-List" >Vendors List</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Login-history" >Login History</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/admin-vendor-list-detail' || pathName === '/Admin-OrderDetails' ||
                                    pathName === '/customer-details' || pathName === '/registered-vendor-details' ||
                                    pathName === '/admin-cake-details' || pathName === '/new-cake-details' ||
                                    pathName === '/Customize-cake-details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/Admin-hamper-details' || pathName === '/Admin-hamper-order-details' ||
                                    pathName === '/Admin-statement-of-accounts-details' || pathName === '/Admin-product-details' ||
                                    pathName === '/Admin-product-orders-details' || pathName === '/Admin-InternalUser-Details' ||
                                    pathName === '/admin-cake-update' ?
                                    '/Admin-InternalUsers-List' : '#'}
                                className={(pathName === '/Admin-register' || pathName === '/Admin-InternalUsers-List' ||
                                    pathName === '/Admin-InternalUser-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <RiCustomerServiceFill className="sidenavigationIcon" /><span className='pl-3'>Internal Users</span>
                            </MenuLink>}
                            body={
                                <ul className='active'  >
                                    <li>
                                        <MenuLink to="/Admin-register" >Internal User Registration</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Admin-InternalUsers-List" >Internal Users List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/customer-list">
                                <ImUsers className="sidenavigationIcon" /><span className='pl-3'>Customers List</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Admin-hamper-list">
                                <IoGift className="sidenavigationIcon" /><span className='pl-3'>Hampers List</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/cake-design-and-decoration">
                                <FaShapes className="sidenavigationIcon" /><span className='pl-3'>Cake Properties</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Admin-cake-types">
                                <CgListTree className="sidenavigationIcon" /><span className='pl-3'>Cake Type</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Admin-bankname-list">
                                <BsBank2 className="sidenavigationIcon" /><span className='pl-3'>Bank Name</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Statement-of-accounts">
                                <IoReceipt className="sidenavigationIcon" /><span className='pl-3'>Statement Of Accounts</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Admin-statement-of-accounts">
                                <FaFileInvoice className="sidenavigationIcon" /><span className='pl-3'>Vendor Statement Of Accounts</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Chart">
                                <FaChartLine className="sidenavigationIcon" /><span className='pl-3'>Chart</span>
                            </MenuLink>
                        </li >
                        {/* <li className="menu-item">
                            <MenuLink to="/Admin-register" >
                                <span><i className="fa fa-file-invoice fs-16" ></i>Register</span>
                            </MenuLink>
                        </li > */}

                    </ul >
                </Scrollbar >
            </div >
        </>
    );
};