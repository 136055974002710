import actions from "./actions";

const initState = {
    Order_list: [],
    OrderListByAbove5kg: [],
    Above5kgAssignStatus: [],
    CancelOrderStatus: [],
    DeliveryCharge: [],
    StatementOfAccounts: [],
    VendorStatementList: [],
    VendorStatementDetails: [],
    CreateStatementStatus: [],
    Tax: [],
    productShareperList: [],
    VendorsOrdersList: [],
    OrderResponse: [],
};

const OrderReducer = (state = initState, action) => {
    switch (action.type) {

        case actions.SET_UPDATE_PRODUCT_SHARE_PER_LIST: {
            return {
                ...state,
                productShareperList: action.payload

            };
        }
        case actions.SET_ORDER_LIST: {
            return {
                ...state,
                Order_list: action.payload

            };
        }
        case actions.SET_ORDER_LIST_BY_ABOVE_5KG: {
            return {
                ...state,
                OrderListByAbove5kg: action.payload.OrderListByAbove5kg

            };
        }
        case actions.ABOVE_5KG_ORDER_ASSIGN_STATUS: {
            return {
                ...state,
                Above5kgAssignStatus: action.payload.Above5kgAssignStatus

            };
        }
        case actions.CANCEL_ORDER_STATUS: {
            return {
                ...state,
                CancelOrderStatus: action.payload.CancelOrderStatus

            };
        }
        case actions.SET_DELIVERY_CHARGE: {
            return {
                ...state,
                DeliveryCharge: action.payload.DeliveryCharge

            };
        }
        case actions.SET_ADMIN_STATEMENTOFACCOUNTS:
            return {
                ...state,
                StatementOfAccounts: action.payload.StatementOfAccounts,
            };
        case actions.SET_VENDOR_STATEMENTOFACCOUNTS_LIST:
            return {
                ...state,
                VendorStatementList: action.payload.VendorStatementList,
            };
        case actions.SET_VENDOR_STATEMENT_DETAILS:
            return {
                ...state,
                VendorStatementDetails: action.payload.VendorStatementDetails,
            };
        case actions.CREATE_STATEMENT_STATUS:
            return {
                ...state,
                CreateStatementStatus: action.payload.CreateStatementStatus,
            };
        case actions.SET_TAX:
            return {
                ...state,
                Tax: action.payload.Tax,
            };
        case actions.SET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR:
            return {
                ...state,
                VendorsOrdersList: action.payload,
            };
        case actions.ORDER_RESPONSE:
            return {
                ...state,
                OrderResponse: action.payload,
            };


        default: return state;
    }
}

export default OrderReducer;