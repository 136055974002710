import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { useSelector } from "react-redux";
import { NumberValidation, float2D } from "../../../../utils/Validation";

export default function MiscellaneousApprovalByManager(props) {

    const { showMiscellaneousApprovalByManagerPopup, handleCloseIntimateToCustomerForChangeRequestPopup, loader,
        handleUpdateManagerApprovalStatusForMiscellaneous } = props;

    const { OrderDetailsByTypeOfOrder, TicketDetails } = useSelector(state => state.TicketReducer);

    const [updatedStatus, setUpdatedStatus] = useState({ Status: '', Validation: false });
    const [refundType, setRefundType] = useState({ Type: '', Validation: false });
    const [refundValue, setRefundValue] = useState({ Value: '', Validation: false, FieldName: '' });

    const handleUpdate = (e, Field) => {
        if (Field === 'Manager_Approved_Status') {
            setRefundType({ Type: '', Validation: false });
            setRefundValue({ Value: '', Validation: false, FieldName: '' });
            (e.target.value) ? setUpdatedStatus({ Status: e.target.value, Validation: false }) :
                setUpdatedStatus({ Status: '', Validation: true });
        } else if (Field === 'RefundType') {
            (e.target.value) ? setRefundType({ Type: e.target.value, Validation: false }) :
                setRefundType({ Type: '', Validation: true });
            setRefundValue({
                Value: OrderDetailsByTypeOfOrder?.NewTotal ?
                    float2D(OrderDetailsByTypeOfOrder?.NewTotal) :
                    float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue || 0)),
                Validation: false,
                FieldName: (e.target.value === 'Cash') ? 'Refund_Amount' : 'CouponValue'
            })
        } else if (Field === 'RefundValue') {
            (e.target.value) ? setRefundValue({
                Value: e.target.value,
                Validation: false,
                FieldName: (refundType?.Type === 'Cash') ? 'Refund_Amount' : 'CouponValue'
            }) : setRefundValue({ Value: '', Validation: false, FieldName: '' });
        };
    };

    const onSubmit = (e) => {
        e.preventDefault();
        let formData;
        if (updatedStatus?.Status === 'Approved') {
            let FieldName = refundValue?.FieldName;
            let CouponType = (refundType?.Type === 'Coupon') ? 'Amount' : '';
            formData = {
                Manager_Approved_Status: updatedStatus?.Status,
                RefundType: refundType?.Type,
                [FieldName]: refundValue?.Value,
                CouponType: CouponType,
                Refund_Status: 'Pending',
                Last_Intimate: ['HelpdeskC']
            }
        } else {
            formData = {
                Manager_Approved_Status: updatedStatus?.Status,
                Last_Intimate: ['HelpdeskC']
            }
        };
        handleUpdateManagerApprovalStatusForMiscellaneous(formData);
        // dispatch({ type: ticketActions.MISCELLANEOUS_APPROVAL_BY_MANAGER, payload: { Id: TicketDetails?.Id, data: formData } });
    };

    const handleClose = (e) => {
        setUpdatedStatus({ Status: '', Validation: false });
        setRefundType({ Type: '', Validation: false });
        setRefundValue({ Value: '', Validation: false, FieldName: '' });
        handleCloseIntimateToCustomerForChangeRequestPopup(e);
    };

    return (
        <div>
            <Modal show={showMiscellaneousApprovalByManagerPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Miscellaneous</h4>
                    <div className="form-row">
                        <div className="col-md-12 col-sm-12">
                            <div className="ms-panel-body tabledata-background">
                                {/* <h4 className="bold text-center">Miscellaneous</h4> */}
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Type of Miscellaneous</th>
                                            <td><b>{TicketDetails?.TypeOfMiscellaneous}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Type of Order</th>
                                            <td>{TicketDetails?.TypeOfOrder}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Order ID</th>
                                            <td>{TicketDetails?.Order_ID}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Order Total</th>
                                            <td>{OrderDetailsByTypeOfOrder?.NewTotal ? float2D(OrderDetailsByTypeOfOrder?.NewTotal) : float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue || 0))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12 mb-2">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Approval for Refund<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select
                                    className={updatedStatus?.Validation ? 'form-control required-field' : 'form-control'}
                                    defaultValue=''
                                    // {...register('Manager_Approved_Status', { required: { value: true, message: 'required' } })}
                                    onChange={(e) => handleUpdate(e, 'Manager_Approved_Status')}
                                    value={updatedStatus?.Status || ''}
                                >
                                    <option value='' disabled>--Select--</option>
                                    <option value='Approved'>Approved</option>
                                    <option value='Not Approved'>Not Approved</option>
                                </select>
                            </div>
                        </div>
                        {(!updatedStatus?.Validation && updatedStatus?.Status === 'Approved') &&
                            <div className="col-md-12 col-sm-12 mb-2">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Type<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <select
                                        className={refundType?.Validation ? 'form-control required-field' : 'form-control'}
                                        defaultValue=''
                                        onChange={(e) => handleUpdate(e, 'RefundType')}
                                        value={refundType?.Type || ''}
                                    >
                                        <option value='' disabled>--Select--</option>
                                        <option value='Cash'>Cash</option>
                                        <option value='Coupon'>Coupon</option>
                                    </select>
                                </div>
                            </div>
                        }
                        {(refundType?.Type) &&
                            <div className="col-md-12 col-sm-12">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">{refundType?.Type === 'Cash' ? 'Refund Amount' : 'Coupon Amount'}<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input
                                        onWheel={() => document.activeElement.blur()}
                                        className='form-control'
                                        placeholder='Enter Here...'
                                        onInput={NumberValidation}
                                        maxLength={10} min={0}
                                        onChange={(e) => handleUpdate(e, 'RefundValue')}
                                        value={refundValue?.Value || ''}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div>
                        <p className="text-success text-center intimation-message">This will be auto intimated to Customer Helpdesk</p>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={(((updatedStatus?.Status) &&
                                    ((updatedStatus?.Status === 'Approved' && refundType?.Type && (refundValue?.Value && parseFloat(refundValue?.Value) > 0)) ||
                                        (updatedStatus?.Status === 'Not Approved'))) ? false : true)}
                                className="btn btn-primary shadow-none mr-3"
                                onClick={onSubmit}
                            >
                                Submit
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};