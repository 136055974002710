import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Dropdown, NavLink } from 'react-bootstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import MenuLink from '../../common/MenuLink';
import actions from '../../../redux/Auth/actions';
import { Modal } from 'react-bootstrap';
import actions2 from '../../../redux/ForgotPassword/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import Alert from '../../common/Alert';
import CommonActions from '../../../redux/Common/actions';
import { FaKey, FaPowerOff, FaEye, FaEyeSlash } from 'react-icons/fa';

export default function Topnavigation() {

    const dispatch = useDispatch();

    const PasswordR = useRef();
    const ConfirmPasswordR = useRef();

    const [show, setShow] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [timer, setTimer] = useState("");

    //get logged in users details
    const auth = useSelector(
        state => state.authReducer
    );

    //get status for change password
    const Status = useSelector(
        state => state.forgotpasswordReducer
    );

    const { response } = useSelector(
        state => state.commonReducer
    );

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: {
                alert: false,
                message: null,
                status: null
            }
        });
    };

    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    };

    //for logout
    const logout = () => {
        dispatch({ type: actions.UPDATE_AUTH_DETAILS, payload: { isAuthenticated: false } });
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();
    };

    //for show forget password popup
    const handleChangePassword = (e) => {
        e.preventDefault();
        setShow(true);
    };

    //for close popup
    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
    };

    //for change password
    const handleChange = (e) => {
        e.preventDefault();
        setLoader(true);
        setMessage('');
        if (PasswordR.current.value && ConfirmPasswordR.current.value) {
            if (PasswordR.current.value === ConfirmPasswordR.current.value) {
                if (auth.token !== undefined) {
                    dispatch({
                        type: actions2.CHANGE_PASSWORD,
                        payload: { id: auth.token.result._id, Password: PasswordR.current.value }
                    });
                    setTimeout(() => { dispatch({ type: actions2.CHANGE_PASSWORD_STATUS, payload: { ChangePasswordStatus: [] } }); }, 5000);
                }
            } else {
                setMessage("Password doe's not match");
                setLoader(false);
            }
        } else {
            setMessage('*required');
            setLoader(false);
        }
    };

    //for show alert if password updated
    useEffect(() => {
        if (Status.ChangePasswordStatus.statusCode === 400) {
            setMessage(Status.ChangePasswordStatus.message);
            setLoader(false);
        } else if (Status.ChangePasswordStatus.statusCode === 200) {
            setLoader(false);
            setShow(false);
            if (response.alert) {
                setShowAlert(true);
                setTimer(setInterval(() => {
                    clearNotification()
                }, 4000))
            };
        };
        // eslint-disable-next-line
    }, [Status, response]);

    return (
        <>
            <nav className="navbar ms-navbar tabledata-background">
                <div className="ms-aside-toggler ms-toggler pl-0">
                    {/* <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" /> */}
                </div>
                <div className="logo-sn ">
                    <MenuLink className="pl-0 ml-0 text-center navbar-brand mr-0" to="/"><img src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" /></MenuLink>
                </div>
                <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
                    <li className="ms-nav-item ms-nav-user dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                                {auth.token !== undefined &&
                                    // <img className="ms-user-img ms-img-round mh-100 w-100 rounded-circle" src="https://res.cloudinary.com/mindmadetech/image/upload/v1650608428/person-icon_yoovsb.jpg" alt="people" />
                                    <div className='top-letter-border'>
                                        <p className="top-deopdown-letter">{(auth?.token?.result?.Name?.charAt(0))?.toUpperCase()}</p>
                                    </div>
                                }
                            </Dropdown.Toggle>
                            {auth.token !== undefined &&
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown z-100" aria-labelledby="userDropdown">
                                    <div className="dropdown-menu-header">
                                        {/* <img className="ms-img-round profile-image" src="https://res.cloudinary.com/mindmadetech/image/upload/v1661496771/CakeyLogo/istockphoto-1196083861-612x612_kbvicw.jpg" alt="people" /> */}
                                        <img className="ms-img-round profile-image" src="https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" alt="people" />
                                        <h6 className="text-center mt-3"><span className="text-disabled ">{auth.token.result.Name}</span></h6>
                                        <p className="text-center"><span className="text-disabled ">{auth.token.result.Email}</span></p>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={handleChangePassword} to="/">
                                            <span><FaKey className="sidenavigationIcon" /> <span className='pl-3'>Change Password</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={logout} to="/">
                                            <span><FaPowerOff className="sidenavigationIcon" /> <span className='pl-3'>Logout</span></span>
                                        </Link>
                                    </div>
                                </Dropdown.Menu>
                            }
                        </Dropdown>
                    </li>
                </ul>
                <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                {/* change password popup */}
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <h1 className="text-center pb-2">Change Password</h1>
                        <div>
                            <label><b>New Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText1 === true ? "text" : "password"} ref={PasswordR} />
                                <button className='eye-button' onClick={() => setShowText1(!showText1)}>
                                    {!showText1 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <label><b>Confirm Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText2 === true ? "text" : "password"} ref={ConfirmPasswordR} />
                                <button className='eye-button' onClick={() => setShowText2(!showText2)}>
                                    {!showText2 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <p className="text-danger">{message}</p>
                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary shadow-none mr-3" onClick={handleChange}>Change</button>
                                    <button type="submit" className="btn btn-secondary shadow-none" onClick={handleClose}>Cancel</button>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </nav >
            {showAlert &&
                <Alert response={response} />
            }
        </>
    );
}
