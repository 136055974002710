import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';

function Content() {

    const history = useHistory();
    const state = useLocation();

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb />
            </div>
            <div className="ms-profile-overview">
                <div className="ms-profile-cover">
                    <img className="ms-profile-img" src={state?.state?.ProfileImage === undefined ? "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" : state?.state?.ProfileImage} alt="people" />
                    <div className="ms-profile-user-info">
                        <h4 className="ms-profile-username text-white">{state?.state?.UserName === undefined ? null : state?.state?.UserName}</h4>
                    </div>
                </div>
            </div>
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-body tabledata-background">
                    <h2 className="section-title">BASIC INFORMATION</h2>
                    <table className="table ms-profile-information">
                        <tbody>
                            <tr>
                                <th scope="row">Customer Mobile Number</th>
                                <td>{state?.state?.PhoneNumber}</td>
                            </tr>
                            {state?.state?.UserName === undefined ? null :
                                <tr>
                                    <th scope="row">Customer Name</th>
                                    <td>{state?.state?.UserName}</td>
                                </tr>
                            }
                            {state?.state?.Address === undefined ? null :
                                <tr>
                                    <th scope="row">Address</th>
                                    <td><span className="long-line-break">{state?.state?.Address}</span></td>
                                </tr>
                            }
                        </tbody>
                        <button
                            className="btn btn-secondary float-start"
                            type="submit"
                            onClick={() => history.push('/customer-list')}
                        >
                            Close
                        </button>
                    </table>
                </div>
            </div>
        </div >
    );
};

export default Content;