import React from 'react';
import moment from 'moment';
import { float2D } from '../../../../../utils/Validation';

export default function PendingPayment(props) {

    const { selectedMonth, handleSelectedMonth, filteredData } = props;

    return (
        <div className="ms-panel-body tabledata-background tab-pane-content">
            <div className="table-responsive Scrollbar-Style">
                <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="row">
                        <div className="col-sm-12 col-md-6"></div>
                        <div className="col-sm-12 col-md-6 mb-2">
                            <div id="data-table-4_filter" className="dataTables_filter">
                                <label className="font-weight-bold">Select Month :
                                    <input
                                        type='month'
                                        className="form-control form-control-sm"
                                        aria-controls="data-table-4"
                                        value={selectedMonth || ''}
                                        onChange={handleSelectedMonth} max={moment().format('YYYY-MM')}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='header-break-line'> <hr /> </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {filteredData?.message === 'No Records Found' ?
                                < h6 className='text-center mt-4'>Pending Payments From Cakey - Rs. <b>0</b></h6> :
                                <h6 className='text-center mt-4'>Pending Payments From Cakey - Rs. <b>{float2D(filteredData?.ClosingBalance) ?? '0.00'}</b></h6>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};