import React from 'react';
import { Modal } from 'react-bootstrap';
import { float2D } from '../../../../utils/Validation';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import Flavour from "../../../vendor/section/Vendor-Other-Product-Order-Details/Flavour";

export default function OrderDetailsPopup(props) {

    const {
        handleCloseDetailsPopup,
        showOrderDetailsPopup,
        OrderDetailsByTypeOfOrder,
        TypeOfOrder,
        CauseOfTicket
    } = props;

    return (
        <div>
            <Modal show={showOrderDetailsPopup} onHide={handleCloseDetailsPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button
                        type="button"
                        className="close change-password-close"
                        onClick={handleCloseDetailsPopup}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="col-xl-12 col-md-12 ">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="bold text-center">Order Details</h4>
                            {OrderDetailsByTypeOfOrder.length === 0 ?
                                <div className='h6 text-center mt-4'>Loading...</div> :
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Order ID</th>
                                            <td><b>{OrderDetailsByTypeOfOrder?.Id}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                {TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'cakeorder' ?
                                                    'Cake Name' : TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'productorder' ?
                                                        'Product Name' : TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'hamperorder' ?
                                                            'Hamper Name' : 'Customised Cake Name'
                                                }
                                            </th>
                                            <td><span className="long-line-break">
                                                {TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'cakeorder' ?
                                                    OrderDetailsByTypeOfOrder?.CakeName : TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'productorder' ?
                                                        OrderDetailsByTypeOfOrder?.ProductName : TypeOfOrder?.toLowerCase()?.replace(/ /g, '') === 'hamperorder' ?
                                                            OrderDetailsByTypeOfOrder?.HampersName : OrderDetailsByTypeOfOrder?.CakeName
                                                }
                                            </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer ID</th>
                                            <td>{OrderDetailsByTypeOfOrder?.User_ID}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Name</th>
                                            <td><span className="long-line-break">{OrderDetailsByTypeOfOrder?.UserName}</span></td>
                                        </tr>
                                        {OrderDetailsByTypeOfOrder?.Vendor_ID &&
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{OrderDetailsByTypeOfOrder?.Vendor_ID}</td>
                                            </tr>
                                        }
                                        {OrderDetailsByTypeOfOrder?.VendorName &&
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td><span className="long-line-break">{OrderDetailsByTypeOfOrder?.VendorName}</span></td>
                                            </tr>
                                        }
                                        <tr>
                                            <th scope="row">Order Date</th>
                                            <td>{OrderDetailsByTypeOfOrder?.Created_On}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Delivery Date</th>
                                            <td>{OrderDetailsByTypeOfOrder?.DeliveryDate}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Order Status</th>
                                            <td>{OrderDetailsByTypeOfOrder?.Status}</td>
                                        </tr>
                                        {(CauseOfTicket === 'Change Requests' || CauseOfTicket === 'Order Cancellation by Vendor' || CauseOfTicket === 'Customized Cake through Vendor') &&
                                            <>
                                                <tr>
                                                    <th scope="row">Flavour</th>
                                                    <td><span className="long-line-break">{TypeOfOrder === 'Product Order' ?
                                                        <Flavour FlavoursList={OrderDetailsByTypeOfOrder?.Flavour} /> :
                                                        <FlavourList FlavoursList={OrderDetailsByTypeOfOrder?.Flavour} />}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td><span className="long-line-break">{TypeOfOrder === 'Product Order' || TypeOfOrder === 'Customized Cake Order' ? OrderDetailsByTypeOfOrder?.Shape : `${OrderDetailsByTypeOfOrder?.Shape?.Name} - Additional Rs.${OrderDetailsByTypeOfOrder?.Shape?.Price}/Kg`}</span></td>
                                                </tr>
                                            </>
                                        }
                                        {OrderDetailsByTypeOfOrder?.Total &&
                                            <tr>
                                                <th scope="row">Total Cost</th>
                                                <td>Rs. {float2D(OrderDetailsByTypeOfOrder?.Total)}</td>
                                            </tr>
                                        }
                                        {OrderDetailsByTypeOfOrder?.Final_Total &&
                                            <tr>
                                                <th scope="row">Final Price</th>
                                                <td>Rs. {float2D(OrderDetailsByTypeOfOrder?.Final_Total)}</td>
                                            </tr>
                                        }
                                        {OrderDetailsByTypeOfOrder?.PaymentType &&
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{OrderDetailsByTypeOfOrder?.PaymentType}</td>
                                            </tr>
                                        }
                                        {OrderDetailsByTypeOfOrder?.PaymentStatus &&
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{OrderDetailsByTypeOfOrder?.PaymentStatus}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
};