import React from 'react';
import { Helmet } from 'react-helmet';
import Topnavigation from '../Layouts/Topnavigation';
import Sidenavigation from '../Layouts/Sidenavigation';
import Content from '../../CommonFolders/Inactive-Vendors-List/Content';

export default function ManagerInactiveVendorsList(){
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Inactive Vendors</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content Page='Manager' />
                {/* page content end  */}

            </main>

        </div>
    )
}