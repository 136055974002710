import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';


function Breadcrumb({ page }) {
    return (
        <nav aria-label="breadcrumb">
            {page === 'orderlist' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/Vendor-OrderList">Cakes &amp; Product Orders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Product Order Details</li>
                </ol>
                :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    <li className="breadcrumb-item"><Link to="/orders-report">Orders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Product Order Details</li>
                </ol>
            }
        </nav>
    );
}

export default Breadcrumb;