import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb () {
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>              
                    <li className="breadcrumb-item active" aria-current="page">Profile</li>
                </ol>
            </nav>
        );
}

export default Breadcrumb;