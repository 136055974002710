import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../redux/CakeArray/actions";
import { LettersValidation } from "../../../../../utils/Validation";

export default function FlavourList({ option, className, handleSelectButton, disabledButton, handleRemoveButton, show, cakeFlavourList
    //  handleInputValue, flavoursPrice,  
}) {

    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const [newFlavour, setNewFlavour] = useState('');
    const [message, setMessage] = useState('');


    //get status for add new flavour
    const { AddNewFlavourStatus } = useSelector(state => state.CakeArrayReducer);

    useEffect(() => {
        if (option === undefined) {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new flavour functionality
    const handleAddNew = () => {
        setMessage('');
        if (newFlavour) {
            const newList = list.filter(val => {
                if (val.Name === newFlavour) {
                    return val;
                } else {
                    return null;
                }
            });
            if (newList.length === 0) {
                dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
            }
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        }
    };

    //validate add new flavour status
    useEffect(() => {
        if (AddNewFlavourStatus !== undefined) {
            if (AddNewFlavourStatus.statusCode === 200) {
                dispatch({ type: actions.GET_FLAVOUR_LIST });
                setNewFlavour('');
            }
        };
    }, [AddNewFlavourStatus, dispatch]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Flavours <span className='text-danger'>*</span></label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input type="text" className="form-control" onInput={LettersValidation} value={newFlavour} onChange={(e) => setNewFlavour(e.target.value)} />
                    <button className='add-button rounded' onClick={handleAddNew}>add</button>
                </div>
            </div>
            <div className="scrollable-list">
                {show ?
                    <>
                        {list.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>

                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            value={value.Name}
                                            onClick={() => handleSelectButton(i, value.Name)}
                                        > + </button>
                                    </> :
                                    <>
                                        <button className='mr-1 minus-button' value={value.Name} onClick={() => handleRemoveButton(i)}> - </button>
                                        <div className={className}> {value.Name}</div>
                                        {/* {flavoursPrice.map(val =>
                                            <div key={val.index}>
                                                {val.index === i &&
                                                    <input className="price-input" defaultValue={val.Price} onChange={(e) => handleInputValue(e, value.Name)} />
                                                }
                                            </div>
                                        )} */}
                                    </>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {cakeFlavourList?.map((val, i) =>
                <div className="list-array" key={i}>
                    {val.Name}
                </div>
            )}
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};