import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../../redux/Vendor/actions';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactPagination from '../../../../common/React-Pagination';

const validationSchema = yup.object().shape({
    StartDate: yup.date().required('required'),
    EndDate: yup.date().required('required'),
});

export default function Content() {

    const dispatch = useDispatch();

    const [message, setMessage] = useState('');
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    //get logged in users details
    const { token } = useSelector((state) => state.authReducer);

    const { LoginSession } = useSelector(state => state.VendorReducer);

    const onSubmit = (data) => {
        if (token !== undefined) {
            if (new Date(data.StartDate) > new Date(data.EndDate)) {
                setMessage("End Date should be greater than Start Date");
                setShow(false);
            } else {
                setMessage('');
                setShow(true);
                dispatch({
                    type: actions.GET_LOGIN_SESSION,
                    payload: {
                        id: token?.result?._id,
                        StartDate: moment(moment(data.StartDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'),
                        EndDate: moment(moment(data.EndDate, 'YYYY-MM-DD')).format('DD-MM-YYYY')
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (LoginSession?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(LoginSession);
        }
    }, [LoginSession]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-md-12">
                    <Breadcrumb />
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h6 className='text-uppercase font-weight-bold'>Login Time</h6>
                        </div>
                        <div className='header-break-line'> <hr /> </div>
                        <div className="ms-panel-body tabledata-background">
                            <div className="table-responsive Scrollbar-Style">
                                <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                    <div className="row ">
                                        <div className="col-sm-12 col-md-6"></div>
                                        <div className="col-sm-12 col-md-6 mb-2">
                                            <div id="data-table-4_filter" className="dataTables_filter">
                                                <label className="font-weight-bold">Start Date : </label>
                                                <input
                                                    type="date"
                                                    maxLength={4}
                                                    max={new Date()?.toISOString()?.slice(0, 10)}
                                                    className={errors?.StartDate ? "form-control form-control-sm required-field" : "form-control form-control-sm"}
                                                    name='StartDate'
                                                    {...register('StartDate')}
                                                />
                                                <label className="font-weight-bold ml-2">End Date : </label>
                                                <input
                                                    type="date"
                                                    maxLength={4}
                                                    max={new Date()?.toISOString()?.slice(0, 10)}
                                                    className={errors?.EndDate ? "form-control form-control-sm required-field" : "form-control form-control-sm"}
                                                    name='EndDate'
                                                    {...register('EndDate')} />
                                                <button
                                                    className='btn-success filter-button ml-2'
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    Filter
                                                </button>
                                                <p className='text-danger'>{message}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                <thead>
                                                    <tr role="row">
                                                        <th scope="col">S. No</th>
                                                        <th scope="col">Clock In Date</th>
                                                        <th scope="col">Clock In Time</th>
                                                        <th scope="col">Clock Out Date</th>
                                                        <th scope="col">Clock Out Time</th>
                                                    </tr>
                                                </thead>
                                                {show ?
                                                    <>
                                                        {filteredData.length === 0 ?
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody> :
                                                            <tbody>
                                                                {currentdata?.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{i + 1}</th>
                                                                        <td>{val.Login_At?.slice(0, 10)}</td>
                                                                        <td>{val.Login_At?.slice(11, 20)}</td>
                                                                        <td>{val.Logout_At?.slice(0, 10)}</td>
                                                                        <td>{val.Logout_At?.slice(11, 20)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        }
                                                    </> :
                                                    <tbody>
                                                        <tr className="odd">
                                                            <td colSpan={5} className='font-weight-bold'>Select Date Range</td>
                                                        </tr>
                                                    </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {filteredData.length > postsPerPage &&
                                <ReactPagination
                                    paginate={paginate}
                                    pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                    currentPage={currentPage}
                                    currentdata={currentdata}
                                    totalPosts={filteredData.length}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};