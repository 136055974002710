import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../redux/CakeArray/actions";

export default function WeightList({ option, className, handleSelectButton, handleRemoveButton, disabledButton, show, cakeWeightList, getValues, minWeight }) {

    const [list, setList] = useState([]);
    const [newWeight, setNewWeight] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add new weight status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option?.message === 'No Records Found') {
            setList([]);
        } else {
            setList(option);
        };
        // eslint-disable-next-line
    }, [option]);

    //add new weight functionality
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newWeight) {
            if (parseFloat(newWeight) > 0) {
                if (list?.length === 0) {
                    dispatch({ type: actions.ADD_NEW_WEIGHT, payload: newWeight });
                    setNewWeight("")
                } else {
                    const newList = list?.filter(val => {
                        if (val.Weight === newWeight) {
                            return val;
                        } else {
                            return null;
                        }
                    });
                    if (newList?.length === 0) {
                        dispatch({ type: actions.ADD_NEW_WEIGHT, payload: newWeight });
                        setNewWeight("")
                    }
                }
            } else {
                setMessage("Enter Valid Weight");
                setTimeout(() => setMessage(''), 5000);
            }
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        };
    };

    //validate add new weight status
    useEffect(() => {
        if (Status.AddNewWeightStatus !== undefined) {
            if (Status.AddNewWeightStatus.statusCode === 200) {
                dispatch({ type: actions.GET_WEIGHT_LIST });
                setNewWeight('');
            }
        };
    }, [Status, dispatch]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Cake Weight<span className='text-info'> (Weight should be greater than or equal to Minimum Weight)</span></label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={2} onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                        } else if (e.target.value < e.target.min) {
                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                        }
                    }} placeholder="eg.4" className="form-control" value={newWeight} onChange={(e) => setNewWeight(e.target.value)} />
                    <button disabled>Kg</button>
                    <button className='add-button rounded' onClick={handleAddNew}>add</button>
                </div>
            </div>
            <div className="scrollable-list">
                {(show || list?.length > 0) ?
                    <>
                        {list?.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>
                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Weight}</div>
                                        <button
                                            className='add-button'
                                            disabled={!minWeight ? true : (parseFloat(value.Weight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) > parseFloat(minWeight?.value?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0])) ? false : true}
                                            value={value.Weight}
                                            // name='MinWeightList' {...register('MinWeightList')}
                                            onClick={() => handleSelectButton(value, i)}
                                        > + </button>
                                    </> :
                                    <>
                                        <button className='mr-1 minus-button' value={value.Weight} onClick={() => handleRemoveButton(i)}> - </button>
                                        <div className={className}> {value.Weight}</div>
                                    </>}
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {cakeWeightList?.map((val, i) =>
                <div className="list-array" key={i}>
                    {val}
                </div>
            )}
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};