import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function MainContent() {

    const history = useHistory();

    const [show, setShow] = useState(true);
    const [selectedCakeTypeForAdd, setSelectedCakeTypeForAdd] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [productType, setProductType] = useState('');
    const [showTypeField, setShowTypeField] = useState(false);

    const ProductTypes = [
        { value: 'Brownie', label: 'Brownie' },
        { value: 'Cake Dessert', label: 'Cake Dessert' },
        { value: 'Cheese Cake', label: 'Cheese Cake' },
        { value: 'Chocolates', label: 'Chocolates' },
        { value: 'Cookies', label: 'Cookies' },
        { value: 'Cup Cake', label: 'Cup Cake' },
        { value: 'macaroons', label: 'Macarons' },
        { value: 'Tea Cake', label: 'Tea Cake' },
    ];

    const handleSelectCakeType = (e) => {
        setSelectedCakeTypeForAdd(e.target.value)
        if (e.target.value === "Others") {
            setShowTypeField(true);
        } else {
            setShowTypeField(false);
        }
    };

    const handleStartAdd = (e) => {
        e.preventDefault();
        if (selectedCakeTypeForAdd) {
            setShow(false);
            if (selectedCakeTypeForAdd === 'Normal Cake') {
                history.push('/add-cake');
                setShowTypeField(false);
            } else {
                if (productType) {
                    history.push('/add-others', productType?.value);
                } else {
                    setShow(true);
                    setPopupMessage("Select Any Other Product Type");
                }
            };
        } else {
            setShow(true);
            setPopupMessage("Select Any Product Type to add");
        };
    };

    const handleClosePopup = (e) => {
        e.preventDefault();
        setShow(false);
        history.push('/vendor-cakes-list');
    };

    return (
        <div>
            <Modal className="modal-min" show={show} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="text-center tabledata-background">
                    <button type="button" className="close" onClick={handleClosePopup}><span aria-hidden="true">×</span></button>
                    <h1>Select Type</h1>
                    <form>
                        <div className="col-md-12 mt-4 mb-3 px-md-2">
                            <div className="input-group">
                                <select className="form-control" defaultValue='' onChange={handleSelectCakeType}>
                                    <option value='' disabled='disabled'>-- Select --</option>
                                    <option value="Normal Cake">Add Cake</option>
                                    <option value="Others">Add Others Product</option>
                                </select>
                            </div>
                        </div>
                        {showTypeField &&
                            <div className="col-md-12 mt-4 mb-4 px-md-2 text-left">
                                <label htmlFor="validationCustom18" className='font-weight-bold'>Other Product Type</label>
                                {/* <div className="input-group"> */}
                                <Select
                                    styles={customStyles}
                                    options={ProductTypes}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    onChange={(e) => setProductType(e)}
                                    value={productType}
                                />
                                {/* <select className="form-control text-capitalize" defaultValue='' onChange={(e) => { setProductType(e.target.value) }}>
                                        <option value='' disabled='disabled'>-- Select --</option>
                                        <option value='Brownie'>Brownie</option>
                                        <option value='Cake Dessert'>Cake Dessert</option>
                                        <option value='Cheese Cake'>Cheese Cake</option>
                                        <option value='Chocolates'>Chocolates</option>
                                        <option value='Cookies'>Cookies</option>
                                        <option value='Cup Cake'>Cup Cake</option>
                                        <option value='macaroons'>Macarons</option>
                                        <option value='Tea Cake'>Tea Cake</option>
                                    </select> */}
                                {/* </div> */}
                            </div>
                        }
                        <p className='text-danger text-left ml-3'>{popupMessage}</p>
                        <button type="submit" onClick={handleStartAdd} className="btn btn-primary shadow-none mr-3">Add</button>
                        <button type="submit" onClick={handleClosePopup} className="btn btn-secondary shadow-none mb-2">Cancel</button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
};