import React from 'react';

export default function Flavour({ List, show, handleDeletePopup }) {
    return (
        <div className="col-xl-6 col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-body tabledata-background">
                    <h6 className="pb-2">FLAVOUR LIST
                        <span className="pb-2 float-right">
                            <button className="btn-sm btn-success p-2 add-button rounded" type="submit" onClick={show}>Add </button>
                        </span>
                    </h6>
                    <div className='cake-design-table'>
                        <table className="table">
                            <tbody>
                                {List.length > 0 ?
                                    <>
                                        {List.map((a, i) =>
                                            <tr className="category-row" key={i}>
                                                <th>{a.Name}</th>
                                                <button className="btn-sm btn-primary category-row-btn remove-button remove-2 cake-remove" onClick={(e) => handleDeletePopup(e, a)}>x</button>
                                            </tr>
                                        )}
                                    </> :
                                    <tr >
                                        <th className="text-center">No Records Found</th>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}