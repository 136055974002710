import React, { useState, useEffect } from 'react';

export default function ShapesWithWeightList({ ShapesList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (ShapesList === undefined) {
            setList([]);
        } else {
            setList(ShapesList);
        };
    }, [ShapesList]);

    return (
        <div>
            {
                list?.Info?.map((val, i) =>
                    <div key={i}>
                        <span>{(i ? ', ' : '') + `${val.Name} - Additional Rs.${val.Price}/Kg - Min ${val.MinWeight}`}</span><br />
                    </div>
                )
            }
        </div>
    )
};