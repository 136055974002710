import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom/esm/react-router-dom";
import CustomizedCakeActions from '../../../../redux/Customize-Cake/actions';
import Preloader from "../../../layouts/Preloader";
import Breadcrumb from "./Breadcrumb";
import TableSearch from "../../../common/TableSearch";
import ReactPagination from "../../../common/React-Pagination";
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from "../../../common/SortingOrders";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
    ]);

    const { CustomizedCakeOrderThroughVendor } = useSelector(state => state.CustomizeCakeReducer);


    useEffect(() => {
        dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR });
        //eslint-disable-next-line
    }, []);

    //for get the search input value
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //validation for customized cakes list
    useEffect(() => {
        if (CustomizedCakeOrderThroughVendor?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(CustomizedCakeOrderThroughVendor.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CakeType && val.CakeType.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.Weight && val.Weight.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [CustomizedCakeOrderThroughVendor, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR });
        setsearch('');
        setSorting([
            { table: 0, field: "OD", type: "D" }
        ]);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //for view details page
    const ViewDetails = (cake) => {
        history.push('/helpdeskV-CustomizedCake-Order-through-Vendor-Details', { Id: cake._id })
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = filteredData;
        let result = SortingOrders({ type, field, filterData });
        setFilteredData(result);
    };

    //loading
    useEffect(() => {
        (CustomizedCakeOrderThroughVendor.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CustomizedCakeOrderThroughVendor]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh ={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Customised Cake Orders through Vendor</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                    value={search || ''}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Cake Weight</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr className={(cake.Ticket_Raised === "n" && cake.Status === 'Sent') ? "odd cursor font-weight-bold" : "odd cursor"} key={cake._id} onClick={() => ViewDetails(cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Weight}</td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td className="price-align3-row"><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}