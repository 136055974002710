
import actions from "./actions";

const initialState = {
  AllHamperOrders: [],
  HamperDetails: [],
  VendorHamperOrders: [],
  AcceptHamperStatus: [],
  CancelHamperStatus: [],
  HamperUpdateStatus: [],
};

const HamperOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ADMIN_HAMPER_ORDERS_LIST:
      return {
        ...state,
        AllHamperOrders: action.payload.AllHamperOrders
      }
    case actions.SET_HAMPER_DETAILS:
      return {
        ...state,
        HamperDetails: action.payload.HamperDetails
      }
    case actions.SET_VENDOR_HAMPER_ORDERS_LIST:
      return {
        ...state,
        VendorHamperOrders: action.payload.VendorHamperOrders
      }
    case actions.ACCEPT_HAMPER_ORDER_STATUS:
      return {
        ...state,
        AcceptHamperStatus: action.payload.AcceptHamperStatus
      }
    case actions.CANCEL_HAMPER_ORDER_STATUS:
      return {
        ...state,
        CancelHamperStatus: action.payload.CancelHamperStatus
      }
    case actions.UPDATE_HAMPER_ORDER_STATUS_RESPONSE:
      return {
        ...state,
        HamperUpdateStatus: action.payload.HamperUpdateStatus
      }
    default:
      return state;
  }
};

export default HamperOrderReducer;