import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from './actions'
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const CakeArraySaga = function* () {

    yield all([
        yield takeEvery(actions.GET_FLAVOUR_LIST, GetFlavourList),
        yield takeEvery(actions.GET_SHAPE_LIST, GetShapeList),
        yield takeEvery(actions.GET_WEIGHT_LIST, GetWeightList),
        yield takeEvery(actions.GET_CAKETOPPINGS_LIST, GetCakeToppingsList),
        yield takeEvery(actions.GET_ARTICLE_LIST, GetArticleList),
        yield takeEvery(actions.ADD_NEW_FLAVOUR, AddNewFlavour),
        yield takeEvery(actions.ADD_NEW_SHAPE, AddNewShape),
        yield takeEvery(actions.ADD_NEW_ARTICLE, AddNewArticle),
        yield takeEvery(actions.ADD_NEW_WEIGHT, AddNewWeight),
        yield takeEvery(actions.DELETE_FLAVOUR, DeleteFlavour),
        yield takeEvery(actions.DELETE_SHAPE, DeleteShape),
        yield takeEvery(actions.DELETE_WEIGHT, DeleteWeight),
        yield takeEvery(actions.DELETE_ARTICLE, DeleteArticle),
        yield takeEvery(actions.GET_CAKE_TYPE, GetCakeType),
        yield takeEvery(actions.ADD_CAKE_TYPE, AddCakeType),
        yield takeEvery(actions.UPDATE_CAKE_TYPE_IMAGE, UpdateCakeTypeImage),
        yield takeEvery(actions.DELETE_CAKE_TYPE, DeleteCakeType),
        yield takeEvery(actions.GET_ALL_FLAVOURS_LIST, GetAllFlavoursList),
        yield takeEvery(actions.GET_ALL_SHAPES_LIST, GetAllShapesList),
    ]);
};

//get all flavours list
const GetFlavourList = function* () {

    try {

        const result = yield call(() =>
            axios.get(
                `${API_URL}/flavour/list`
            )
        );
        yield put({
            type: actions.SET_FLAVOUR_LIST,
            payload: { FlavourList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllFlavoursList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/flavour/alllist`
            )
        );
        yield put({
            type: actions.SET_ALL_FLAVOURS_LIST,
            payload: { Flavours: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all shapes list
const GetShapeList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/shape/list`
            )
        );
        yield put({
            type: actions.SET_SHAPE_LIST,
            payload: { ShapeList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllShapesList = function* () {

    try {

        const result = yield call(() =>
            axios.get(
                `${API_URL}/shape/alllist`
            )
        );
        yield put({
            type: actions.SET_ALL_SHAPES_LIST,
            payload: { Shapes: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all weight list
const GetWeightList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/weight/alllist`
            )
        );
        yield put({
            type: actions.SET_WEIGHT_LIST,
            payload: { WeightList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all cake toppings list
const GetCakeToppingsList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/toppings/list`
            )
        );
        yield put({
            type: actions.SET_CAKETOPPINGS_LIST,
            payload: { CakeToppingsList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all articles list
const GetArticleList = function* () {

    try {

        const result = yield call(() =>
            axios.get(
                `${API_URL}/article/list`
            )
        );
        yield put({
            type: actions.SET_ARTICLE_LIST,
            payload: { ArticleList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//add new flavour
const AddNewFlavour = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/flavour/new`, { Flavour: payload }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_ALL_FLAVOURS_LIST });
        yield put({ type: actions.GET_FLAVOUR_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    };
};

//add new shape
const AddNewShape = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.post(
                `${API_URL}/shape/new`, { Shape: payload }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_ALL_SHAPES_LIST });
        yield put({ type: actions.GET_SHAPE_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    };
};


//add new article
const AddNewArticle = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/article/new`, { Article: payload }
            )
        );
        yield put({
            type: actions.ADD_NEW_ARTICLE_STATUS,
            payload: { AddNewArticleStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//add new weight
const AddNewWeight = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/weight/new`, { Weight: payload }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_WEIGHT_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//delete flavour
const DeleteFlavour = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/flavour/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_FLAVOUR_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    }
};

//delete shape
const DeleteShape = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.delete(
                `${API_URL}/shape/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_SHAPE_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//delete weight
const DeleteWeight = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.delete(
                `${API_URL}/weight/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_WEIGHT_LIST });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//delete article
const DeleteArticle = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/article/delete/${payload}`
            )
        );
        yield put({
            type: actions.DELETE_ARTICLE_STATUS,
            payload: { DeleteArticleStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCakeType = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/caketype/list`
            )
        );
        yield put({
            type: actions.SET_CAKE_TYPE,
            payload: { CakeType: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AddCakeType = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/caketype/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_CAKE_TYPE });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateCakeTypeImage = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/caketype/update`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_CAKE_TYPE });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DeleteCakeType = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/caketype/delete`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_CAKE_TYPE });
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_ARRAY_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default CakeArraySaga;