const actions = {
    GET_ADMIN_DETAILS : 'GET_ADMIN_DETAILS',
    SET_ADMIN_DETAILS : 'SET_ADMIN_DETAILS',
    GET_ADMINORDER_STATUS_COUNT : 'GET_ADMINORDER_STATUS_COUNT',
    SET_ADMINORDER_STATUS_COUNT : 'SET_ADMINORDER_STATUS_COUNT',
    GET_TOTAL_USERS : 'GET_TOTAL_USERS',
    SET_TOTAL_USERS : 'SET_TOTAL_USERS',
    GET_ADMIN_RECENTORDER : 'GET_ADMIN_RECENTORDER',
    SET_ADMIN_RECENTORDER : 'SET_ADMIN_RECENTORDER',
    GET_ADMIN_RECENTORDER_N : 'GET_ADMIN_RECENTORDER_N',
    SET_ADMIN_RECENTORDER_N : 'SET_ADMIN_RECENTORDER_N',
    GET_ADMIN_RECENTORDER_Y : 'GET_ADMIN_RECENTORDER_Y',
    SET_ADMIN_RECENTORDER_Y : 'SET_ADMIN_RECENTORDER_Y',
    SET_PROFILE_UPDATE_STATUS : 'SET_PROFILE_UPDATE_STATUS',
    GET_ADMIN_DETAILS_BY_EMAIL : 'GET_ADMIN_DETAILS_BY_EMAIL',
    SET_ADMIN_DETAILS_BY_EMAIL : 'SET_ADMIN_DETAILS_BY_EMAIL',
    GET_NOTIFICATION_COUNT : 'GET_NOTIFICATION_COUNT',
    SET_NOTIFICATION_COUNT : 'SET_NOTIFICATION_COUNT',
    GET_CAKE_BANNERS: 'GET_CAKE_BANNERS',
    SET_CAKE_BANNERS: 'SET_CAKE_BANNERS',
    ADD_BANNER: 'ADD_BANNER',
    ADD_BANNER_STATUS: 'ADD_BANNER_STATUS',
    UPDATE_BANNER: 'UPDATE_BANNER',
    UPDATE_BANNER_STATUS: 'UPDATE_BANNER_STATUS',
    DELETE_BANNER: 'DELETE_BANNER', 
    DELETE_BANNER_STATUS: 'DELETE_BANNER_STATUS',
    GET_ADMIN_NOTIFICATION_LIST: 'GET_ADMIN_NOTIFICATION_LIST', 
    SET_ADMIN_NOTIFICATION_LIST: 'SET_ADMIN_NOTIFICATION_LIST',
    REMOVE_ADMIN_NOTIFICATION_BY_ID: 'REMOVE_ADMIN_NOTIFICATION_BY_ID', 
    REMOVE_ADMIN_NOTIFICATION: 'REMOVE_ADMIN_NOTIFICATION', 
    ADMIN_UPDATE_CAKE:"ADMIN_UPDATE_CAKE",
    SAVE_ADMIN_REGISTER_INITIAL_USER:"SAVE_ADMIN_REGISTER_INITIAL_USER",
    GET_INTERNAL_USERS_LIST: "GET_INTERNAL_USERS_LIST",
    SET_INTERNAL_USERS_LIST: "SET_INTERNAL_USERS_LIST",
    GET_INTERNAL_USER_DETAILS: 'GET_INTERNAL_USER_DETAILS',
    SET_INTERNAL_USER_DETAILS: 'SET_INTERNAL_USER_DETAILS',
    UPDATE_INTERNAL_USER_DETAILS: "UPDATE_INTERNAL_USER_DETAILS",
    ADMIN_RESPONSE: 'ADMIN_RESPONSE',
    GET_CUSTOMER_RATINGS_LIST: 'GET_CUSTOMER_RATINGS_LIST',
    SET_CUSTOMER_RATINGS_LIST: 'SET_CUSTOMER_RATINGS_LIST',
};
export default actions;