import React from "react";

export default function HelpdeskCFullyCustomizedCake(props) {

    const { TicketDetails, auth, handleIntimatePopup, state, handleAcceptTicket,
        handleOrderAssignToVendorPopup, handleHeldpeskVEditPricePopup, handleManagerEditPricePopup,
        handleUpdateCustomerApproval, handleShowCloseTicketPopup } = props;

    return (
        <>
            {(!TicketDetails?.HelpdeskC_Accepted_By && state?.state?.Page === "HelpdeskC") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(!TicketDetails?.HelpdeskV_Accepted_By && state?.state?.Page === "HelpdeskV") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(!TicketDetails?.Fully_Customized_Cake_Assigned && TicketDetails?.HelpdeskV_Accepted_By &&
                TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV')) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleOrderAssignToVendorPopup}
                >
                    Assign to Vendor
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                state?.state?.Page === "HelpdeskV" && TicketDetails?.Order_Status === 'Sent' &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && !TicketDetails?.Price_Sent_To_Manager) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHeldpeskVEditPricePopup}
                >
                    Edit Price
                </button>
            }
            {((TicketDetails?.Order_Status === 'Sent' && TicketDetails?.Last_Intimate.includes('Manager') &&
                state?.state?.Page === "Manager") &&
                TicketDetails?.Price_Sent_To_Manager === 'Yes') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleManagerEditPricePopup}
                >
                    Price Confirmation
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Final_Price_Given_By_Manager === "Yes" && !TicketDetails?.HelpdeskC_Notes) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleUpdateCustomerApproval}
                >
                    Update Approval Status
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                (TicketDetails?.Customer_Approved_Status || TicketDetails?.Ticket_Approved_Status === 'Not Approved') && 
                TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {/* {
                ((TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && 
                    TicketDetails?.Last_Intimate.includes('HelpdeskV')) && (TicketDetails?.VendorID && TicketDetails?.Order_Status === 'Sent')) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            } */}
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                !TicketDetails?.Final_Price_Given_By_Manager) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
        </>
    )
};