import React, { useEffect, useState } from 'react';
import Preloader from '../../../layouts/Preloader';
import { API_URL } from '../../../../utils/constants';
import axios from 'axios';
import moment from 'moment';

export default function Content() {

    let [fetchSuccess, setFetchSuccess] = useState(false);
    let [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        axios.get(`${API_URL}/helpdesk/currentOrders/list/${moment().format('DD-MM-YYYY')}/${'today'}`)
            // axios.get(`${API_URL}/helpdesk/currentOrders/list/${'07-10-2022'}/${'today'}`)
            .then((res) => setFilteredData(res.data));
        //eslint-disable-next-line
    }, [filteredData]);

    useEffect(() => {
        (filteredData.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [filteredData.length]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="db-header-title">Screen-O</h1>
                                </div>
                            </div>
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Today's Orders - ({moment().format('DD-MM-YYYY')})</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Order Type</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Order Date</th>
                                                                <th scope="col">Delivery Date</th>
                                                                <th scope="col">Delivery Session</th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {filteredData.map((cake) =>
                                                                    <tr className="odd" key={cake._id}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CakeID ? 'Cake Order' : cake.HamperID ? 'Hamper Order' : 'Product Order'}</td>
                                                                        <td>{cake.VendorName} - ({cake.Vendor_ID})</td>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td>{cake.DeliveryDate}</td>
                                                                        <td>{cake.DeliverySession}</td>
                                                                        <td><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={8} className='font-weight-bold h6'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};