import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/CakeArray/actions";
import { LettersValidation } from "../../../../utils/Validation";

export default function Flavours({ option, className, handleSelectButton, disabledButton, handleRemoveButton, show, cakeFlavourList, flavourValidate }) {

    const [list, setList] = useState([]);
    const [newFlavour, setNewFlavour] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add flavour status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option?.message === 'No Records Found') {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new flavour functionality
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newFlavour) {
            if (list?.length === 0) {
                dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
            } else {
                const newList = list?.filter(val => {
                    if (val.Name === newFlavour) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
                }
            }
            setNewFlavour('');
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        };
    };

    //validate add new flavour status
    useEffect(() => {
        if (Status.AddNewFlavourStatus !== undefined) {
            if (Status.AddNewFlavourStatus.statusCode === 200) {
                dispatch({ type: actions.GET_FLAVOUR_LIST });
                setNewFlavour('');
            }
        };
        //eslint-disable-next-line
    }, [Status]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Flavours</label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input
                        type="text"
                        className="form-control text-capitalize"
                        value={newFlavour}
                        onInput={LettersValidation}
                        onChange={(e) => setNewFlavour(e.target.value)}
                    />
                    <button
                        className='add-button rounded'
                        disabled={newFlavour ? false : true}
                        onClick={handleAddNew}
                    >
                        add
                    </button>
                </div>
            </div>
            <div className="scrollable-list">
                {(show || list.length > 0) ?
                    <>
                        {list?.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>

                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            onClick={() => handleSelectButton(value.Name, i)}
                                        >
                                            +
                                        </button>
                                    </> :
                                    <>
                                        <button
                                            className='mr-1 minus-button'
                                            onClick={() => handleRemoveButton(i)}
                                        >
                                            -
                                        </button>
                                        <div className={className}> {value.Name}</div>
                                    </>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {cakeFlavourList?.map((val, i) =>
                <div className="list-array" key={i}>
                    {val.Name}
                </div>
            )}
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};