import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from './Breadcrumb';
import actions from '../../../redux/Admin/actions';
import commonActions from '../../../redux/Common/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import FileSizeValidation from '../../common/FileSizeValidation';

function Content(props) {

    const { closeEdit, Details } = props;

    const dispatch = useDispatch();

    var [imageUrl, setImageUrl] = useState();
    // var [image, setImage] = useState(Details?.ProfileImage === undefined ? 'https://res.cloudinary.com/mindmadetech/image/upload/v1661496771/CakeyLogo/istockphoto-1196083861-612x612_kbvicw.jpg' : Details.ProfileImage);
    var [image, setImage] = useState(Details?.ProfileImage === undefined ? 'https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg' : Details.ProfileImage);

    const [errMessage, setErrMessage] = useState('');
    const [loader, setLoader] = useState(false);

    const Email = useRef();
    const Password = useRef();
    const AdminName = useRef();
    const file = useRef();

    // for get the edit status 
    const { ProfileUpdateStatus } = useSelector(state => state.adminReducer);

    //for get the selected image
    function handleImageSelect(e) {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: commonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setImage(image = result?.FinalImages[0]);
                if (image) {
                    setImageUrl(URL.createObjectURL(image));
                };
            }
        }
    };

    //edit profile functionality
    const EditProfile = () => {
        setLoader(true);
        if (Email.current.value && Password.current.value && AdminName.current.value) {
            if (file.current.value) {
                const data = new FormData();
                data.append("Email", Email.current.value);
                data.append("Password", Password.current.value);
                data.append("AdminName", AdminName.current.value);
                data.append("file", image);
                dispatch({
                    type: actions.GET_ADMIN_DETAILS,
                    payload: { adminId: Details?._id, updateAdmin: data }
                });
            } else {
                const data = new FormData();
                data.append("Email", Email.current.value);
                data.append("Password", Password.current.value);
                data.append("AdminName", AdminName.current.value);
                dispatch({
                    type: actions.GET_ADMIN_DETAILS,
                    payload: { adminId: Details?._id, updateAdmin: data }
                });
            }
        } else {
            setErrMessage('*required');
            setLoader(false);
        };
    };

    //check the status and redirect
    useEffect(() => {
        if (ProfileUpdateStatus?.statusCode === 400 || ProfileUpdateStatus?.statusCode === 200) {
            setLoader(false);
            closeEdit();
        };
        //eslint-disable-next-line
    }, [ProfileUpdateStatus]);

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb closeEdit={closeEdit} />
            </div>
            <div className="ms-profile-overview">
                <div className="ms-profile-cover">
                    <div className='profile-img-div'>
                        <img className="ms-profile-img" src={imageUrl ? imageUrl : image} alt="people" />
                        <div className='profile-upload-i'>
                            <input
                                type='file'
                                accept='image/jpg,image/jpeg,image/png'
                                className='profile-img-upload'
                                onChange={handleImageSelect}
                                name='file'
                                ref={file}
                            />
                            <i className="fa fa-upload"></i>
                        </div>
                    </div>
                    <div className="ms-profile-user-info">
                        <h4 className="ms-profile-username text-white">{Details?.AdminName}</h4>
                        <h2 className="ms-profile-role">{Details?.Email}</h2>
                    </div>
                </div>
            </div>
            <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
            <div className="ms-panel ms-panel-fh ">
                <div className="ms-panel-header"><h6>Edit Profile</h6></div>
                <div className="ms-panel-body tabledata-background">
                    <form className="needs-validation clearfix" noValidate="">
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom18">Admin Name <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" ref={AdminName} defaultValue={Details?.AdminName} />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom18">Email <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" ref={Email} placeholder="Email" defaultValue={Details?.Email} />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom18">Password <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input type="text" className="form-control" ref={Password} defaultValue={Details?.Password} />
                                </div>
                            </div>
                            <p className='text-danger'>{errMessage}</p>
                        </div>
                        {loader ? <div className='float-right'><CircleDotSpinner /></div> :
                            <>
                                <button
                                    className="btn btn-secondary float-right mr-4"
                                    type="submit"
                                    onClick={closeEdit}
                                >
                                    Close
                                </button>
                                <button
                                    className="btn btn-primary float-right mr-4"
                                    type="button"
                                    onClick={EditProfile}
                                >
                                    Save
                                </button>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div >
    );
};

export default Content;