import React from 'react';
import { IoMdRefresh } from 'react-icons/io';

export default function PageRefresh(props) {

    return (
        // <div className={props?.className}>
        // <div className='d-flex justify-content-end'>
        <div>
            <button className='refresh-styles' onClick={props?.handlePageRefresh}><IoMdRefresh className="h5 mt-1" /></button>
        </div>
        // </div>
        // </div>
    )
};