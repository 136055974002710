import React from 'react';

export default function CakeyDirectGeneralOrder(props) {

    const { TicketDetails, auth, handleHelpdeskVSelectVendorForGOPopup,
        state, handleVendorApprovedStatusPopup, handleHelpdeskVRemarksPopup,
        handleShowCloseTicketPopup } = props;
        // handleIntimatePopup

    return (
        <>
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && !TicketDetails?.Selected_Vendor) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHelpdeskVSelectVendorForGOPopup}
                >
                    Select Vendor
                </button>
            }
            {(TicketDetails?.Last_Intimate.includes('Manager') && state?.state?.Page === 'Manager' &&
                (TicketDetails?.Selected_Vendor && (!TicketDetails?.Vendor_Approved_Status || TicketDetails?.Vendor_Approved_Status === 'Change Vendor'))) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleVendorApprovedStatusPopup}
                >
                    Update Status
                </button>

            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.Vendor_Approved_Status === 'Approved' &&
                !TicketDetails?.HelpdeskV_Remarks) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHelpdeskVRemarksPopup}
                >
                    Remarks
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.HelpdeskV_Remarks &&
                TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {/* {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && ((TicketDetails?.VendorCallConversation && !TicketDetails?.Vendor_Approved_Status) ||
                    ((TicketDetails?.Vendor_Approved_Status && TicketDetails?.Vendor_Approved_Status !== 'Approved')))) &&
                // (!TicketDetails?.Vendor_Approved_Status || TicketDetails?.Vendor_Approved_Status === 'Change Vendor')) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.Last_Intimate.includes('Manager') && state?.state?.Page === 'Manager' &&
                (TicketDetails?.Vendor_Approved_Status)) &&
                // (TicketDetails?.Selected_Vendor || TicketDetails?.Vendor_Approved_Status === 'Change Vendor')) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                !TicketDetails?.Final_Price_Given_By_Manager) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            } */}
        </>
    )
};