import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import ReactPagination from '../../common/React-Pagination';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { AllTickets } = useSelector(state => state.TicketReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_ALL_TICKETS_LIST });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (AllTickets?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(AllTickets.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CauseOfTicket && val.CauseOfTicket.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_On && val.Ticket_Raised_On.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_By.Name && val.Ticket_Raised_By.Name.toLowerCase().includes(search)) ||
                    (val.TypeOfUser && val.TypeOfUser.toLowerCase().includes(search)) ||
                    (val.Ticket_Status && val.Ticket_Status.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [AllTickets, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_ALL_TICKETS_LIST });
    };

    const ViewDetails = (e, details) => {
        e.preventDefault();
        let Path = (Page === 'Manager') ? '/Manager-Ticket-Details' : '/Management-Ticket-Details';

        history.push(Path, { Id: details._id, Page: Page, Tab: "All" });
    };

    useEffect(() => {
        (AllTickets.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [AllTickets]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>All Tickets List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="search Id/Status..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Ticket ID</th>
                                                                <th scope="col">Cause of Ticket</th>
                                                                <th scope="col">Ticket Raised On</th>
                                                                <th scope="col">Ticket Raised By</th>
                                                                <th scope="col">Ticket Raised For</th>
                                                                <th scope="col">Ticket Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr
                                                                        className="odd cursor"
                                                                        key={cake._id}
                                                                        onClick={(e) => ViewDetails(e, cake)}
                                                                    >
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CauseOfTicket === 'Fully Customized Cake' ? 'Fully Customised Cake' :
                                                                            cake.CauseOfTicket === 'Customized Cake through Vendor' ? 'Customised Cake through Vendor' :
                                                                                cake.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' ? 'Cakey Direct-Fully Customised Cake' :
                                                                                    cake.CauseOfTicket

                                                                        }</td>
                                                                        <td>{cake.Ticket_Raised_On}</td>
                                                                        <td>{cake.Ticket_Raised_By.Name}</td>
                                                                        <td>{cake.Ticket_Raised_For}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Ticket_Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Ticket_Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};