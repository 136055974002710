import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';

function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'HelpdeskC' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/Helpdesk-C-Dashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                            <Link to="/Helpdesk-C-Direct-Customized-Cake-Orders-List">Cakey Direct Customised Cake Orders</Link>
                        </li>
                    <li className="breadcrumb-item active" aria-current="page">Cakey Direct Customised Cake Order Details</li>
                </ol> :
                Page === 'HelpdeskV' ?
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item">
                            <Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/Helpdesk-C-Direct-Customized-Cake-Orders-List">Cakey Direct Customised Cake Orders</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Cakey Direct Customised Cake Order Details</li>
                    </ol> :
                    <></>
            }
        </nav>
    );
};

export default Breadcrumb;