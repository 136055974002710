import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from "../../utils/constants";
import axios from "axios";

const HamperOrderSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ADMIN_HAMPER_ORDERS_LIST, GetAdminHamperOrdersList),
        yield takeEvery(actions.GET_HAMPER_DETAILS, GetHamperDetails),
        yield takeEvery(actions.GET_VENDOR_HAMPER_ORDERS_LIST, GetVendorHamperOrdersList),
        yield takeEvery(actions.ACCEPT_HAMPER_ORDER, AcceptHamperOrder),
        yield takeEvery(actions.CANCEL_HAMPER_ORDER, CancelHamperOrder),
        yield takeEvery(actions.UPDATE_HAMPER_ORDER_STATUS, UpdateHamperOrderStatus),
    ]);
};

const GetAdminHamperOrdersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamperorder/list`
            )
        );
        yield put({
            type: actions.SET_ADMIN_HAMPER_ORDERS_LIST,
            payload: { AllHamperOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHamperDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamperorder/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_HAMPER_DETAILS,
            payload: { HamperDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorHamperOrdersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamperorder/listbyvendor/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_HAMPER_ORDERS_LIST,
            payload: { VendorHamperOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AcceptHamperOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/hamperorder/accepted/${payload}`
            )
        );
        // yield put({
        //     type: actions.ACCEPT_HAMPER_ORDER_STATUS,
        //     payload: { AcceptHamperStatus: result.data }
        // });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_HAMPER_DETAILS, payload: payload });
        yield delay(2000);
        // yield put({ type: actions.ACCEPT_HAMPER_ORDER_STATUS, payload: { AcceptHamperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const CancelHamperOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/hamperorder/canceled/${payload.Id}`, {
                Cancelled_By: 'Vendor',
                ReasonForCancel: payload.ReasonForCancel
            }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.CANCEL_HAMPER_ORDER_STATUS,
            payload: { CancelHamperStatus: result.data }
        });
        yield put({ type: actions.GET_HAMPER_DETAILS, payload: payload.Id });
        yield delay(2000);
        yield put({ type: actions.CANCEL_HAMPER_ORDER_STATUS, payload: { CancelHamperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateHamperOrderStatus = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/hamperorder/updatestatus/${payload.OrderId}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.UPDATE_HAMPER_ORDER_STATUS_RESPONSE,
            payload: { HamperUpdateStatus: result.data }
        });
        yield put({ type: actions.GET_HAMPER_DETAILS, payload: payload.OrderId });
        yield delay(2000);
        yield put({ type: actions.UPDATE_HAMPER_ORDER_STATUS_RESPONSE, payload: { HamperUpdateStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default HamperOrderSaga;