import actions from "./actions";

const initialState = {
    AddNewTopperStatus: [],
    VendorToppersList: [],
    UpdateTopperStatus: [],
    ToppersList: [],
    CreateHamperStatus: [],
    VendorHamperList: [],
    HamperDetails: [],
    AdminHamperList: [],
    TopperDetails: [],
    TopperReponse: []

};

const TopperReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.ADD_NEW_TOPPER_STATUS:
            return {
                ...state,
                AddNewTopperStatus: action.payload.AddNewTopperStatus
            }
        case actions.SET_VENDOR_TOPPERS_LIST:
            return {
                ...state,
                VendorToppersList: action.payload.VendorToppersList
            }
        case actions.UPDATE_TOPPER_STATUS:
            return {
                ...state,
                UpdateTopperStatus: action.payload.UpdateTopperStatus
            }
        case actions.SET_ALL_TOPPERS_LIST:
            return {
                ...state,
                ToppersList: action.payload.ToppersList
            }
        case actions.CREATE_HAMBPERS_STATUS:
            return {
                ...state,
                CreateHamperStatus: action.payload.CreateHamperStatus
            }
        case actions.SET_VENDOR_HAMPERS_LIST:
            return {
                ...state,
                VendorHamperList: action.payload.VendorHamperList
            }
        case actions.SET_HAMPERS_DETAILS:
            return {
                ...state,
                HamperDetails: action.payload.HamperDetails
            }
        case actions.UPDATE_HAMPER_STATUS:
            return {
                ...state,
                UpdateHamperStatus: action.payload.UpdateHamperStatus
            }
        case actions.SET_ADMIN_HAMPERS_LIST:
            return {
                ...state,
                AdminHamperList: action.payload.AdminHamperList
            }
        case actions.TOPPER_RESPONSE:
            return {
                ...state,
                TopperReponse: action.payload
            }
        case actions.SET_TOPPER_DETAILS:
            return {
                ...state,
                TopperDetails: action.payload
            }
        default:
            return state
    }
};

export default TopperReducer;