const actions = {
    LOG_IN: 'LOG_IN',
    UPDATE_AUTH_DETAILS: 'UPDATE_AUTH_DETAILS',
    GET_TOKEN: 'GET_TOKEN',
    VERIFY_TOKEN: 'VERIFY_TOKEN',
    SET_LAST_SEEN: 'SET_LAST_SEEN',
    GET_ACTIVE_VENDORS: 'GET_ACTIVE_VENDORS',
    SET_ACTIVE_VENDORS: 'SET_ACTIVE_VENDORS',
    UPDATE_CLOCKIN_OR_CLOCKOUT: 'UPDATE_CLOCKIN_OR_CLOCKOUT',
    GET_USERS_ACTIVE_TIME: 'GET_USERS_ACTIVE_TIME',
    SET_USERS_ACTIVE_TIME: 'SET_USERS_ACTIVE_TIME'
};

export default actions;