import React from 'react';

export default function CancellationWithin15Mins(props) {

    const { TicketDetails, auth, handleIntimatePopup, state, handleAcceptTicket,
        handleIntimatetoCustomerPopup, handleShowCloseTicketPopup } = props;
    // const { TicketDetails, auth, handleIntimatePopup, handleHelpdeskVSelectVendorForGOPopup,
    //     state, handleVendorApprovedStatusPopup, handleHelpdeskVRemarksPopup,
    //     handleShowCloseTicketPopup } = props;

    return (
        <>
            {/* {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && !TicketDetails?.Selected_Vendor) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHelpdeskVSelectVendorForGOPopup}
                >
                    Select Vendor
                </button>
            }
            {(TicketDetails?.Last_Intimate.includes('Manager') && state?.state?.Page === 'Manager' &&
                (TicketDetails?.Selected_Vendor && (!TicketDetails?.Vendor_Approved_Status || TicketDetails?.Vendor_Approved_Status === 'Change Vendor'))) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleVendorApprovedStatusPopup}
                >
                    Update Status
                </button>

            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.Vendor_Approved_Status === 'Approved' &&
                !TicketDetails?.HelpdeskV_Remarks) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHelpdeskVRemarksPopup}
                >
                    Remarks
                </button>
            }*/}
            {(!TicketDetails?.HelpdeskV_Accepted_By && state?.state?.Page === "HelpdeskV") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Refund_Status && !TicketDetails?.Customer_Intimation) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleIntimatetoCustomerPopup}
                >
                    Intimate to Customer
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Customer_Intimation &&
                TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email && !TicketDetails?.Refund_Status) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
        </>
    )
};