import React, { useEffect, useState } from "react";

export default function MacaroonBox(props) {

    const {
        option,
        className,
        handleMPSelectButton,
        disabledButton2,
        handleMPRemoveButton,
        handleMPInputValue,
        handleAddOtherMPBox,
        Name,
        macaroonsBoxPriceList
    } = props;

    const [list, setList] = useState([]);

    useEffect(() => {
        if (option === undefined) {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Pieces and Price Per Box <span className='text-danger'>*</span></label>
            <div className="scrollable-list">
                {disabledButton2?.length > 0 &&
                    <div className="display-flex mb-2 list-main justify-content-between">
                        <div className='font-weight-bold ml-2'>Pieces</div>
                        <div className="font-weight-bold">Minimum Box Count</div>
                        <div className="font-weight-bold mr-2">Price Per Box</div>
                    </div>
                }
                {list.map((value, i) =>
                    <div className="display-flex mb-2 list-main" key={i}>
                        {!disabledButton2?.includes(i) ?
                            <>
                                <div className={className}>
                                    {value}
                                </div>
                                <button
                                    className='add-button'
                                    value={value}
                                    onClick={(e) => handleMPSelectButton(e, i)}
                                >
                                    +
                                </button>
                            </> :
                            <>
                                <button
                                    className='mr-1 minus-button'
                                    value={value}
                                    onClick={(e) => handleMPRemoveButton(e, i)}
                                >
                                    -
                                </button>
                                <div className={className}>
                                    {value}
                                </div>
                                {macaroonsBoxPriceList?.map((val, ind) =>
                                    <div className="d-flex" key={ind}>
                                        {val.index === i &&
                                            <>
                                                <div>
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={3}
                                                        onInput={(e) => {
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                            }
                                                        }}
                                                        className={(val?.Count_Value) ? "price-input-others mr-1" : "price-input-others mr-1 required-field"}
                                                        name="MinCount"
                                                        defaultValue={val?.MinCount}
                                                        placeholder="e.g. 4"
                                                        onChange={(e) => handleMPInputValue(e, value, ind, "MC")}
                                                    />
                                                </div>
                                                <div className="mr-2">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={5}
                                                        onInput={(e) => {
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                            }
                                                        }}
                                                        className={(val?.Price_Value) ? "price-input-others" : "price-input-others required-field"}
                                                        name="PricePerBox"
                                                        defaultValue={val?.PricePerBox}
                                                        placeholder="e.g. 400"
                                                        onChange={(e) => handleMPInputValue(e, value, ind, "PPB")}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                )}
                            </>
                        }
                    </div>
                )}
                <div
                    className="font-weight-bold text-center pb-2 pt-2 cursor border-top add-weight-hover"
                    onClick={(e) => handleAddOtherMPBox(e, `${Name} Pieces Per Box`)}
                >
                    Add New Minimum Weight
                </div>
            </div>
            <div className="row">
                {macaroonsBoxPriceList?.map((val, i) =>
                    <div key={i} className="col-md-6 d-flex justify-content-center">
                        {(val.MinCount && val.PricePerBox) &&
                            <div className="list-array">
                                {val.Piece} Pieces -  Min {val.MinCount ? val.MinCount : 0} Box - Rs. {val.PricePerBox ? val.PricePerBox : 0} / Box
                            </div>
                        }
                    </div>
                )}
            </div>
        </div>
    )
};