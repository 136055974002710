export const ColorArray = [
    { Letter: "A", Color: "#FF5005" },
    { Letter: "B", Color: "#FFA405" },
    { Letter: "C", Color: "#3090C7" },
    { Letter: "D", Color: "#C48793" },
    { Letter: "E", Color: "#54C571" },
    { Letter: "F", Color: "#C36241" },
    { Letter: "G", Color: "#42cfd4" },
    { Letter: "H", Color: "#4eb3e6" },
    { Letter: "I", Color: "#872657" },
    { Letter: "J", Color: "#bf4dbf" },
    { Letter: "K", Color: "#F4A460" },
    { Letter: "L", Color: "#F67280" },
    { Letter: "M", Color: "#a7a7fc" },
    { Letter: "N", Color: "#2f6cfa" },
    { Letter: "O", Color: "#f75454" },
    { Letter: "P", Color: "#828282" },
    { Letter: "Q", Color: "#a152d9" },
    { Letter: "R", Color: "#e536eb" },
    { Letter: "S", Color: "#603F8B" },
    { Letter: "T", Color: "#42cfd4" },
    { Letter: "U", Color: "#4eb3e6" },
    { Letter: "V", Color: "#F0A3FF" },
    { Letter: "W", Color: "#bf4dbf" },
    { Letter: "X", Color: "#c94b9d" },
    { Letter: "Y", Color: "#5e5b5b" },
    { Letter: "Z", Color: "#43302E" }
];