import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom/esm/react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from './Breadcrumb';
import ImageSlider from '../../../sections/Customize-Cake-Details/ImageSlider';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
// import { Modal } from "react-bootstrap";
import CustomizeCakeActions from '../../../../redux/Customize-Cake/actions';
import OrderActions from '../../../../redux/Order/actions';
// import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Preloader from '../../../layouts/Preloader';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const state = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()

    const Id = state?.state?.id;

    // const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    // const [reasonForCancel, setReasonForCancel] = useState("");

    // var [show, setShow] = useState(false);
    // var [showErr, setShowErr] = useState('');

    //get logged in users details
    // const { token } = useSelector(state => state.authReducer);

    const { CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);
    // const { CancelCustomieCake, CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    useEffect(() => {
        dispatch({ type: CustomizeCakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    // const handleShow = () => {
    //     setShow(true);
    //     setShowErr(false);
    // };

    // const handleCancel = (e) => {
    //     e.preventDefault();
    //     setShow(false);
    //     setShowErr(false);
    // };

    //close the details page
    const handleClose = () => {
        if (state?.state?.page === 'customizecake') {
            history.push('/Vendor-customize-cake-list');
        } else if (state?.state?.page === 'vendordashboard') {
            history.push("/vendordashboard");
        };
    };

    useEffect(() => {
        if (CustomizedCakeDeatils.length !== 0) {
            if (CustomizedCakeDeatils.Vendor_Response_Status !== 'seen') {
                dispatch({
                    type: OrderActions.CHANGE_CUSTOMIZEDCAKE_ORDER_VIEW_RESPONSE_BY_VENDOR,
                    payload: { id: CustomizedCakeDeatils._id, response: 'seen' }
                });
            }
        };
        // eslint-disable-next-line
    }, [CustomizedCakeDeatils]);

    // const handleCancelOrder = (e) => {
    //     e.preventDefault();
    //     if (!reasonForCancel) {
    //         setShowErr(true);
    //     } else {
    //         setShowErr(false);
    //         setLoader(true);
    //         dispatch({
    //             type: CustomizeCakeActions.CANCEL_CUSTOMIZED_CAKE_ORDER,
    //             payload: { VendorID: token?.result?._id, id: Id, ReasonForCancel: reasonForCancel }
    //         });
    //     }
    // };

    // useEffect(() => {
    //     if (CancelCustomieCake?.statusCode === 200) {
    //         setLoader(false);
    //         setShow(false);
    //         if (state.state.page === 'customizecake') {
    //             history.push('/Vendor-customize-cake-list');
    //         } else if (state.state.page === 'vendordashboard') {
    //             history.push("/vendordashboard");
    //         };
    //     } else {
    //         setLoader(false);
    //     }
    //     // eslint-disable-next-line
    // }, [CancelCustomieCake, state]);

    //send the invoice for customized cake orders
    const handleSendInvoice = () => {
        history.push('/Vendor-customize-cake-invoice', { page: 'customizecake', Id: CustomizedCakeDeatils?._id });
    };

    useEffect(() => {
        if (CustomizedCakeDeatils?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [CustomizedCakeDeatils]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        {CustomizedCakeDeatils?.Images === undefined || CustomizedCakeDeatils?.Images?.length === 0 ?
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header tabledata-background">
                                        <h6>Customised Cake Details</h6>
                                    </div>
                                </div>
                            </div> :
                            <div className="col-md-12">
                                <div className="ms-panel tabledata-background">
                                    <div className="ms-panel-header">
                                        <h6>Customised Cake Details</h6>
                                    </div>

                                    <div className="ms-panel-body order-image">
                                        <ImageSlider image={CustomizedCakeDeatils?.Images} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(CustomizedCakeDeatils?.ReasonForCancel && (CustomizedCakeDeatils?.Status === 'Cancelled' || CustomizedCakeDeatils?.Status === 'Rejected')) &&
                                                <tr className='text-danger'>
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{CustomizedCakeDeatils?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CustomizedCakeDeatils?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td><b>{CustomizedCakeDeatils?.CakeName}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{CustomizedCakeDeatils?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{CustomizedCakeDeatils?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CustomizedCakeDeatils?.Status?.toUpperCase())?.replace(/ /g, '')}>{CustomizedCakeDeatils?.Status}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6  col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{CustomizedCakeDeatils?.DeliverySession}</td>
                                            </tr>
                                            {CustomizedCakeDeatils?.Status_Updated_On !== undefined ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {CustomizedCakeDeatils?.Theme &&
                                                <tr>
                                                    <th scope="row">Theme Name</th>
                                                    <td>{CustomizedCakeDeatils?.Theme}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.Tier &&
                                                <tr>
                                                    <th scope="row">Tier</th>
                                                    <td>{CustomizedCakeDeatils?.Tier} Tier</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{CustomizedCakeDeatils?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><FlavourList FlavoursList={CustomizedCakeDeatils?.Flavour} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shape</th>
                                                <td>{CustomizedCakeDeatils?.Shape}</td>
                                            </tr>
                                            {CustomizedCakeDeatils?.MessageOnTheCake &&
                                                <tr>
                                                    <th scope="row">Message on the Cake</th>
                                                    <td>{CustomizedCakeDeatils?.MessageOnTheCake}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.SpecialRequest &&
                                                <tr>
                                                    <th scope="row">Special Request</th>
                                                    <td>{CustomizedCakeDeatils?.SpecialRequest}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CustomizedCakeDeatils?.Status === 'Sent' || CustomizedCakeDeatils?.Status === 'Ordered' ||
                            CustomizedCakeDeatils?.Status === 'Price Approved' ?
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">PRICE INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Price</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Vendor_Price)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{CustomizedCakeDeatils?.Weight}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="row">Extra Charges</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.ExtraCharges)}</td>
                                                </tr> */}
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Discount)}</td>
                                                </tr>
                                                {CustomizedCakeDeatils?.DeliveryCharge &&
                                                    <tr>
                                                        <th scope="row">Delivery Charge</th>
                                                        <td>{float2D(CustomizedCakeDeatils?.DeliveryCharge)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Total</th>
                                                    <td><b>{float2D(CustomizedCakeDeatils?.Vendor_Total)}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Invoice Sent On</th>
                                                    <td>{CustomizedCakeDeatils?.Invoice_Sent_On}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : <></>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{CustomizedCakeDeatils?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{CustomizedCakeDeatils?.UserName}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Customer Phonenumber</th>
                                                <td>{CustomizedCakeDeatils?.UserPhoneNumber}</td>
                                            </tr> */}
                                            {CustomizedCakeDeatils?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{CustomizedCakeDeatils?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.Status === 'Cancelled' || CustomizedCakeDeatils?.Status === 'Rejected' ?
                                                <></> :
                                                <>
                                                    {CustomizedCakeDeatils?.Status === 'Sent' || CustomizedCakeDeatils?.Status === 'Ordered' ||
                                                        CustomizedCakeDeatils?.Status === 'Price Approved' ?
                                                        <>
                                                            <button
                                                                className="btn btn-secondary float-start mr-3 disabled-assign" disabled type="submit" >Invoice Sent</button>
                                                        </> :
                                                        <>
                                                            <button
                                                                className="btn btn-primary float-start mr-3"
                                                                type="submit"
                                                                onClick={handleSendInvoice}
                                                            >
                                                                Send Invoice
                                                            </button>
                                                            {/* {CustomizedCakeDeatils?.Ticket_Raised === 'n' &&
                                                                <button
                                                                    className="btn btn-warning float-start mr-3"
                                                                    type="submit"
                                                                    onClick={handleShow}
                                                                >
                                                                    Cancel Order
                                                                </button>
                                                            } */}
                                                        </>
                                                    }
                                                </>
                                            }
                                            <button
                                                className="btn btn-secondary float-start"
                                                type="submit"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Modal className="modal-min" show={show} onHide={handleCancel} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleCancel}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Cancel Order</h1>
                            <div>
                                <label htmlFor="validationCustom24" className='font-weight-bold'>Reason For Cancel the Order - (Order Id : {CustomizedCakeDeatils?.Id})<span className='text-danger'> *</span></label>
                                <div className="input-group">
                                    <input type="text" className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"} name='ReasonForSuspend' id="validationCustom24" placeholder="Enter the reason..." onChange={(e) => setReasonForCancel(e.target.value)} />
                                    <p className='text-danger'>{showErr}</p>
                                </div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary shadow-none" onClick={handleCancelOrder}>Cancel Order</button>
                                        <button type="submit" className="btn btn-secondary ml-4" onClick={handleCancel} >Close</button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal> */}
                </div>
            }
        </>
    )
}