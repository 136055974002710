import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import AccountsActions from '../../../redux/Accounts/actions';
import Preloader from '../../layouts/Preloader';
import { useHistory } from 'react-router-dom';
import ReactPagination from '../../common/React-Pagination';
import { float2D } from '../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(10);

    const { VendorsStatementList } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        dispatch({ type: AccountsActions.GET_VENDORS_STATEMENTOFACCOUNTS_LIST });
        // eslint-disable-next-line
    }, []);

    //validate the list is empty or not
    useEffect(() => {
        if (VendorsStatementList?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(VendorsStatementList)
        };
    }, [VendorsStatementList]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const ViewDetails = (e, vendor) => {
        e.preventDefault();
        history.push('/Admin-statement-of-accounts-details', { Id: vendor.VendorID, Name: vendor.VendorName, Vendor_ID: vendor.Vendor_ID });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: AccountsActions.GET_VENDORS_STATEMENTOFACCOUNTS_LIST });
    };

    //loading
    useEffect(() => {
        (VendorsStatementList.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [VendorsStatementList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Vendors Statement Of Accounts</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Vendor ID</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Total Sales</th>
                                                                <th scope="col">Cakey Share</th>
                                                                <th scope="col">Delivery Charges</th>
                                                                <th scope="col">Total Payment</th>
                                                                <th scope="col">Pending Payment</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((order, i) =>
                                                                    <tr className="odd cursor" key={i} onClick={(e) => ViewDetails(e, order)}>
                                                                        <th className='id-column' scope="row">{order.Vendor_ID}</th>
                                                                        <td>{order.VendorName}</td>
                                                                        <td className='vendor-sales-alignment'>{order.Total === 0 ? "0.00" : float2D(order.Total)}</td>
                                                                        <td className='vendor-sales-alignment'>{order.CakeyShare === 0 ? "0.00" : float2D(order.CakeyShare)}</td>
                                                                        <td className='vendor-sales-alignment'>{order.DeliveryCharge === 0 ? "0.00" : float2D(order.DeliveryCharge)}</td>
                                                                        <td className='vendor-sales-alignment'>{order.FinalTotal === 0 ? "0.00" : float2D(order.FinalTotal)}</td>
                                                                        <td className='vendor-sales-alignment'>{order.PendingPayment === 0 ? "0.00" : float2D(order.PendingPayment)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};



// import React, { useEffect, useState } from 'react';
// import Breadcrumb from './Breadcrumb';
// import { useSelector, useDispatch } from 'react-redux';
// import AccountsActions from '../../../redux/Accounts/actions';
// import Preloader from '../../layouts/Preloader';
// import { useHistory } from 'react-router-dom';
// import ReactPagination from '../../common/React-Pagination';
// import { float2D } from '../../../utils/Validation';

// export default function Content() {

//     const dispatch = useDispatch();
//     const history = useHistory();

//     const [fetchSuccess, setFetchSuccess] = useState(false);
//     const [filteredData, setFilteredData] = useState([]);
//     const [currentPage, setCurrentPage] = useState(0);
//     const [postsPerPage] = useState(10);

//     const { VendorsStatementList } = useSelector(state => state.AccountsReducer);

//     useEffect(() => {
//         dispatch({ type: AccountsActions.GET_VENDORS_STATEMENTOFACCOUNTS_LIST });
//         // eslint-disable-next-line
//     }, []);

//     //validate the list is empty or not
//     useEffect(() => {
//         if (VendorsStatementList?.message === 'No Records Found') {
//             setFilteredData([]);
//         } else {
//             setFilteredData(VendorsStatementList)
//         };
//     }, [VendorsStatementList]);

//     const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
//     const indexOfLastPost = indexOfFirstPost + postsPerPage;
//     const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

//     // Change page
//     const paginate = (event) => {
//         setCurrentPage(event.selected);
//     };

//     const ViewDetails = (e, vendor) => {
//         e.preventDefault();
//         history.push('/Admin-statement-of-accounts-details', { Id: vendor.VendorID, Name: vendor.VendorName, Vendor_ID: vendor.Vendor_ID });
//     };

//     const handlePageRefresh = (e) => {
//         e.preventDefault();
//         dispatch({ type: AccountsActions.GET_VENDORS_STATEMENTOFACCOUNTS_LIST });
//     };

//     //loading
//     useEffect(() => {
//         (VendorsStatementList.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
//     }, [VendorsStatementList]);

//     return (
//         <>
//             {!fetchSuccess ?
//                 <Preloader fetchSuccess={fetchSuccess} /> :
//                 <div className="ms-content-wrapper">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <Breadcrumb handlePageRefresh={handlePageRefresh} />
//                             <div className="ms-panel">
//                                 <div className="ms-panel-header">
//                                     <h6>Vendors Statement Of Accounts</h6>
//                                 </div>
//                                 <div className="ms-panel-body tabledata-background">
//                                     <div className="table-responsive Scrollbar-Style">
//                                         <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
//                                             <div className="row">
//                                                 <div className="col-sm-12">
//                                                     <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
//                                                         <thead>
//                                                             <tr role="row">
//                                                                 <th scope="col">Vendor ID</th>
//                                                                 <th scope="col">VendorName</th>
//                                                                 <th scope="col">Total Sales</th>
//                                                                 <th scope="col">Cakey Share</th>
//                                                                 <th scope="col">Delivery Charges</th>
//                                                                 <th scope="col">Total Payment</th>
//                                                                 <th scope="col">Pending Payment</th>
//                                                             </tr>
//                                                         </thead>
//                                                         {filteredData.length > 0 ?
//                                                             <tbody>
//                                                                 {currentdata.map((order, i) =>
//                                                                     <tr className="odd cursor" key={i} onClick={(e) => ViewDetails(e, order)}>
//                                                                         <th className='id-column' scope="row">{order.Vendor_ID}</th>
//                                                                         <td>{order.VendorName}</td>
//                                                                         <td className='vendor-sales-alignment'>{order.Total === 0 ? "0.00" : float2D(order.Total)}</td>
//                                                                         <td className='vendor-sales-alignment'>{order.CakeyShare === 0 ? "0.00" : float2D(order.CakeyShare)}</td>
//                                                                         <td className='vendor-sales-alignment'>{order.DeliveryCharge === 0 ? "0.00" : float2D(order.DeliveryCharge)}</td>
//                                                                         <td className='vendor-sales-alignment'>{order.FinalTotal === 0 ? "0.00" : float2D(order.FinalTotal)}</td>
//                                                                         <td className='vendor-sales-alignment'>{order.PendingPayment === 0 ? "0.00" : float2D(order.PendingPayment)}</td>
//                                                                     </tr>
//                                                                 )}
//                                                             </tbody> :
//                                                             <tbody>
//                                                                 <tr>
//                                                                     <td colSpan={5} className='font-weight-bold'>No Records Found</td>
//                                                                 </tr>
//                                                             </tbody>
//                                                         }
//                                                     </table>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     {filteredData.length > postsPerPage &&
//                                         <ReactPagination
//                                             paginate={paginate}
//                                             pageCount={Math.ceil(filteredData?.length / postsPerPage)}
//                                             currentPage={currentPage}
//                                             currentdata={currentdata}
//                                             totalPosts={filteredData.length}
//                                         />
//                                     }
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             }
//         </>
//     )
// };