import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/CakeArray/actions";
import { LettersValidation } from "../../../../utils/Validation";

export default function Shapes({ option, className, handleSelectButton, handleRemoveButton, disabledButton, show, shapeValidate }) {

    const [list, setList] = useState([]);
    const [newShape, setNewShape] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add new shape status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option?.message === 'No Records Found') {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new shape functionality
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newShape) {
            if (list?.length === 0) {
                dispatch({ type: actions.ADD_NEW_SHAPE, payload: newShape });
            } else {
                const newList = list?.filter(val => {
                    if (val.Name === newShape) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: actions.ADD_NEW_SHAPE, payload: newShape });
                }
            }
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        }
    };

    //validate add new shape status
    useEffect(() => {
        if (Status.AddNewShapeStatus !== undefined) {
            if (Status.AddNewShapeStatus.statusCode === 200) {
                dispatch({ type: actions.GET_SHAPE_LIST });
                setNewShape('');
            }
        };
        //eslint-disable-next-line
    }, [Status]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Shapes</label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input
                        type="text"
                        className="form-control text-capitalize"
                        value={newShape}
                        onInput={LettersValidation}
                        onChange={(e) => setNewShape(e.target.value)}
                    />
                    <button
                        className='add-button rounded'
                        onClick={handleAddNew}
                    >
                        add
                    </button>
                </div>
            </div>
            <div className="scrollable-list">
                {(show || list?.length > 0) ?
                    <>
                        {list.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>
                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            value={value.Name}
                                            onClick={() => handleSelectButton(value.Name, i)}
                                        >
                                            +
                                        </button>
                                    </> :
                                    <>
                                        <button
                                            className='mr-1 minus-button'
                                            value={value.Name}
                                            onClick={() => handleRemoveButton(i)}
                                        >
                                            -
                                        </button>
                                        <div className={className}> {value.Name}</div>
                                    </>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};