import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const VendorOrderListSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_VENDOR_ORDER_LIST, getVendorOrderList),
        yield takeEvery(actions.UPDATE_ORDER_STATUS, UpdateOrderStatus),
        yield takeEvery(actions.GET_VENDOR_ORDER_DETAILS, GetVendorOrderDetails),
        yield takeEvery(actions.GET_VENDOR_ORDER_LIST_BY_STATUS, GetVendorOrderListByStatus),
        yield takeEvery(actions.GET_VENDOR_LOGIN_SESSION_ORDERS, GetVendorLoginSessionOrders),
        yield takeEvery(actions.ACCEPT_ORDER, AcceptOrder),
        yield takeEvery(actions.GET_SALES_COUNT_CHART_REPORT, GetSalesCountChartReport),
        yield takeEvery(actions.GET_VENDORS_PINCODE_LIST, GetVendorsPincodeList),
    ]);
};

//get vendor orders list
const getVendorOrderList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbyvendorid/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_ORDER_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor orders list by status
const GetVendorOrderListByStatus = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbyvendorstatus/${payload.id}/${payload.status}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_ORDER_LIST_BY_STATUS,
            payload: { VendorOrderListByStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//update order status
const UpdateOrderStatus = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/order/updatestatus/${payload.OrderId}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.SET_UPDATE_ORDER_STATUS, payload: { UpdateOrderStatus: result.data } });
        yield put({ type: actions.GET_VENDOR_ORDER_DETAILS, payload: payload.OrderId });
        yield delay(2000);
        yield put({ type: actions.SET_UPDATE_ORDER_STATUS, payload: { UpdateOrderStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AcceptOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/order/accept/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_VENDOR_ORDER_DETAILS, payload: payload });
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor's order's details
const GetVendorOrderDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_ORDER_DETAILS,
            payload: { GetVendorOrderDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor orders list
const GetVendorLoginSessionOrders = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/session`
            )
        );
        yield put({
            type: actions.SET_VENDOR_LOGIN_SESSION_ORDERS,
            payload: { LoginSessionOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetSalesCountChartReport = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/chart/vendorsSaleCount/${payload.StartDate}/${payload.EndDate}/${payload.Type}`
            )
        );
        yield put({
            type: actions.SET_SALES_COUNT_CHART_REPORT,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorsPincodeList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/chart/pincode/list`
            )
        );
        yield put({
            type: actions.SET_VENDORS_PINCODE_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default VendorOrderListSaga;