import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'list' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/Accounts-Dashboard"><IoMdHome className='home' /> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/Accounts-Tickets-List"> Tickets</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Ticket Details</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/Accounts-Dashboard"><IoMdHome className='home' /> Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Unclosed Ticket Details</li>
                </ol>
            }
        </nav>
    );
};

export default Breadcrumb;