import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Accounts/actions';
import ReactPagination from '../../common/React-Pagination';
import Preloader from '../../layouts/Preloader';
import { float2D } from '../../../utils/Validation';

export default function MiscellaneousRefundReport(props) {

    const { Page } = props;

    const dispatch = useDispatch();

    const { Miscellaneous_Refund } = useSelector(state => state.AccountsReducer);

    const [filteredData, setFilteredData] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);//currentPage
    const [postsPerPage, setPostPerPage] = useState(10);//itemsPerPage

    useEffect(() => {
        dispatch({ type: actions.GET_MISCELLANEOUS_REFUND_REPORT });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Miscellaneous_Refund?.length === 0) {
            setFilteredData([]);
        } else {
            (Miscellaneous_Refund?.message === 'No Records Found') ? setFilteredData([]) : setFilteredData(Miscellaneous_Refund);
        }
    }, [Miscellaneous_Refund]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_MISCELLANEOUS_REFUND_REPORT });
    };

    useEffect(() => {
        (Miscellaneous_Refund?.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [Miscellaneous_Refund]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb
                                Page={Page} handlePageRefresh={handlePageRefresh}
                            />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Miscellaneous Refund Report</h6>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Compensation Value</th>
                                                                <th scope="col">Date</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{val.Order_ID}</th>
                                                                        <td>{val.RefundType}</td>
                                                                        <td className='miscellaneous-refund'>{float2D(val.CompensationValue)}</td>
                                                                        <td>{val.Date}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}