import actions from "./actions";

const initState = {
    isAuthenticated: false,
    user: {},
    route: "",
    token: [],
    result: {},
    Alert: "",
    ActiveVendors: [],
    Users_Active_Time: [],
};

const authReducer = (state = initState, action) => {

    switch (action.type) {
        case actions.UPDATE_AUTH_DETAILS: {
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                user: action.payload,
                route: action.payload.route,
                token: action.payload.token,
                result: action.payload.result,
                //    Alert: action.payload.Alert
            };
        }
        case actions.SET_ACTIVE_VENDORS:
            return {
                ...state,
                ActiveVendors: action.payload.ActiveVendors
            }
        case actions.SET_USERS_ACTIVE_TIME:
            return {
                ...state,
                Users_Active_Time: action.payload
            }
        // case actions.SET_LOGINALERT: {
        //     return {
        //         ...state,
        //        loginAlert: action.payload
        //     };
        // }
        default: return state;
    }
}

export default authReducer;