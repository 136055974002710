import React, { useEffect, useState } from "react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom/esm/react-router-dom";
import VendorOrderListActions from '../../../../redux/VendorOrderList/actions';
import CustomizedCakeActions from '../../../../redux/Customize-Cake/actions';
import TicketsActions from '../../../../redux/Tickets/actions';
import FlavourList from "../../../sections/ArrayMapping/FlavourList";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import Preloader from "../../../layouts/Preloader";
import Breadcrumb from "./Breadcrumb";
import { useForm } from "react-hook-form";
import { float2D } from "../../../../utils/Validation";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state.state.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showTicketRaisePopup, setShowTicketRaisePopup] = useState(false);
    const [loader, setLoader] = useState(false);

    const { GetVendorOrderDetails } = useSelector(state => state.VendorOrderReducer);

    // const { CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    const { Ticket_Response } = useSelector(state => state.TicketReducer);

    const auth = useSelector((state) => state.authReducer);

    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const CustomizedCakeID = GetVendorOrderDetails?.CakeID;

    useEffect(() => {
        dispatch({ type: VendorOrderListActions.GET_VENDOR_ORDER_DETAILS, payload: Id });
        //eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: CustomizedCakeID });
        //eslint-disable-next-line
    }, [CustomizedCakeID]);

    const handleClose = (e) => {
        e.preventDefault();
        history.push('/helpdeskV-Cakey-Direct-CustomizedCake-Orderlist');
    };

    const handleRaiseTicketPopup = (e) => {
        e.preventDefault();
        setShowTicketRaisePopup(true);
    };

    const handleCloseTicketRaisePopup = () => {
        setShowTicketRaisePopup(false);
        reset();
    };

    const handleRaiseTicket = (data) => {
        let formData, Accepted_By, Ticket_Raised_For, Last_Intimate;
        Accepted_By = 'HelpdeskV_Accepted_By';
        Ticket_Raised_For = 'Vendor';
        Last_Intimate = ['HelpdeskV'];
        formData = {
            TypeOfUser: auth?.token?.result?.TypeOfUser,
            CauseOfTicket: "Cakey Direct-Fully Customized Cake",
            TicketDescription: data.TicketDescription,
            TypeOfOrder: 'Cake Order',
            OrderID: GetVendorOrderDetails?._id,
            Order_ID: GetVendorOrderDetails?.Id,
            Order_Status: GetVendorOrderDetails?.Status,
            ProductID: GetVendorOrderDetails?.CakeID,
            Product_ID: GetVendorOrderDetails?.Cake_ID,
            VendorID: GetVendorOrderDetails?.VendorID,
            Vendor_ID: GetVendorOrderDetails?.Vendor_ID,
            UserID: GetVendorOrderDetails?.UserID,
            User_ID: GetVendorOrderDetails?.User_ID,
            Weight: GetVendorOrderDetails?.Weight,
            Price: GetVendorOrderDetails?.Price,
            Total: GetVendorOrderDetails?.Total,
            Ticket_Raised_For: Ticket_Raised_For,
            Last_Intimate: Last_Intimate,
            Ticket_Status: 'In Progress',
            Ticket_Raised_By: {
                ID: auth?.token?.result?._id,
                Name: auth?.token?.result?.Name,
                Email: auth?.token?.result?.Email,
                TypeOfUser: auth?.token?.result?.TypeOfUser
            },
            [Accepted_By]: {
                ID: auth?.token?.result?._id,
                Name: auth?.token?.result?.Name,
                Email: auth?.token?.result?.Email,
                TypeOfUser: auth?.token?.result?.TypeOfUser
            },
            Ticket_Accessed_By: {
                HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
            }
        };
        setLoader(true);
        dispatch({ type: TicketsActions.RAISE_NEW_TICKET, payload: formData });
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400 || Ticket_Response.statusCode === 200) {
            setLoader(false);
            setShowTicketRaisePopup(false);
            dispatch({ type: VendorOrderListActions.GET_VENDOR_ORDER_DETAILS, payload: Id });
        }
        // else if (Ticket_Response.statusCode === 200) {
        //     setLoader(false);
        //     setShowTicketRaisePopup(false);
        //     dispatch({ type: VendorOrderListActions.GET_VENDOR_ORDER_DETAILS, payload: Id });
        // };
        // eslint-disable-next-line 
    }, [Ticket_Response]);

    useEffect(() => {
        (GetVendorOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [GetVendorOrderDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Order Details</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                {GetVendorOrderDetails?.Image &&
                                    <div className="ms-panel-body order-image tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center responsive-image-width"
                                                src={GetVendorOrderDetails?.Image}
                                                alt="Cake"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Order ID</th>
                                                <td><b>{GetVendorOrderDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{GetVendorOrderDetails?.Cake_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td>{GetVendorOrderDetails?.CakeName ? GetVendorOrderDetails?.CakeName : 'Customised Cake'}</td>
                                            </tr>
                                            {GetVendorOrderDetails?.CakeCommonName &&
                                                <tr>
                                                    <th scope="row">Cake Common Name</th>
                                                    <td>{GetVendorOrderDetails?.CakeCommonName}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{GetVendorOrderDetails?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{GetVendorOrderDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(GetVendorOrderDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{GetVendorOrderDetails?.Status}</span></td>
                                            </tr>
                                            {GetVendorOrderDetails?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{GetVendorOrderDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{GetVendorOrderDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {GetVendorOrderDetails?.Theme &&
                                                <tr>
                                                    <th scope="row">Theme Name</th>
                                                    <td>{GetVendorOrderDetails?.Theme}</td>
                                                </tr>
                                            }
                                            {GetVendorOrderDetails?.Tier &&
                                                <tr>
                                                    <th scope="row">Tier</th>
                                                    <td>{GetVendorOrderDetails?.Tier} Tier</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><FlavourList FlavoursList={GetVendorOrderDetails?.Flavour} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shape</th>
                                                <td>{`${GetVendorOrderDetails?.Shape?.Name} - Additional Rs.${GetVendorOrderDetails?.Shape?.Price}`}</td>
                                            </tr>

                                            {GetVendorOrderDetails?.MessageOnTheCake &&
                                                <tr>
                                                    <th scope="row">Message on the Cake</th>
                                                    <td>{GetVendorOrderDetails?.MessageOnTheCake}</td>
                                                </tr>
                                            }
                                            {GetVendorOrderDetails?.SpecialRequest &&
                                                <tr>
                                                    <th scope="row">Special Request</th>
                                                    <td>{GetVendorOrderDetails?.SpecialRequest}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {GetVendorOrderDetails?.Toppers?.TopperName &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">TOPPER INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Topper Name</th>
                                                    <td>{GetVendorOrderDetails?.Toppers?.TopperName}</td>
                                                </tr>
                                                {/* {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                                    <tr>
                                                        <th scope="row">Topper Price</th>
                                                        <td>Rs. {float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                } */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center w-50 h-50 responsive-image-width"
                                                src={GetVendorOrderDetails?.Toppers?.TopperImage}
                                                alt="First slide"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Product Count</th>
                                                <td>{GetVendorOrderDetails?.ItemCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Price per Kg</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.NewPrice ? GetVendorOrderDetails?.NewPrice : GetVendorOrderDetails?.Vendor_Price)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{GetVendorOrderDetails?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Extra Charges</th>
                                                {GetVendorOrderDetails?.ExtraCharges === '0' || GetVendorOrderDetails?.ExtraCharges === undefined ?
                                                    <td>0.00</td> :
                                                    <td><span>{float2D(GetVendorOrderDetails?.NewExtraCharges ? GetVendorOrderDetails?.NewExtraCharges : GetVendorOrderDetails?.ExtraCharges)}</span></td>
                                                }
                                            </tr>
                                            {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                                <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>{float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.NewDiscount ? GetVendorOrderDetails?.NewDiscount : GetVendorOrderDetails?.Discount)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.NewDeliveryCharges ? GetVendorOrderDetails?.NewDeliveryCharges : GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            {GetVendorOrderDetails?.TypeOfCustomizedCake === 'Cakey' ?
                                                <tr>
                                                    <th scope="row">Total</th>
                                                    <td><span><b>{float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span></td>
                                                </tr>
                                                :
                                                <tr>
                                                    <th scope="row">Total</th>
                                                    <td><span><b>{GetVendorOrderDetails?.NewTotal ? float2D(parseFloat(GetVendorOrderDetails?.NewTotal) - parseFloat(GetVendorOrderDetails?.NewTax)) : float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span></td>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Product Count</th>
                                                <td>{GetVendorOrderDetails?.ItemCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Price per Kg</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.Price)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{GetVendorOrderDetails?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Extra Charges</th>
                                                {GetVendorOrderDetails?.ExtraCharges === '0' || GetVendorOrderDetails?.ExtraCharges === undefined ?
                                                    <td>0.00</td> :
                                                    <td><span>{float2D(GetVendorOrderDetails?.ExtraCharges)}</span></td>
                                                }
                                            </tr>
                                            {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                                <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>{float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.Discount)}</span></td>
                                            </tr>
                                            {(GetVendorOrderDetails?.CouponValue && !GetVendorOrderDetails?.NewTotal) &&
                                                <tr>
                                                    <th scope="row">Coupon Value</th>
                                                    <td>{float2D(GetVendorOrderDetails?.CouponValue)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.Gst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                <td><span>{float2D(GetVendorOrderDetails?.Sgst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td>
                                                    <span><b>{float2D(parseFloat(GetVendorOrderDetails?.Total))}</b></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {GetVendorOrderDetails?.FinalCakeImage &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h6 className='text-uppercase font-weight-bold'>FINAL CAKE IMAGE</h6>
                                    </div>
                                    <div className='header-break-line'> <hr /> </div>
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body order-image tabledata-background">
                                            <ResponsiveEmbed aspectRatio='21by9'>
                                                <img
                                                    className="d-block align-center "
                                                    src={GetVendorOrderDetails?.FinalCakeImage}
                                                    alt="Cake"
                                                />
                                                </ResponsiveEmbed>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{GetVendorOrderDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{GetVendorOrderDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{GetVendorOrderDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{GetVendorOrderDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{GetVendorOrderDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{GetVendorOrderDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{GetVendorOrderDetails?.UserName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Mobile Number</th>
                                                <td>{GetVendorOrderDetails?.UserPhoneNumber}</td>
                                            </tr>
                                            {GetVendorOrderDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{GetVendorOrderDetails?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                                    <hr />
                                    <p>{GetVendorOrderDetails?.Description ? GetVendorOrderDetails?.Description : 'No Description'}</p>
                                    {GetVendorOrderDetails.Ticket_Raised_For_Customized_Cake === 'No' &&
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleRaiseTicketPopup}
                                        >
                                            Raise Ticket
                                        </button>
                                    }
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Modal className="modal-min" show={showTicketRaisePopup} onHide={handleCloseTicketRaisePopup} aria-labelledby="contained-modal-title-vcenter"
                            centered contentClassName="modal-body-scrollar">
                            <Modal.Body className="tabledata-background">
                                <button type="button" className="close" onClick={handleCloseTicketRaisePopup}><span aria-hidden="true">×</span></button>
                                <h1 className="text-center pb-2">Raise Ticket</h1>
                                <div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Ticket Description</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <textarea
                                                className={errors.TicketDescription ? 'form-control required-field text-capitalize' : 'form-control text-capitalize'}
                                                placeholder='Type here...'
                                                {...register('TicketDescription', { required: { value: true, message: 'required' } })}
                                            />
                                        </div>
                                    </div>
                                    {loader ?
                                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary shadow-none mr-3"
                                                onClick={handleSubmit(handleRaiseTicket)}
                                            >
                                                Raise Ticket
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary shadow-none"
                                                onClick={handleCloseTicketRaisePopup}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            }
        </>
    )
}