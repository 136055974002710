import React from "react";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PushNotification } from "../../../common/PushNotification";

export default function IntimateToAccountsForCustomerCancel(props) {

    const { showIntimateToAccountsForCustomerCancelPopup, handleCloseIntimatetoAccountsForCustomerCancelPopup, loader,
        handleIntimateToAccountsForCustomerCancel, UserDetails } = props;

    const { TicketDetails } = useSelector(state => state.TicketReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    const handleUpdate = (e) => {
        e.preventDefault();
        let bodyContent, For_Display, User_For_Display;
        if (TicketDetails?.Ticket_Approved_Status === 'Approved') {
            if (TicketDetails?.TypeOfOrder === 'Cake Order') {
                For_Display = `Based on your Approval Order is CANCELLED`;
                User_For_Display = `Your Request for Cancellation is Approved on Order, Your Refund will be Proccessed Shortly`;
            } else if (TicketDetails?.TypeOfOrder === 'Product Order') {
                For_Display = `Based on your Approval Order is CANCELLED`;
                User_For_Display = `Your Request for Cancellation is Approved on Order, Your Refund will be Proccessed Shortly`;
            } else if (TicketDetails?.TypeOfOrder === 'Hamper Order') {
                For_Display = `Based on your Approval Order is CANCELLED`;
                User_For_Display = `Your Request for Cancellation is Approved on Order, Your Refund will be Proccessed Shortly`;
            };
        }
        (TicketDetails?.Ticket_Approved_Status === 'Approved') ? bodyContent = User_For_Display :
            bodyContent = `We are unable to Cancel your Order as Requested, Thank You`;
        if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: bodyContent,
            }
            PushNotification(pushMessageData);
        };
        if (VendorDetails?.Notification_Id && TicketDetails?.Ticket_Approved_Status === 'Approved') {
            let pushMessageData = {
                type: 'vendor',
                registration_ids: [VendorDetails?.Notification_Id],
                title: 'Cakey',
                body: For_Display,
            }
            PushNotification(pushMessageData);
        }
        handleIntimateToAccountsForCustomerCancel(UserDetails?.UserName);
    };

    return (
        <div>
            <Modal show={showIntimateToAccountsForCustomerCancelPopup} onHide={handleCloseIntimatetoAccountsForCustomerCancelPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseIntimatetoAccountsForCustomerCancelPopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Confirmation</h4>
                    <div>
                        <h6 className='text-center'>{TicketDetails?.Ticket_Approved_Status === 'Approved' ? 'Are you sure you want to intimate to customer and accounts for refund?' : 'Are you sure you want to intimate to Customer?'}</h6>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleUpdate}
                            >
                                Yes
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseIntimatetoAccountsForCustomerCancelPopup}
                            >
                                No
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};