import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import DaywiseReport from './ReportByDayWise';
import MonthwiseReport from './ReportByMonthWise';
// import PendingOrders from './PendingOrders';

export default function Content() {
    const [key, setKey] = useState('Day');
    return (
        <>
            {/* {!fetchSuccess ? */}
            {/* <Preloader fetchSuccess={fetchSuccess} /> : */}
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <Breadcrumb />
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <h6 className='text-uppercase font-weight-bold'>Sales Report</h6>
                            </div>
                            <div className='header-break-line'> <hr /> </div>
                            <div className="ms-panel-body tabledata-background">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3 tab-style"
                                >
                                    <Tab
                                        tabClassName='schedule-tab'
                                        eventKey="Day"
                                        title="Day"
                                    >
                                        <DaywiseReport />
                                    </Tab>
                                    <Tab
                                        tabClassName='schedule-tab'
                                        eventKey="Month"
                                        title="Month"
                                    >
                                        <MonthwiseReport />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* } */}
        </>
    )
}