const actions = {
    GET_VENDOR_CAKE_LIST:'GET_VENDOR_CAKE_LIST',
    SET_VENDOR_CAKE_LIST:'SET_VENDOR_CAKE_LIST',
    GET_VENDOR_NOTIFICATION: 'GET_VENDOR_NOTIFICATION',
    SET_VENDOR_NOTIFICATION: 'SET_VENDOR_NOTIFICATION',
    REMOVE_NOTIFICATION_BY_ID: 'REMOVE_NOTIFICATION_BY_ID', 
    REMOVE_NOTIFICATION_BY_VENDORID: 'REMOVE_NOTIFICATION_BY_VENDORID', 
    CREATE_BANKNAME: 'CREATE_BANKNAME',
    DELETE_BANKNAME: 'DELETE_BANKNAME',
    GET_BANKNAME: 'GET_BANKNAME',
    SET_BANKNAME: 'SET_BANKNAME',
    GET_ALL_CAKES_LIST_BY_VENDOR: 'GET_ALL_CAKES_LIST_BY_VENDOR',
    SET_ALL_CAKES_LIST_BY_VENDOR: 'SET_ALL_CAKES_LIST_BY_VENDOR',
    VENDORCAKELIST_RESPONSE: 'VENDORCAKELIST_RESPONSE',
  };
  
  export default actions;