import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import ScrollToTop from './components/common/scroll-to-top';
import Preloader from './components/layouts/Preloader';
// import io from 'socket.io-client'

//Routes 
import PrivateRoute from './route/PrivateRoute';
import PublicRoute from './route/PublicRoute';
import actions from "../src/redux/Auth/actions";
import commonActions from './redux/Common/actions';
// import sampleActions from './redux/Sample/actions';
import { store } from './redux/store';
import setAuthToken from './utils/setAuthToken';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';

//Admin Dashboard - import list
import Customerlist from './components/pages/Customerlist';
import CustomerDetails from './components/pages/Customer-Details';
import AdminCakeList from './components/pages/Admin-Cake-List';
import AdminCakeDetails from './components/pages/Admin-Cake-Details';
import Orders from './components/pages/Orders';
import OrderDetails from './components/pages/OrderDetails';
import NewCakesList from './components/pages/New-Cakes-List';
import NewCakeDetails from './components/pages/New-Cake-Details';
import AdminVendorList from './components/pages/AdminVendorList';
import AdminVendorListDetail from './components/pages/AdminVendorListDetail';
import AdminVendorListEdit from './components/pages/AdminVendorListEdit';
import AdminProfile from './components/pages/adminProfile';
// import RegisteredVendorList from './components/pages/RegisteredVendorList';
// import RegisteredVendorDetails from './components/pages/RegisteredVendorDetails';
// import CategoryList from './components/pages/CategoryList';
import CustomizeCakeList from './components/pages/Customize-Cake-List';
import CustomizeCakeDetails from './components/pages/Customize-Cake-Details';
// import AddHelpdeskMember from './components/pages/Add-Helpdesk-Member';
// import HelpdeskMembersList from './components/pages/Helpdesk-Member-List';
import CakeDesignAndDecoration from './components/pages/Cake-Design-Decoration';
// import Defaultregister from './components/pages/Defaultregister';
// import AdminTopperList from './components/pages/Admin-Topper-List';
// import AdminTopperDetails from './components/pages/Admin-Topper-Details';
import LoginHistory from './components/pages/Login-History';
// import CakeBanner from './components/pages/Cake-Banner';
import StatementOfAccounts from './components/pages/StatementOfAccounts';
import AdminStatementOfAccounts from './components/pages/Admin-StatementOfAccounts';
import AdminStatementDetails from './components/pages/Admin-StatementDetails';
import CakeType from './components/pages/CakeType';
import AdminHampersList from './components/pages/Admin-Hampers-List';
import AdminHampersDetails from './components/pages/Admin-Hampers-Details';
import AdminHamperOrdersList from './components/pages/Admin-Hampers-Order-List';
import AdminHamperOrderDetails from './components/pages/Admin-Hamper-Order-Details';
import NewPeoductDetails from './components/pages/Other-Product-Details';
import AdminOtherOrdersList from './components/pages/Admin-Other-Product-Orders';
import AdminOtherOrdersDetails from './components/pages/Admin-Other-Product-Details';
import BankNameList from './components/pages/BankName';
import AdminInternalUsersList from './components/pages/Admin-InternalUsers-List';
import AdminInternalUserDetails from './components/pages/Admin-InternalUsers-Details';
// import AddVendor from './components/pages/AddVendor';

//Vendor Dashboard - import list
import AddCake from './components/vendor/pages/AddCake';
import VendorCakesList from './components/vendor/pages/Vendor-Cakes-List';
import VendorCakeDetails from './components/vendor/pages/Vendor-Cake-Details';
// import VendorCakeEdit from './components/vendor/pages/Vendor-Cake-Edit';
import Vendorprofile from './components/vendor/pages/vendorprofile';
import VendorOrderList from './components/vendor/pages/VendorOrderList';
import VendorOrderDetails from './components/vendor/pages/VendorOrderDetails';
// import VendorOrderInvoice from './components/vendor/pages/Vendor-Order-Invoice';
import VendorCustomizeCakeList from './components/vendor/pages/Vendor-Customize-Cake-List';
import VendorCustomizeCakeDetails from './components/vendor/pages/Vendor-Customize-Cake-Details';
import VendorCustomizeCakeInvoice from './components/vendor/pages/Vendor-Customize-Cake-Invoice';
import AddTopper from './components/vendor/pages/Add-Topper';
import VendorToppersList from './components/vendor/pages/Vendor-Topper-List';
import VendorTopperDetails from './components/vendor/pages/Vendor-Topper-Details';
import VendorStatementOfAccounts from './components/vendor/pages/Vendor-StatementOfAccounts';
import VendorLoginSession from './components/vendor/pages/Vendor-LoginSession';
import AddHampers from './components/vendor/pages/Add-Hampers';
import VendorHampersList from './components/vendor/pages/Vendor-Hampers-List';
import VendorHamperDetails from './components/vendor/pages/Vendor-Hampers-Details';
import VendorHamperOrdersList from './components/vendor/pages/Vendor-Hamper-Orders-List';
import VendorHamperOrdersDetails from './components/vendor/pages/Vendor-Hamper-Order-Details';
import AddOthers from './components/vendor/pages/Add-Others';
import AddCakeOrOthersMain from './components/vendor/pages/Add-CakeOrOthersMain';
import VendorProductDetails from './components/vendor/pages/Vendor-Other-Product-Details';
import VendorProductOrderDetails from './components/vendor/pages/Vendor-Other-Product-Order-Details';
import VendorCakeUpdate from './components/vendor/pages/Vendor-Cake-Update';
// import VendorReports from './components/vendor/pages/Vendor-Reports';
import LoginTime from './components/vendor/pages/LoginTime';
import OrdersReport from './components/vendor/pages/Orders';
import Payments from './components/vendor/pages/Payments';
import VendorProductEdit from './components/vendor/pages/Vendor-Other-Product-Edit';

//Helpdesk Dashboard - import list
import HelpdeskVOrdersList from './components/HelpdeskV/pages/HelpdeskV-Orders-List';
import HelpdeskVOrderDetails from './components/HelpdeskV/pages/HelpdeskV-Order-Details';
import HelpdeskVVendorsList from './components/HelpdeskV/pages/HelpdeskV-Vendors-List';
import HelpdeskVVendorDetails from './components/HelpdeskV/pages/HelpdeskV-Vendors-Details';
import AdminCakeUpdate from './components/pages/Admin-Cake-Update';
import Chart from './components/pages/Chart';

import HelpdeskVVendorRegister from './components/HelpdeskV/pages/HelpdeskV-Vendors-Registration';
import HelpdeskVNewCakesList from './components/HelpdeskV/pages/HelpdeskV-New-Cakes-List';
import HepldeskVApprovedCakesList from './components/HelpdeskV/pages/HelpdeskV-Approved-Cake-List'
import HepldeskVCakeDetails from './components/HelpdeskV/pages/HelpdeskV-Cake-details'
import HelpdeskVOtherProductsDetails from './components/HelpdeskV/pages/HelpdeskV-Other-Product-details'
import HelpdeskVHampersDetails from './components/HelpdeskV/pages/HelpdeskV-Hampers-details'
import HelpdeskVTicketsList from './components/HelpdeskV/pages/HelpdeskV-Tickets-List'
import HelpdeskVRaiseTicket from './components/HelpdeskV/pages/HelpdeskV-Raise-Ticket'
import HelpdeskVUnclosedTicketsList from './components/HelpdeskV/pages/HelpdeskV-Unclosed-Tickets-List';
import HelpdeskVUnclosedTicketDetails from './components/HelpdeskV/pages/HelpdeskV-Unclosed-Ticket-Details';
import HelpdeskVDeliveryOrdersList from './components/HelpdeskV/pages/HelpdeskV-Delivery-Orders-List';
import HelpdeskVDeliveryOrderDetails from './components/HelpdeskV/pages/HelpdeskV-Delivery-Order-Details';
import HelpdeskVDailyTicketReports from './components/HelpdeskV/pages/HelpdeskV-Daily-Tickets-Report';
import HelpdeskVCakeyDirectGeneralOrder from './components/HelpdeskV/pages/HelpdeskV-Above5-General-CakeOrder-List'
import HelpdeskVCakeyDirectCustomizedCakeOrder from './components/HelpdeskV/pages/HelpdeskV-CakeyDirect-CustomizedCake-List';
import HelpdeskVCakeyDirectCustomizedCakeOrderDetails from './components/HelpdeskV/pages/HelpdeskV-CakeyDirect-CustomizedCake-Details';
import HelpdeskVCakeyDirectGeneralOrderDetails from './components/HelpdeskV/pages/HelpdeskV-CakeyDirect-GeneralOrder-Details';
import CustomizedCakeOrderThroughVendor from './components/HelpdeskV/pages/CustomizedCake-Order-Through-Vendor-List';
import CustomizedCakeOrderThroughVendorDetails from './components/HelpdeskV/pages/CustomizedCake-Order-Through-Vendor-Details';
import VendorRejectedOrdersList from './components/HelpdeskV/pages/Vendor-Rejected-Orders-List';
import VendorRejectedOrderDetails from './components/HelpdeskV/pages/Vendor-Rejected-Order-Details';
import HelpdeskVCakeUpdate from './components/HelpdeskV/pages/HelpdeskV-Cake-Update';
import HelpdeskVChat from './components/HelpdeskV/pages/HelpdeskV-Chat';
// import HelpdeskVCakeyDirectGeneralOrderDetails from './components/HelpdeskV/pages/HelpdeskV-Above5-General-CakeOrder-Details'

import AdminRegister from './components/pages/AdminRegister';

import HelpdeskCTicketsList from './components/HelpdeskC/Pages/Helpdeks-C-Tickets-List';
import HelpdeskCOrdersList from './components/HelpdeskC/Pages/Helpdesk-C-Orders-List';
import HelpdeskCOrderDetails from './components/HelpdeskC/Pages/Helpdesk-C-Order-Details';
import HelpdeskCRaiseTicket from './components/HelpdeskC/Pages/Helpdesk-C-Raise-Ticket';
import HelpdeskCTicketDetails from './components/HelpdeskC/Pages/Helpdesk-C-Ticket-Details';
import HelpdeskCUnclosedTicketsList from './components/HelpdeskC/Pages/Helpdesk-C-Unclosed-Tickets-List';
import HelpdeskCUnclosedTicketDetails from './components/HelpdeskC/Pages/Helpdesk-C-Unclosed-Ticket-Details';
import HelpdeskCDailyTicketsReport from './components/HelpdeskC/Pages/Helpdesk-C-Daily-Tickets-Report';
import HelpdeskCDirectCustomizedCakeOrdersList from './components/HelpdeskC/Pages/Helpdesk-C-Direct-Customized-Cake-Orders-List';
import HelpdeskCDirectCustomizedCakeOrderDetails from './components/HelpdeskC/Pages/Helpdesk-C-Direct-Customized-Cake-Order-Details';
import HelpdeskCCancellationWithin15MinsList from './components/HelpdeskC/Pages/Helpdesk-C-Cancellation-Within-15Mins-List';
import HelpdeskCCancellationWithin15MinsOrderDetails from './components/HelpdeskC/Pages/Helpdesk-C-Cancellation-Within-15Mins-Order-Details';
import HelpdeskCChat from './components/HelpdeskC/Pages/Helpdesk-C-Chat';

import ManagerDeliveryOrdersList from './components/Manager/Pages/Manager-Delivery-Orders-List';
import ManagerDeliveryOrderDetails from './components/Manager/Pages/Manager-Delivery-Orders-Details';
import ManagerCurrentOrdersList from './components/Manager/Pages/Manager-Current-Orders-List';
import ManagerCurrentOrderDetails from './components/Manager/Pages/Manager-Current-Order-Details';
import HelpdeskVHampersList from './components/HelpdeskV/pages/HelpdeskV-Hampers-List';
import HelpdeskVTicketDetails from './components/HelpdeskV/pages/HelpdeskV-Ticket-Details';
import ManagerTicketsList from './components/Manager/Pages/Manager-Tickets-List';
import ManagerTicketDetails from './components/Manager/Pages/Manager-Ticket-Details';
import ManagerUnclosedTicketsList from './components/Manager/Pages/Manager-Unclosed-Tickets-List';
import ManagerUnclosedTicketDetails from './components/Manager/Pages/Manager-Unclosed-Ticket-Details';
import ManagerVendorLoginHistory from './components/Manager/Pages/Manager-Vendor-Login-History';
import ManagerVendorWorkHrsReport from './components/Manager/Pages/Manager-Vendor-Work-Hrs-Report';
import ManagerHelpdeskCLoginHistory from './components/Manager/Pages/Manager-HelpdeskC-Login-History';
import ManagerHelpdeskVLoginHistory from './components/Manager/Pages/Manager-HelpdeskV-Login-History';
import ManagerDailyTicketsReport from './components/Manager/Pages/Manager-Daily-Tickets-Report';
import ManagerInactiveVendorsList from './components/Manager/Pages/Manager-Inactive-Vendors-List';
import ManagerAllTicketsList from './components/Manager/Pages/Manager-All-Tickets-List';
import CustomerRatings from './components/Manager/Pages/Customer_Ratings';
import ManagerMiscellaneousRefundReport from './components/Manager/Pages/Manager-Miscellaneous-Refund-Report';

import AccountsCurrentOrdersList from './components/Accounts/Pages/Accounts-Current-Orders-List';
import AccountsCurrentOrderDetails from './components/Accounts/Pages/Accounts-Current-Order-Details';
import AccountsTicketsList from './components/Accounts/Pages/Accounts-Tickets-List';
import AccountsTicketDetails from './components/Accounts/Pages/Accounts-Ticket-Details';
import AccountsVendorsPaymentStatementList from './components/Accounts/Pages/Accounts-Vendors-PaymentStatement-List';
import AccountsVendorPaymentStatementDetails from './components/Accounts/Pages/Accounts-Vendor-PaymentStatement-Details';
import AccountsVendorPaymentReport from './components/Accounts/Pages/Accounts-Vendor-Payments-Report';
import AccountsCancelledOrdersList from './components/Accounts/Pages/Accounts-Cancelled-Orders-List';
import AccountsRefundReport from './components/Accounts/Pages/Accounts-Refund-Report';
import AccountsSalesReport from './components/Accounts/Pages/Accounts-Sales-Report';
import AccountsMiscellaneousRefundReport from './components/Accounts/Pages/Accounts-Miscellaneous-Refund-Report';

import ManagementDeliveryOrdersList from './components/Management/Pages/Management-Delivery-Orders-List';
import ManagementDeliveryOrderDetails from './components/Management/Pages/Management-Delivery-Order-Details';
import ManagementCurrentOrdersList from './components/Management/Pages/Management-Current-Orders-List';
import ManagementCurrentOrderDetails from './components/Management/Pages/Management-Current-Order-Details';
import ManagementTicketsList from './components/Management/Pages/Management-Tickets-List';
import ManagementTicketDetails from './components/Management/Pages/Management-Ticket-Details';
import ManagementUnclosedTicketsList from './components/Management/Pages/Management-Unclosed-Tickets-List';
import ManagementUnclosedTicketDetails from './components/Management/Pages/Management-Unclosed-Ticket-Details';
import ManagementVendorLoginHistory from './components/Management/Pages/Management-Vendor-Login-History';
import ManagementHelpdeskCLoginHistory from './components/Management/Pages/Management-HelpdeskC-Login-History';
import ManagementHelpdeskVLoginHistory from './components/Management/Pages/Management-HelpdeskV-Login-History';
import ManagementVendorWorkHrsReport from './components/Management/Pages/Management-Vendor-Work-Hrs-Report';
import ManagementHelpdeskCWorkHrsReport from './components/Management/Pages/Management-HekpdeskC-Work-Hrs-Report';
import ManagementHelpdeskVWorkHrsReport from './components/Management/Pages/Management-HelpdeskV-Work-Hrs-Report';
import ManagementSalesCountChart from './components/Management/Pages/Management-Sales-Count-Chart';
import ManagementInactiveVendorsList from './components/Management/Pages/Management-Inactive-vendors-List';
import ManagementAllTicketsList from './components/Management/Pages/Management-All-Tickets-List';
import ManagementMiscellaneousRefundReport from './components/Management/Pages/Management-Miscellaneous-Refund-Report';

import ScreenDDeliveryOrdersList from './components/Screen-D/Pages/Screen-D-Delivery-Orders-List';
import ScreenOCurrentOrdersList from './components/Screen-O/Pages/Screen-O-Current-Orders-List';

import HelpDeskChat from './components/vendor/pages/HelpDeskChat';
import Content from './components/CommonFolders/PaymentGateway/Content';
import ManagerHelpdeskCWorkHours from './components/Manager/Pages/Manager-HelpdeskC-Work-Hrs';
import ManagerHelpdeskVWorkHours from './components/Manager/Pages/Manager-HelpdeskV-Work-Hrs';
import { FileErrorModel } from './components/common/fileErrorModel';
// import AutoCompleteAddress from './components/common/AutoCompleteAddress';
// import Error from './components/pages/Error';

//404 page
// import PageNotFound from './components/common/404';

//Login and dashboard page
const Defaultlogin = lazy(() => import('./components/pages/Defaultlogin'));
const Dashboard = lazy(() => import('./components/pages/Dashboard'));
const VendorDashboard = lazy(() => import('./components/vendor/pages/vendorDashboard'));
const HelpdeskDashboard = lazy(() => import('./components/Helpdesk/pages/Helpdesk-Dashboard'));
const HelpdeskCDashboard = lazy(() => import('./components/HelpdeskC/Pages/Helpdesk-C-Dashboard'));
const ManagerDashboard = lazy(() => import('./components/Manager/Pages/Manager-Dashboard'));
const HelpdeskVDashboard = lazy(() => import('./components/HelpdeskV/pages/HelpdeskV-Dashboard'));
const AccountsDashboard = lazy(() => import('./components/Accounts/Pages/Accounts-Dashboard'));
const ManagementDashboard = lazy(() => import('./components/Management/Pages/Management-Dashboard'));

const PrivacyPolicy = lazy(() => import('./components/sections/Policies/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./components/sections/Policies/RefundPolicy'));
const TermsAndConditions = lazy(() => import('./components/sections/Policies/TermsAndConditions'));

//Auto-login - token verification
if (sessionStorage.getItem('token')) {
  // if (localStorage.getItem('token')) {

  setAuthToken(sessionStorage.getItem('token'));
  // setAuthToken(localStorage.getItem('token'));
  store.dispatch({
    type: actions.VERIFY_TOKEN,
    payload: sessionStorage.getItem('token')
    // payload: localStorage.getItem('token')
  });
  store.dispatch({
    type: actions.UPDATE_AUTH_DETAILS,
    payload: { isAuthenticated: true }
  });

};

function App() {

  const dispatch = useDispatch();

  const { token } = useSelector(state => state.authReducer)
  const { socket } = useSelector(
    state => state.commonReducer
  );

  useEffect(() => {
    if (token?.result?.Email && sessionStorage.getItem('token')) {
      //eslint-disable-next-line
      let data = socket?.emit("adduser", { Email: token?.result?.Email, type: token?.result?.TypeOfUser, _id: token?.result?._id, Id: token?.result?.Id, Name: token?.result?.Name })

      // // get user
      //eslint-disable-next-line
      let data2 = socket?.on("getUser", users => {
        dispatch({ type: commonActions.SET_ONLINE_USERS, payload: users });
      })
    }
    // window.onload = function () {

    //   let sessionStart = Date.now();
    //   let timer = setInterval(function () {
    //     if (Date.now() - sessionStart > 60000) {
    //       clearTimeout(timer);
    //       window.location.reload()
    //       // alert("Session Timed out!");
    //       // window.location = "http://www.example.com/login";
    //     }
    //   }, 1000);


    // };

  }, [token, socket, dispatch])

  // useEffect(() => {
  //   if (sessionStorage.getItem("token")) {
  //     const timerID = setInterval(() => tick(), 60000);
  //     return function stop() {
  //       clearInterval(timerID);
  //     }
  //   }
  //   // eslint-disable-next-line 
  // }, []);

  // const tick = () => {
  //   if (sessionStorage.getItem('token')) {
  //     setD(d = moment().format("DD-MM-YYYY hh:mm A"));
  //     const data = {
  //       token: sessionStorage.getItem('token'),
  //       LastLogout_At: d
  //     };
  //     dispatch({ type: actions.SET_LAST_SEEN, payload: data });
  //     localStorage.setItem('time', d);
  //   }
  // };



  return (
    <>
      <Router >
        <Suspense fallback={<Preloader />}>
          <Switch>
            <ScrollToTop history={useHistory()}>
              <FileErrorModel />

              {/* <PrivateRoute exact path="/default-register" component={Defaultregister} /> */}
              <PublicRoute exact path="/" component={Defaultlogin} />
              <PublicRoute exact path="/login" component={Defaultlogin} />
              <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
              <PublicRoute exact path="/refund-policy" component={RefundPolicy} />
              <PublicRoute exact path="/terms-and-conditions" component={TermsAndConditions} />
              <PrivateRoute exact path="/Chart" component={Chart} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/vendordashboard" component={VendorDashboard} />
              <PrivateRoute exact path='/helpdeskdashboard' component={HelpdeskDashboard} />
              <PrivateRoute exact path='/Helpdesk-C-Dashboard' component={HelpdeskCDashboard} />
              <PrivateRoute exact path='/helpdeskVdashboard' component={HelpdeskVDashboard} />
              <PrivateRoute exact path='/Manager-Dashboard' component={ManagerDashboard} />
              <PrivateRoute exact path='/Accounts-Dashboard' component={AccountsDashboard} />
              <PrivateRoute exact path='/Management-Dashboard' component={ManagementDashboard} />
              <PrivateRoute exact path='/Screen-D-Dashboard' component={ScreenDDeliveryOrdersList} />
              <PrivateRoute exact path='/Screen-O-Dashboard' component={ScreenOCurrentOrdersList} />

              <PrivateRoute exact path="/admin-profile" component={AdminProfile} />
              <PrivateRoute exact path="/customer-list" component={Customerlist} />
              <PrivateRoute exact path='/customer-details' component={CustomerDetails} />
              <PrivateRoute exact path="/orders" component={Orders} />
              <PrivateRoute exact path="/Admin-OrderDetails" component={OrderDetails} />
              <PrivateRoute exact path='/admin-vendor-List' component={AdminVendorList} />
              <PrivateRoute exact path='/admin-vendor-list-detail' component={AdminVendorListDetail} />
              <PrivateRoute exact path='/admin-vendor-detail-edit' component={AdminVendorListEdit} />
              {/* <PrivateRoute exact path="/registered-vendor-list" component={RegisteredVendorList} /> */}
              {/* <PrivateRoute exact path="/registered-vendor-details" component={RegisteredVendorDetails} /> */}
              <PrivateRoute exact path="/new-cakes-list" component={NewCakesList} />
              <PrivateRoute exact path="/new-cake-details" component={NewCakeDetails} />
              <PrivateRoute exact path="/admin-cake-list" component={AdminCakeList} />
              <PrivateRoute exact path="/admin-cake-details" component={AdminCakeDetails} />
              <PrivateRoute exact path="/admin-cake-update" component={AdminCakeUpdate} />
              <PrivateRoute exact path='/Customize-cake-list' component={CustomizeCakeList} />
              <PrivateRoute exact path='/Customize-cake-details' component={CustomizeCakeDetails} />
              {/* <PrivateRoute exact path='/Category-List' component={CategoryList} /> */}
              {/* <PrivateRoute exact path='/add-helpdesk-member' component={AddHelpdeskMember} /> */}
              {/* <PrivateRoute exact path='/helpdesk-members-list' component={HelpdeskMembersList} /> */}
              <PrivateRoute exact path='/cake-design-and-decoration' component={CakeDesignAndDecoration} />
              {/* <PrivateRoute exact path='/Admin-toppers-list' component={AdminTopperList} /> */}
              {/* <PrivateRoute exact path='/Admin-topper-details' component={AdminTopperDetails} /> */}
              <PrivateRoute exact path='/Login-history' component={LoginHistory} />
              {/* <PrivateRoute exact path='/Cake-banner' component={CakeBanner} /> */}
              <PrivateRoute exact path='/Statement-of-accounts' component={StatementOfAccounts} />
              <PrivateRoute exact path='/Admin-statement-of-accounts' component={AdminStatementOfAccounts} />
              <PrivateRoute exact path='/Admin-statement-of-accounts-details' component={AdminStatementDetails} />
              <PrivateRoute exact path='/Admin-cake-types' component={CakeType} />
              <PrivateRoute exact path='/Admin-hamper-list' component={AdminHampersList} />
              <PrivateRoute exact path='/Admin-hamper-details' component={AdminHampersDetails} />
              <PrivateRoute exact path='/Admin-hamper-orders-list' component={AdminHamperOrdersList} />
              <PrivateRoute exact path='/Admin-hamper-order-details' component={AdminHamperOrderDetails} />
              <PrivateRoute exact path='/Admin-product-details' component={NewPeoductDetails} />
              <PrivateRoute exact path='/Admin-product-orders-list' component={AdminOtherOrdersList} />
              <PrivateRoute exact path='/Admin-product-orders-details' component={AdminOtherOrdersDetails} />
              <PrivateRoute exact path='/Admin-bankname-list' component={BankNameList} />
              <PrivateRoute exact path='/Admin-register' component={AdminRegister} />
              <PrivateRoute exact path='/Admin-InternalUsers-List' component={AdminInternalUsersList} />
              <PrivateRoute exact path='/Admin-InternalUser-Details' component={AdminInternalUserDetails} />
              {/* <PrivateRoute exact path="/add-vendor" component={AddVendor} /> */}

              <PrivateRoute exact path="/vendor-profile" component={Vendorprofile} />
              <PrivateRoute exact path="/add-cake" component={AddCake} />
              <PrivateRoute exact path="/vendor-cakes-list" component={VendorCakesList} />
              <PrivateRoute exact path="/Vendor-cake-details" component={VendorCakeDetails} />
              {/* <PrivateRoute exact path='/vendor-cake-edit' component={VendorCakeEdit} /> */}
              <PrivateRoute exact path="/Vendor-OrderList" component={VendorOrderList} />
              <PrivateRoute exact path="/Vendor-OrderDetails" component={VendorOrderDetails} />
              {/* <PrivateRoute exact path="/Vendor-Order-Invoice" component={VendorOrderInvoice} /> */}
              <PrivateRoute exact path='/Vendor-customize-cake-list' component={VendorCustomizeCakeList} />
              <PrivateRoute exact path='/Vendor-customize-cake-details' component={VendorCustomizeCakeDetails} />
              <PrivateRoute exact path='/Vendor-customize-cake-invoice' component={VendorCustomizeCakeInvoice} />
              <PrivateRoute exact path='/Add-topper' component={AddTopper} />
              <PrivateRoute exact path='/Vendor-toppers-list' component={VendorToppersList} />
              <PrivateRoute exact path='/Vendor-topper-details' component={VendorTopperDetails} />
              <PrivateRoute exact path='/Vendor-statementofaccounts' component={VendorStatementOfAccounts} />
              <PrivateRoute exact path='/Vendor-loginhistory' component={VendorLoginSession} />
              <PrivateRoute exact path='/Add-hampers' component={AddHampers} />
              <PrivateRoute exact path='/vendor-hampers-list' component={VendorHampersList} />
              <PrivateRoute exact path='/vendor-hampers-details' component={VendorHamperDetails} />
              <PrivateRoute exact path='/vendor-hamper-orders-list' component={VendorHamperOrdersList} />
              <PrivateRoute exact path='/vendor-hamper-order-details' component={VendorHamperOrdersDetails} />
              <PrivateRoute exact path='/add-others' component={AddOthers} />
              <PrivateRoute exact path='/add-main' component={AddCakeOrOthersMain} />
              <PrivateRoute exact path='/vendor-product-details' component={VendorProductDetails} />
              <PrivateRoute exact path='/vendor-product-order-details' component={VendorProductOrderDetails} />
              <PrivateRoute exact path='/vendor-cake-update' component={VendorCakeUpdate} />
              <PrivateRoute exact path='/helpdeskV-Chat' component={HelpDeskChat} />
              <PrivateRoute exact path='/orders-report' component={OrdersReport} />
              <PrivateRoute exact path='/login-time' component={LoginTime} />
              <PrivateRoute exact path='/payments' component={Payments} />
              <PrivateRoute exact path='/vendor-product-edit' component={VendorProductEdit} />
              {/* <PrivateRoute exact path='/vendor-reports' component={VendorReports} /> */}

              <PrivateRoute exact path='/helpdeskV-order-list' component={HelpdeskVOrdersList} />
              <PrivateRoute exact path='/helpdeskV-order-details' component={HelpdeskVOrderDetails} />
              <PrivateRoute exact path='/helpdeskV-vendors-list' component={HelpdeskVVendorsList} />
              <PrivateRoute exact path='/helpdeskV-vendor-details' component={HelpdeskVVendorDetails} />
              <PrivateRoute exact path='/helpdeskV-vendor-register' component={HelpdeskVVendorRegister} />
              <PrivateRoute exact path='/helpdeskV-New-Cakes-List' component={HelpdeskVNewCakesList} />
              <PrivateRoute exact path='/helpdeskV-Approved-Cakes-List' component={HepldeskVApprovedCakesList} />
              <PrivateRoute exact path='/helpdeskV-Cake-Details' component={HepldeskVCakeDetails} />
              <PrivateRoute exact path='/helpdeskV-Other-Product-Details' component={HelpdeskVOtherProductsDetails} />
              <PrivateRoute exact path='/helpdeskV-Hampers-List' component={HelpdeskVHampersList} />
              <PrivateRoute exact path='/helpdeskV-Hampers-Details' component={HelpdeskVHampersDetails} />
              <PrivateRoute exact path='/helpdeskV-Tickets-List' component={HelpdeskVTicketsList} />
              <PrivateRoute exact path='/helpdeskV-Raise-Ticket' component={HelpdeskVRaiseTicket} />
              <PrivateRoute exact path='/helpdeskV-Ticket-Details' component={HelpdeskVTicketDetails} />
              <PrivateRoute exact path='/helpdeskV-Unclosed-Tickets-List' component={HelpdeskVUnclosedTicketsList} />
              <PrivateRoute exact path='/helpdeskV-Unclosed-Ticket-Details' component={HelpdeskVUnclosedTicketDetails} />
              <PrivateRoute exact path='/helpdeskV-Delivery-Orders-List' component={HelpdeskVDeliveryOrdersList} />
              <PrivateRoute exact path='/helpdeskV-Delivery-Order-Details' component={HelpdeskVDeliveryOrderDetails} />
              <PrivateRoute exact path='/helpdeskV-Daily-Tickets-Report' component={HelpdeskVDailyTicketReports} />
              <PrivateRoute exact path='/helpdeskV-Cakey-Direct-General-Orderlist' component={HelpdeskVCakeyDirectGeneralOrder} />
              <PrivateRoute exact path='/helpdeskV-Cakey-Direct-CustomizedCake-Orderlist' component={HelpdeskVCakeyDirectCustomizedCakeOrder} />
              <PrivateRoute exact path='/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails' component={HelpdeskVCakeyDirectCustomizedCakeOrderDetails} />
              <PrivateRoute exact path='/helpdeskV-Cakey-Direct-General-Orderdetails' component={HelpdeskVCakeyDirectGeneralOrderDetails} />
              <PrivateRoute exact path='/helpdeskV-CustomizedCake-Order-through-Vendor' component={CustomizedCakeOrderThroughVendor} />
              <PrivateRoute exact path='/helpdeskV-CustomizedCake-Order-through-Vendor-Details' component={CustomizedCakeOrderThroughVendorDetails} />
              <PrivateRoute exact path='/helpdeskV-Vendor-Rejected-Orders-List' component={VendorRejectedOrdersList} />
              <PrivateRoute exact path='/helpdeskV-Vendor-Rejected-Order-Details' component={VendorRejectedOrderDetails} />
              <PrivateRoute exact path='/helpdeskV-Cake-Update' component={HelpdeskVCakeUpdate} />
              <PrivateRoute exact path='/helpdeskV-Dashoard-Chat' component={HelpdeskVChat} />

              <PrivateRoute exact path='/Helpdesk-C-Tickets-List' component={HelpdeskCTicketsList} />
              <PrivateRoute exact path='/Helpdesk-C-Orders-List' component={HelpdeskCOrdersList} />
              <PrivateRoute exact path='/Helpdesk-C-Order-Details' component={HelpdeskCOrderDetails} />
              <PrivateRoute exact path='/Helpdesk-C-Raise-Ticket' component={HelpdeskCRaiseTicket} />
              <PrivateRoute exact path='/Helpdesk-C-Ticket-Details' component={HelpdeskCTicketDetails} />
              <PrivateRoute exact path='/Helpdesk-C-Unclosed-Tickets-List' component={HelpdeskCUnclosedTicketsList} />
              <PrivateRoute exact path='/Helpdesk-C-Unclosed-Ticket-Details' component={HelpdeskCUnclosedTicketDetails} />
              <PrivateRoute exact path='/Helpdesk-C-Daily-Tickets-Report' component={HelpdeskCDailyTicketsReport} />
              <PrivateRoute exact path='/Helpdesk-C-Direct-Customized-Cake-Orders-List' component={HelpdeskCDirectCustomizedCakeOrdersList} />
              <PrivateRoute exact path='/Helpdesk-C-Direct-Customized-Cake-Order-Details' component={HelpdeskCDirectCustomizedCakeOrderDetails} />
              <PrivateRoute exact path='/Helpdesk-C-Cancelled-Orders-With-15Mins-List' component={HelpdeskCCancellationWithin15MinsList} />
              <PrivateRoute exact path='/Helpdesk-C-Cancelled-Orders-With-15Mins-Details' component={HelpdeskCCancellationWithin15MinsOrderDetails} />
              <PrivateRoute exact path='/Helpdesk-C-Dashboard-Chat' component={HelpdeskCChat} />

              <PrivateRoute exact path='/Manager-Delivery-Orders-List' component={ManagerDeliveryOrdersList} />
              <PrivateRoute exact path='/Manager-Delivery-Order-Details' component={ManagerDeliveryOrderDetails} />
              <PrivateRoute exact path='/Manager-Current-Order-List' component={ManagerCurrentOrdersList} />
              <PrivateRoute exact path='/Manager-Current-Order-Details' component={ManagerCurrentOrderDetails} />
              <PrivateRoute exact path='/Manager-Tickets-List' component={ManagerTicketsList} />
              <PrivateRoute exact path='/Manager-Ticket-Details' component={ManagerTicketDetails} />
              <PrivateRoute exact path='/Manager-Unclosed-Ticket-List' component={ManagerUnclosedTicketsList} />
              <PrivateRoute exact path='/Manager-Unclosed-Ticket-Details' component={ManagerUnclosedTicketDetails} />
              <PrivateRoute exact path='/Manager-Vendor-Login-History' component={ManagerVendorLoginHistory} />
              <PrivateRoute exact path='/Manager-HelpdeskC-Login-History' component={ManagerHelpdeskCLoginHistory} />
              <PrivateRoute exact path='/Manager-HelpdeskV-Login-History' component={ManagerHelpdeskVLoginHistory} />
              <PrivateRoute exact path='/Manager-Vendor-Work-hrs' component={ManagerVendorWorkHrsReport} />
              <PrivateRoute exact path='/Manager-HelpdeskC-Work-hrs' component={ManagerHelpdeskCWorkHours} />
              <PrivateRoute exact path='/Manager-HelpdeskV-Work-hrs' component={ManagerHelpdeskVWorkHours} />
              <PrivateRoute exact path='/Manager-Daily-Tickets-Report' component={ManagerDailyTicketsReport} />
              <PrivateRoute exact path='/Manager-Inactive-Vendors' component={ManagerInactiveVendorsList} />
              <PrivateRoute exact path='/Manager-All-Tickets-List' component={ManagerAllTicketsList} />
              <PrivateRoute exact path='/Manager-customer-ratings' component={CustomerRatings} />
              <PrivateRoute exact path='/Manager-miscellaneous-refund' component={ManagerMiscellaneousRefundReport} />

              <PrivateRoute exact path='/Accounts-Current-Orders-List' component={AccountsCurrentOrdersList} />
              <PrivateRoute exact path='/Accounts-Current-Order-Details' component={AccountsCurrentOrderDetails} />
              <PrivateRoute exact path='/Accounts-Tickets-List' component={AccountsTicketsList} />
              <PrivateRoute exact path='/Accounts-Ticket-Details' component={AccountsTicketDetails} />
              <PrivateRoute exact path='/Accounts-Vendors-PaymentStatement-List' component={AccountsVendorsPaymentStatementList} />
              <PrivateRoute exact path='/Accounts-Vendor-PaymentStatement-Details' component={AccountsVendorPaymentStatementDetails} />
              <PrivateRoute exact path='/Accounts-Vendor-Payments-Report' component={AccountsVendorPaymentReport} />
              <PrivateRoute exact path='/Accounts-Cancelled-Orders-List' component={AccountsCancelledOrdersList} />
              <PrivateRoute exact path='/Accounts-Refund-Report' component={AccountsRefundReport} />
              <PrivateRoute exact path='/Accounts-Sales-Report' component={AccountsSalesReport} />
              <PrivateRoute exact path='/Accounts-miscellaneous-refund' component={AccountsMiscellaneousRefundReport} />


              <PrivateRoute exact path='/Management-Delivery-Orders-List' component={ManagementDeliveryOrdersList} />
              <PrivateRoute exact path='/Management-Delivery-Order-Details' component={ManagementDeliveryOrderDetails} />
              <PrivateRoute exact path='/Management-Current-Order-List' component={ManagementCurrentOrdersList} />
              <PrivateRoute exact path='/Management-Current-Order-Details' component={ManagementCurrentOrderDetails} />
              <PrivateRoute exact path='/Management-Tickets-List' component={ManagementTicketsList} />
              <PrivateRoute exact path='/Management-Ticket-Details' component={ManagementTicketDetails} />
              <PrivateRoute exact path='/Management-Unclosed-Tickets-List' component={ManagementUnclosedTicketsList} />
              <PrivateRoute exact path='/Management-Unclosed-Ticket-Details' component={ManagementUnclosedTicketDetails} />
              <PrivateRoute exact path='/Management-Vendor-Login-History' component={ManagementVendorLoginHistory} />
              <PrivateRoute exact path='/Management-HelpdeskC-Login-History' component={ManagementHelpdeskCLoginHistory} />
              <PrivateRoute exact path='/Management-HelpdeskV-Login-History' component={ManagementHelpdeskVLoginHistory} />
              <PrivateRoute exact path='/Management-Vendor-Work-hrs' component={ManagementVendorWorkHrsReport} />
              <PrivateRoute exact path='/Management-HelpdeskC-Work-hrs' component={ManagementHelpdeskCWorkHrsReport} />
              <PrivateRoute exact path='/Management-HelpdeskV-Work-hrs' component={ManagementHelpdeskVWorkHrsReport} />
              <PrivateRoute exact path='/Management-Sales-Count-Chart' component={ManagementSalesCountChart} />
              <PrivateRoute exact path='/Management-Inactive-vendors' component={ManagementInactiveVendorsList} />
              <PrivateRoute exact path='/Management-All-Tickets-List' component={ManagementAllTicketsList} />
              <PrivateRoute exact path='/Management-miscellaneous-refund-report' component={ManagementMiscellaneousRefundReport} />

              <PrivateRoute exact path='/Payment-Gateway' component={Content} />

              {/* <Route path="/404" component={PageNotFound} /> */}

              {/* <Route exact path='/autocomplete-address' component={AutoCompleteAddress} /> */}
              {/* <PrivateRoute exact path="/error" component={Error} /> */}

            </ScrollToTop>
          </Switch>
        </Suspense>
      </Router>
    </>

  );
}

export default App;