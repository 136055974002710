import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/CakeArray/actions";

export default function FlavourList({ option, className, handleSelectButton, disabledButton, handleRemoveButton, handleInputValue, show, cakeFlavourList, register, errors,
    selectedFlavour }) {

    const [list, setList] = useState([]);
    const [newFlavour, setNewFlavour] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add flavour status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option?.message === 'No Records Found') {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new flavour functionality
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newFlavour) {
            if (list?.length === 0) {
                dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
            } else {
                const newList = list?.filter(val => {
                    if (val.Name === newFlavour) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
                }
            }
            setNewFlavour('');
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        };
    };

    //validate add new flavour status
    useEffect(() => {
        if (Status.AddNewFlavourStatus !== undefined) {
            if (Status.AddNewFlavourStatus.statusCode === 200) {
                dispatch({ type: actions.GET_FLAVOUR_LIST });
                setNewFlavour('');
            }
        };
    }, [Status, dispatch]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Custom Flavours</label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input type="text" className="form-control text-capitalize" value={newFlavour} onChange={(e) => setNewFlavour(e.target.value)} />
                    <button className='add-button rounded' disabled={newFlavour ? false : true} onClick={handleAddNew}>add</button>
                </div>
            </div>
            <div className="scrollable-list">
                {(show || list.length > 0) ?
                    <>
                        {list?.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>

                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            disabled={(selectedFlavour?.value === value.Name) ? true : false}
                                            // value={value.Name}
                                            // name='CustomFlavourList' {...register('CustomFlavourList')}
                                            onClick={() => handleSelectButton(i, value.Name)}
                                        > + </button>
                                    </> :
                                    <>
                                        <button className='mr-1 minus-button'
                                            // value={value.Name} 
                                            onClick={() => handleRemoveButton(i)}> - </button>
                                        <div className={className}> {value.Name}</div>
                                        <input type="number" onWheel={() => document.activeElement.blur()} placeholder="Rs.300" maxLength={4} onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            } else if (e.target.value < e.target.min) {
                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                            }
                                        }} className="price-input" value={cakeFlavourList?.find(e => e.Name === value.Name)?.Price} onChange={(e) => handleInputValue(e, value.Name)} />
                                    </>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {cakeFlavourList?.map((val, i) =>
                <>
                    {val.Price &&
                        <div className="list-array" key={i}>
                            {val.Name} - {val.Price}
                        </div>
                    }
                </>
            )}
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};