import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import OrderActions from '../../../../redux/Order/actions';
import CommonActions from '../../../../redux/Common/actions';
import { useDispatch, useSelector } from "react-redux";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { float2D, NumberValidation } from "../../../../utils/Validation";

export default function ManagerEditPricePopup(props) {

    const dispatch = useDispatch();

    const { showManagerEditPricePopup, handleCloseManagerEditPricePopup, CustomizedCakeDeatils,
        handleCustomizedCakeEditedPriceByManager, loader } = props;

    const [viewFinalPrice, setViewFinalPrice] = useState(false);

    let [cakeyShare, setCakeyShare] = useState();
    // let [taxValue, setTaxValue] = useState();
    // let [finalTotal, setFinalTotal] = useState();

    const { productShareperList, Tax } = useSelector(state => state.OrderReducer);

    const { Invoice_Calculation } = useSelector(state => state.commonReducer);

    const HelpdeskV_Price = CustomizedCakeDeatils?.HelpdeskV_Price;

    useEffect(() => {
        dispatch({ type: OrderActions.GET_UPDATE_PRODUCT_SHARE_PER_LIST });
        dispatch({ type: OrderActions.GET_TAX });
        //eslint-disable-next-line
    }, []);

    const handleChangeMarginPercentage = (e) => {
        setViewFinalPrice(false);
        if (e.target.value) {
            let Margin = parseFloat(HelpdeskV_Price) * parseFloat(e.target.value || 0) / 100;
            let Final_Total = parseFloat(HelpdeskV_Price) + parseFloat(Margin);
            setCakeyShare({ Percentage: parseFloat(e.target.value), Margin: parseFloat(Margin), Final_Total: Final_Total });
        } else {
            let Final_Total = parseFloat(HelpdeskV_Price);
            setCakeyShare({ Percentage: '', Margin: parseFloat(0), Final_Total: Final_Total });
        }
    };

    useEffect(() => {
        if (productShareperList.length === 0) {
            setCakeyShare({ Percentage: 0, Margin: 0, Final_Total: HelpdeskV_Price });
        } else {
            if (parseInt(productShareperList[0]?.Percentage) === 0) {
                setCakeyShare({ Percentage: productShareperList[0]?.Percentage, Margin: 0, Final_Total: HelpdeskV_Price });
            } else {

            }
            let Margin = parseFloat(HelpdeskV_Price) * parseFloat(productShareperList[0]?.Percentage) / 100;
            let Final_Total = parseFloat(HelpdeskV_Price) + parseFloat(Margin);
            setCakeyShare({ Percentage: parseFloat(productShareperList[0]?.Percentage), Margin: parseFloat(Margin), Final_Total: Final_Total });
        };
    }, [HelpdeskV_Price, productShareperList]);

    const handleCalculateFinalTotal = (e) => {
        e.preventDefault();
        if (cakeyShare?.Percentage?.toString()) {
            setViewFinalPrice(true);
            // let CakePrice, CakeTax, CakeFinalPrice;
            // CakePrice = parseFloat(cakeyShare?.Final_Total) * parseFloat(CustomizedCakeDeatils?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]);
            // if (CustomizedCakeDeatils?.ExtraCharges) {
            //     CakePrice += parseFloat(CustomizedCakeDeatils?.ExtraCharges);
            // };
            // if (Tax?.length === 0) {
            //     setTaxValue(taxValue = 0);
            // } else {
            //     if (parseFloat(Tax[0]?.Total_GST) > 0) {
            //         CakeTax = ((parseFloat(CakePrice) + parseFloat(CustomizedCakeDeatils?.DeliveryCharge)) * parseFloat(Tax[0]?.Total_GST)) / 100;
            //     } else {
            //         CakeTax = 0;
            //     };
            //     setTaxValue(taxValue = CakeTax);
            // };
            // CakeFinalPrice = parseFloat(CakePrice) + parseFloat(taxValue) + parseFloat(CustomizedCakeDeatils?.DeliveryCharge) - parseFloat(CustomizedCakeDeatils?.Discount);
            // setFinalTotal(finalTotal = CakeFinalPrice);
            let formData = {
                Type: "Kg",
                Price: cakeyShare?.Final_Total,
                ItemCount: "1",
                Flavour: CustomizedCakeDeatils?.Flavour,
                Shape: { Name: CustomizedCakeDeatils?.Shape, Price: "0" },
                Weight: CustomizedCakeDeatils?.Weight,
                Discount: CustomizedCakeDeatils?.Discount,
                DeliveryCharge: CustomizedCakeDeatils?.DeliveryCharge,
                Tax: Tax[0]?.Total_GST || "0",
                CalculationFor: "Ticket"
            };
            dispatch({ type: CommonActions.GET_INVOICE_CALCULATION, payload: { type: "CCO", data: formData } })
        }
    };

    const handleClose = (e) => {
        handleCloseManagerEditPricePopup(e);
        setViewFinalPrice(false)
        if (productShareperList.length === 0) {
            setCakeyShare({ Percentage: 0, Margin: 0, Final_Total: HelpdeskV_Price });
        } else {
            if (parseInt(productShareperList[0]?.Percentage) === 0) {
                setCakeyShare({ Percentage: productShareperList[0]?.Percentage, Margin: 0, Final_Total: HelpdeskV_Price });
            } else {

            }
            let Margin = parseFloat(HelpdeskV_Price) * parseFloat(productShareperList[0]?.Percentage) / 100;
            let Final_Total = parseFloat(HelpdeskV_Price) + parseFloat(Margin);
            setCakeyShare({ Percentage: parseFloat(productShareperList[0]?.Percentage), Margin: parseFloat(Margin), Final_Total: Final_Total });
        };
    };

    return (
        <div>
            <Modal show={showManagerEditPricePopup} onHide={handleCloseManagerEditPricePopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseManagerEditPricePopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Add Cakey Margin Percentage per Kg</h4>
                    <div className="form-row">
                        <div className="col-md-12 col-sm-12">
                            <label className='float-left font-weight-bold'>Vendor&apos;s Price per Kg</label>
                            <div className='input-group'>
                                <div className='form-control'>Rs. {CustomizedCakeDeatils?.Vendor_Price}</div>
                            </div>
                        </div>
                        {/* <div className="col-md-12 col-sm-12">
                            <label className='float-left font-weight-bold'>HelpdeskV Price per Kg<span className='text-danger'>*</span></label>
                            <div className='input-group'>
                                <div className='form-control'>Rs. {CustomizedCakeDeatils?.HelpdeskV_Price}</div>
                            </div>
                        </div> */}
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Cake Weight</label>
                            <div className='input-group'>
                                <div className='form-control'>{CustomizedCakeDeatils?.Weight}</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label className='float-left font-weight-bold'>Margin (<span className="text-danger">%</span>)<span className='text-danger'>*</span></label>
                            <div className='input-group'>
                                <input
                                    className={(cakeyShare?.Percentage?.toString()) ? 'form-control' : 'form-control required-field'}
                                    type='number'
                                    onWheel={() => document.activeElement.blur()}
                                    min={0} maxLength={2}
                                    onInput={NumberValidation}
                                    value={cakeyShare?.Percentage}
                                    onChange={handleChangeMarginPercentage}
                                />
                                {/* <div className='form-control'>Rs. {cakeyShare?.Margin} ({cakeyShare?.Percentage} %)</div> */}
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <label className='float-left font-weight-bold'>Final Price per Kg (<span className="text-danger">Margin Per Kg - Rs. {cakeyShare?.Margin || 0}</span>)<span className='text-danger'>*</span></label>
                            <div className='input-group'>
                                <div className='form-control'>Rs. {cakeyShare?.Final_Total || 0}</div>
                                {/* <input
                                    type='number'
                                    className='form-control'
                                    placeholder="Final Price..."
                                    // className={viewButtonValidate ? 'form-control required-field' : 'form-control'}
                                    min={0} maxLength={6}
                                    onInput={(e) => {
                                        e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                        if (e.target.value.length > e.target.maxLength) {
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                        } else if (e.target.value < e.target.min) {
                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                        }
                                    }}
                                    defaultValue={CustomizedCakeDeatils?.Price}
                                // onChange={handleFinalPricePerKg}
                                /> */}
                            </div>
                        </div>
                        {viewFinalPrice &&
                            <div className="col-md-12 col-sm-12">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="bold text-center">Final Price</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price per Kg</th>
                                                <td>{float2D(cakeyShare?.Final_Total)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{CustomizedCakeDeatils?.Weight}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">ExtraCharges</th>
                                                <td>{float2D(Invoice_Calculation?.ExtraCharges)}</td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{float2D(Invoice_Calculation?.DiscountPrice)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td>{float2D(Invoice_Calculation?.DeliveryCharge)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(Invoice_Calculation?.Gst)}</td>
                                                {/* <td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(Invoice_Calculation?.Sgst)}</td>
                                                {/* <td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td><b>{float2D(Invoice_Calculation?.Total)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {(!viewFinalPrice) &&
                            <div className="col-md-12 col-sm-12">
                                <p className='text-success cursor' onClick={handleCalculateFinalTotal}><u>Click here to View Total Cost</u></p>
                            </div>
                        }
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={viewFinalPrice ? false : true}
                                className="btn btn-primary shadow-none mr-3"
                                onClick={(e) => handleCustomizedCakeEditedPriceByManager(e, cakeyShare, (parseFloat(Invoice_Calculation?.Gst) + parseFloat(Invoice_Calculation?.Sgst)), Tax, Invoice_Calculation?.Total)}
                            // onClick={(e) => handleCustomizedCakeEditedPriceByManager(e, cakeyShare, taxValue, Tax, finalTotal)}
                            >
                                Intimate to Customer Helpdesk
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};