import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TicketsActions from '../../../redux/Tickets/actions';
// import CommonActions from '../../../redux/Common/actions';
import UserActions from '../../../redux/users/actions';
import CustomizedCakeActions from '../../../redux/Customize-Cake/actions';
import CakeArrayActions from '../../../redux/CakeArray/actions';
import AccountsActions from '../../../redux/Accounts/actions';
import FlavourList from '../../sections/ArrayMapping/FlavourList';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import FlavoursList from './CakeArray/Flavours';
import ShapesList from './CakeArray/Shapes';
import Breadcrumb from './Breadcrumb';
import Select from 'react-select';
import { useForm } from "react-hook-form";

const customStyle = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
};

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [causeOfTicket, setCauseOfTicket] = useState('');
    const [typeOfOrder, setTypeOfOrder] = useState('');
    const [orderDetails, setOrderDetails] = useState();
    const [userDetails, setUserDetails] = useState();
    const [selectedOrder, setSelectedOrder] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const [loader, setLoader] = useState(false);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(true);

    let [ordersList, setOrdersList] = useState([]);
    let [usersList, setUsersList] = useState([]);
    let [customizedCakeOrders, setCustomizedCakeOrders] = useState([]);
    let [disabledButton, setDisabledButton] = useState([]);
    let [disabledButton2, setDisabledButton2] = useState([]);
    let [cakeFlavourList, setCakeFlavourList] = useState([]);
    let [cakeShapeList, setCakeShapeList] = useState();
    let [cancelledOrdersList, setCancelledOrdersList] = useState([]);
    let [typeOfMiscellaneous, setTypeOfMiscellaneous] = useState('');

    const { AllOrdersList, AllOrderWithDelivered, Ticket_Response } = useSelector(state => state.TicketReducer);

    // const { response } = useSelector(state => state.commonReducer);

    const { registeredUsers } = useSelector(state => state.registeredUserReducer);

    const auth = useSelector((state) => state.authReducer);

    const { CakeyDirectCustomizedCakeOrders } = useSelector(state => state.CustomizeCakeReducer);

    const { OrderCancelledWithin15MinsList } = useSelector(state => state.AccountsReducer);

    const { Flavours, Shapes } = useSelector(state => state.CakeArrayReducer);

    const { register, reset, handleSubmit, formState: { errors }, setValue, clearErrors, setError } = useForm();

    //all orders list assign to state variable
    useEffect(() => {
        let OrdersListForDisplay = [];
        if ((!AllOrdersList && AllOrdersList.length === 0) || (!AllOrderWithDelivered && AllOrderWithDelivered.length === 0)) {
            setOrdersList([]);
        } else {
            if (AllOrdersList?.message === 'No Records Found' || AllOrderWithDelivered?.message === 'No Records Found') {
                setOrdersList([]);
            } else {
                if (causeOfTicket === 'Miscellaneous') {
                    if (typeOfOrder === 'cakeorder' || typeOfOrder === 'productorder' || typeOfOrder === 'hamperorder') {
                        //eslint-disable-next-line
                        AllOrderWithDelivered.map(val => {
                            if (userDetails && userDetails?.value?._id?.toString() === val.UserID) {
                                return OrdersListForDisplay.push({
                                    value: val,
                                    label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status}`
                                });
                            }
                        });
                    } else {
                        AllOrderWithDelivered.map(val => {
                            return OrdersListForDisplay.push({ value: val, label: `${val.Id} - ${val.UserName}` });
                        });
                    }
                    setOrdersList(OrdersListForDisplay);
                } else {
                    if (typeOfOrder === 'cakeorder' || typeOfOrder === 'productorder' || typeOfOrder === 'hamperorder' || causeOfTicket === 'Change Requests') {
                        AllOrdersList.map(val => {
                            return OrdersListForDisplay.push({
                                value: val,
                                label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status}`
                            });
                        });
                    } else {
                        AllOrdersList.map(val => {
                            return OrdersListForDisplay.push({ value: val, label: `${val.Id} - ${val.UserName}` });
                        });
                    }
                    setOrdersList(OrdersListForDisplay);
                }
            }
        };
    }, [AllOrdersList, AllOrderWithDelivered, typeOfOrder, causeOfTicket, userDetails]);

    useEffect(() => {
        let OrdersListForDisplay = [];
        if (!OrderCancelledWithin15MinsList && OrderCancelledWithin15MinsList.length === 0) {
            setCancelledOrdersList([]);
        } else {
            if (OrderCancelledWithin15MinsList?.message === 'No Records Found') {
                setCancelledOrdersList([]);
            } else {
                //eslint-disable-next-line
                OrderCancelledWithin15MinsList.filter(val => {
                    if (val.Ticket_Raised_For_Customer_Cancel === 'No') {
                        return OrdersListForDisplay.push({
                            value: val,
                            label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status_Updated_On}`
                        });
                    }
                });
                setCancelledOrdersList(OrdersListForDisplay);
            }
        };
    }, [OrderCancelledWithin15MinsList]);

    //customers list assign to state variable
    useEffect(() => {
        let UsersListForDisplay = [];
        if (!registeredUsers && registeredUsers.length === 0) {
            setUsersList([]);
        } else {
            if (registeredUsers?.message === 'No Records Found') {
                setUsersList([]);
            } else {
                registeredUsers.map(val => {
                    return UsersListForDisplay.push({
                        value: val,
                        label: `${val.Id} - ${val.PhoneNumber} - ${val.UserName && `${val.UserName}`}`
                    });
                });
                setUsersList(UsersListForDisplay);
            }
        };
    }, [registeredUsers]);

    //cakey direct customized cake assign to state variable
    useEffect(() => {
        let CustomizedCakeListForDisplay = [];
        if (!CakeyDirectCustomizedCakeOrders && CakeyDirectCustomizedCakeOrders.length === 0) {
            setCustomizedCakeOrders([]);
        } else {
            if (CakeyDirectCustomizedCakeOrders?.message === 'No Records Found') {
                setCustomizedCakeOrders([]);
            } else {
                CakeyDirectCustomizedCakeOrders.filter(val => {
                    if (val.Ticket_Raised === 'n' && val.Status !== 'Cancelled') {
                        return CustomizedCakeListForDisplay.push({
                            value: val,
                            label: `${val.Id} - ${val.UserName}`
                        });
                    }
                    return false;
                });
                setCustomizedCakeOrders(CustomizedCakeListForDisplay);
            }
        };
    }, [CakeyDirectCustomizedCakeOrders]);

    //onChange function for cause of ticket
    const handleChangeCauseOfTicket = (e) => {
        setValue('CauseOfTicket', e.target.value);
        clearErrors('CauseOfTicket');
        setCauseOfTicket(e.target.value);
        setValue('TypeOfOrder', '');
        setTypeOfOrder('');
        setOrderDetails();
        setUserDetails();
        if (e.target.value === 'Change Requests') {
            dispatch({ type: TicketsActions.GET_ALL_ORDERS_LIST_BY_TYPE, payload: 'cakeorder' });
            dispatch({ type: CakeArrayActions.GET_ALL_FLAVOURS_LIST });
            dispatch({ type: CakeArrayActions.GET_ALL_SHAPES_LIST });
        } else if (e.target.value === 'Fully Customized Cake') {
            dispatch({ type: TicketsActions.SET_ALL_ORDERS_LIST_BY_TYPE, payload: [] });
            dispatch({ type: CustomizedCakeActions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST });
        } else if (e.target.value === 'Miscellaneous') {
            dispatch({ type: TicketsActions.SET_ALL_ORDERS_LIST_BY_TYPE, payload: [] });
            dispatch({ type: UserActions.GET_ALL_USERS });
        } else if (e.target.value === 'Cancellation Within 15mins') {
            dispatch({ type: AccountsActions.GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS });
        };
    };

    //onChange for Type of order
    const handleChangeTypeOfOrder = (e) => {
        setValue('TypeOfOrder', e.target.value);
        clearErrors('TypeOfOrder');
        setTypeOfOrder(e.target.value);
        setOrderDetails('');
        if (causeOfTicket === 'Miscellaneous') {
            dispatch({ type: TicketsActions.GET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE, payload: e.target.value });
        } else {
            dispatch({ type: TicketsActions.GET_ALL_ORDERS_LIST_BY_TYPE, payload: e.target.value });
        };
    };

    useEffect(() => {
        if (Flavours?.message === 'No Records Found') {
            setShow1(false);
        }
        if (Shapes?.message === 'No Records Found') {
            setShow2(false);
        }
    }, [Flavours, Shapes]);

    //react select onChange
    const handleselectedDetails = (e) => {
        setOrderDetails(e);
        setSelectedOrder(false);
    };

    const handleselectedUserDetails = (e) => {
        setUserDetails(e);
        setSelectedUser(false);
    }

    //add cake flavours
    const handleSelectButton = (value, i) => {
        setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, { Name: value, Price: '0' }]);
        if (disabledButton.length === 0) {
            setDisabledButton(disabledButton = [i]);
        } else {
            setDisabledButton(disabledButton = [...disabledButton, i]);
        }
    };

    //remove cake flavours
    const handleRemoveButton = (i) => {
        //for remove the index value in array
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);
        //for remove the name and price object in array
        const array2 = [...cakeFlavourList];
        let indexValue;
        Flavours.filter((val, ind) => {
            return array2.filter((v, inde) => {
                if (i === ind) {
                    if (val.Name === v.Name) { indexValue = inde }
                }
                return false;
            });
        });
        if (indexValue !== -1) {
            array2.splice(indexValue, 1);
        };
        setCakeFlavourList(array2);
    };

    const handleSelectButton2 = (value, i) => {
        setCakeShapeList(cakeShapeList = { Name: value, Price: '0' });
        setDisabledButton2(disabledButton2 = [i]);
    };

    const handleRemoveButton2 = () => {
        setDisabledButton2([]);
        setCakeShapeList();
    };

    const handleRaiseTicket = (data) => {
        let formData, Accepted_By, Ticket_Raised_For, Last_Intimate;
        (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
            Accepted_By = 'HelpdeskC_Accepted_By' : Accepted_By = 'HelpdeskV_Accepted_By';
        (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
            Ticket_Raised_For = 'Customer' : Ticket_Raised_For = 'Vendor';
        (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
            Last_Intimate = ['HelpdeskC'] : Last_Intimate = ['HelpdeskV'];
        if (causeOfTicket === 'Miscellaneous') {
            if (!typeOfMiscellaneous || (typeOfMiscellaneous === 'Others' && !userDetails) || (typeOfMiscellaneous !== 'Others' && (!userDetails || !orderDetails))) {
                (!typeOfMiscellaneous) ? setError('TypeOfMiscellaneous') : clearErrors('TypeOfMiscellaneous');
                (!userDetails) ? setSelectedUser(true) : setSelectedUser(false);
                if (typeOfMiscellaneous !== 'Others') {
                    (!orderDetails) ? setSelectedOrder(true) : setSelectedOrder(false);
                }
            } else {
                let OrderType, OrderIDs;
                if (typeOfMiscellaneous !== 'Others') {
                    (data.TypeOfOrder === 'cakeorder') ? OrderType = 'Cake Order' : (data.TypeOfOrder === 'productorder') ? OrderType = 'Product Order' :
                        (data.TypeOfOrder === 'hamperorder') ? OrderType = 'Hamper Order' : OrderType = 'Customized Cake Order';
                    OrderIDs = { _id: orderDetails?.value?._id, Id: orderDetails?.value?.Id };
                };
                formData = {
                    TypeOfUser: auth?.token?.result?.TypeOfUser,
                    CauseOfTicket: data.CauseOfTicket,
                    TypeOfOrder: OrderType,
                    TicketDescription: data.TicketDescription,
                    TypeOfMiscellaneous: data.TypeOfMiscellaneous,
                    UserID: userDetails?.value?._id,
                    User_ID: userDetails?.value?.Id,
                    OrderID: OrderIDs?._id,
                    Order_ID: OrderIDs?.Id,
                    Ticket_Raised_For: Ticket_Raised_For,
                    Last_Intimate: Last_Intimate,
                    Ticket_Status: 'In Progress',
                    Ticket_Raised_By: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    [Accepted_By]: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ? {
                        HelpdeskV: 'n', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'n'
                    } : {
                        HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
                    }
                };
                setLoader(true);
                dispatch({ type: TicketsActions.RAISE_NEW_TICKET, payload: formData });
            };
        } else {
            if (!orderDetails) {
                setSelectedOrder(true);
            } else {
                // if (causeOfTicket === 'Miscellaneous') {
                //     formData = {
                //         TypeOfUser: auth?.token?.result?.TypeOfUser,
                //         CauseOfTicket: data.CauseOfTicket,
                //         TicketDescription: data.TicketDescription,
                //         UserID: orderDetails?.value?._id,
                //         User_ID: orderDetails?.value?.Id,
                //         Ticket_Raised_For: Ticket_Raised_For,
                //         Last_Intimate: Last_Intimate,
                //         Ticket_Status: 'In Progress',
                //         Ticket_Raised_By: {
                //             ID: auth?.token?.result?._id,
                //             Name: auth?.token?.result?.Name,
                //             Email: auth?.token?.result?.Email,
                //             TypeOfUser: auth?.token?.result?.TypeOfUser
                //         },
                //         [Accepted_By]: {
                //             ID: auth?.token?.result?._id,
                //             Name: auth?.token?.result?.Name,
                //             Email: auth?.token?.result?.Email,
                //             TypeOfUser: auth?.token?.result?.TypeOfUser
                //         },
                //         Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ? {
                //             HelpdeskV: 'n', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'n'
                //         } : {
                //             HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
                //         }
                //     };
                // } else
                if (causeOfTicket === 'Fully Customized Cake') {
                    (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
                        Accepted_By = 'HelpdeskC_Accepted_By' : Accepted_By = 'HelpdeskV_Accepted_By';
                    (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
                        Ticket_Raised_For = 'Customer' : Ticket_Raised_For = 'Vendor';
                    Last_Intimate = ['HelpdeskV', 'Manager'];
                    let Ticket_Accessed_By = { HelpdeskV: 'y', HelpdeskC: 'y', Manager: 'y', Management: 'n', Accounts: 'n' }
                    formData = {
                        TypeOfUser: auth?.token?.result?.TypeOfUser,
                        CauseOfTicket: data.CauseOfTicket,
                        TicketDescription: data.TicketDescription,
                        TypeOfOrder: 'Customized Cake Order',
                        OrderID: orderDetails?.value?._id,
                        Order_ID: orderDetails?.value?.Id,
                        Order_Status: orderDetails?.Status,
                        VendorID: orderDetails?.value?.VendorID,
                        Vendor_ID: orderDetails?.value?.Vendor_ID,
                        UserID: orderDetails?.value?.UserID,
                        User_ID: orderDetails?.value?.User_ID,
                        Ticket_Raised_For: Ticket_Raised_For,
                        Last_Intimate: Last_Intimate,
                        Ticket_Status: 'In Progress',
                        Ticket_Raised_By: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        [Accepted_By]: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        Ticket_Accessed_By: Ticket_Accessed_By,
                    };
                } else if (causeOfTicket === 'Order Cancellation by Customer' || causeOfTicket === 'Change Requests') {
                    (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
                        Accepted_By = 'HelpdeskC_Accepted_By' : Accepted_By = 'HelpdeskV_Accepted_By';
                    (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
                        Ticket_Raised_For = 'Customer' : Ticket_Raised_For = 'Vendor';
                    Last_Intimate = ['HelpdeskV', 'Manager'];
                    let Ticket_Accessed_By = { HelpdeskV: 'y', HelpdeskC: 'y', Manager: 'y', Management: 'n', Accounts: 'n' }
                    formData = {
                        TypeOfUser: auth?.token?.result?.TypeOfUser,
                        CauseOfTicket: data.CauseOfTicket,
                        TicketDescription: data.TicketDescription,
                        TypeOfOrder: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? 'Cake Order' : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? 'Product Order' :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? 'Hamper Order' : causeOfTicket === 'Change Requests' ?
                                'Cake Order' : 'Customized Cake Order',
                        OrderID: orderDetails?.value?._id,
                        Order_ID: orderDetails?.value?.Id,
                        Order_Status: orderDetails?.Status,
                        ProductID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? orderDetails?.value?.CakeID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? orderDetails?.value?.Other_ProductID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? orderDetails?.value?.HamperID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.CakeID : '',
                        Product_ID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.Cake_ID) ? orderDetails?.value?.Cake_ID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_Product_ID) ? orderDetails?.value?.Other_Product_ID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.Hamper_ID) ? orderDetails?.value?.Hamper_ID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.Cake_ID : '',
                        VendorID: orderDetails?.value?.VendorID,
                        Vendor_ID: orderDetails?.value?.Vendor_ID,
                        UserID: orderDetails?.value?.UserID,
                        User_ID: orderDetails?.value?.User_ID,
                        Ticket_Raised_For: Ticket_Raised_For,
                        Last_Intimate: Last_Intimate,
                        Ticket_Status: 'In Progress',
                        Flavour: cakeFlavourList,
                        Shape: cakeShapeList,
                        Ticket_Raised_By: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        [Accepted_By]: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        Ticket_Accessed_By: Ticket_Accessed_By,
                    };
                } else if (causeOfTicket === 'Cancellation Within 15mins') {
                    Last_Intimate = ['HelpdeskV', 'Accounts'];
                    let Ticket_Accessed_By = { HelpdeskV: 'y', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'y' }

                    formData = {
                        TypeOfUser: auth?.token?.result?.TypeOfUser,
                        CauseOfTicket: data.CauseOfTicket,
                        TicketDescription: data.TicketDescription,
                        TypeOfOrder: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? 'Cake Order' : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? 'Product Order' :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? 'Hamper Order' : causeOfTicket === 'Change Requests' ?
                                'Cake Order' : 'Customized Cake Order',
                        OrderID: orderDetails?.value?._id,
                        Order_ID: orderDetails?.value?.Id,
                        Order_Status: orderDetails?.Status,
                        ProductID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? orderDetails?.value?.CakeID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? orderDetails?.value?.Other_ProductID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? orderDetails?.value?.HamperID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.CakeID : '',
                        Product_ID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.Cake_ID) ? orderDetails?.value?.Cake_ID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_Product_ID) ? orderDetails?.value?.Other_Product_ID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.Hamper_ID) ? orderDetails?.value?.Hamper_ID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.Cake_ID : '',
                        VendorID: orderDetails?.value?.VendorID,
                        Vendor_ID: orderDetails?.value?.Vendor_ID,
                        UserID: orderDetails?.value?.UserID,
                        User_ID: orderDetails?.value?.User_ID,
                        Flavour: cakeFlavourList,
                        Shape: cakeShapeList,
                        Ticket_Raised_For: Ticket_Raised_For,
                        Last_Intimate: Last_Intimate,
                        Ticket_Status: 'In Progress',
                        Ticket_Raised_By: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        [Accepted_By]: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        Ticket_Accessed_By: Ticket_Accessed_By
                    };
                } else {
                    formData = {
                        TypeOfUser: auth?.token?.result?.TypeOfUser,
                        CauseOfTicket: data.CauseOfTicket,
                        TicketDescription: data.TicketDescription,
                        TypeOfOrder: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? 'Cake Order' : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? 'Product Order' :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? 'Hamper Order' : causeOfTicket === 'Change Requests' ?
                                'Cake Order' : 'Customized Cake Order',
                        OrderID: orderDetails?.value?._id,
                        Order_ID: orderDetails?.value?.Id,
                        Order_Status: orderDetails?.Status,
                        ProductID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.CakeID) ? orderDetails?.value?.CakeID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_ProductID) ? orderDetails?.value?.Other_ProductID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.HamperID) ? orderDetails?.value?.HamperID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.CakeID : '',
                        Product_ID: (data.TypeOfOrder === 'cakeorder' || orderDetails?.value?.Cake_ID) ? orderDetails?.value?.Cake_ID : (data.TypeOfOrder === 'productorder' || orderDetails?.value?.Other_Product_ID) ? orderDetails?.value?.Other_Product_ID :
                            (data.TypeOfOrder === 'hamperorder' || orderDetails?.value?.Hamper_ID) ? orderDetails?.value?.Hamper_ID : causeOfTicket === 'Change Requests' ?
                                orderDetails?.value?.Cake_ID : '',
                        VendorID: orderDetails?.value?.VendorID,
                        Vendor_ID: orderDetails?.value?.Vendor_ID,
                        UserID: orderDetails?.value?.UserID,
                        User_ID: orderDetails?.value?.User_ID,
                        Flavour: cakeFlavourList,
                        Shape: cakeShapeList,
                        Ticket_Raised_For: Ticket_Raised_For,
                        Last_Intimate: Last_Intimate,
                        Ticket_Status: 'In Progress',
                        Ticket_Raised_By: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        [Accepted_By]: {
                            ID: auth?.token?.result?._id,
                            Name: auth?.token?.result?.Name,
                            Email: auth?.token?.result?.Email,
                            TypeOfUser: auth?.token?.result?.TypeOfUser
                        },
                        Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ? {
                            HelpdeskV: 'n', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'n'
                        } : {
                            HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
                        }
                    };
                }
                setLoader(true);
                dispatch({ type: TicketsActions.RAISE_NEW_TICKET, payload: formData });
            };
        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        let Path;
        Page === 'HelpdeskC' ? Path = '/Helpdesk-C-Tickets-List' : Path = '/helpdeskV-Tickets-List';
        history.push(Path);
    };

    const handleTypeOfMiscellaneous = (e) => {
        setTypeOfMiscellaneous(e.target.value);
        setValue('TypeOfMiscellaneous', e.target.value);
        clearErrors('TypeOfMiscellaneous');
        // setUserDetails();
        setTypeOfOrder();
        setTypeOfOrder('');
        setValue('TypeOfOrder', '');
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400) {
            setLoader(false);
        } else if (Ticket_Response.statusCode === 200) {
            setLoader(false);
            reset();
            setOrderDetails();
            setSelectedOrder(false);
            setSelectedUser(false);
            setCauseOfTicket('');
            setTypeOfOrder('');
            setUserDetails();
        };
        // eslint-disable-next-line 
    }, [Ticket_Response]);

    // useEffect(() => {
    //     if (response?.alert === true) {
    //         setTimeout(() => {
    //             dispatch({
    //                 type: CommonActions.SET_ALERT,
    //                 payload: { alert: false, message: null, status: null }
    //             });
    //         }, 2000);
    //     };
    //     //eslint-disable-next-line
    // }, [response]);

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb Page={Page} />
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header"><h6>Raise Your Ticket</h6></div>
                        <div className="ms-panel-body">
                            <form className="needs-validation clearfix" noValidate="">
                                <div className="form-row">
                                    <div className={(causeOfTicket && (causeOfTicket === 'Order Cancellation by Customer')) ? "col-xl-6 col-lg-6 col-md-12 mb-3" : "col-xl-12 col-lg-12 col-md-12 mb-3"}>
                                        <label htmlFor="validationCustom18"><b>Cause of Ticket</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select
                                                className={errors.CauseOfTicket ? 'form-control required-field' : 'form-control'}
                                                defaultValue=''
                                                {...register('CauseOfTicket', { required: { value: true, message: 'required' } })}
                                                onChange={handleChangeCauseOfTicket}
                                            >
                                                <option value='' disabled>--Select--</option>
                                                <option value='Order Cancellation by Customer'>Order Cancellation by Customer</option>
                                                <option value='Change Requests'>Change Requests</option>
                                                <option value='Fully Customized Cake'>Fully Customised Cake</option>
                                                <option value='Miscellaneous'>Miscellaneous</option>
                                                <option value='Cancellation Within 15mins'>Cancellation Within 15mins</option>
                                            </select>
                                        </div>
                                    </div>
                                    {(causeOfTicket && (causeOfTicket === 'Order Cancellation by Customer')) &&
                                        // {(causeOfTicket && (causeOfTicket === 'Order Cancellation by Customer' || causeOfTicket === 'Change Requests')) &&
                                        <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Type of Order</b> <span className='text-danger'>*</span></label>
                                            <select
                                                className={errors.TypeOfOrder ? 'form-control required-field' : 'form-control'}
                                                defaultValue=''
                                                {...register('TypeOfOrder', { required: { value: true, message: 'required' } })}
                                                onChange={handleChangeTypeOfOrder}
                                            >
                                                <option value='' disabled>--Select--</option>
                                                <option value='cakeorder'>Cake Order</option>
                                                <option value='productorder'>Product Order</option>
                                                <option value='hamperorder'>Hamper Order</option>
                                                {/* {causeOfTicket === 'Fully Customized Cake' &&
                                                    <option value='customizedcakeorder'>Customized Cake Order</option>
                                                } */}
                                            </select>
                                        </div>
                                    }
                                    {causeOfTicket === 'Miscellaneous' &&
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom18"><b>Type of Miscellaneous</b></label>
                                                <select className={errors?.TypeOfMiscellaneous ? 'form-control required-field' : 'form-control'}
                                                    defaultValue=''
                                                    {...register('TypeOfMiscellaneous', { required: { value: true, message: 'required' } })}
                                                    onChange={handleTypeOfMiscellaneous}
                                                // value={typeOfMiscellaneous}
                                                >
                                                    <option value='' disabled>--Select--</option>
                                                    <option value='Unsatisfied'>Unsatisfied</option>
                                                    <option value='Wrong Order'>Wrong Order</option>
                                                    <option value='Others'>Others</option>
                                                </select>
                                            </div>
                                            {typeOfMiscellaneous &&
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Customers</b> <span className='text-danger'>*</span> (Customer ID - PhoneNumber - Customer Name)</label>
                                                    {registeredUsers.length === 0 ?
                                                        <>
                                                            <select className="form-control" defaultValue=''>
                                                                <option value='' disabled>No Customer Found</option>
                                                            </select>
                                                        </> :
                                                        <Select
                                                            className={selectedUser ? "required-field" : ""}
                                                            styles={customStyle}
                                                            options={usersList}
                                                            closeMenuOnSelect={true}
                                                            isSearchable={true}
                                                            onChange={(e) => handleselectedUserDetails(e)}
                                                            value={userDetails}
                                                        />
                                                    }
                                                </div>
                                            }
                                            {(typeOfMiscellaneous === 'Unsatisfied' || typeOfMiscellaneous === 'Wrong Order') &&
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Type Of Order</b> <span className='text-danger'>*</span></label>
                                                    <select
                                                        className={errors.TypeOfOrder ? 'form-control required-field' : 'form-control'}
                                                        defaultValue=''
                                                        {...register('TypeOfOrder', { required: { value: true, message: 'required' } })}
                                                        onChange={handleChangeTypeOfOrder}
                                                    >
                                                        <option value='' disabled>--Select--</option>
                                                        <option value='cakeorder'>Cake Order</option>
                                                        <option value='productorder'>Product Order</option>
                                                        <option value='hamperorder'>Hamper Order</option>
                                                        {/* {causeOfTicket === 'Fully Customized Cake' &&
                                                    <option value='customizedcakeorder'>Customized Cake Order</option>
                                                } */}
                                                    </select>
                                                </div>
                                            }
                                        </>
                                    }
                                    {causeOfTicket === 'Cancellation Within 15mins' &&
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Orders</b> <span className='text-danger'>*</span> (Order ID - Customer Name - Vendor Name - Cancelled On)</label>
                                            {cancelledOrdersList.length === 0 ?
                                                <>
                                                    <select className="form-control" defaultValue=''>
                                                        <option value='' disabled>No Orders Found</option>
                                                    </select>
                                                </> :
                                                <Select
                                                    className={selectedOrder ? "required-field" : ""}
                                                    styles={customStyle}
                                                    options={cancelledOrdersList}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    onChange={(e) => handleselectedDetails(e)}
                                                    value={orderDetails}
                                                />
                                            }
                                        </div>
                                    }
                                    {causeOfTicket === 'Fully Customized Cake' &&
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Customised Cake Orders</b> <span className='text-danger'>*</span> (Customised Cake ID - Customer Name )</label>
                                            <Select
                                                className={selectedOrder ? "required-field" : ""}
                                                styles={customStyle}
                                                options={customizedCakeOrders}
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                onChange={(e) => handleselectedDetails(e)}
                                                value={orderDetails}
                                            />
                                        </div>
                                    }
                                    {(typeOfOrder || causeOfTicket === 'Change Requests') &&
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Orders</b> <span className='text-danger'>*</span> (Order ID - Customer Name - Vendor Name - Order Status)</label>
                                            {(ordersList.length === 0) ?
                                                <>
                                                    <select className="form-control" defaultValue=''>
                                                        <option value='' disabled>No Orders Found</option>
                                                    </select>
                                                </> :
                                                <Select
                                                    name="Program Owner"
                                                    className={selectedOrder ? "required-field" : ""}
                                                    styles={customStyle}
                                                    options={ordersList}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    onChange={(e) => handleselectedDetails(e)}
                                                    value={orderDetails}
                                                />
                                            }
                                        </div>
                                    }
                                    {/* {(causeOfTicket === 'Cancellation Within 15mins' && orderDetails) &&
                                        <div className='col-md-12 mb-3'>
                                            <div className="ms-panel ms-panel-fh">
                                                <div className="ms-panel-body tabledata-background">
                                                    <h4 className="section-title bold">Selected Order's Details</h4>
                                                    <table className="table ms-profile-information">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Order ID</th>
                                                                <td><b>{orderDetails?.value?.Id}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{orderDetails?.value?.CakeID ? 'Cake Name' : orderDetails?.value?.Other_ProductID ? 'Product Name' : 'Hamper Name'}</th>
                                                                <td>{orderDetails?.value?.CakeID ? orderDetails?.value?.CakeName : orderDetails?.value?.Other_ProductID ? orderDetails?.value?.ProductName : orderDetails?.value?.HampersName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">User Name</th>
                                                                <td>{orderDetails?.value?.UserName}</td>
                                                            </tr>
                                                            {orderDetails?.value?.VendorName &&
                                                                <tr>
                                                                    <th scope="row">Vendor Name</th>
                                                                    <td>{orderDetails?.value?.VendorName}</td>
                                                                </tr>
                                                            }
                                                            {orderDetails?.value?.Total &&
                                                                <tr>
                                                                    <th scope="row">Total Price</th>
                                                                    <td>{orderDetails?.value?.Total}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <th scope="row">Order Date</th>
                                                                <td>{orderDetails?.value?.Created_On}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Cancelled On</th>
                                                                <td>{orderDetails?.value?.Status_Updated_On}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Reason For Cancel</th>
                                                                <td>{orderDetails?.value?.ReasonForCancel}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                    {(causeOfTicket !== 'Miscellaneous' && orderDetails) &&
                                        <div className='col-md-12 mb-3'>
                                            <div className="ms-panel ms-panel-fh">
                                                <div className="ms-panel-body tabledata-background">
                                                    <h4 className="section-title bold">Selected Order's Details</h4>
                                                    <table className="table ms-profile-information">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Order ID</th>
                                                                <td><b>{orderDetails?.value?.Id}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{(typeOfOrder === 'cakeorder' || orderDetails?.value?.CakeName) ? 'Cake Name' : (typeOfOrder === 'productorder' || orderDetails?.value?.ProductName) ? 'Product Name' : (typeOfOrder === 'hamperorder' || orderDetails?.value?.HampersName) ? 'Hamper Name' : 'Customised Cake Name'}</th>
                                                                <td>{(typeOfOrder === 'cakeorder' || orderDetails?.value?.CakeName) ? orderDetails?.value?.CakeName : (typeOfOrder === 'productorder' || orderDetails?.value?.ProductName) ? orderDetails?.value?.ProductName : (typeOfOrder === 'hamperorder' || orderDetails?.value?.HampersName) ? orderDetails?.value?.HampersName : orderDetails?.value?.CakeName}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Customer Name</th>
                                                                <td>{orderDetails?.value?.UserName}</td>
                                                            </tr>
                                                            {orderDetails?.value?.VendorName &&
                                                                <tr>
                                                                    <th scope="row">Vendor Name</th>
                                                                    <td>{orderDetails?.value?.VendorName}</td>
                                                                </tr>
                                                            }
                                                            {(causeOfTicket === 'Change Requests' || causeOfTicket === 'Fully Customized Cake') &&
                                                                <tr>
                                                                    <th scope="row">Flavour</th>
                                                                    <td>
                                                                        <FlavourList
                                                                            FlavoursList={orderDetails?.value?.Flavour}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {causeOfTicket === 'Change Requests' &&
                                                                <tr>
                                                                    <th scope="row">Shape</th>
                                                                    <td>{`${orderDetails?.value?.Shape?.Name} - Additional Rs.${orderDetails?.value?.Shape?.Price}`}</td>
                                                                </tr>
                                                            }
                                                            {orderDetails?.value?.Total &&
                                                                <tr>
                                                                    <th scope="row">Total Price</th>
                                                                    <td>{orderDetails?.value?.Total}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <th scope="row">Order Status</th>
                                                                <td>
                                                                    <span className={(orderDetails?.value?.Status?.toUpperCase())?.replace(/ /g, '')}>{orderDetails?.value?.Status}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Order Date</th>
                                                                <td>{orderDetails?.value?.Created_On}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Delivery Date</th>
                                                                <td>{orderDetails?.value?.DeliveryDate}</td>
                                                            </tr>
                                                            {causeOfTicket === 'Cancellation Within 15mins' &&
                                                                <>
                                                                    <tr>
                                                                        <th scope="row">Cancelled On</th>
                                                                        <td>{orderDetails?.value?.Status_Updated_On}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Reason for Cancel</th>
                                                                        <td>{orderDetails?.value?.ReasonForCancel}</td>
                                                                    </tr>
                                                                </>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(causeOfTicket === 'Change Requests' && orderDetails) &&
                                        <>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <FlavoursList className="option-control"
                                                    option={Flavours} disabledButton={disabledButton} show={show1}
                                                    handleSelectButton={handleSelectButton} handleRemoveButton={handleRemoveButton}
                                                    cakeFlavourList={cakeFlavourList}
                                                />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <ShapesList className="option-control"
                                                    option={Shapes} disabledButton={disabledButton2} show={show2}
                                                    handleSelectButton={handleSelectButton2} handleRemoveButton={handleRemoveButton2}
                                                    cakeShapeList={cakeShapeList}
                                                />
                                            </div>
                                            <p className='text-success ml-1'>Customer can change only cake flavours or cake shape</p>
                                        </>
                                    }
                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Ticket Description</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <textarea
                                                className={errors.TicketDescription ? 'form-control required-field text-capitalize description-textarea' : 'form-control text-capitalize description-textarea'}
                                                placeholder='Type here...'
                                                {...register('TicketDescription', { required: { value: true, message: 'required' } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {causeOfTicket === 'Fully Customized Cake' &&
                                    <div>
                                        <p className="text-success intimation-message">This will be auto intimated to Vendor Helpdesk and Manager</p>
                                    </div>
                                }
                                {(causeOfTicket === 'Order Cancellation by Customer' || causeOfTicket === 'Change Requests') &&
                                    <div>
                                        <p className="text-success intimation-message">This will be auto intimated to Vendor Helpdesk and Manager</p>
                                    </div>
                                }
                                {(causeOfTicket === 'Cancellation Within 15mins') &&
                                    <div>
                                        <p className="text-success intimation-message">This will be auto intimated to Vendor Helpdesk and Accounts</p>
                                    </div>
                                }
                                {loader ?
                                    <CircleDotSpinner /> :
                                    <>
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            onClick={handleSubmit(handleRaiseTicket)}
                                        >
                                            Raise Ticket
                                        </button>
                                        <button
                                            className="btn btn-secondary ml-4"
                                            type="button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};