import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import adminActions from '../../../redux/Admin/actions';
import commonActions from '../../../redux/Common/actions';
import Breadcrumb from "./Breadcrumb";
import { useHistory } from "react-router-dom";
import Preloader from '../../layouts/Preloader';
import helpdeskActions from '../../../redux/Helpdesk/actions';
import ReactPagination from '../../common/React-Pagination';
// import { TbSortAscending } from 'react-icons/tb';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../common/SortingOrders';
import moment from 'moment';

export default function Ordertable() {

    const dispatch = useDispatch();
    const history = useHistory();

    //get above 5kg and below 5kg orders
    const { RecentOrdersY, RecentOrdersN } = useSelector(state => state.adminReducer);

    //get not respond orders
    const { NotRespondOrders } = useSelector(state => state.HelpdeskReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(5);
    const [search2, setsearch2] = useState('');
    const [filteredData2, setFilteredData2] = useState([]);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [postsPerPage2, setPostPerPage2] = useState(5);
    const [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'A5KG' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    const [startDate2, setStartDate2] = useState((Filtered_Dates?.Page === 'B5KG' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    const [filteredData3, setFilteredData3] = useState([]);
    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
        { table: 1, field: "OD", type: "D" },
        { table: 2, field: "OD", type: "D" },
    ]);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: adminActions.GET_ADMIN_RECENTORDER_N, payload: { Above5KG: 'n' } });
        dispatch({ type: adminActions.GET_ADMIN_RECENTORDER_Y, payload: { Above5KG: 'y' } });
        dispatch({ type: helpdeskActions.GET_NOT_RESPOND_ORDERS });
        // eslint-disable-next-line 
    }, []);

    //get search input value - above 5kg
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //get search input value - below 5kg
    const handleSearchbar2 = (e) => {
        setsearch2(e.target.value.toLowerCase());
        setCurrentPage2(0);
    };

    //validate the list is empty or not - above 5kg
    useEffect(() => {
        if (RecentOrdersY.message === 'No Orders') {
            setFilteredData([]);
        } else {
            let FilteredDataWithDate = [];
            if (Filtered_Dates?.Page === 'A5KG' && Filtered_Dates?.StartDate) {
                RecentOrdersY.filter(val => {
                    if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === Filtered_Dates?.StartDate) {
                        return FilteredDataWithDate.push(val);
                    } else {
                        return null;
                    }
                });
            } else {
                FilteredDataWithDate = RecentOrdersY;
            };
            setFilteredData(FilteredDataWithDate.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CakeName && val.CakeName.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.Weight && val.Weight.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [RecentOrdersY, search, Filtered_Dates]);

    //validate the list is empty or not - below 5kg
    useEffect(() => {
        if (RecentOrdersN?.message === 'No Orders') {
            setFilteredData2([]);
        } else {
            let FilteredDataWithDate = [];
            if (Filtered_Dates?.Page === 'B5KG' && Filtered_Dates?.StartDate) {
                RecentOrdersN.filter(val => {
                    if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === Filtered_Dates?.StartDate) {
                        return FilteredDataWithDate.push(val);
                    } else {
                        return null;
                    }
                });
            } else {
                FilteredDataWithDate = RecentOrdersN;
            };
            setFilteredData2(FilteredDataWithDate.filter(val => {
                if (search2 === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search2)) ||
                    (val.CakeName && val.CakeName.toLowerCase().includes(search2)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search2)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search2)) ||
                    (val.Status && val.Status.toLowerCase().includes(search2)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search2))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [RecentOrdersN, search2, Filtered_Dates]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const indexOfFirstPost2 = (currentPage2 * postsPerPage2) % filteredData2?.length;
    const indexOfLastPost2 = indexOfFirstPost2 + postsPerPage2;
    const currentdata2 = filteredData2.slice(indexOfFirstPost2, indexOfLastPost2);

    // Change page
    const paginate2 = (event) => {
        setCurrentPage2(event.selected);
    };

    const handleShowPerPage2 = (e) => {
        setPostPerPage2(parseInt(e.target.value));
        setCurrentPage2(0);
    };

    useEffect(() => {
        if (NotRespondOrders?.message === 'No Orders') {
            setFilteredData3([]);
        } else {
            // eslint-disable-next-line
            setFilteredData3(NotRespondOrders.filter(val => {
                if (val.VendorID) { return val }
            }));
        };
    }, [NotRespondOrders]);

    //view details page
    function ViewOrderDetails(order) {
        history.push('/Admin-OrderDetails', { orders: order, page: 'orderlist', OrderId: order._id });
    };

    const handleSelectedDate = (e) => {
        setStartDate(e.target.value);
        setStartDate2('');
        setCurrentPage(0);
        if (e.target.value) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'A5KG' } });
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, Page: null } });
        };
        let newArray = sorting;
        newArray[0].field = 'OD';
        newArray[0].type = 'D';
        setSorting(newArray);
    };

    const handleSelectedDate2 = (e) => {
        setStartDate2(e.target.value);
        setStartDate('');
        setCurrentPage2(0);
        if (e.target.value) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'B5KG' } });
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, Page: null } });
        };
        let newArray = sorting;
        newArray[1].field = 'OD';
        newArray[1].type = 'D';
        setSorting(newArray);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: adminActions.GET_ADMIN_RECENTORDER_N, payload: { Above5KG: 'n' } });
        dispatch({ type: adminActions.GET_ADMIN_RECENTORDER_Y, payload: { Above5KG: 'y' } });
        dispatch({ type: helpdeskActions.GET_NOT_RESPOND_ORDERS });
        setSorting([
            { table: 0, field: "OD", type: "D" },
            { table: 1, field: "OD", type: "D" },
            { table: 2, field: "OD", type: "D" }
        ]);
        setStartDate();
        setStartDate2();
        setsearch('');
        setsearch2('');
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        (table === 0) ? filterData = filteredData : (table === 1) ? filterData = filteredData2 : filterData = filteredData3;
        let result = SortingOrders({ type, field, filterData });
        (table === 0) ? setFilteredData(result) : (table === 1) ? setFilteredData2(result) : setFilteredData3(result);
    };

    //loading
    useEffect(() => {
        if (RecentOrdersY.length === 0 || RecentOrdersN.length === 0 || NotRespondOrders.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
        // eslint-disable-next-line
    }, [RecentOrdersY, RecentOrdersN, NotRespondOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'> Orders List - Above 5KG and Premium Orders</h6>
                                    <span className="float-right add-banner-button col-sm-3 col-md-3 mb-2">
                                        <div id="data-table-4_filter" className="dataTables_filter">
                                            <input
                                                type='date'
                                                maxLength={4}
                                                max={"9999-12-31"}
                                                className="form-control mt-4"
                                                disabled={RecentOrdersY?.message === 'No Orders' ? true : false}
                                                aria-controls="data-table-4"
                                                onChange={handleSelectedDate}
                                                value={startDate || ''}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input
                                                                type='Search'
                                                                className="form-control form-control-sm"
                                                                placeholder="type here to search..."
                                                                onChange={handleSearchbar}
                                                                value={search || ''}
                                                                aria-controls="data-table-4"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table table-hover thead-primary">
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Cake Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                {/* <th scope="col">Order Type</th> */}
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Cake Weight</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((order) =>
                                                                    <tr className='odd cursor' onClick={() => ViewOrderDetails(order)} key={order._id}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td>{order.CakeName ? order.CakeName : 'Customised Cake'}</td>
                                                                        <td> {order.UserName}</td>
                                                                        {/* <td>
                                                                            <span className={order.Above5KG === 'y' ? 'Regular' : 'Premium'}>
                                                                                {order.Above5KG === 'y' ? 'Above 5Kg' : 'Premium order'}
                                                                            </span>
                                                                        </td> */}
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        <td>
                                                                            <span className={(order.Status.toUpperCase()).replace(/ /g, '')}>
                                                                                {order.Status}
                                                                            </span>
                                                                        </td>
                                                                        <td className='price-align3-row'>{order.Weight}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={8} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'> Orders List - Upto 5 Kg</h6>
                                    <span className="float-right add-banner-button col-sm-3 col-md-3 mb-2">
                                        <div id="data-table-4_filter" className="dataTables_filter">
                                            <input
                                                type='date'
                                                maxLength={4}
                                                max={"9999-12-31"}
                                                className="form-control form-control mt-4"
                                                disabled={RecentOrdersN?.message === 'No Orders' ? true : false}
                                                aria-controls="data-table-4"
                                                onChange={handleSelectedDate2}
                                                value={startDate2 || ''}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage2}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input
                                                                type='Search'
                                                                className="form-control form-control-sm"
                                                                placeholder="type here to search..."
                                                                onChange={handleSearchbar2}
                                                                value={search2 || ''}
                                                                aria-controls="data-table-4"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table table-hover thead-primary">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Cake Name</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 1)}><BsArrowUp className={`sort-up-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'OD' && sorting[1]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'OD' && sorting[1]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 1)}><BsArrowUp className={`sort-up-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'DD' && sorting[1]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'DD' && sorting[1]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData2.length > 0 ?
                                                            <tbody>
                                                                {currentdata2.map((order) =>
                                                                    <tr className='cursor' onClick={() => ViewOrderDetails(order)} key={order._id}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td>
                                                                            <span className={order.CakeName ? '' : 'font-weight-bold'}>
                                                                                {order.CakeName ? order.CakeName : 'Customised Cake'}
                                                                            </span>
                                                                        </td>
                                                                        <td>{order.VendorName}</td>
                                                                        <td> {order.UserName}</td>
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        <td className='price-align3-row'>
                                                                            <span className={(order.Status.toUpperCase()).replace(/ /g, '')}>
                                                                                {order.Status}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData2.length > postsPerPage2 &&
                                        <ReactPagination
                                            paginate={paginate2}
                                            pageCount={Math.ceil(filteredData2?.length / postsPerPage2)}
                                            currentPage={currentPage2}
                                            currentdata={currentdata2}
                                            totalPosts={filteredData2.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6> Orders List - No Response from Vendors</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table table-hover thead-primary">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Cake Name</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 2)}><BsArrowUp className={`sort-up-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'OD' && sorting[2]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'OD' && sorting[2]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 2)}><BsArrowUp className={`sort-up-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'DD' && sorting[2]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'DD' && sorting[2]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData3.length !== 0 ?
                                                            <tbody>
                                                                {filteredData3.map((order) =>
                                                                    <tr className='cursor' onClick={() => ViewOrderDetails(order)} key={order._id}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td><span className={order.CakeName ? '' : 'font-weight-bold'}>{order.CakeName ? order.CakeName : 'Customised Cake'}</span></td>
                                                                        <td>
                                                                            <span className={order.VendorName !== undefined ? '' :
                                                                                order.Above5KG === 'y' ? 'Regular' : 'Premium'}
                                                                            >
                                                                                {order.VendorName !== undefined ? order.VendorName :
                                                                                    order.Above5KG === 'y' ? 'Above 5Kg' : 'Premium order'
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td> {order.UserName}</td>
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        <td className='price-align3-row'>
                                                                            <span className={(order.Status?.toUpperCase())?.replace(/ /g, '')}>
                                                                                {order.Status}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};