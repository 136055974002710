import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import CakeActions from '../../../../redux/cake/actions';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import ShapesWithWeightList from '../../../sections/ArrayMapping/ShapesWithWeightList';
import WeightList from '../../../sections/ArrayMapping/WeightList';
import Information from '../../../sections/ArrayMapping/Information';
import CakeTypeLists from '../../../sections/ArrayMapping/CakeType';
import CakeImageSlider from './CakeImageSlider';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const CakeId = state?.state?.cakeId;

    const [show, setShow] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ReasonForSuspend, setReasonForSuspend] = useState();
    const [sliderImages, setSliderImages] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    //get status for delete cake
    const { CakeDetails, DeleteCakeStatus } = useSelector(state => state.CakesReducer);

    const auth = useSelector(state => state.authReducer);

    const AdditionalCakeImages = CakeDetails?.AdditionalCakeImages;
    const MainCakeImage = CakeDetails?.MainCakeImage;

    useEffect(() => {
        dispatch({ type: CakeActions.GET_SINGLE_CAKEDETAILS, payload: CakeId });
        // eslint-disable-next-line 
    }, [CakeId]);

    useEffect(() => {
        if (AdditionalCakeImages?.length > 0 || AdditionalCakeImages !== undefined) {
            setSliderImages([...AdditionalCakeImages, MainCakeImage]);
        } else {
            setSliderImages([MainCakeImage]);
        };
    }, [AdditionalCakeImages, MainCakeImage]);

    //redirect to edit cake page
    const UpdateCakeDetails = () => {
        history.push('/vendor-cake-update', { CakeId: CakeDetails?._id });
    };

    // open cake delete popup
    const handleDeletePopup = () => {
        setShow(true);
        setShowErr(false)
    };

    // cake delete functionality
    const handleDelete = (e) => {
        e.preventDefault();
        if (!ReasonForSuspend) {
            setShowErr(true)
        } else {
            setLoader(true);
            setShowErr(false);
            dispatch({
                type: CakeActions.DELETE_CAKE,
                payload: { id: state.state.cakeId, ReasonForSuspend: ReasonForSuspend }
            });
        }
    };

    // close cake delete popup
    const handleClose = () => { setShow(false) };

    // validate delete cake status
    useEffect(() => {
        if (DeleteCakeStatus?.statusCode === 200 || DeleteCakeStatus?.statusCode === 400) {
            setLoader(false);
            setShow(false);
        }
    }, [DeleteCakeStatus]);

    useEffect(() => {
        (CakeDetails?.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CakeDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6 >Cake Details</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <CakeImageSlider image={sliderImages} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {CakeDetails?.Status === "Suspended" ?
                                                <tr>
                                                    <th scope="row" className="text-danger">Reason for Suspend</th>
                                                    <td><b>{CakeDetails?.ReasonForSuspend}</b></td>
                                                </tr>
                                                : <></>}

                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CakeDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Common Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeCommonName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Type</th>
                                                <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeType} /></span></td>
                                            </tr>
                                            {CakeDetails?.CakeSubType?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Cake SubType</th>
                                                    <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeSubType} /></span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Default Cake Egg Or Eggless</th>
                                                <td>{CakeDetails?.DefaultCakeEggOrEggless}</td>
                                            </tr>
                                            {auth?.token?.result?.EggOrEggless !== 'Eggless' || auth?.token?.result?.EggOrEggless !== 'Egg' ?
                                                <>
                                                    {CakeDetails?.DefaultCakeEggOrEggless !== 'Eggless' &&
                                                        <tr>
                                                            <th scope="row">Is Eggless Option available</th>
                                                            <td>{CakeDetails?.IsEgglessOptionAvailable === 'y' ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                    }
                                                    {(CakeDetails?.BasicEgglessCostPerKg && CakeDetails?.IsEgglessOptionAvailable === 'y') &&
                                                        <tr>
                                                            <th scope="row">Basic Eggless Cake cost per kg</th>
                                                            <td>{`Rs.${CakeDetails?.BasicEgglessCostPerKg}`}</td>
                                                        </tr>
                                                    }

                                                </>
                                                : <></>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Basic Flavour</th>
                                                <td><span className="long-line-break">{CakeDetails?.BasicFlavour}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Weight</th>
                                                <td>{CakeDetails?.MinWeight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Shape</th>
                                                <td>{CakeDetails?.BasicShape}</td>
                                            </tr>
                                            {CakeDetails?.CustomFlavourList?.length > 0 ?
                                                <tr>
                                                    <th scope="row">Custom Flavour</th>
                                                    <td><span className="long-line-break"><FlavourList FlavoursList={CakeDetails?.CustomFlavourList} /></span></td>
                                                </tr> : <tr></tr>
                                            }
                                            {CakeDetails?.MinWeightList?.length > 0 ?
                                                <tr>
                                                    <th scope="row">Weight List</th>
                                                    <td><span className="long-line-break"><WeightList WeightLists={CakeDetails?.MinWeightList} /></span></td>
                                                </tr> : <tr></tr>
                                            }
                                            {CakeDetails?.CustomShapeList?.Info?.length > 0 ?
                                                <tr>
                                                    <th scope="row">Custom Shape</th>
                                                    <td><span className="long-line-break"><ShapesWithWeightList ShapesList={CakeDetails?.CustomShapeList} /></span></td>
                                                </tr> : <tr></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td>{`Rs.${float2D(CakeDetails?.BasicCakePrice)}`}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{CakeDetails?.Discount}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Created On</th>
                                                <td>{CakeDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Availability</th>
                                                <td><span className={(CakeDetails?.Stock?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Stock}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CakeDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Status}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                       
                        {CakeDetails?.CustomShapeList?.SampleImages?.length > 0 ?
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6 >Sample Images for Shapes</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <CakeImageSlider image={CakeDetails?.CustomShapeList?.SampleImages} />
                                    </div>
                                </div>
                            </div> : <></>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">OTHER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Minimum Time For Delivery Of Default Cake({CakeDetails?.MinWeight})</th>
                                                <td>{CakeDetails?.MinTimeForDeliveryOfDefaultCake}</td>
                                            </tr>
                                            {CakeDetails?.MinTimeForDeliveryOfABelow2KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 0.5Kg to 2Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfABelow2KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA2to4KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 2Kg to 4Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA2to4KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA4to5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 4Kg to 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA4to5KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of Above 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cake Base</th>
                                                <td>{CakeDetails?.CakeBase}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Cream</th>
                                                <td>{CakeDetails?.CakeCream}</td>
                                            </tr>
                                            {CakeDetails?.ButterCreamType &&
                                                <tr>
                                                    <th scope="row">Butter Cream Type</th>
                                                    <td>{CakeDetails?.ButterCreamType}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Best before </th>
                                                <td>{CakeDetails?.BestUsedBefore}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">To Be Stored In</th>
                                                <td>{CakeDetails?.ToBeStoredIn}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Time at Room Temperature before use</th>
                                                <td>{CakeDetails?.KeepTheCakeInRoomTemperature}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Theme Cake Possible</th>
                                                <td>{CakeDetails?.ThemeCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Toppers Possible</th>
                                                <td>{CakeDetails?.ToppersPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Customisation Possible</th>
                                                <td>{CakeDetails?.BasicCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Full Customisation Possibility</th>
                                                <td>{CakeDetails?.FullCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How good are you in this Cake</th>
                                                <td>{CakeDetails?.HowGoodAreYouWithTheCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How many times have you Baked this Particular Cake</th>
                                                <td>{CakeDetails?.HowManyTimesHaveYouBakedThisParticularCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Is Tier Cake Possible</th>
                                                <td>{CakeDetails?.IsTierCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CakeDetails?.OtherInstructions &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">OTHER INSTRUCTIONS FOR CUSTOMERS</h4>
                                        <hr />
                                        <p>{CakeDetails?.OtherInstructions}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {CakeDetails?.SendInformation?.length > 0 &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Information From Admin</h4>
                                        <hr />
                                        <Information InfoList={CakeDetails?.SendInformation} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                                    <hr />
                                    <p>{CakeDetails?.Description}</p>
                                    {CakeDetails?.Status !== "Suspended" ?
                                        <div className='cake-details-button'>
                                            <button className="btn btn-primary ml-3" type="button" onClick={UpdateCakeDetails} >Edit</button>
                                            {/* <button className={CakeDetails?.Status === "New" ? "btn btn-primary ml-3 new-disabled" : "btn btn-primary ml-3"} type="button" disabled={CakeDetails?.Status === "New" ? true : false} onClick={UpdateCakeDetails} >Edit</button> */}
                                            <button className="btn btn-warning ml-3 " type="button" onClick={handleDeletePopup}>Suspend</button>
                                            <button className="btn btn-secondary ml-3 " type="button" onClick={() => history.push('/vendor-cakes-list')}>Close</button>
                                            {/* <button className="btn btn-primary EditButton ml-3" type="button" onClick={UpdateCakeDetails} >Edit</button> */}
                                            {/* <button className="btn btn-secondary ml-3 float-right" type="button" onClick={() => history.push('/vendor-cakes-list')} >Close</button>  */}
                                        </div> :
                                        <div className='cake-details-button  mt-3'>
                                            <button className="btn btn-secondary " type="button" onClick={() => history.push('/vendor-cakes-list')}>Close</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* delete cake Confirmation popup  */}
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className='tabledata-background'>
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <div>
                                <div>
                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Reason For Suspend<span className='text-danger'> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"} name='ReasonForSuspend' id="validationCustom24" placeholder="Enter the reason..." onChange={(e) => setReasonForSuspend(e.target.value)} />
                                        <p className='text-danger'>{showErr}</p>
                                    </div>
                                    <p className="text-danger mt-4">Note: If you suspend this cake it will not be displayed to customers</p>
                                </div>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleDelete}
                                        >
                                            Ok
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }

                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
}
