import React from 'react';
// import TierCakeDeliveryTime from './CakeArray/TierCakeDeliveryTime';
// import TierCakeWeightAndPrice from './CakeArray/TierCakeWeightandPrice';

function BodyContent2({
    errors, register, handleShowTierCakeField, showTierCakeField, handleGetSelectedCakeCream, handleGetSelectedCakeBase,
    // disabledButton5,handleRemoveButton6, handleInputValue5,
    // handleSelectButton5, handleRemoveButton5, handleInputValue4, disabledButton6, handleSelectButton6,
    // handleBasicCustomization, basicCustomizationValidate
    showButterCreamField, CakeCreamArray, CakeBaseArray, minWeight, cakeCreamValidate, CakeBaseValidate, tierFieldValidation }) {

    // const TierList = ['2 Tier', '3 Tier', '4 Tier'];

    return (
        <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
                <h6>Other Details</h6>
            </div>
            <div className="ms-panel-body tabledata-background">
                {/* <form className="needs-validation clearfix" noValidate> */}
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom18" className='font-weight-bold'>Minimum Time For Delivery Of Default Cake {minWeight?.value} <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.MinTimeForDeliveryOfDefaultCake ? "form-control required-field" : "form-control"} name='MinTimeForDeliveryOfDefaultCake' {...register('MinTimeForDeliveryOfDefaultCake')} >
                                <option value="">--select--</option>
                                {/* <option value="N/A">Not Applicable</option> */}
                                <option value="1 day">1 day</option>
                                <option value="2 days">2 days</option>
                                <option value="3 days">3 days</option>
                                <option value="4 days">4 days</option>
                                <option value="5 days">5 days</option>
                                <option value="6 days">6 days</option>
                                <option value="7 days">7 days</option>
                                <option value="8 days">8 days</option>
                                <option value="9 days">9 days</option>
                                <option value="10 days">10 days</option>
                            </select>
                            {/* <div className='display-flex w-100'>
                                <input type="number" min={0} maxLength={2} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className={errors?.MinTimeForDeliveryOfDefaultCake ? "form-control required-field" : "form-control"} name='MinTimeForDeliveryOfDefaultCake' {...register('MinTimeForDeliveryOfDefaultCake')} placeholder="e.g. 2" />
                                <select className={errors?.MinTimeForDeliveryOfDefaultCake ? "form-control w-50 required-field" : "form-control w-50"} name='MinTimeForDeliveryOfDefaultCakeUnit' {...register('MinTimeForDeliveryOfDefaultCakeUnit')} defaultValue='days' id="validationCustom22" >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom24" className='font-weight-bold'>Minimum Time For Delivery Of 0.5Kg - 2Kg Cake<span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.MinTimeForDeliveryOfABelow2KgCake ? "form-control required-field" : "form-control w-50"} id="validationCustom22" name='MinTimeForDeliveryOfABelow2KgCake' {...register('MinTimeForDeliveryOfABelow2KgCake')} >
                                <option value="">--select--</option>
                                <option value="N/A">Not Applicable</option>
                                <option value="1 day">1 day</option>
                                <option value="2 days">2 days</option>
                                <option value="3 days">3 days</option>
                                <option value="4 days">4 days</option>
                                <option value="5 days">5 days</option>
                                <option value="6 days">6 days</option>
                                <option value="7 days">7 days</option>
                                <option value="8 days">8 days</option>
                                <option value="9 days">9 days</option>
                                <option value="10 days">10 days</option>
                            </select>
                            {/* <div className='display-flex w-100'>
                                <input type="number" min={0} maxLength={2} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className="form-control" id="validationCustom24" placeholder="e.g. 2" name='MinTimeForDeliveryOfA1KgCake' {...register('MinTimeForDeliveryOfA1KgCake')} />
                                <select className="form-control w-50" defaultValue='days' id="validationCustom22" name='MinTimeForDeliveryOfA1KgCakeUnit' {...register('MinTimeForDeliveryOfA1KgCakeUnit')} >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div> */}

                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom24" className='font-weight-bold'>Minimum Time For Delivery Of 2Kg - 4Kg Cake<span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.MinTimeForDeliveryOfA2to4KgCake ? "form-control required-field" : "form-control w-50"} id="validationCustom22" name='MinTimeForDeliveryOfA2to4KgCake' {...register('MinTimeForDeliveryOfA2to4KgCake')} >
                                <option value="">--select--</option>
                                <option value="N/A">Not Applicable</option>
                                <option value="1 day">1 day</option>
                                <option value="2 days">2 days</option>
                                <option value="3 days">3 days</option>
                                <option value="4 days">4 days</option>
                                <option value="5 days">5 days</option>
                                <option value="6 days">6 days</option>
                                <option value="7 days">7 days</option>
                                <option value="8 days">8 days</option>
                                <option value="9 days">9 days</option>
                                <option value="10 days">10 days</option>
                            </select>
                        </div>
                        {/* <div className="input-group">
                            <div className='display-flex w-100'>
                                <input type="number" min={0} maxLength={2} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className="form-control" id="validationCustom24" placeholder="e.g. 2" name='MinTimeForDeliveryOfA2KgCake' {...register('MinTimeForDeliveryOfA2KgCake')} />
                                <select className="form-control w-50" defaultValue='days' id="validationCustom22" name='MinTimeForDeliveryOfA2KgCakeUnit' {...register('MinTimeForDeliveryOfA2KgCakeUnit')} >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div>
                        </div> */}

                    </div>
                    {/* {/ {showMinTimeField.includes(3) && /} */}
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom24" className='font-weight-bold'>Minimum Time For Delivery Of 4Kg - 5Kg Cake<span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.MinTimeForDeliveryOfA4to5KgCake ? "form-control required-field" : "form-control w-50"} id="validationCustom22" name='MinTimeForDeliveryOfA4to5KgCake' {...register('MinTimeForDeliveryOfA4to5KgCake')} >
                                <option value="">--select--</option>
                                <option value="N/A">Not Applicable</option>
                                <option value="1 day">1 day</option>
                                <option value="2 days">2 days</option>
                                <option value="3 days">3 days</option>
                                <option value="4 days">4 days</option>
                                <option value="5 days">5 days</option>
                                <option value="6 days">6 days</option>
                                <option value="7 days">7 days</option>
                                <option value="8 days">8 days</option>
                                <option value="9 days">9 days</option>
                                <option value="10 days">10 days</option>
                            </select>

                            {/* <div className='display-flex w-100'>
                                <input type="number" min={0} maxLength={2} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className="form-control" id="validationCustom24" placeholder="e.g. 2" name='MinTimeForDeliveryOfA3KgCake' {...register('MinTimeForDeliveryOfA3KgCake')} />
                                <select className="form-control w-50" defaultValue='days' id="validationCustom22" name='MinTimeForDeliveryOfA3KgCakeUnit' {...register('MinTimeForDeliveryOfA3KgCakeUnit')} >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div> */}
                        </div>
                    </div>
                    {/* {/ } /}
                    {/ {showMinTimeField.includes(5) && /} */}
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Time For Delivery Of Above 5Kg Cake <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.MinTimeForDeliveryOfAAbove5KgCake ? "form-control required-field" : "form-control w-50"} id="validationCustom22" name='MinTimeForDeliveryOfAAbove5KgCake' {...register('MinTimeForDeliveryOfAAbove5KgCake')} >
                                <option value="">--select--</option>
                                <option value="N/A">Not Applicable</option>
                                <option value="1 day">1 day</option>
                                <option value="2 days">2 days</option>
                                <option value="3 days">3 days</option>
                                <option value="4 days">4 days</option>
                                <option value="5 days">5 days</option>
                                <option value="6 days">6 days</option>
                                <option value="7 days">7 days</option>
                                <option value="8 days">8 days</option>
                                <option value="9 days">9 days</option>
                                <option value="10 days">10 days</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Cake Base <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.CakeBase && !CakeBaseValidate ? "form-control required-field" : "form-control"} name='CakeBase' {...register('CakeBase')} defaultValue='' id="validationCustom22" onChange={handleGetSelectedCakeBase} >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <>
                                    {CakeBaseArray?.map((val, i) =>
                                        <option key={i} value={val}>{val}</option>
                                    )}
                                </>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Cake Cream <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.CakeCream && !cakeCreamValidate ? "form-control required-field" : "form-control"} defaultValue='' name='CakeCream' {...register('CakeCream')} id="validationCustom22" onChange={handleGetSelectedCakeCream}>
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value='N/A'>Not Applicable</option>
                                <>
                                    {CakeCreamArray.map((val, i) =>
                                        <option key={i} value={val}>{val}</option>
                                    )}
                                </>
                                {/* <option value="Butter Cream">Butter Cream</option>
                                <option value="Whipping Cream">Whipping Cream</option>
                                <option value="Others">Others</option> */}
                            </select>
                        </div>
                    </div>
                    {showButterCreamField &&
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationCustom18" className='font-weight-bold'>Butter Cream Type</label>
                            <div className="input-group">
                                <input type="text" className="form-control text-capitalize" name='ButterCreamType' {...register('ButterCreamType')} placeholder="Butter Cream Type" />
                            </div>
                        </div>
                    }
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Best before<span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <div className='display-flex w-100'>
                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={3} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className={errors?.BestUsedBefore ? "form-control required-field" : "form-control"} name='BestUsedBefore' {...register('BestUsedBefore')} id="validationCustom24" placeholder="e.g. 2" />
                                <select className={errors?.BestUsedBefore ? "form-control w-50 required-field" : "form-control w-50"} defaultValue='days' id="validationCustom22" name='BestUsedBeforeUnit' {...register('BestUsedBeforeUnit')} >
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>To Be Stored In <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.ToBeStoredIn ? "form-control required-field" : "form-control"} name='ToBeStoredIn' {...register('ToBeStoredIn')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="Freezer">Freezer</option>
                                <option value="Chiller">Chiller</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Time at Room Temperature before use <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <div className='display-flex w-100'>
                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={3} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className={errors?.KeepTheCakeInRoomTemperature ? "form-control required-field" : "form-control"} name='KeepTheCakeInRoomTemperature' {...register('KeepTheCakeInRoomTemperature')} id="validationCustom24" placeholder="Keep The Cake In Room Temperature" />
                                <select className={errors?.KeepTheCakeInRoomTemperature ? "form-control w-50 required-field" : "form-control w-50"} defaultValue='days' id="validationCustom22" name='KeepTheCakeInRoomTemperatureUnit' {...register('KeepTheCakeInRoomTemperatureUnit')}>
                                    <option value="mins">mins</option>
                                    <option value="hours">hours</option>
                                    <option value="days">days</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Theme cake possible? <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.ThemeCakePossible ? "form-control required-field" : "form-control"} name='ThemeCakePossible' {...register('ThemeCakePossible')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="y">Yes</option>
                                <option value="n">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Toppers possible? <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.ToppersPossible ? "form-control required-field" : "form-control"} name='ToppersPossible' {...register('ToppersPossible')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="y">Yes</option>
                                <option value="n">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Customization possible? <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            {/* <select className={errors?.BasicCustomisationPossible && !basicCustomizationValidate ? "form-control required-field" : "form-control"} name='BasicCustomisationPossible' {...register('BasicCustomisationPossible')} defaultValue='' onChange={handleBasicCustomization} > */}
                            <select className={errors?.BasicCustomisationPossible ? "form-control required-field" : "form-control"} name='BasicCustomisationPossible' {...register('BasicCustomisationPossible')} defaultValue='' >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="y">Yes</option>
                                <option value="n">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>Full Customization possible? <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.FullCustomisationPossible ? "form-control required-field" : "form-control"} name='FullCustomisationPossible' {...register('FullCustomisationPossible')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="y">Yes</option>
                                <option value="n">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>How good are you in this cake <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.HowGoodAreYouWithTheCake ? "form-control required-field" : "form-control"} name='HowGoodAreYouWithTheCake' {...register('HowGoodAreYouWithTheCake')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Good">Good</option>
                                <option value="Excellent">Excellent</option>
                                <option value="Specialist">Specialist</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="validationCustom22" className='font-weight-bold'>How Many Times Have You Baked This Particular Cake <span className='text-danger'>*</span></label>
                        <div className="input-group">
                            <select className={errors?.HowManyTimesHaveYouBakedThisParticularCake ? "form-control required-field" : "form-control"} name='HowManyTimesHaveYouBakedThisParticularCake' {...register('HowManyTimesHaveYouBakedThisParticularCake')} defaultValue='' id="validationCustom22" >
                                <option value='' disabled='disabled'>-- Select --</option>
                                <option value="Never">Never</option>
                                <option value="1-3 times">1-3 times</option>
                                <option value="4-7 times">4-7 times</option>
                                <option value="7-10 times">7-10 times</option>
                                <option value="More than 10 times">More than 10 times</option>
                            </select>
                        </div>
                    </div>
                    {!tierFieldValidation ?
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationCustom22" className='font-weight-bold'>Is Tier cake possible? <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select className={errors?.IsTierCakePossible && !showTierCakeField ? "form-control required-field" : "form-control"} name='IsTierCakePossible' {...register('IsTierCakePossible')} defaultValue='' onChange={handleShowTierCakeField} >
                                    <option value='' disabled='disabled'>-- Select --</option>
                                    <option value="y">Yes</option>
                                    <option value="n">No</option>
                                </select>
                            </div>
                        </div> :
                        <div name='IsTierCakePossible' {...register('IsTierCakePossible')} defaultValue="y"></div>
                    }
                    {/* {((showMinTimeField.includes(3) && !showMinTimeField.includes(5)) || (!showMinTimeField.includes(3) && showMinTimeField.includes(5)) || (showMinTimeField.includes(3) && showMinTimeField.includes(5))) &&
                        <div className="col-md-6 mb-3"></div>
                    } */}
                    {/* {showTierCakeField === 'y' &&
                        <>
                            <div className="col-md-6 mb-3">
                                <TierCakeWeightAndPrice className="option-control"
                                    option={TierList} disabledButton={disabledButton5}
                                    handleSelectButton={handleSelectButton5} handleRemoveButton={handleRemoveButton5}
                                    handleInputValue={handleInputValue4}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <TierCakeDeliveryTime className="option-control"
                                    option={TierList} disabledButton={disabledButton6}
                                    handleSelectButton={handleSelectButton6} handleRemoveButton={handleRemoveButton6}
                                    handleInputValue={handleInputValue5}
                                />
                            </div>
                        </>
                    } */}
                </div>
                {/* </form> */}
            </div>
        </div>
    );
}

export default BodyContent2;