import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function Breadcrumb({ page }) {

    return (
        <nav aria-label="breadcrumb">
            {page === 'orderlist' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/dashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/orders"> Orders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/dashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                </ol>
            }
        </nav>
    );
};


