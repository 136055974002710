import actions from "./actions";

const initialState = {
    VendorsStatementList: [],
    VendorStatementDetails: [],
    VendorPaymentReports: [],
    CouponCodeList: [],
    CancelledOrders: [],
    RefundReport: [],
    AccountsDashboardCount: [],
    AccountsInprogressTickets: [],
    OrderCancelledWithin15MinsList: [],
    SalesReportByDate: [],
    SalesReportByMonth: [],
    Miscellaneous_Refund: [],
};

const AccountsReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_VENDORS_STATEMENTOFACCOUNTS_LIST:
            return {
                ...state,
                VendorsStatementList: action.payload
            }
        case actions.SET_VENDOR_STATEMENTOFACCOUNTS_DETAILS:
            return {
                ...state,
                VendorStatementDetails: action.payload
            }
        case actions.SET_DAILY_VENDOR_PAYMENT_REPORTS:
            return {
                ...state,
                VendorPaymentReports: action.payload
            }
        case actions.SET_COUPONCODE_LIST:
            return {
                ...state,
                CouponCodeList: action.payload
            }
        case actions.SET_CANCELLED_ORDERS_LIST:
            return {
                ...state,
                CancelledOrders: action.payload
            }
        case actions.SET_REFUND_REPORTS:
            return {
                ...state,
                RefundReport: action.payload
            }
        case actions.SET_ACCOUNTS_DASHBOARD_COUNT:
            return {
                ...state,
                AccountsDashboardCount: action.payload
            }
        case actions.SET_ACCOUNTS_INPROGRESS_TICKETS:
            return {
                ...state,
                AccountsInprogressTickets: action.payload
            }
        case actions.SET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS:
            return {
                ...state,
                OrderCancelledWithin15MinsList: action.payload
            }
        case actions.SET_SALES_REPORT_BY_DATE:
            return {
                ...state,
                SalesReportByDate: action.payload
            }
        case actions.SET_SALES_REPORT_BY_MONTH:
            return {
                ...state,
                SalesReportByMonth: action.payload
            }
        case actions.SET_MISCELLANEOUS_REFUND_REPORT:
            return {
                ...state,
                Miscellaneous_Refund: action.payload
            }

        default:
            return state;
    }
};

export default AccountsReducer;