import React, { useEffect, useState, useRef } from "react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../../redux/CakeArray/actions";
import CommonActions from '../../../redux/Common/actions';
import Preloader from '../../layouts/Preloader';
import CircleDotSpinner from "../../common/CircleDotSpinner";
import Breadcrumb from "./Breadcrumb";
import FileSizeValidation from "../../common/FileSizeValidation";
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function CatagoryList() {

    const dispatch = useDispatch();

    const CakeTypeRef = useRef();
    const CakeSubType = useRef();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [category, setCategory] = useState("");
    const [ErrMessage, setErrMessage] = useState(false);
    const [loader, setLoader] = useState(false);

    let [deleteCakeType, setDeleteCakeType] = useState("");
    let [SelectedType, setselectedType] = useState("");
    let [deleteSubType, setDeleteSubType] = useState("");
    let [selectedCategory, setSelectedCategory] = useState("");
    let [selectedSubCategory, setSelectedSubCategory] = useState("");
    let [selectedTypeImage, setSelectedTypeImage] = useState({ Type: '', Image: '' });
    let [selectedImage, setSelectedImage] = useState();
    let [selectedImageUrl, setSelectedImageUrl] = useState();
    let [subcategoryList, setSubCategoryList] = useState([]);
    let [cakeTypeList, setCakeTypeList] = useState([]);

    //get category list
    const { CakeType, CakeArrayResponse } = useSelector((state) => state.CakeArrayReducer);

    //dispatch the Get Category List api
    useEffect(() => {
        dispatch({ type: actions.GET_CAKE_TYPE });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (CakeType?.length === 0) {
            setCakeTypeList([]);
        } else {
            if (CakeType?.message === 'No Records Found') {
                setCakeTypeList([]);
            } else {
                let CakeTypeArray = [];
                CakeType.map(val => {
                    return CakeTypeArray.push({ label: val.Type, value: val.Type });
                });
                setCakeTypeList(CakeTypeArray);
            }
        };
    }, [CakeType]);

    const handleAddImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setSelectedImage(selectedImage = result?.FinalImages[0]);
                if (selectedImage) {
                    setSelectedImageUrl(selectedImageUrl = URL.createObjectURL(selectedImage));
                };
            }
        }
    };

    //Add New Category
    const handleNewCategory = (e) => {
        e.preventDefault();
        var NewArray = []
        if (CakeTypeRef.current.value && selectedImage) {
            if (CakeType?.message === 'No Records Found') {
                NewArray.push('New One')
            } else {
                CakeType.filter(val => {
                    if (val.Type.toLowerCase() === CakeTypeRef.current.value.toLowerCase()) {
                        NewArray.push('Existing One');
                    } else {
                        NewArray.push('New One');
                    }
                    return false;
                });
            }
            if (NewArray.includes('Existing One')) {
                setErrMessage("Cake Type Already Exist");
            } else {
                setLoader(true);
                const formData = new FormData();
                formData.append('Type', CakeTypeRef.current.value);
                formData.append('Type_Image', selectedImage);
                dispatch({ type: actions.ADD_CAKE_TYPE, payload: formData });
                CakeTypeRef.current.value = "";
            }
        } else {
            (!CakeTypeRef.current.value) ? setErrMessage("Cake Type is required") : setErrMessage("Image is required");
        };
    };

    const handleSelectCakeTypeForAddSubType = (e) => {
        setCategory(e);
    };

    //add new sub category
    const handleNewSubCategory = (e) => {
        e.preventDefault();
        var NewArray = []
        if (CakeSubType.current.value && category?.value && selectedImage) {
            CakeType.filter(val => {
                if (val.Type === category?.value) {
                    if (val.SubType.length === 0) {
                        NewArray.push('New One');
                    } else {
                        val.SubType.filter(s => {
                            if (s.Name.toLowerCase() === CakeSubType.current.value?.toLowerCase()) {
                                NewArray.push('Existing One');
                            } else {
                                NewArray.push('New One');
                            }
                            return null;
                        });
                    }
                } else {
                    return null;
                }
                return null;
            });
            if (NewArray.includes('Existing One')) {
                setErrMessage("Cake SubType Already Exist");
            } else {
                setLoader(true);
                const formData = new FormData();
                formData.append("Type", category?.value);
                formData.append("SubType", CakeSubType.current.value);
                formData.append('SubType_Image', selectedImage);
                dispatch({ type: actions.ADD_CAKE_TYPE, payload: formData });
                CakeSubType.current.value = "";
            }
        } else {
            (!category?.value) ? setErrMessage("Cake Type is required") : (!CakeSubType.current.value) ?
                setErrMessage("Cake SubType is required") : setErrMessage("Image is required")

        };
    };

    //for open delete category popup
    const handleDeleteCategoryPopUp = (a) => {
        setDeleteCakeType(a.Type);
        setShow3(true);
    };

    //for delete category
    const handleDeleteCategory = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({
            type: actions.DELETE_CAKE_TYPE,
            payload: { TypeStatus: 'Type', Type: deleteCakeType }
        });
    };

    //for open delete subcategory popup
    const handleDeleteSubCategoryPopUp = (a, s) => {
        setShow4(true);
        setDeleteSubType(s);
        setselectedType(a);
    };

    //for delete subcategory
    const handleDeleteSubCategory = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({
            type: actions.DELETE_CAKE_TYPE,
            payload: { TypeStatus: 'SubType', Type: SelectedType, SubType: deleteSubType }
        });
    };

    //for close modals
    const handleClose = () => {
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShowImagePopup(false);
        setSelectedTypeImage();
        setSelectedImage();
        setSelectedImageUrl();
        setErrMessage("");
        setCategory();
    };

    const handleSelectCakeType = (a) => {
        setSelectedCategory(a.Type);
        setSelectedTypeImage({ Type: 'CakeType', Image: a.Type_Image });
        setShowImagePopup(true);
        let Result = CakeType?.find(val => val.Type === a.Type);
        (Result) && setSubCategoryList(Result.SubType);
    };

    const handleSelectCakeSubType = (s, a) => {
        setSelectedSubCategory(s.Name);
        setSelectedCategory(a);
        setSelectedTypeImage({ Type: 'CakeSubType', Image: s.SubType_Image });
        setShowImagePopup(true);
    };

    const handleUploadImage = (e) => {
        e.preventDefault();
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setSelectedImage(selectedImage = result?.FinalImages[0]);
                if (selectedImage) {
                    setSelectedImageUrl(selectedImageUrl = URL.createObjectURL(selectedImage));
                    setSelectedTypeImage({ Type: selectedTypeImage.Type, Image: selectedImageUrl });
                };
            }
        }
    };

    const handleUpdateImage = (e) => {
        e.preventDefault();
        if (selectedImage) {
            let formData = new FormData();
            if (selectedTypeImage.Type === 'CakeType') {
                formData.append('TypeStatus', selectedTypeImage.Type);
                formData.append('Type', selectedCategory);
                formData.append('Type_Image', selectedImage);
            } else {
                formData.append('TypeStatus', selectedTypeImage.Type);
                formData.append('Type', selectedCategory);
                formData.append('SubType', selectedSubCategory);
                formData.append('SubType_Image', selectedImage);
            };
            setLoader(true);
            dispatch({ type: actions.UPDATE_CAKE_TYPE_IMAGE, payload: formData });
        } else {
            setErrMessage("Selected image to upload");
        }
    }

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_CAKE_TYPE });
    };

    useEffect(() => {
        if (selectedCategory) {
            let Array = CakeType?.find(val => val.Type === selectedCategory);
            setSubCategoryList(Array?.SubType || []);
        };
    }, [CakeType, selectedCategory]);

    useEffect(() => {
        if (CakeArrayResponse?.statusCode === 200 || CakeArrayResponse?.statusCode === 400) {
            setLoader(false);
            setShow1(false);
            setShow2(false);
            setShow3(false);
            setShow4(false);
            setShowImagePopup(false);
            setErrMessage("");
            setSelectedImage();
            setSelectedImageUrl();
            setSelectedTypeImage();
        };
    }, [CakeArrayResponse]);

    //loading
    useEffect(() => {
        (CakeType?.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        // eslint-disable-next-line
    }, [CakeType]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className="pb-2">CAKE TYPE
                                        <span className="pb-2 float-right">
                                            <button
                                                className="btn-sm btn-success p-2 add-button rounded"
                                                // className="btn-sm btn-primary p-2"
                                                type="submit"
                                                onClick={() => setShow1(true)}
                                            >
                                                Add
                                            </button>
                                        </span>
                                    </h6>
                                    <table className="table ">
                                        <tbody>
                                            {CakeType?.length > 0 ?
                                                <>
                                                    {CakeType?.map((a, i) =>
                                                        <tr className="category-row hover" key={i}>
                                                            <th
                                                                className={selectedCategory === a.Type ? "active" : ""}
                                                                onClick={() => handleSelectCakeType(a)}
                                                            >
                                                                {a.Type}
                                                            </th>
                                                            <button
                                                                className="btn-sm btn-primary category-row-btn remove-button remove-2 cake-remove"
                                                                // className="btn-sm btn-secondary category-row-btn remove-button remove-2"
                                                                onClick={() => handleDeleteCategoryPopUp(a)}
                                                            >
                                                                x
                                                            </button>
                                                        </tr>
                                                    )}
                                                </> :
                                                <tr >
                                                    <th className="text-center">No Records Found</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className="pb-2">CAKE SUBTYPE
                                        <span className="pb-2 float-right">
                                            <button
                                                className="btn-sm btn-success p-2 add-button rounded"
                                                // className="btn-sm btn-primary p-2"
                                                type="submit"
                                                onClick={() => setShow2(true)}
                                            >
                                                Add
                                            </button>
                                        </span>
                                    </h6>
                                    <table className="table ">
                                        <tbody>
                                            {subcategoryList?.length > 0 ?
                                                <>
                                                    {subcategoryList?.map((a, i) =>
                                                        <tr className="category-row hover" key={i}>
                                                            <th
                                                                className={selectedSubCategory === a.Name ? "active" : ""}
                                                                onClick={() => handleSelectCakeSubType(a, selectedCategory)}
                                                            >
                                                                {a.Name}
                                                            </th>
                                                            <button
                                                                className="btn-sm btn-primary category-row-btn remove-button remove-2 cake-remove"
                                                                // className="btn-sm btn-secondary category-row-btn remove-button remove-2"
                                                                onClick={() => handleDeleteSubCategoryPopUp(selectedCategory, a.Name)}
                                                            >
                                                                x
                                                            </button>
                                                        </tr>
                                                    )}
                                                </> :
                                                <tr >
                                                    <th className="text-center">{(selectedCategory && subcategoryList?.length === 0) ? "No Records Found" : "Select Category"}</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* Add Category Popup */}
            <Modal className="modal-min" show={show1} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Add New Cake Type</h1>
                    <div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom23" className='font-weight-bold'>Cake Type<span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control text-capitalize"
                                        ref={CakeTypeRef}
                                        placeholder="Cake Type"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom12" className='float-left font-weight-bold ml-2'> Add Cake Type Image <span className='text-danger'>*</span></label>
                                <div className="custom-file">
                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleAddImage} />
                                    <label className="custom-file-label">Upload Cake Type Image...</label>
                                </div>
                                <p className='text-success font-weight-bold float-left mt-1'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                            </div>
                            <div className='display-flex'>
                                <div className='display-flex'>
                                    {selectedImageUrl !== undefined &&
                                        <div className="imgPre">
                                            <img
                                                width='90px'
                                                className='update-select-cake'
                                                src={selectedImageUrl}
                                                alt="Cake"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className="text-danger">{ErrMessage}</p>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleNewCategory}
                                >
                                    Add
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Add SubCategory Popup */}
            <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Add New Cake SubType</h1>
                    <div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom23" className='font-weight-bold'>Cake Type<span className="text-danger">*</span></label>
                                {/* <div className="input-group">
                                    {CakeType?.length > 0 ?
                                        <select className='form-control' defaultValue='-- select --' onChange={(e) => setCategory(e.target.value)}>
                                            <option value='-- select --' disabled='disabled'>-- Select --</option>
                                            {CakeType?.map((a, i) =>
                                                <option value={a.Type} key={i}>{a.Type}</option>
                                            )}
                                        </select> :
                                        <></>
                                    }
                                </div> */}
                                <Select
                                    styles={customStyles}
                                    options={cakeTypeList}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    onChange={handleSelectCakeTypeForAddSubType}
                                    value={category}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom23" className='font-weight-bold'>Cake SubType<span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control text-capitalize"
                                        ref={CakeSubType}
                                        placeholder="Subcategory Name"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label htmlFor="validationCustom12" className='float-left font-weight-bold ml-2'> Add Cake SubType Image <span className='text-danger'>*</span></label>
                                <div className="custom-file">
                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleAddImage} />
                                    <label className="custom-file-label">Upload Cake SubType Image...</label>
                                </div>
                                <p className='text-success font-weight-bold float-left mt-1'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                            </div>
                            <div className='display-flex'>
                                <div className='display-flex'>
                                    {selectedImageUrl !== undefined &&
                                        <div className="imgPre">
                                            <img
                                                width='90px'
                                                className='update-select-cake'
                                                src={selectedImageUrl}
                                                alt="Cake"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className="text-danger">{ErrMessage}</p>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleNewSubCategory}
                                >
                                    Add
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete Category Popup */}
            <Modal className="modal-min" show={show3} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div>
                        <h6 className='text-center mb-4'>Are you sure you want to delete the Cake Type "{deleteCakeType}" ?</h6>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleDeleteCategory}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary ml-4"
                                    onClick={handleClose}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* Delete Subcategory Popup */}
            <Modal className="modal-min" show={show4} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div>
                        <h6 className='text-center mb-4'>Are you sure you want to delete the Cake SubType "{deleteSubType}" ?</h6>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleDeleteSubCategory}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary ml-4"
                                    onClick={handleClose}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="modal-min" show={showImagePopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <h1 className="text-center pb-3">{selectedTypeImage?.Type === 'CakeType' ? selectedCategory : selectedSubCategory}&apos;s Image</h1>
                    <div>
                        <div className="input-group text-center cakeType-image">
                            <ResponsiveEmbed aspectRatio='21by9'>
                                <img
                                    src={selectedTypeImage?.Image}
                                    className="caketypeimage "
                                    alt="type"
                                // width='500px'
                                // height='600px'
                                />
                            </ResponsiveEmbed>
                        </div>
                        {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                {selectedImage ?
                                    <button
                                        type="submit"
                                        className="btn btn-primary shadow-none mr-3"
                                        onClick={handleUpdateImage}
                                    >
                                        Update Image
                                    </button> :
                                    <>
                                        <label className="btn btn-primary mr-3 mt-4" htmlFor="actual-btn">Upload New Image</label>
                                        <input type="file" accept='image/jpg,image/jpeg,image/png' id="actual-btn" onChange={handleUploadImage} hidden />
                                    </>
                                }
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                                <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}