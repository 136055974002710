const actions = {
    ADD_OTHER_PRODUCT: 'ADD_OTHER_PRODUCT',
    ADD_OTHER_PRODUCT_STATUS: 'ADD_OTHER_PRODUCT_STATUS',
    APPROVE_OTHER_PRODUCT: 'APPROVE_OTHER_PRODUCT',
    APPROVE_UPDATED_OTHER_PRODUCT: 'APPROVE_UPDATED_OTHER_PRODUCT',
    GET_NEW_OTHER_PRODUCT_LIST: 'GET_NEW_OTHER_PRODUCT_LIST',
    SET_NEW_OTHER_PRODUCT_LIST: 'SET_NEW_OTHER_PRODUCT_LIST',
    GET_OTHER_PRODUCT_DETAILS: 'GET_OTHER_PRODUCT_DETAILS',
    SET_OTHER_PRODUCT_DETAILS: 'SET_OTHER_PRODUCT_DETAILS',
    GET_APPROVED_PRODUCT_LIST: 'GET_APPROVED_PRODUCT_LIST',
    SET_APPROVED_PRODUCT_LIST: 'SET_APPROVED_PRODUCT_LIST',
    OTHER_PRODUCT_SEND_INFORMATION: 'OTHER_PRODUCT_SEND_INFORMATION',
    GET_OTHER_PRODUCT_BY_VENDOR: 'GET_OTHER_PRODUCT_BY_VENDOR',
    SET_OTHER_PRODUCT_BY_VENDOR: 'SET_OTHER_PRODUCT_BY_VENDOR',
    UPDATE_OTHER_PRODUCT: 'UPDATE_OTHER_PRODUCT',
    GET_VENDOR_OTHER_PRODUCT_ORDERS_LIST: 'GET_VENDOR_OTHER_PRODUCT_ORDERS_LIST',
    SET_VENDOR_OTHER_PRODUCT_ORDERS_LIST: 'SET_VENDOR_OTHER_PRODUCT_ORDERS_LIST',
    GET_OTHER_PRODUCT_ORDER_DETAILS: 'GET_OTHER_PRODUCT_ORDER_DETAILS',
    SET_OTHER_PRODUCT_ORDER_DETAILS: 'SET_OTHER_PRODUCT_ORDER_DETAILS',
    UPDATE_OTHER_PRODUCT_ORDER_STATUS: 'UPDATE_OTHER_PRODUCT_ORDER_STATUS',
    UPDATE_OTHER_PRODUCT_ORDER_STATUS_RESPONSE: 'UPDATE_OTHER_PRODUCT_ORDER_STATUS_RESPONSE',
    ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER: 'ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER',
    ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER_STATUS: 'ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER_STATUS',
    GET_ALL_OTHER_PRODUCT_ORDERS_LIST: 'GET_ALL_OTHER_PRODUCT_ORDERS_LIST',
    SET_ALL_OTHER_PRODUCT_ORDERS_LIST: 'SET_ALL_OTHER_PRODUCT_ORDERS_LIST',
    DELETE_OTHER_PRODUCT: 'DELETE_OTHER_PRODUCT',
    SET_DELETE_OTHER_PRODUCT_STATUS: 'SET_DELETE_OTHER_PRODUCT_STATUS',
    OTHER_PRODUCT_RESPONSE: 'OTHER_PRODUCT_RESPONSE',

};

export default actions;