import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/Accounts/actions';
import TableSearch from '../../../common/TableSearch';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import ReactPagination from '../../../common/React-Pagination';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../../common/SortingOrders';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "CD", type: "D" },
    ]);

    const { OrderCancelledWithin15MinsList } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (OrderCancelledWithin15MinsList.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(OrderCancelledWithin15MinsList.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [OrderCancelledWithin15MinsList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        setSorting([{ table: 0, field: "CD", type: "D" }]);
        setsearch('');
        dispatch({ type: actions.GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS });
    };

    const ViewDetails = (e, details) => {
        e.preventDefault();
        let Type;
        (details.CakeID) ? Type = 'Cake Order' : (details.HamperID) ? Type = 'Hamper Order' : Type = 'Product Order';
        history.push('/Helpdesk-C-Cancelled-Orders-With-15Mins-Details', { Id: details._id, Type: Type });
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = filteredData;
        let result = SortingOrders({ type, field, filterData });
        setFilteredData(result);
    };

    useEffect(() => {
        (OrderCancelledWithin15MinsList.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [OrderCancelledWithin15MinsList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Cancellation Within 15Mins</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search Id/Status..." onChange={handleSearchbar} value={search || ''} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Order Type</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Cancelled On <span className='cursor' onClick={(e) => handleSorting(e, 'CD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'CD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'CD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr className={cake.Ticket_Raised_For_Customer_Cancel === "Yes" ? "odd cursor" : "odd cursor font-weight-bold"} key={cake._id} onClick={(e) => ViewDetails(e, cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CakeID ? 'Cake Order' : cake.HamperID ? 'Hamper Order' : 'Product Order'}</td>
                                                                        <td>{cake.VendorName ?? '-'}</td>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td className='price-align3-row'>{cake.Status_Updated_On}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};