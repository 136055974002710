import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from './actions'
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const CustomizeCakeSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y, GetCustomizeCakeListAbove5KG),
        yield takeEvery(actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N, GetCustomizeCakeListBelow5KG),
        yield takeEvery(actions.GET_CUSTOMIZE_CAKE_LIST_BY_VENDORID, GetCustomizeCakeListByVendorID),
        yield takeEvery(actions.GET_ALL_CUSTOMIZE_CAKE, GetAllCustomizeCakeList),
        yield takeEvery(actions.CUSTOMIZE_CAKE_ASSIGN, CustomizeCakeAssign),
        yield takeEvery(actions.CUSTOMIZE_CAKE_PRICE_INVOICE, CustomizeCakePriceInvoice),
        yield takeEvery(actions.GET_NEW_CUSTOMIZE_CAKE_LIST, GetNewCustomizeCakeList),
        yield takeEvery(actions.CANCEL_CUSTOMIZED_CAKE_ORDER, CancelCustomizedCakeOrder),
        yield takeEvery(actions.GET_CUSTOMIZED_CAKE_DETAILS, GetCustomizedCakeDetails),
        yield takeEvery(actions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST, GetCakeyDirectCustomizedCakeOrdersList),
        yield takeEvery(actions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV, GetCakeyDirectCustomizedCakeOrdersForHelpdeskV),
        yield takeEvery(actions.GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR, GetCustomizedCakeOrderThroughVendor),
    ]);
};

//get all customized cakes list
const GetAllCustomizeCakeList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/list`
            )
        );
        yield put({
            type: actions.SET_ALL_CUSTOMIZE_CAKE,
            payload: { GetAllCustomizeCake: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCustomizedCakeDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_CUSTOMIZED_CAKE_DETAILS,
            payload: { CustomizedCakeDeatils: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get above 5kg customized cakes list
const GetNewCustomizeCakeList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/newlist`
            )
        );
        yield put({
            type: actions.SET_NEW_CUSTOMIZE_CAKE_LIST,
            payload: { NewCustomizeCakeList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get above 5kg customized cakes list
const GetCustomizeCakeListAbove5KG = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y,
            payload: { CustomizeCakesList_Above5Kg: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get below 5kg customized cakes list
const GetCustomizeCakeListBelow5KG = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N,
            payload: { CustomizeCakesList_Below5Kg: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get customized cakes list based on vendor id
const GetCustomizeCakeListByVendorID = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/listbyvendorid/${payload}`
            )
        );
        yield put({
            type: actions.SET_CUSTOMIZE_CAKE_LIST_BY_VENDORID,
            payload: { GetCustomizeCakesListByVendor: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//assign above 5kg customized cakes to vendors
const CustomizeCakeAssign = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/customize/cake/assign/${payload.id}`, {
                VendorID: payload.VendorID,
                Vendor_ID: payload.Vendor_ID,
                VendorName: payload.VendorName,
                VendorPhoneNumber1: payload.VendorPhoneNumber1,
                VendorPhoneNumber2: payload.VendorPhoneNumber2,
                VendorAddress: payload.VendorAddress,
                GoogleLocation: payload.GoogleLocation,
                Status: payload.Status,
                Status_Updated_By: payload.Status_Updated_By,
            })
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.CUSTOMIZE_CAKE_ASSIGN_STATUS,
            payload: { CustomizeCakeAssignStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//send price invoice to users by vendors 
const CustomizeCakePriceInvoice = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/customize/cake/price/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.CUSTOMIZE_CAKE_PRICE_INVOICE_STATUS,
            payload: { CustomizeCakePriceInvoiceStatus: result.data }
        });
        yield put({ type: actions.GET_CUSTOMIZED_CAKE_DETAILS, payload: payload.id });
        yield delay(2000);
        yield put({ type: actions.CUSTOMIZE_CAKE_PRICE_INVOICE_STATUS, payload: { CustomizeCakePriceInvoiceStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const CancelCustomizedCakeOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/customize/cake/cancel/${payload.id}`, {
                Cancelled_By: 'Vendor',
                ReasonForCancel: payload.ReasonForCancel,
                Status_Updated_By: payload.VendorID
            }
            )
        );
        yield put({
            type: actions.CANCEL_CUSTOMIZED_CAKE_ORDER_STATUS,
            payload: { CancelCustomieCake: result.data }
        });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCakeyDirectCustomizedCakeOrdersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/directCakey/customizedCake/Orders`
            )
        );
        yield put({
            type: actions.SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCakeyDirectCustomizedCakeOrdersForHelpdeskV = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/orders/cakeyDirect/customizedCakeOrders`
            )
        );
        yield put({
            type: actions.SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCustomizedCakeOrderThroughVendor = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/customizedCakeList/Vendor`
            )
        );
        yield put({
            type: actions.SET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default CustomizeCakeSaga;