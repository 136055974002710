import React, { useState, useEffect } from "react";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useDispatch, useSelector } from "react-redux";
import Preloader from '../../../layouts/Preloader';
import ReactPagination from "../../../common/React-Pagination";
import { float2D } from "../../../../utils/Validation";
import moment from 'moment'
import AccountsActions from '../../../../redux/Accounts/actions'
import { CSVLink } from 'react-csv';

export default function Content() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [filteredData2, setFilteredData2] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    var [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    var [exportSalesReport, setExportSalesReport] = useState([]);

    const { SalesReportByDate } = useSelector(state => state.AccountsReducer)

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData2?.AllOrdersByDate?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData2?.AllOrdersByDate?.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //loading
    useEffect(() => {
        if (SalesReportByDate?.AllOrdersByDate?.length === 0) {
            setFetchSuccess(false)
        } else {
            setFetchSuccess(true);
        }
        // eslint-disable-next-line
    }, [SalesReportByDate]);

    useEffect(() => {
        if (SalesReportByDate?.message === 'No Records Found') {
            setFilteredData2([])
        } else {
            setFilteredData2(SalesReportByDate)
        }
    }, [SalesReportByDate])

    const handleSelectedDate = (e) => {
        setSelectedDate(selectedDate = e.target.value)
        if (selectedDate) {
            dispatch({ type: AccountsActions.GET_SALES_REPORT_BY_DATE, payload: moment(selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY') })
        } else {
            dispatch({ type: AccountsActions.SET_SALES_REPORT_BY_DATE, payload: [] })

        }
    }
    useEffect(() => {
        if (selectedDate) {
            dispatch({ type: AccountsActions.GET_SALES_REPORT_BY_DATE, payload: moment(selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY') })
        }
        //eslint-disable-next-line
    }, [selectedDate])

    const handleExport = () => {
        if (filteredData2?.AllOrdersByDate?.length > 0) {
            const SalesReport = [
                [
                    "Product Price",
                    "Coupon Amount",
                    "Delivery Charges",
                    "Tax",
                    "Invoice Amount",
                    "Received Amount",
                    "Cancellation Charges",
                    "Excess",
                    "Short",
                    "Refund",
                    "Payable Tax",
                    " ",
                    " ",
                    " ",
                    " "
                ],
                [

                    filteredData2?.SumOfData?.SumOfProductPrice,
                    filteredData2?.SumOfData?.SumOfCouponValue,
                    filteredData2?.SumOfData?.SumOfDeliveryCharges,
                    filteredData2?.SumOfData?.SumOfTax,
                    filteredData2?.SumOfData?.SumOfInvoiceAmount,
                    filteredData2?.SumOfData?.SumOfReceivedAmount,
                    filteredData2?.SumOfData?.SumOfCancellationCharges,
                    filteredData2?.SumOfData?.SumOfExcess,
                    filteredData2?.SumOfData?.SumOfShort,
                    filteredData2?.SumOfData?.SumOfRefund,
                    filteredData2?.SumOfData?.SumOfPayableTax,
                    
                    " ",
                    " ",
                    " ",
                    " "
                ],
                [
                    " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " ", " "
                ],
                [
                    "Order ID",
                    "Customer ID",
                    "Vendor ID",
                    "Product Price",
                    "Coupon Amount",
                    "Delivery Charges",
                    "Tax",
                    "Invoice Amount",
                    "Received Amount",
                    "Cancellation Charges",
                    "Excess",
                    "Short",
                    "Refund",
                    "Status",
                ],

                ...filteredData2?.AllOrdersByDate?.map((single) =>
                    [
                        single.Order_ID,
                        single.UserID,
                        single.Vendor_ID,
                        float2D(single.ProductPrice),
                        float2D(single.CouponValue),
                        float2D(single.DeliveryCharge),
                        float2D(single.Tax),
                        float2D(single.InvoiceAmount),
                        float2D(single.ReceivedAmount),
                        float2D(single.Cancellation_Charges),
                        float2D(single.Excess),
                        float2D(single.Short),
                        float2D(single.Refund),
                        single.Status
                    ]
                ),
            ]

            SalesReport.reduce((prev, curr) => [prev, curr]);
            const data = SalesReport;
            setExportSalesReport(data);
        }
    };

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">

                                                    {(SalesReportByDate?.message === 'No Records Found' || selectedDate === '') ?
                                                        <button className="btn btn-success add-button rounded sales-report-export"
                                                            //  disabled={(SalesReportByDate?.message === 'No Records Found' || selectedDate === '') ? true : false}
                                                            disabled
                                                            type="submit">Export</button>
                                                        :
                                                        <button className="btn btn-success add-button rounded sales-report-export" type="submit">
                                                            <CSVLink
                                                                data={exportSalesReport}
                                                                filename={`Sales Report for ${moment(selectedDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}.csv`}
                                                                className="text-white "
                                                                target="_blank"
                                                                onClick={handleExport}
                                                            >
                                                                EXPORT
                                                            </CSVLink>
                                                        </button>
                                                    }

                                                    <div className="float-right dataTables_length" id="data-table-5_length">
                                                        <input
                                                            type='date'
                                                            maxLength={4}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            className="form-control mb-2"
                                                            aria-controls="data-table-4"
                                                            onChange={handleSelectedDate}
                                                            value={selectedDate}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Product Price</th>
                                                                <th>Coupon Amount</th>
                                                                <th>Delivery Charges</th>
                                                                <th>Tax</th>
                                                                <th>Invoice Amount</th>
                                                                <th>Received Amount</th>
                                                                <th>Cancellation Charges</th>
                                                                <th>Excess</th>
                                                                <th>Short</th>
                                                                <th>Refund</th>
                                                                <th>Payable Tax</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData2?.AllOrdersByDate?.length > 0 ?
                                                            <tbody>
                                                                {/* <tr className="odd cursor price-alignment" > */}
                                                                <tr className="odd cursor" >
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfProductPrice)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfCouponValue)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfDeliveryCharges)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfTax)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfInvoiceAmount)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfReceivedAmount)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfCancellationCharges)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfExcess)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfShort)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfRefund)}</td>
                                                                    <td>{float2D(filteredData2?.SumOfData?.SumOfPayableTax)}</td>
                                                                </tr>
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={10} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row mb-2 ">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="float-right dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Order ID</th>
                                                                <th>Customer ID</th>
                                                                <th>Vendor ID</th>
                                                                <th>Product Price</th>
                                                                <th>Coupon Amount</th>
                                                                <th>Delivery Charges</th>
                                                                <th>Tax</th>
                                                                <th>Invoice Amount</th>
                                                                <th>Received Amount</th>
                                                                <th>Cancellation Charges</th>
                                                                <th>Excess</th>
                                                                <th>Short</th>
                                                                <th>Refund</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData2?.AllOrdersByDate?.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((single, i) =>
                                                                    <tr className="odd cursor " key={i}>
                                                                        <th className='id-column' scope="row">{single.Order_ID}</th>
                                                                        <td>{(single.UserID)}</td>
                                                                        <td>{(single.Vendor_ID)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.ProductPrice)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.CouponValue)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.DeliveryCharge)}</td>
                                                                        <td className="sales-report-alignment-short">{float2D(single.Tax)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.InvoiceAmount)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.ReceivedAmount)}</td>
                                                                        <td className="sales-report-alignment">{float2D(single.Cancellation_Charges)}</td>
                                                                        <td className="sales-report-alignment-short">{float2D(single.Excess)}</td>
                                                                        <td className="sales-report-alignment-short">{float2D(single.Short)}</td>
                                                                        <td className="sales-report-alignment-short">{float2D(single.Refund)}</td>
                                                                        <td>
                                                                            <span className={(single.Status?.toUpperCase())?.replace(/ /g, '')}>
                                                                                {single.Status}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={10} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData2?.AllOrdersByDate?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData2?.AllOrdersByDate?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData2?.AllOrdersByDate?.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};