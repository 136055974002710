import actions from "./actions";

const initialState = {
    CustomizeCakesList_Above5Kg: [],
    CustomizeCakesList_Below5Kg: [],
    GetCustomizeCakesListByVendor: [],
    GetAllCustomizeCake: [],
    CustomizeCakeAssignStatus: [],
    CustomizeCakePriceInvoiceStatus: [],
    NewCustomizeCakeList: [],
    CancelCustomieCake: [],
    CustomizedCakeDeatils: [],
    CakeyDirectCustomizedCakeOrders: [],
    CakeyDirectCustomizedCakeOrdersForHelpdeskV: [],
    CustomizedCakeOrderThroughVendor: [],
};

const CustomizeCakeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y:
            return {
                ...state,
                CustomizeCakesList_Above5Kg: action.payload.CustomizeCakesList_Above5Kg
            }
        case actions.SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N:
            return {
                ...state,
                CustomizeCakesList_Below5Kg: action.payload.CustomizeCakesList_Below5Kg
            }
        case actions.SET_CUSTOMIZE_CAKE_LIST_BY_VENDORID:
            return {
                ...state,
                GetCustomizeCakesListByVendor: action.payload.GetCustomizeCakesListByVendor
            }
        case actions.SET_ALL_CUSTOMIZE_CAKE:
            return {
                ...state,
                GetAllCustomizeCake: action.payload.GetAllCustomizeCake
            }
        case actions.CUSTOMIZE_CAKE_ASSIGN_STATUS:
            return {
                ...state,
                CustomizeCakeAssignStatus: action.payload.CustomizeCakeAssignStatus
            }
        case actions.CUSTOMIZE_CAKE_PRICE_INVOICE_STATUS:
            return {
                ...state,
                CustomizeCakePriceInvoiceStatus: action.payload.CustomizeCakePriceInvoiceStatus
            }
        case actions.SET_NEW_CUSTOMIZE_CAKE_LIST:
            return {
                ...state,
                NewCustomizeCakeList: action.payload.NewCustomizeCakeList
            }
        case actions.CANCEL_CUSTOMIZED_CAKE_ORDER_STATUS:
            return {
                ...state,
                CancelCustomieCake: action.payload.CancelCustomieCake
            }
        case actions.SET_CUSTOMIZED_CAKE_DETAILS:
            return {
                ...state,
                CustomizedCakeDeatils: action.payload.CustomizedCakeDeatils
            }
        case actions.SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST:
            return {
                ...state,
                CakeyDirectCustomizedCakeOrders: action.payload
            }
        case actions.SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV:
            return {
                ...state,
                CakeyDirectCustomizedCakeOrdersForHelpdeskV: action.payload
            }
        case actions.SET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR:
            return {
                ...state,
                CustomizedCakeOrderThroughVendor: action.payload
            }
        default:
            return state;
    }
};

export default CustomizeCakeReducer;