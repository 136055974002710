import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb ({CakeDetails}) {
   
        return (
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/vendor-cakes-list">Cakes List</Link></li>
                    <li className="breadcrumb-item"><Link to={{pathname : "/Vendor-cake-details",state : {cakeId : CakeDetails}}}>Cake&apos;s Details</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Update Cake&apos;s Details</li>
                </ol>
            </nav>
      );  
};

export default Breadcrumb;