import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
// import Carousel from "react-multi-carousel";
import PlacesAutocomplete from 'react-places-autocomplete';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Vendor/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import Preloader from '../../layouts/Preloader';
import CakeArrayActions from '../../../redux/CakeArray/actions';
import BankNameActions from '../../../redux/VendorCakeList/actions';
import CommonActions from '../../../redux/Common/actions';
import { AadharNumberValidation, NumberValidation } from '../../../utils/Validation';
import Breadcrumb from './Breadcrumb';
import { IoMdHome } from 'react-icons/io';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { GOOGLE_API_KEY } from '../../../utils/constants';
import FileSizeValidation from '../../common/FileSizeValidation';
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

const useremail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = yup.object().shape({
    VendorName: yup.string().required('Name is required'),
    PreferredNameOnTheApp: yup.string().required('Prefered Name is required'),
    PhoneNumber1: yup.string().required("Main Phonenumber is required")
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, "to short").max(10, "to long"),
    Email: yup.string().required('Email is required')
        .matches(useremail, 'email is not valid'),
    DateOfBirth: yup.date().required('dob is required').max(new Date(), 'Please choose past date'),
    Address: yup.string().required('Address is required'),
    DoorNumber: yup.string().required('Address is required'),
    Pincode: yup.string().required('required').max(6, 'Pincode is not valid').min(6, 'Pincode is not valid'),
    Description: yup.string().required('Description is required'),
    EggOrEggless: yup.string().required('required'),
    Gender: yup.string().required('Gender is required'),
    YearsOfExperienceAsBaker: yup.string().required('required'),
    AadhaarNumber: yup.string().required('required'),
    PANNumber: yup.string().required('required'),
    FSSAINumber: yup.string().required('required'),
    FSSAIExpiryDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    JobType: yup.string().required('required'),
    BankName: yup.string().required('required'),
    Branch: yup.string().required('required'),
    AccountNumber: yup.string().required('required'),
    IFSCCode: yup.string().required('required'),
    Ratings: yup.string().required('required'),
    AreYouFamiliarOnWorkingWithApps: yup.string().required('required'),
    LearningType: yup.string().required('required'),
    TotalDurationOfLearning: yup.string().required('required'),
    CurrentAverageSalePerMonth: yup.string().required('required'),
    HowManyCakesCanYouMakeInaWeek: yup.string().required('required'),
    HowManyDaysCanYouWorkInaWeek: yup.string().required('required'),
    CanYouMakeSingleCakeAbove5Kgs: yup.string().required('required'),
    CanYouMakeTierCakes: yup.string().required('required'),
    CanYouMakeARegularCakeWithFondantAsToppers: yup.string().required('required'),
});

const FondantTopperImages = ['https://sugitechnologies.com/images/AppImages/171979585_10225893625708421_2982526219153819858_n_wfqzcp.jpg']

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const [show, setShow] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [instituteShow, setInstituteShow] = useState('');
    const [showImageSelect, setShowImageSelect] = useState('');
    const [instituteName, setInstituteName] = useState('');
    const [instituteNameList, setInstituteNameList] = useState([]);
    const [showOthersPopup, setShowOthersPopup] = useState(false);
    const [cakeTypeName, setCakeTypeName] = useState('');
    const [cakeTypeNameList, setCakeTypeNameList] = useState([]);
    const [showMessage, setShowMessage] = useState("");
    const [specialityCakeName, setSpecialityCakeName] = useState('');
    const [specialityCakeNameList, setSpecialityCakeNameList] = useState([]);
    const [showImageError, setShowImageError] = useState("");
    const [learningDuration, setLearningDuration] = useState('years');
    const [experienceDuration, setExperienceDuration] = useState('years');
    const [loader, setLoader] = useState(false);
    const [coordinates, setCoordinates] = useState({
        Latitude: null,
        Longitude: null
    });

    let [SelectProfile, setSelectProfile] = useState();
    let [SelectProfileUrl, setSelectProfileUrl] = useState();
    let [address, setAddress] = useState('');
    let [bankNameList, setBankNameList] = useState([]);
    let [selectedBankName, setSelectedBankName] = useState();
    let [cakeTypeList, setCakeTypeList] = useState([]);

    const { register, handleSubmit, setValue, formState, reset, resetField } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const { token } = useSelector(state => state.authReducer);

    const { UpdateVendorProfileStatus, VendorDetails2 } = useSelector(state => state.VendorReducer);

    const { CakeType } = useSelector(state => state.CakeArrayReducer);

    const { BankName } = useSelector(state => state.VendorCakeListReducer);

    const VendorBankName = VendorDetails2?.BankName;

    const Id = state?.state?.VendorId;

    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_CAKE_TYPE });
        dispatch({ type: BankNameActions.GET_BANKNAME });
        // eslint-disable-next-line
    }, []);

    //dispatch the vendors cake list api
    useEffect(() => {
        if (Id) {
            dispatch({ type: actions.GET_VENDOR_DETAILS2, payload: Id });
        }
        // eslint-disable-next-line 
    }, [Id]);

    useEffect(() => {
        if (BankName?.length !== 0) {
            if (BankName?.message === 'No Records Found') {
                setBankNameList([]);
            } else {
                let NewArray = [];
                BankName.map(val => {
                    return NewArray.push({ label: val.BankName, value: val.BankName });
                });
                setBankNameList(NewArray);
            };
        } else {
            setBankNameList([]);
        };
        setSelectedBankName({ label: VendorBankName, value: VendorBankName });
        setValue('BankName', VendorBankName);
        //eslint-disable-next-line
    }, [BankName, VendorBankName]);

    const handleSelectBankName = (e) => {
        setSelectedBankName(e);
        setValue('BankName', e?.value);
    };

    useEffect(() => {
        if (CakeType?.length === 0) {
            setCakeTypeList([]);
        } else {
            if (CakeType?.message === 'No Records Found') {
                setCakeTypeList([]);
            } else {
                let CakeTypeArray = [];
                //eslint-disable-next-line
                CakeType.map(val => {
                    if (val.SubType?.length === 0) {
                        return CakeTypeArray.push({ label: val.Type, value: val.Type });
                    } else {
                        let SubTypeArray = [];
                        val.SubType.map((s, i) => {
                            return SubTypeArray.push({ label: `${i + 1}. ${s.Name}`, value: `${val.Type} - ${s.Name}` })
                        });
                        CakeTypeArray.push({ label: val.Type, options: SubTypeArray });
                    }
                });
                CakeTypeArray.push({ label: 'Others', value: 'Others' });
                setCakeTypeList(CakeTypeArray);
            }
        };
    }, [CakeType]);

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        setShowOthersPopup(false);
    };

    const handleShowAddressPopup = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleChange = (value) => {
        setAddress(address = value);
        if (address.length === 0) {
            setCoordinates({
                Latitude: null, Longitude: null
            });
        }
    };

    const handleSelect = async (value) => {
        setAddress(value)
    };

    const handleGetLatLng = async (e) => {
        e.preventDefault();
        if (VendorDetails2?.Address === address) {
            setShow(false);
        } else {
            await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`)
                .then((res) => res.json())
                .then((data) => {
                    setValue("Address", address);
                    setShow(false);
                    setCoordinates({
                        Latitude: data.results[0].geometry.location.lat,
                        Longitude: data.results[0].geometry.location.lng
                    })
                }).catch(err => console.log(err));
        }
    };

    const handleSelectProfile = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setSelectProfile(SelectProfile = result?.FinalImages[0]);
                // if (SelectProfile.type === "image/jpeg" || SelectProfile.type === "image/jpg" ||
                //     SelectProfile.type === "image/png") {
                if (VendorDetails2?._id) {
                    setSelectProfileUrl(URL.createObjectURL(SelectProfile));
                    const formData = new FormData();
                    formData.append('ProfileImage', SelectProfile);
                    dispatch({ type: actions.UPLOAD_PROFILE_IMAGE, payload: { id: VendorDetails2?._id, body: formData, Email: VendorDetails2?.Email } })
                    setShowImageError("");
                }
                // } else {
                //     setShowImageError("Unsupported Image format");
                // }
            };
        }
    };

    //add institution list
    const handleAddInstitute = (e) => {
        e.preventDefault();
        setInstituteName('');
        setInstituteNameList([...instituteNameList, instituteName]);
    };

    //remove institution name from institution list
    const RemoveAddedInstituteName = (e, index) => {
        e.preventDefault();
        var splicedList = [...instituteNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setInstituteNameList(splicedList);
    };

    //add cake type list
    const handleAddCakeType = (e) => {
        // e.preventDefault();
        if (e?.value === 'Others') {
            // if (e.target.value === 'Others') {
            setShowOthersPopup(true);
        } else {
            if (cakeTypeNameList.length === 0) {
                setCakeTypeNameList([e?.value]);
                // setCakeTypeNameList([e.target.value]);
            } else {
                if (!cakeTypeNameList.includes(e?.value)) {
                    // if (!cakeTypeNameList.includes(e.target.value)) {
                    // setCakeTypeNameList([...cakeTypeNameList, e.target.value]);
                    setCakeTypeNameList([...cakeTypeNameList, e?.value]);
                };
            };
        };
        resetField('CakeTypesYouBake');
    };

    const handleAddNewCakeType = (e) => {
        e.preventDefault();
        if (cakeTypeName) {
            if (cakeTypeNameList.includes(cakeTypeName)) {
                setShowOthersPopup(true);
                setShowMessage("Cake type already exist")
            } else {
                setShowOthersPopup(false);
                setCakeTypeNameList([...cakeTypeNameList, cakeTypeName]);
                reset({ "CakeTypesYouBake": '' })
            }
        } else {
            setShowOthersPopup(true);
            setShowMessage("Types of other product is required");
        };
    };

    //remove cake type from list
    const RemoveAddedCakeTypeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...cakeTypeNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setCakeTypeNameList(splicedList);
    };

    //add speciality cake list
    const handleAddSpecialityCake = (e) => {
        e.preventDefault();
        setSpecialityCakeName('');
        setSpecialityCakeNameList([...specialityCakeNameList, specialityCakeName]);
    };

    //remove speciality cake from list
    const RemoveAddedSpecialityCakeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...specialityCakeNameList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setSpecialityCakeNameList(splicedList);
    };

    const onSubmit = (data) => {
        if (cakeTypeNameList.length === 0 || specialityCakeNameList.length === 0 || ((instituteNameList?.length === 0 && instituteShow !== 'Self Learning') && (instituteShow === 'At Institution' || VendorDetails2?.LearningType === 'At Institution'))) {
            if (cakeTypeNameList?.length === 0) {
                setShowImageError('Cake Type is Required');
            } else if (specialityCakeNameList?.length === 0) {
                setShowImageError('Speciality Cake is Required');
            }
            // else if(instituteNameList?.length === 0 && (instituteShow === 'At Institution' || VendorDetails2?.LearningType === 'At Institution')){
            else if ((instituteNameList?.length === 0 && instituteShow !== 'Self Learning') && (instituteShow === 'At Institution' || VendorDetails2?.LearningType === 'At Institution')) {
                setShowImageError('Institution Name is Required');


            }
        } else {
            setLoader(true);
            const formData = {
                Email: data.Email,
                VendorName: data.VendorName,
                PreferredNameOnTheApp: data.PreferredNameOnTheApp,
                PhoneNumber1: data.PhoneNumber1,
                PhoneNumber2: data.PhoneNumber2 ? data.PhoneNumber2 : '',
                DoorNo: data.DoorNumber,
                Pincode: data.Pincode,
                Address: data.Address,
                GoogleLocation: coordinates,
                Description: data.Description,
                EggOrEggless: data.EggOrEggless,
                DateOfBirth: moment(moment(data.DateOfBirth, 'YYYY-MM-DD')).format('DD-MM-YYYY'),
                Gender: data.Gender,
                YearsOfExperienceAsBaker: data.YearsOfExperienceAsBaker + ' ' + experienceDuration,
                AadhaarNumber: data.AadhaarNumber,
                PANNumber: data.PANNumber?.toUpperCase(),
                GSTNumber: data.GSTNumber ? data.GSTNumber?.toUpperCase() : '',
                FSSAINumber: data.FSSAINumber?.toUpperCase(),
                FSSAIExpiryDate: moment(moment(data.FSSAIExpiryDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'),
                JobType: data.JobType,
                BankName: data.BankName,
                Branch: data.Branch,
                AccountNumber: data.AccountNumber,
                IFSCCode: data.IFSCCode?.toUpperCase(),
                UPIId: data.UPIId ? data.UPIId : '',
                Ratings: data.Ratings,
                AreYouFamiliarOnWorkingWithApps: data.AreYouFamiliarOnWorkingWithApps,
                LearningType: data.LearningType,
                TotalDurationOfLearning: data.TotalDurationOfLearning + ' ' + learningDuration,
                InstitutionName: instituteNameList,
                CurrentAverageSalePerMonth: data.CurrentAverageSalePerMonth + 'Kg',
                HowManyCakesCanYouMakeInaWeek: data.HowManyCakesCanYouMakeInaWeek + 'Kg',
                HowManyDaysCanYouWorkInaWeek: data.HowManyDaysCanYouWorkInaWeek,
                YourSpecialityCakes: specialityCakeNameList,
                CanYouMakeSingleCakeAbove5Kgs: data.CanYouMakeSingleCakeAbove5Kgs,
                CanYouMakeTierCakes: data.CanYouMakeTierCakes,
                CakeTypesYouBake: cakeTypeNameList,
                CanYouMakeARegularCakeWithFondantAsToppers: data.CanYouMakeARegularCakeWithFondantAsToppers,
                Updated_By: {
                    TypeOfUser: token.type,
                    Name: token?.result?.Name ? token?.result?.Name : token?.result?.AdminName,
                    Email: token?.result?.Email
                }
            };
            if (VendorDetails2?._id) {
                dispatch({ type: actions.UPDATE_VENDOR, payload: { id: VendorDetails2?._id, body: formData, Email: VendorDetails2?.Email } });
            };
        };
    };

    const Type = state?.state?.type;

    useEffect(() => {
        if (UpdateVendorProfileStatus?.statusCode === 200) {
            setLoader(false);
            if (Type === "helpdeskV") {
                history.push('/helpdeskV-vendor-details', { Id: VendorDetails2?._id });
            } else {
                history.push('/admin-vendor-list-detail', { id: VendorDetails2?._id });
            };
        } else if (UpdateVendorProfileStatus?.statusCode === 400) {
            setLoader(false);
        };
        //eslint-disable-next-line
    }, [UpdateVendorProfileStatus]);

    //loading
    useEffect(() => {
        if (CakeType.length === 0 && BankName.length === 0 && VendorDetails2?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            setInstituteNameList(VendorDetails2?.InstitutionName);

            if (VendorDetails2?.length !== 0) {
                setLearningDuration((VendorDetails2?.TotalDurationOfLearning?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[1]);
                setExperienceDuration((VendorDetails2?.YearsOfExperienceAsBaker?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[1])
                setCakeTypeNameList(VendorDetails2?.CakeTypesYouBake);
                setSpecialityCakeNameList(VendorDetails2?.YourSpecialityCakes);
                setSelectProfileUrl(VendorDetails2?.ProfileImage);
                setAddress(VendorDetails2?.Address);
                setCoordinates(VendorDetails2?.GoogleLocation);
                setValue("Address", VendorDetails2?.Address);
                const Data = {
                    Email: VendorDetails2?.Email,
                    VendorName: VendorDetails2?.VendorName,
                    PreferredNameOnTheApp: VendorDetails2?.PreferredNameOnTheApp,
                    PhoneNumber1: VendorDetails2?.PhoneNumber1,
                    PhoneNumber2: VendorDetails2?.PhoneNumber2 ? VendorDetails2?.PhoneNumber2 : '',
                    DoorNo: VendorDetails2?.DoorNo,
                    Pincode: VendorDetails2?.Pincode,
                    Address: VendorDetails2?.Address,
                    GoogleLocation: VendorDetails2?.GoogleLocation,
                    Description: VendorDetails2?.Description,
                    EggOrEggless: VendorDetails2?.EggOrEggless,
                    DateOfBirth: moment(moment(VendorDetails2?.DateOfBirth, 'DD-MM-YYYY')).format('YYYY-MM-DD'),
                    Gender: VendorDetails2?.Gender,
                    YearsOfExperienceAsBaker: (VendorDetails2?.YearsOfExperienceAsBaker?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[0],
                    AadhaarNumber: VendorDetails2?.AadhaarNumber,
                    PANNumber: VendorDetails2?.PANNumber,
                    GSTNumber: VendorDetails2?.GSTNumber ? VendorDetails2?.GSTNumber : '',
                    FSSAINumber: VendorDetails2?.FSSAINumber,
                    FSSAIExpiryDate: moment(moment(VendorDetails2?.FSSAIExpiryDate, 'DD-MM-YYYY')).format('YYYY-MM-DD'),
                    JobType: VendorDetails2?.JobType,
                    BankName: VendorDetails2?.BankName,
                    Branch: VendorDetails2?.Branch,
                    AccountNumber: VendorDetails2?.AccountNumber,
                    IFSCCode: VendorDetails2?.IFSCCode,
                    UPIId: VendorDetails2?.UPIId ? VendorDetails2?.UPIId : '',
                    Ratings: VendorDetails2?.Ratings,
                    AreYouFamiliarOnWorkingWithApps: VendorDetails2?.AreYouFamiliarOnWorkingWithApps,
                    LearningType: VendorDetails2?.LearningType,
                    TotalDurationOfLearning: VendorDetails2?.TotalDurationOfLearning?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0],
                    CurrentAverageSalePerMonth: VendorDetails2?.CurrentAverageSalePerMonth?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0],
                    HowManyCakesCanYouMakeInaWeek: VendorDetails2?.HowManyCakesCanYouMakeInaWeek?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0],
                    HowManyDaysCanYouWorkInaWeek: VendorDetails2?.HowManyDaysCanYouWorkInaWeek,
                    CanYouMakeSingleCakeAbove5Kgs: VendorDetails2?.CanYouMakeSingleCakeAbove5Kgs,
                    CanYouMakeTierCakes: VendorDetails2?.CanYouMakeTierCakes,
                    CanYouMakeARegularCakeWithFondantAsToppers: VendorDetails2?.CanYouMakeARegularCakeWithFondantAsToppers,
                };
                reset(Data);
            }
        }
        //eslint-disable-next-line
    }, [VendorDetails2, CakeType, BankName]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        {state?.state?.type === "helpdeskV" ?
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb pl-0">
                                    <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/helpdeskV-vendors-list">Vendors List</Link></li>
                                    <li className="breadcrumb-item"><Link to={{ pathname: "/helpdeskV-vendor-details", state: { Id: VendorDetails2?._id } }}>Vendor Details</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Edit Vendor Details</li>
                                </ol>
                            </nav>
                            :
                            <Breadcrumb id={VendorDetails2?._id} />
                        }
                    </div>
                    <div className="ms-profile-overview">
                        <div className="ms-profile-cover">
                            <div className='profile-img-div'>
                                <img className="ms-profile-img" src={SelectProfileUrl} alt="people" />
                                <div className='profile-upload-i'>
                                    <input
                                        type='file'
                                        accept='image/jpg,image/jpeg,image/png'
                                        className='profile-img-upload'
                                        name='file'
                                        onChange={handleSelectProfile}
                                    />
                                    <i className="fa fa-upload"></i>
                                </div>
                            </div>
                            <div className="ms-profile-user-info register-header">
                                <h4 className="ms-profile-username text-white ">Edit Vendor Details</h4>
                            </div>
                        </div>
                    </div>
                    <p className='text-danger'>{showImageError}</p>
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-header"><h6>BASIC INFORMATION</h6></div>
                                <div className="ms-panel-body tabledata-background">
                                    <form className="needs-validation clearfix" noValidate="">
                                        <div className="form-row">
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Email</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.Email ? "form-control required-field text-lowercase" : "form-control text-lowercase"}
                                                        // className={errors?.Email ? "form-control required-field text-lowercase" : "form-control text-lowercase"}
                                                        {...register('Email')}
                                                        defaultValue={VendorDetails2?.Email}
                                                        placeholder="Email"
                                                        name='Email'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Prefered Name on the App</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.VendorName ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        {...register('VendorName')}
                                                        defaultValue={VendorDetails2?.VendorName}
                                                        placeholder="Prefered Name"
                                                        name='VendorName'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Vendor Name</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.PreferredNameOnTheApp ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        defaultValue={VendorDetails2?.PreferredNameOnTheApp}
                                                        placeholder="Vendor Name"
                                                        name='PreferredNameOnTheApp'
                                                        {...register('PreferredNameOnTheApp')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Gender</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={errors?.Gender ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                        defaultValue={VendorDetails2?.Gender}
                                                        name='Gender'
                                                        {...register('Gender')}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Male'>Male</option>
                                                        <option value='Female'>Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Date of Birth</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type='date'
                                                        className={errors?.DateOfBirth ? "form-control required-field" : "form-control"}
                                                        defaultValue={VendorDetails2?.DateOfBirth?.split("-")?.reverse()?.join("-")}
                                                        maxLength={4}
                                                        max={new Date()?.toISOString()?.slice(0, 10)}
                                                        pattern="[1-9][0-9]{3}"
                                                        name='DateOfBirth'
                                                        {...register('DateOfBirth')}
                                                    />
                                                </div>
                                                <p className='text-danger'>{errors?.DateOfBirth?.message === 'Please choose past date' ? 'Please choose past date' : ''}</p>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Egg or Eggless</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className={errors?.EggOrEggless ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.EggOrEggless} name='EggOrEggless' {...register('EggOrEggless')}>
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value='Egg'>Only Egg</option>
                                                        <option value='Eggless'>Only Eggless</option>
                                                        <option value='Egg and Eggless'>Egg and Eggless</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Address Line 1</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="text"
                                                        className={errors?.DoorNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Door No/ Block No"
                                                        name='DoorNumber'
                                                        {...register('DoorNumber')}
                                                        defaultValue={VendorDetails2?.DoorNo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8 mb-3 px-md-2">
                                                <label htmlFor="validationCustom18"><b>Address Line 2</b> <span className='text-danger'>(Click add button to enter address)</span> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="text"
                                                            disabled
                                                            className={errors?.Address ? "form-control required-field disabled-form-control" : "form-control disabled-form-control"}
                                                            placeholder="Address"
                                                            name='Address'
                                                            {...register('Address')}
                                                            defaultValue={VendorDetails2?.Address}
                                                            onChange={() => setShow(true)}
                                                        />
                                                        <button className='add-button rounded disabled-add' onClick={handleShowAddressPopup}>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Pincode</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        maxLength={6}
                                                        onInput={NumberValidation}
                                                        className={errors?.Pincode ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                        placeholder="Pincode"
                                                        defaultValue={VendorDetails2?.Pincode}
                                                        name='Pincode'
                                                        {...register('Pincode')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Main Mobile Number</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={10}
                                                        onInput={NumberValidation}
                                                        className={errors?.PhoneNumber1 ? "form-control required-field" : "form-control"}
                                                        placeholder="Mobile Number"
                                                        defaultValue={VendorDetails2?.PhoneNumber1}
                                                        name='PhoneNumber1'
                                                        {...register('PhoneNumber1')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 mb-3 px-md-2">
                                                <label htmlFor="validationCustom25"><b>Alternative Mobile Number</b></label>
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={10}
                                                        onInput={NumberValidation}
                                                        className={errors?.PhoneNumber2 ? "form-control required-field" : "form-control"}
                                                        placeholder="Alternative Mobile Number"
                                                        defaultValue={VendorDetails2?.PhoneNumber2}
                                                        name='PhoneNumber2'
                                                        {...register('PhoneNumber2')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-header">
                                    <h6>BANK DETAILS</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Bank Name</b> <span className='text-danger'>*</span></label>
                                            <Select
                                                className={errors?.BankName ? "required-field text-uppercase" : "text-uppercase"}
                                                styles={customStyles}
                                                options={bankNameList}
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                name='BankName'
                                                {...register('BankName')}
                                                onChange={handleSelectBankName}
                                                value={selectedBankName}
                                            />
                                            {/* <div className="input-group">
                                                        <div className='display-flex w-100'>
                                                            {BankName?.message === 'No Records Found' ?
                                                                <select className='form-control'>
                                                                    <option className='text-center'>No Records Found</option>
                                                                </select> :
                                                                <select defaultValue={VendorDetails2?.BankName} className={errors?.BankName ? "form-control required-field text-uppercase" : "form-control text-uppercase"} placeholder="Bank Name" name='BankName' {...register('BankName')}>
                                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                                    {BankName?.map((val, i) =>
                                                                        <option key={i} className='font-weight-bold' value={val.BankName}>{val.BankName}</option>
                                                                    )}
                                                                </select>
                                                            }
                                                        </div>
                                                    </div> */}
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Branch</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className={errors?.Branch ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                    placeholder="Branch"
                                                    name='Branch'
                                                    defaultValue={VendorDetails2?.Branch}
                                                    {...register('Branch')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Account Number</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className={errors?.AccountNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                    placeholder="Account Number"
                                                    defaultValue={VendorDetails2?.AccountNumber}
                                                    name='AccountNumber'
                                                    {...register('AccountNumber')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>IFSC Code</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className={errors?.IFSCCode ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                    placeholder="IFSC Code"
                                                    defaultValue={VendorDetails2?.IFSCCode}
                                                    name='IFSCCode'
                                                    {...register('IFSCCode')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>UPI ID</b></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control text-lowercase"
                                                    placeholder="UPI ID"
                                                    defaultValue={VendorDetails2?.UPIId}
                                                    name='UPIId'
                                                    {...register('UPIId')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-header">
                                    <h6> VENDOR IDENTITY AND LICENSE DETAILS</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Aadhaar Number</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    maxLength={14}
                                                    onInput={AadharNumberValidation}
                                                    className={errors?.AadhaarNumber ? "form-control required-field" : "form-control"}
                                                    defaultValue={VendorDetails2?.AadhaarNumber}
                                                    placeholder="Aadhaar Number"
                                                    name='AadhaarNumber'
                                                    {...register('AadhaarNumber')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label><b>PAN Number</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    // min={0}
                                                    // maxLength={14}
                                                    // onInput={NumberValidation}
                                                    className={errors?.PANNumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                    defaultValue={VendorDetails2?.PANNumber}
                                                    placeholder="PAN Number"
                                                    name='PANNumber'
                                                    {...register('PANNumber')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label><b>FSSAI Number</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    min={0}
                                                    maxLength={14}
                                                    onInput={NumberValidation}
                                                    className={errors?.FSSAINumber ? "form-control required-field text-uppercase" : "form-control text-uppercase"}
                                                    defaultValue={VendorDetails2?.FSSAINumber}
                                                    placeholder="FSSAI Number"
                                                    name='FSSAINumber'
                                                    {...register('FSSAINumber')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>FSSAI Expiry Date</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="date"
                                                    className={errors?.FSSAIExpiryDate ? "form-control required-field" : "form-control"}
                                                    placeholder="FSSAI Expiry Date"
                                                    defaultValue={VendorDetails2?.FSSAIExpiryDate?.split("-")?.reverse()?.join("-")}
                                                    maxLength={4}
                                                    max={"9999-12-31"}
                                                    min={new Date()?.toISOString()?.slice(0, 10)}
                                                    name='FSSAIExpiryDate'
                                                    {...register('FSSAIExpiryDate')}
                                                />
                                            </div>
                                            <p className='text-danger'>{errors?.FSSAIExpiryDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom24"><b>GST Number</b></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control text-uppercase"
                                                    placeholder="GST Number"
                                                    name='GSTNumber'
                                                    defaultValue={VendorDetails2?.GSTNumber}
                                                    {...register('GSTNumber')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-header">
                                    <h6>OTHER DETAILS</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Learning Type</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.LearningType && !instituteShow ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.LearningType} name='LearningType' {...register('LearningType')} onChange={(e) => setInstituteShow(e.target.value)}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='Self Learning'>Self Learning</option>
                                                    <option value='At Institution'>At Institution</option>
                                                </select>
                                            </div>
                                        </div>
                                        {/* {VendorDetails2?.LearningType === 'At Institution' && */}
                                        {((instituteShow === 'At Institution') || (VendorDetails2?.LearningType === 'At Institution' && instituteShow !== 'Self Learning')) &&
                                            <div className="col-md-8 mb-3">
                                                <label htmlFor="validationCustom25"><b>Institution Name</b> <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="text"
                                                            className={instituteNameList?.length === 0 ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                            // className="form-control text-capitalize"
                                                            placeholder="Enter Institution name"
                                                            value={instituteName}
                                                            onChange={(e) => setInstituteName(e.target.value)}
                                                        />
                                                        <button className='add-button rounded disabled-add'
                                                            disabled={(instituteNameList?.length === 5 || instituteName === '') && true}
                                                            onClick={handleAddInstitute}
                                                        >Add</button>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        {instituteNameList?.map((value, index) =>
                                                            <div key={index}>
                                                                <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right'
                                                                    onClick={(e) => RemoveAddedInstituteName(e, index)}
                                                                >X</button></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Total Duration of Learning</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <div className='display-flex w-100'>
                                                    <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={2} onInput={NumberValidation}
                                                        defaultValue={VendorDetails2?.length !== 0 ? VendorDetails2?.TotalDurationOfLearning?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0] : ''}
                                                        className={errors?.TotalDurationOfLearning ? "form-control required-field" : "form-control"} placeholder="example: 6" name='TotalDurationOfLearning' {...register('TotalDurationOfLearning')} />
                                                    <select className='form-control w-50' defaultValue={learningDuration}
                                                        onChange={(e) => setLearningDuration(e.target.value)}
                                                    >
                                                        <option value='years'>years</option>
                                                        <option value='months'>months</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Maximum Capacity to Bake in a Week (in kgs)</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    onWheel={() => document.activeElement.blur()}
                                                    min={0}
                                                    maxLength={2}
                                                    onInput={NumberValidation}
                                                    defaultValue={VendorDetails2?.length !== 0 ? (VendorDetails2?.HowManyCakesCanYouMakeInaWeek?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[0] : ''}
                                                    className={errors?.HowManyCakesCanYouMakeInaWeek ? "form-control required-field" : "form-control"}
                                                    placeholder="Example: 5"
                                                    name='HowManyCakesCanYouMakeInaWeek'
                                                    {...register('HowManyCakesCanYouMakeInaWeek')}
                                                />
                                                <button disabled>Kg</button>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Current Average Sales Per Month (in kgs)</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    onWheel={() => document.activeElement.blur()}
                                                    min={0}
                                                    maxLength={3}
                                                    onInput={NumberValidation}
                                                    defaultValue={VendorDetails2?.length !== 0 ? (VendorDetails2?.CurrentAverageSalePerMonth?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[0] : ''}
                                                    className={errors?.CurrentAverageSalePerMonth ? "form-control required-field" : "form-control"}
                                                    placeholder="Example: 5"
                                                    name='CurrentAverageSalePerMonth'
                                                    {...register('CurrentAverageSalePerMonth')}
                                                />
                                                <button disabled>Kg</button>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Baking Experience</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <div className='display-flex w-100'>
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={2}
                                                        onInput={NumberValidation}
                                                        defaultValue={VendorDetails2?.length !== 0 ? (VendorDetails2?.YearsOfExperienceAsBaker?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi))[0] : ''}
                                                        className={errors?.YearsOfExperienceAsBaker ? "form-control required-field" : "form-control"}
                                                        placeholder="Example : 3"
                                                        name='YearsOfExperienceAsBaker'
                                                        {...register('YearsOfExperienceAsBaker')} />
                                                    <select className='form-control w-50' defaultValue={experienceDuration}
                                                        onChange={(e) => setExperienceDuration(e.target.value)}
                                                    >
                                                        <option value='years'>years</option>
                                                        <option value='months'>months</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Number of Days You Can Bake In a Week</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select defaultValue={VendorDetails2?.HowManyDaysCanYouWorkInaWeek} className={errors?.HowManyDaysCanYouWorkInaWeek ? "form-control required-field" : "form-control"} placeholder="example: 5" name='HowManyDaysCanYouWorkInaWeek' {...register('HowManyDaysCanYouWorkInaWeek')}>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Type of Baker</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.JobType ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.JobType} name='JobType' {...register('JobType')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='Part Time'>part Time</option>
                                                    <option value='Full Time'>Full Time</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Can You Bake Cakes Above 5kg</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.CanYouMakeSingleCakeAbove5Kgs ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.CanYouMakeSingleCakeAbove5Kgs} name='CanYouMakeSingleCakeAbove5Kgs' {...register('CanYouMakeSingleCakeAbove5Kgs')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='y'>Yes</option>
                                                    <option value='n'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Do You Make Tier Cake</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.CanYouMakeTierCakes ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.CanYouMakeTierCakes} name='CanYouMakeTierCakes' {...register('CanYouMakeTierCakes')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='y'>Yes</option>
                                                    <option value='n'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Comfortable on Working With Mobile Apps</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.AreYouFamiliarOnWorkingWithApps ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.AreYouFamiliarOnWorkingWithApps} name='AreYouFamiliarOnWorkingWithApps' {...register('AreYouFamiliarOnWorkingWithApps')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='y'>Yes</option>
                                                    <option value='n'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Types of Cake/Other Product</b> <span className='text-danger'>*</span></label>
                                            <Select
                                                // className={errors?.CakeTypesYouBake && cakeTypeNameList?.length === 0 ? "required-field" : ""}
                                                className={cakeTypeNameList?.length === 0 ? "required-field" : ""}
                                                styles={customStyles}
                                                options={cakeTypeList}
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                name='CakeTypesYouBake'
                                                {...register('CakeTypesYouBake')}
                                                onChange={handleAddCakeType}
                                            />
                                            <div className="input-group">
                                                {/* <div className='display-flex w-100'>
                                                            <select defaultValue='' className={errors?.CakeTypesYouBake && cakeTypeNameList?.length === 0 ? "form-control required-field" : "form-control"} placeholder="types of cakes" name='CakeTypesYouBake' {...register('CakeTypesYouBake')} onChange={handleAddCakeType}>
                                                                <option value='' disabled='disabled'>-- Select --</option>
                                                                {CakeType?.map((val, i) =>
                                                                    <>{val.SubType.length === 0 ?
                                                                        <option className='font-weight-bold' value={val.Type}>{val.Type}</option> :
                                                                        <optgroup label={val.Type}>
                                                                            {val.SubType?.map((s, ind) =>
                                                                                <option value={`${val.Type} - ${s.Name}`}>{ind + 1}. {s.Name}</option>
                                                                            )}
                                                                        </optgroup>
                                                                    }</>
                                                                )}
                                                                <option value='Others' className='font-weight-bold'>Others</option>
                                                            </select>
                                                        </div> */}
                                                {cakeTypeNameList?.map((value, index) =>
                                                    <div key={index}>
                                                        <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeTypeName(e, index)}>X</button></div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Your Speciality Cakes</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <div className='display-flex w-100'>
                                                    <input type="text" className={specialityCakeNameList?.length === 0 ? "form-control required-field text-capitalize" : "form-control text-capitalize"} placeholder="speciality cakes" value={specialityCakeName} onChange={(e) => setSpecialityCakeName(e.target.value)} />
                                                    <button className='add-button rounded disabled-add' disabled={specialityCakeNameList?.length === 5 || specialityCakeName?.length === 0 ? true : false}
                                                        onClick={handleAddSpecialityCake}
                                                    >Add</button>
                                                </div>
                                                <div className='col-md-12'>
                                                    {specialityCakeNameList?.map((value, index) =>
                                                        <div key={index}>
                                                            <div className='list-map'>{value} &#160; <button className='btn-secondary list-map-remove float-right'
                                                                onClick={(e) => RemoveAddedSpecialityCakeName(e, index)}
                                                            >X</button></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Self Rating</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select defaultValue={VendorDetails2?.Ratings} className={errors?.Ratings ? "form-control required-field fa-select" : "form-control fa-select"} placeholder="Example: 4" name='Ratings' {...register('Ratings')}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value="1">1 - Poor &#160; &#160; &#160; &#160; &#160; &#160; &#160; &#xf005;</option>
                                                    <option value="2">2 - Weak &#160; &#160; &#160; &#160; &#160; &#160; &#xf005; &#xf005;</option>
                                                    <option value="3">3 - Good &#160; &#160; &#160; &#160; &#160; &#160; &#xf005; &#xf005; &#xf005;</option>
                                                    <option value="4">4 - Very Good &#160; &#xf005; &#xf005; &#xf005; &#xf005;</option>
                                                    <option value="5">5 - Excellent &#160; &#160; &#xf005; &#xf005; &#xf005; &#xf005; &#xf005;</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5 mb-3 px-md-2">
                                            <label htmlFor="validationCustom25"><b>Do You Bake Regular Cake With Fondant Topper</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className={errors?.CanYouMakeARegularCakeWithFondantAsToppers && !showImageSelect ? "form-control required-field" : "form-control"} defaultValue={VendorDetails2?.CanYouMakeARegularCakeWithFondantAsToppers} name='CanYouMakeARegularCakeWithFondantAsToppers' {...register('CanYouMakeARegularCakeWithFondantAsToppers')} onChange={(e) => setShowImageSelect(e.target.value)}>
                                                    <option value='' disabled='disabled'>-- Select --</option>
                                                    <option value='y'>Yes</option>
                                                    <option value='n'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3 px-md-2">
                                            <h6 className='pl-2'>Example for regular cake with Fondant Toppers</h6>
                                            <hr />
                                            <div>
                                                <img className="cake-carousel-item" src={FondantTopperImages[0]} alt="cake" height={300} width={300} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom25"><b>Description</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <textarea
                                                    type="text"
                                                    maxLength={400}
                                                    defaultValue={VendorDetails2?.Description}
                                                    className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"}
                                                    placeholder="Description"
                                                    name='Description'
                                                    {...register('Description')}
                                                />
                                            </div>
                                        </div>
                                        {loader ? <CircleDotSpinner /> :
                                            <>
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmit)}
                                                >
                                                    Update
                                                </button>
                                                <button
                                                    className="btn btn-secondary ml-4"
                                                    type="submit"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (state?.state?.type === "helpdeskV") {
                                                            history.push('/helpdeskV-vendor-details', { Id: VendorDetails2?._id })
                                                        } else {
                                                            history.push('/admin-vendor-list-detail', { id: VendorDetails2?._id })
                                                        }
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-min" show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1>Enter your Address</h1>
                            <form>
                                <div className="ms-form-group has-icon">
                                    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input className='form-control' {...getInputProps({ placeholder: 'Type here ...' })} />
                                                {loading ? <div>Loading...</div> : null}
                                                {suggestions.map((suggestion, i) => {
                                                    const style = suggestion.active ? { backgroundColor: '#d4d2d2', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                                                    return (
                                                        <div key={i} {...getSuggestionItemProps(suggestion, { style })}>
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                                <button type="submit" onClick={handleGetLatLng} className="btn btn-primary shadow-none">Ok</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showOthersPopup} onHide={() => setShowOthersPopup(false)} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1>Enter Other Product</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <input type="text" className="form-control text-capitalize" placeholder="Types of other product" onChange={(e) => setCakeTypeName(e.target.value)} />
                                    </div>
                                </div>
                                <p className='text-danger'>{showMessage}</p>
                                <button
                                    type="submit"
                                    onClick={handleAddNewCakeType}
                                    className="btn btn-primary shadow-none mr-3"
                                >
                                    Ok
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleClose}
                                    className="btn btn-secondary shadow-none"
                                >
                                    Cancel
                                </button>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
}

export default Content;