import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import CircleDotSpinner from '../../../../common/CircleDotSpinner';
import { CouponGenerator } from '../../../../common/CouponGenerator';
import { useSelector } from 'react-redux';
import { PushNotification } from '../../../../common/PushNotification';
import { NumberValidation, float2D } from '../../../../../utils/Validation';

export default function RefundPopup(props) {

    const { showRefundPopup, handleCloseRefundPopup, TicketDetails, OrderDetailsByTypeOfOrder,
        handleRefundPayment, loader, CouponCodeList } = props;

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    let [couponList, setCouponList] = useState([]);
    let [refundType, setRefundType] = useState('');
    let [validation, setValidation] = useState(false);
    let [couponCode, setCouponCode] = useState('');
    let [couponType, setCouponType] = useState('');
    let [couponValue, setCouponValue] = useState('');

    useEffect(() => {
        if (CouponCodeList.length === 0) {
            setCouponList([]);
        } else {
            if (CouponCodeList?.message === "No Records Found") {
                setCouponList([]);
            } else {
                setCouponList(CouponCodeList);
            };
        };
    }, [CouponCodeList]);

    const handleRefundType = (e) => {
        e.preventDefault();
        setRefundType(e.target.value);
        if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
            if (e.target.value === 'Coupon') {
                setCouponCode('');
                setCouponType('Amount');
                setCouponValue(float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) - parseFloat(TicketDetails?.Cancellation_Charges)));
                setValidation(false);
            } else if (e.target.value === 'Cash') {
                setCouponCode('');
                setCouponType('');
                setCouponValue('');
                setValidation(false);
            }
        } else {
            setCouponCode('');
            setCouponType('');
            setCouponValue('');
            setValidation(false);
        };
    };

    const handleGenerateCoupon = async () => {
        const CouponCode = await CouponGenerator();
        if (couponList.length > 0) {
            let CouponValidate = couponList.find(val => val.Code === CouponCode);
            if (CouponValidate) {
                setValidation(true)
                setCouponCode('');
            } else {
                setValidation(false)
                setCouponCode(CouponCode)
            };
        } else {
            setCouponCode(CouponCode);
        };
    };

    const handleClose = (e) => {
        setCouponCode('');
        setCouponType('');
        setCouponValue('');
        setRefundType('');
        setValidation(false);
        handleCloseRefundPopup(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let Data;
        if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') {
            let bodyContent;
            (refundType === 'Cash') ? bodyContent = `Your cancelled order's(${TicketDetails?.Order_ID}) payment is refunded successfully` :
                bodyContent = `Coupon will be provided for your cancellation order(${TicketDetails?.Order_ID})`;
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: bodyContent,
                }
                PushNotification(pushMessageData);
            };
            if (refundType === 'Cash') {
                Data = {
                    RefundType: refundType,
                    Total_Amount: (parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ?
                        (OrderDetailsByTypeOfOrder?.NewTotal) : (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue)),
                    Cancellation_Charges: TicketDetails?.Cancellation_Charges,
                    Refund_Amount: TicketDetails?.Refund_Amount,
                    For_Display: `Your cancelled order's(${TicketDetails?.Order_ID}) payment is refunded successfully`,
                }
            } else {
                Data = {
                    RefundType: refundType,
                    Total_Amount: (parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ?
                        (OrderDetailsByTypeOfOrder?.NewTotal) : (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue)),
                    Cancellation_Charges: TicketDetails?.Cancellation_Charges,
                    Refund_Amount: TicketDetails?.Refund_Amount,
                    CouponCode: couponCode,
                    CouponType: couponType,
                    CouponValue: couponValue,
                    For_Display: `Received Coupon for your Cancelled Order(${TicketDetails?.Order_ID})`,
                }
            };
        } else if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor') {
            let bodyContent;
            (TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Cash') ? bodyContent = `Your cancelled order's(${TicketDetails?.Order_ID}) payment is refunded successfully` :
                (TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon') ?
                    bodyContent = `Coupon will be provided for your cancellation order(${TicketDetails?.Order_ID})` :
                    (TicketDetails?.Ticket_Approved_Status === 'Approved' && refundType === 'Cash') ?
                        bodyContent = `Your order's(${TicketDetails?.Order_ID}) excess payment is refunded successfully` :
                        bodyContent = `Coupon will be provided for your order(${TicketDetails?.Order_ID})'s excess amount`;

            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: bodyContent,
                }
                PushNotification(pushMessageData);
            };
            if (TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Cash') {
                Data = {
                    RefundType: 'Cash',
                    Total_Amount: OrderDetailsByTypeOfOrder?.Total,
                    Refund_Amount: OrderDetailsByTypeOfOrder?.Total,
                    For_Display: `Your Cancelled Order(${TicketDetails?.Order_ID}) Payment is Refunded Successfully`,
                }
            } else if (TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon') {
                Data = {
                    RefundType: 'Coupon',
                    Total_Amount: OrderDetailsByTypeOfOrder?.Total,
                    Refund_Amount: OrderDetailsByTypeOfOrder?.Total,
                    CouponCode: couponCode,
                    CouponType: couponType,
                    CouponValue: couponValue,
                    For_Display: `Received Coupon for your Cancelled Order(${TicketDetails?.Order_ID})`,
                }
            } else if (TicketDetails?.Ticket_Approved_Status === 'Approved') {
                if (refundType === 'Cash') {
                    Data = {
                        RefundType: refundType,
                        Total_Amount: OrderDetailsByTypeOfOrder?.Total,
                        Refund_Amount: TicketDetails?.Refund_Amount,
                        // For_Display: `Your Order(${TicketDetails?.Order_ID}) Excess Amount is Processed Successfully will be Credited in your Account Shortly`,
                        For_Display: `Refund of Excess Amount on (${TicketDetails?.Order_ID}) is Processed Successfully, Will be Credited in your Account in 2-3 Working Days`,
                    }
                } else {
                    Data = {
                        RefundType: refundType,
                        Total_Amount: OrderDetailsByTypeOfOrder?.Total,
                        Refund_Amount: TicketDetails?.Refund_Amount,
                        CouponCode: couponCode,
                        CouponType: couponType,
                        CouponValue: couponValue,
                        For_Display: `You have Received Coupon(${couponCode} - Rs. ${couponValue}) towards Refund of Excess Amount on your Order(${TicketDetails?.Order_ID})`,
                    }
                };
            }
        }
        handleRefundPayment(e, Data);
    };

    useEffect(() => {
        if (validation) {
            handleGenerateCoupon();
            setValidation(false);
        };
        //eslint-disable-next-line
    }, [validation]);

    const handleDefaultCoupon = (e) => {
        setCouponType(e.target.value)
        let defaultCouponValue = 0;
        defaultCouponValue = TicketDetails?.Refund_Amount;
        setCouponValue(defaultCouponValue)
    }
    return (
        <div>
            <Modal show={showRefundPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Payment Refund</h4>
                    {OrderDetailsByTypeOfOrder?.length === 0 ?
                        <div className="text-center mt-5 mb-5">
                            <p>Loading...</p>
                        </div> :
                        <>
                            {(TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer') &&
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Type<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className='form-control' defaultValue='' onChange={handleRefundType}>
                                                <option disabled value=''>--Select--</option>
                                                <option value='Cash'>Cash</option>
                                                {/* <option value='Coupon'>Coupon</option> */}
                                            </select>
                                        </div>
                                    </div>
                                    {refundType === 'Cash' &&
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Total Amount</label>
                                                <div className="input-group">
                                                    <input className='form-control' disabled defaultValue={(parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) && parseFloat(OrderDetailsByTypeOfOrder?.Refund_Amount) > 0) ?
                                                        parseFloat(OrderDetailsByTypeOfOrder?.NewTotal) : (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue))} />
                                                    {/* <input className='form-control' disabled defaultValue={OrderDetailsByTypeOfOrder?.Total} /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cancellation Charges</label>
                                                <div className="input-group">
                                                    <input className='form-control' disabled defaultValue={TicketDetails?.Cancellation_Charges} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Amount</label>
                                                <div className="input-group">
                                                    <input className='form-control' disabled defaultValue={float2D(TicketDetails?.Refund_Amount)} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {refundType === 'Coupon' &&
                                        <>
                                            <div className="col-md-12">
                                                <div className="ms-panel-body tabledata-background">
                                                    <h4 className="bold text-center">Refund Amount Details</h4>
                                                    <table className="table ms-profile-information">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Total Amount</th>
                                                                <td>Rs. {parseFloat(OrderDetailsByTypeOfOrder?.Total)?.toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Cancellation Charges</th>
                                                                <td>Rs. {parseFloat(TicketDetails?.Cancellation_Charges)?.toFixed(2)}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Refund Amount</th>
                                                                <td><b>Rs. {float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) - parseFloat(TicketDetails?.Cancellation_Charges))}</b></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Generate Coupon Code<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input type="text" className="form-control" value={couponCode || ''} disabled />
                                                        <button className='add-button rounded disabled-add' onClick={handleGenerateCoupon} disabled={couponCode ? true : false}>Generate</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Type<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='form-control'>Amount</div>
                                                    {/* <select className='form-control' defaultValue='' onChange={(e) => setCouponType(e.target.value)}>
                                                        <option value='' disabled>--Select--</option>
                                                        <option value='Percentage'>Percentage</option>
                                                        <option value='Amount'>Amount</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Value {couponType}<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type='number'
                                                        onWheel={() => document.activeElement.blur()}
                                                        className='form-control'
                                                        placeholder='Enter Here...'
                                                        value={couponValue}
                                                        // defaultValue={float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) - parseFloat(TicketDetails?.Cancellation_Charges))}
                                                        // disabled={couponType ? false : true}
                                                        onChange={(e) => setCouponValue(e.target.value)}
                                                        maxLength={10} min={0}
                                                        onInput={NumberValidation}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' &&
                                <div className="form-row">
                                    {TicketDetails?.Ticket_Approved_Status === 'Approved' &&
                                        <div className="col-md-12 mb-3">
                                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Type<span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select className='form-control' defaultValue='' onChange={handleRefundType}>
                                                    <option disabled value=''>--Select--</option>
                                                    <option value='Cash'>Cash</option>
                                                    {/* <option value='Coupon'>Coupon</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {(TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Cash' || (refundType === 'Cash' && TicketDetails?.Ticket_Approved_Status === 'Approved')) &&

                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Total Amount<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input className='form-control' disabled defaultValue={parseFloat(OrderDetailsByTypeOfOrder?.Total)?.toFixed(2)} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Amount<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input className='form-control' disabled defaultValue={parseFloat(TicketDetails?.Refund_Amount).toFixed(2)} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {(TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon' || (refundType === 'Coupon' && TicketDetails?.Ticket_Approved_Status === 'Approved')) &&
                                        <>
                                            <div className="col-md-12">
                                                <div className="ms-panel-body tabledata-background">
                                                    {/* <h4 className="bold text-center">Refund Amount Details</h4> */}
                                                    <table className="table ms-profile-information">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Total Amount</th>
                                                                <td>Rs. {parseFloat(OrderDetailsByTypeOfOrder?.Total)?.toFixed(2)}</td>
                                                            </tr>
                                                            {TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon' &&
                                                                <tr>
                                                                    <th scope="row">Refund Amount</th>
                                                                    <td><b>Rs. {parseFloat(OrderDetailsByTypeOfOrder?.Total)?.toFixed(2)}</b></td>
                                                                </tr>
                                                            }
                                                            {(refundType === 'Coupon' && TicketDetails?.Ticket_Approved_Status === 'Approved') &&
                                                                <tr>
                                                                    <th scope="row">Refund Amount</th>
                                                                    <td><b>Rs.{parseFloat(TicketDetails?.Refund_Amount).toFixed(2)} </b></td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Generate Coupon Code<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input type="text" className="form-control" value={couponCode || ''} disabled />
                                                        <button className='add-button rounded disabled-add' onClick={handleGenerateCoupon} disabled={couponCode ? true : false}>Generate</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Type<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select className='form-control' defaultValue='' onChange={handleDefaultCoupon}>
                                                        <option value='' disabled>--Select--</option>
                                                        {/* <option value='Percentage'>Percentage</option> */}
                                                        <option value='Amount'>Amount</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Value Amount<span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        type='number'
                                                        onWheel={() => document.activeElement.blur()}
                                                        className='form-control'
                                                        placeholder='Enter Here...'
                                                        value={couponValue}
                                                        disabled={couponType ? false : true}
                                                        onChange={(e) => setCouponValue(e.target.value)}
                                                        maxLength={10} min={0}
                                                        onInput={NumberValidation}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                            }
                        </>
                    }

                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            {TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' &&
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    disabled={(TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' &&
                                        (refundType && ((refundType === 'Cash') || (refundType === 'Coupon' && couponCode && couponType && couponValue)))) ? false : true}
                                    onClick={handleSubmit}
                                >
                                    Intimate to Customer Helpdesk
                                </button>
                            }
                            {TicketDetails?.CauseOfTicket === 'Order Cancellation by Vendor' &&
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    disabled={
                                        ((TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Cash') ||
                                            (TicketDetails?.Ticket_Approved_Status === 'Cancel and Refund Coupon' && couponCode && couponType && couponValue) ||
                                            (TicketDetails?.Ticket_Approved_Status === 'Approved' && (refundType && ((refundType === 'Cash') || (refundType === 'Coupon' && couponCode && couponType && couponValue))))) ? false : true}
                                    onClick={handleSubmit}
                                >
                                    Intimate to Vendor Helpdesk
                                </button>
                            }

                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};