import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { float2D } from '../../../../utils/Validation';
// import OrderActions from '../../../../redux/Order/actions';
// import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';

export default function HelpdeskVEditPriceDirectVendorCustomizedCakePopup(props) {

    const { showHelpdeskVEditPriceForVendorCCPopup, handleCloseEditPricePopup,
        OrderDetailsByTypeOfOrder, handleCustomizedCakePriceByHelpdeskV, loader } = props;

    // const dispatch = useDispatch();

    // const [price, setPrice] = useState();

    const { Tax } = useSelector(state => state.OrderReducer);

    const { Invoice_Calculation } = useSelector(state => state.commonReducer);

    // useEffect(() => {
    //     dispatch({ type: OrderActions.GET_TAX });
    //     //eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //     // let TaxAmount = 0, weight;
    //     if (Tax?.length !== 0 && OrderDetailsByTypeOfOrder && Invoice_Calculation?.length === 0) {
    //         let formData = {
    //             Type: "Kg",
    //             Price: OrderDetailsByTypeOfOrder?.Vendor_Price,
    //             ItemCount: OrderDetailsByTypeOfOrder?.ItemCount || "1",
    //             Flavour: OrderDetailsByTypeOfOrder?.Flavour,
    //             Shape: { Name: OrderDetailsByTypeOfOrder?.Shape, Price: "0" },
    //             Weight: OrderDetailsByTypeOfOrder?.Weight,
    //             Discount: OrderDetailsByTypeOfOrder?.Discount,
    //             DeliveryCharge: OrderDetailsByTypeOfOrder?.DeliveryCharge,
    //             Tax: Tax[0]?.Total_GST || "0",
    //             CalculationFor: "Ticket"
    //         };
    //         dispatch({ type: CommonActions.GET_INVOICE_CALCULATION, payload: { type: "CCO", data: formData } })
    //         // (OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1] === 'g') ? weight = [parseFloat(OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) / 1000] :
    //         //     weight = OrderDetailsByTypeOfOrder?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi);
    //         // if (weight) {
    //         //     if (parseFloat(Tax[0]?.Total_GST) > 0) {
    //         //         TaxAmount = ((((parseInt(OrderDetailsByTypeOfOrder?.ItemCount || 1) * parseFloat(OrderDetailsByTypeOfOrder?.Vendor_Price)) * JSON.parse(parseFloat(weight[0]).toFixed(1))) +
    //         //             (parseFloat(OrderDetailsByTypeOfOrder?.ExtraCharges) + parseFloat(OrderDetailsByTypeOfOrder?.DeliveryCharge))) *
    //         //             parseFloat(Tax[0]?.Total_GST)) / 100;
    //         //     } else {
    //         //         TaxAmount = 0
    //         //     };
    //         //     let FinalTotal = ((parseInt(OrderDetailsByTypeOfOrder?.ItemCount || 1) * (parseFloat(OrderDetailsByTypeOfOrder?.Vendor_Price)) * JSON.parse(parseFloat(weight[0]).toFixed(1))) +
    //         //         (parseFloat(OrderDetailsByTypeOfOrder?.ExtraCharges) + parseFloat(OrderDetailsByTypeOfOrder?.DeliveryCharge) + parseFloat(TaxAmount))) -
    //         //         parseFloat(OrderDetailsByTypeOfOrder?.Discount);
    //         //     let PriceObject = {
    //         // Price: OrderDetailsByTypeOfOrder?.Vendor_Price,
    //         // Weight: OrderDetailsByTypeOfOrder?.Weight,
    //         // ExtraCharges: OrderDetailsByTypeOfOrder?.ExtraCharges,
    //         // DeliveryCharge: OrderDetailsByTypeOfOrder?.DeliveryCharge,
    //         // Discount: OrderDetailsByTypeOfOrder?.Discount,
    //         // TaxPercentage: Tax[0]?.Total_GST,
    //         // TaxAmount: TaxAmount,
    //         // Total: FinalTotal
    //         //     };
    //         //     setPrice(PriceObject)
    //         // }
    //     };
    //     //eslint-disable-next-line
    // }, [OrderDetailsByTypeOfOrder, Tax, Invoice_Calculation]);

    const handleClose = (e) => {
        handleCloseEditPricePopup(e);
    };

    return (
        <div>
            <Modal show={showHelpdeskVEditPriceForVendorCCPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <div className="form-row">
                        {Invoice_Calculation ?
                            <div className="col-md-12 col-sm-12">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="bold text-center">Customised Cake Order&apos;s Final Price</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price per Kg</th>
                                                <td>{float2D(OrderDetailsByTypeOfOrder?.Vendor_Price)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{OrderDetailsByTypeOfOrder?.Weight}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">ExtraCharges</th>
                                                <td>{float2D(Invoice_Calculation?.ExtraCharges)}</td>
                                            </tr> */}
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{float2D(Invoice_Calculation?.DiscountPrice)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td>{float2D(Invoice_Calculation?.DeliveryCharge)}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(parseFloat(Invoice_Calculation?.Gst))}</td>
                                                {/* <td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                {/* {Tax?.length > 0 && parseFloat(Tax[0]?.Total_GST) > 0 ? */}
                                                <td><span disabled className='mr-2 text-muted'><small>{parseFloat(Invoice_Calculation?.TaxPercentage) / 2}%</small></span> {float2D(parseFloat(Invoice_Calculation?.Sgst))}</td>
                                                {/* <td>0</td>
                                                } */}
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td><b>{float2D(Invoice_Calculation?.Total)}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> :
                            <div>
                                <h6 className='text-center'>Loading...</h6>
                            </div>
                        }
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={(e) => handleCustomizedCakePriceByHelpdeskV(e, {
                                    Price: OrderDetailsByTypeOfOrder?.Vendor_Price,
                                    Weight: OrderDetailsByTypeOfOrder?.Weight,
                                    ExtraCharges: Invoice_Calculation?.ExtraCharges,
                                    DeliveryCharge: Invoice_Calculation?.DeliveryCharge,
                                    Discount: Invoice_Calculation?.DiscountPrice,
                                    TaxPercentage: Tax[0]?.Total_GST,
                                    TaxAmount: (parseFloat(Invoice_Calculation?.Gst) + parseFloat(Invoice_Calculation?.Sgst)),
                                    Total: Invoice_Calculation?.Total
                                })}
                            >
                                Intimate to Customer Helpdesk
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};