import React from "react"
import { Carousel, ResponsiveEmbed } from 'react-bootstrap';

export default function ImageSlider({ image }) {

    return (
        <Carousel id="arrowSlider" controls={image.length === 1 ? false : true} className="ms-arrow-slider carousel slide" data-ride="carousel" data-interval="false" indicators={false}>
            {image.map((img, i) =>
                <Carousel.Item key={i}>
                    <ResponsiveEmbed aspectRatio='21by9'>
                        <img className="d-block align-center responsive-image-width" src={img} alt="First slide" />
                    </ResponsiveEmbed>
                </Carousel.Item>
            )}

        </Carousel>
    )
}