import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const TicketSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ALL_ORDERS_LIST_BY_TYPE, GetAllOrdersListByType),
        yield takeEvery(actions.GET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE, GetAllOrdersListWithDeliveredByType),
        yield takeEvery(actions.RAISE_NEW_TICKET, RaiseNewTicket),
        yield takeEvery(actions.GET_TICKETS_LIST_BY_TYPE_OF_USER, GetTicketsListByTypeOfUser),
        yield takeEvery(actions.GET_TICKET_DETAILS, GetTicketDetails),
        yield takeEvery(actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER, GetUnclosedTicketsListByTypeOfUser),
        yield takeEvery(actions.ACCEPT_TICKET, AcceptTicket),
        yield takeEvery(actions.INTIMATE_TICKET, IntimateTicket),
        yield takeEvery(actions.GET_DAILY_UNCLOSED_TICKETS_COUNT, GetDailyUnclosedTicketsCount),
        yield takeEvery(actions.DAILY_TICKET_REPORTS_SEND_TO_MANAGER, DailyTicketReportsSendToManager),
        yield takeEvery(actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE, GetDailyTicketReportWithDateRange),
        yield takeEvery(actions.TICKET_APPROVAL_STATUS_UPDATE, TicketApprovalStatusUpdate),
        yield takeEvery(actions.GET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER, GetAllTicketsByEmailAndTypeOfUser),
        yield takeEvery(actions.GET_ORDER_DETAILS_BY_TYPEOFORDER, GetOrderDetailsByTypeOfOrder),
        yield takeEvery(actions.HELPDESKC_NOTES, HelpdeskCNotes),
        yield takeEvery(actions.REFUND_PAYMENT, RefundPayment),
        yield takeEvery(actions.REFUND_PAYMENT_FOR_CUSTOMER_CANCEL, RefundPaymentForCustomerCancel),
        yield takeEvery(actions.HELPDESKC_FULLY_CUSTOMIZED_CAKE_ASSIGN_TO_VENDOR, HelpdeskCFullyCustomizedCakeAssignToVendor),
        yield takeEvery(actions.FULLY_CUSTOMIZED_CAKE_PRICE_INVOICE_SEND_BY_HELPDESKV, FullyCustomizedCakePriceInvoiceSendByHelpdeskV),
        yield takeEvery(actions.CLOSE_TICKET, CloseTicket),
        yield takeEvery(actions.SEND_PAYMENT_LINK_FOR_CHANGE_REQUEST, SendPaymentLinkForChangeRequest),
        yield takeEvery(actions.ENTER_SOLUTION_FOR_TICKETS, EnterSolutionForTickets),
        yield takeEvery(actions.MANAGER_REMARKS, ManagerRemarks),
        yield takeEvery(actions.HELPDESKV_UPDATE_VENDOR_AND_FINAL_COST, UpdateVendorAndFinalCost),
        yield takeEvery(actions.HELPDESKV_VENDOR_CANCEL_HELPDESKC_NOTES, UpdateHelpdeskCNotes),
        yield takeEvery(actions.HELPDESKV_VENDOR_CANCEL_ABORT_REASON, UpdateReasonForAbort)
    ]);
};

const GetAllOrdersListByType = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/Orders/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_ALL_ORDERS_LIST_BY_TYPE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllOrdersListWithDeliveredByType = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/Orders/listWithDelivered/${payload}`
            )
        );
        yield put({
            type: actions.SET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RaiseNewTicket = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/tickets/new`, payload
            )
        );
        yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetTicketsListByTypeOfUser = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/listByTypeOfUser/${payload.TypeOfUser}/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_TICKETS_LIST_BY_TYPE_OF_USER,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetTicketDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_TICKET_DETAILS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetUnclosedTicketsListByTypeOfUser = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/unclosedListByTypeOfUser/${payload.TypeOfUser}/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AcceptTicket = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/accept/${payload.Id}`, payload.data
            )
        )
        yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id })
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data })
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const IntimateTicket = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/update/intimation/${payload.Id}`, payload.data
            )
        )
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
            yield delay(2000);
            yield put({ type: actions.TICKET_RESPONSE, payload: [] });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: false,
                    message: null,
                    status: null,
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetDailyUnclosedTicketsCount = function* (data) {
    const { payload } = data

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/unclosedList/countByEmail/${payload.TypeOfUser}/${payload.Email}`
            )
        );
        yield put({
            type: actions.SET_DAILY_UNCLOSED_TICKETS_COUNT,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DailyTicketReportsSendToManager = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/unclosedTickets/sendToManager`, payload
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.SET_DAILY_UNCLOSED_TICKETS_COUNT, payload: [] });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetDailyTicketReportWithDateRange = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/dailyUnclosedReports/${payload.Email}/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const TicketApprovalStatusUpdate = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/approvalStatusUpdate/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllTicketsByEmailAndTypeOfUser = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/listByEmail/${payload.TypeOfUser}/${payload.Email}`
            )
        );
        yield put({
            type: actions.SET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetOrderDetailsByTypeOfOrder = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/Orders/Details/${payload.Id}/${payload.TypeOfOrder}`
            )
        );
        yield put({
            type: actions.SET_ORDER_DETAILS_BY_TYPEOFORDER,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskCNotes = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/notesForHelpdeskC/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskCFullyCustomizedCakeAssignToVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/assignToVendor/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RefundPayment = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/refund/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const RefundPaymentForCustomerCancel = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/refund/customerCancel/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const FullyCustomizedCakePriceInvoiceSendByHelpdeskV = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/updateCustomizedCakePrice/helpdeskV/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({
                type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
                payload: { Id: payload.OrderID, TypeOfOrder: payload.TypeOfOrder }
            });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const CloseTicket = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/close/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const SendPaymentLinkForChangeRequest = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/sendPaymentLink/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const EnterSolutionForTickets = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/solution/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const ManagerRemarks = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/finalSolution/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateVendorAndFinalCost = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/tickets/UpdateVendorAndFinalCost/${payload.Id}`, payload.data)
        )
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data)
    }
}
const UpdateHelpdeskCNotes = function* (data) {

    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/helpdesk/VendorCancel/HelpdeskCNotes/${payload.Id}`, payload.data)
        )
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data)
    }
}

const UpdateReasonForAbort = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/helpdesk/VendorCancel/AbortReason/${payload.Id}`, payload.data)
        )
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data)
    }
}

export default TicketSaga;