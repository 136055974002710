import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

function BodyContent1({
    errors, register, showEgglessOption, showEgglessCost, handleShowEgglessField, handleShowEgglessCostField,
    VendorDetails, AllList, handleAddCakeTypeArray, cakeTypeArray, RemoveAddedCakeType, handleAddSuTypeArray,
    cakeSubTypeArray, RemoveAddedSubCakeType, handleSelectedMinWeight, minWeight, FlavourList, handleAddFlavour,
    selectedFlavour, ShapesList, handleAddShape, selectedShape, reset, EgglessOptionAvailable, showSubTypeField, setShowSubTypeField }) {

    // const [showSubTypeField, setShowSubTypeField] = useState(false);
    const [SubTypes, setSubTypes] = useState([]);
    const [flavourList, setFlavourList] = useState([]);
    const [shapeList, setShapeList] = useState([]);
    const [cakeType, setCakeType] = useState([]);

    const WeightListForDisplay = [
        { label: '0.5kg', value: '0.5kg' }, { label: '1kg', value: '1kg' }, { label: '1.5kg', value: '1.5kg' },
        { label: '2kg', value: '2kg' }, { label: '2.5kg', value: '2.5kg' }, { label: '3kg', value: '3kg' },
        { label: '3.5kg', value: '3.5kg' }, { label: '4kg', value: '4kg' }, { label: '5kg', value: '5kg' },
        { label: '6kg', value: '6kg' }, { label: '7kg', value: '7kg' }, { label: '8kg', value: '8kg' },
        { label: '9kg', value: '9kg' }, { label: '10kg', value: '10kg' },
    ]

    useEffect(() => {
        if (FlavourList?.length === 0) {
            setFlavourList([]);
        } else {
            if (FlavourList?.message === 'No Records Found') {
                setFlavourList([]);
            } else {
                let FlavoursArray = [];
                FlavourList.map(val => {
                    return FlavoursArray.push({ value: val.Name, label: val.Name });
                });
                FlavoursArray.push({ value: "Other", label: "Other" });
                setFlavourList(FlavoursArray);
            }
        }
    }, [FlavourList]);

    useEffect(() => {
        if (ShapesList?.length === 0) {
            setShapeList([]);
        } else {
            if (ShapesList?.message === 'No Records Found') {
                setShapeList([]);
            } else {
                let ShapesArray = [];
                ShapesList.map(val => {
                    return ShapesArray.push({ value: val.Name, label: val.Name });
                });
                ShapesArray.push({ value: "Other", label: "Other" });
                setShapeList(ShapesArray);
            }
        }
    }, [ShapesList]);

    useEffect(() => {
        if (AllList?.length === 0) {
            setCakeType([]);
        } else {
            if (AllList?.message === 'No Records Found') {
                setCakeType([]);
            } else {
                let CakeTypesArray = [];
                AllList.map(val => {
                    return CakeTypesArray.push({ value: val.Type, label: val.Type });
                });
                // CakeTypesArray.push({ value: "Other", label: "Other" });
                setCakeType(CakeTypesArray);
            }
        }
    }, [AllList])

    const handleGetSelectedCakeType = (e) => {
        var NewArray = [];
        // eslint-disable-next-line
        AllList.filter(val => {
            if (e?.value === val.Type) {
                // if (e.target.value === val.Type) {
                if (val.SubType.length !== 0) {
                    NewArray.push('SubType');
                    let SubTypeList = [];
                    // eslint-disable-next-line
                    val.SubType.filter(v => {
                        SubTypeList.push({ label: v.Name, value: v.Name });
                    });
                    setSubTypes(SubTypeList);
                } else {
                    NewArray.push('No SubType');
                }
            }
        });
        if (NewArray.includes('SubType') || cakeSubTypeArray.length > 0) {
            setShowSubTypeField(true);
        } else {
            setShowSubTypeField(false);
        };
        handleAddCakeTypeArray(e?.value);
        // handleAddCakeTypeArray(e.target.value);
    };

    return (
        <div className="col-md-12">
            <div className="ms-panel ms-panel-fh">
                <div className="ms-panel-header">
                    <h6>Update New Cakes</h6>
                </div>
                <div className="ms-panel-body tabledata-background">
                    {/* <form className="needs-validation clearfix" noValidate> */}
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            {/* <div className={showSubTypeField ? "col-md-6 mb-3" : "col-md-4 mb-3"}> */}
                            <label htmlFor="validationCustom18" className='font-weight-bold'>Cake Type <span className='text-danger'>*</span></label>
                            <Select
                                className="text-capitalize"
                                name='CakeType'
                                {...register('CakeType')}
                                styles={customStyles}
                                options={cakeType}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                onChange={handleGetSelectedCakeType}
                            />
                            <div className="input-group">
                                {/* {AllList?.message === 'No Records Found' ?
                                    <select className='form-control'>
                                        <option className='text-center'>No Records Found</option>
                                    </select> :
                                    <select className="form-control text-capitalize" defaultValue='' name='CakeType' {...register('CakeType')} onChange={handleGetSelectedCakeType}>
                                        <option value='' disabled>-- Select --</option>
                                        {AllList?.map((val, i) =>
                                            <option key={i} value={val.Type}>{val.Type}</option>
                                        )}
                                    </select>
                                } */}
                                {/* <input type="text" className={errors?.CakeType ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='CakeType' {...register('CakeType')} placeholder="Cake Type" /> */}
                                <div className='col-md-12'>
                                    {cakeTypeArray?.map((value, index) =>
                                        <div key={index}>
                                            <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeType(e, index, value)}>X</button></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(showSubTypeField || cakeSubTypeArray?.length > 0) && cakeTypeArray?.length > 0 ?
                            <div className="col-md-6 mb-3">
                                <label htmlFor="validationCustom18" className='font-weight-bold'>Cake SubType</label>
                                <Select
                                    className={errors?.CakeSubType ? "required-field text-capitalize" : "text-capitalize"}
                                    name='CakeSubType'
                                    {...register('CakeSubType')}
                                    styles={customStyles}
                                    options={SubTypes}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    onChange={handleAddSuTypeArray}
                                />
                                <div className="input-group">
                                    {/* <select className={errors?.CakeSubType ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='CakeSubType' {...register('CakeSubType')} defaultValue='' onChange={handleAddSuTypeArray}>
                                        <option value=''>-- Select --</option>
                                        {SubTypes.map((val, i) =>
                                            <option key={i} value={val}>{val}</option>
                                        )}
                                    </select> */}
                                    <div className='col-md-12'>
                                        {cakeSubTypeArray?.map((value, index) =>
                                            <div key={index}>
                                                <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedSubCakeType(e, index)}>X</button></div>
                                            </div>
                                        )}
                                    </div>
                                    {/* <input type="text" className={errors?.CakeSubType ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='CakeSubType' {...register('CakeSubType')} placeholder="Cake Name" /> */}
                                </div>
                            </div> : <></>
                        }
                        <div className="col-md-6 mb-3">
                            {/* <div className={showSubTypeField ? "col-md-6 mb-3" : "col-md-4 mb-3"}> */}
                            <label htmlFor="validationCustom18" className='font-weight-bold'>Cake Name <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="text" className={errors?.CakeName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='CakeName' {...register('CakeName')} placeholder="Cake Name" />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <div className={showSubTypeField ? "col-md-6 mb-3" : "col-md-4 mb-3"}> */}
                            <label htmlFor="validationCustom24" className='font-weight-bold'>Cake Common Name <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="text" className={errors?.CakeCommonName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='CakeCommonName' {...register('CakeCommonName')} id="validationCustom24" placeholder="Cake Common Name" />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <div className={showEgglessCost ? "col-md-4 mb-3" : "col-md-6 mb-3"}> */}
                            <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Cake Flavour <span className='text-danger'>*</span></label>
                            <Select
                                className={errors?.BasicFlavour && !selectedFlavour ? "required-field text-capitalize" : "text-capitalize"}
                                styles={customStyles}
                                options={flavourList}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                name='BasicFlavour'
                                {...register('BasicFlavour')}
                                defaultValue=''
                                onChange={handleAddFlavour}
                                value={selectedFlavour}
                            />
                            {/* <div className="input-group">
                                <select
                                    className={errors?.BasicFlavour && !selectedFlavour ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                    name='BasicFlavour'
                                    {...register('BasicFlavour')}
                                    defaultValue=''
                                    onChange={handleAddFlavour}
                                    size={size?.field === 'Flavour' ? size?.count : 1}
                                    onFocus={() => handleFocus('Flavour')} onBlur={() => handleBlur('Flavour')}
                                >
                                    <option value='' disabled='disabled'>-- Select --</option>

                                    {FlavourList?.message === 'No Records Found' ?
                                        <></> :
                                        <>
                                            {FlavourList?.map((val, i) =>
                                                <option key={i} value={val.Name}>{val.Name}</option>
                                            )}
                                        </>
                                    }
                                    <option value='Other'>Other</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <div className="col-md-4 mb-3"> */}
                            <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Cake Shape <span className='text-danger'>*</span></label>
                            <Select
                                className={errors?.BasicShape && !selectedShape ? "required-field text-capitalize" : "text-capitalize"}
                                styles={customStyles}
                                options={shapeList}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                name='BasicShape'
                                {...register('BasicShape')}
                                defaultValue=''
                                onChange={handleAddShape}
                                value={selectedShape}
                            />
                            {/* <div className="input-group">
                                <select
                                    className={errors?.BasicShape && !selectedShape ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                    name='BasicShape'
                                    {...register('BasicShape')}
                                    defaultValue=''
                                    onChange={handleAddShape}
                                    size={size?.field === 'Shape' ? size?.count : 1}
                                    onFocus={() => handleFocus('Shape')} onBlur={() => handleBlur('Shape')}
                                >
                                    <option value='' disabled='disabled'>-- Select --</option>

                                    {ShapesList?.message === 'No Records Found' ?
                                        <></> :
                                        <>
                                            {ShapesList?.map((val, i) =>
                                                <option key={i} value={val.Name}>{val.Name}</option>
                                            )}

                                        </>
                                    }
                                    <option value='Other'>Others</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6 mb-3">
                            {/* <div className="col-md-4 mb-3"> */}
                            <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Weight <span className='text-danger'>*</span></label>
                            <Select
                                className={errors?.MinWeight && !minWeight ? "required-field" : ""}
                                styles={customStyles}
                                options={WeightListForDisplay}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                name='MinWeight'
                                {...register('MinWeight')}
                                defaultValue=''
                                onChange={handleSelectedMinWeight}
                                value={minWeight}
                            />
                            {/* <div className="input-group">
                                <select
                                    className={errors?.MinWeight && !minWeight ? "form-control required-field" : "form-control"}
                                    defaultValue=''
                                    name='MinWeight'
                                    {...register('MinWeight')}
                                    onChange={(e) => handleSelectedMinWeight(e)}
                                    size={size?.field === 'Weight' ? size?.count : 0}
                                    onFocus={() => handleFocus('Weight')} onBlur={() => handleBlur('Weight')}
                                >
                                    <option value='' disabled='disabled'>-- Select --</option>
                                    <option value="0.5kg">0.5kg</option>
                                    <option value="1kg">1kg</option>
                                    <option value="1.5kg">1.5kg</option>
                                    <option value="2kg">2kg</option>
                                    <option value="2.5kg">2.5kg</option>
                                    <option value="3kg">3kg</option>
                                    <option value="3.5kg">3.5kg</option>
                                    <option value="4kg">4kg</option>
                                    <option value="5kg">5kg</option>
                                    <option value="6kg">6kg</option>
                                    <option value="7kg">7kg</option>
                                    <option value="8kg">8kg</option>
                                    <option value="9kg">9kg</option>
                                    <option value="10kg">10kg</option>
                                </select>
                            </div> */}
                        </div>
                        {VendorDetails?.EggOrEggless === 'Egg' || VendorDetails?.EggOrEggless === 'Eggless' ?
                            <>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Default Cake Egg Or Eggless <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" id="validationCustom24" disabled name='DefaultCakeEggOrEggless' {...register('DefaultCakeEggOrEggless')} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    {/* <div className="col-md-4 mb-3"> */}
                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Cake Price/kg <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        {/* <div className='display-flex w-100'> */}
                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            } else if (e.target.value < e.target.min) {
                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                            }
                                        }} className={errors?.BasicCakePrice ? "form-control required-field" : "form-control"} name='BasicCakePrice' {...register('BasicCakePrice')} id="validationCustom24" placeholder="e.g. 300" />
                                        <button className={errors?.BasicCakePrice ? "RupeeSign required-field" : "RupeeSign"} disabled><i className="fas fa-rupee-sign"></i></button>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="col-md-6 mb-3">
                                    {/* <div className={showEgglessOption ? "col-md-3 mb-3" : "col-md-6 mb-3"}> */}
                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Default Cake Egg Or Eggless <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select className={((errors?.DefaultCakeEggOrEggless && !showEgglessCost) && (errors?.DefaultCakeEggOrEggless && !showEgglessOption) && (errors?.DefaultCakeEggOrEggless && !showEgglessCost && !showEgglessOption)) ? "form-control required-field" : "form-control"} defaultValue='' name='DefaultCakeEggOrEggless' {...register('DefaultCakeEggOrEggless')} onChange={handleShowEgglessField}>
                                            <option value='' disabled='disabled'>-- Select --</option>
                                            <option value="Egg">Egg</option>
                                            <option value="Eggless">Eggless</option>
                                            {/* <option value="Egg and Eggless">Egg and Eggless</option> */}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    {/* <div className="col-md-4 mb-3"> */}
                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Basic Cake Price/kg <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        {/* <div className='display-flex w-100'> */}
                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            } else if (e.target.value < e.target.min) {
                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                            }
                                        }} className={errors?.BasicCakePrice ? "form-control required-field" : "form-control"} name='BasicCakePrice' {...register('BasicCakePrice')} id="validationCustom24" placeholder="e.g. 300" />
                                        <button className={errors?.BasicCakePrice ? "RupeeSign required-field" : "RupeeSign"} disabled><i className="fas fa-rupee-sign"></i></button>
                                        {/* </div> */}
                                    </div>
                                </div>
                                {showEgglessOption &&
                                    <div className="col-md-6 mb-3">
                                        {/* <div className="col-md-3 mb-3"> */}
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>Is Eggless Option available? <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className="form-control" name='IsEgglessOptionAvailable' {...register('IsEgglessOptionAvailable')} defaultValue='' onChange={handleShowEgglessCostField} id="validationCustom22" >
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="y">Yes</option>
                                                <option value="n">No</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                {showEgglessCost ?
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="validationCustom22" className='font-weight-bold'>Basic <span className="text-danger">Eggless</span> cake cost per kg <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <button className="RupeeSign" disabled><i className="fas fa-rupee-sign"></i></button>
                                            <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength) {
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                } else if (e.target.value < e.target.min) {
                                                    e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                }
                                            }} className="form-control" name='BasicEgglessCostPerKg' {...register('BasicEgglessCostPerKg')} id="validationCustom24" placeholder="e.g. 300" />
                                        </div>
                                    </div> : <></>
                                }
                            </>
                        }


                        <div className="col-md-6 mb-3">
                            {/* <div className="col-md-4 mb-3"> */}
                            <label htmlFor="validationCustom23" className='font-weight-bold'>Discount <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) {
                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                    } else if (e.target.value < e.target.min) {
                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                    }
                                }} className={errors?.Discount ? "form-control required-field" : "form-control"} name='Discount' {...register('Discount')} id="validationCustom25" placeholder='e.g. 5' />
                                <button className={errors?.Discount ? "RupeeSign required-field" : "RupeeSign"} disabled><b>%</b></button>
                            </div>
                        </div>
                        {/* <div className="col-md-4 mb-3">
                            <label htmlFor="validationCustom23" className='font-weight-bold'>Tax <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select className={errors?.Tax ? "form-control required-field" : "form-control"} name='Tax' {...register('Tax')} defaultValue=''>
                                    <option value='' disabled='disabled'>-- Select --</option>
                                    <option value="0">GST 0%</option>
                                    <option value="5">GST 5%</option>
                                    <option value="12">GST 12%</option>
                                    <option value="18">GST 18%</option>
                                    <option value="28">GST 28%</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
}

export default BodyContent1;