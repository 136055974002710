import React from "react";
import { Carousel, ResponsiveEmbed } from 'react-bootstrap';

export default function ImageSlider(props) {

    const { image } = props;
    var images;

    if (image === undefined) {
        images = [];
    } else {
        images = image;
    };

    return (
        <Carousel id="arrowSlider" controls={images.length === 1 ? false : true} className="ms-arrow-slider carousel slide" data-ride="carousel" data-interval="false" indicators={false}>
            {images.map((img, index) =>
                <Carousel.Item key={index}>
                    <ResponsiveEmbed aspectRatio='21by9'>
                        <img className="d-block align-center rounded" src={img} alt="First slide" />
                    </ResponsiveEmbed>
                </Carousel.Item>
            )}
        </Carousel>
    )
};