import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from "../../utils/constants";
import axios from 'axios';

const OtherProductSaga = function* () {

    yield all([
        yield takeEvery(actions.ADD_OTHER_PRODUCT, AddOtherProduct),
        yield takeEvery(actions.APPROVE_OTHER_PRODUCT, ApproveOtherProduct),
        yield takeEvery(actions.APPROVE_UPDATED_OTHER_PRODUCT, ApproveUpdatedOtherProduct),
        yield takeEvery(actions.GET_NEW_OTHER_PRODUCT_LIST, GetNewOtherProductList),
        yield takeEvery(actions.GET_OTHER_PRODUCT_DETAILS, GetOtherProductDetails),
        yield takeEvery(actions.GET_APPROVED_PRODUCT_LIST, GetApprovedProductList),
        yield takeEvery(actions.OTHER_PRODUCT_SEND_INFORMATION, OtherProductSendInformation),
        yield takeEvery(actions.GET_OTHER_PRODUCT_BY_VENDOR, GetOtherProductByVendor),
        yield takeEvery(actions.UPDATE_OTHER_PRODUCT, UpdateOtherProduct),
        yield takeEvery(actions.GET_VENDOR_OTHER_PRODUCT_ORDERS_LIST, GetVendorOtherProductOrdersList),
        yield takeEvery(actions.GET_OTHER_PRODUCT_ORDER_DETAILS, GetOtherProductOrderDetails),
        yield takeEvery(actions.UPDATE_OTHER_PRODUCT_ORDER_STATUS, UpdateOtherProductOrderStatus),
        yield takeEvery(actions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER, AcceptOrCancelOtherProductOrder),
        yield takeEvery(actions.GET_ALL_OTHER_PRODUCT_ORDERS_LIST, GetAllOtherProductOrderList),
        yield takeEvery(actions.DELETE_OTHER_PRODUCT, DeleteOtherProduct),

    ])
};

const AddOtherProduct = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/otherproduct/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.ADD_OTHER_PRODUCT_STATUS,
            payload: { AddOtherProductStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const ApproveOtherProduct = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/approve/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: payload.id });
        yield put({ type: actions.OTHER_PRODUCT_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.OTHER_PRODUCT_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const ApproveUpdatedOtherProduct = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/approveupdated/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: payload.id });
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetNewOtherProductList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/newlist`
            )
        );
        yield put({
            type: actions.SET_NEW_OTHER_PRODUCT_LIST,
            payload: { NewOtherProductList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetOtherProductDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_OTHER_PRODUCT_DETAILS,
            payload: { OtherProductDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetApprovedProductList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/list`
            )
        );
        yield put({
            type: actions.SET_APPROVED_PRODUCT_LIST,
            payload: { ApprovedProductList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const OtherProductSendInformation = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/information/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: payload.id });
        yield put({ type: actions.OTHER_PRODUCT_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.OTHER_PRODUCT_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetOtherProductByVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/listbyvendor/${payload}`
            )
        );
        yield put({
            type: actions.SET_OTHER_PRODUCT_BY_VENDOR,
            payload: { VendorOtherProducts: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateOtherProduct = function* (data) {
    const { payload } = data;
    const { history } = payload;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/update/${payload.id}`, payload.data
            )
        );
        yield put({
            type: actions.OTHER_PRODUCT_RESPONSE,
            payload: result?.data
        });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
            history.push('/vendor-product-details', { Id: payload.id })
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield delay(2000);
        yield put({
            type: actions.OTHER_PRODUCT_RESPONSE,
            payload: []
        });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorOtherProductOrdersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/order/listbyvendor/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_OTHER_PRODUCT_ORDERS_LIST,
            payload: { VendorOtherOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetOtherProductOrderDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/order/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_OTHER_PRODUCT_ORDER_DETAILS,
            payload: { OtherProductOrderDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateOtherProductOrderStatus = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/order/updatestatus/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.UPDATE_OTHER_PRODUCT_ORDER_STATUS_RESPONSE,
            payload: { UpdateStatusResponse: result.data }
        });
        yield put({ type: actions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: payload.id });
        yield delay(2000);
        yield put({ type: actions.UPDATE_OTHER_PRODUCT_ORDER_STATUS_RESPONSE, payload: { UpdateStatusResponse: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AcceptOrCancelOtherProductOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/order/acceptorcancel/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER_STATUS,
            payload: { AcceptOrCancelStatus: result.data }
        });
        yield put({ type: actions.GET_OTHER_PRODUCT_ORDER_DETAILS, payload: payload.id });
        yield delay(2000);
        yield put({ type: actions.ACCEPT_OR_CANCEL_OTHER_PRODUCT_ORDER_STATUS, payload: { AcceptOrCancelStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllOtherProductOrderList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/order/alllist`
            )
        );
        yield put({
            type: actions.SET_ALL_OTHER_PRODUCT_ORDERS_LIST,
            payload: { AllOtherProductOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//delete Other product
const DeleteOtherProduct = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/delete/${payload.id}`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.SET_DELETE_OTHER_PRODUCT_STATUS,
            payload: { DeleteOtherProductStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default OtherProductSaga;