import React from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Content from '../sections/AdminVendorListEdit/Content';
import { Helmet } from "react-helmet";
import HepDeskSidenavigation from "../HelpdeskV/layout/Sidenavigation"
import HepDeskTopnavigation from "../HelpdeskV/layout/Topnavigation"
import { useLocation } from 'react-router-dom';
function AdminVendorListEdit() {
    const state = useLocation();
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme">
            {/* title  */}
            <Helmet>
                <title>Edit Vendor Details</title>
            </Helmet>
            {/* sidebar start  */}
            {state?.state?.type === "helpdeskV" ?
                <HepDeskSidenavigation />
                :
                <Sidenavigation />
            }
            {/* sidebar end  */}

            <main className="body-content">

                {/* Topnavigation start  */}
                {state?.state?.type === "helpdeskV" ?
                    <HepDeskTopnavigation />
                    :
                    <Topnavigation />
                }

                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content />
                {/* page content end  */}

            </main>
        </div>
    );
};

export default AdminVendorListEdit;