import React from 'react';
import ReactPaginate from 'react-paginate';

export default function ReactPagination(props) {

    return (
        <nav>
            <div className='row'>
                <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info mt-3" id="data-table-4_info" role="status" aria-live="polite">
                        Showing {props?.currentdata?.length} of {props?.totalPosts} entries
                    </div>
                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="d-flex justify-content-end">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=" >>"
                            onPageChange={props?.paginate}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={1}
                            containerClassName="pagination"
                            activeClassName="active"
                            pageLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            pageClassName="page-item"
                            breakClassName="page-item"
                            nextClassName="page-item"
                            previousClassName="page-item"
                            pageCount={props?.pageCount}
                            previousLabel="<<"
                            renderOnZeroPageCount={null}
                            forcePage={props?.currentPage}
                        />
                    </div>
                </div>
            </div>
        </nav>
    )
};