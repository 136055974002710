import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../../common/MenuLink';
import Alert from '../../common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { FaTicketAlt, FaFileInvoiceDollar, FaCoins } from 'react-icons/fa';
import { MdDashboard, MdRemoveShoppingCart, MdMiscellaneousServices } from 'react-icons/md';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { RiRefundFill } from 'react-icons/ri';
import Dropdown from '../../common/dropdown';
import { useHistory } from 'react-router-dom/esm/react-router-dom';

export default function Sidenavigation() {

    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && Filtered_Dates?.Page === 'CO') && (pathName !== '/Accounts-Current-Orders-List' && pathName !== '/Accounts-Current-Order-Details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        };
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Dashboard">
                                <MdDashboard className="fs-16" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                        </li>
                        {/* <li className="menu-item">
                            <MenuLink to="/Helpdesk-C-Tickets-List">
                                <FaTicketAlt className="fs-16" /><span className='pl-3'>Tickets</span>
                            </MenuLink>
                        </li > */}
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Current-Orders-List">
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders Based On Order Date </span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Cancelled-Orders-List">
                                <MdRemoveShoppingCart className="sidenavigationIcon" /><span className='pl-3'>Cancelled Orders </span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Tickets-List">
                                <FaTicketAlt className="sidenavigationIcon" /><span className='pl-3'>Tickets </span>
                            </MenuLink>
                        </li >
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Accounts-Vendor-PaymentStatement-Details' || pathName === '/Accounts-Current-Order-Details' ||
                                    pathName === '/Accounts-Ticket-Details' ?
                                    '/Accounts-Vendors-PaymentStatement-List' : '#'}
                                className={(pathName === '/Accounts-Vendors-PaymentStatement-List' || pathName === '/Accounts-Vendor-Payments-Report' ||
                                    pathName === '/Accounts-Vendor-PaymentStatement-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <FaFileInvoiceDollar className="sidenavigationIcon" /><span className='pl-3'>Vendor Payments </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Accounts-Vendors-PaymentStatement-List">Vendors Statement Of Accounts</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Accounts-Vendor-Payments-Report">Vendor Payments Report</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Refund-Report">
                                <RiRefundFill className="sidenavigationIcon" /><span className='pl-3'>Refund Report </span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Sales-Report">
                                <FaCoins className="sidenavigationIcon" /><span className='pl-3'>Sales Report</span>
                            </MenuLink>
                        </li>
                        <li className="menu-item">
                            <MenuLink to="/Accounts-miscellaneous-refund">
                                <MdMiscellaneousServices className="sidenavigationIcon" /><span className='pl-3'>Miscellaneous Refund Report</span>
                            </MenuLink>
                        </li>
                        {/* <li className="menu-item">
                            <MenuLink to="/Accounts-Vendors-PaymentStatement-List">
                                <FaFileInvoiceDollar className="sidenavigationIcon" /><span className='pl-3'>Vendors Due Statement</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Accounts-Vendor-Payments-Report">
                                <MdPayments className="sidenavigationIcon" /><span className='pl-3'>Vendor Payments Report </span>
                            </MenuLink>
                        </li > */}
                        {/* <li className="menu-item">
                            <MenuLink to="/Payment-Gateway">
                                <span><i className="fas fa-clipboard-list fs-16" />Payment</span>
                            </MenuLink>
                        </li > */}
                        {/* <Dropdown
                            head={<MenuLink
                                to='/Helpdesk-C-Tickets-List'
                                className="has-chevron"
                            >
                                <FaTicketAlt className="fs-16" /><span className='pl-3'>Tickets </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Raise-Ticket" >Raise Ticket</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Tickets-List" >Tickets List</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Unclosed-Tickets-List" >Unclosed Tickets List</MenuLink>
                                    </li>
                                </ul >
                            }
                        /> */}
                    </ul >
                </Scrollbar>
            </div >
        </>
    );
};