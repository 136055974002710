import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { Modal } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/OtherProducts/actions';
import Preloader from '../../../layouts/Preloader';
import CakeImageSlider from '../Vendor-Cake-Details/CakeImageSlider';
import Flavours from '../../../sections/Other-Product-Details/Flavours';
import PricePerUnit from '../../../sections/ArrayMapping/PricePerUnit';
import PricePerBox from '../../../sections/ArrayMapping/PricePerBox';
import Information from '../../../sections/ArrayMapping/Information';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const state = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const { OtherProductDetails, DeleteOtherProductStatus } = useSelector(state => state.OtherProductReducer);

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [loader, setLoader] = useState(false);
    const [ReasonForSuspend, setReasonForSuspend] = useState();

    let [sliderImages, setSliderImages] = useState([]);

    useEffect(() => {
        dispatch({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: state?.state?.Id });
        // eslint-disable-next-line 
    }, [state?.state?.Id]);

    useEffect(() => {
        if (OtherProductDetails?.ProductImage?.length > 0) {
            if (OtherProductDetails?.AdditionalProductImages?.length > 0 || OtherProductDetails?.AdditionalProductImages !== undefined) {
                // setSliderImages([...OtherProductDetails?.AdditionalProductImages, OtherProductDetails?.ProductImage[0]]);
                setSliderImages([OtherProductDetails?.ProductImage[0], ...OtherProductDetails?.AdditionalProductImages]);
            } else {
                setSliderImages([OtherProductDetails?.ProductImage[0]]);
            };
        };
    }, [OtherProductDetails]);

    const handleClose = () => {
        history.push('/vendor-cakes-list');
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        history.push('/vendor-product-edit', { Id: OtherProductDetails?._id });
    };

    // open cake delete popup
    const handleDeletePopup = () => {
        setShowDelete(true);
        setShowErr(false);
    };

    // cake delete functionality
    const handleDelete = (e) => {
        e.preventDefault();
        if (!ReasonForSuspend) {
            setShowErr(true);
        } else {
            setLoader(true);
            setShowErr(false);
            dispatch({
                type: actions.DELETE_OTHER_PRODUCT,
                payload: { id: state.state.Id, ReasonForSuspend: ReasonForSuspend }
            });
            setTimeout(() => {
                dispatch({
                    type: actions.SET_DELETE_OTHER_PRODUCT_STATUS,
                    payload: { DeleteOtherProductStatus: [] }
                });
            }, 2000);
        };
    };

    // close cake delete popup
    const handlePopUpClose = () => {
        setShowDelete(false);
    };

    // validate delete cake status
    useEffect(() => {
        if (DeleteOtherProductStatus !== undefined) {
            if (DeleteOtherProductStatus?.statusCode === 200) {
                setLoader(false);
                setShowDelete(false);
                dispatch({
                    type: actions.GET_OTHER_PRODUCT_BY_VENDOR,
                    payload: state?.state?.id
                });
                history.push('/vendor-cakes-list');
            };
        };
        //eslint-disable-next-line
    }, [DeleteOtherProductStatus, state?.state?.id]);

    useEffect(() => {
        (OtherProductDetails?.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
    }, [OtherProductDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6 >Product Details</h6>
                                </div>
                                <div className="ms-panel-body">
                                    <CakeImageSlider image={sliderImages} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {OtherProductDetails?.Status === "Suspended" ?
                                                <tr>
                                                    <th scope="row" className="text-danger">Reason for Suspend</th>
                                                    <td><b>{OtherProductDetails?.ReasonForSuspend}</b></td>
                                                </tr> :
                                                <></>
                                            }
                                            <tr>
                                                <th scope="row">Product ID</th>
                                                <td><b>{OtherProductDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Name</th>
                                                <td>{OtherProductDetails?.ProductName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Common Name</th>
                                                <td>{OtherProductDetails?.ProductCommonName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Type</th>
                                                <td>{OtherProductDetails?.CakeType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SubType</th>
                                                <td>{OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</td>
                                            </tr>
                                            {OtherProductDetails?.EggOrEggless &&
                                                <tr>
                                                    <th scope="row">Egg Or Eggless</th>
                                                    <td>{OtherProductDetails?.EggOrEggless}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {OtherProductDetails?.Flavour?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Flavour</th>
                                                    <td>
                                                        <Flavours FlavoursList={OtherProductDetails?.Flavour} />
                                                    </td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.Shape &&
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td>{OtherProductDetails?.Shape}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.MinWeightPerKg?.Weight &&
                                                <tr>
                                                    <th scope="row">Minimum Weight</th>
                                                    <td>{OtherProductDetails?.MinWeightPerKg?.Weight}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.MinWeightPerKg?.PricePerKg &&
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td>{float2D(OtherProductDetails?.MinWeightPerKg?.PricePerKg)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{OtherProductDetails?.Discount}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Created On</th>
                                                <td>{OtherProductDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Availability</th>
                                                <td>
                                                    <span className="badge badge-pill badge-primary">
                                                        {OtherProductDetails?.Stock}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td>
                                                    <span className="badge badge-pill badge-secondary">
                                                        {OtherProductDetails?.Status}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {(OtherProductDetails?.MinWeightPerUnit?.length > 0 || OtherProductDetails?.MinWeightPerBox?.length > 0) &&
                                        <hr />
                                    }
                                    {OtherProductDetails?.MinWeightPerUnit?.length > 0 &&
                                        <div className="row">
                                            <PricePerUnit
                                                WeightAndPrice={OtherProductDetails?.MinWeightPerUnit}
                                            />
                                        </div>
                                    }
                                    {OtherProductDetails?.MinWeightPerBox?.length > 0 &&
                                        <div className="row">
                                            <PricePerBox
                                                PieceAndPrice={OtherProductDetails?.MinWeightPerBox}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">OTHER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Minimum Time For Delivery</th>
                                                <td>{OtherProductDetails?.MinTimeForDelivery}</td>
                                            </tr>
                                            {OtherProductDetails?.CakeBase &&
                                                <tr>
                                                    <th scope="row">Product Base</th>
                                                    <td>{OtherProductDetails?.CakeBase}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Best before </th>
                                                <td>{OtherProductDetails?.BestUsedBefore}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">To Be Stored In</th>
                                                <td>{OtherProductDetails?.ToBeStoredIn}</td>
                                            </tr>
                                            {OtherProductDetails?.KeepTheCakeInRoomTemperature &&
                                                <tr>
                                                    <th scope="row">Minimum Time at Room Temperature before use</th>
                                                    <td>{OtherProductDetails?.KeepTheCakeInRoomTemperature}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.ToppersPossible &&
                                                <tr>
                                                    <th scope="row">Toppers Possible</th>
                                                    <td>{OtherProductDetails?.ToppersPossible === 'y' ? 'Yes' : 'No'}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">How good are you in this {OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</th>
                                                <td>{OtherProductDetails?.HowGoodAreYouWithTheCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How many times have you Baked this Particular {OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</th>
                                                <td>{OtherProductDetails?.HowManyTimesHaveYouBakedThisParticularCake}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductDetails?.SendInformation?.length > 0 &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Information From Admin</h4>
                                        <hr />
                                        <Information
                                            InfoList={OtherProductDetails?.SendInformation}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT DESCRIPTION</h4>
                                    <hr />
                                    <p>{OtherProductDetails?.Description}</p>
                                    <hr />
                                    {OtherProductDetails?.Status !== "Suspended" ?
                                        <div className='cake-details-button'>
                                            <button
                                                className="btn btn-primary float-start"
                                                type="submit"
                                                onClick={handleUpdate}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="btn btn-warning ml-2"
                                                type="button"
                                                onClick={handleDeletePopup}
                                            >
                                                Suspend
                                            </button>
                                            <button
                                                className="btn btn-secondary ml-2"
                                                type="submit"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </div> :
                                        <div className='cake-details-button'>
                                            <button
                                                className="btn btn-secondary float-start"
                                                type="submit"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* delete cake Confirmation popup  */}
                    <Modal
                        className="modal-min"
                        show={showDelete}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        contentClassName="modal-body-scrollar"
                    >
                        <Modal.Body className='tabledata-background'>
                            <button
                                type="button"
                                className="close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <div>
                                <div>
                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Reason for Suspend<span className='text-danger'> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"} name='ReasonForSuspend' id="validationCustom24" placeholder="Enter the reason..." onChange={(e) => setReasonForSuspend(e.target.value)} />
                                        <p className='text-danger'>{showErr}</p>
                                    </div>
                                    <p className="text-danger mt-4">Note: If you suspend this product it will not be displayed to customers</p>
                                </div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleDelete}
                                        >
                                            Ok
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handlePopUpClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
};