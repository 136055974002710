
//Allowed only positive numbers
export const NumberValidation = (e) => {
    if (e.target.value >= 0) {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        } else if (e.target.value < e.target.min) {
            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
        }
    } else {
        e.target.value = 0;
    }
};

export const DiscountValidation = (e) => {
    if (e.target.value >= 0 && e.target.value <= 99) {
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        } else if (e.target.value < e.target.min) {
            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
        }
    } else {
        e.target.value = 0;
    }
};

export const PercentageValidation = (e) => {
    let val = e.target.value;
    if (e.target.value <= 100) {
        val = val.replace(/([^0-9.]+)/, "");
        val = val.replace(/^(0|\.)/, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,3})?)/g.exec(val);
        const value = (match[1].length === 0 ? 0 : match[1]) + match[2];
        e.target.value = value;
    } else {
        e.target.value = 0;
    }
};

export const AadharNumberValidation = (e) => {
    if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength).replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join("-")
    } else {
        e.target.value = e.target.value.replace(/\D/g, "").split(/(?:([\d]{4}))/g).filter(s => s.length > 0).join(" ")
    }
};

export const MobilenumberValidation = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    e.target.value = (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) +
        e.target.value.substr(e.target.value.indexOf("."), 0)) : e.target.value;
};

export const LettersValidation = (e) => {
    e.target.value = e.target.value.replace(/[^A-Za-z/\s/]/gi, '')
};

export const float2D = (num) => { return !isNaN(parseFloat(num)) ? parseFloat(num).toFixed(2) : 0 };