import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import AccountsActions from '../../../../../redux/Accounts/actions';
import Breadcrumb from './Breadcrumb';
import Preloader from '../../../../layouts/Preloader';
import PaymentHistory from './PaymentHistory';
import PendingPayment from './PendingPayments';
import StatementOfAccounts from './StatementOfAccounts';
import moment from 'moment';

export default function Content() {

    const dispatch = useDispatch();

    const [key, setKey] = useState('Statement Of Accounts');
    const [selectedMonth, setSelectedMonth] = useState();
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);

    const { token } = useSelector((state) => state.authReducer);

    const { VendorStatementDetails } = useSelector(state => state.AccountsReducer);

    const handleSelectedMonth = (e) => {
        setSelectedMonth(moment(e.target.value).format('YYYY-MM'));
        dispatch({ type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS, payload: { Id: token?.result?._id, Month: e.target.value?.slice(5, 7), Year: e.target.value?.slice(0, 4), TypeOfUser: 'Vendor' } });
        // dispatch({ type: actions.GET_VENDOR_STATEMENT_DETAILS, payload: { VendorID: token?.result?._id, Month: e.target.value?.slice(5, 7), Year: e.target.value?.slice(0, 4) } });
    };

    useEffect(() => {
        const Month = moment().format('DD-MM-YYYY');
        setSelectedMonth(moment().format('YYYY-MM'));
        dispatch({ type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS, payload: { Id: token?.result?._id, Month: Month?.slice(3, 5), Year: Month?.slice(6, 10), TypeOfUser: 'Vendor' } });
        // dispatch({ type: actions.GET_VENDOR_STATEMENT_DETAILS, payload: { VendorID: token?.result?._id, Month: Month?.slice(3, 5), Year: Month?.slice(6, 10) } });
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        (VendorStatementDetails?.message === 'No Records Found') ?
            setFilteredData([]) : setFilteredData(VendorStatementDetails.result);
    }, [VendorStatementDetails]);

    useEffect(() => {
        (VendorStatementDetails.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
    }, [VendorStatementDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Payments</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <Tabs
                                        id="controlled-tab-example"
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="mb-3 tab-style"
                                    >
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Statement Of Accounts"
                                            title="Statement Of Accounts"
                                        >
                                            <StatementOfAccounts
                                                handleSelectedMonth={handleSelectedMonth}
                                                selectedMonth={selectedMonth}
                                                filteredData={filteredData}
                                            />
                                        </Tab>
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Payment History"
                                            title="Payment History"
                                        >
                                            <PaymentHistory
                                                handleSelectedMonth={handleSelectedMonth}
                                                selectedMonth={selectedMonth}
                                                filteredData={filteredData}
                                            />
                                        </Tab>
                                        <Tab
                                            tabClassName='schedule-tab'
                                            eventKey="Pending Payment"
                                            title="Pending Payment"
                                        >
                                            <PendingPayment
                                                handleSelectedMonth={handleSelectedMonth}
                                                selectedMonth={selectedMonth}
                                                filteredData={VendorStatementDetails}
                                            />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};