import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../../common/MenuLink';
import Alert from '../../common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { MdDashboard, MdMiscellaneousServices } from 'react-icons/md';
import { FaTicketAlt, FaHistory, FaUsersSlash } from 'react-icons/fa';
import { BsHourglassSplit, BsFileEarmarkTextFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import Dropdown from '../../common/dropdown';

export default function Sidenavigation() {

    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && Filtered_Dates?.Page === 'tickets') && (pathName !== '/Management-Tickets-List' && pathName !== '/Management-Ticket-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'DO') && (pathName !== '/Management-Delivery-Orders-List' && pathName !== '/Management-Delivery-Order-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'CO') && (pathName !== '/Management-Current-Order-List' && pathName !== '/Management-Current-Order-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'UT') && (pathName !== '/Management-Unclosed-Tickets-List' && pathName !== '/Management-Ticket-Details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        };
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            <MenuLink to="/Management-Dashboard">
                                <MdDashboard className="sidenavigationIcon" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                        </li>
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Management-Delivery-Order-Details' || pathName === '/Management-Current-Order-Details' ||
                                    pathName === '/Management-Ticket-Details' || pathName === '/Management-Unclosed-Ticket-Details' ?
                                    '/Management-Delivery-Orders-List' : '#'}
                                className={(pathName === '/Management-Delivery-Orders-List' || pathName === '/Management-Delivery-Order-Details' ||
                                    pathName === '/Management-Current-Order-List' || pathName === '/Management-Current-Order-Details' ||
                                    pathName === '/Management-Sales-Count-Chart') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Management-Delivery-Orders-List" >Orders Based On Delivery Date</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-Current-Order-List" >Orders Based On Order Date</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-Sales-Count-Chart" >Sales Count Chart</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Management-Delivery-Order-Details' || pathName === '/Management-Current-Order-Details' ||
                                    pathName === '/Management-Ticket-Details' || pathName === '/Management-Unclosed-Ticket-Details' ?
                                    '/Management-Tickets-List' : '#'}
                                className={(pathName === '/Management-Tickets-List' || pathName === '/Management-Unclosed-Tickets-List' ||
                                    pathName === '/Management-Ticket-Details' || pathName === '/Management-Unclosed-Ticket-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <FaTicketAlt className="sidenavigationIcon" /><span className='pl-3'>Tickets </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Management-Tickets-List" >Tickets</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-Unclosed-Tickets-List" >Unclosed Tickets</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-All-Tickets-List" >All Tickets List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Management-Delivery-Order-Details' || pathName === '/Management-Current-Order-Details' ||
                                    pathName === '/Management-Ticket-Details' || pathName === '/Management-Unclosed-Ticket-Details' ?
                                    '/Management-Vendor-Login-History' : '#'}
                                className={(pathName === '/Management-Vendor-Login-History' || pathName === '/Management-HelpdeskC-Login-History' ||
                                    pathName === '/Management-HelpdeskV-Login-History') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <FaHistory className="sidenavigationIcon" /><span className='pl-3'>Login History </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Management-Vendor-Login-History" >Vendors Login History</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-HelpdeskC-Login-History" >Customer Helpdesk Login History</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-HelpdeskV-Login-History" >Vendor Helpdesk Login History</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Management-Delivery-Order-Details' || pathName === '/Management-Current-Order-Details' ||
                                    pathName === '/Management-Ticket-Details' || pathName === '/Management-Unclosed-Ticket-Details' ?
                                    '/Management-Vendor-Work-hrs' : '#'}
                                className={(pathName === '/Management-Vendor-Work-hrs' || pathName === '/Management-HelpdeskC-Work-hrs' ||
                                    pathName === '/Management-HelpdeskV-Work-hrs') ?
                                    "has-chevron active" : "has-chevron"}
                            >
                                <BsHourglassSplit className="sidenavigationIcon" /><span className='pl-3'>Work Hours Report </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Management-Vendor-Work-hrs" >Vendors Work Hours Report</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-HelpdeskC-Work-hrs" >Customer Helpdesk Work Hours Report</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Management-HelpdeskV-Work-hrs" >Vendor Helpdesk Work Hours Report</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/Management-Inactive-vendors">
                                <FaUsersSlash className="sidenavigationIcon" /><span className='pl-3'>Inactive Vendors </span>
                            </MenuLink>
                        </li>
                        <li className="menu-item">
                            <MenuLink to="/Management-miscellaneous-refund-report">
                                <MdMiscellaneousServices className="sidenavigationIcon" /><span className='pl-3'>Miscellaneous Refund Report</span>
                            </MenuLink>
                        </li>
                    </ul >
                </Scrollbar>
            </div >
        </>
    );
};