import React, { useEffect, useState } from 'react';
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import actions from '../../../../redux/cake/actions';
import OtherActions from '../../../../redux/OtherProducts/actions';
import TableSearch from '../../../common/TableSearch';
import Preloader from '../../../layouts/Preloader';
import Breadcrumbs from './Breadcrumb';
import ReactPagination from '../../../common/React-Pagination';
import { float2D } from '../../../../utils/Validation';

export default function Listcontent() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [search2, setsearch2] = useState('');
    const [filteredData2, setFilteredData2] = useState([]);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [postsPerPage2, setPostPerPage2] = useState(10);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    // for get cakes list
    const { AdminCakesList } = useSelector((state) => state.CakesReducer);

    const { ApprovedProductList } = useSelector((state) => state.OtherProductReducer);

    // dispatch the cakes list api 
    useEffect(() => {
        dispatch({ type: actions.GET_ADMIN_CAKES_LIST });
        dispatch({ type: OtherActions.GET_APPROVED_PRODUCT_LIST });
        // eslint-disable-next-line 
    }, []);

    //for get search input value
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //for validate the list is empty or not
    useEffect(() => {
        if (AdminCakesList?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(AdminCakesList?.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CakeName && val.CakeName.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.Stock && val.Stock.toLowerCase().includes(search)) ||
                    (val.BasicCakePrice && val.BasicCakePrice.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        }
    }, [AdminCakesList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSearchbar2 = (e) => {
        setsearch2(e.target.value.toLowerCase());
        setCurrentPage2(0);
    };

    //for validate the list is empty or not
    useEffect(() => {
        if (ApprovedProductList?.message === 'No Records Found') {
            setFilteredData2([]);
        } else {
            setFilteredData2(ApprovedProductList?.filter(val => {
                if (search2 === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search2)) ||
                    (val.ProductName && val.ProductName.toLowerCase().includes(search2)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search2)) ||
                    (val.Stock && val.Stock.toLowerCase().includes(search2))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        }
    }, [ApprovedProductList, search2]);

    const indexOfFirstPost2 = (currentPage2 * postsPerPage2) % filteredData2?.length;
    const indexOfLastPost2 = indexOfFirstPost2 + postsPerPage2;
    const currentdata2 = filteredData2.slice(indexOfFirstPost2, indexOfLastPost2);

    // Change page
    const paginate2 = (event) => {
        setCurrentPage2(event.selected);
    };

    const handleShowPerPage2 = (e) => {
        setPostPerPage2(parseInt(e.target.value));
        setCurrentPage2(0);
    };

    // Get current posts
    // const indexOfLastPost2 = currentPage2 * postsPerPage2;
    // const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage2;
    // const currentdata2 = filteredData2.slice(indexOfFirstPost2, indexOfLastPost2);

    // // Change page
    // const paginate2 = (e, pageNumber) => {
    //     e.preventDefault();
    //     setCurrentPage2(pageNumber);
    // };

    //for view details page
    function ViewDetails(cake) {
        history.push('/helpdeskV-Cake-Details', { page: 'cakeslist', cakeId: cake?._id });
    };

    function ViewProductDetails(cake) {
        history.push('/helpdeskV-Other-Product-Details', { page: 'cakeslist', Id: cake?._id });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_ADMIN_CAKES_LIST });
        dispatch({ type: OtherActions.GET_APPROVED_PRODUCT_LIST });
    };

    //loading
    useEffect(() => {
        (AdminCakesList?.length === 0 || ApprovedProductList?.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
    }, [AdminCakesList, ApprovedProductList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumbs handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Cakes List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Cake ID</th>
                                                                <th>Cake Image</th>
                                                                <th>Cake Name</th>
                                                                <th>Vendor Name</th>
                                                                <th>Availability</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata?.map((cake) =>
                                                                    <tr className={cake.Status === 'Suspended' ? 'odd cursor text-danger price-alignment' : 'odd cursor price-alignment'} key={cake._id} onClick={() => ViewDetails(cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td><img className='profile-image' src={cake.MainCakeImage} alt="Cake" /></td>
                                                                        <td>{cake.CakeName}</td>
                                                                        <td>{cake.VendorName}</td>
                                                                        <td><span className={(cake.Stock?.toUpperCase())?.replace(/ /g, '')}>{cake.Stock}</span></td>
                                                                        <td>{float2D(cake.BasicCakePrice)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Other Product List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage2}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar2}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Product ID</th>
                                                                <th>Product Image</th>
                                                                <th>Product Name</th>
                                                                <th>Vendor Name</th>
                                                                <th>Availability</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData2.length > 0 ?
                                                            <tbody>
                                                                {currentdata2.map((cake) =>
                                                                    <tr className={cake.Status === 'Suspended' ? 'odd cursor text-danger' : 'odd cursor'} key={cake._id} onClick={() => ViewProductDetails(cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td><img className='profile-image' src={cake.ProductImage[0]} alt="Cake" /></td>
                                                                        <td>{cake.ProductName}</td>
                                                                        <td>{cake.VendorName}</td>
                                                                        <td><span className={(cake.Stock?.toUpperCase())?.replace(/ /g, '')}>{cake.Stock}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData2?.length > postsPerPage2 &&
                                        <ReactPagination
                                            paginate={paginate2}
                                            pageCount={Math.ceil(filteredData2?.length / postsPerPage2)}
                                            currentPage={currentPage2}
                                            currentdata={currentdata2}
                                            totalPosts={filteredData2.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};