import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import actions from "./actions";
import CommonActions from '../Common/actions';
import CakeAction from "../cake/actions";
import { API_URL } from "../../utils/constants";
import axios from "axios";

const Adminsaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ADMIN_DETAILS, updateAdminDetails),
        yield takeEvery(actions.GET_ADMINORDER_STATUS_COUNT, getAdminOrderStatusCount),
        yield takeEvery(actions.GET_TOTAL_USERS, getTotalUsers),
        yield takeEvery(actions.GET_ADMIN_RECENTORDER, getAdminRecentOrder),
        yield takeEvery(actions.GET_ADMIN_RECENTORDER_N, getAdminRecentOrderN),
        yield takeEvery(actions.GET_ADMIN_RECENTORDER_Y, getAdminRecentOrderY),
        yield takeEvery(actions.GET_ADMIN_DETAILS_BY_EMAIL, getAdminDetailsByEmail),
        yield takeEvery(actions.GET_NOTIFICATION_COUNT, GetNotificationCount),
        yield takeEvery(actions.GET_CAKE_BANNERS, GetCakeBanners),
        yield takeEvery(actions.ADD_BANNER, AddBanner),
        yield takeEvery(actions.UPDATE_BANNER, UpdateBanner),
        yield takeEvery(actions.DELETE_BANNER, DeleteBanner),
        yield takeEvery(actions.GET_ADMIN_NOTIFICATION_LIST, GetAdminNotificationList),
        yield takeEvery(actions.REMOVE_ADMIN_NOTIFICATION, RemoveAdminNotification),
        yield takeEvery(actions.REMOVE_ADMIN_NOTIFICATION_BY_ID, RemoveAdminNotificationById),
        yield takeEvery(actions.ADMIN_UPDATE_CAKE, adminUpdateCake),
        yield takeEvery(actions.SAVE_ADMIN_REGISTER_INITIAL_USER, adminRegisterInitialuser),
        yield takeEvery(actions.GET_INTERNAL_USERS_LIST, GetInternalUsersList),
        yield takeEvery(actions.GET_INTERNAL_USER_DETAILS, GetInternalUserDetails),
        yield takeEvery(actions.UPDATE_INTERNAL_USER_DETAILS, UpdateInternalUserDetails),
        yield takeEvery(actions.GET_CUSTOMER_RATINGS_LIST, GetCustomerRatings),
    ]);
};

//get single admin details
const getAdminDetailsByEmail = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/admin/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_ADMIN_DETAILS_BY_EMAIL,
            payload: { GetAdminDetailsbyEmail: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//update admin details
const updateAdminDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/admin/update/${payload.adminId}`, payload.updateAdmin
            )
        );
        yield put({
            type: actions.SET_PROFILE_UPDATE_STATUS,
            payload: { ProfileUpdateStatus: result.data }
        });
        yield put({ type: actions.GET_ADMIN_DETAILS_BY_EMAIL, payload: payload.Email });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.SET_PROFILE_UPDATE_STATUS, payload: { ProfileUpdateStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get notification count
const GetNotificationCount = function* () {
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/notification/count`
            )
        );
        yield put({
            type: actions.SET_NOTIFICATION_COUNT,
            payload: { NotificationCount: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get orders status count
const getAdminOrderStatusCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/totalcount`
            )
        );
        yield put({
            type: actions.SET_ADMINORDER_STATUS_COUNT,
            payload: { StatusCount: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get total users count
const getTotalUsers = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/admin/userscount`
            )
        );
        yield put({
            type: actions.SET_TOTAL_USERS,
            payload: { TotalUsers: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all recent orders
const getAdminRecentOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listby/status/${payload}`
            )
        );
        yield put({
            type: actions.SET_ADMIN_RECENTORDER,
            payload: { RecentOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all below 5kg orders list
const getAdminRecentOrderN = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbystatus/${payload.Above5KG}`
            )
        );
        yield put({
            type: actions.SET_ADMIN_RECENTORDER_N,
            payload: { RecentOrdersN: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all above 5kg orders list
const getAdminRecentOrderY = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbystatus/${payload.Above5KG}`
            )
        );
        yield put({
            type: actions.SET_ADMIN_RECENTORDER_Y,
            payload: { RecentOrdersY: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get banners
const GetCakeBanners = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/banner/list`
            )
        );
        yield put({
            type: actions.SET_CAKE_BANNERS,
            payload: { CakeBanners: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const AddBanner = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/banner/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.GET_CAKE_BANNERS,
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateBanner = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/banner/update/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.GET_CAKE_BANNERS,
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DeleteBanner = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.delete(
                `${API_URL}/banner/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.GET_CAKE_BANNERS,
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAdminNotificationList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/admin/notificationlist`
            )
        );
        yield put({
            type: actions.SET_ADMIN_NOTIFICATION_LIST,
            payload: { NotificationList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveAdminNotification = function* () {

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/admin/removeallnotification`
            )
        );

        yield put({ type: actions.GET_ADMIN_NOTIFICATION_LIST });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveAdminNotificationById = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/admin/removenotificationbyid/${payload}`
            )
        );
        yield put({ type: actions.GET_ADMIN_NOTIFICATION_LIST });

    } catch (err) {
        console.log(err.response.data);
    };
};


const adminUpdateCake = function* (data) {
    const { payload } = data;
    const { history } = payload;

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/admin/cake/update/${payload.id}`, payload.data
            )
        );
        yield put({ type: CakeAction.Add_CAKES_STATUS, payload: { addCakeStatus: result.data } });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
            yield put({ type: CakeAction.GET_SINGLE_CAKEDETAILS, payload: payload.id });
            history.push(payload.path, { page: 'newcakeslist', cakeId: payload.id });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};


const adminRegisterInitialuser = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/admin/internalUsers/register`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
        // yield put({ type: actions.GET_CAKE_BANNERS, });

    } catch (err) {
        console.log(err.response.data);
    };
    yield put({ type: CommonActions.SET_LOADER, payload: false });
};

const GetInternalUsersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/internalUsers/list`
            )
        );
        yield put({
            type: actions.SET_INTERNAL_USERS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetInternalUserDetails = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}//internalUser/Details/${payload}`
            )
        );
        yield put({
            type: actions.SET_INTERNAL_USER_DETAILS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateInternalUserDetails = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/internalUser/update/${payload.Id}`, payload.data
            )
        );
        yield put({ type: actions.ADMIN_RESPONSE, payload: result.data });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.GET_INTERNAL_USER_DETAILS, payload: payload.Id
        });
        yield delay(2000);
        yield put({ type: actions.ADMIN_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCustomerRatings = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}//customer/ratings/list`
            )
        );
        yield put({ type: actions.SET_CUSTOMER_RATINGS_LIST, payload: result.data });
    } catch (err) {
        console.log(err.response.data);
    };
};


export default Adminsaga;