import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb({ page }) {

    return (
        <nav aria-label="breadcrumb">
            {page === 'newcakeslist' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/dashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/new-cakes-list"> New Cakes And Products List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"> Product Details</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/dashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/admin-cake-list"> Cakes And Products List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page"> Product Details</li>
                </ol>
            }
        </nav>
    );
};

export default Breadcrumb;