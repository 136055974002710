import actions from "./actions";

const initialState = {
  CakesList: [],
  VendorNotification : [],
  BankName: [],
  VendorCakes: [],
  VendorCakeListResponse: [],
};

const VendorCakeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_VENDOR_CAKE_LIST:
      return {
        ...state,
        CakesList: action.payload
      }
    case actions.SET_VENDOR_NOTIFICATION:
      return {
        ...state,
        VendorNotification: action.payload.VendorNotification
      }
    case actions.SET_BANKNAME:
      return {
        ...state,
        BankName: action.payload.BankName
      }
    case actions.SET_ALL_CAKES_LIST_BY_VENDOR:
      return {
        ...state,
        VendorCakes: action.payload.VendorCakes
      }
    case actions.VENDORCAKELIST_RESPONSE:
      return {
        ...state,
        VendorCakeListResponse: action.payload
      }
    default:
      return state;
  }
};

export default VendorCakeListReducer;