const actions = {
   GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y : 'GET CUSTOMIZE CAKE BY ABOVE5KG_Y',
   SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y : 'SET CUSTOMIZE CAKE BY ABOVE5KG_Y',
   GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N : 'GET CUSTOMIZE CAKE BY ABOVE5KG_N',
   SET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N : 'SET CUSTOMIZE CAKE BY ABOVE5KG_N',
   GET_CUSTOMIZE_CAKE_LIST_BY_VENDORID : 'GET CUSTOMIZE CAKE LIST BY VENDORID',
   SET_CUSTOMIZE_CAKE_LIST_BY_VENDORID : 'SET CUSTOMIZE CAKE LIST BY VENDORID',
   GET_ALL_CUSTOMIZE_CAKE : 'GET_ALL_CUSTOMIZE_CAKE',
   SET_ALL_CUSTOMIZE_CAKE : 'SET_ALL_CUSTOMIZE_CAKE',
   CUSTOMIZE_CAKE_ASSIGN : 'CUSTOMIZE_CAKE_ASSIGN',
   CUSTOMIZE_CAKE_ASSIGN_STATUS : 'CUSTOMIZE_CAKE_ASSIGN_STATUS',
   CUSTOMIZE_CAKE_PRICE_INVOICE : 'CUSTOMIZE_CAKE_PRICE_INVOICE',
   CUSTOMIZE_CAKE_PRICE_INVOICE_STATUS : 'CUSTOMIZE_CAKE_PRICE_INVOICE_STATUS',
   GET_NEW_CUSTOMIZE_CAKE_LIST: 'GET_NEW_CUSTOMIZE_CAKE_LIST',
   SET_NEW_CUSTOMIZE_CAKE_LIST: 'SET_NEW_CUSTOMIZE_CAKE_LIST',
   CANCEL_CUSTOMIZED_CAKE_ORDER: 'CANCEL_CUSTOMIZED_CAKE_ORDER',
   CANCEL_CUSTOMIZED_CAKE_ORDER_STATUS: 'CANCEL_CUSTOMIZED_CAKE_ORDER_STATUS',
   GET_CUSTOMIZED_CAKE_DETAILS: 'GET_CUSTOMIZED_CAKE_DETAILS',
   SET_CUSTOMIZED_CAKE_DETAILS: 'SET_CUSTOMIZED_CAKE_DETAILS',
   GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST: 'GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST',
   SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST: 'SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_LIST',
   GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV: "GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV",
   SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV: "SET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV",
   GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR: 'GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR',
   SET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR: 'SET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR',
  };
  
  export default actions;
  