import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Dropdown, NavLink } from 'react-bootstrap';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import MenuLink from '../common/MenuLink';
import actions from '../../redux/Auth/actions';
import actions2 from '../../redux/Admin/actions'
import { useHistory } from 'react-router-dom';
import actions3 from '../../redux/ForgotPassword/actions';
import CircleDotSpinner from '../common/CircleDotSpinner';
import { Modal } from 'react-bootstrap';
import { FaRegUser, FaKey, FaPowerOff, FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoNotifications } from 'react-icons/io5';

export default function Topnavigation() {

    const dispatch = useDispatch();
    const history = useHistory();

    const PasswordR = useRef();
    const ConfirmPasswordR = useRef();

    const [show, setShow] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    //for get logged in users details
    const auth = useSelector(
        state => state.authReducer
    );

    //for get count for display in chart
    const StatusCount = useSelector(
        state => state.adminReducer
    );

    //for get change password status
    const Status = useSelector(
        state => state.forgotpasswordReducer
    );

    useEffect(() => {
        dispatch({ type: actions2.GET_ADMIN_NOTIFICATION_LIST });
        // eslint-disable-next-line
    }, []);

    const addsidenavigation = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    }

    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    }

    //dispatch count and notification count apis
    useEffect(() => {
        dispatch({ type: actions2.GET_ADMINORDER_STATUS_COUNT });
        dispatch({ type: actions2.GET_NOTIFICATION_COUNT });
    }, [dispatch]);

    //for logout
    const logout = () => {
        dispatch({ type: actions.UPDATE_AUTH_DETAILS, payload: { isAuthenticated: false } });
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();
    };

    //for open forgot password popup
    const handleChangePassword = (e) => {
        e.preventDefault();
        setShow(true);
    };

    //for close popup
    const handleClose = () => {
        setShow(false);
    };

    //for change password
    const handleChange = (e) => {
        e.preventDefault();
        setLoader(true);
        setMessage('');
        if (PasswordR.current.value && ConfirmPasswordR.current.value) {
            if (PasswordR.current.value === ConfirmPasswordR.current.value) {
                if (auth?.token !== undefined) {
                    dispatch({
                        type: actions3.CHANGE_PASSWORD,
                        payload: { id: auth?.token?.result?._id, Password: PasswordR.current.value }
                    });
                    // setTimeout(() => { dispatch({ type: actions3.CHANGE_PASSWORD_STATUS, payload: { ChangePasswordStatus: [] } }); }, 5000);
                }
            } else {
                setMessage("Password does not match");
                setLoader(false);
            }
        } else {
            setMessage('*required');
            setLoader(false);
        }
    };

    const handleClickNotification = (value) => {
        dispatch({ type: actions2.REMOVE_ADMIN_NOTIFICATION_BY_ID, payload: value._id });
        if (value.NotificationType === 'New Order' || value.NotificationType === 'Cake Order Rejected') {
            history.push('/Admin-OrderDetails', { page: 'orderlist', OrderId: value.Id });
        } else if (value.NotificationType === 'New Cake') {
            history.push('/new-cake-details', { page: 'newcakeslist', cakeId: value.Id });
        } else if (value.NotificationType === 'New Customized Cake' || value.NotificationType === "Customized Cake Order Rejected") {
            history.push('/Customize-cake-details', { page: 'customizecake', id: value.Id })
        } else if (value.NotificationType === 'New Hampers' || value.NotificationType === 'Hamper Updated') {
            history.push('/Admin-hamper-details', { id: value.Id });
        } else if (value.NotificationType === 'New Hamper Order' || value.NotificationType === "Hamper Order Cancelled") {
            history.push('/Admin-hamper-order-details', { OrderId: value.Id });
        } else if (value.NotificationType === 'Cake Updated') {
            history.push('/new-cake-details', { page: 'newcakeslist', cakeId: value.Id });
        } else if (value.NotificationType === 'New Other Product Order' || value.NotificationType === "Other Product Order Rejected") {
            history.push('/Admin-product-orders-details', { Id: value.Id });
        } else if (value.NotificationType === 'New Other Product' || value.NotificationType === 'Other Product Updated' || value.NotificationType === 'Product Suspended') {
            history.push('/Admin-product-details', { page: 'cakeslist', Id: value.Id });
        } else if (value.NotificationType === 'Cake Suspended') {
            history.push('/admin-cake-details', { page: 'cakeslist', cakeId: value.Id });
        } else {
            return null;
        }
    };

    const handleMarkAllAssRead = (e) => {
        e.preventDefault();
        dispatch({ type: actions2.REMOVE_ADMIN_NOTIFICATION });
    };

    const handleReloadNotification = (e) => {
        e.preventDefault();
        dispatch({ type: actions2.GET_ADMIN_NOTIFICATION_LIST });
    };

    //for validate the status
    useEffect(() => {
        if (Status?.ChangePasswordStatus?.statusCode === 400) {
            setMessage(Status?.ChangePasswordStatus?.message);
            setLoader(false);
        } else if (Status?.ChangePasswordStatus?.statusCode === 200) {
            setLoader(false);
            setShow(false);

        };
        // eslint-disable-next-line
    }, [Status]);

    return (
        <>
            <nav className="navbar ms-navbar tabledata-background">
                <div className="ms-aside-toggler ms-toggler pl-0" onClick={addsidenavigation}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                <div className="logo-sn logo-sm ms-d-block-sm">
                    <MenuLink className="pl-0 ml-0 text-center navbar-brand mr-0" to="/"><img src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" /></MenuLink>
                </div>
                <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
                    <li className="ms-nav-item dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} className='top-nav-link'>
                                <IoNotifications className="top-nav-icons" onClick={handleReloadNotification} />
                                {StatusCount?.NotificationList?.message === "No Records Found" ?
                                    <></> :
                                    <span className='Notification'>
                                        <span className="badge badge-success style-badge">.</span>
                                    </span>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notificationDropdown">
                                <div className="dropdown-menu-header">
                                    <h6 className="text-center mt-3 mb-3"><span className="text-disabled">Notifications</span></h6>
                                </div>
                                <div className="dropdown-divider" />
                                <Scrollbar className="ms-scrollable ms-dropdown-list mb-3">
                                    {StatusCount?.NotificationList?.message === "No Records Found" ?
                                        <Link to="#">
                                            <p className="text-center mt-4"><b>No Notification </b></p>
                                        </Link> :
                                        <>
                                            {StatusCount?.NotificationList?.map((val, i) =>
                                                <div key={i}>
                                                    <div className="media cursor" onClick={() => handleClickNotification(val)}>
                                                        {/* <Link className="media" to="#" onClick={() => handleClickNotification(val)}> */}
                                                        <img className="mr-3" src={val.Image ? val.Image : 'https://sugitechnologies.com/images/AppImages/107778272-cake-vector-icon-isolated-on-transparent-background-cake-logo-concept_njecku.webp'} alt="notification" />
                                                        <div className="media-body">
                                                            <p className="mt-0 mb-1 font-weight-bold">{val.For_Display}
                                                                {/* {
                                                                val.NotificationType === 'New Order' ? 'New Order' :
                                                                    val.NotificationType === 'New Cake' ? 'New Cake' :
                                                                        val.NotificationType === 'New Customized Cake' ? 'New Customized Cake' :
                                                                            val.NotificationType === 'New Hampers' ? 'New Hampers' :
                                                                                val.NotificationType === 'New Hamper Order' ? 'New Hamper Order' :
                                                                                    val.NotificationType === 'Cake Updated' ? 'Cake Updated' :
                                                                                        val.NotificationType === 'Hamper Updated' ? 'Hamper Updated' :
                                                                                            val.NotificationType === 'New Other Product Order' ? 'New Product Order' :
                                                                                                val.NotificationType === 'New Other Product' ? 'New Product' :
                                                                                                    val.NotificationType === 'Other Product Updated' ? 'Product Updated' :
                                                                                                        val.NotificationType === 'Cake Order Rejected' ? 'Cake Order Rejected' :
                                                                                                            val.NotificationType === 'Other Product Order Rejected' ? 'Other Product Order Rejected' :
                                                                                                                val.NotificationType === 'Customized Cake Order Rejected' ? 'Customized Cake Order Rejected' :
                                                                                                                    val.NotificationType === 'Hamper Order Cancelled' ? 'Hamper Order Cancelled' :
                                                                                                                        val.NotificationType === 'Cake Suspended' ? 'Cake Suspended' :
                                                                                                                            val.NotificationType === 'Product Suspended' ? 'Product Suspended' : ''
                                                            } */}
                                                            </p>
                                                            <span className='notification-time'>{val.Created_On}</span>

                                                        </div>
                                                        {/* </Link> */}

                                                    </div>
                                                    <hr />
                                                </div>
                                            )}
                                        </>
                                    }
                                </Scrollbar>
                                {StatusCount?.NotificationList?.message === "No Records Found" ? <></> :
                                    <p className='mark-as-read' onClick={handleMarkAllAssRead}>Mark All As Read</p>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="ms-nav-item ms-nav-user dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                                {(auth?.token && auth?.token?.result?.ProfileImage) ?
                                    <img className="ms-user-img ms-img-round mh-100 w-100" src={auth?.token?.result?.ProfileImage} alt="people" /> :
                                    <div className='top-letter-border'>
                                        <p className="top-deopdown-letter">{auth?.token?.result?.AdminName?.charAt(0)}</p>
                                    </div>
                                    // <p className="top-deopdown-letter">{auth?.token?.result?.AdminName.charAt(0)}</p>
                                }
                            </Dropdown.Toggle>
                            {auth?.token !== undefined &&
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown z-100" aria-labelledby="userDropdown">
                                    <div className="dropdown-menu-header">
                                        <img className="ms-img-round profile-image" src={auth?.token === undefined || auth?.token?.result?.ProfileImage === undefined ? "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" : auth?.token?.result?.ProfileImage} alt="people" />
                                        <h6 className="text-center mt-3"><span className="text-disabled ">{auth?.token?.result?.AdminName}</span></h6>
                                        <p className="text-center"><span className="text-disabled ">{auth?.token?.result?.Email}</span></p>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="ms-dropdown-list">
                                        <Link className="media fs-14 p-2" to="/admin-profile">
                                            <span><FaRegUser className="sidenavigationIcon" /> <span className='pl-3'>Profile</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={handleChangePassword} to="/">
                                            <span><FaKey className="sidenavigationIcon" /> <span className='pl-3'>Change Password</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={logout} to="/">
                                            <span><FaPowerOff className="sidenavigationIcon" /> <span className='pl-3'>Logout</span></span>
                                        </Link>
                                    </div>
                                </Dropdown.Menu>
                            }
                        </Dropdown>
                    </li>
                </ul>
                <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                {/* change password popup start */}
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <h1 className="text-center pb-2">Change Password</h1>
                        <div>
                            <label><b>New Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText1 === true ? "text" : "password"} ref={PasswordR} />
                                <button className='eye-button' onClick={() => setShowText1(!showText1)}>
                                    {!showText1 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <label><b>Confirm Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText2 === true ? "text" : "password"} ref={ConfirmPasswordR} />
                                <button className='eye-button' onClick={() => setShowText2(!showText2)}>
                                    {!showText2 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <p className="text-danger">{message}</p>
                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary shadow-none mr-3" onClick={handleChange}>Change</button>
                                    <button type="submit" className="btn btn-secondary shadow-none" onClick={handleClose}>Cancel</button>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                {/* change password popup end */}

            </nav >
        </>
    );
}
