import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import AdminActions from '../../../redux/Admin/actions';
import Preloader from '../../layouts/Preloader';
import TableSearch from '../../common/TableSearch';
import Breadcrumb from "./Breadcrumb";
import ReactPagination from '../../common/React-Pagination';

export default function Ordertable() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { InternalUsersList } = useSelector(state => state.adminReducer);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: AdminActions.GET_INTERNAL_USERS_LIST });
        // eslint-disable-next-line 
    }, []);

    //get search input value - above 5kg
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //validate the list is empty or not - above 5kg
    useEffect(() => {
        if (InternalUsersList?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(InternalUsersList.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.Name && val.Name.toLowerCase().includes(search)) ||
                    (val.Email && val.Email.toLowerCase().includes(search)) ||
                    (val.Mobilenumber && (val.Mobilenumber)?.toString()?.toLowerCase().includes(search)) ||
                    // (val.Mobilenumber && val.Mobilenumber.toLowerCase().includes(search)) ||
                    // (val.TypeOfUser && val.TypeOfUser.toLowerCase().includes(search))
                    (val.TypeOfUser && (val.TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk'?.toLowerCase().includes(search) : val.TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk'?.toLowerCase().includes(search) : val.TypeOfUser.toLowerCase().includes(search)))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [InternalUsersList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //view details page
    function ViewDetails(value) {
        history.push('/Admin-InternalUser-Details', { Id: value._id });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: AdminActions.GET_INTERNAL_USERS_LIST });
    };

    //loading
    useEffect(() => {
        if (InternalUsersList.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
        // eslint-disable-next-line
    }, [InternalUsersList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Internal Users List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Internal User ID</th>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Mobile Number</th>
                                                                <th>Type of User</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd cursor" key={i} onClick={() => ViewDetails(val)}>
                                                                        <th className="sorting_1 id-column" scope="row">{val.Id}</th>
                                                                        <td>{val.Name}</td>
                                                                        <td>{val.Email}</td>
                                                                        <td>{val.Mobilenumber}</td>
                                                                        <td className='price-align3-row'>{val.TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk' :
                                                                            val.TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk' : val.TypeOfUser}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};