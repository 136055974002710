const actions = {
   GET_ORDERS_COUNT: 'GET_ORDERS_COUNT',
   SET_ORDERS_COUNT: 'SET_ORDERS_COUNT',
   GET_NOT_RESPOND_ORDERS: 'GET_NOT_RESPOND_ORDERS',
   SET_NOT_RESPOND_ORDERS: 'SET_NOT_RESPOND_ORDERS',
   ADD_NEW_HELPDESK_MEMBER: 'ADD_NEW_HELPDESK_MEMBER',
   ADD_NEW_HELPDESK_MEMBER_STATUS: 'ADD_NEW_HELPDESK_MEMBER_STATUS',
   GET_HELPDESK_MEMBERS_LIST: 'GET_HELPDESK_MEMBERS_LIST',
   SET_HELPDESK_MEMBERS_LIST: 'SET_HELPDESK_MEMBERS_LIST',
   DELETE_HELPDESK_MEMBER: 'DELETE_HELPDESK_MEMBER',
   DELETE_HELPDESK_MEMBER_STATUS: 'DELETE_HELPDESK_MEMBER_STATUS',
   GET_DELIVERY_ORDERS_LIST: 'GET_DELIVERY_ORDERS_LIST',
   SET_DELIVERY_ORDERS_LIST: 'SET_DELIVERY_ORDERS_LIST',
   GET_CURRENT_ORDERS_LIST: 'GET_CURRENT_ORDERS_LIST',
   SET_CURRENT_ORDERS_LIST: 'SET_CURRENT_ORDERS_LIST',
   CREATE_CONVERSATION: 'CREATE_CONVERSATION',
   GET_CONVERSATION: 'GET_CONVERSATION',
   SET_CONVERSATION: 'SET_CONVERSATION',
   GET_MESSAGES: 'GET_MESSAGES',
   SET_MESSAGES: 'SET_MESSAGES',
   GET_HELPDESK_V_USERS_LIST: "GET_HELPDESK_V_USERS_LIST",
   SET_HELPDESK_V_USERS_LIST: "SET_HELPDESK_V_USERS_LIST",
   GET_INTERNAL_USERS_NOTIFICATIONS: "GET_INTERNAL_USERS_NOTIFICATIONS",
   SET_INTERNAL_USERS_NOTIFICATIONS: "SET_INTERNAL_USERS_NOTIFICATIONS",
   REMOVE_INTERNAL_USER_NOTIFICATION_BY_NOTIFICATIONID: 'REMOVE_INTERNAL_USER_NOTIFICATION_BY_NOTIFICATIONID',
   REMOVE_ALL_INTERNAL_USER_NOTIFICATION: 'REMOVE_ALL_INTERNAL_USER_NOTIFICATION',
   HELPDESK_RESPONSE: 'HELPDESK_RESPONSE',
   GET_LAST_SEEN_LIST_BY_ID: "GET_LAST_SEEN_LIST_BY_ID",
   SET_LAST_SEEN_LIST_BY_ID: "SET_LAST_SEEN_LIST_BY_ID",
   GET_HELPDESKV_DASHBOARD_COUNT: 'GET_HELPDESKV_DASHBOARD_COUNT',
   SET_HELPDESKV_DASHBOARD_COUNT: 'SET_HELPDESKV_DASHBOARD_COUNT',
   GET_HELPDESKC_DASHBOARD_COUNT: 'GET_HELPDESKC_DASHBOARD_COUNT',
   SET_HELPDESKC_DASHBOARD_COUNT: 'SET_HELPDESKC_DASHBOARD_COUNT',
   GET_MANAGER_DASHBOARD_COUNT: 'GET_MANAGER_DASHBOARD_COUNT',
   SET_MANAGER_DASHBOARD_COUNT: 'SET_MANAGER_DASHBOARD_COUNT',
   UPDATE_CHATWITH_MEMBER: 'UPDATE_CHATWITH_MEMBER',
   GET_CHAT_NOTIFICATIONS_LIST: 'GET_CHAT_NOTIFICATIONS_LIST',
   SET_CHAT_NOTIFICATIONS_LIST: 'SET_CHAT_NOTIFICATIONS_LIST',
   REMOVE_CHAT_NOTIFICATION_BY_ID: 'REMOVE_CHAT_NOTIFICATION_BY_ID',
   GET_NOT_RESPOND_OTHER_PRODUCT_ORDERS: 'GET_NOT_RESPOND_OTHER_PRODUCT_ORDERS',
   SET_NOT_RESPOND_OTHER_PRODUCT_ORDERS: 'SET_NOT_RESPOND_OTHER_PRODUCT_ORDERS',
   GET_NOT_RESPOND_HAMPER_ORDERS: 'GET_NOT_RESPOND_HAMPER_ORDERS',
   SET_NOT_RESPOND_HAMPER_ORDERS: 'SET_NOT_RESPOND_HAMPER_ORDERS',
   GET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS: 'GET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS',
   SET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS: 'SET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS',

};

export default actions;