const actions = {
    ADD_NEW_TOPPER : 'ADD_NEW_TOPPER',
    ADD_NEW_TOPPER_STATUS : 'ADD_NEW_TOPPER_STATUS',
    GET_VENDOR_TOPPERS_LIST: 'GET_VENDOR_TOPPERS_LIST',
    SET_VENDOR_TOPPERS_LIST: 'SET_VENDOR_TOPPERS_LIST',
    UPDATE_TOPPER : 'UPDATE_TOPPER',
    UPDATE_TOPPER_STATUS : 'UPDATE_TOPPER_STATUS',
    GET_ALL_TOPPERS_LIST: 'GET_ALL_TOPPERS_LIST',
    SET_ALL_TOPPERS_LIST: 'SET_ALL_TOPPERS_LIST',
    DELETE_TOPPER: 'DELETE_TOPPER',
    CREATE_HAMBPERS: 'CREATE_HAMBPERS',
    CREATE_HAMBPERS_STATUS: 'CREATE_HAMBPERS_STATUS',
    GET_VENDOR_HAMPERS_LIST: 'GET_VENDOR_HAMPERS_LIST',
    SET_VENDOR_HAMPERS_LIST: 'SET_VENDOR_HAMPERS_LIST',
    GET_HAMPERS_DETAILS: 'GET_HAMPERS_DETAILS',
    SET_HAMPERS_DETAILS: 'SET_HAMPERS_DETAILS',
    UPDATE_HAMPER: 'UPDATE_HAMPER',
    UPDATE_HAMPER_STATUS: 'UPDATE_HAMPER_STATUS',
    DELETE_HAMPER: 'DELETE_HAMPER',
    GET_ADMIN_HAMPERS_LIST: 'GET_ADMIN_HAMPERS_LIST',
    SET_ADMIN_HAMPERS_LIST: 'SET_ADMIN_HAMPERS_LIST',
    APPROVE_HAMPER: 'APPROVE_HAMPER',
    GET_TOPPER_DETAILS: 'GET_TOPPER_DETAILS',
    SET_TOPPER_DETAILS: 'SET_TOPPER_DETAILS',
    TOPPER_RESPONSE: 'TOPPER_RESPONSE',
};

export default actions;