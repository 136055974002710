import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../redux/CakeArray/actions";
import { LettersValidation } from "../../../../../utils/Validation";

export default function FlavourList({ option, className, handleSelectButton, disabledButton, handleRemoveButton, handleInputValue, show, flavoursPrice, cakeFlavourList,
    CakeDetails }) {

    const [list, setList] = useState([]);
    const [newFlavour, setNewFlavour] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add new flavour status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option === undefined) {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new flavour functioanlity
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newFlavour) {
            const newList = list.filter(val => {
                if (val.Name === newFlavour) {
                    return val;
                } else {
                    return null;
                }
            });
            if (newList.length === 0) {
                dispatch({ type: actions.ADD_NEW_FLAVOUR, payload: newFlavour });
            }
            setNewFlavour('');
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        }
    };

    //validate add new flavour status
    useEffect(() => {
        if (Status.AddNewFlavourStatus !== undefined) {
            if (Status.AddNewFlavourStatus.statusCode === 200) {
                dispatch({ type: actions.GET_ALL_FLAVOURS_LIST });
                setNewFlavour('');
            }
        };
    }, [Status, dispatch]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Custom Flavours (Basic Flavour - {CakeDetails?.BasicFlavour})</label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input type="text" className="form-control text-capitalize" onInput={LettersValidation} value={newFlavour} onChange={(e) => setNewFlavour(e.target.value)} />
                    <button className='add-button rounded' onClick={handleAddNew}>add</button>
                </div>
            </div>
            <div className="scrollable-list">
                {show ?
                    <>
                        {list.map((value, i) =>
                            <div className="display-flex mb-2 list-main" key={i}>

                                {!disabledButton?.includes(i) ?
                                    <>
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            disabled={(value.Name === CakeDetails?.BasicFlavour) ? true : false}
                                            value={value.Name}
                                            onClick={() => handleSelectButton(i, value.Name)}
                                        > + </button>
                                    </> :
                                    <>
                                        <button className='mr-1 minus-button' value={value.Name} onClick={() => handleRemoveButton(i)}> - </button>
                                        <div className={className}> {value.Name}</div>
                                        {flavoursPrice.map(val =>
                                            <div key={val.index}>
                                                {val.index === i &&
                                                    <input maxLength={4} onInput={(e) => {
                                                        if (e.target.value.length > e.target.maxLength) {
                                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                        } else if (e.target.value < e.target.min) {
                                                            e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                        }
                                                    }} type="number" onWheel={() => document.activeElement.blur()} className="price-input" defaultValue={val.Price} onChange={(e) => handleInputValue(e, value.Name)} />
                                                }
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {cakeFlavourList?.map((val, i) =>
                <>
                    {(val.Price) &&
                        <div className="list-array" key={i}>
                            {val.Name} - {val.Price}
                        </div>
                    }
                </>
            )}
            <p className="text-danger mt-1">{message}</p>
        </div>
    )
};