import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../redux/CakeArray/actions";
import { LettersValidation } from "../../../../../utils/Validation";

export default function CakeShapesWithWeight({ option, className, ErrMessage, handleSelectButton, handleRemoveButton, disabledButton, show, handleInputValue, cakeShapeList,
    selectedShape, minWeight }) {

    const [list, setList] = useState([]);
    const [newShape, setNewShape] = useState('');
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    //get add new shape status
    const Status = useSelector(
        state => state.CakeArrayReducer
    );

    useEffect(() => {
        if (option?.message === 'No Records Found') {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    //add new shape functionality
    const handleAddNew = (e) => {
        e.preventDefault();
        setMessage('');
        if (newShape) {
            if (list.message === 'No Records Found') {
                dispatch({ type: actions.ADD_NEW_SHAPE, payload: newShape });
            } else {
                const newList = list.filter(val => {
                    if (val.Name === newShape) {
                        return val;
                    } else {
                        return null;
                    }
                });
                if (newList?.length === 0) {
                    dispatch({ type: actions.ADD_NEW_SHAPE, payload: newShape });
                }
            };
            setNewShape('');
        } else {
            setMessage('*required');
            setTimeout(() => setMessage(''), 5000);
        };
    };

    //validate add new shape status
    useEffect(() => {
        if (Status.AddNewShapeStatus !== undefined) {
            if (Status.AddNewShapeStatus.statusCode === 200) {
                dispatch({ type: actions.GET_ALL_SHAPES_LIST });
                setNewShape('');
            }
        };
    }, [Status, dispatch]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Add Custom Shapes<span className='text-info'> (Weight should be greater than or equal to Minimum Weight)</span></label>
            <div className="input-group">
                <div className='display-flex w-100'>
                    <input type="text" className="form-control text-capitalize" onInput={LettersValidation} value={newShape} onChange={(e) => setNewShape(e.target.value)} />
                    <button className='add-button rounded' disabled={newShape ? false : true} onClick={(e) => handleAddNew(e, newShape)}>add</button>
                </div>
            </div>
            <div className="scrollable-list">
                {disabledButton?.length > 0 &&
                    <div className="display-flex mb-2 list-main justify-content-between">
                        <div className='font-weight-bold ml-2'>Shape</div>
                        <div className="font-weight-bold">Minimum Weight</div>
                        <div className="font-weight-bold mr-5">Price/kg</div>
                    </div>
                }
                {(show || list?.length > 0) ?
                    <>
                        {list.map((value, i) =>
                            <div key={i}>
                                {!disabledButton?.includes(i) ?
                                    <div className="display-flex mb-2 list-main">
                                        <div className={className}>{value.Name}</div>
                                        <button
                                            className='add-button'
                                            value={value.Name}
                                            disabled={(selectedShape?.value && selectedShape?.value === value.Name) ? true : false}
                                            // name='CustomShapeList' {...register('CustomShapeList')}
                                            onClick={(e) => handleSelectButton(e, i, value.Name)}
                                        > + </button>
                                    </div> :
                                    <div className="display-flex mb-2 list-main justify-content-between">
                                        <div className="display-flex">
                                            <button className='mr-1 minus-button' value={value.Name} onClick={(e) => handleRemoveButton(e, i)}> - </button>
                                            <div className={className}> {value.Name}</div>
                                        </div>
                                        <div>
                                            <input type="number" onWheel={() => document.activeElement.blur()} maxLength={3} onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength) {
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                }
                                                // } else if (e.target.value < e.target.min) {
                                                //     e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                // }
                                            }}
                                                className={ErrMessage?.find(val => val.Name === value.Name && val.Validation === true) ? "required-field price-input-others" : "price-input-others"}
                                                // className={ErrMessage === 'less than minimumweight' ? "required-field price-input-others" : "price-input-others"} 
                                                name="MinWeight" placeholder="e.g. 4" onChange={(e) => handleInputValue(e, value.Name)} />
                                            <button className="price-input-others" disabled>Kg</button>
                                        </div>
                                        <div className="mr-2">
                                            <input type="number" onWheel={() => document.activeElement.blur()} maxLength={5} onInput={(e) => {
                                                if (e.target.value.length > e.target.maxLength) {
                                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                }
                                                // else if (e.target.value < e.target.min) {
                                                //     e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                // }
                                            }} className="price-input-others" name="Price" placeholder="e.g. 400" onChange={(e) => handleInputValue(e, value.Name)} />
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </> :
                    <div className="text-center mt-2">No Records Found</div>
                }
            </div>
            {ErrMessage.find(val => val.Validation === false) &&
                <>
                    {cakeShapeList?.map((val, i) =>
                        <>
                            {(val.MinWeight && val.Price) &&
                                <div className="list-array" key={i}>
                                    {val.Name} -  Min {val.MinWeight ? val.MinWeight : 0} - {val.Price}
                                </div>
                            }
                        </>
                    )}
                </>
            }
            <p className="text-danger mt-1">{message}</p>
            <p className="text-danger mt-1">{(!minWeight && cakeShapeList?.length > 0) ? 'Minimum weight is required' : ''}</p>
            {/* <p className="text-danger mt-1">{ErrMessage === "less than minimumweight" ? 'Weight should be greater than or equal to minimum weight' :
                ErrMessage === "Minimum Weight is required" ? 'Minimum weight is required' : ''}</p> */}
        </div>
    )
};