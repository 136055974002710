import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import commonActions from '../../../../../redux/Common/actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ReactPagination from '../../../../common/React-Pagination';
import { float2D } from '../../../../../utils/Validation';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../../../common/SortingOrders';

export default function OrderList(props) {

    const { CakeOrderList, OtherOrderList, HamperOrderList } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);

    let [CurrentOrderList, setCurrentOrderList] = useState([]);
    let [OrderType, setOrderType] = useState('CakeOrders');
    let [startDate, setStartDate] = useState(((Filtered_Dates?.Page === 'VRCO' || Filtered_Dates?.Page === 'VROPO' || Filtered_Dates?.Page === 'VRHO') && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    let [endDate, setEndDate] = useState(((Filtered_Dates?.Page === 'VRCO' || Filtered_Dates?.Page === 'VROPO' || Filtered_Dates?.Page === 'VRHO') && moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
    ]);

    const handleSelectOrderType = (e) => {
        setOrderType(e.target.value);
        setStartDate('');
        setEndDate(moment().format('YYYY-MM-DD'));
        setButtonChange(false);
        setCurrentOrderList([]);
        dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    useEffect(() => {
        if (Filtered_Dates?.Page === 'VRCO' && Filtered_Dates?.StartDate) {
            let CurrentList = [], RangeDate = [];
            if (CakeOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                let fromDate = moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let toDate = moment(moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let type = 'days';
                let diff = toDate.diff(fromDate, type)
                for (let i = 0; i <= diff; i++) {
                    RangeDate.push(moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')).add(i, type).format('DD-MM-YYYY'))
                }
                CakeOrderList.filter((val) => {
                    for (let j = 0; j < RangeDate.length; j++) {
                        if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === RangeDate[j]) {
                            CurrentList.push(val);
                        };
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
                setOrderType('CakeOrders');
                if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate) {
                    setButtonChange(true);
                };
            }
        }
        //eslint-disable-next-line
    }, [Filtered_Dates, CakeOrderList]);

    useEffect(() => {
        if (Filtered_Dates?.Page === 'VROPO' && Filtered_Dates?.StartDate) {
            let CurrentList = [], RangeDate = [];
            if (OtherOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                let fromDate = moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let toDate = moment(moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let type = 'days';
                let diff = toDate.diff(fromDate, type)
                for (let i = 0; i <= diff; i++) {
                    RangeDate.push(moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')).add(i, type).format('DD-MM-YYYY'))
                }
                OtherOrderList.filter((val) => {
                    for (let j = 0; j < RangeDate.length; j++) {
                        if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === RangeDate[j]) {
                            CurrentList.push(val);
                        };
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
                setOrderType('OtherProductsOrder');
                if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate) {
                    setButtonChange(true);
                };
            }
        }
        //eslint-disable-next-line
    }, [Filtered_Dates, OtherOrderList]);

    useEffect(() => {
        if (Filtered_Dates?.Page === 'VRHO' && Filtered_Dates?.StartDate) {
            let CurrentList = [], RangeDate = [];
            if (HamperOrderList.length === 0) {
                setCurrentOrderList([]);
            } else {
                let fromDate = moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let toDate = moment(moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD'));
                let type = 'days';
                let diff = toDate.diff(fromDate, type)
                for (let i = 0; i <= diff; i++) {
                    RangeDate.push(moment(moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')).add(i, type).format('DD-MM-YYYY'))
                }
                HamperOrderList.filter((val) => {
                    for (let j = 0; j < RangeDate.length; j++) {
                        if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === RangeDate[j]) {
                            CurrentList.push(val);
                        };
                    }
                    return null;
                });
                setCurrentOrderList(CurrentList);
                setOrderType('HamperOrders');
                if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate) {
                    setButtonChange(true);
                };
            }
        }
        //eslint-disable-next-line
    }, [Filtered_Dates, HamperOrderList]);

    const handleFilterDateRange = () => {
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            setCurrentOrderList([]);
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            setSorting([{ table: 0, field: "OD", type: "D" }]);
        } else {
            setButtonChange(true);
            if (startDate && endDate) {
                if (OrderType === "CakeOrders") {
                    dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'VRCO' } });
                } else if (OrderType === "OtherProductsOrder") {
                    dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'VROPO' } });
                } else {
                    dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'VRHO' } });
                };
            };
        };
    };

    const indexOfFirstPost = (currentPage * postsPerPage) % CurrentOrderList?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = CurrentOrderList.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = CurrentOrderList;
        let result = SortingOrders({ type, field, filterData });
        setCurrentOrderList(result);
    };

    const ViewOrderDetails = (orders) => {
        if (orders.CakeName) {
            history.push('/Vendor-OrderDetails', { page: 'reports', OrderId: orders._id });
        } else if (orders.HampersName) {
            history.push('/vendor-hamper-order-details', { page: 'reports', OrderId: orders._id });
        } else if (orders.ProductName) {
            history.push('/vendor-product-order-details', { page: 'reports', Id: orders._id });
        } else {
            return null;
        }
    };

    return (
        <>
            <Row>
                <Col xl={5} lg={5} md={12} sm={12} className='vendor-report-select'>
                    <select
                        name="OrderType"
                        value={OrderType}
                        className="form-control form-control-sm"
                        onChange={handleSelectOrderType}
                    >
                        <option value="CakeOrders">Cake Orders</option>
                        <option value="OtherProductsOrder">Other Products Orders</option>
                        <option value="HamperOrders">Hamper Orders</option>
                    </select>
                </Col>
                <Col xl={7} lg={7} md={12} sm={12} className='d-flex justify-content-end'>
                    <Row>
                        <Col xl={4} lg={4} md={4} sm={4}>
                            <input
                                type='date'
                                className='form-control ml-4'
                                max={moment().format('YYYY-MM-DD')}
                                value={startDate || ''}
                                disabled={buttonChange}
                                onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={4}>
                            <input
                                type='date'
                                className='form-control ml-3'
                                min={moment(startDate).format('YYYY-MM-DD')}
                                max={moment().format('YYYY-MM-DD')}
                                value={endDate || ''}
                                disabled={buttonChange}
                                onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                            />
                        </Col>
                        <Col xl={4} lg={4} md={4} sm={4} className='vendor-report-filter'>
                            <Button
                                size='sm'
                                variant={buttonChange ? 'danger' : 'success'}
                                disabled={(startDate && endDate) ? false : true}
                                className={(startDate && endDate) ? 'font-weight-bold vendor-report-button' : 'font-weight-bold cursor-notallowed vendor-report-button'}
                                onClick={handleFilterDateRange}
                            >
                                {buttonChange ? 'Clear' : 'Filter'}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="ms-panel-body tabledata-background">
                <div className="table-responsive Scrollbar-Style">
                    <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div className="row pt-3">
                            <div className="col-sm-12">
                                <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info">
                                    <thead>
                                        <tr role="row">
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Product Name</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                            <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                            <th scope="col">Order Status</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    {buttonChange ?
                                        <>
                                            {CurrentOrderList.length === 0 ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={7} className='font-weight-bold no-records-found'>No Records Found</td>
                                                    </tr>
                                                </tbody> :
                                                <tbody>
                                                    {currentdata.map((order) =>
                                                        <tr className='odd ' key={order._id} onClick={() => ViewOrderDetails(order)} >
                                                            <th className='id-column' scope="row">{order.Id}</th>
                                                            <td className={order.CakeName || order.HampersName || order.ProductName ? '' : 'font-weight-bold text-success'}>{order.CakeName ? order.CakeName : order.HampersName ? order.HampersName : order.ProductName ? order.ProductName : 'Customised Cake'}</td>
                                                            <td> {order.UserName}</td>
                                                            <td> {order.Created_On}</td>
                                                            <td>{order.DeliveryDate}</td>
                                                            <td><span className={(order.Status?.toUpperCase())?.replace(/ /g, '')}>{order.Status}</span></td>
                                                            {(order.CakeID && order?.Vendor_Price && order?.TypeOfCustomizedCake === 'Cakey') ?
                                                                <td className='sales-alignment'>{float2D(order?.Vendor_Total)}</td> :
                                                                (order.CakeID && order?.Vendor_Price && order?.TypeOfCustomizedCake === 'Vendor') ?
                                                                    <td className='sales-alignment'>{order?.NewTotal ? float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax)) : (float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td> :
                                                                    order.ProductID ?
                                                                        <td className='sales-alignment'>{order?.NewTotal ? (float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax))) : (float2D(parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td> :
                                                                        order.HamperID ?
                                                                            <td className='sales-alignment'>{float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst)))}</td> :
                                                                            <td className='sales-alignment'>{order?.NewTotal ? float2D(parseFloat(order?.NewTotal) - parseFloat(order?.NewTax)) : (float2D((parseFloat(order?.Total) + parseFloat(order?.CouponValue || 0)) - (parseFloat(order?.Gst) + parseFloat(order?.Sgst))))}</td>
                                                            }
                                                        </tr>
                                                    )}
                                                </tbody>
                                            }
                                        </> :
                                        <tbody>
                                            <tr>
                                                <td colSpan={7} className='font-weight-bold no-records-found'>Select Date Range</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {CurrentOrderList.length > postsPerPage &&
                <ReactPagination
                    paginate={paginate}
                    pageCount={Math.ceil(CurrentOrderList?.length / postsPerPage)}
                    currentPage={currentPage}
                    currentdata={currentdata}
                    totalPosts={CurrentOrderList.length}
                />
            }
        </>
    )
};