import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'Manager' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/Manager-Dashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/Manager-Current-Order-List">Orders Based On Order Date</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                </ol> :
                Page === 'Accounts' ?
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item">
                            <Link to="/Accounts-Dashboard"><IoMdHome className='home' /> Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/Accounts-Current-Orders-List">Orders Based On Order Date</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                    </ol> :
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item">
                            <Link to="/Management-Dashboard"><IoMdHome className='home' /> Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/Management-Current-Order-List">Orders Based On Order Date</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                    </ol>
            }
        </nav>
    );
};


