import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import Preloader from '../../../../components/layouts/Preloader';
import CommonActions from '../../../../redux/Common/actions';
import HelpDeskAction from '../../../../redux/Helpdesk/actions';
import AddConversation from "./addConversation";
import ChatContent from "./chatContent";
import userAction from "../../../../redux/users/actions";

export default function Homecontent() {

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    // eslint-disable-next-line
    const [timer, setTimer] = useState("");
    const [show, setShow] = useState(false);
    const [messages, setmessages] = useState([]);

    const { token } = useSelector(state => state.authReducer);

    const { response, socket, onlineusers, currentConversation } = useSelector(state => state.commonReducer);

    const clearNotification = () => {
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: {
                alert: false,
                message: null,
                status: null
            }
        });
    };

    //loading
    useEffect(() => {
        if (token === undefined) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            if (response.alert) {
                setTimer(setInterval(() => {
                    clearNotification()
                }, 4000))
            }
        };
        // eslint-disable-next-line
    }, [token, response]);

    useEffect(() => {
        //add user 
        if (socket) {
            // get message
            //eslint-disable-next-line
            let data2 = socket?.on("getMessage", (data) => {
                if (currentConversation?._id === data?.Consersation_Id) {
                    setmessages([...messages, { ...data, status: "seen" }])
                } else {
                    setmessages([...messages, { ...data, status: "unseen" }])
                }
            })
        }
    }, [socket, onlineusers, messages, currentConversation]);

    useEffect(() => {
        dispatch({ type: HelpDeskAction.GET_HELPDESK_V_USERS_LIST });
        dispatch({ type: userAction.GET_ALL_USERS });
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch({ type: HelpDeskAction.GET_LAST_SEEN_LIST_BY_ID, payload: "Helpdesk V" });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <AddConversation
                        onlineusers={onlineusers}
                        show={show}
                        setShow={setShow}
                    />
                    <ChatContent
                        setShow={setShow}
                        onlineusers={onlineusers}
                        messages={messages}
                        setmessages={setmessages}
                    />
                </div>
            }
        </>
    );
};
