import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Alert = (props) => {

	const { response } = props;

	useEffect(() => {
		if (response?.status === "success") {
			toast.success(response?.message,{
				className:'toast-style'
			});
		} else {
			toast.error(response?.message,{
				className:'toast-style'
			}
			);
		}
	}, [response]);

	return (
		<div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				// closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				bodyClassName="toastBody"
			/>
		</div>
		// <div className='container_notification'>
		// 	<div className={`rectangle ${response.status ? response.status === "success" ? '' : 'bg-red' : ''}`}>
		// 		<div className="notification-text">
		// 			<span className='text-white'>&nbsp;&nbsp;{response.message}</span>
		// 		</div>
		// 	</div>
		// </div>
	)
}

export default Alert;