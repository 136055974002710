import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom/esm/react-router-dom";
import actions from '../../../redux/Customize-Cake/actions';
import helpdeskActions from '../../../redux/Helpdesk/actions';
import commonActions from '../../../redux/Common/actions';
import ReactPagination from "../../common/React-Pagination";
import Preloader from "../../layouts/Preloader";
import Breadcrumb from "./Breadcrumb";
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from "../../common/SortingOrders";
import moment from "moment";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    //get customized cake orders list
    const { CustomizeCakesList_Above5Kg, CustomizeCakesList_Below5Kg } = useSelector(state => state.CustomizeCakeReducer);

    const { NotRespondCustomizedCakeOrders } = useSelector(state => state.HelpdeskReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [search1, setsearch1] = useState('');
    const [search2, setsearch2] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData1, setFilteredData1] = useState([]);
    const [filteredData2, setFilteredData2] = useState([]);
    const [filteredData3, setFilteredData3] = useState([]);
    const [currentPage1, setCurrentPage1] = useState(0);
    const [postsPerPage1, setPostPerPage1] = useState(5);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [postsPerPage2, setPostPerPage2] = useState(5);
    const [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'CCA5KG' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    const [startDate2, setStartDate2] = useState((Filtered_Dates?.Page === 'CCB5KG' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
        { table: 1, field: "OD", type: "D" },
        { table: 2, field: "OD", type: "D" },
    ])

    //dispatch the get customized cake orders apis
    useEffect(() => {
        dispatch({ type: actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N, payload: 'n' });
        dispatch({ type: actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y, payload: 'y' });
        dispatch({ type: helpdeskActions.GET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS });
        // eslint-disable-next-line 
    }, []);

    //get search input value - above 5kg
    const handleSearchbar1 = (e) => {
        setsearch1(e.target.value.toLowerCase());
        setCurrentPage1(0);
    };

    //get search input value - below 5kg
    const handleSearchbar2 = (e) => {
        setsearch2(e.target.value.toLowerCase());
        setCurrentPage2(0);
    };

    useEffect(() => {
        //for validate the list is empty or not -above 5kg list
        if (CustomizeCakesList_Above5Kg?.message === 'No Records Found') {
            setFilteredData1([]);
        } else {
            let FilteredDataWithDate = [];
            if (Filtered_Dates?.Page === 'CCA5KG' && Filtered_Dates?.StartDate) {
                CustomizeCakesList_Above5Kg.filter(val => {
                    if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === Filtered_Dates?.StartDate) {
                        return FilteredDataWithDate.push(val);
                    } else {
                        return null;
                    }
                });
            } else {
                FilteredDataWithDate = CustomizeCakesList_Above5Kg;
            };
            setFilteredData1(FilteredDataWithDate.filter(val => {
                if (search1 === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search1)) ||
                    (val.CakeType && val.CakeType.toLowerCase().includes(search1)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search1)) ||
                    (val.Weight && val.Weight.toLowerCase().includes(search1)) ||
                    (val.Status && val.Status.toLowerCase().includes(search1))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [CustomizeCakesList_Above5Kg, search1, Filtered_Dates]);

    const indexOfFirstPost1 = (currentPage1 * postsPerPage1) % filteredData1?.length;
    const indexOfLastPost1 = indexOfFirstPost1 + postsPerPage1;
    const currentdata1 = filteredData1.slice(indexOfFirstPost1, indexOfLastPost1);

    // Change page
    const paginate1 = (event) => {
        setCurrentPage1(event.selected);
    };

    const handleShowPerPage1 = (e) => {
        setPostPerPage1(parseInt(e.target.value));
        setCurrentPage1(0);
    };

    useEffect(() => {
        //for validate the list is empty or not - below 5kg list
        if (CustomizeCakesList_Below5Kg?.message === 'No Records Found') {
            setFilteredData2([]);
        } else {
            let FilteredDataWithDate = [];
            if (Filtered_Dates?.Page === 'CCB5KG' && Filtered_Dates?.StartDate) {
                CustomizeCakesList_Below5Kg.filter(val => {
                    if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === Filtered_Dates?.StartDate) {
                        return FilteredDataWithDate.push(val);
                    } else {
                        return null;
                    }
                });
            } else {
                FilteredDataWithDate = CustomizeCakesList_Below5Kg;
            };
            setFilteredData2(FilteredDataWithDate.filter(val => {
                if (search2 === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search2)) ||
                    (val.CakeType && val.CakeType.toLowerCase().includes(search2)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search2)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search2)) ||
                    // val.Created_On.toLowerCase().includes(search2) || 
                    (val.Status && val.Status.toLowerCase().includes(search2))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [CustomizeCakesList_Below5Kg, search2, Filtered_Dates]);

    const indexOfFirstPost2 = (currentPage2 * postsPerPage2) % filteredData2?.length;
    const indexOfLastPost2 = indexOfFirstPost2 + postsPerPage2;
    const currentdata2 = filteredData2.slice(indexOfFirstPost2, indexOfLastPost2);

    // Change page
    const paginate2 = (event) => {
        setCurrentPage2(event.selected);
    };

    const handleShowPerPage2 = (e) => {
        setPostPerPage2(parseInt(e.target.value));
        setCurrentPage2(0);
    };

    useEffect(() => {
        if (NotRespondCustomizedCakeOrders?.message === 'No Orders') {
            setFilteredData3([]);
        } else {
            // eslint-disable-next-line
            setFilteredData3(NotRespondCustomizedCakeOrders.filter(val => {
                if (val.VendorID) { return val }
            }));
        };
    }, [NotRespondCustomizedCakeOrders]);

    //for view details page
    const ViewDetails = (cake) => {
        history.push('/Customize-cake-details', { cake: cake, page: 'customizecake', id: cake._id })
    };

    const handleSelectedDate = (e) => {
        setStartDate(e.target.value);
        setStartDate2('');
        setCurrentPage1(0);
        if (e.target.value) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'CCA5KG' } });
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, Page: null } });
        };
        let newArray = sorting;
        newArray[0].field = 'OD';
        newArray[0].type = 'D';
        setSorting(newArray);
    };

    const handleSelectedDate2 = (e) => {
        setStartDate2(e.target.value);
        setStartDate('');
        setCurrentPage2(0);
        if (e.target.value) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'CCB5KG' } });
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, Page: null } });
        };
        let newArray = sorting;
        newArray[1].field = 'OD';
        newArray[1].type = 'D';
        setSorting(newArray);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_N, payload: 'n' });
        dispatch({ type: actions.GET_CUSTOMIZE_CAKE_BY_ABOVE5KG_Y, payload: 'y' });
        dispatch({ type: helpdeskActions.GET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS });
        setSorting([
            { table: 0, field: "OD", type: "D" },
            { table: 1, field: "OD", type: "D" },
            { table: 2, field: "OD", type: "D" }
        ]);
        setStartDate();
        setStartDate2();
        setsearch1('');
        setsearch2('');
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        (table === 0) ? filterData = filteredData1 : (table === 1) ? filterData = filteredData2 : filterData = filteredData3;
        let result = SortingOrders({ type, field, filterData });
        (table === 0) ? setFilteredData1(result) : (table === 1) ? setFilteredData2(result) : setFilteredData3(result);
    };

    //loading
    useEffect(() => {
        if (CustomizeCakesList_Above5Kg.length === 0 || CustomizeCakesList_Below5Kg.length === 0 ||
            NotRespondCustomizedCakeOrders.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
        // eslint-disable-next-line
    }, [CustomizeCakesList_Above5Kg, CustomizeCakesList_Below5Kg, NotRespondCustomizedCakeOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Customised Cakes List - Above 5 KG</h6>
                                    <span className="float-right add-banner-button col-sm-3 col-md-3 mb-2">
                                        <div id="data-table-4_filter" className="dataTables_filter">
                                            <input
                                                type='date'
                                                maxLength={4}
                                                max={"9999-12-31"}
                                                className="form-control mt-4"
                                                disabled={CustomizeCakesList_Above5Kg?.message === 'No Records Found' ? true : false}
                                                aria-controls="data-table-4"
                                                onChange={handleSelectedDate}
                                                value={startDate || ''}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage1}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input type='Search' className="form-control form-control-sm" placeholder="type here to search..." onChange={handleSearchbar1} value={search1 || ''} aria-controls="data-table-4" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Cake ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Cake Weight</th>
                                                                <th scope="col">Premium Order</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData1.length > 0 ?
                                                            <tbody>
                                                                {currentdata1.map((cake) =>
                                                                    <tr className="odd cursor" key={cake._id} onClick={() => ViewDetails(cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Weight}</td>
                                                                        <td><span className={cake.PremiumVendor}>{cake.PremiumVendor === 'y' ? 'yes' : 'no'}</span></td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData1.length > postsPerPage1 &&
                                        <ReactPagination
                                            paginate={paginate1}
                                            pageCount={Math.ceil(filteredData1?.length / postsPerPage1)}
                                            currentPage={currentPage1}
                                            currentdata={currentdata1}
                                            totalPosts={filteredData1.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Customised Cakes List - Upto 5 KG</h6>
                                    <span className="float-right add-banner-button col-sm-3 col-md-3 mb-2">
                                        <div id="data-table-4_filter" className="dataTables_filter">
                                            <input
                                                type='date'
                                                maxLength={4}
                                                max={"9999-12-31"}
                                                className="form-control mt-4"
                                                disabled={CustomizeCakesList_Below5Kg?.message === 'No Records Found' ? true : false}
                                                aria-controls="data-table-4"
                                                onChange={handleSelectedDate2}
                                                value={startDate2 || ''}
                                            />
                                        </div>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage2}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input type='Search' className="form-control form-control-sm" placeholder="type here to search..." onChange={handleSearchbar2} value={search2 || ''} aria-controls="data-table-4" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Cake ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Premium Order</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 1)}><BsArrowUp className={`sort-up-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'OD' && sorting[1]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[1]?.table === 1 && sorting[1]?.field === 'OD' && sorting[1]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData2.length > 0 ?
                                                            <tbody>
                                                                {currentdata2.map((cake) =>
                                                                    <tr className="odd cursor" key={cake._id} onClick={() => ViewDetails(cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.UserName}</td>
                                                                        {cake.VendorName ?
                                                                            <td>{cake.VendorName}</td> :
                                                                            <td> --- </td>
                                                                        }
                                                                        <td><span className={cake.PremiumVendor}>{cake.PremiumVendor === 'y' ? 'yes' : 'no'}</span></td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Status.toUpperCase()).replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData2.length > postsPerPage2 &&
                                        <ReactPagination
                                            paginate={paginate2}
                                            pageCount={Math.ceil(filteredData2?.length / postsPerPage2)}
                                            currentPage={currentPage2}
                                            currentdata={currentdata2}
                                            totalPosts={filteredData2.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6> Customised Cakes List - No Response from Vendors</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table table-hover thead-primary">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Cake ID</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 2)}><BsArrowUp className={`sort-up-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'OD' && sorting[2]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[2]?.table === 2 && sorting[2]?.field === 'OD' && sorting[2]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData3.length !== 0 ?
                                                            <tbody>
                                                                {filteredData3.map((cake, i) =>
                                                                    <tr className='cursor' onClick={() => ViewDetails(cake)} key={i}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.UserName}</td>
                                                                        {cake.VendorName ?
                                                                            <td>{cake.VendorName}</td> :
                                                                            <td> --- </td>
                                                                        }
                                                                        <td>{cake.Created_On}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Status.toUpperCase()).replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};