import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import CakeActions from '../../../redux/cake/actions';
import FlavourList from '../ArrayMapping/FlavourList';
import ShapesWithWeightList from '../ArrayMapping/ShapesWithWeightList';
import WeightList from '../ArrayMapping/WeightList';
import CakeImageSlider from '../../vendor/section/Vendor-Cake-Details/CakeImageSlider';
import CakeTypeLists from '../ArrayMapping/CakeType';
import Information from '../ArrayMapping/Information';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import { float2D } from '../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.cakeId;

    const [sliderImages, setSliderImages] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    const { CakeDetails } = useSelector(state => state.CakesReducer);

    const AdditionalCakeImages = CakeDetails?.AdditionalCakeImages;
    const MainCakeImage = CakeDetails?.MainCakeImage;

    useEffect(() => {
        dispatch({ type: CakeActions.GET_SINGLE_CAKEDETAILS, payload: Id });
        // eslint-disable-next-line 
    }, [Id]);


    useEffect(() => {
        if (AdditionalCakeImages?.length > 0 || AdditionalCakeImages !== undefined) {
            setSliderImages([...AdditionalCakeImages, MainCakeImage]);
        } else {
            setSliderImages([MainCakeImage]);
        };
    }, [AdditionalCakeImages, MainCakeImage]);

    // for close the details page 
    const handleClose = () => {
        if (state?.state?.page === 'cakeslist') {
            history.push('/admin-cake-list');
        } else if (state?.state?.page === 'vendordetails') {
            history.push('/admin-vendor-list-detail', { id: CakeDetails?.VendorID });
        };
    };

    // const UpdateCakeDetails = () => {
    //     history.push('/admin-cake-update', { cake: Status?.CakeDetails, id: token?.result?._id });
    // };

    useEffect(() => {
        if (CakeDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [CakeDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6 >Cake Details</h6>
                                </div>
                                <div className="ms-panel-body">
                                    <CakeImageSlider image={sliderImages} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CakeDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Common Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeCommonName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Type</th>
                                                <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeType} /></span></td>
                                            </tr>
                                            {CakeDetails?.CakeSubType.length > 0 &&
                                                <tr>
                                                    <th scope="row">Cake SubType</th>
                                                    <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeSubType} /></span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Default Cake Egg Or Eggless</th>
                                                <td>{CakeDetails?.DefaultCakeEggOrEggless}</td>
                                            </tr>
                                            {CakeDetails?.DefaultCakeEggOrEggless !== 'Eggless' &&
                                                <tr>
                                                    <th scope="row">Is Eggless Option available</th>
                                                    <td>{CakeDetails?.IsEgglessOptionAvailable === 'y' ? 'Yes' : 'No'}</td>
                                                </tr>
                                            }
                                            {(CakeDetails?.BasicEgglessCostPerKg && CakeDetails?.IsEgglessOptionAvailable === 'y') &&
                                                <tr>
                                                    <th scope="row">Basic Eggless cake cost per kg</th>
                                                    <td>{`Rs.${CakeDetails?.BasicEgglessCostPerKg}`}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td>{`Rs.${float2D(CakeDetails?.BasicCakePrice)}`}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{CakeDetails?.Discount}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Tax</th>
                                                <td>{CakeDetails?.Tax}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Created On</th>
                                                <td>{CakeDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Availability</th>
                                                <td><span className={(CakeDetails?.Stock?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Stock}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CakeDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Status}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Category</th>
                                                <td><span className={CakeDetails?.CakeCategory?.replace(/ /g, '')}>{CakeDetails?.CakeCategory}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Basic Flavour</th>
                                                <td><span className="long-line-break">{CakeDetails?.BasicFlavour}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Weight</th>
                                                <td>{CakeDetails?.MinWeight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Shape</th>
                                                <td>{CakeDetails?.BasicShape}</td>
                                            </tr>
                                            {CakeDetails?.CustomFlavourList?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Custom Flavour</th>
                                                    <td><span className="long-line-break"><FlavourList FlavoursList={CakeDetails?.CustomFlavourList} /></span></td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinWeightList?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Minimum Weight List</th>
                                                    <td><WeightList WeightLists={CakeDetails?.MinWeightList} /></td>
                                                </tr>
                                            }
                                            {CakeDetails?.CustomShapeList?.Info?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Custom Shape</th>
                                                    <td><span className="long-line-break"><ShapesWithWeightList ShapesList={CakeDetails?.CustomShapeList} /></span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CakeDetails?.CustomShapeList?.SampleImages?.length > 0 &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6 >Sample Images for Shapes</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <CakeImageSlider image={CakeDetails?.CustomShapeList?.SampleImages} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">OTHER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Minimum Time For Delivery Of Default Cake</th>
                                                <td>{CakeDetails?.MinTimeForDeliveryOfDefaultCake}</td>
                                            </tr>
                                            {CakeDetails?.MinTimeForDeliveryOfABelow2KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 0.5Kg to 2Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfABelow2KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA2to4KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 2Kg to 4Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA2to4KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA4to5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of 4Kg to 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA4to5KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time For Delivery Of Above 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cake Base</th>
                                                <td>{CakeDetails?.CakeBase}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Cream</th>
                                                <td>{CakeDetails?.CakeCream}</td>
                                            </tr>
                                            {CakeDetails?.ButterCreamType &&
                                                <tr>
                                                    <th scope="row">Butter Cream Type</th>
                                                    <td>{CakeDetails?.ButterCreamType}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Best before</th>
                                                <td>{CakeDetails?.BestUsedBefore}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">To Be Stored In</th>
                                                <td>{CakeDetails?.ToBeStoredIn}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Time at Room Temperature before use</th>
                                                <td>{CakeDetails?.KeepTheCakeInRoomTemperature}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Theme Cake Possible</th>
                                                <td>{CakeDetails?.ThemeCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Toppers Possible</th>
                                                <td>{CakeDetails?.ToppersPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Customisation Possible</th>
                                                <td>{CakeDetails?.BasicCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Full Customisation Possibility</th>
                                                <td>{CakeDetails?.FullCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How good are you in this cake</th>
                                                <td>{CakeDetails?.HowGoodAreYouWithTheCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How many times have you Baked this Particular Cake</th>
                                                <td>{CakeDetails?.HowManyTimesHaveYouBakedThisParticularCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Is Tier Cake Possible</th>
                                                <td>{CakeDetails?.IsTierCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{CakeDetails?.Vendor_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{CakeDetails?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Main Mobile Number</th>
                                                <td>{CakeDetails?.VendorPhoneNumber1}</td>
                                            </tr>
                                            {CakeDetails?.VendorPhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Vendor Alternative Mobile Number</th>
                                                    <td>{CakeDetails?.VendorPhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Vendor Address</th>
                                                <td><span className="long-line-break">{CakeDetails?.VendorAddress}</span></td>
                                            </tr>
                                            {CakeDetails?.GoogleLocation &&
                                                <tr>
                                                    <th scope="row">Google Location</th>
                                                    <td><b>Latitude</b> : {CakeDetails?.GoogleLocation?.Latitude} <br />
                                                        <b>Longitude</b> : {CakeDetails?.GoogleLocation?.Longitude}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Rating for the vendor for this Particular Cake</th>
                                                <td><span className={CakeDetails?.RatingsForVendor}>{CakeDetails?.RatingsForVendor}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CakeDetails?.OtherInstructions &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">OTHER INSTRUCTIONS FOR CUSTOMERS</h4>
                                        <hr />
                                        <p>{CakeDetails?.OtherInstructions}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {CakeDetails?.SendInformation?.length > 0 &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">INFORMATION SENT</h4>
                                        <hr />
                                        <Information InfoList={CakeDetails?.SendInformation} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                                    <hr />
                                    <p>{CakeDetails?.Description}</p>
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    {/* <button
                                        className="btn btn-primary ml-3"
                                        type="button"
                                        onClick={UpdateCakeDetails}
                                    >
                                        Edit
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};