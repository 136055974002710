import React from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Content from "../sections/Admin-Cake-Update/Content";
import { Helmet } from "react-helmet";

export default function AdminCakeUpdate() {
    // let tempdata = {
    //     CakeName: CakeName,
    //     CakeCommonName: CakeCommonName,
    //     CakeType: CakeType,
    //     DefaultCakeEggOrEggless: DefaultCakeEggOrEggless,
    //     IsEgglessOptionAvailable: IsEgglessOptionAvailable, //"Y/N"//
    //     BasicEgglessCostPerKg: BasicEgglessCostPerKg,
    //     Discount: Discount,
    //     Tax: Tax,
    //     Status: Status,
    //     Stock: Stock,
    //     BasicFlavour: BasicFlavour,
    //     BasicShape: BasicShape,
    //     MinWeight: MinWeight,
    //     MinTimeForDeliveryOfDefaultCake: MinTimeForDeliveryOfDefaultCake,
    //     MinTimeForDeliveryOfABelow2KgCake: MinTimeForDeliveryOfABelow2KgCake,
    //     MinTimeForDeliveryOfA2to4KgCake: MinTimeForDeliveryOfA2to4KgCake,
    //     MinTimeForDeliveryOfA4to5KgCake: MinTimeForDeliveryOfA4to5KgCake,
    //     MinTimeForDeliveryOfAAbove5KgCake: MinTimeForDeliveryOfAAbove5KgCake,
    //     CakeBase: CakeBase,
    //     CakeCream: CakeCream,
    //     BestUsedBefore: BestUsedBefore,
    //     ToBeStoredIn: ToBeStoredIn,
    //     KeepTheCakeInRoomTemperature: KeepTheCakeInRoomTemperature,
    //     ThemeCakePossible: ThemeCakePossible,
    //     ToppersPossible: ToppersPossible,
    //     BasicCustomisationPossible: BasicCustomisationPossible,
    //     FullCustomisationPossible: FullCustomisationPossible,
    //     HowGoodAreYouWithTheCake: HowGoodAreYouWithTheCake,
    //     HowManyTimesHaveYouBakedThisParticularCake: HowManyTimesHaveYouBakedThisParticularCake,
    //     IsTierCakePossible: IsTierCakePossible,
    //     OtherInstructions: OtherInstructions,
    //     Description: Description,
    //     BasicCakePrice: BasicCakePrice,
    //     CustomFlavourList: FinalCustomFlavourList,  // List//
    //     CustomShapeList: {
    //         Info: FinalCustomShapeList, // List//
    //         SampleImages: FinalSampleImages // List//
    //     },
    //     MinWeightList: FinalMinWeightList, // List//
    //     MainCakeImage: FinalmainImage,
    //     AdditionalCakeImages: FinalCakeAdditionalImage, // List//
    //     Modified_On: Modified_On,

    // }
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme">
            {/* title  */}
            <Helmet>
                <title>Cake&apos;s Details</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end  */}

            <main className="body-content">

                {/* Topnavigation start  */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page conten start  */}
                <Content />
                {/* page content end  */}

            </main>

        </div>
    );
};


