import React from "react";

export default function TableSearch(props) {

    return (
        <div className="col-sm-12 col-md-6 mb-2">
            <div id="data-table-4_filter" className="dataTables_filter">
                <label className="font-weight-bold">{props.label} :
                    <input type={props.type} className="form-control form-control-sm" placeholder={props.placeholder} onChange={props.onChange} value={props.value} aria-controls="data-table-4" />
                </label>
            </div>
        </div>
    )
};