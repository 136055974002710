import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumb() {

    const state=useLocation();

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home'/> Home</Link></li>
                {state.state.page === 'newcakeslist'?
                <>
                <li className="breadcrumb-item"><Link to="/helpdeskV-New-Cakes-List"> New Cakes List</Link></li>
                <li className="breadcrumb-item active" aria-current="page"> New Cake Details</li>
                </>
                :
                <>
                <li className="breadcrumb-item"><Link to="/helpdeskV-Approved-Cakes-List"> Approved Cakes List</Link></li>
                <li className="breadcrumb-item active" aria-current="page"> Approved Cake Details</li>
                </>}
            </ol>
        </nav>
    );
};

export default Breadcrumb;