import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CircleDotSpinner from "../../../common/CircleDotSpinner";

export default function HelpdeskCRemarksForMiscellaneousPopup(props) {

    const { showHelpdeskCRemarksForMiscellaneous, handleCloseMiscellaneousPopups, loader,
        handleHelpdeskCRemarksForMiscellaneous } = props;

    const [remarks, setRemarks] = useState('');
    const [fieldValidate, setFieldValidate] = useState(false);

    const handleChangeSolution = (e) => {
        setRemarks(e.target.value);
        (e.target.value?.length === 0) ? setFieldValidate(true) : setFieldValidate(false);
    };

    const handleSubmitSolution = (e) => {
        e.preventDefault();
        if (remarks) {
            handleHelpdeskCRemarksForMiscellaneous(remarks);
        } else {
            setFieldValidate(true);
        };
    };

    const handleClose = (e) => {
        setFieldValidate(false);
        setRemarks('');
        handleCloseMiscellaneousPopups(e);
    };

    return (
        <div>
            <Modal show={showHelpdeskCRemarksForMiscellaneous} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Remarks</h4>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Your Remarks<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <textarea
                                    className={fieldValidate ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                    type='text'
                                    placeholder="Enter Here..."
                                    onChange={handleChangeSolution}
                                />
                            </div>
                        </div>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleSubmitSolution}
                            >
                                Submit
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};