import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/OtherProducts/actions';
import VendorActions from '../../../redux/Vendor/actions';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import CakeImageSlider from '../../vendor/section/Vendor-Cake-Details/CakeImageSlider';
import Preloader from '../../layouts/Preloader';
import Information from '../ArrayMapping/Information';
import PricePerUnit from '../ArrayMapping/PricePerUnit';
import Flavours from './Flavours';
import PricePerBox from '../ArrayMapping/PricePerBox';
import { float2D } from '../../../utils/Validation';
import { PushNotification } from '../../common/PushNotification';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [show, setShow] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [informationValidate, setInformationValidate] = useState('');

    var [sliderImages, setSliderImages] = useState([]);

    const RatingsForVendor = useRef();
    const CakeCategory = useRef();
    const InformationRef = useRef();

    //get new cakes list
    const { OtherProductDetails, OtherProductResponse } = useSelector(state => state.OtherProductReducer);
    // const Status = useSelector(state => state.OtherProductReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);
    // const Vendor = useSelector(state => state.VendorReducer);

    const auth = useSelector(state => state.authReducer);

    const VendorID = OtherProductDetails?.VendorID;
    const ProductImage = OtherProductDetails?.ProductImage;
    const AdditionalProductImages = OtherProductDetails?.AdditionalProductImages;

    useEffect(() => {
        dispatch({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: Id });
        // eslint-disable-next-line 
    }, [Id]);

    useEffect(() => {
        if (VendorID) {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: VendorID } });
        }
        // eslint-disable-next-line 
    }, [VendorID]);

    useEffect(() => {
        if (ProductImage?.length > 0) {
            if (AdditionalProductImages?.length > 0 || AdditionalProductImages !== undefined) {
                setSliderImages([...AdditionalProductImages, ProductImage[0]]);
            } else {
                setSliderImages([ProductImage[0]]);
            };
        };
    }, [ProductImage, AdditionalProductImages]);

    //approve new cake functionality
    const handleApprove = (e) => {
        e.preventDefault();
        setMessage('');
        if (auth?.token) {
            if (RatingsForVendor.current.value && CakeCategory.current.value) {
                setLoader(true);
                if (VendorDetails?.Notification_Id) {
                    let pushMessageData = {
                        type: 'vendor',
                        registration_ids: [VendorDetails.Notification_Id],
                        title: 'Cakey',
                        body: "Your Product is Approved",
                    }
                    PushNotification(pushMessageData);
                };
                const formdata = {
                    RatingsForVendor: RatingsForVendor.current.value,
                    CakeCategory: CakeCategory.current.value,
                    Status: 'Approved',
                    Status_Updated_By: auth?.token?.result?.AdminName
                };
                dispatch({ type: actions.APPROVE_OTHER_PRODUCT, payload: { id: OtherProductDetails?._id, data: formdata } });
                // history.push('/new-cakes-list');
            } else {
                setMessage("All fields are mandatory");
            };
        };
    };

    //popup close 
    const handleClose = () => {
        if (state?.state?.page === 'newcakeslist') {
            history.push('/new-cakes-list');
        } else {
            history.push('/admin-cake-list');
        }
    };

    //approve cake status validation
    // useEffect(() => {
    //     if (Status.ApproveCakeStatus.statusCode === 200) {
    //         setLoader(false);
    //         setShow(false);
    //         setMessage('');
    //         history.push('/new-cakes-list');
    //         dispatch({ type: actions.GET_ALL_CAKES_BY_STATUS, payload: 'New' });
    //     } else {
    //         setMessage(Status.ApproveCakeStatus.message);
    //         setLoader(false);
    //         setShow(false);
    //     };
    //     // eslint-disable-next-line 
    // }, [Status]);

    const handleApproveValidate = (e) => {
        e.preventDefault();
        if (auth?.token) {
            if (OtherProductDetails?.Status === 'New' || (OtherProductDetails?.Status === 'Updated' && !OtherProductDetails?.CakeCategory)) {
                setShow(true);
            } else {
                const data = {
                    Status_Updated_By: auth?.token?.result?.AdminName
                };
                if (VendorDetails?.Notification_Id) {
                    let pushMessageData = {
                        type: 'vendor',
                        registration_ids: [VendorDetails.Notification_Id],
                        title: 'Cakey',
                        body: "Your Updated Product is Approved",
                    }
                    PushNotification(pushMessageData);
                };
                dispatch({ type: actions.APPROVE_UPDATED_OTHER_PRODUCT, payload: { id: OtherProductDetails?._id, data: data } });
                // history.push('/new-cakes-list');
            }
        }
    };

    const handleSendInfo = (e) => {
        e.preventDefault();
        // setShowInfoPopup(false);
        if (InformationRef.current.value) {
            setLoader(true);
            if (VendorDetails?.Notification_Id) {
                let pushMessageData = {
                    type: 'vendor',
                    registration_ids: [VendorDetails.Notification_Id],
                    title: 'Cakey',
                    body: "Information from Admin",
                }
                PushNotification(pushMessageData);
            };
            if (auth?.token) {
                const formdata = {
                    Information: InformationRef.current.value,
                    Created_By: auth?.token?.result?.AdminName
                };
                dispatch({ type: actions.OTHER_PRODUCT_SEND_INFORMATION, payload: { id: OtherProductDetails?._id, data: formdata } });
                // history.push('/new-cakes-list');
            }
        } else {
            setInformationValidate("Information is required");
        };
    };

    useEffect(() => {
        if (OtherProductResponse?.statusCode === 200 || OtherProductResponse?.statusCode === 400) {
            setShowInfoPopup(false);
            setInformationValidate("");
            setLoader(false);
            setShow(false);
            setMessage('');
        };
    }, [OtherProductResponse]);

    const handleClosePopup = (type) => {
        if (type === 'info') {
            setShowInfoPopup(false);
            setInformationValidate("");
            InformationRef.current.value = '';
        } else {
            setShow(false);
            setMessage('');
            RatingsForVendor.current.value = '';
            CakeCategory.current.value = '';
        };
    };

    useEffect(() => {
        if (OtherProductDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [OtherProductDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6 >Product Details</h6>
                                </div>
                                <div className="ms-panel-body">
                                    <CakeImageSlider image={sliderImages} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Product ID</th>
                                                <td><b>{OtherProductDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Name</th>
                                                <td>{OtherProductDetails?.ProductName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Product Common Name</th>
                                                <td>{OtherProductDetails?.ProductCommonName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Type</th>
                                                <td>{OtherProductDetails?.CakeType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SubType</th>
                                                <td>{OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</td>
                                            </tr>
                                            {OtherProductDetails?.EggOrEggless &&
                                                <tr>
                                                    <th scope="row">Egg Or Eggless</th>
                                                    <td>{OtherProductDetails?.EggOrEggless}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {OtherProductDetails?.Flavour?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Flavour</th>
                                                    <td><Flavours FlavoursList={OtherProductDetails?.Flavour} /></td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.Shape &&
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td>{OtherProductDetails?.Shape}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.MinWeightPerKg?.Weight &&
                                                <tr>
                                                    <th scope="row">Minimum Weight</th>
                                                    <td>{OtherProductDetails?.MinWeightPerKg?.Weight}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.MinWeightPerKg?.PricePerKg &&
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td>Rs. {float2D(OtherProductDetails?.MinWeightPerKg?.PricePerKg)}</td>
                                                </tr>
                                            }

                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{OtherProductDetails?.Discount}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Created On</th>
                                                <td>{OtherProductDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Availability</th>
                                                <td><span className={(OtherProductDetails?.Stock?.toUpperCase())?.replace(/ /g, '')}>{OtherProductDetails?.Stock}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(OtherProductDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{OtherProductDetails?.Status}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    {OtherProductDetails?.MinWeightPerUnit?.length > 0 &&
                                        <div className="row">
                                            <PricePerUnit WeightAndPrice={OtherProductDetails?.MinWeightPerUnit}
                                            />
                                        </div>
                                    }
                                    {OtherProductDetails?.MinWeightPerBox?.length > 0 &&
                                        <div className="row">
                                            <PricePerBox PieceAndPrice={OtherProductDetails?.MinWeightPerBox}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">OTHER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Minimum Time for Delivery</th>
                                                <td>{OtherProductDetails?.MinTimeForDelivery}</td>
                                            </tr>
                                            {OtherProductDetails?.CakeBase &&
                                                <tr>
                                                    <th scope="row">Product Base</th>
                                                    <td>{OtherProductDetails?.CakeBase}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Best before </th>
                                                <td>{OtherProductDetails?.BestUsedBefore}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">To Be Stored In</th>
                                                <td>{OtherProductDetails?.ToBeStoredIn}</td>
                                            </tr>
                                            {OtherProductDetails?.KeepTheCakeInRoomTemperature &&
                                                <tr>
                                                    <th scope="row">Minimum Time at Room Temperature before use </th>
                                                    <td>{OtherProductDetails?.KeepTheCakeInRoomTemperature}</td>
                                                </tr>
                                            }
                                            {OtherProductDetails?.ToppersPossible &&
                                                <tr>
                                                    <th scope="row">Toppers Possible</th>
                                                    <td>{OtherProductDetails?.ToppersPossible === 'y' ? 'Yes' : 'No'}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">How good are you in this {OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</th>
                                                <td>{OtherProductDetails?.HowGoodAreYouWithTheCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How many times have you Baked this Particular {OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</th>
                                                <td>{OtherProductDetails?.HowManyTimesHaveYouBakedThisParticularCake}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{OtherProductDetails?.Vendor_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{OtherProductDetails?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Main Mobile Number</th>
                                                <td>{OtherProductDetails?.VendorPhoneNumber1}</td>
                                            </tr>
                                            {OtherProductDetails?.VendorPhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Vendor Alternative Mobile Number</th>
                                                    <td>{OtherProductDetails?.VendorPhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Vendor Address</th>
                                                <td><span className="long-line-break">{OtherProductDetails?.VendorAddress}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Google Location</th>
                                                <td><b>Latitude:</b> {OtherProductDetails?.GoogleLocation?.Latitude},<br />
                                                    <b>Longitude:</b> {OtherProductDetails?.GoogleLocation?.Longitude}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {OtherProductDetails?.SendInformation?.length > 0 &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">INFORMATION SENT</h4>
                                        <hr />
                                        <Information InfoList={OtherProductDetails?.SendInformation} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRODUCT DESCRIPTION</h4>
                                    <hr />
                                    <p>{OtherProductDetails?.Description}</p>
                                    <hr />
                                    {(OtherProductDetails?.Status === 'New' || OtherProductDetails?.Status === 'Updated') &&
                                        <>
                                            <button
                                                className="btn btn-success float-start mr-3"
                                                type="submit" onClick={handleApproveValidate}
                                            >
                                                Approve
                                            </button>
                                            <button
                                                className="btn btn-primary float-start mr-3"
                                                type="submit"
                                                onClick={() => setShowInfoPopup(true)}
                                            >
                                                Send Information
                                            </button>
                                        </>
                                    }
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* approve new cake Confirmation popup */}
                    <Modal className="modal-min" show={show} onHide={() => handleClosePopup('approve')} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={() => handleClosePopup('approve')}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Confirmation</h1>
                            <div className="form-row">
                                <div className="col-md-12 mb-1">
                                    <label className='float-left' htmlFor="validationCustom18"><b>Rating for the vendor for this {OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}</b> <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select className="form-control" ref={RatingsForVendor} defaultValue='' id="validationCustom22" >
                                            <option value='' disabled='disabled'>-- Select --</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Good">Good</option>
                                            <option value="Excellent">Excellent</option>
                                            <option value="Specialist">Specialist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-1">
                                    <label className='float-left' htmlFor="validationCustom18"><b>Product Category</b> <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select className="form-control" ref={CakeCategory} defaultValue='' id="validationCustom22" >
                                            <option value='' disabled='disabled'>-- Select --</option>
                                            <option value="Regular">Regular</option>
                                            <option value="Special">Special</option>
                                            <option value="Premium">Premium</option>
                                            <option value="Rare">Rare</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <p className='text-danger'>{message}</p>
                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mr-3"
                                        onClick={handleApprove}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        onClick={() => handleClosePopup('approve')}
                                    >
                                        Cancel
                                    </button>
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showInfoPopup} onHide={() => handleClosePopup('info')} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={() => handleClosePopup('info')}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-4">Send Information</h1>
                            <div className="form-row">
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <textarea
                                            type="text"
                                            row={7}
                                            className="form-control text-capitalize"
                                            ref={InformationRef}
                                            placeholder="Enter Here..."
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='text-danger text-left'>{informationValidate}</p>
                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mr-3"
                                        onClick={handleSendInfo}
                                    >
                                        Send
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        onClick={() => handleClosePopup('info')}
                                    >
                                        Cancel
                                    </button>
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
};