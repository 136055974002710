import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb({ page }) {
    return (
        <nav aria-label="breadcrumb">
            {page === 'list' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/vendor-hamper-orders-list">Hamper Orders List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Hamper Order Details</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Reports</li>
                    <li className="breadcrumb-item"><Link to="/orders-report">Orders</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Hamper Order Details</li>
                </ol>
            }
        </nav>
    );
}

export default Breadcrumb;