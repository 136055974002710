import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/esm/react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import actions from '../../../redux/Topper/actions';
import TableSearch from "../../common/TableSearch";
import Preloader from "../../layouts/Preloader";
import Breadcrumb from "./Breadcrumb";
import ReactPagination from "../../common/React-Pagination";
import { float2D } from "../../../utils/Validation";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { AdminHamperList } = useSelector(state => state.TopperReducer);

    //dispatch customized cake list api
    useEffect(() => {
        dispatch({ type: actions.GET_ADMIN_HAMPERS_LIST });
        // eslint-disable-next-line 
    }, []);

    //get search input value
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //validate orders list is empty or not
    useEffect(() => {
        if (AdminHamperList?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(AdminHamperList?.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.HampersName && val.HampersName.toLowerCase().includes(search)) ||
                    (val.Price && val.Price.toLowerCase().includes(search)) ||
                    (val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [AdminHamperList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //view details page
    const ViewDetails = (cake) => {
        history.push('/Admin-hamper-details', { id: cake._id });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_ADMIN_HAMPERS_LIST });
    };

    //loading
    useEffect(() => {
        if (AdminHamperList.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
        // eslint-disable-next-line
    }, [AdminHamperList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Hampers List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Hamper ID</th>
                                                                <th>Hamper Image</th>
                                                                <th>Hamper Name</th>
                                                                <th>Vendor Name</th>
                                                                <th>Status</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake, i) =>
                                                                    <tr className="odd cursor " key={i} onClick={() => ViewDetails(cake)}>
                                                                        <th className="sorting_1 id-column" scope="row">{cake.Id}</th>
                                                                        <td><img className='profile-image' src={cake.ApproveImage ? cake.ApproveImage : cake.HamperImage} alt="Cake" /></td>
                                                                        <td>{cake.HampersName}</td>
                                                                        <td>{cake.VendorName}</td>
                                                                        <td><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                        <td className="hamper-price-alignment">{float2D(cake.Price)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};