import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TicketsActions from '../../../../redux/Tickets/actions';
import CommonActions from '../../../../redux/Common/actions';
// import UserActions from '../../../../redux/users/actions';
import CustomizedCakeActions from '../../../../redux/Customize-Cake/actions';
import OrderActions from '../../../../redux/Order/actions'
import vendorActions from '../../../../redux/Vendor/actions'
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Breadcrumb from './Breadcrumb';
import Select from 'react-select';
import { useForm } from "react-hook-form";

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [causeOfTicket, setCauseOfTicket] = useState('');
    const [typeOfOrder, setTypeOfOrder] = useState('');
    const [orderDetails, setOrderDetails] = useState();
    const [selectedOrder, setSelectedOrder] = useState(false);
    const [loader, setLoader] = useState(false);

    let [ordersList, setOrdersList] = useState([]);
    let [listOfVendors, setListOfVendors] = useState([]);
    let [customizedCakeOrders, setCustomizedCakeOrders] = useState([]);

    const {
        // AllOrdersList, 
        Ticket_Response } = useSelector(state => state.TicketReducer);

    const { response } = useSelector(state => state.commonReducer);

    const auth = useSelector((state) => state.authReducer);

    //get customized cake orders list below 5kg for final pricing
    const { CakeyDirectCustomizedCakeOrdersForHelpdeskV, CustomizedCakeOrderThroughVendor } = useSelector(state => state.CustomizeCakeReducer);

    // const { CakeyDirectCustomizedCakeOrders } = useSelector(state => state.CustomizeCakeReducer);

    const { OrderListByAbove5kg } = useSelector(state => state.OrderReducer)

    const { vendorsList } = useSelector(state => state.VendorReducer)

    const { register, reset, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm();

    const { RejectedOrders } = useSelector(state => state.TicketReducer);

    useEffect(() => {
        dispatch({ type: TicketsActions.GET_REJECTED_ORDERS_LIST });
        //eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     let OrdersListForDisplay = [],filteredArray=[];
    //     if (causeOfTicket === 'Order Cancellation by Vendor') {
    //         if (!AllOrdersList && AllOrdersList.length === 0) {
    //             setOrdersList([]);
    //         } else {
    //             if (AllOrdersList?.message === 'No Records Found') {
    //                 setOrdersList([]);
    //             } else {
    //                 if (typeOfOrder === 'cakeorder' || typeOfOrder === 'productorder' || typeOfOrder === 'hamperorder') {
    //                     AllOrdersList.map(val => {
    //                         return OrdersListForDisplay.push({
    //                             value: val,
    //                             label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status}`
    //                         });
    //                     });
    //                 } else {
    //                     AllOrdersList.map(val => {
    //                         return OrdersListForDisplay.push({ value: val, label: `${val.Id} - ${val.UserName}` });
    //                     });
    //                 }
    //                 setOrdersList(OrdersListForDisplay);
    //             }
    //         };
    //     } else if (causeOfTicket === 'Cakey Direct-General Order') {
    //         if (!OrderListByAbove5kg && OrderListByAbove5kg.length === 0) {
    //             setOrdersList([]);
    //         } else {
    //             if (OrderListByAbove5kg?.message === 'No Records Found') {
    //                 setOrdersList([]);
    //             } else {

    //                 OrderListByAbove5kg.map(val => {
    //                     return OrdersListForDisplay.push({
    //                         value: val,
    //                         label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status}`
    //                     });
    //                 });

    //                 setOrdersList(OrdersListForDisplay);
    //             }
    //         };
    //     }

    // }, [AllOrdersList, typeOfOrder, OrderListByAbove5kg, causeOfTicket]);

    useEffect(() => {
        let OrdersListForDisplay = [];
        if (causeOfTicket === 'Order Cancellation by Vendor') {
            if (!RejectedOrders && RejectedOrders.length === 0) {
                setOrdersList([]);
            } else {
                if (RejectedOrders?.message === 'No Records Found') {
                    setOrdersList([]);
                } else {
                    if (typeOfOrder === 'cakeorder' || typeOfOrder === 'productorder' || typeOfOrder === 'hamperorder') {
                        //eslint-disable-next-line
                        RejectedOrders.filter(val => {
                            if (val.CakeID && typeOfOrder === 'cakeorder' && val.Ticket_RaisedFor_Vendor_Cancel === "No") {
                                return OrdersListForDisplay.push({
                                    value: val,
                                    label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.CakeName}`
                                });
                            } else if (val.Other_ProductID && typeOfOrder === 'productorder' && val.Ticket_RaisedFor_Vendor_Cancel === "No") {
                                return OrdersListForDisplay.push({
                                    value: val,
                                    label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.ProductName}`
                                });
                            }

                        });
                    } else {
                        RejectedOrders.map(val => {
                            return OrdersListForDisplay.push({ value: val, label: `${val.Id} - ${val.UserName}` });
                        });
                    }
                    setOrdersList(OrdersListForDisplay);
                }
            };
        } else if (causeOfTicket === 'Cakey Direct-General Order') {
            if (!OrderListByAbove5kg && OrderListByAbove5kg.length === 0) {
                setOrdersList([]);
            } else {
                if (OrderListByAbove5kg?.message === 'No Records Found') {
                    setOrdersList([]);
                } else {
                    //eslint-disable-next-line
                    OrderListByAbove5kg.map(val => {
                        if (val.Ticket_Raised_For_Above_5KG === "No" && val.Status !== 'Cancelled') {
                            let label;
                            (val.VendorName) ? label = `${val.Id} - ${val.UserName} - ${val.VendorName} - ${val.Status}` :
                                label = `${val.Id} - ${val.UserName} - ${val.Status}`
                            return OrdersListForDisplay.push({
                                value: val,
                                label: label
                                // label: `${val.Id} - ${val.UserName} - ${val.VendorName && `${val.VendorName} -`} ${val.Status}`
                            });
                        }
                    });
                    setOrdersList(OrdersListForDisplay);
                }
            };
        }

    }, [RejectedOrders, typeOfOrder, OrderListByAbove5kg, causeOfTicket]);

    useEffect(() => {
        let VendorsListForDisplay = [];
        if (!vendorsList && vendorsList.length === 0) {
            setListOfVendors([]);
        } else {
            if (vendorsList?.message === 'No Records Found') {
                setListOfVendors([]);
            } else {
                vendorsList.map(val => {
                    return VendorsListForDisplay.push({
                        value: val,
                        label: `${val.Id} - ${val.VendorName && `${val.VendorName} - ${val.PhoneNumber1}`}`
                    });
                });
                setListOfVendors(VendorsListForDisplay);
            }
        };
    }, [vendorsList]);

    useEffect(() => {
        let CustomizedCakeListForDisplay = [];
        if (causeOfTicket === 'Cakey Direct-Fully Customized Cake') {
            if (!CakeyDirectCustomizedCakeOrdersForHelpdeskV && CakeyDirectCustomizedCakeOrdersForHelpdeskV.length === 0) {
                setCustomizedCakeOrders([]);
            } else {
                if (CakeyDirectCustomizedCakeOrdersForHelpdeskV?.message === 'No Records Found') {
                    setCustomizedCakeOrders([]);
                } else {
                    CakeyDirectCustomizedCakeOrdersForHelpdeskV.filter(val => {
                        if (val.Ticket_Raised_For_Customized_Cake === 'No') {
                            return CustomizedCakeListForDisplay.push({
                                value: val,
                                label: `${val.Id} - ${val.UserName}`
                            });
                        }
                        return CustomizedCakeListForDisplay;
                    });
                    setCustomizedCakeOrders(CustomizedCakeListForDisplay);
                }
            };
        } else if (causeOfTicket === 'Customized Cake through Vendor') {
            if (!CustomizedCakeOrderThroughVendor && CustomizedCakeOrderThroughVendor.length === 0) {
                setCustomizedCakeOrders([]);
            } else {
                if (CustomizedCakeOrderThroughVendor?.message === 'No Records Found') {
                    setCustomizedCakeOrders([]);
                } else {
                    CustomizedCakeOrderThroughVendor.filter(val => {
                        if (val.Ticket_Raised === 'n' && val.Status === 'Sent') {
                            return CustomizedCakeListForDisplay.push({
                                value: val,
                                label: `${val.Id} - ${val.UserName} - ${val.VendorName}`
                            });
                        }

                        return CustomizedCakeListForDisplay;
                    });
                    setCustomizedCakeOrders(CustomizedCakeListForDisplay);
                }
            };
        }

    }, [CakeyDirectCustomizedCakeOrdersForHelpdeskV, causeOfTicket, CustomizedCakeOrderThroughVendor]);


    const handleChangeCauseOfTicket = (e) => {
        setValue('CauseOfTicket', e.target.value);
        clearErrors('CauseOfTicket');
        setCauseOfTicket(e.target.value);
        setValue('TypeOfOrder', '');
        setTypeOfOrder('');
        setOrderDetails();
        if (e.target.value === 'Cakey Direct-Fully Customized Cake') {
            setValue('TypeOfOrder', 'cakeorder');
            dispatch({ type: TicketsActions.SET_ALL_ORDERS_LIST_BY_TYPE, payload: [] });
            dispatch({ type: CustomizedCakeActions.GET_CAKEY_DIRECT_CUSTOMIZED_CAKE_ORDERS_FOR_HELPDESKV });
        }
        else if (e.target.value === 'Cakey Direct-General Order') {
            setValue('TypeOfOrder', 'cakeorder');
            dispatch({ type: OrderActions.GET_ORDER_LIST_BY_ABOVE_5KG, payload: 'y' })
        } else if (e.target.value === 'Customized Cake through Vendor') {
            dispatch({ type: CustomizedCakeActions.GET_CUSTOMIZED_CAKE_ORDER_THROUGH_VENDOR });
        }
    };

    const handleChangeTypeOfOrder = (e) => {
        setValue('TypeOfOrder', e.target.value);
        clearErrors('TypeOfOrder');
        setTypeOfOrder(e.target.value);
        setOrderDetails('');
        dispatch({ type: TicketsActions.GET_ALL_ORDERS_LIST_BY_TYPE, payload: e.target.value });
    };

    const handleSelectedOption = (e) => {
        setOrderDetails(e);
        setSelectedOrder(false);
    };

    const handleRaiseTicket = (data) => {
        if (!orderDetails) {
            setSelectedOrder(true);
        } else {
            let formData, Accepted_By, Ticket_Raised_For, Last_Intimate;
            Accepted_By = 'HelpdeskV_Accepted_By';
            Ticket_Raised_For = 'Vendor';
            Last_Intimate = ['HelpdeskV'];
            if (causeOfTicket === 'Vendor Request') {
                let Last_Intimate_For_VR = ['HelpdeskV', 'Manager'];
                formData = {
                    TypeOfUser: auth?.token?.result?.TypeOfUser,
                    CauseOfTicket: data.CauseOfTicket,
                    TicketDescription: data.TicketDescription,
                    VendorID: orderDetails?.value?._id,
                    Vendor_ID: orderDetails?.value?.Id,
                    VendorName: orderDetails?.value?.VendorName,
                    Ticket_Raised_For: Ticket_Raised_For,
                    Last_Intimate: Last_Intimate_For_VR,
                    Ticket_Status: 'In Progress',
                    Ticket_Raised_By: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    [Accepted_By]: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk V') && {
                        HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'y', Management: 'n', Accounts: 'n'
                    }
                };
            } else {
                formData = {
                    TypeOfUser: auth?.token?.result?.TypeOfUser,
                    CauseOfTicket: data.CauseOfTicket,
                    TicketDescription: data.TicketDescription,
                    TypeOfOrder: data.TypeOfOrder === 'cakeorder' ? 'Cake Order' : data.TypeOfOrder === 'productorder' ? 'Product Order' :
                        data.TypeOfOrder === 'hamperorder' ? 'Hamper Order' : 'Customised Cake Order',
                    OrderID: orderDetails?.value?._id,
                    Order_ID: orderDetails?.value?.Id,
                    Order_Status: orderDetails?.Status,
                    ProductID: data.TypeOfOrder === 'cakeorder' ? orderDetails?.value?.CakeID : data.TypeOfOrder === 'productorder' ? orderDetails?.value?.Other_ProductID :
                        data.TypeOfOrder === 'hamperorder' ? orderDetails?.value?.HamperID : '',
                    Product_ID: data.TypeOfOrder === 'cakeorder' ? orderDetails?.value?.Cake_ID : data.TypeOfOrder === 'productorder' ? orderDetails?.value?.Other_Product_ID :
                        data.TypeOfOrder === 'hamperorder' ? orderDetails?.value?.Hamper_ID : '',
                    VendorID: orderDetails?.value?.VendorID,
                    Vendor_ID: orderDetails?.value?.Vendor_ID,
                    UserID: orderDetails?.value?.UserID,
                    User_ID: orderDetails?.value?.User_ID,
                    Weight: orderDetails?.value?.Weight,
                    Price: orderDetails?.value?.Price,
                    Total: orderDetails?.value?.Total,
                    Ticket_Raised_For: Ticket_Raised_For,
                    Last_Intimate: Last_Intimate,
                    Ticket_Status: 'In Progress',
                    Ticket_Raised_By: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    [Accepted_By]: {
                        ID: auth?.token?.result?._id,
                        Name: auth?.token?.result?.Name,
                        Email: auth?.token?.result?.Email,
                        TypeOfUser: auth?.token?.result?.TypeOfUser
                    },
                    Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk V') && {
                        HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
                    }
                };
            }
            setLoader(true);
            dispatch({
                type: TicketsActions.RAISE_NEW_TICKET,
                payload: formData
            });
            setTimeout(() => {
                dispatch({ type: TicketsActions.TICKET_RESPONSE, payload: [] })
            }, 3000);
        };
    };

    const handleCancel = (e) => {
        e.preventDefault();
        history.push('/helpdeskV-Tickets-List');
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400) {
            setLoader(false);
        } else if (Ticket_Response.statusCode === 200) {
            setLoader(false);
            reset();
            setOrderDetails();
            setSelectedOrder(false);
            setCauseOfTicket('');
            setTypeOfOrder('');
        };
        // eslint-disable-next-line 
    }, [Ticket_Response]);

    useEffect(() => {
        if (response?.alert === true) {
            setTimeout(() => {
                dispatch({
                    type: CommonActions.SET_ALERT,
                    payload: { alert: false, message: null, status: null }
                });
            }, 2000);
        };
        //eslint-disable-next-line
    }, [response]);

    useEffect(() => {

        dispatch({ type: vendorActions.GET_ALL_VENDORS })

        //eslint-disable-next-line
    }, [])

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb Page={Page} />
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header"><h6>Raise Your Ticket</h6></div>
                        <div className="ms-panel-body">
                            <form className="needs-validation clearfix" noValidate="">
                                <div className="form-row">
                                    <div className={(causeOfTicket && (causeOfTicket === 'Order Cancellation by Vendor')) ? "col-xl-6 col-lg-6 col-md-12 mb-3" : "col-xl-12 col-lg-12 col-md-12 mb-3"}>
                                        <label htmlFor="validationCustom18"><b>Cause Of Ticket</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select
                                                className={errors.CauseOfTicket ? 'form-control required-field' : 'form-control'}
                                                defaultValue=''
                                                {...register('CauseOfTicket', { required: { value: true, message: 'required' } })}
                                                onChange={handleChangeCauseOfTicket}
                                            >
                                                <option value='' disabled>--Select--</option>
                                                <option value='Order Cancellation by Vendor'>Order Cancellation by Vendor</option>
                                                <option value='Cakey Direct-General Order'>Cakey Direct-General Order(Non Customised Cake)</option>
                                                <option value='Cakey Direct-Fully Customized Cake'>Cakey Direct-Fully Customised Cake</option>
                                                <option value='Customized Cake through Vendor'>Customised Cake through Vendor for Final Pricing</option>
                                                <option value='Vendor Request'>Vendor Request</option>
                                            </select>
                                        </div>
                                    </div>
                                    {(causeOfTicket && (causeOfTicket === 'Order Cancellation by Vendor')) &&
                                        <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Type Of Order</b> <span className='text-danger'>*</span></label>
                                            <select
                                                className={errors.TypeOfOrder ? 'form-control required-field' : 'form-control'}
                                                defaultValue=''
                                                {...register('TypeOfOrder', { required: { value: true, message: 'required' } })}
                                                onChange={handleChangeTypeOfOrder}
                                            >
                                                <option value='' disabled>--Select--</option>
                                                <option value='cakeorder'>Cake Order</option>
                                                <option value='productorder'>Product Order</option>
                                                {causeOfTicket !== "Order Cancellation by Vendor" &&
                                                    <option value='hamperorder'>Hamper Order</option>
                                                }

                                            </select>
                                        </div>
                                    }
                                    {causeOfTicket === 'Vendor Request' &&
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom18"><b>Vendors</b> <span className='text-danger'>*</span> (Vendor ID - Vendor Name - Phonenumber)</label>
                                            {vendorsList.length === 0 ?
                                                <>
                                                    <select className="form-control" defaultValue=''>
                                                        <option value='' disabled>No Vendors Found</option>
                                                    </select>
                                                </> :
                                                <Select
                                                    className={selectedOrder ? "required-field" : ""}
                                                    styles={customStyles}
                                                    options={listOfVendors}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    onChange={(e) => handleSelectedOption(e)}
                                                    value={orderDetails}
                                                />
                                            }
                                        </div>
                                    }
                                    {(causeOfTicket === 'Cakey Direct-Fully Customized Cake' || causeOfTicket === 'Customized Cake through Vendor') &&
                                        <div className="col-md-12 mb-3">
                                            {causeOfTicket === 'Cakey Direct-Fully Customized Cake' ?
                                                <label htmlFor="validationCustom18"><b>Customised Cake Orders</b> <span className='text-danger'>*</span> (Order ID - Customer Name)</label> :
                                                <label htmlFor="validationCustom18"><b>Customised Cake Orders</b> <span className='text-danger'>*</span> (Order ID - Customer Name - Vendor Name)</label>
                                            }
                                            <Select
                                                className={selectedOrder ? "required-field" : ""}
                                                styles={customStyles}
                                                options={customizedCakeOrders}
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                onChange={(e) => handleSelectedOption(e)}
                                                value={orderDetails}
                                            />
                                        </div>
                                    }
                                    {(typeOfOrder || causeOfTicket === 'Cakey Direct-General Order') &&
                                        <div className="col-md-12 mb-3">
                                            {causeOfTicket === 'Cakey Direct-General Order' ?
                                                <label htmlFor="validationCustom18"><b>Orders</b> <span className='text-danger'>*</span> (Order ID - Customer Name - Vendor Name - Status)</label> :
                                                <label htmlFor="validationCustom18"><b>Orders</b> <span className='text-danger'>*</span><span>{(causeOfTicket === 'Order Cancellation by Vendor' && typeOfOrder === 'cakeorder') ? (' (Cake ID - Customer Name - Vendor Name - Cake Name)') : (' (Product ID - Customer Name - Vendor Name - Product Name)')}</span></label>
                                            }
                                            {/* {ordersList.length === 0 ?
                                                <>
                                                    <select className="form-control" defaultValue=''>
                                                        <option value='' disabled>No Orders Found</option>
                                                    </select>
                                                </> : */}
                                                <Select
                                                    className={selectedOrder ? "required-field" : ""}
                                                    styles={customStyles}
                                                    options={ordersList}
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    onChange={(e) => handleSelectedOption(e)}
                                                    value={orderDetails}
                                                    noOptionsMessage={() => 'No Orders'}
                                                />
                                            {/* } */}
                                        </div>
                                    }
                                    {(causeOfTicket !== 'Vendor Request' && orderDetails) &&
                                        <div className='col-md-12 mb-3'>
                                            <div className="ms-panel ms-panel-fh">
                                                <div className="ms-panel-body tabledata-background">
                                                    <h4 className="section-title bold">Selected Order's Details</h4>
                                                    <table className="table ms-profile-information">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Order ID</th>
                                                                <td><b>{orderDetails?.value?.Id}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">{typeOfOrder === 'cakeorder' || causeOfTicket === 'Cakey Direct-General Order' ? 'Cake Name' : typeOfOrder === 'productorder' ? 'Product Name' : typeOfOrder === 'hamperorder' ? 'Hamper Name' : 'Customised Cake Name'}</th>
                                                                <td>{typeOfOrder === 'cakeorder' || causeOfTicket === 'Cakey Direct-General Order' ? orderDetails?.value?.CakeName : typeOfOrder === 'productorder' ? orderDetails?.value?.ProductName : typeOfOrder === 'hamperorder' ? orderDetails?.value?.HampersName : orderDetails?.value?.CakeName}</td>
                                                            </tr>
                                                            {typeOfOrder === 'productorder' &&
                                                                <tr>
                                                                    <th scope="row">Product SubType</th>
                                                                    <td>{orderDetails?.value?.CakeSubType}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <th scope="row">User Name</th>
                                                                <td>{orderDetails?.value?.UserName}</td>
                                                            </tr>
                                                            {orderDetails?.value?.VendorName &&
                                                                <tr>
                                                                    <th scope="row">Vendor Name</th>
                                                                    <td>{orderDetails?.value?.VendorName}</td>
                                                                </tr>
                                                            }
                                                            {(causeOfTicket === 'Cakey Direct-Fully Customized Cake' || causeOfTicket === 'Customized Cake through Vendor') &&
                                                                <tr>
                                                                    <th scope="row">Flavour</th>
                                                                    <td>
                                                                        <FlavourList
                                                                            FlavoursList={orderDetails?.value?.Flavour}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {(causeOfTicket === 'Cakey Direct-Fully Customized Cake') &&
                                                                <tr>
                                                                    <th scope="row">Shape</th>
                                                                    <td>{`${orderDetails?.value?.Shape?.Name} - Additional Rs.${orderDetails?.value?.Shape?.Price}`}</td>
                                                                </tr>
                                                            }
                                                            {(causeOfTicket === 'Customized Cake through Vendor') &&
                                                                <>
                                                                    <tr>
                                                                        <th scope="row">Shape</th>
                                                                        <td>{`${orderDetails?.value?.Shape}`}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Price</th>
                                                                        <td>{`${orderDetails?.value?.Price}`}</td>
                                                                    </tr>
                                                                </>

                                                            }


                                                            {orderDetails?.value?.Total &&
                                                                <tr>
                                                                    <th scope="row">Total Price</th>
                                                                    <td>{orderDetails?.value?.Total}</td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <th scope="row">Order Status</th>
                                                                <td>
                                                                    <span className={(orderDetails?.value?.Status?.toUpperCase())?.replace(/ /g, '')}>{orderDetails?.value?.Status}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Order Date</th>
                                                                <td>{orderDetails?.value?.Created_On}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Delivery Date</th>
                                                                <td>{orderDetails?.value?.DeliveryDate}</td>
                                                            </tr>
                                                            {causeOfTicket === "Cakey Direct-General Order" &&
                                                                <tr>
                                                                    <th scope="row">Weight</th>
                                                                    <td>{orderDetails?.value?.Weight}</td>
                                                                </tr>
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Ticket Description</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <textarea
                                                className={errors.TicketDescription ? 'form-control required-field text-capitalize description-textarea' : 'form-control text-capitalize description-textarea'}
                                                placeholder='Type here...'
                                                {...register('TicketDescription', { required: { value: true, message: 'required' } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {causeOfTicket === 'Vendor Request' &&
                                    <div>
                                        <p className="text-success intimation-message">This will be auto intimated to Manager</p>
                                    </div>
                                }
                                {loader ?
                                    <CircleDotSpinner /> :
                                    <>
                                        <button
                                            className="btn btn-primary"
                                            type="submit"
                                            onClick={handleSubmit(handleRaiseTicket)}
                                        >
                                            Raise Ticket
                                        </button>
                                        <button
                                            className="btn btn-secondary ml-4"
                                            type="button"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};