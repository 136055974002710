const actions = {
  GET_ALL_VENDORS: 'GET_ALL_VENDORS',
  SET_ALL_VENDORS: 'SET_ALL_VENDORS',
  REGISTER_VENDOR: 'REGISTER_VENDOR',
  REGISTER_VENDOR_STATUS: 'REGISTER_VENDOR_STATUS',
  APPROVE_VENDOR: 'APPROVE_VENDOR',
  APPROVE_VENDOR_STATUS: 'APPROVE_VENDOR_STATUS',
  ADD_VENDORS: 'ADD_VENDORS',
  ADD_VENDORS_STATUS: 'ADD_VENDORS_STATUS',
  GET_SINGLE_VENDORS: 'GET_SINGLE_VENDORS',
  SET_SINGLE_VENDORS: 'SET_SINGLE_VENDORS',
  UPDATE_VENDOR: 'UPDATE_VENDOR',
  SET_UPDATE_VENDORPROFILE_STATUS: 'SET_UPDATE_VENDORPROFILE_STATUS',
  GET_VENDOR_RECENT_ORDER: 'GET_VENDOR_ORDERBYSTATUS',
  SET_VENDOR_RECENT_ORDER: 'SET_VENDOR_ORDERBYSTATUS',
  GET_VENDORORDER_STATUS_COUNT: 'GET_VENDORORDER_STATUS_COUNT',
  SET_VENDORORDER_STATUS_COUNT: 'SET_VENDORORDER_STATUS_COUNT',
  GET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID: 'GET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID',
  SET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID: 'SET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID',
  GET_LOGIN_SESSION: 'GET_LOGIN_SESSION',
  SET_LOGIN_SESSION: 'SET_LOGIN_SESSION',
  UPLOAD_PROFILE_IMAGE: 'UPLOAD_PROFILE_IMAGE',
  BLOCK_AND_UNBLOCK_VENDOR: 'BLOCK_AND_UNBLOCK_VENDOR',
  GET_VENDOR_DETAILS_BY_ID: 'GET_VENDOR_DETAILS_BY_ID',
  SET_VENDOR_DETAILS_BY_ID: 'SET_VENDOR_DETAILS_BY_ID',
  GET_VENDOR_DETAILS2: 'GET_VENDOR_DETAILS2',
  SET_VENDOR_DETAILS2: 'SET_VENDOR_DETAILS2',
  GET_VENDOR_DASHBOARD_COUNT: 'GET_VENDOR_DASHBOARD_COUNT',
  SET_VENDOR_DASHBOARD_COUNT: 'SET_VENDOR_DASHBOARD_COUNT',
  VENDOR_RESPONSE: 'VENDOR_RESPONSE'

};

export default actions;
