import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import ReactPagination from '../../common/React-Pagination';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);

    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const { DailyTicketReport } = useSelector(state => state.TicketReducer);

    const auth = useSelector((state) => state.authReducer);

    const Email = auth?.token?.result?.Email;

    useEffect(() => {
        if (Page === 'Manager') {
            dispatch({
                type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                payload: {
                    Email: 'All',
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: moment().format('DD-MM-YYYY')
                }
            });
        } else {
            dispatch({
                type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                payload: {
                    Email: Email,
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: moment().format('DD-MM-YYYY')
                }
            });
        }
        //eslint-disable-next-line
    }, [Email, Page]);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (DailyTicketReport?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(DailyTicketReport.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.InternalUser_ID && val.InternalUser_ID.toLowerCase().includes(search)) ||
                    (val.Name && val.Name.toLowerCase().includes(search)) ||
                    (val.Email && val.Email.toLowerCase().includes(search)) ||
                    (val.TypeOfUser && val.TypeOfUser.toLowerCase().includes(search)) ||
                    (val.Today_Tickets_Count && val.Today_Tickets_Count.toLowerCase().includes(search)) ||
                    (val.Today_Closed_Tickets_Count && val.Today_Closed_Tickets_Count.toLowerCase().includes(search)) ||
                    (val.Total_Unclosed_Tickets_Count && val.Total_Unclosed_Tickets_Count.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [DailyTicketReport, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        let LoginEmail;
        (Page === 'Manager') ? LoginEmail = "All" : LoginEmail = Email;
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({
                type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                payload: {
                    Email: LoginEmail,
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: moment().format('DD-MM-YYYY')
                }
            });
        } else {
            if (startDate && endDate) {
                setButtonChange(true);
                dispatch({
                    type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                    payload: {
                        Email: LoginEmail,
                        StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    }
                });
            };
        };
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        let LoginEmail;
        (Page === 'Manager') ? LoginEmail = "All" : LoginEmail = Email;
        if (startDate && endDate) {
            dispatch({
                type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                payload: {
                    Email: LoginEmail,
                    StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                }
            });
        } else {
            dispatch({
                type: actions.GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE,
                payload: {
                    Email: LoginEmail,
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: moment().format('DD-MM-YYYY')
                }
            });
        }
    }

    useEffect(() => {
        (DailyTicketReport.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [DailyTicketReport]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Daily Reports</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="search Id/Status..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table w-100 thead-primary dataTable no-footer" role="grid" >
                                                        <thead>
                                                            <tr role="row">
                                                                {Page === 'Manager' &&
                                                                    <th scope="col">User ID</th>
                                                                }
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Email</th>
                                                                {Page === 'Manager' &&
                                                                    <th scope="col">Type of User</th>
                                                                }
                                                                <th scope="col">Today&apos;s Tickets</th>
                                                                <th scope="col">Today&apos;s Closed Tickets</th>
                                                                <th scope="col">Total Unclosed Tickets</th>
                                                                <th scope="col">Created On</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((value, i) =>
                                                                    <tr className="odd" key={i} >
                                                                        {Page === 'Manager' &&
                                                                            <th className='id-column' scope="row">{value.InternalUser_ID}</th>
                                                                        }
                                                                        <td>{value.Name}</td>
                                                                        <td>{value.Email}</td>
                                                                        {Page === 'Manager' &&
                                                                            <td>{value.TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk' : value.TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk' : value.TypeOfUser}</td>
                                                                        }
                                                                        <td>{value.Today_Tickets_Count}</td>
                                                                        <td>{value.Today_Closed_Tickets_Count}</td>
                                                                        <td>{value.Total_Unclosed_Tickets_Count}</td>
                                                                        <td className='price-align3-row'>{value.Created_On}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={8} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};