import React from 'react';
import Sidenavigation from '../layouts/Sidenavigation';
import Topnavigation from '../layouts/Topnavigation';
import Content from '../sections/AdminVendorListDetail/Content';
import { Helmet } from "react-helmet";

function AdminVendorListDetail() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme">
            {/* title  */}
            <Helmet>
                <title>Vendor Details</title>
            </Helmet>
            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end  */}

            <main className="body-content">

                {/* Topnavigation start  */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content />
                {/* page content end  */}

            </main>

        </div>
    );
};

export default AdminVendorListDetail;