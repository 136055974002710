import actions from "./actions";
import { takeEvery, put, all, call } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const LoginHistoryAndWorkHoursSagas = function* () {
    yield all([
        yield takeEvery(actions.GET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE, GetVendorLoginHistoryWithDateRange),
        yield takeEvery(actions.GET_VENDORS_WORK_HRS_WITH_DATE_RANGE, GetVendorWorkHrsWithDateRange),
        yield takeEvery(actions.GET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE, GetHelpdeskLoginHistoryWithDateRange),
        yield takeEvery(actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE, GetHelpdeskWorkHoursWithDateRange),
        yield takeEvery(actions.GET_INACTIVE_VENDORS_LIST, GetInactiveVendorsList),
    ])
};

const GetVendorLoginHistoryWithDateRange = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/loginSessionForAll/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorWorkHrsWithDateRange = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/workHrsForAll/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_VENDORS_WORK_HRS_WITH_DATE_RANGE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHelpdeskLoginHistoryWithDateRange = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/loginHistory/internalUsers/list/${payload.StartDate}/${payload.EndDate}/${payload.TypeOfUser}`
            )
        );
        yield put({
            type: actions.SET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHelpdeskWorkHoursWithDateRange = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdesk/workHrsForAll/${payload.StartDate}/${payload.EndDate}/${payload.TypeOfUser}`
            )
        );
        yield put({
            type: actions.SET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetInactiveVendorsList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/inActive/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_INACTIVE_VENDORS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default LoginHistoryAndWorkHoursSagas;