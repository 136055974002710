import React, { useEffect, useState } from "react";
import Breadcrumb from "./Breadcrumb";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Preloader from '../../layouts/Preloader';
import HamperActions from '../../../redux/HampersOrder/actions';
import ProductContains from "../../vendor/section/Vendor-Hampers-Details/ProductContains";
import { float2D } from "../../../utils/Validation";
import { ResponsiveEmbed } from "react-bootstrap";

export default function Details() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.OrderId;

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const { HamperDetails } = useSelector(state => state.HamperOrderReducer);

    //dispatch the get orders list api
    useEffect(() => {
        dispatch({ type: HamperActions.GET_HAMPER_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    //for close the page
    function handleClose() {
        history.push("/Admin-hamper-orders-list");
    };

    useEffect(() => {
        if (HamperDetails?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [HamperDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6 >Hamper Order Details</h6>
                                </div>
                                {HamperDetails?.HamperImage &&
                                    <div className="ms-panel-body order-image">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img className="d-block align-center responsive-image-width" src={HamperDetails?.HamperImage} alt="cake" />
                                        </ResponsiveEmbed>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">HAMPER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(HamperDetails?.ReasonForCancel && HamperDetails?.Status === 'Cancelled') &&
                                                <tr className="text-danger">
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{HamperDetails?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Order ID</th>
                                                <td><b>{HamperDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Hamper ID</th>
                                                <td><b>{HamperDetails?.Hamper_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Hamper Name</th>
                                                <td>{HamperDetails?.HampersName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Title</th>
                                                <td>{HamperDetails?.Title}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{HamperDetails?.Weight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{HamperDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(HamperDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{HamperDetails?.Status}</span></td>
                                            </tr>
                                            {HamperDetails?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{HamperDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{HamperDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Product Count</th>
                                                <td>{HamperDetails?.ItemCount}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td><span>{float2D(HamperDetails?.Price)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Coupon Value</th>
                                                <td><span>{float2D(HamperDetails?.CouponValue || 0)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Charge</th>
                                                <td><span>{float2D(HamperDetails?.DeliveryCharge)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">CGST</th>
                                                <td><span>{float2D(HamperDetails?.Gst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">SGST</th>
                                                <td><span>{float2D(HamperDetails?.Sgst)}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Total</th>
                                                <td><span><b>{float2D(HamperDetails?.Total)}</b></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{HamperDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{HamperDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{HamperDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{HamperDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{HamperDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{HamperDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{HamperDetails?.UserName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Mobile Number</th>
                                                <td>{HamperDetails?.UserPhoneNumber}</td>
                                            </tr>
                                            {HamperDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{HamperDetails?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{HamperDetails?.Vendor_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{HamperDetails?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Main Mobile Number</th>
                                                <td>{HamperDetails?.VendorPhoneNumber1}</td>
                                            </tr>
                                            {HamperDetails?.VendorPhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Vendor Alternative Mobile Number</th>
                                                    <td>{HamperDetails?.VendorPhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Vendor Address</th>
                                                <td><span className="long-line-break">{HamperDetails?.VendorAddress}</span></td>
                                            </tr>
                                            {HamperDetails?.GoogleLocation &&
                                                <tr>
                                                    <th scope="row">Google Location</th>
                                                    <td>
                                                        <b>Latitude</b> : {HamperDetails?.GoogleLocation?.Latitude} <br />
                                                        <b>Longitude</b> : {HamperDetails?.GoogleLocation?.Longitude}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CONTAINS</h4>
                                    <hr />
                                    <ProductContains ArrayList={HamperDetails?.Product_Contains} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">HAMPER DESCRIPTION</h4>
                                    <hr />
                                    <p>{HamperDetails?.Description}</p>
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};