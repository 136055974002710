import React, { useEffect, useState } from 'react';

export default function FlavourList({ FlavoursList }) {

    const[list, setList] = useState([]);

    useEffect(()=>{
        if (FlavoursList === undefined) {
            setList([]);
        } else {
            setList(FlavoursList);
        };
    },[FlavoursList]);
    return (
        <div>
            {
                list.map((val, i) =>
                    <span key={i}>{(i ? ', ' : '') + `${val.Name} - Additional Rs.${val.Price}/Kg`}</span>
                )
            }
        </div>
    )
};