import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import AccountsActions from '../Accounts/actions';
import helpdeskActions from '../Helpdesk/actions';
import VendorOrdersListActions from '../VendorOrderList/actions';
import otherProductActions from '../OtherProducts/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const OrderListSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ORDER_LIST, getOrderList),
        yield takeEvery(actions.GET_ORDER_LIST_BY_ABOVE_5KG, getOrderListbyAbove5KG),
        yield takeEvery(actions.ABOVE_5KG_ORDER_ASSIGN, Above5KGOrderAssign),
        yield takeEvery(actions.CHANGE_ORDER_VIEW_RESPONSE, ChangeOrderViewResponse),
        yield takeEvery(actions.CHANGE_ORDER_VIEW_RESPONSE_BY_VENDOR, ChangeOrderViewResponseByVendor),
        yield takeEvery(actions.CHANGE_ORDER_RESPONSE, ChangeOrderResponse),
        yield takeEvery(actions.CANCEL_ORDER, CancelOrder),
        yield takeEvery(actions.GET_DELIVERY_CHARGE, GetDeliveryCharge),
        yield takeEvery(actions.UPDATE_DELIVERY_CHARGE, UpdateDeliveryCharge),
        yield takeEvery(actions.UPDATE_PRODUCT_SHARE_PER, UpdateproductSharePer),
        yield takeEvery(actions.GET_UPDATE_PRODUCT_SHARE_PER_LIST, GetproductSharePer),
        yield takeEvery(actions.GET_ADMIN_STATEMENTOFACCOUNTS, GetAdminStatementOfAccounts),
        yield takeEvery(actions.GET_VENDOR_STATEMENTOFACCOUNTS_LIST, GetVendorStatementOfAccountsList),
        yield takeEvery(actions.GET_VENDOR_STATEMENT_DETAILS, GetVendorStatementDetails),
        yield takeEvery(actions.CREATE_STATEMENT, CreateStatement),
        yield takeEvery(actions.GET_TAX, GetTax),
        yield takeEvery(actions.UPDATE_TAX, UpdateTax),
        yield takeEvery(actions.GET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR, GetVendorsNormalCakeOrdersListByShowToVendor),
        yield takeEvery(actions.CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE, changeOtherProductOrderViewResponse),
        yield takeEvery(actions.CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE_BY_VENDOR, changeOtherProductOrderViewResponseByVendor),
        yield takeEvery(actions.CHANGE_HAMPER_ORDER_VIEW_RESPONSE_BY_VENDOR, changeHamperOrderViewResponseByVendor),
        yield takeEvery(actions.CHANGE_CUSTOMIZEDCAKE_ORDER_VIEW_RESPONSE_BY_VENDOR, changeCustomizedCakeOrderViewResponseByVendor),
    ]);
};

//get orders list
const getOrderList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/list`
            )
        );
        yield put({
            type: actions.SET_ORDER_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get above 5kg orders list
const getOrderListbyAbove5KG = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbyAbove5KG/${payload}`
            )
        );
        yield put({
            type: actions.SET_ORDER_LIST_BY_ABOVE_5KG,
            payload: { OrderListByAbove5kg: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//above 5kg orders assign to vendors
const Above5KGOrderAssign = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/order/assign/${payload.id}`, {
                VendorID: payload.VendorID,
                Vendor_ID: payload.Vendor_ID,
                VendorName: payload.VendorName,
                VendorPhoneNumber1: payload.VendorPhoneNumber1,
                VendorPhoneNumber2: payload.VendorPhoneNumber2,
                VendorAddress: payload.VendorAddress,
                GoogleLocation: payload.GoogleLocation,
                Status: payload.Status,
                Status_Updated_By: payload.Status_Updated_By,
            }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.ABOVE_5KG_ORDER_ASSIGN_STATUS,
            payload: { Above5kgAssignStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data)
    };
};

//change order view response
const ChangeOrderViewResponse = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/order/response/update/${payload.id}/${payload.response}`
            )
        );
        yield put({ type: helpdeskActions.GET_NOT_RESPOND_ORDERS });
        yield put({ type: VendorOrdersListActions.GET_VENDOR_ORDER_DETAILS, payload: payload.id });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const ChangeOrderViewResponseByVendor = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/order/response/update/${payload.id}/${payload.response}`
            )
        );

    } catch (err) {
        console.log(err.response.data)
    };
};

//change order response status automatically
const ChangeOrderResponse = function* () {

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/order/updateresponse`
            )
        );
    } catch (err) {
        console.log(err.response.data);
    };
};

const CancelOrder = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/order/cancel/${payload.id}`, {
                Cancelled_By: 'Vendor',
                Status_Updated_By: payload.VendorID,
                ReasonForCancel: payload.ReasonForCancel
            }
            )
        );
        yield put({ type: actions.CANCEL_ORDER_STATUS, payload: { CancelOrderStatus: result.data } });
        yield put({ type: VendorOrdersListActions.GET_VENDOR_ORDER_DETAILS, payload: payload.id });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.CANCEL_ORDER_STATUS, payload: { CancelOrderStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get Delivery charge
const GetDeliveryCharge = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/deliverycharge/list`
            )
        );
        yield put({
            type: actions.SET_DELIVERY_CHARGE,
            payload: { DeliveryCharge: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateDeliveryCharge = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/deliverycharge`, payload
            )
        );
        yield put({ type: actions.GET_DELIVERY_CHARGE });
        yield put({ type: actions.ORDER_RESPONSE, payload: result.data });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.ORDER_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateproductSharePer = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/ProductSharePercentage`, payload
            )
        );
        yield put({ type: actions.GET_UPDATE_PRODUCT_SHARE_PER_LIST });
        yield put({ type: actions.ORDER_RESPONSE, payload: result.data });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.ORDER_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetproductSharePer = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/ProductSharePercentage/list`
            )
        );
        yield put({
            type: actions.SET_UPDATE_PRODUCT_SHARE_PER_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAdminStatementOfAccounts = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/admin/statementofaccounts/${payload.Month}/${payload.Year}`
            )
        );
        yield put({
            type: actions.SET_ADMIN_STATEMENTOFACCOUNTS,
            payload: { StatementOfAccounts: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor statement of accounts list
const GetVendorStatementOfAccountsList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/statementofaccountslist`
            )
        );
        yield put({
            type: actions.SET_VENDOR_STATEMENTOFACCOUNTS_LIST,
            payload: { VendorStatementList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get vendor statement of accounts list
const GetVendorStatementDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendor/statementofaccountsdetails/${payload.VendorID}/${payload.Month}/${payload.Year}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_STATEMENT_DETAILS,
            payload: { VendorStatementDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const CreateStatement = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/statementofaccounts/new`, payload.data
            )
        );
        yield put({ type: actions.CREATE_STATEMENT_STATUS, payload: { CreateStatementStatus: result.data } });
        if (result.data.statusCode === 200) {
            yield put({
                type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
                payload: { Id: payload.VendorID, Month: payload.Month, Year: payload.Year, TypeOfUser: "Admin" }
            });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.CREATE_STATEMENT_STATUS, payload: { CreateStatementStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetTax = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tax/list`
            )
        );
        yield put({
            type: actions.SET_TAX,
            payload: { Tax: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateTax = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/tax`, payload
            )
        );
        yield put({ type: actions.GET_TAX });
        yield put({ type: actions.ORDER_RESPONSE, payload: result.data });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.ORDER_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorsNormalCakeOrdersListByShowToVendor = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/orders/listByVendorId/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
}

const changeOtherProductOrderViewResponse = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/order/response/update/${payload.id}/${payload.response}`
            )
        );
        yield put({ type: helpdeskActions.GET_NOT_RESPOND_OTHER_PRODUCT_ORDERS });
        yield put({ type: otherProductActions.GET_OTHER_PRODUCT_DETAILS, payload: payload.id });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const changeOtherProductOrderViewResponseByVendor = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/otherproduct/order/response/update/${payload.id}/${payload.response}`
            )
        );

    } catch (err) {
        console.log(err.response.data)
    };
};

const changeHamperOrderViewResponseByVendor = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/hamper/order/response/update/${payload.id}/${payload.response}`
            )
        );

    } catch (err) {
        console.log(err.response.data)
    };
};

const changeCustomizedCakeOrderViewResponseByVendor = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/customize/cake/response/update/${payload.id}/${payload.response}`
            )
        );

    } catch (err) {
        console.log(err.response.data)
    };
};

export default OrderListSaga;