import React from "react";
// import React, { useState } from "react";
// import CircleDotSpinner from "../../../common/CircleDotSpinner";

export default function CustomizedCakeThroughVendor(props) {

    const { TicketDetails, auth, handleHeldpeskVEditPriceForVendorCCPopup, state,
        handleIntimateToCustomerForPaymentPopup, handleAcceptTicket,
        handleIntimateToVendorForOrderPopup, handleShowCloseTicketPopup,
        handleEnterNotesForCCThroughVendorPopup } = props;
    // loader,handleHelpdeskCNotes, handleIntimatePopup

    // const [notes, setNotes] = useState('');
    // const [notesValidate, setNotesValidate] = useState(false);

    // const handleNotes = (e) => {
    //     setNotes(e.target.value);
    //     (e.target.value?.length > 0) && setNotesValidate(false);
    // };

    // const handleUpdateNotes = (e) => {
    //     e.preventDefault();
    //     if (notes.length > 0) {
    //         setNotesValidate(false);
    //         handleHelpdeskCNotes(notes);
    //     } else {
    //         setNotesValidate(true);
    //     };
    // };

    return (
        <>
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                state?.state?.Page === "HelpdeskV" && TicketDetails?.Order_Status === 'Sent' &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV')) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleHeldpeskVEditPriceForVendorCCPopup}
                >
                    Add Tax
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Order_Status === 'Price Approved' &&
                !TicketDetails?.Ticket_Approved_Status) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimateToCustomerForPaymentPopup}
                >
                    Intimate Customer
                </button>
            }
            {(!TicketDetails?.HelpdeskC_Notes && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status && TicketDetails?.Customer_Approved_Status) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterNotesForCCThroughVendorPopup}
                >
                    Enter Notes
                </button>
                // <>
                //     <div>
                //         <hr />
                //         <label htmlFor="validationCustom18"><b>Enter your Notes here</b> <span className='text-danger'>*</span></label>
                //         <div className="input-group">
                //             <textarea
                //                 className={notesValidate ? 'form-control text-capitalize required-field' : 'form-control text-capitalize'}
                //                 placeholder='Type here...'
                //                 onChange={handleNotes}
                //             />
                //         </div>
                //         {loader ? <CircleDotSpinner /> :
                //             <span className='text-success cursor' onClick={handleUpdateNotes}><u>Click here to submit your notes</u></span>
                //         }
                //     </div><br />
                // </>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.HelpdeskC_Notes &&
                !TicketDetails?.HelpdeskV_Remarks) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleIntimateToVendorForOrderPopup}
                >
                    Intimate Vendor
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.HelpdeskV_Remarks &&
                TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {(!TicketDetails?.HelpdeskC_Accepted_By && state?.state?.Page === "HelpdeskC") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {/* {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.Last_Intimate.includes('HelpdeskC') &&
                TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.HelpdeskC_Notes) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskV') && TicketDetails?.Order_Status === 'Price Approved' &&
                !TicketDetails?.HelpdeskC_Notes) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            } */}
        </>
    )
};