import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom/esm/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/Customize-Cake/actions';
import UserActions from '../../../../redux/users/actions';
import CakeArrayActions from '../../../../redux/CakeArray/actions';
import CommonActions from '../../../../redux/Common/actions';
import ImageSlider from '../../../sections/Customize-Cake-Details/ImageSlider';
import FlavoursList from './CakeArray/FlavourList';
import ShapesList from './CakeArray/ShapeList';
import WeightsList from './CakeArray/WeightList';
import Preloader from '../../../layouts/Preloader';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Breadcrumb from './Breadcrumb';
import { DiscountValidation, float2D, NumberValidation } from '../../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;
    const Page = state?.state?.page;

    const [loader, setLoader] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [showInvoicePoup, setShowInvoicePoup] = useState(false);
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(true);
    const [show4, setShow4] = useState(true);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [cakePrice, setCakePrice] = useState('');
    const [message, setMessage] = useState('');
    const [cakeDiscount, setCakeDiscount] = useState('');

    let [disabledButton, setDisabledButton] = useState([]);
    let [disabledButton2, setDisabledButton2] = useState();
    let [disabledButton4, setDisabledButton4] = useState();
    let [cakeFlavourList, setCakeFlavourList] = useState([]);
    let [cakeShapeList, setCakeShapeList] = useState();
    let [cakeWeightList, setCakeWeightList] = useState();

    //get flavour, shape, weight, article list
    const { FlavourList, ShapeList, WeightList } = useSelector(state => state.CakeArrayReducer);

    //get customized cake invoice sent status
    const { CustomizeCakePriceInvoiceStatus, CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    const { Invoice_Calculation } = useSelector(state => state.commonReducer);

    const User_ID = CustomizedCakeDeatils?.User_ID;
    const FlavourState = CustomizedCakeDeatils?.Flavour;
    const ShapeState = CustomizedCakeDeatils?.Shape;
    const Weighttate = CustomizedCakeDeatils?.Weight;

    useEffect(() => {
        dispatch({ type: actions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: CakeArrayActions.GET_FLAVOUR_LIST });
        dispatch({ type: CakeArrayActions.GET_SHAPE_LIST });
        dispatch({ type: CakeArrayActions.GET_WEIGHT_LIST });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch({ type: UserActions.GET_USER_DETAILS, payload: User_ID });
        // eslint-disable-next-line
    }, [User_ID]);

    useEffect(() => {
        //flavour list
        if (FlavourList) {
            const ind1 = [];
            // eslint-disable-next-line
            FlavourList.map((val, index) => {
                for (let i = 0; i < FlavourState?.length; i++) {
                    if (val.Name.includes(FlavourState[i]?.Name)) {
                        ind1.push(index);
                    }
                }
            })
            setDisabledButton(ind1);
        };
        //shape list
        if (ShapeList) {
            var ind2;
            // eslint-disable-next-line
            ShapeList.map((val, index) => {
                if (val.Name.toLowerCase() === ShapeState?.toLowerCase()) {
                    return ind2 = index;
                }
            })
            setDisabledButton2(ind2);
        };
        //weight list
        if (WeightList) {
            var ind4;
            // eslint-disable-next-line
            WeightList.map((val, index) => {
                if (val.Weight.toLowerCase() === Weighttate?.toLowerCase()) {
                    ind4 = index;
                }
            })
            setDisabledButton4(ind4);
        };
    }, [FlavourList, ShapeList, WeightList, FlavourState, ShapeState, Weighttate]);

    //for add flavour index value in single array
    const handleSelectButton = (i, Name) => {
        setCakeFlavourList(cakeFlavourList = [...cakeFlavourList, { Name: Name, Price: "0" }]);
        if (disabledButton.length === 0) {
            setDisabledButton(disabledButton = [i]);
        } else {
            setDisabledButton(disabledButton = [...disabledButton, i]);
        }
    };

    //for remove flavour index value in single array
    const handleRemoveButton = (i) => {
        //for remove the index value in array
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);

        //for remove the name and price object in array
        const array2 = [...cakeFlavourList];
        if (index !== -1) {
            array2.splice(index, 1);
        }
        setCakeFlavourList(array2);
    };

    //for add shape index value in single array
    const handleSelectButton2 = (value, i) => {
        setCakeShapeList(value.Name);
        setDisabledButton2(i);
    };

    //for remove shape index value in single array
    const handleRemoveButton2 = (i) => {
        setDisabledButton2();
        setCakeShapeList();
    };

    //for add weight index value in single array
    const handleSelectButton4 = (value, i) => {
        setCakeWeightList(value.Weight);
        setDisabledButton4(i);
    };

    //for remove weight index value in single array
    const handleRemoveButton4 = (i) => {
        setDisabledButton4();
        setCakeWeightList();
    };

    //close the popup
    const handleClose = () => {
        setShowModel(false);
        setShowInvoicePoup(false);
    };

    //open confirm order popup
    const handleConfirmPopoup = async (e) => {
        e.preventDefault();
        setMessage('');
        let formData = {
            Type: "Kg",
            Price: cakePrice,
            ItemCount: "1",
            Flavour: cakeFlavourList,
            Shape: { Name: cakeShapeList, Price: "0" },
            Weight: cakeWeightList,
            Discount: cakeDiscount,
            DeliveryCharge: "0",
            CalculationFor: "Order",
            Tax: "0"
        };
        dispatch({ type: CommonActions.GET_INVOICE_CALCULATION, payload: { type: "CCO", data: formData } })

        if (cakeFlavourList.length === 0 || !cakeShapeList || !cakeWeightList || !cakePrice || !cakeDiscount) {
            setShowModel(false);
            setMessage('*required');
        } else {
            setShowModel(true);
            setMessage('');
        }
    };

    const handleShowInvoicePopup = (e) => {
        e.preventDefault();
        setShowModel(false);
        setShowInvoicePoup(true);
    };

    //confirm order invoice
    const handleSendInvoice = (e) => {
        e.preventDefault();
        const formData = {
            EggOrEggless: CustomizedCakeDeatils?.EggOrEggless,
            Flavour: cakeFlavourList,
            Shape: cakeShapeList,
            Weight: cakeWeightList,
            DeliveryAddress: CustomizedCakeDeatils?.DeliveryAddress,
            DeliveryDate: CustomizedCakeDeatils?.DeliveryDate,
            DeliverySession: CustomizedCakeDeatils?.DeliverySession,
            DeliveryInformation: CustomizedCakeDeatils?.DeliveryInformation,
            VendorID: CustomizedCakeDeatils?.VendorID,
            Vendor_ID: CustomizedCakeDeatils?.Vendor_ID,
            VendorName: CustomizedCakeDeatils?.VendorName,
            VendorPhoneNumber1: CustomizedCakeDeatils?.VendorPhoneNumber1,
            VendorPhoneNumber2: CustomizedCakeDeatils?.VendorPhoneNumber2,
            VendorAddress: CustomizedCakeDeatils?.VendorAddress,
            UserID: CustomizedCakeDeatils?.UserID,
            User_ID: CustomizedCakeDeatils?.User_ID,
            UserName: CustomizedCakeDeatils?.UserName,
            UserPhoneNumber: CustomizedCakeDeatils?.UserPhoneNumber,
            Status: "Sent",
            Notification: "unseen",
            Price: cakePrice,
            Discount: Invoice_Calculation?.DiscountPrice?.toString(),
            Discount_Percentage: parseFloat(cakeDiscount),
            ExtraCharges: Invoice_Calculation?.ExtraCharges?.toString(),
            Total: Invoice_Calculation?.Total?.toString(),
            Invoice_Sent_By: CustomizedCakeDeatils?.VendorID
        };
        setLoader(true);
        dispatch({ type: actions.CUSTOMIZE_CAKE_PRICE_INVOICE, payload: { id: CustomizedCakeDeatils?._id, data: formData } });
    };

    const OrderId = CustomizedCakeDeatils?._id;
    //validate status
    useEffect(() => {
        if (CustomizeCakePriceInvoiceStatus?.statusCode === 200 || CustomizeCakePriceInvoiceStatus?.statusCode === 400) {
            setLoader(false);
            setShowInvoicePoup(false);
            history.push('/Vendor-customize-cake-details', { page: 'customizecake', id: OrderId });
        };
        //eslint-disable-next-line
    }, [CustomizeCakePriceInvoiceStatus, OrderId]);

    //loading and validate the list is empty or not
    useEffect(() => {
        (CustomizedCakeDeatils.length === 0 || FlavourList.length === 0 || ShapeList.length === 0 ||
            WeightList.length === 0) ?
            setFetchSuccess(false) : setFetchSuccess(true);
        if (FlavourList?.message === 'No Records Found') { setShow1(false) };
        if (ShapeList?.message === 'No Records Found') { setShow2(false) };
        if (WeightList?.message === 'No Records Found') { setShow4(false) };
        setCakeFlavourList(CustomizedCakeDeatils?.Flavour);
        setCakeShapeList(CustomizedCakeDeatils?.Shape);
        setCakeWeightList(CustomizedCakeDeatils?.Weight);
    }, [FlavourList, ShapeList, WeightList, CustomizedCakeDeatils]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Id={CustomizedCakeDeatils?._id} page={Page} />
                        </div>
                        <div className="col-md-12">
                            {CustomizedCakeDeatils?.Images === undefined || CustomizedCakeDeatils?.Images?.length === 0 ?
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Customised Cake&apos;s Invoice</h6>
                                    </div>
                                </div> :
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Customised Cake&apos;s Invoice</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <ImageSlider image={CustomizedCakeDeatils?.Images} />
                                    </div>
                                </div>
                            }
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <form className="needs-validation clearfix" noValidate>
                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Cake ID <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled placeholder="Cake Name" defaultValue={CustomizedCakeDeatils?.Id} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Egg Or Eggless <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled id="validationCustom24" defaultValue={CustomizedCakeDeatils?.EggOrEggless} placeholder="Type Of Cake" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Above 5 KG <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled id="validationCustom24" defaultValue={CustomizedCakeDeatils?.Above5KG === 'n' ? 'No' : 'Yes'} placeholder="Type Of Cake" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Ordered Date <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" disabled id="validationCustom24" defaultValue={CustomizedCakeDeatils?.Created_On} placeholder="Type Of Cake" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <div className='form-row m-3' >
                                            <div className="col-xl-4 col-md-6 col-sm-12 mb-3">
                                                <FlavoursList className="option-control" option={FlavourList} show={show1}
                                                    handleSelectButton={handleSelectButton} disabledButton={disabledButton}
                                                    handleRemoveButton={handleRemoveButton} cakeFlavourList={cakeFlavourList}
                                                // handleInputValue={handleInputValue} flavoursPrice={flavoursPrice} 
                                                />
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-12 mb-3">
                                                <ShapesList className="option-control" option={ShapeList} show={show2}
                                                    handleSelectButton={handleSelectButton2} disabledButton={disabledButton2}
                                                    handleRemoveButton={handleRemoveButton2} cakeShapeList={cakeShapeList}
                                                />
                                            </div>
                                            <div className="col-xl-4 col-md-6 col-sm-12 mb-3">
                                                <WeightsList className="option-control" option={WeightList} show={show4}
                                                    handleSelectButton={handleSelectButton4} disabledButton={disabledButton4}
                                                    handleRemoveButton={handleRemoveButton4} cakeWeightList={cakeWeightList}
                                                    OrderedWeight={CustomizedCakeDeatils?.Weight}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <form className="needs-validation clearfix" noValidate>
                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Price per Kg <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <button className="RupeeSign" disabled><i className="fas fa-rupee-sign"></i></button>
                                                        <input type="text" className="form-control" placeholder="Price" onChange={(e) => setCakePrice(e.target.value)} onWheel={() => document.activeElement.blur()} min={0} maxLength={10} onInput={NumberValidation} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Discount (%)<span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" id="validationCustom24" placeholder="Discount" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={DiscountValidation} onChange={(e) => setCakeDiscount(e.target.value)} />
                                                        <button className="RupeeSign" disabled><b>%</b></button>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4 mb-3">
                                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Message On The Cake</label>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control text-capitalize" id="validationCustom24" placeholder="Message On The Cake" defaultValue={MessageOnTheCake} onChange={(e) => setMessageOnTheCake(e.target.value)} />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <p className='text-danger'>{message}</p>
                                            {CustomizedCakeDeatils?.Status === 'Sent' ?
                                                <button className="btn btn-secondary" disabled>Invoice Sent</button> :
                                                <button className="btn btn-primary" onClick={handleConfirmPopoup}>Confirm Invoice</button>
                                            }
                                            <button className="btn btn-secondary ml-3" onClick={() => history.push('/Vendor-customize-cake-details', { page: 'customizecake', id: CustomizedCakeDeatils?._id })}>Close</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-min" show={showModel} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Alert</h1>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Price per Kg</th>
                                        {cakePrice ?
                                            <td>₹ {float2D(cakePrice)}</td> :
                                            <td>0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{cakeWeightList}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        {Invoice_Calculation?.Total ?
                                            <td><b>₹ {float2D(parseFloat(Invoice_Calculation?.Total) + parseFloat(Invoice_Calculation?.DiscountPrice || 0))}</b></td> :
                                            <td>0</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                            {/* <p className='text-danger text-center mt-4'>Total = Price Per Kg * Weight</p> */}
                            {/* <h6 className='mt-4'>Is this Correct?</h6> */}
                            {loader ?
                                <div className="login-spinner2">
                                    <CircleDotSpinner />
                                </div> :
                                <div className="text-center mb-2">
                                    <button
                                        className="btn btn-secondary mr-3"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={handleShowInvoicePopup}
                                    >
                                        Proceed
                                    </button>
                                </div>
                            }
                        </Modal.Body>
                    </Modal>
                    {/* confirm invoice popup  */}
                    <Modal className="modal-min" show={showInvoicePoup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Confirm Invoice</h1>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Price per Kg</th>
                                        {cakePrice ?
                                            <td>{float2D(cakePrice)}</td> :
                                            <td>0</td>
                                        }
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{cakeWeightList}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td><span disabled className='mr-2 text-muted'><small>{cakeDiscount}%</small></span> {float2D(Invoice_Calculation?.DiscountPrice)}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        {Invoice_Calculation?.Total ?
                                            <td><b>{float2D(parseFloat(Invoice_Calculation?.Total))}</b></td> :
                                            <td>0</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                            {loader ?
                                <div className="login-spinner2">
                                    <CircleDotSpinner />
                                </div> :
                                <div className='text-center'>
                                    <button
                                        className="btn btn-primary mr-3"
                                        onClick={handleSendInvoice}
                                    >
                                        Send Invoice
                                    </button>
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}