import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, NavLink } from 'react-bootstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Scrollbar from 'react-perfect-scrollbar';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import MenuLink from '../../common/MenuLink';
import actions from '../../../redux/Auth/actions';
import VendorActions from '../../../redux/VendorCakeList/actions';
import actions3 from '../../../redux/ForgotPassword/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import { Modal } from 'react-bootstrap';
import CakeActions from '../../../redux/cake/actions';
import OtherActions from '../../../redux/OtherProducts/actions';
import HelpdeskActions from '../../../redux/Helpdesk/actions';
import commonAction from '../../../redux/Common/actions';
import { FaRegUser, FaKey, FaPowerOff, FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';
import { IoNotifications } from 'react-icons/io5';
// FaRegBell

export default function Topnavigation() {

    const dispatch = useDispatch();
    const history = useHistory();

    const pathName = useHistory().location.pathname;

    const PasswordR = useRef();
    const ConfirmPasswordR = useRef();

    const [show, setShow] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    let [chatNotification, setChatNotification] = useState([]);
    let [popupValue, setPopupValue] = useState({
        Id: '',
        Id_For_Display: '',
        FieldName: ''
    });

    //get logged in users details
    const { token } = useSelector(state => state.authReducer);

    //get change password status
    const { ChangePasswordStatus } = useSelector(state => state.forgotpasswordReducer);

    const { VendorNotification } = useSelector(state => state.VendorCakeListReducer);

    const { CakeDetails } = useSelector(state => state.CakesReducer);

    const { OtherProductDetails } = useSelector(state => state.OtherProductReducer);

    const { ChatNotificationsList } = useSelector(state => state.HelpdeskReducer);

    const addsidenavigation = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    };

    //logout
    const logout = () => {
        dispatch({ type: actions.UPDATE_AUTH_DETAILS, payload: { isAuthenticated: false } });
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();
    };

    //dispatch the vendor orders count api
    useEffect(() => {
        // dispatch({ type: actions2.GET_VENDORORDER_STATUS_COUNT, payload: auth.token.result._id });
        dispatch({ type: VendorActions.GET_VENDOR_NOTIFICATION, payload: token?.result?._id });
        dispatch({ type: HelpdeskActions.GET_CHAT_NOTIFICATIONS_LIST, payload: token?.result?.Email });
        //eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (ChatNotificationsList?.length === 0) {
            setChatNotification([]);
        } else {
            if (ChatNotificationsList?.message === 'No Records Found') {
                setChatNotification([]);
            } else {
                setChatNotification(ChatNotificationsList);
            }
        };
    }, [ChatNotificationsList]);

    //open change password popup
    const handleChangePassword = (e) => {
        e.preventDefault();
        setShow(true);
    };

    //close change password popup
    const handleClose = () => {
        setShow(false);
        setShowInfo(false);
    };

    //change password functionality
    const handleChange = (e) => {
        e.preventDefault();
        setLoader(true);
        setMessage('');
        if (PasswordR.current.value && ConfirmPasswordR.current.value) {
            if (PasswordR.current.value === ConfirmPasswordR.current.value) {
                if (token !== undefined) {
                    dispatch({
                        type: actions3.CHANGE_PASSWORD,
                        payload: { id: token?.result?._id, Password: PasswordR.current.value }
                    });
                    // setTimeout(() => { dispatch({ type: actions3.CHANGE_PASSWORD_STATUS, payload: { ChangePasswordStatus: [] } }); }, 5000);
                }
            } else {
                setMessage("Password does not match");
                setLoader(false);
            }
        } else {
            setMessage('*required');
            setLoader(false);
        }
    };

    const handleClickNotification = (value) => {
        dispatch({ type: VendorActions.REMOVE_NOTIFICATION_BY_ID, payload: { id: value._id, VendorID: value.VendorID } });
        if (value.OrderID) {
            history.push('/Vendor-OrderDetails', { page: 'orderlist', OrderId: value.OrderID });
        } else if (value.CustomizedCakeID) {
            history.push('/Vendor-customize-cake-details', { page: 'customizecake', id: value.CustomizedCakeID });
        } else if (value.CakeID && value.NotificationType !== "Information from Admin") {
            history.push('/Vendor-cake-details', { cakeId: value.CakeID });
        } else if ((value.HamperID && value.NotificationType === 'Your Hamper is Approved')) {
            history.push('/vendor-hampers-details', { id: value.HamperID });
        } else if ((value.HamperID && value.NotificationType === 'You Got a New Hamper Order') ||
            (value.HamperID && value.NotificationType === 'Your Hamper Order is Cancelled')) {
            history.push('/vendor-hamper-order-details', { OrderId: value.HamperID, page: 'list' });
        } else if ((value.Other_ProductID && value.NotificationType === 'You Got a New Product Order') ||
            (value.Other_ProductID && value.NotificationType === 'Your Product Order is Cancelled')) {
            history.push('/vendor-product-order-details', { Id: value.Other_ProductID, page: 'orderlist' });
        } else if ((value.Other_ProductID && value.NotificationType === 'Your Product is Approved') ||
            (value.Other_ProductID && value.NotificationType === 'Your Updated Product is Approved')) {
            history.push('/vendor-product-details', { Id: value.Other_ProductID });
        } else if (value.CakeID && value.NotificationType === 'Information from Admin') {
            setShowInfo(true);
            setPopupValue({ Id: value.CakeID, Id_For_Display: value.Cake_ID, FieldName: 'Cake' });
            dispatch({ type: CakeActions.GET_SINGLE_CAKEDETAILS, payload: value.CakeID });
        } else if (value.Other_ProductID && value.NotificationType === 'Information from Admin') {
            setShowInfo(true);
            setPopupValue({ Id: value.Other_ProductID, Id_For_Display: value.Other_Product_ID, FieldName: 'OtherProduct' });
            dispatch({ type: OtherActions.GET_OTHER_PRODUCT_DETAILS, payload: value.Other_ProductID });
        } else {
            return null;
        }
    };

    const handleGoToDetails = (e) => {
        setShowInfo(false);
        if (popupValue?.FieldName) {
            if (popupValue?.FieldName === 'Cake') {
                history.push('/Vendor-cake-details', { cakeId: popupValue?.Id });
            } else if (popupValue?.FieldName === 'OtherProduct') {
                history.push('/vendor-product-details', { Id: popupValue?.Id });
            }
        }
    }

    const handleMarkAllAssRead = (e) => {
        e.preventDefault();
        dispatch({ type: VendorActions.REMOVE_NOTIFICATION_BY_VENDORID, payload: token?.result?._id });
    };

    const handleReloadNotification = (e) => {
        e.preventDefault();
        dispatch({ type: VendorActions.GET_VENDOR_NOTIFICATION, payload: token?.result?._id });
    };

    const handleReloadChatNotification = (e) => {
        e.preventDefault();
        dispatch({ type: HelpdeskActions.GET_CHAT_NOTIFICATIONS_LIST, payload: token?.result?.Email });
    };

    const handleClickChatNotification = (value) => {
        dispatch({
            type: HelpdeskActions.UPDATE_CHATWITH_MEMBER,
            payload: { TypeOfUser: "Vendor", ChatWith: value.Send_By_Id, Sender: value?.Receiver_Email, Consersation_Id: value?.Consersation_Id }
        });
        let setCurrentConversation = {
            Created_By: value?.Send_By_Id, Created_On: value?.Sent_On,
            Members: [value?.Receiver_Email, value?.Send_By_Id], _id: value?.Consersation_Id
        }
        dispatch({ type: commonAction.SET_CURRENT_CONVERSATION, payload: setCurrentConversation });
        dispatch({ type: HelpdeskActions.REMOVE_CHAT_NOTIFICATION_BY_ID, payload: { Consersation_Id: value?.Consersation_Id, Receiver_Email: value?.Receiver_Email } });
        if (pathName !== '/helpdeskV-Chat') {
            history.push('/helpdeskV-Chat');
        }
    };

    //validate change password status 
    useEffect(() => {
        if (ChangePasswordStatus?.statusCode === 400) {
            setMessage(ChangePasswordStatus?.message);
            setLoader(false);
        } else if (ChangePasswordStatus?.statusCode === 200) {
            setLoader(false);
            setShow(false);
        };
        // eslint-disable-next-line
    }, [ChangePasswordStatus]);

    return (
        <>
            <nav className="navbar ms-navbar tabledata-background">
                <div className="ms-aside-toggler ms-toggler pl-0" onClick={addsidenavigation}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                <div className="logo-sn logo-sm ms-d-block-sm">
                    <MenuLink className="pl-0 ml-0 text-center navbar-brand mr-0" to="/"><img src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" /></MenuLink>
                </div>
                <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
                    <li className="ms-nav-item dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} className='top-nav-link'>
                                <BsChatDotsFill className="top-nav-chat-icons" onClick={handleReloadChatNotification} />
                                {chatNotification?.length === 0 ?
                                    <></> :
                                    <span className='Chat-Notification'>
                                        <span className="badge badge-success style-badge chat-badge">.</span>
                                    </span>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notificationDropdown">
                                <div className="dropdown-menu-header">
                                    <h6 className="text-center mt-3 mb-3"><span className="text-disabled">Chat Notifications</span></h6>
                                </div>
                                <div className="dropdown-divider" />
                                <Scrollbar className="ms-scrollable ms-dropdown-list mb-3">
                                    {chatNotification?.length === 0 ?
                                        <Link to="#">
                                            <p className="text-center mt-4"><b>No Messages </b></p>
                                        </Link> :
                                        <>
                                            {chatNotification?.map((val, i) =>
                                                <div key={i}>
                                                    <div className="media cursor" onClick={() => handleClickChatNotification(val)}>
                                                        {/* <img className="mr-3" src='https://res.cloudinary.com/mindmadetech/image/upload/v1677319826/pngtree-vector-chat-icon-png-image_323830_zmn5rb.jpg' alt="notification" /> */}
                                                        <img className="mr-3" src='https://sugitechnologies.com/images/AppImages/pngtree-vector-chat-icon-png-image_323830_zmn5rb.jpg' alt="notification" />
                                                        <div className="media-body">
                                                            <p className="mt-0 mb-1 font-weight-bold">
                                                                {/* {val.Sent_By} */}
                                                                {val.SenderName ? val.SenderName : val.Sent_By}
                                                            </p>
                                                            <span className='notification-time'>You have {val.Count} unseen messages</span>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )}
                                        </>
                                    }
                                </Scrollbar>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="ms-nav-item dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} className='top-nav-link'>
                                <IoNotifications className="top-nav-icons" onClick={handleReloadNotification} />
                                {VendorNotification?.message === "No Records Found" ?
                                    <></> :
                                    <span className='Notification'>
                                        <span className="badge badge-success style-badge">.</span>
                                    </span>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notificationDropdown">
                                <div className="dropdown-menu-header">
                                    <h6 className="text-center mt-3 mb-3"><span className="text-disabled">Notifications</span></h6>
                                </div>
                                <div className="dropdown-divider" />
                                <Scrollbar className="ms-scrollable ms-dropdown-list mb-3">
                                    {VendorNotification?.message === "No Records Found" ?
                                        <Link to="#">
                                            <p className="text-center mt-4"><b>No Notification </b></p>
                                        </Link> :
                                        <>
                                            {VendorNotification?.map((val, i) =>
                                                <div key={i}>
                                                    <div className="media cursor" onClick={() => handleClickNotification(val)}>
                                                        <img className="mr-3" src={val.Image ? val.Image : 'https://sugitechnologies.com/images/AppImages/107778272-cake-vector-icon-isolated-on-transparent-background-cake-logo-concept_njecku.webp'} alt="notification" />
                                                        <div className="media-body">
                                                            <p className="mt-0 mb-1 font-weight-bold">
                                                                {val.For_Display}
                                                            </p>
                                                            <span className='notification-time'>{val.Status_Updated_On}</span>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )}
                                        </>
                                    }
                                </Scrollbar>
                                {VendorNotification?.message === "No Records Found" ? <></> :
                                    <p className='mark-as-read' onClick={handleMarkAllAssRead}>Mark All As Read</p>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="ms-nav-item ms-nav-user dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                                {(token && token?.result?.ProfileImage) ?
                                    <img className="ms-user-img ms-img-round mh-100 w-100 rounded-circle" src={token?.result?.ProfileImage} alt="people" /> :
                                    <div className='top-letter-border'>
                                        <p className="top-deopdown-letter">{(token?.result?.VendorName?.charAt(0))?.toLowerCase()}</p>
                                    </div>
                                }
                            </Dropdown.Toggle>
                            {token !== undefined &&
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown z-100" aria-labelledby="userDropdown">
                                    <div className="dropdown-menu-header">
                                        <img className="ms-img-round profile-image" src={token === undefined || token?.result?.ProfileImage === undefined ? "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" : token?.result?.ProfileImage} alt="people" />
                                        <h6 className="text-center mt-3"><span className="text-disabled ">{token?.result?.VendorName}</span></h6>
                                        <p className="text-center"><span className="text-disabled ">{token?.result?.Email}</span></p>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="ms-dropdown-list">
                                        <Link className="media fs-14 p-2" to="/vendor-profile">
                                            <span><FaRegUser className="sidenavigationIcon" /> <span className='pl-3'>Profile</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={handleChangePassword} to="/">
                                            <span><FaKey className="sidenavigationIcon" /> <span className='pl-3'>Change Password</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={logout} to="/">
                                            <span><FaPowerOff className="sidenavigationIcon" /> <span className='pl-3'>Logout</span></span>
                                        </Link>
                                    </div>
                                </Dropdown.Menu>
                            }
                        </Dropdown>
                    </li>
                </ul>
                <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                {/* change password popup  */}
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <h1 className="text-center pb-2">Change Password</h1>
                        <div>
                            <label><b>New Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText1 === true ? "text" : "password"} ref={PasswordR} />
                                <button className='eye-button' onClick={() => setShowText1(!showText1)}>
                                    {!showText1 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <label><b>Confirm Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText2 === true ? "text" : "password"} ref={ConfirmPasswordR} />
                                <button className='eye-button' onClick={() => setShowText2(!showText2)}>
                                    {!showText2 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <p className="text-danger">{message}</p>
                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <div className="text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary shadow-none mr-3"
                                        onClick={handleChange}
                                    >
                                        Change
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary shadow-none"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                
                <Modal className="modal-min" show={showInfo} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="tabledata-background">
                        <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <h1 className="text-center mb-4">Information From Admin</h1>
                        <div>
                            {(popupValue?.FieldName && popupValue?.FieldName === 'Cake') ?
                                <>
                                    {CakeDetails?.SendInformation &&
                                        <div className="col-xl-12 col-md-12">
                                            <div className="ms-panel p-3 notification-info">
                                                <span className='font-weight-bold mr-1'>Cake Id: </span><span>{popupValue?.Id_For_Display}</span>
                                                <div className='pt-2 pb-2'>
                                                    <span className='font-weight-bold mr-1'>Information: </span><span>{CakeDetails?.SendInformation[CakeDetails?.SendInformation?.length - 1]?.Information}</span>
                                                </div>
                                                <span className='font-weight-bold mr-1 mt-2'>Sent On: </span><span> {CakeDetails?.SendInformation[CakeDetails?.SendInformation?.length - 1]?.Created_On}</span>
                                            </div>
                                        </div>
                                    }
                                </> : (popupValue?.FieldName && popupValue?.FieldName === "OtherProduct") ?
                                    <>
                                        {OtherProductDetails?.SendInformation &&
                                            <div className="col-xl-12 col-md-12">
                                                <div className="ms-panel p-3 notification-info">
                                                    <span className='font-weight-bold mr-1'>Product Id: </span><span>{popupValue?.Id_For_Display}</span>
                                                    <div className='pt-2 pb-2'>
                                                        <span className='font-weight-bold mr-1'>Information: </span><span>{OtherProductDetails?.SendInformation[OtherProductDetails?.SendInformation?.length - 1]?.Information}</span>
                                                    </div>
                                                    <span className='font-weight-bold mr-1 mt-2'>Sent On: </span><span> {OtherProductDetails?.SendInformation[OtherProductDetails?.SendInformation?.length - 1]?.Created_On}</span>
                                                </div>
                                            </div>
                                        }
                                    </> : <></>
                            }
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary mr-3"
                                    onClick={handleGoToDetails}
                                >
                                    Go To Details Page
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </nav>
        </>
    );
}
