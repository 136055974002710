import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AccountsActions from '../../../../redux/Accounts/actions';
import Preloader from '../../../layouts/Preloader';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { float2D } from '../../../../utils/Validation';

export default function SalesReportByMonth() {

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState();
    const [date, setDate] = useState("");
    const [exportStatement, setExportStatement] = useState([]);

    const { SalesReportByMonth } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        setDate(moment().format('YYYY-MM'));
        dispatch({
            type: AccountsActions.GET_SALES_REPORT_BY_MONTH,
            payload: { Month: moment().format('MM'), Year: moment().format('YYYY') }
        });
        // eslint-disable-next-line
    }, []);

    //validate the list is empty or not
    useEffect(() => {
        if (SalesReportByMonth.length === 0) {
            setFilteredData();
        } else {
            if (SalesReportByMonth?.message === 'No Records Found') {
                setFilteredData();
            } else {
                setFilteredData(SalesReportByMonth?.MonthData);
            };
        };
    }, [SalesReportByMonth]);

    const handleExport = () => {
        const Statement = [
            [
                "Invoice Amount",
                "Received Amount",
                "Coupon Amount",
                "Delivery Charges",
                "Tax",
                "Excess",
                "Short",
                "Refund",
                "Payable Tax",
                "Cakey Share",
            ],
            [
                filteredData?.SumOfInvoiceAmount,
                filteredData?.SumOfReceivedAmount,
                filteredData?.SumOfCouponValue,
                filteredData?.SumOfDeliveryCharges,
                filteredData?.SumOfTax,
                filteredData?.SumOfExcess,
                filteredData?.SumOfShort,
                filteredData?.SumOfRefund,
                filteredData?.SumOfPayableTax,
                filteredData?.SumOfCakeyShare,
            ]
        ]
        Statement.reduce((prev, curr) => [prev, curr]);
        const data = Statement;
        setExportStatement(data);
    };

    const handleTableSearch = (e) => {
        if (e.target.value) {
            setDate(e.target.value);
            dispatch({
                type: AccountsActions.GET_SALES_REPORT_BY_MONTH,
                payload: { Month: moment(e.target.value, 'YYYY-MM').format('MM'), Year: moment(e.target.value, 'YYYY-MM').format('YYYY') }
            });
        }
    };

    //loading
    useEffect(() => {
        if (SalesReportByMonth?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
        // eslint-disable-next-line
    }, [SalesReportByMonth]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="row">
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className='header-break-line'> <hr /> </div>
                            <div className="ms-panel-body tabledata-background">
                                <div className="table-responsive Scrollbar-Style">
                                    <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                {(!filteredData) ?
                                                    <button className="btn btn-success add-button rounded sales-report-export"
                                                        //  disabled={(SalesReportByDate?.message === 'No Records Found' || selectedDate === '') ? true : false}
                                                        disabled
                                                        type="submit">Export</button>
                                                    :
                                                    <button className="btn btn-success add-button rounded sales-report-export" type="submit">
                                                        <CSVLink
                                                            data={exportStatement}
                                                            filename={`Sales Report for ${moment(date, 'YYYY-MM').format('MM-YYYY')}.csv`}
                                                            className="text-white "
                                                            target="_blank"
                                                            onClick={handleExport}
                                                        >
                                                            EXPORT
                                                        </CSVLink>
                                                    </button>
                                                }
                                                <div className="float-right dataTables_length" id="data-table-5_length">
                                                    <input
                                                        type='month'
                                                        max={moment().format('YYYY-MM')}
                                                        className="form-control form-control-sm"
                                                        value={date || ""}
                                                        aria-controls="data-table-4"
                                                        onChange={handleTableSearch}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                    <thead>
                                                        <tr role="row">
                                                            <th scope="col">Invoice Amount</th>
                                                            <th scope="col">Received Amount</th>
                                                            <th scope="col">Coupon Amount</th>
                                                            <th scope="col">Delivery Charges</th>
                                                            <th scope="col">Tax</th>
                                                            <th scope="col">Excess</th>
                                                            <th scope="col">Short</th>
                                                            <th scope="col">Refund</th>
                                                            <th scope="col">Payable Tax</th>
                                                            <th scope="col">Cakey Share</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filteredData ?
                                                            <tr className="odd">
                                                                <td>{float2D(filteredData?.SumOfInvoiceAmount || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfReceivedAmount || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfCouponValue || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfDeliveryCharges || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfTax || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfExcess || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfShort || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfRefund || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfPayableTax || 0)}</td>
                                                                <td>{float2D(filteredData?.SumOfCakeyShare || 0)}</td>
                                                            </tr> :
                                                            <tr>
                                                                <td colSpan={8} className='font-weight-bold'>No Records Found</td>
                                                            </tr>
                                                        }
                                                        {/* {filteredData?.length > 0 ?
                                                            <>
                                                                {filteredData.map((order) =>
                                                                    <tr className="odd">
                                                                        <td>{order.Date}</td>
                                                                        <td>{order.Order_ID}</td>
                                                                        <td>{order.Type}</td>
                                                                        <td>{float2D(order.Total)}</td>
                                                                        <td>{float2D(order.CouponValue || 0.00)}</td>
                                                                        <td>{float2D(order.CakeyShare)}</td>
                                                                        <td>{float2D(order.DeliveryCharge)}</td>
                                                                        <td>{float2D(order.FinalTotal)}</td>
                                                                    </tr>
                                                                )}
                                                            </> :
                                                            <tr>
                                                                <td className='font-weight-bold' colSpan={8}>No Records Found</td>
                                                            </tr>
                                                        } */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};