import actions from "./actions";

const initialState = {
  vendorsList: [],
  addVendorStatus: '',
  vendorsDetails: [],
  RecentOrders: [],
  StatusCount: [],
  UpdateVendorProfileStatus: [],
  RegisterVendorStatus: [],
  ApproveVendorStatus: [],
  NewCustomizeCake: [],
  LoginSession: [],
  VendorDetails: [],
  VendorDetails2: [],
  VendorResponse: [],
  VendorDashboardCount: [],
};

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ALL_VENDORS:
      return {
        ...state,
        vendorsList: action.payload
      }
    case actions.ADD_VENDORS_STATUS:
      return {
        ...state,
        addVendorStatus: action.payload.addVendorStatus
      }
    case actions.SET_SINGLE_VENDORS:
      return {
        ...state,
        vendorsDetails: action.payload.vendorsDetails
      }
    case actions.SET_VENDOR_RECENT_ORDER:
      return {
        ...state,
        RecentOrders: action.payload.RecentOrders
      }
    case actions.SET_VENDORORDER_STATUS_COUNT:
      return {
        ...state,
        StatusCount: action.payload.StatusCount
      }
    case actions.SET_UPDATE_VENDORPROFILE_STATUS:
      return {
        ...state,
        UpdateVendorProfileStatus: action.payload.UpdateVendorProfileStatus
      }
    case actions.REGISTER_VENDOR_STATUS:
      return {
        ...state,
        RegisterVendorStatus: action.payload.RegisterVendorStatus
      }
    case actions.APPROVE_VENDOR_STATUS:
      return {
        ...state,
        ApproveVendorStatus: action.payload.ApproveVendorStatus
      }
    case actions.SET_NEW_CUSTOMIZE_CAKE_LIST_BY_VENDORID:
      return {
        ...state,
        NewCustomizeCake: action.payload.NewCustomizeCake
      }
    case actions.SET_LOGIN_SESSION:
      return {
        ...state,
        LoginSession: action.payload.LoginSession
      }
    case actions.SET_VENDOR_DETAILS_BY_ID:
      return {
        ...state,
        VendorDetails: action.payload
      }
    case actions.SET_VENDOR_DETAILS2:
      return {
        ...state,
        VendorDetails2: action.payload
      }
    case actions.VENDOR_RESPONSE:
      return {
        ...state,
        VendorResponse: action.payload
      }
    case actions.SET_VENDOR_DASHBOARD_COUNT:
      return {
        ...state,
        VendorDashboardCount: action.payload
      }
    default:
      return state;
  }
};

export default VendorReducer;