import React, { useState, useEffect } from 'react';
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Topper/actions';
import CommonActions from '../../../../redux/Common/actions';
import EditImageUrl from './EditImageUrl'
import ProductContains from './ProductContains';
import CakeImageSlider from '../Vendor-Cake-Details/CakeImageSlider';
import Preloader from '../../../layouts/Preloader';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { float2D, NumberValidation } from '../../../../utils/Validation';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    HampersName: yup.string().required('required'),
    Price: yup.string().required('required'),
    Description: yup.string().required("required"),
    Title: yup.string().required('required'),
    EggOrEggless: yup.string().required('required'),
    Occasion: yup.string().required('required'),
    StartDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    EndDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    DeliveryStartDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    DeliveryEndDate: yup.date().required('required').min(new Date(), 'Please choose future date'),
    Weight: yup.string().required('required'),
    WeightUnit: yup.string().required('required'),
});

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.id;

    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    let [sliderImages, setSliderImages] = useState([]);
    let [Message, setMessage] = useState('');
    let [HamperImage, setHamperImage] = useState();
    let [HamperImageUrl, setHamperImageUrl] = useState();
    let [AdditionalHamperImages, setAdditionalHamperImages] = useState([]);
    let [AdditionalHamperImagesUrl, setAdditionalHamperImagesUrl] = useState([]);
    let [AdditionalOldHamperImages, setAdditionalOldHamperImages] = useState([]);
    let [productContains, setProductContains] = useState([]);
    let [productContainsList, setProductContainsList] = useState([]);
    let [BookingStart, setBookingStart] = useState();
    let [DeliveryStart, setDeliveryStart] = useState();

    const { token } = useSelector(state => state.authReducer);

    const { HamperDetails, UpdateHamperStatus } = useSelector(state => state.TopperReducer);

    const AdditionalHamperImage = HamperDetails?.AdditionalHamperImage;
    const HamperImages = HamperDetails?.HamperImage;
    const Product_Contains = HamperDetails?.Product_Contains;

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        dispatch({ type: actions.GET_HAMPERS_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        if (HamperImages) {
            if (AdditionalHamperImage?.length > 0 || AdditionalHamperImage !== undefined) {
                setSliderImages([...AdditionalHamperImage, HamperImages]);
            } else {
                setSliderImages([HamperImages]);
            };
        }
    }, [HamperImages, AdditionalHamperImage]);

    useEffect(() => {
        setHamperImageUrl(HamperImages);
        setProductContainsList(Product_Contains);
    }, [HamperImages, Product_Contains]);

    const handleSelectImage = (e) => {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setHamperImage(HamperImage = result?.FinalImages[0]);
                if (HamperImage) {
                    setHamperImageUrl(URL.createObjectURL(HamperImage));
                };
            }
        }
    };

    const handleAddSpecialityCake = (e) => {
        e.preventDefault();
        setProductContains('');
        setProductContainsList([...productContainsList, productContains]);
    };

    const RemoveAddedSpecialityCakeName = (e, index) => {
        e.preventDefault();
        var splicedList = [...productContainsList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setProductContainsList(splicedList);
    };

    // const handleAddCakeType = (e) => {
    //     e.preventDefault();
    //     if (cakeTypeNameList.length === 0) {
    //         setCakeTypeNameList([e.target.value]);
    //     } else {
    //         if (!cakeTypeNameList.includes(e.target.value)) {
    //             setCakeTypeNameList([...cakeTypeNameList, e.target.value]);
    //         };
    //     };
    //     reset({'Occasion':''})
    // };

    // const RemoveAddedCakeTypeName = (e, index) => {
    //     e.preventDefault();
    //     var splicedList = [...cakeTypeNameList];
    //     if (index !== -1) {
    //         splicedList.splice(index, 1);
    //     };
    //     setCakeTypeNameList(splicedList);
    // };

    const handleAdditionalHamperImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = AdditionalHamperImages.find(val => val.name === files[i].name &&
                            val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        }
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = AdditionalHamperImages.find(val => val.name === files[i].name &&
                        val.size === files[i].size && val.type === files[i].type && val.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };
            }
            // var selected = e.target.files;
            if (selectedImages.length === 1) {
                setAdditionalHamperImages(AdditionalHamperImages = AdditionalHamperImages.concat(selectedImages[0]));
            } else {
                for (let i = 0; i < selectedImages.length; i++) {
                    setAdditionalHamperImages(AdditionalHamperImages = AdditionalHamperImages.concat(selectedImages[i]));
                };
            }
            //Display the images
            var imageArray = AdditionalHamperImages.map(file => {
                return URL.createObjectURL(file);
            });
            setAdditionalHamperImagesUrl(imageArray);
        }
    };

    //remove selected image - additional cake images
    function handleRemoveAdditionalCakeImages(e, index) {
        e.preventDefault();
        //for display
        var spliceImage = [...AdditionalHamperImagesUrl];
        if (index !== -1) {
            spliceImage.splice(index, 1);
        };
        setAdditionalHamperImagesUrl(AdditionalHamperImagesUrl = spliceImage);
        //for store in database
        var SelectedFile = [...AdditionalHamperImages];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setAdditionalHamperImages(AdditionalHamperImages = SelectedFile);
    };
    //remove old additional images
    const handleRemoveOldAdditionalImages = (e) => {
        e.preventDefault();
        setAdditionalOldHamperImages(AdditionalOldHamperImages.filter((val) => {
            if (val !== e.target.value) {
                return val;
            } else {
                return null;
            }
        }));
    };

    useEffect(() => {
        if (AdditionalHamperImage?.length === 0 || AdditionalHamperImage === undefined) {
            setAdditionalOldHamperImages([]);
        } else {
            setAdditionalOldHamperImages(AdditionalHamperImage);
        };
    }, [AdditionalHamperImage]);

    const onSubmit = (data) => {
        setLoader(true);
        if (token !== undefined) {
            if (productContainsList.length === 0) {
                setMessage("Contents Field is required");
                setLoader(false);
            } else {
                setMessage('')
                const formData = new FormData();
                formData.append('VendorID', token?.result?._id);
                formData.append('Vendor_ID', token?.result?.Id);
                formData.append('VendorName', token?.result?.VendorName);
                formData.append('VendorPhoneNumber1', token?.result?.PhoneNumber1);
                token?.result?.PhoneNumber2 && formData.append('VendorPhoneNumber2', token?.result?.PhoneNumber2);
                formData.append('VendorAddress', token?.result?.Address);
                formData.append('GoogleLocation', JSON.stringify(token?.result?.GoogleLocation));
                formData.append('HampersName', data.HampersName);
                formData.append('Title', data.Title);
                formData.append('Occasion', data.Occasion);
                formData.append('EggOrEggless', data.EggOrEggless);
                formData.append('Weight', data.Weight + data.WeightUnit);
                formData.append('StartDate', moment(moment(data.StartDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('EndDate', moment(moment(data.EndDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('DeliveryStartDate', moment(moment(data.DeliveryStartDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('DeliveryEndDate', moment(moment(data.DeliveryEndDate, 'YYYY-MM-DD')).format('DD-MM-YYYY'))
                formData.append('Price', data.Price);
                formData.append('Product_Contains', JSON.stringify(productContainsList));
                formData.append('Description', data.Description);
                if (HamperImage) {
                    formData.append('HamperImage', HamperImage);
                }
                if (AdditionalOldHamperImages.length > 0) {
                    formData.append('OldAdditionalHamperImage', JSON.stringify(AdditionalOldHamperImages));
                }
                if (AdditionalHamperImages.length > 0) {
                    for (let i = 0; i < AdditionalHamperImages.length; i++) {
                        formData.append("AdditionalHamperImage", AdditionalHamperImages[i]);
                    }
                }
                dispatch({ type: actions.UPDATE_HAMPER, payload: { data: formData, id: HamperDetails?._id } });
            }
        }
    };

    const handleDeletePopup = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleDeleteHamper = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({ type: actions.DELETE_HAMPER, payload: { id: HamperDetails?._id, VendorID: HamperDetails?.VendorID, history: history } });
    };

    const handleClose = () => { setShow(false) };

    //validate add cake status
    useEffect(() => {
        if (UpdateHamperStatus?.statusCode === 200) {
            setLoader(false);
            setAdditionalHamperImages([]);
            setAdditionalHamperImagesUrl([]);
            setHamperImage();
            setHamperImageUrl();
            setEdit(false);
        } else if (UpdateHamperStatus?.statusCode === 400) {
            setLoader(false);
            setAdditionalHamperImages([]);
            setAdditionalHamperImagesUrl([]);
            setHamperImage();
            setHamperImageUrl();
        };
        // eslint-disable-next-line
    }, [UpdateHamperStatus]);

    useEffect(() => {
        if (HamperDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            setBookingStart(moment(HamperDetails?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
            setDeliveryStart(moment(HamperDetails?.DeliveryStartDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        };
    }, [HamperDetails]);
    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <>
                    {!edit ?
                        <div className="ms-content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb />
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-header">
                                                <h6 >Hamper Details</h6>
                                            </div>
                                            <div className="ms-panel-body">
                                                <CakeImageSlider image={sliderImages} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h4 className="section-title bold">HAMPER INFO</h4>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Hamper ID</th>
                                                            <td><b>{HamperDetails?.Id}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Hamper Name</th>
                                                            <td><b>{HamperDetails?.HampersName}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Title</th>
                                                            <td>{HamperDetails?.Title}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Occasion</th>
                                                            {/* <td><OccasionList Occasion={HamperDetails?.Occasion} /></td> */}
                                                            <td>{HamperDetails?.Occasion}</td>

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Egg Or Eggless</th>
                                                            {/* <td><OccasionList Occasion={HamperDetails?.Occasion} /></td> */}
                                                            <td>{HamperDetails?.EggOrEggless}</td>

                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Booking Start Date</th>
                                                            <td>{HamperDetails?.StartDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Booking End Date</th>
                                                            <td>{HamperDetails?.EndDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Delivery Start Date</th>
                                                            <td>{HamperDetails?.DeliveryStartDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Delivery End Date</th>
                                                            <td>{HamperDetails?.DeliveryEndDate}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Weight</th>
                                                            <td>{HamperDetails?.Weight}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Price</th>
                                                            <td>Rs.{float2D(HamperDetails?.Price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Created On</th>
                                                            <td>{HamperDetails?.Created_On}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Status</th>
                                                            <td><span className={(HamperDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{HamperDetails?.Status}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body tabledata-background">
                                                <h4 className="section-title bold">CONTAINS</h4>
                                                <hr />
                                                <ProductContains ArrayList={HamperDetails?.Product_Contains} />
                                            </div>
                                        </div>
                                    </div>
                                    {HamperDetails?.ApproveImage &&
                                        <div className="col-xl-12 col-md-12">
                                            <div className="ms-panel ms-panel-fh">
                                                <div className="ms-panel-body tabledata-background">
                                                    <h4 className="section-title bold">HAMPER APPROVED IMAGE</h4>
                                                </div>
                                                <div className="ms-panel-body tabledata-background order-image">
                                                    <ResponsiveEmbed aspectRatio='21by9'>
                                                        <img className="d-block align-center responsive-image-width" src={HamperDetails?.ApproveImage} alt="First slide" />
                                                    </ResponsiveEmbed>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body tabledata-background">
                                                <h4 className="section-title bold">HAMPER DESCRIPTION</h4>
                                                <hr />
                                                <p>{HamperDetails?.Description}</p>
                                                <>
                                                    {(moment(HamperDetails?.StartDate, 'DD-MM-YYYY').diff(moment(), 'days') >= 0 ||
                                                        moment(HamperDetails?.EndDate, 'DD-MM-YYYY').diff(moment(), 'days') < 0) &&
                                                        <button
                                                            className="btn btn-success mr-3"
                                                            type="button"
                                                            onClick={() => setEdit(true)}
                                                        >
                                                            Edit
                                                        </button>
                                                    }
                                                    {(moment(HamperDetails?.StartDate, 'DD-MM-YYYY').diff(moment(), 'days') >= 0 ||
                                                        moment(HamperDetails?.EndDate, 'DD-MM-YYYY').diff(moment(), 'days') < 0) &&
                                                        <button
                                                            className="btn btn-primary mr-3"
                                                            type="button"
                                                            onClick={handleDeletePopup}
                                                        >
                                                            Delete
                                                        </button>
                                                    }
                                                </>
                                                <button
                                                    className="btn btn-secondary mr-3 "
                                                    type="button"
                                                    onClick={() => history.push('/vendor-hampers-list')}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                                    centered contentClassName="modal-body-scrollar">
                                    <Modal.Body className="tabledata-background">
                                        <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                                        <div>
                                            <h6 className='text-center mb-4'>Are you sure you want to delete the Hamper ?</h6>
                                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                                <div className="text-center">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={handleDeleteHamper}
                                                    >
                                                        Yes
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-secondary ml-4"
                                                        onClick={handleClose}
                                                    >
                                                        No
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div> :
                        <div className="ms-content-wrapper">
                            <div className="col-md-12">
                                <Breadcrumb />
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Update Hamper Details</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background order-image">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img className="d-block align-center responsive-image-width" src={HamperImageUrl} alt="First slide" />
                                        </ResponsiveEmbed>
                                        <label className="btn btn-primary upload-button mr-4" htmlFor="actual-btn">Upload</label>
                                        <input type="file" accept='image/jpg,image/jpeg,image/png' id="actual-btn" onChange={handleSelectImage} hidden />
                                        <p className='text-success font-weight-bold text-right'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <form className="needs-validation clearfix" noValidate="">
                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Hamper Name <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className={errors?.HampersName ? "form-control required-field" : "form-control"} name='HampersName' {...register('HampersName')} placeholder="Hamper name" defaultValue={HamperDetails?.HampersName} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Title</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className={errors?.Title ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='Title' {...register('Title')} placeholder="Title" defaultValue={HamperDetails?.Title} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3 px-md-2">
                                                    <label htmlFor="validationCustom25"><b>Occasion</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="text" className={errors?.Occasion ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='Occasion' {...register('Occasion')} placeholder="Occasion" defaultValue={HamperDetails?.Occasion} />

                                                        {/* <div className='display-flex w-100'> */}

                                                        {/* <select defaultValue='' className="form-control" placeholder="types of cakes" name='Occasion' {...register('Occasion')} onChange={handleAddCakeType}>
                                                                <option value='' disabled='disabled'>-- Select --</option>
                                                                {CakeArray.CakeType.map((val, i) =>
                                                                    <>{val.Type === 'Occasion Cakes' ?
                                                                        <> {val.SubType.map((s) =>
                                                                            <option value={s}>{s}</option>
                                                                        )} </> : <></>
                                                                    } </>
                                                                )}
                                                            </select> */}
                                                        {/* </div> */}
                                                        {/* {cakeTypeNameList.map((value, index) =>
                                                            <div key={index}>
                                                                <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedCakeTypeName(e, index)}>X</button></div>
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Egg Or Eggless</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <select className={errors?.EggOrEggless ? "form-control required-field" : "form-control"} defaultValue={HamperDetails?.EggOrEggless} name='EggOrEggless' {...register('EggOrEggless')} >
                                                            <option value='' disabled='disabled'>-- Select --</option>
                                                            <option value="Egg">Egg</option>
                                                            <option value="Eggless">Eggless</option>
                                                            {/* <option value="Egg and Eggless">Egg and Eggless</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Booking Start Date</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="date" maxLength={4} max={"9999-12-31"} min={moment().add(1, 'days').format('YYYY-MM-DD').toString()} className={errors?.StartDate ? "form-control required-field" : "form-control"} name='StartDate' {...register('StartDate')} defaultValue={moment(moment(HamperDetails?.StartDate, 'DD-MM-YYYY')).format('YYYY-MM-DD')} onChange={(e) => setBookingStart(e.target.value)} />
                                                        {/* <input type="date" maxLength={4} max={"9999-12-31"} className={errors?.StartDate ? "form-control required-field" : "form-control"} name='StartDate' {...register('StartDate')} defaultValue={BookingStart} onChange={(e)=>setBookingStart(e.target.value)}/> */}
                                                    </div>
                                                    <p className='text-danger'>{errors?.StartDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>

                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Booking End Date</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="date" maxLength={4} max={"9999-12-31"} min={BookingStart} disabled={BookingStart ? false : true} className={errors?.EndDate ? "form-control required-field" : "form-control"} name='EndDate' {...register('EndDate')} defaultValue={moment(moment(HamperDetails?.EndDate, 'DD-MM-YYYY')).format('YYYY-MM-DD')} />
                                                    </div>
                                                    <p className='text-danger'>{errors?.EndDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>

                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b> Delivery Start Date</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="date" maxLength={4} max={"9999-12-31"} min={BookingStart} disabled={BookingStart ? false : true} className={errors?.DeliveryStartDate ? "form-control required-field" : "form-control"}
                                                            name='DeliveryStartDate' {...register('DeliveryStartDate')} onChange={(e) => setDeliveryStart(e.target.value)} defaultValue={moment(moment(HamperDetails?.DeliveryStartDate, 'DD-MM-YYYY')).format('YYYY-MM-DD')} />
                                                    </div>
                                                    <p className='text-danger'>{errors?.DeliveryStartDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Delivery End Date</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="date" maxLength={4} max={"9999-12-31"} min={DeliveryStart} disabled={DeliveryStart ? false : true} className={errors?.DeliveryEndDate ? "form-control required-field" : "form-control"}
                                                            name='DeliveryEndDate' {...register('DeliveryEndDate')} defaultValue={moment(moment(HamperDetails?.DeliveryEndDate, 'DD-MM-YYYY')).format('YYYY-MM-DD')} />
                                                    </div>
                                                    <p className='text-danger'>{errors?.DeliveryEndDate?.message === 'Please choose future date' ? 'Please choose future date' : ''}</p>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18"><b>Weight(All Product Weight Included)</b> <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <div className='display-flex w-100'>
                                                            <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.Weight ? "form-control required-field" : "form-control"} name='Weight' {...register('Weight')} placeholder="Weight" defaultValue={HamperDetails?.Weight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]} />
                                                            <select className={errors?.Weight ? "form-control w-50 required-field" : "form-control w-50"} id="validationCustom22" name='WeightUnit' {...register('WeightUnit')} defaultValue={HamperDetails?.Weight.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1]}>
                                                                <option value="g">grams</option>
                                                                <option value="kg">kg</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Price <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.Price ? "form-control required-field" : "form-control"} name='Price' {...register('Price')} placeholder="Price" defaultValue={HamperDetails?.Price} />
                                                        <button className={errors?.Price ? "RupeeSign required-field" : "RupeeSign"} disabled><i className="fas fa-rupee-sign"></i></button>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Contains <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <div className='display-flex w-100'>
                                                            <input type="text" className="form-control text-capitalize" placeholder="e.g. One Fruit And Nut Dry Cake (300 gm)" name='Product_Contains' {...register('Product_Contains')} value={productContains} onChange={(e) => setProductContains(e.target.value)} />
                                                            <button className='add-button rounded disabled-add' disabled={productContains.length === 0 ? true : false} onClick={handleAddSpecialityCake}>Add</button>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            {productContainsList?.map((value, index) =>
                                                                <div key={index}>
                                                                    <div className='list-map'>{value} <button className='btn-secondary list-map-remove float-right' onClick={(e) => RemoveAddedSpecialityCakeName(e, index)}>X</button></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Description <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <textarea type="text" className={errors?.Description ? "form-control required-field" : "form-control"} name='Description' {...register('Description')} placeholder="e.g. 10" defaultValue={HamperDetails?.Description} />
                                                    </div>
                                                </div>
                                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Additional Cake Images</label>
                                                <div className="col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='files' multiple onChange={handleAdditionalHamperImages} />
                                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Cake Images...</label>
                                                    </div>
                                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                    <div className='display-flex'>
                                                        {(AdditionalOldHamperImages.length > 0 || AdditionalOldHamperImages !== undefined) &&
                                                            <EditImageUrl display='additionalcake' divClassName="display-flex" imagesurl={AdditionalOldHamperImages} deleteFun={(e) => handleRemoveOldAdditionalImages(e)} />
                                                        }
                                                        <div className='display-flex'>
                                                            {AdditionalHamperImagesUrl.map((value, index) =>
                                                                <div className="imgPre" key={value}>
                                                                    <img width='90px' className='update-select-cake' src={value} alt="Cake" />
                                                                    <button className='btn-secondary imgPreCloseBtn' value={value} onClick={(e) => handleRemoveAdditionalCakeImages(e, index)}>X</button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                                <>
                                                    <div className=" mb-1">
                                                        <label className='text-success' htmlFor="validationCustom18"><b><span className='text-success'>Note:</span> </b> If you update this hamper then it will be send to admin for Approval.</label>
                                                    </div>
                                                    <p className='text-danger text-left font-weight-bold'>{Message}</p>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mr-3"
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        Update
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-secondary"
                                                        onClick={() => history.push('/vendor-hampers-list')}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <Modal className="modal-min" show={showPopup} aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body className="text-center tabledata-background">
                                    <button type="button" className="close" onClick={() => setShowPopup(false)}><span aria-hidden="true">×</span></button>
                                    <h1 className="text-center pb-2">Confirmation</h1>
                                    <div className=" mb-1">
                                        <label className='float-left' htmlFor="validationCustom18"><b>If you update this cake then it will be send to admin for Approval. Are you sure you want to update this cake?</b> <span className='text-danger'>*</span></label>
                                    </div>
                                    {loader ?
                                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                                        <>
                                            <p className='text-danger text-left font-weight-bold'>{Message}</p>
                                            <button type="submit" className="btn btn-primary mr-3" onClick={handleSubmit(onSubmit)}>Update</button>
                                            <button type="submit" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Cancel</button>
                                        </>}
                                </Modal.Body>
                            </Modal> */}
                        </div>
                    }
                </>
            }
        </>
    );
}

export default Content;