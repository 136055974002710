import React, { useEffect, useState } from 'react';
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import actions from '../../../redux/users/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import ReactPagination from '../../common/React-Pagination';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [search, setsearch] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    //get users list
    const { registeredUsers } = useSelector((state) => state.registeredUserReducer);

    //dispatch users list api
    useEffect(() => {
        dispatch({ type: actions.GET_ALL_USERS });
        // eslint-disable-next-line 
    }, []);

    //get search input value
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //for validate the list is empty or not
    useEffect(() => {
        if (registeredUsers?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(registeredUsers?.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.PhoneNumber && val.PhoneNumber.toString().toLowerCase().includes(search))) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [registeredUsers, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //for view details page
    const handleView = (user) => {
        history.push('/customer-details', user);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_ALL_USERS });
    };

    //loading
    useEffect(() => {
        if (registeredUsers.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [registeredUsers]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Customers List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-4_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table w-100 thead-primary dataTable no-footer" role="grid" >
                                                        <thead>
                                                            <tr>
                                                                <th>Customer ID</th>
                                                                <th>Mobile Number</th>
                                                                <th>Details</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((user) =>
                                                                    <tr key={user._id}>
                                                                        <th className='id-column' scope="row">{user.Id}</th>
                                                                        <td className='price-align3-row'>{user.PhoneNumber}</td>
                                                                        <td><button type="button" className="btn btn-primary view-button" onClick={() => handleView(user)}>View</button></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={3} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
