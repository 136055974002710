import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb() {

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/Management-Dashboard"><IoMdHome className='home'/> Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Sales Count Chart</li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;