import React from "react";

export default function Miscellaneous(props) {

    const { TicketDetails, auth, handleIntimatePopup, state, handleEnterSolutionPopup,
        handleManagerRemarksPopup, handleShowCloseTicketPopup, handleIntimatetoManagerForMiscellaneous,
        handleMiscellaneousApprovalByManagerPopup, handleHelpdeskCRemarksForMiscellaneousPopup,
        handleIntimateToAccountsForMiscellaneous } = props;

    return (
        <>
            {TicketDetails?.TypeOfMiscellaneous === 'Others' ?
                <>
                    {(state?.state?.Page !== 'HelpdeskC' && !TicketDetails?.Final_Solution) &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleEnterSolutionPopup}
                        >
                            Enter Solution
                        </button>
                    }
                    {(state?.state?.Page === 'Manager' && !TicketDetails?.Final_Solution) &&
                        <button
                            className="btn btn-primary float-start mr-3"
                            type="submit"
                            onClick={handleManagerRemarksPopup}
                        >
                            Remarks
                        </button>
                    }
                    {(state?.state?.Page === 'HelpdeskC' && TicketDetails?.Final_Solution && TicketDetails?.Ticket_Status !== "Closed") &&
                        <button
                            className="btn btn-primary float-start mr-3"
                            type="submit"
                            onClick={handleShowCloseTicketPopup}
                        >
                            Close Ticket
                        </button>
                    }
                    {
                        (TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV')) &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleIntimatePopup}
                        >
                            Intimate
                        </button>
                    }
                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Final_Solution) &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleIntimatePopup}
                        >
                            Intimate
                        </button>
                    }
                </> :
                <>
                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Manager_Approved_Status) &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleIntimatetoManagerForMiscellaneous}
                        >
                            Intimate
                        </button>
                    }
                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && (TicketDetails?.Manager_Approved_Status === 'Not Approved' ||
                            (TicketDetails?.Manager_Approved_Status === 'Approved' && TicketDetails?.Refund_Status !== 'Pending')) &&
                        !TicketDetails?.HelpdeskC_Remarks) &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleHelpdeskCRemarksForMiscellaneousPopup}
                        >
                            Remarks
                        </button>
                    }
                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.HelpdeskC_Remarks &&
                        TicketDetails?.Ticket_Status !== "Closed") &&
                        <button
                            className="btn btn-primary float-start mr-3"
                            type="submit"
                            onClick={handleShowCloseTicketPopup}
                        >
                            Close Ticket
                        </button>
                    }
                    {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Manager_Approved_Status === 'Approved' && TicketDetails?.Refund_Status === 'Pending') &&
                        <button
                            className="btn btn-success float-start mr-3"
                            type="submit"
                            onClick={handleIntimateToAccountsForMiscellaneous}
                        >
                            Intimate to Accounts
                        </button>
                    }
                    {(state?.state?.Page === 'Manager' && !TicketDetails?.Manager_Approved_Status) &&
                        <button
                            className="btn btn-danger float-start mr-3"
                            type="submit"
                            onClick={handleMiscellaneousApprovalByManagerPopup}
                        >
                            Need Approval
                        </button>
                    }
                </>
            }
        </>
    )
};