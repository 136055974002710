import React, { useState, useEffect } from 'react';

export default function WeightList({ WeightLists }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (WeightLists === undefined) {
            setList([]);
        } else {
            setList(WeightLists);
        };
    }, [WeightLists]);

    return (
        <div>
            {
                list.map((val, i) =>
                    <span key={i}>{(i ? ', ' : '') + val}</span>
                )
            }
        </div>
    )
};