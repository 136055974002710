import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../../../redux/VendorCakeList/actions';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import CircleDotSpinner from '../../common/CircleDotSpinner';

export default function Content() {

    const dispatch = useDispatch();

    const { BankName, VendorCakeListResponse } = useSelector(state => state.VendorCakeListReducer);

    const BankNameRef = useRef();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [deleteBankName, setDeleteBankName] = useState('');
    const [Err, setErr] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch({ type: Actions.GET_BANKNAME });
        // eslint-disable-next-line
    }, []);

    const handleDeleteBankNamePopUp = (e, a) => {
        e.preventDefault();
        setDeleteBankName(a);
        setShow2(true);
    };

    const handleAddPopup = (e) => {
        e.preventDefault();
        setShow1(true);
    };

    const handleNewBankName = (e) => {
        e.preventDefault();
        setErr("");
        if (BankNameRef.current.value) {
            setLoader(true);
            dispatch({ type: Actions.CREATE_BANKNAME, payload: BankNameRef.current.value?.toUpperCase() });
        } else {
            setErr("required");
        }
    };

    const handleDeleteBankName = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch({ type: Actions.DELETE_BANKNAME, payload: deleteBankName });
    };

    const handleClose = () => {
        setShow1(false);
        setShow2(false);
        setErr("");
    }

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: Actions.GET_BANKNAME });
    };

    useEffect(() => {
        if (VendorCakeListResponse?.statusCode === 200 || VendorCakeListResponse?.statusCode === 400) {
            setShow1(false);
            setShow2(false);
            setLoader(false);
            setErr("");
        }
    }, [VendorCakeListResponse]);

    //loading
    useEffect(() => {
        if (BankName.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
        // eslint-disable-next-line
    }, [BankName]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className="pb-2">BANK NAME
                                        <span className="pb-2 float-right">
                                            <button
                                                className="btn-sm btn-success p-2 add-button rounded"
                                                // className="btn-sm btn-primary p-2"
                                                type="submit"
                                                onClick={handleAddPopup}
                                            >
                                                Add
                                            </button>
                                        </span>
                                    </h6>
                                    <table className="table ">
                                        <tbody>
                                            {BankName?.message !== 'No Records Found' ?
                                                <>
                                                    {BankName?.map((a) =>
                                                        <tr className="category-row" key={a._id}>
                                                            <th>{a.BankName}</th>
                                                            <button
                                                                className="btn-sm btn-primary category-row-btn remove-button remove-2 cake-remove"
                                                                // className="btn-sm btn-secondary category-row-btn remove-button remove-2"
                                                                onClick={(e) => handleDeleteBankNamePopUp(e, a.BankName)}
                                                            >
                                                                x
                                                            </button>
                                                        </tr>
                                                    )}
                                                </> :
                                                <tr >
                                                    <th className="text-center">No Records Found</th>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal className="modal-min" show={show1} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Add New Bank Name</h1>
                            <div>
                                <label htmlFor="validationCustom23" className='font-weight-bold'>Bank Name<span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        ref={BankNameRef}
                                        placeholder="Bank Name"
                                    />
                                </div>
                                <p className='text-danger'>{Err}</p>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none mr-3"
                                            onClick={handleNewBankName}
                                        >
                                            Add
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary shadow-none"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <div>
                                <h6 className='text-center mb-4'>Are you sure you want to delete the Bank Name "{deleteBankName}" ?</h6>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleDeleteBankName}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            No
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}