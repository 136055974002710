import { takeEvery, put, call, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from "../../utils/constants";
import axios from 'axios';

const TopperSaga = function* () {

    yield all([
        yield takeEvery(actions.ADD_NEW_TOPPER, AddNewTopper),
        yield takeEvery(actions.GET_VENDOR_TOPPERS_LIST, GetVendorToppersList),
        yield takeEvery(actions.UPDATE_TOPPER, UpdateTopper),
        yield takeEvery(actions.GET_ALL_TOPPERS_LIST, GetAllToppersList),
        yield takeEvery(actions.DELETE_TOPPER, DeleteTopper),
        yield takeEvery(actions.CREATE_HAMBPERS, CreateHampers),
        yield takeEvery(actions.GET_VENDOR_HAMPERS_LIST, GetVendorHampersList),
        yield takeEvery(actions.GET_HAMPERS_DETAILS, GetHampersDetails),
        yield takeEvery(actions.UPDATE_HAMPER, UpdateHamper),
        yield takeEvery(actions.DELETE_HAMPER, DeleteHamper),
        yield takeEvery(actions.GET_ADMIN_HAMPERS_LIST, GetAdminHamperList),
        yield takeEvery(actions.APPROVE_HAMPER, ApproveHamper),
        yield takeEvery(actions.GET_TOPPER_DETAILS, GetTopperDetails),
    ])
};

const AddNewTopper = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/toppers/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.ADD_NEW_TOPPER_STATUS,
            payload: { AddNewTopperStatus: result.data }
        });
        yield delay(2000);
        yield put({ type: actions.ADD_NEW_TOPPER_STATUS, payload: { AddNewTopperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateTopper = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/toppers/update/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.UPDATE_TOPPER_STATUS,
            payload: { UpdateTopperStatus: result.data }
        });
        yield put({ type: actions.GET_TOPPER_DETAILS, payload: payload.id });
        yield delay(2000);
        yield put({ type: actions.UPDATE_TOPPER_STATUS, payload: { UpdateTopperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorToppersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/toppers/listbyvendor/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_TOPPERS_LIST,
            payload: { VendorToppersList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetTopperDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/toppers/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_TOPPER_DETAILS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllToppersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/toppers/list`
            )
        );
        yield put({
            type: actions.SET_ALL_TOPPERS_LIST,
            payload: { ToppersList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DeleteTopper = function* (data) {
    const { payload } = data;
    const { history } = payload;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/toppers/delete/${payload.id}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_VENDOR_TOPPERS_LIST, payload: payload.VendorID });
        history.push('/Vendor-toppers-list');
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const CreateHampers = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/hamper/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.CREATE_HAMBPERS_STATUS,
            payload: { CreateHamperStatus: result.data }
        });
        yield delay(2000);
        yield put({ type: actions.CREATE_HAMBPERS_STATUS, payload: { CreateHamperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetVendorHampersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamper/listbyvendor/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_HAMPERS_LIST,
            payload: { VendorHamperList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHampersDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamper/details/${payload}`
            )
        );
        yield put({
            type: actions.SET_HAMPERS_DETAILS,
            payload: { HamperDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateHamper = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/hamper/update/${payload.id}`, payload.data
            )
        );
        yield put({ type: actions.GET_HAMPERS_DETAILS, payload: payload.id })
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.UPDATE_HAMPER_STATUS,
            payload: { UpdateHamperStatus: result.data }
        });
        yield delay(2000);
        yield put({ type: actions.UPDATE_HAMPER_STATUS, payload: { UpdateHamperStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DeleteHamper = function* (data) {
    const { payload } = data;
    const { history } = payload;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/hamper/delete/${payload.id}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }

        yield put({ type: actions.GET_VENDOR_HAMPERS_LIST, payload: payload.VendorID });
        history.push('/vendor-hampers-list');
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAdminHamperList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamper/list`
            )
        );
        yield put({
            type: actions.SET_ADMIN_HAMPERS_LIST,
            payload: { AdminHamperList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const ApproveHamper = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/hamper/approve/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_HAMPERS_DETAILS, payload: payload.id });
        yield put({ type: actions.TOPPER_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.TOPPER_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    };
};

export default TopperSaga;