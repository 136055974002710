const actions = {
    GET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE: 'GET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE',
    SET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE: 'SET_VENDOR_LOGIN_HISTORY_WITH_DATE_RANGE',
    GET_VENDORS_WORK_HRS_WITH_DATE_RANGE: 'GET_VENDORS_WORK_HRS_WITH_DATE_RANGE',
    SET_VENDORS_WORK_HRS_WITH_DATE_RANGE: 'SET_VENDORS_WORK_HRS_WITH_DATE_RANGE',
    GET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE: 'GET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE',
    SET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE: 'SET_HELPDESK_LOGIN_HISTORY_WITH_DATE_RANGE',
    GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE : 'GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE',
    SET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE : 'SET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE',
    GET_INACTIVE_VENDORS_LIST: 'GET_INACTIVE_VENDORS_LIST',
    SET_INACTIVE_VENDORS_LIST: 'SET_INACTIVE_VENDORS_LIST',
};

export default actions;