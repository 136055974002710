
export const CouponGenerator = () => {

    let Generator = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let coupon = '';

    for (var i = 0; i < 6; i++) {
        coupon += Generator[Math.floor(Math.random() * Generator.length)];
    };
    return coupon;
};

