import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AccountsActions from '../../../redux/Accounts/actions';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { float2D } from '../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [date, setDate] = useState("");
    const [exportStatement, setExportStatement] = useState([]);

    const { VendorStatementDetails } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        const Month = moment().format('DD-MM-YYYY');
        setDate(moment().format('YYYY-MM'));
        dispatch({
            type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
            payload: { Id: Id, Month: Month?.slice(3, 5), Year: Month?.slice(6, 10), TypeOfUser: "Admin" }
        });
        // eslint-disable-next-line
    }, [Id]);

    //validate the list is empty or not
    useEffect(() => {
        if (VendorStatementDetails?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(VendorStatementDetails.result);
        };
    }, [VendorStatementDetails]);

    const handleTableSearch = (e) => {
        setDate(moment(e.target.value).format('YYYY-MM'));
        dispatch({
            type: AccountsActions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
            payload: { Id: Id, Month: e.target.value.slice(5, 7), Year: e.target.value.slice(0, 4), TypeOfUser: "Admin" }
        });
    };

    const handleExport = () => {
        const Statement = [
            [
                "Date",
                "Order ID",
                "Description",
                "Product Price",
                "Cakey Share",
                "Delivery Charges",
                "Total Payment",
                "Payment",
                "Pending Payment",
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", "Opening Balance:", float2D(VendorStatementDetails?.OpeningBalance)
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            ...filteredData?.map(details =>

                details.Type === 'Payment' ?
                    [
                        details.Date,
                        "-",
                        details.Type,
                        "-",
                        "-",
                        "-",
                        "-",
                        float2D(details.FinalTotal),
                        "-"
                    ] :
                    [
                        details.Date,
                        details.Order_ID,
                        details.Type,
                        details.Total,
                        details.CakeyShare,
                        details.DeliveryCharge,
                        float2D(details.FinalTotal),
                        "-",
                        float2D(details.FinalTotal)
                    ]
            ),
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            [
                " ", " ", " ", VendorStatementDetails?.TotalCalculations?.Total, VendorStatementDetails?.TotalCalculations?.CakeyShare, VendorStatementDetails?.TotalCalculations?.DeliveryCharge, VendorStatementDetails?.TotalCalculations?.FinalTotal, VendorStatementDetails?.TotalCalculations?.FinalPayment, " "
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", " ", " "
            ],
            [
                " ", " ", " ", " ", " ", " ", " ", "Pending Payment:", float2D(VendorStatementDetails?.ClosingBalance)
            ],
        ]
        Statement.reduce((prev, curr) => [prev, curr]);
        const data = Statement;
        setExportStatement(data);
    };

    //loading
    useEffect(() => {
        (VendorStatementDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [VendorStatementDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Statement Of Accounts({state?.state?.Vendor_ID} - {state?.state?.Name})</h6>
                                    <span className="float-right add-banner-button">
                                        {filteredData?.length === 0 ?
                                            <button className="btn btn-success add-button rounded text-white font-weight-bold" disabled type="submit"> EXPORT</button> :
                                            <button className="btn btn-success add-button rounded" type="submit">
                                                <CSVLink
                                                    data={exportStatement}
                                                    filename={`${state?.state?.Name}'s account statement for ${date.slice(5, 7)} - ${date.slice(0, 4)}.csv`}
                                                    className="text-white font-weight-bold"
                                                    target="_blank"
                                                    onClick={handleExport}
                                                >
                                                    EXPORT
                                                </CSVLink>
                                            </button>
                                        }
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6"></div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Select Month :
                                                            <input
                                                                type='month'
                                                                max={moment().format('YYYY-MM')}
                                                                className="form-control form-control-sm"
                                                                value={date || ""}
                                                                aria-controls="data-table-4"
                                                                onChange={handleTableSearch}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Product Price</th>
                                                                <th scope="col">Cakey Share</th>
                                                                <th scope="col">Delivery Charges</th>
                                                                <th scope="col">Total Payment</th>
                                                                <th scope="col">Payment</th>
                                                                <th scope="col"> Pending Payment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="odd">
                                                                <td colSpan={7}></td>
                                                                <td className='font-weight-bold'>Opening Balance</td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.OpeningBalance || 0)}</td>
                                                            </tr>
                                                            {filteredData?.length > 0 ?
                                                                <>
                                                                    {filteredData.map((order) =>
                                                                        <> {order.Type === 'Payment' ?
                                                                            <tr className="odd" >
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className='font-weight-bold'>{order.Type}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                {/* <td className="vendor-details-alignment">----</td> */}
                                                                                <td className="vendor-details-alignment" >----</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                            </tr> :
                                                                            <tr className="odd">
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td>{order.Order_ID}</td>
                                                                                <td>{order.Type}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.Total)}</td>
                                                                                {/* <td className="vendor-details-alignment">{float2D(order.CouponValue || 0.00)}</td> */}
                                                                                <td className="vendor-details-alignment">{float2D(order.CakeyShare)}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.DeliveryCharge)}</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                                <td className="vendor-details-alignment">----</td>
                                                                                <td className="vendor-details-alignment">{float2D(order.FinalTotal)}</td>
                                                                            </tr>
                                                                        }
                                                                        </>
                                                                    )}
                                                                </> :
                                                                <tr>
                                                                    <td colSpan={9}>----</td>
                                                                </tr>
                                                            }
                                                            <tr className="odd">
                                                                <td colSpan={3}></td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.Total)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.CakeyShare)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.DeliveryCharge)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.FinalTotal)} </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.TotalCalculations?.FinalPayment)} </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className="odd">
                                                                <td colSpan={7}></td>
                                                                <td className='font-weight-bold'>Total Pending Amount </td>
                                                                <td className='font-weight-bold vendor-details-alignment'>{float2D(VendorStatementDetails?.ClosingBalance || 0)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};