import actions from "./actions";

const initState = {
    Vendor_Order_list: [],
    UpdateOrderStatus: [],
    UpdateVendorProfileStatus: [],
    GetVendorOrderDetails: [],
    VendorOrderListByStatus: [],
    LoginSessionOrders: [],
    OrderAcceptStatus: [],
    SalesCountChartReport: [],
    VendorsPincodeList: [],
};

const VendorOrderReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.SET_VENDOR_ORDER_LIST:
            return {
                ...state,
                Vendor_Order_list: action.payload

            }
        case actions.SET_UPDATE_ORDER_STATUS:
            return {
                ...state,
                UpdateOrderStatus: action.payload.UpdateOrderStatus

            }
        case actions.SET_VENDOR_ORDER_DETAILS:
            return {
                ...state,
                GetVendorOrderDetails: action.payload.GetVendorOrderDetails

            }
        case actions.SET_VENDOR_ORDER_LIST_BY_STATUS:
            return {
                ...state,
                VendorOrderListByStatus: action.payload.VendorOrderListByStatus

            }
        case actions.SET_VENDOR_LOGIN_SESSION_ORDERS:
            return {
                ...state,
                LoginSessionOrders: action.payload.LoginSessionOrders

            }
        case actions.ACCEPT_ORDER_STATUS:
            return {
                ...state,
                OrderAcceptStatus: action.payload.OrderAcceptStatus

            }
        case actions.SET_SALES_COUNT_CHART_REPORT:
            return {
                ...state,
                SalesCountChartReport: action.payload

            }
        case actions.SET_VENDORS_PINCODE_LIST:
            return {
                ...state,
                VendorsPincodeList: action.payload

            }
        default:
            return state;
    };
};

export default VendorOrderReducer;