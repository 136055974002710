import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/Accounts/actions';
import ReactPagination from '../../../common/React-Pagination';
import TableSearch from '../../../common/TableSearch';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';

export default function Content() {

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { CancelledOrders } = useSelector(state => state.AccountsReducer);

    // const auth = useSelector((state) => state.authReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_CANCELLED_ORDERS_LIST });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (CancelledOrders.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            //eslint-disable-next-line
            setFilteredData(CancelledOrders.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CakeID && val.CakeID.toLowerCase().includes(search)) ||
                    (val.Other_ProductID && val.Other_ProductID.toLowerCase().includes(search)) ||
                    (val.PaymentStatus && val.PaymentStatus.toLowerCase().includes(search)) ||
                    (val.Cancelled_By && val.Cancelled_By.toLowerCase().includes(search)) ||
                    // (val.Refund_Status && val.Refund_Status.toLowerCase().includes(search)) ||
                    (val.Refund_On && val.Refund_On.toLowerCase().includes(search)) ||
                    (val.Status_Updated_On && val.Status_Updated_On.toLowerCase().includes(search)) ||
                    (val.Refund_Type && val.Refund_Type.toLowerCase().includes(search))
                ) {
                    return val;
                } else if (("pending").includes(search?.toLowerCase())) {
                    if (!val.Refund_Status || val.Refund_Status?.toLowerCase() === 'cash on delivery' || val.Refund_Status?.toLowerCase().includes(search?.toLowerCase())) {
                        return val;
                    }
                } else if (("success").includes(search?.toLowerCase())) {
                    if (val.Refund_Status?.toLowerCase().includes(search?.toLowerCase())) {
                        return val;
                    }
                } else if (("no refund").includes(search?.toLowerCase())) {
                    if (val.Refund_Status?.toLowerCase() === 'cash on delivery') {
                        return val;
                    }
                } else if (("product order").includes(search?.toLowerCase())) {
                    if (val.Other_ProductID) {
                        return val;
                    }
                } else if (("cake order".includes(search?.toLowerCase()))) {
                    if (val.CakeID) {
                        return val;
                    }
                } else {
                    return null;
                }
            }))
        };
    }, [CancelledOrders, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_CANCELLED_ORDERS_LIST });
    };

    useEffect(() => {
        (CancelledOrders.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CancelledOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <h6>Cancelled Orders List</h6>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search Id/Status..." onChange={handleSearchbar} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Type of Order</th>
                                                                <th scope="col">Payment Status</th>
                                                                <th scope="col">Cancelled By</th>
                                                                <th scope="col">Refund  Type</th>
                                                                <th scope="col">Refund Status</th>
                                                                <th scope="col">Refund On/Order Cancelled On</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{val.Id}</th>
                                                                        <td>{val.CakeID ? 'Cake Order' : val.Other_ProductID ? 'Product Order' : 'Hamper Order'}</td>
                                                                        <td>{val.PaymentStatus}</td>
                                                                        <td>{val.Cancelled_By}</td>
                                                                        <td>{(val.Refund_Type && val.PaymentStatus?.toLowerCase() !== 'cash on delivery') ? val.Refund_Type : "-"}</td>
                                                                        <td><span className={(!val.Refund_Status || val.Refund_Status === 'Pending') ? 'REFUNDPENDING' : ((val.Refund_Status?.toUpperCase())?.replace(/ /g, ''))}>{val.Refund_Status?.toLowerCase() === 'cash on delivery' ? 'No Refund' : (!val.Refund_Status || val.Refund_Status === 'Pending') ? 'Pending' : val.Refund_Status}</span></td>
                                                                        <td className='price-align3-row'>{val.Refund_On ?? val.Status_Updated_On}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};