import React from 'react';
import CommonActions from '../../../../redux/Common/actions';
import { useDispatch } from 'react-redux';
import FileSizeValidation from '../../../common/FileSizeValidation';

function BodyContent3({
    errors, register, handleSelectMainCakeImages, mainCakeImageUrl, handleRemoveMainCakeImages,
    handleAdditionalCakeImages, additionalCakeImagesUrl, handleRemoveAdditionalCakeImages, mainCakeImage
}) {

    const dispatch = useDispatch();

    const handleMainCakeImageValidation = (e) => {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                handleSelectMainCakeImages(result?.FinalImages);
            };
        }
    };

    const handleAdditionalCakeImagesValidation = (e) => {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                (result?.FinalImages?.length > 0) && handleAdditionalCakeImages(result?.FinalImages);
            } else {
                handleAdditionalCakeImages(result?.FinalImages);
            };
        }
    }

    return (
        <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-body tabledata-background">
                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Main Cake Image <span className='text-danger'>*</span></label>
                <div className="col-md-12 mb-3">
                    <div className="custom-file">
                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='MainCakeImage' {...register('MainCakeImage')} onChange={handleMainCakeImageValidation} />
                        <label className={errors?.MainCakeImage && !mainCakeImage ? "custom-file-label required-field" : "custom-file-label"} htmlFor="validatedCustomFile">Upload Cake Images...</label>
                    </div>
                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                    <div className='display-flex'>
                        <div className='display-flex'>
                            {mainCakeImageUrl !== undefined &&
                                <div className="imgPre">
                                    <img width='90px' className='update-select-cake' src={mainCakeImageUrl} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' value={mainCakeImageUrl} onClick={handleRemoveMainCakeImages}>X</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Additional Cake Images</label>
                <div className="col-md-12 mb-3">
                    <div className="custom-file">
                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='files' multiple onChange={handleAdditionalCakeImagesValidation} />
                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Cake Images...</label>
                    </div>
                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                    <div className='display-flex'>
                        <div className='display-flex'>
                            {additionalCakeImagesUrl?.map((value, index) =>
                                <div className="imgPre" key={value}>
                                    <img width='90px' className='update-select-cake' src={value?.Url} alt="Cake" /><button className='btn-secondary imgPreCloseBtn' onClick={(e) => handleRemoveAdditionalCakeImages(e, index, value)}>X</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BodyContent3;