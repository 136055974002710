import React from "react";
import { Modal } from "react-bootstrap";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
// import { PushNotification } from "../../../common/PushNotification";

export default function CloseTicketPopup(props) {

    const { showCloseTicketPopup, handleCloseTicketPopup, handleCloseTicket, loader, } = props;
    // UserDetails, TicketDetails

    const handleUpdate = (e) => {
        // if (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' && UserDetails?.Notification === 'y' &&
        //     UserDetails.Notification_Id && TicketDetails?.Ticket_Approved_Status === 'Approved') {
        //     let pushMessageData = {
        //         type: 'customer',
        //         registration_ids: [UserDetails.Notification_Id],
        //         title: 'Cakey',
        //         body: 'Your order is cancelled as per your request',
        //     }
        //     PushNotification(pushMessageData);
        // }
        handleCloseTicket(e);
    };

    return (
        <div>
            <Modal show={showCloseTicketPopup} onHide={handleCloseTicketPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseTicketPopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Confirmation</h4>
                    <div className="form-row">
                        <div className="col-md-12 mb-3 text-center">
                            <h6>Are you sure you want to close the ticket?</h6>
                        </div>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleUpdate}
                            >
                                Yes
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseTicketPopup}
                            >
                                No
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};