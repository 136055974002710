import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function UserDetailsPopup(props) {

    const {
        handleCloseDetailsPopup,
        showUserDetailsPopup,
    } = props;

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    return (
        <div>
            <Modal show={showUserDetailsPopup} onHide={handleCloseDetailsPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button
                        type="button"
                        className="close change-password-close"
                        onClick={handleCloseDetailsPopup}
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="bold text-center">Customer Details</h4>
                            {UserDetails?.length === 0 ?
                                <div className='h6 text-center mt-4'>Loading...</div> :
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Customer ID</th>
                                            <td><b>{UserDetails?.Id}</b></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Customer Name</th>
                                            <td><span className="long-line-break">{UserDetails?.UserName}</span></td>
                                        </tr>
                                        {UserDetails?.Address &&
                                            <tr>
                                                <th scope="row">Customer Address</th>
                                                <td><span className="long-line-break">{UserDetails?.Address}</span></td>
                                            </tr>
                                        }
                                        <tr>
                                            <th scope="row">Customer Mobile Number</th>
                                            <td>{UserDetails?.PhoneNumber}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
};