const actions = {
  GET_ALL_CAKES: 'GET_ALL_CAKES',
  SET_ALL_CAKES: 'SET_ALL_CAKES',
  GET_ALL_CAKES_BY_STATUS : 'GET_ALL_CAKES_BY_STATUS',
  SET_ALL_CAKES_BY_STATUS : 'SET_ALL_CAKES_BY_STATUS',
  Add_CAKES:'Add_CAKES',
  Add_CAKES_STATUS:'Add_CAKES_STATUS',
  APPROVE_CAKE : 'APPROVE_CAKE',
  APPROVE_CAKE_STATUS : 'APPROVE_CAKE_STATUS',
  UPDATE_CAKES:' UPDATE_CAKES',
  UPDATE_CAKES_STATUS:'UPDATE_CAKES_STATUS',
  GET_SINGLE_CAKEDETAILS:'GET_SINGLE_CAKEDETAILS',
  SET_SINGLE_CAKEDETAILS:'SET_SINGLE_CAKEDETAILS',
  DELETE_CAKE : 'DELETE_CAKE',
  SET_DELETE_CAKE_STATUS : 'SET_DELETE_CAKE_STATUS',
  GET_NEW_AND_UPDATED_CAKES_LIST: 'GET_NEW_AND_UPDATED_CAKES_LIST',
  SET_NEW_AND_UPDATED_CAKES_LIST: 'SET_NEW_AND_UPDATED_CAKES_LIST',
  UPDATE_APPROVED_CAKES:' UPDATE_APPROVED_CAKES',
  UPDATE_APPROVED_CAKES_STATUS:'UPDATE_APPROVED_CAKES_STATUS',
  SEND_INFORMATION: 'SEND_INFORMATION',
  GET_ADMIN_CAKES_LIST: 'GET_ADMIN_CAKES_LIST',
  SET_ADMIN_CAKES_LIST: 'SET_ADMIN_CAKES_LIST',
  CAKE_RESPONSE: 'CAKE_RESPONSE',
};

export default actions;
