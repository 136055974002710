import { takeEvery, call, all, put, delay } from "redux-saga/effects";
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from "../../utils/constants";
import axios from "axios";

const ForgotpasswordSaga = function* () {

  yield all([
    yield takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    yield takeEvery(actions.CHANGE_PASSWORD, ChangePassword),
  ]);
};

//forgot password
const forgotPassword = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/forgotpassword/${payload.Email}`, {
        Password: payload.Password
      }
      )
    );
    if (result.data.statusCode === 200) {
      yield put({
        type: actions.UPDATE_STATUS,
        payload: result.data.message
      });
    } else {
      yield put({
        type: actions.UPDATE_STATUS,
        payload: result.data.message
      });
    }
    yield delay(2000);
    yield put({
      type: actions.UPDATE_STATUS,
      payload: []
    });

  } catch (err) {
    yield put({
      type: actions.UPDATE_STATUS,
      payload: { Status: 'Failed' }
    });
  };
};

//change password
const ChangePassword = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/password/change/${payload.id}`, {
        Password: payload.Password
      }
      )
    );
    if (result.data.statusCode === 200) {
      yield put({
        type: CommonActions.SET_ALERT,
        payload: {
          alert: true,
          message: result.data.message,
          status: 'success',
        }
      });
    } else {
      yield put({
        type: CommonActions.SET_ALERT,
        payload: {
          alert: true,
          message: result.data.message,
          status: 'failed',
        }
      });
    }
    yield put({
      type: actions.CHANGE_PASSWORD_STATUS,
      payload: { ChangePasswordStatus: result.data }
    });
    yield delay(2000);
    yield put({
      type: actions.CHANGE_PASSWORD_STATUS,
      payload: { ChangePasswordStatus: [] }
    });
    yield put({
      type: CommonActions.SET_ALERT,
      payload: {
        alert: false,
        message: null,
        status: null,
      }
    });

  } catch (err) {
    console.log(err.response.data);
  };
};

export default ForgotpasswordSaga;