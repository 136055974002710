import React from "react";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { Modal } from "react-bootstrap";

export default function HelpdeskVVendorCancelIntimateToAccountsPopup(props) {

    const { showVendorCancelIntimateToAccountsPopup, handleCloseVendorCancelIntimateToAccountsPopup, loader,
        handleIntimateToAccountsForVendorCancel } = props;

    // const handleUpdate = (e) => {
    //     e.preventDefault();

    // let bodyContent;
    // (TicketDetails?.Ticket_Approved_Status === 'Approved') ? bodyContent = `Your order(${TicketDetails?.Order_ID}) is cancelled as per your request` :
    //     bodyContent = `Vendor did not accept your cancel request for Order ID ${TicketDetails?.Order_ID}`;
    // if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
    //     let pushMessageData = {
    //         type: 'customer',
    //         registration_ids: [UserDetails.Notification_Id],
    //         title: 'Cakey',
    //         body: bodyContent,
    //     }
    //     PushNotification(pushMessageData);
    // };
    // if (VendorDetails?.Notification_Id && TicketDetails?.Ticket_Approved_Status === 'Approved') {
    //     let pushMessageData = {
    //         type: 'vendor',
    //         registration_ids: [VendorDetails?.Notification_Id],
    //         title: 'Cakey',
    //         body: "Your order is cancelled",
    //     }
    //     PushNotification(pushMessageData);
    // }
    // handleIntimateToAccountsForCustomerCancel(UserDetails?.UserName);
    // };

    return (
        <div>
            <Modal show={showVendorCancelIntimateToAccountsPopup} onHide={handleCloseVendorCancelIntimateToAccountsPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseVendorCancelIntimateToAccountsPopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Confirmation</h4>
                    <div>
                        <h6 className='text-center'>Are you sure you want to intimate to Accounts for refund?</h6>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleIntimateToAccountsForVendorCancel}
                            >
                                Yes
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseVendorCancelIntimateToAccountsPopup}
                            >
                                No
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};