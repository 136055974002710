import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'HelpdeskC' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/Helpdesk-C-Dashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/Helpdesk-C-Orders-List"> Orders Based On Delivery Date</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                </ol> :
                Page === 'Manager' ?
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item">
                            <Link to="/Manager-Dashboard"><IoMdHome className='home' /> Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/Manager-Delivery-Orders-List"> Orders Based On Delivery Date</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                    </ol> :
                    Page === 'Management' ?
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item">
                                <Link to="/Management-Dashboard"><IoMdHome className='home' /> Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/Management-Delivery-Orders-List"> Orders Based On Delivery Date</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                        </ol> :
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item">
                                <Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/helpdeskV-Delivery-Orders-List"> Orders Based On Delivery Date</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                        </ol>
            }
        </nav>
    );
};


