import React from 'react';
import { Helmet } from 'react-helmet'
import Topnavigation from '../Layouts/Topnavigation'
import Sidenavigation from '../Layouts/Sidenavigation'
import Content from '../../CommonFolders/Helpdesk-Work-Hours-Report/Content'

export default function HelpdeskVWorkHours(){
    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Vendor Helpdesk Work Hrs Report</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content Page='Manager' TypeOfUser='Helpdesk V' />
                {/* page content end  */}

            </main>

        </div>
    )
}