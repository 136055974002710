import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { PushNotification } from "../../../common/PushNotification";

export default function EnterNotesForChangeRequest(props) {

    const { handleHelpdeskCNotes, loader, showIntimateToCustomerForChangeRequestPopup,
        handleCloseIntimateToCustomerForChangeRequestPopup, UserDetails } = props;

        const { TicketDetails } = useSelector(state => state.TicketReducer);

    const [notes, setNotes] = useState('');
    const [notesValidate, setNotesValidate] = useState(false);

    const handleNotes = (e) => {
        setNotes(e.target.value);
        (e.target.value?.length > 0) && setNotesValidate(false);
    };

    const handleUpdateNotes = (e) => {
        e.preventDefault();
        if (notes.length > 0) {
            setNotesValidate(false);
            if(TicketDetails?.Ticket_Approved_Status === 'Not Approved'){
                if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                    let pushMessageData = {
                        type: 'customer',
                        registration_ids: [UserDetails.Notification_Id],
                        title: 'Cakey',
                        body: 'We are unable to Process Your Shape/Flavour Change Request, Thank You',
                    }
                    PushNotification(pushMessageData);
                }; 
            };
            handleHelpdeskCNotes(notes);
        } else {
            setNotesValidate(true);
        };
    };

    return (
        <div>
            <Modal show={showIntimateToCustomerForChangeRequestPopup} onHide={handleCloseIntimateToCustomerForChangeRequestPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseIntimateToCustomerForChangeRequestPopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-3">Enter Notes</h4>
                    {/* <label htmlFor="validationCustom18"><b className="text-danger">Enter your Notes here</b> <span className='text-danger'>*</span></label> */}
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <div className="input-group">
                                <textarea
                                    className={notesValidate ? 'form-control text-capitalize required-field' : 'form-control text-capitalize'}
                                    placeholder='Type here...'
                                    onChange={handleNotes}
                                />
                            </div>
                        </div>
                    </div>
                    {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleUpdateNotes}
                            >
                                {TicketDetails?.Ticket_Approved_Status === 'Not Approved' ? 'Intimate To Customer' :
                                    'Submit'}
                                {/* Intimate To Customer */}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseIntimateToCustomerForChangeRequestPopup}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}