import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb({ page }) {

    return (

        <nav aria-label="breadcrumb">
            {page === 'customizecake' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/Vendor-customize-cake-list">Customised Cakes List</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Customised Cake Details</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item"><Link to="/vendordashboard"><IoMdHome className='home'/> Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Customised Cake Details</li>
                </ol>
            }

        </nav>
    );
};

export default Breadcrumb;