const actions = {
    GET_ALL_ORDERS_LIST_BY_TYPE: 'GET_ALL_ORDERS_LIST_BY_TYPE',
    SET_ALL_ORDERS_LIST_BY_TYPE: 'SET_ALL_ORDERS_LIST_BY_TYPE',
    GET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE: 'GET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE',
    SET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE: 'SET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE',
    GET_TICKETS_LIST_BY_TYPE_OF_USER: 'GET_TICKETS_LIST_BY_TYPE_OF_USER',
    SET_TICKETS_LIST_BY_TYPE_OF_USER: 'SET_TICKETS_LIST_BY_TYPE_OF_USER',
    GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER: 'GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER',
    SET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER: 'SET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER',
    GET_TICKET_DETAILS: 'GET_TICKET_DETAILS',
    SET_TICKET_DETAILS: 'SET_TICKET_DETAILS',
    RAISE_NEW_TICKET: 'RAISE_NEW_TICKET',
    TICKET_RESPONSE: 'TICKET_RESPONSE',
    ACCEPT_TICKET: 'ACCEPT_TICKET',
    INTIMATE_TICKET: 'INTIMATE_TICKET',
    GET_DAILY_UNCLOSED_TICKETS_COUNT: 'GET_DAILY_UNCLOSED_TICKETS_COUNT',
    SET_DAILY_UNCLOSED_TICKETS_COUNT: 'SET_DAILY_UNCLOSED_TICKETS_COUNT',
    DAILY_TICKET_REPORTS_SEND_TO_MANAGER: 'DAILY_TICKET_REPORTS_SEND_TO_MANAGER',
    GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE: 'GET_DAILY_TICKET_REPORT_WITH_DATE_RANGE',
    SET_DAILY_TICKET_REPORT_WITH_DATE_RANGE: 'SET_DAILY_TICKET_REPORT_WITH_DATE_RANGE',
    TICKET_APPROVAL_STATUS_UPDATE: 'TICKET_APPROVAL_STATUS_UPDATE',
    GET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER: 'GET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER',
    SET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER: 'SET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER',
    GET_ORDER_DETAILS_BY_TYPEOFORDER: 'GET_ORDER_DETAILS_BY_TYPEOFORDER',
    SET_ORDER_DETAILS_BY_TYPEOFORDER: 'SET_ORDER_DETAILS_BY_TYPEOFORDER',
    HELPDESKC_NOTES: 'HELPDESKC_NOTES',
    REFUND_PAYMENT: 'REFUND_PAYMENT',
    REFUND_PAYMENT_FOR_CUSTOMER_CANCEL: 'REFUND_PAYMENT_FOR_CUSTOMER_CANCEL',
    HELPDESKC_FULLY_CUSTOMIZED_CAKE_ASSIGN_TO_VENDOR: 'HELPDESKC_FULLY_CUSTOMIZED_CAKE_ASSIGN_TO_VENDOR',
    FULLY_CUSTOMIZED_CAKE_PRICE_INVOICE_SEND_BY_HELPDESKV: 'FULLY_CUSTOMIZED_CAKE_PRICE_INVOICE_SEND_BY_HELPDESKV',
    CLOSE_TICKET: 'CLOSE_TICKET',
    SEND_PAYMENT_LINK_FOR_CHANGE_REQUEST: 'SEND_PAYMENT_LINK_FOR_CHANGE_REQUEST',
    ENTER_SOLUTION_FOR_TICKETS: "ENTER_SOLUTION_FOR_TICKETS",
    FULLY_CUSTOMIZED_CAKE_FINAL_PRICE_SEND_BY_MANAGER: 'FULLY_CUSTOMIZED_CAKE_FINAL_PRICE_SEND_BY_MANAGER',
    MANAGER_REMARKS: 'MANAGER_REMARKS',
    UPDATE_CUSTOMER_APPROVAL_BY_HELPDESKC: 'UPDATE_CUSTOMER_APPROVAL_BY_HELPDESKC',
    HELPDESKV_UPDATE_VENDOR_AND_FINAL_COST: 'HELPDESKV_UPDATE_VENDOR_AND_FINAL_COST',
    HELPDESKV_VENDOR_CANCEL_HELPDESKC_NOTES: 'HELPDESKV_VENDOR_CANCEL_HELPDESKC_NOTES',
    HELPDESKV_CUSTOMIZED_CAKE_SELECT_VENDOR: "HELPDESKV_CUSTOMIZED_CAKE_SELECT_VENDOR",
    HELPDESKV_CUSTOMIZED_CAKE_UPDATE_VENDOR_APPROVE_STATUS: 'HELPDESKV_CUSTOMIZED_CAKE_UPDATE_VENDOR_APPROVE_STATUS',
    HELPDESKV_REMARKS: 'HELPDESKV_REMARKS',
    VENDOR_CUSTOMIZED_CAKE_UPDATE_TAX_BY_HELPDESKV: 'VENDOR_CUSTOMIZED_CAKE_UPDATE_TAX_BY_HELPDESKV',
    INTIMATE_TO_CUSTOMER_FOR_PAYMENT: "INTIMATE_TO_CUSTOMER_FOR_PAYMENT",
    INTIMATE_TO_VENDOR_FOR_VENDORCC_TO_ORDER: 'INTIMATE_TO_VENDOR_FOR_VENDORCC_TO_ORDER',
    GET_REJECTED_ORDERS_LIST: "GET_REJECTED_ORDERS_LIST",
    SET_REJECTED_ORDERS_LIST: "SET_REJECTED_ORDERS_LIST",
    UPDATE_HELPDESKV_REMARKS_FOR_VENDOR_CANCEL: 'UPDATE_HELPDESKV_REMARKS_FOR_VENDOR_CANCEL',
    REFUND_FOR_CANCEL_WITHIN_15MINS: 'REFUND_FOR_CANCEL_WITHIN_15MINS',
    INTIMATE_TO_CUSTOMER_FOR_CANCEL: 'INTIMATE_TO_CUSTOMER_FOR_CANCEL',
    GET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER: 'GET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER',
    SET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER: 'SET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER',
    GET_ALL_TICKETS_LIST: 'GET_ALL_TICKETS_LIST',
    SET_ALL_TICKETS_LIST: 'SET_ALL_TICKETS_LIST',
    INTIMATE_TO_VENDOR_FOR_CC_ORDERS: 'INTIMATE_TO_VENDOR_FOR_CC_ORDERS',
    INTIMATE_TO_ACCOUNTS_AND_CUSTOMER_FOR_CUSTOMER_CANCEL: 'INTIMATE_TO_ACCOUNTS_AND_CUSTOMER_FOR_CUSTOMER_CANCEL',
    HELPDESKV_VENDOR_CANCEL_ABORT_REASON: 'HELPDESKV_VENDOR_CANCEL_ABORT_REASON',
    MISCELLANEOUS_APPROVAL_BY_MANAGER: 'MISCELLANEOUS_APPROVAL_BY_MANAGER',
    MISCELLANEOUS_REFUND_BY_ACCOUNTS: 'MISCELLANEOUS_REFUND_BY_ACCOUNTS',
    HELPDESKC_REMARKS_FOR_MISCELLANEOUS: 'HELPDESKC_REMARKS_FOR_MISCELLANEOUS',
};

export default actions;