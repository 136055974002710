import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import FlavourListArray from "../../../sections/ArrayMapping/FlavourList";

export default function EnterInputPopup(props) {

    const { showEnterInputPopup, handleCloseEnterInputPopup, CauseOfTicket, handleUpdateStatus,
        loader, errors, register, handleSubmit, setError, clearErrors, setValue, resetField,
        TicketDetails, handleUpdateChangeRequestStatus, OrderDetailsByTypeOfOrder, Ticket_Response } = props;

    let [showField, setShowField] = useState(false);
    let [approvedStatus, setApprovedStatus] = useState('');
    let [cakeFlavours, setCakeFlavours] = useState([]);
    let [cakeShape, setCakeShape] = useState();
    let [existingFlavoursPrice, setExistingFlavoursPrice] = useState(0);
    let [existingShapePrice, setExistingShapePrice] = useState(0);
    let [flavourTotal, setFlavourTotal] = useState(0);
    let [flavourCostDifference, setFlavourCostDifference] = useState({ Difference: 0, Validation: false });
    let [shapeCostDifference, setShapeCostDifference] = useState(0);
    let [differenceInCost, setDifferenceInCost] = useState(0);
    let [totaDifferenceInCost, setTotalDifferenceInCost] = useState(0);
    let [flavourValidate, setFlavourValidate] = useState(false);
    let [shapeValidate, setShapeValidate] = useState(false);

    const TicketFlavours = TicketDetails?.Flavour;
    const OrderFlavours = OrderDetailsByTypeOfOrder?.Flavour;
    const TicketShape = TicketDetails?.Shape;
    const OrderShape = OrderDetailsByTypeOfOrder?.Shape;
    const OrderWeight = OrderDetailsByTypeOfOrder?.Weight;

    //Change Requests -> assign flavours and shapes to state variable
    useEffect(() => {
        if (CauseOfTicket === 'Change Requests' && showEnterInputPopup && showField) {
            setCakeFlavours([]);
            setCakeShape();
            if (TicketFlavours?.length > 0) {
                let flavourArray = [];
                TicketFlavours.map(val => { return flavourArray.push({ Name: val.Name, Price: "0" }) });
                setCakeFlavours(flavourArray);
                if (OrderFlavours?.length > 0) {
                    if (OrderFlavours?.length === 1) {
                        (OrderFlavours[0]?.Price === "0") ? setExistingFlavoursPrice(0) :
                            setExistingFlavoursPrice(parseFloat(OrderFlavours[0]?.Price));
                        if (parseFloat(OrderFlavours[0]?.Price) === 0) {
                            setFlavourCostDifference({ Difference: 0, Validation: false });
                        } else {
                            setFlavourCostDifference({ Difference: 0, Validation: true });
                        };
                    } else {
                        setExistingFlavoursPrice(OrderFlavours?.reduce((a, b) => parseFloat(a.Price) + parseFloat(b.Price)))
                        if (OrderFlavours?.reduce((a, b) => parseFloat(a.Price) + parseFloat(b.Price)) === 0) {
                            setFlavourCostDifference({ Difference: 0, Validation: false });
                        } else {
                            setFlavourCostDifference({ Difference: 0, Validation: true });
                        };
                    };
                } else {
                    setExistingFlavoursPrice(0);
                };
            } else {
                setCakeFlavours([]);
            }
            if (TicketShape) {
                setCakeShape(TicketShape);
                if (OrderShape?.Price) {
                    (parseFloat(OrderShape?.Price) === 0) ? setExistingShapePrice(0) :
                        setExistingShapePrice(parseFloat(OrderShape?.Price));
                } else {
                    setExistingShapePrice(0);
                };
            } else {
                setCakeShape();
            };
        };
    }, [CauseOfTicket, TicketFlavours, OrderFlavours, TicketShape, OrderShape, showEnterInputPopup, showField]);

    //validation for flavour and shape
    useEffect(() => {
        let costDifference1 = 0, costDifference2 = 0, flavourCostDiff = 0, shapeCostDiff = 0;
        if (cakeFlavours && cakeFlavours?.length > 0) {
            const validation = cakeFlavours?.every(item => item.Price);
            if (validation) {
                setFlavourValidate(false);
                if (cakeFlavours.length === 1) {
                    costDifference1 = parseFloat(cakeFlavours[0]?.Price);
                } else {
                    costDifference1 = cakeFlavours.reduce((a, b) => parseFloat(a.Price) + parseFloat(b.Price));
                }
                setFlavourTotal(costDifference1);
            } else {
                setFlavourValidate(true);
                setFlavourTotal(0);
            }
        };
        if (existingFlavoursPrice <= costDifference1) {
            flavourCostDiff = costDifference1 - existingFlavoursPrice;
            setFlavourCostDifference({ Difference: flavourCostDiff, Validation: false });
        } else {
            setFlavourCostDifference({ Difference: 0, Validation: true });
        };

        if (cakeShape && cakeShape?.Price) {
            costDifference2 = parseFloat(cakeShape?.Price);
            setShapeValidate(false);
        }
        // else {
        //     setShapeValidate(true);
        // };
        if (existingShapePrice <= costDifference2) {
            shapeCostDiff = costDifference2 - existingShapePrice;
            setShapeCostDifference(shapeCostDiff)
        } else {
            setShapeCostDifference(0);
        };
        let FinalDiff = flavourCostDiff + shapeCostDiff;
        setDifferenceInCost(FinalDiff);
        let weight;
        (OrderWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[1] === 'g') ? weight = [parseFloat(OrderWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi)[0]) / 1000] :
            weight = OrderWeight?.match(/([0-9.]+)(?![0-9.])|([a-z]+)(?![a-z])/gi);
        let TotalDiff = parseFloat(FinalDiff) * parseFloat(weight);
        setTotalDifferenceInCost(TotalDiff);
        setValue('Difference_In_Price', TotalDiff);
        //eslint-disable-next-line
    }, [cakeFlavours, cakeShape, existingFlavoursPrice, existingShapePrice, OrderWeight]);

    //onChange function for ticket status
    const handleTicketStatus = (e) => {
        setApprovedStatus(e.target.value);
        setValue('Ticket_Approved_Status', e.target.value);
        clearErrors('Ticket_Approved_Status');
        if (e.target.value === 'Approved') {
            setShowField(true);
        } else {
            setShowField(false);
            clearErrors('Cancellation_Charges');
            clearErrors('Difference_In_Price');
            resetField('Cancellation_Charges');
            resetField('Difference_In_Price');
            setFlavourValidate(false);
            setShapeValidate(false);
            setDifferenceInCost(0);
            setTotalDifferenceInCost(0);
            setFlavourTotal(0);
            setFlavourCostDifference({ Difference: 0, Validation: false });
            setShapeCostDifference(0);
            setCakeFlavours([]);
            setCakeShape();
        };
    };

    //onChange function for Cancellation Changes
    const handleChancellationCharges = (e) => {
        if (showField) {
            if (e.target.value && e.target.value >= 0) {
                setValue('Cancellation_Charges', e.target.value);
                clearErrors('Cancellation_Charges')
            } else {
                setError('Cancellation_Charges')
            };
        };
    };

    //add flavours cost
    const handleFlavourPrice = (e, i) => {
        if (e.target.value) {
            let Array = [...cakeFlavours];
            Array[i].Price = e.target.value;
            setCakeFlavours(cakeFlavours = Array);
        } else {
            let Array = [...cakeFlavours];
            Array[i].Price = 0;
            setCakeFlavours(cakeFlavours = Array);
        }
    };

    //add shape cost
    const handleShapePrice = (e) => {
        // if(e.target.value) {}
        if (!e.target.value) {
            setShapeValidate(true)
        };
        // let Object = { ...cakeShape, Price: e.target.value };
        setCakeShape({ ...cakeShape, Price: e.target.value });
    };

    //onChange function for Differnce in Price
    const handleDifferenceInPrice = (e) => {
        if (e.target.value) {
            clearErrors('Difference_In_Price');
            setValue('Difference_In_Price', e.target.value);
        } else {
            setError('Difference_In_Price');
        };
    };

    const handleClose = (e) => {
        resetField('Difference_In_Price');
        resetField('Input');
        resetField('Ticket_Approved_Status');
        setShowField(false);
        setFlavourValidate(false);
        setShapeValidate(false);
        setDifferenceInCost(0);
        setTotalDifferenceInCost(0);
        setFlavourTotal(0);
        setCakeFlavours([]);
        setCakeShape();
        setExistingFlavoursPrice(0);
        setExistingShapePrice(0);
        setFlavourCostDifference({ Difference: 0, Validation: false });
        setShapeCostDifference(0);
        setApprovedStatus('');
        handleCloseEnterInputPopup(e);
    };

    //status update function
    const handleUpdate = (data) => {
        if (data.Ticket_Approved_Status === 'Approved') {
            if (cakeFlavours?.length === 0 && !cakeShape) {
                (data.Difference_In_Price) ? handleUpdateChangeRequestStatus(data) : setError('Difference_In_Price');
            } else {
                if (cakeFlavours?.length > 0) {
                    const validation2 = cakeFlavours?.every(item => item.Price);
                    (validation2) ? setFlavourValidate(flavourValidate = false) : setFlavourValidate(flavourValidate = true);

                    (existingFlavoursPrice <= flavourTotal) ? setFlavourCostDifference(flavourCostDifference = { ...flavourCostDifference, Validation: false }) :
                        setFlavourCostDifference(flavourCostDifference = { ...flavourCostDifference, Validation: true });
                } else {
                    setFlavourValidate(flavourValidate = false);
                }
                if (cakeShape) {
                    (cakeShape?.Price) ? setShapeValidate(shapeValidate = false) : setShapeValidate(shapeValidate = true);
                } else {
                    setShapeValidate(shapeValidate = false);
                };
                if (cakeShape) {
                    (!flavourValidate && !shapeValidate && cakeShape?.Price && (parseFloat(cakeShape?.Price) >= parseFloat(OrderShape?.Price)) &&
                        !flavourCostDifference?.Validation) && handleUpdateChangeRequestStatus(data, cakeFlavours, cakeShape);
                } else {
                    (!flavourValidate && !shapeValidate && !flavourCostDifference?.Validation) && handleUpdateChangeRequestStatus(data, cakeFlavours, cakeShape);
                };
            };
        } else {
            handleUpdateChangeRequestStatus(data);
        };
    };

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            resetField('Difference_In_Price');
            resetField('Input');
            resetField('Ticket_Approved_Status');
            setShowField(false);
            setFlavourValidate(false);
            setShapeValidate(false);
            setFlavourTotal(0);
            setDifferenceInCost(0);
            setTotalDifferenceInCost(0);
            setCakeFlavours([]);
            setCakeShape();
            setExistingFlavoursPrice(0);
            setExistingShapePrice(0);
            setFlavourCostDifference({ Difference: 0, Validation: false });
            setShapeCostDifference(0);
        };
        //eslint-disable-next-line
    }, [Ticket_Response]);

    return (
        <div>
            <Modal show={showEnterInputPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Enter Inputs</h4>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Notes<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <textarea
                                    className={errors?.Input ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                    type='text'
                                    placeholder="Enter Here..."
                                    {...register('Input', { required: { value: true, message: 'required' } })}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Vendor Approved Status<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select
                                    className={errors?.Ticket_Approved_Status ? "form-control required-field" : "form-control"}
                                    {...register('Ticket_Approved_Status', { required: { value: true, message: 'required' } })}
                                    defaultValue=''
                                    onChange={handleTicketStatus}
                                >
                                    <option value='' disabled>--Select--</option>
                                    <option value='Approved'>Approved</option>
                                    <option value='Not Approved'>Not Approved</option>
                                </select>
                            </div>
                        </div>
                        {showField &&
                            <>
                                {CauseOfTicket === 'Order Cancellation by Customer' &&
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cancellation Charge<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                className={errors?.Cancellation_Charges ? "form-control required-field" : "form-control"}
                                                type='number'
                                                onWheel={() => document.activeElement.blur()}
                                                {...register('Cancellation_Charges')}
                                                defaultValue={0}
                                                onChange={handleChancellationCharges}
                                            />
                                        </div>
                                    </div>
                                }
                                {CauseOfTicket === 'Change Requests' &&
                                    <>
                                        {TicketDetails?.Flavour?.length > 0 &&
                                            <>
                                                <div className="col-md-12">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Existing Flavours Price per Kg </label><br />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <FlavourListArray FlavoursList={OrderDetailsByTypeOfOrder?.Flavour} />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <FlavourList Flavours={JSON.stringify(TicketDetails?.Flavour)}
                                                        handleFlavourPrice={handleFlavourPrice}
                                                        flavourValidate={flavourValidate} existingFlavoursPrice={existingFlavoursPrice}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price for Flavour</label>
                                                    <div className="input-group">
                                                        <div
                                                            className={flavourCostDifference?.Validation === true ? "form-control required-field" : "form-control"}
                                                        >Rs. {flavourCostDifference?.Difference ? flavourCostDifference?.Difference : 0}</div>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        {TicketDetails?.Shape &&
                                            <>
                                                <div className="col-md-12">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Existing Shape Price per Kg </label><br />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <div>
                                                        <span>{`${OrderDetailsByTypeOfOrder?.Shape?.Name} - Additional Rs.${OrderDetailsByTypeOfOrder?.Shape?.Price}/Kg`}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <ShapesList Shape={JSON.stringify(TicketDetails?.Shape)}
                                                        handleShapePrice={handleShapePrice}
                                                        shapeValidate={shapeValidate}
                                                        existingShapePrice={existingShapePrice}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price for Shape</label>
                                                    <div className="input-group">
                                                        <div
                                                            className={(cakeShape?.Price && (parseFloat(cakeShape?.Price) >= parseFloat(OrderShape?.Price))) ? "form-control" : "form-control required-field"}
                                                        >Rs. {shapeCostDifference ? shapeCostDifference : 0}</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {(TicketDetails?.Flavour?.length > 0 || TicketDetails?.Shape) ?
                                            <>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price per Kg</label>
                                                    <div className="input-group">
                                                        <div
                                                            className="form-control"
                                                        >Rs. {differenceInCost ? differenceInCost : 0}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Total Difference in Price for {OrderWeight}</label>
                                                    <div className="input-group">
                                                        <div
                                                            className="form-control"
                                                        >Rs. {totaDifferenceInCost ? totaDifferenceInCost : 0}</div>
                                                    </div>
                                                </div>
                                            </> :
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Difference in Price (Only Positive Price) <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        className={errors?.Difference_In_Price ? "form-control required-field" : 'form-control'}
                                                        {...register('Difference_In_Price')}
                                                        defaultValue={0} maxLength={4} min={0}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value?.replace(/[^0-9]/g, '')) : null
                                                            }
                                                        }}
                                                        onChange={handleDifferenceInPrice}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                    </div>
                    {(CauseOfTicket === 'Order Cancellation by Customer' || CauseOfTicket === 'Change Requests') &&
                        <div>
                            <p className="text-success text-center intimation-message">This will be auto intimated to Customer Helpdesk and Manager</p>
                        </div>
                    }
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            {CauseOfTicket === 'Order Cancellation by Customer' ?
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSubmit(handleUpdateStatus)}
                                >
                                    Update and Intimate
                                </button> :
                                <button
                                    type="submit"
                                    disabled={(approvedStatus === 'Approved' && TicketShape !== undefined && !flavourValidate && !shapeValidate &&
                                        (cakeShape?.Price && cakeShape?.Price >= parseFloat(OrderShape?.Price)) && !flavourCostDifference?.Validation) ? false :
                                        (approvedStatus === 'Approved' && !TicketShape && !flavourValidate && !shapeValidate &&
                                            !flavourCostDifference?.Validation) ? false : (approvedStatus === 'Not Approved') ? false : true}
                                    // disabled={(approvedStatus === 'Approved' && !flavourValidate && !shapeValidate &&
                                    //     (TicketShape?.Name ? (cakeShape?.Price && cakeShape?.Price >= parseFloat(OrderShape?.Price)) : '') && !flavourCostDifference?.Validation) ? false : (approvedStatus === 'Not Approved') ? false : true}
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSubmit(handleUpdate)}
                                >
                                    Update and Intimate
                                </button>
                            }
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

function FlavourList(props) {

    const { Flavours, handleFlavourPrice, flavourValidate, existingFlavoursPrice } = props;

    let FlavoursArray = JSON.parse(Flavours);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Enter Flavour Price per Kg <span className="text-success">(Value should be greater than or equal to {existingFlavoursPrice})</span> <span className='text-danger'>*</span></label>
            <div className={flavourValidate ? "scrollable-list2 required-field" : "scrollable-list2"}>
                {FlavoursArray?.map((value, i) =>
                    <div className="display-flex mb-2 list-main" key={i}>
                        <div className="option-control"> {value.Name}</div>
                        <input
                            type="number"
                            onWheel={() => document.activeElement.blur()}
                            placeholder="Rs.300"
                            defaultValue='0'
                            maxLength={4}
                            min={0}
                            onInput={(e) => {
                                e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                if (e.target.value.length > e.target.maxLength) {
                                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                                } else if (e.target.value < e.target.min) {
                                    e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value?.replace(/[^0-9]/g, '')) : null
                                }
                            }}
                            className="price-input"
                            onChange={(e) => handleFlavourPrice(e, i)}
                        />
                    </div>
                )}
            </div>
        </div>
    )
};

function ShapesList(props) {

    const { Shape, handleShapePrice, shapeValidate, existingShapePrice } = props;

    let ShapeObject = JSON.parse(Shape);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Enter Shape Price per Kg <span className="text-success">(Value should be greater than or equal to {existingShapePrice})</span><span className='text-danger'>*</span></label>
            <div className={shapeValidate ? "scrollable-list2 required-field" : "scrollable-list2"}>
                <div className="display-flex mb-2 list-main" >
                    <div className="option-control"> {ShapeObject.Name}</div>
                    <input
                        type="number"
                        onWheel={() => document.activeElement.blur()}
                        placeholder="Rs.300"
                        defaultValue='0'
                        maxLength={4}
                        min={0}
                        onInput={(e) => {
                            e.target.value = e.target.value?.replace(/[^0-9]/, '')
                            if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                            } else if (e.target.value < e.target.min) {
                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                            }
                        }}
                        className="price-input"
                        onChange={(e) => handleShapePrice(e)}
                    />
                </div>
            </div>
        </div>
    )
};