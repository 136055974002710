import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VendorActions from '../../../../redux/Vendor/actions';
import CakeTypeList from '../../../sections/AdminVendorListDetail/CakeTypeList';
import InstituteNameList from '../../../sections/AdminVendorListDetail/InstituteNameList';
import SpacialityCakeList from '../../../sections/AdminVendorListDetail/SpecialityCakeList';
import Breadcrumb from './Breadcrumb';
import { FaPencilAlt } from 'react-icons/fa';
import Preloader from '../../../layouts/Preloader';

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);

    const { VendorDetails2 } = useSelector(state => state.VendorReducer);

    useEffect(() => {
        dispatch({ type: VendorActions.GET_VENDOR_DETAILS2, payload: Id });
        //eslint-disable-next-line
    }, [Id]);

    const handleEdit = (e) => {
        e.preventDefault();
        history.push('/admin-vendor-detail-edit', { type: 'helpdeskV', VendorId: Id });
        // history.push('/admin-vendor-detail-edit', { cakeId: VendorDetails2?._id, Email: VendorDetails2?.Email, type: "helpdeskV" });
    };

    useEffect(() => {
        (VendorDetails2.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [VendorDetails2]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb />
                    </div>
                    <div className="ms-profile-overview">
                        <div className="ms-profile-cover">
                            <div className='profile-img-div'>
                                <img className="ms-profile-img" src={VendorDetails2?.ProfileImage ? VendorDetails2?.ProfileImage : "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg"} alt="people" />
                            </div>
                            <div className="ms-profile-user-info">
                                <h4 className="ms-profile-username text-white">{VendorDetails2?.VendorName}</h4>
                                <h2 className="ms-profile-role">{VendorDetails2?.Email}</h2>
                            </div>
                            <div className="ms-profile-user-buttons">
                                <button
                                    className="btn btn-primary edit-profile"
                                    onClick={handleEdit}
                                >
                                    <FaPencilAlt /> Edit
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">BASIC INFORMATION</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Register ID</th>
                                                <td className={VendorDetails2?.Status === 'Blocked' ? 'text-danger' : ''}><b>{VendorDetails2?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Email ID</th>
                                                <td>{VendorDetails2?.Email}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Preferred Name on the App</th>
                                                <td>{VendorDetails2?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{VendorDetails2?.PreferredNameOnTheApp}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Gender</th>
                                                <td>{VendorDetails2?.Gender}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Date of Birth</th>
                                                <td>{VendorDetails2?.DateOfBirth}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{VendorDetails2?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Mobile Number</th>
                                                <td>{VendorDetails2?.PhoneNumber1}</td>
                                            </tr>
                                            {VendorDetails2?.PhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Alternative Mobile Number</th>
                                                    <td>{VendorDetails2?.PhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Door No</th>
                                                <td><span className="long-line-break">{VendorDetails2?.DoorNo}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Address</th>
                                                <td><span className="long-line-break">{VendorDetails2?.Address}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Pincode</th>
                                                <td><span className="long-line-break">{VendorDetails2?.Pincode}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">BANK DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Bank Name</th>
                                                <td>{VendorDetails2?.BankName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Branch</th>
                                                <td>{VendorDetails2?.Branch}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Account Number</th>
                                                <td>{VendorDetails2?.AccountNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">IFSC Code</th>
                                                <td>{VendorDetails2?.IFSCCode}</td>
                                            </tr>
                                            {VendorDetails2?.UPIId ?
                                                <tr>
                                                    <th scope="row">UPI ID</th>
                                                    <td>{VendorDetails2?.UPIId}</td>
                                                </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title"> IDENTITY AND LICENSE DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Aadhaar Number</th>
                                                <td>{VendorDetails2?.AadhaarNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">PAN Number</th>
                                                <td>{VendorDetails2?.PANNumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">FSSAI Number</th>
                                                <td>{VendorDetails2?.FSSAINumber}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">FSSAI Expiry Date</th>
                                                <td>{VendorDetails2?.FSSAIExpiryDate}</td>
                                            </tr>
                                            {VendorDetails2?.GSTNumber ?
                                                <tr>
                                                    <th scope="row">GST Number</th>
                                                    <td>{VendorDetails2?.GSTNumber}</td>
                                                </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title"> OTHER DETAILS</h2>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Learning Type</th>
                                                <td>{VendorDetails2?.LearningType}</td>
                                            </tr>
                                            {VendorDetails2?.InstitutionName?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Institution Name</th>
                                                    <td><span className="long-line-break"><InstituteNameList NameList={VendorDetails2?.InstitutionName} /></span></td>
                                                    {/* <td>{VendorDetails2?.InstitutionName}</td> */}
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Total Duration of Learning</th>
                                                <td>{VendorDetails2?.TotalDurationOfLearning}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Maximum Capacity to Bake in a Week (in kgs)</th>
                                                <td>{VendorDetails2?.HowManyCakesCanYouMakeInaWeek}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Current Average Sales per Month</th>
                                                <td>{VendorDetails2?.CurrentAverageSalePerMonth}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Baking Experience</th>
                                                <td>{VendorDetails2?.YearsOfExperienceAsBaker}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Number of Days You Can Bake In a Week</th>
                                                <td>{VendorDetails2?.HowManyDaysCanYouWorkInaWeek}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Job Type</th>
                                                <td>{VendorDetails2?.JobType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Can You Bake Cakes Above 5kg </th>
                                                <td>{VendorDetails2?.CanYouMakeSingleCakeAbove5Kgs === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Do You Make Tier Cake</th>
                                                <td>{VendorDetails2?.CanYouMakeTierCakes === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Comfortable on Working With Mobile Apps</th>
                                                <td>{VendorDetails2?.AreYouFamiliarOnWorkingWithApps === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Types of Cake/Other Product</th>
                                                <td><span className="long-line-break"><CakeTypeList NameList={VendorDetails2?.CakeTypesYouBake} /></span></td>
                                                {/* <td>{VendorDetails2?.CakeTypesYouBake[0]}</td> */}
                                            </tr>
                                            <tr>
                                                <th scope="row">Your Speciality Cakes</th>
                                                <td><span className="long-line-break"><SpacialityCakeList NameList={VendorDetails2?.YourSpecialityCakes} /></span></td>
                                                {/* <td>{VendorDetails2?.YourSpecialityCakes}</td> */}
                                            </tr>
                                            <tr>
                                                <th scope="row">Self Rating</th>
                                                <td>{VendorDetails2?.Ratings} Star</td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Do You Bake Regular Cake With Fondant Topper</th>
                                                <td>{VendorDetails2?.CanYouMakeARegularCakeWithFondantAsToppers === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h2 className="section-title">ABOUT VENDOR</h2>
                                    <p className="display-description">{VendorDetails2?.Description}</p>
                                    <div className="registered-vendor-close">
                                        <button
                                            className="btn btn-secondary "
                                            type="submit"
                                            onClick={() => history.push('/helpdeskV-vendors-list')}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    );
}
export default Content;