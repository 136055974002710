import React from 'react';
import Sidenavigation from '../../vendor/layout/Sidenavigation';
import Topnavigation from '../../vendor/layout/Topnavigation';
import Content from '../../vendor/section/vendorprofile/Content';
import { Helmet } from "react-helmet";

export default function Vendorprofile() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Profile</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end  */}

            <main className="body-content">

                {/* Topnavigation start  */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content />
                {/* page content end  */}

            </main>

        </div>
    );
};


