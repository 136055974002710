import React, { useState, useEffect } from 'react';
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Topper/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import Preloader from '../../../layouts/Preloader';
import { float2D, NumberValidation } from '../../../../utils/Validation';
import Breadcrumb from './Breadcrumb';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ImageSlider from '../../../common/ImageSlider';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    TopperName: yup.string().required('required'),
    Price: yup.string().required('required'),
    Stock: yup.string().required("required"),
    AvailableCount: yup.string().required("required"),
});

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    let [topperImage, setTopperImage] = useState();
    let [topperImageUrl, setTopperImageUrl] = useState();
    let [selectedStock, setSelectedStock] = useState();

    const { token } = useSelector(state => state.authReducer);

    const { UpdateTopperStatus, TopperDetails } = useSelector(state => state.TopperReducer);

    const { register, handleSubmit, formState, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        dispatch({ type: actions.GET_TOPPER_DETAILS, payload: Id });
        //eslint-disable-next-line
    }, [Id]);

    const handleSelectImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setTopperImage(topperImage = result?.FinalImages[0]);
                if (topperImage) {
                    setTopperImageUrl(topperImageUrl = URL.createObjectURL(topperImage));
                };
            }
        }
    };

    const handleStock = (e) => {
        setSelectedStock(e.target.value);
        setValue('AvailableCount', 0);
    };

    const onSubmit = (data) => {
        setLoader(true);
        if (token !== undefined) {
            const formData = new FormData();
            formData.append('VendorID', token?.result?._id);
            formData.append('Vendor_ID', token?.result?.Id);
            formData.append('VendorName', token?.result?.VendorName);
            formData.append('TopperName', data.TopperName);
            formData.append('Price', data.Price);
            formData.append('Stock', data.Stock);
            formData.append('AvailableCount', data.AvailableCount);
            formData.append('file', topperImage);
            dispatch({ type: actions.UPDATE_TOPPER, payload: { data: formData, id: TopperDetails?._id } });
        }
    };

    const handleDeletePopup = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleDeleteTopper = (e) => {
        e.preventDefault();
        dispatch({
            type: actions.DELETE_TOPPER,
            payload: { id: TopperDetails?._id, VendorID: TopperDetails?.VendorID, history: history }
        });
    };

    const handleClose = () => { setShow(false) };

    //validate add cake status
    useEffect(() => {
        if (UpdateTopperStatus?.statusCode === 400 || UpdateTopperStatus?.statusCode === 200) {
            setLoader(false);
            setEdit(false);
        };
        // eslint-disable-next-line
    }, [UpdateTopperStatus]);

    useEffect(() => {
        if (TopperDetails.length === 0) {
            setFetchSuccess(false)
        } else {
            setFetchSuccess(true);
            setTopperImageUrl(TopperDetails?.TopperImage);
            setSelectedStock(TopperDetails?.Stock);
        };
    }, [TopperDetails])

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <>
                    {!edit ?
                        <div className="ms-content-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <Breadcrumb />
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-header">
                                                <h6 >Topper Details</h6>
                                            </div>
                                            <div className="ms-panel-body tabledata-background">
                                                <ImageSlider image={[topperImageUrl]} />
                                                {/* <img
                                                    className="d-block align-center"
                                                    src={topperImageUrl}
                                                    alt="First slide"
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h4 className="section-title bold">TOPPER INFO</h4>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Topper Name</th>
                                                            <td><b>{TopperDetails?.TopperName}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Price</th>
                                                            <td>Rs.{float2D(TopperDetails?.Price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Stock</th>
                                                            <td><span className={(TopperDetails?.Stock?.toUpperCase())?.replace(/ /g, '')}>{TopperDetails?.Stock}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Available Count</th>
                                                            <td>{TopperDetails?.AvailableCount}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button
                                                    className="btn btn-success mr-3"
                                                    type="button"
                                                    onClick={() => setEdit(true)}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="btn btn-primary mr-3"
                                                    type="button"
                                                    onClick={handleDeletePopup}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    className="btn btn-secondary mr-3 "
                                                    type="button"
                                                    onClick={() => history.push('/Vendor-toppers-list')}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                                    centered contentClassName="modal-body-scrollar">
                                    <Modal.Body className="tabledata-background">
                                        <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                                        <div>
                                            <h6 className='text-center mb-4'>Are you sure you want to delete the Topper ?</h6>
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={handleDeleteTopper}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-secondary ml-4"
                                                    onClick={handleClose}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div> :
                        <div className="ms-content-wrapper">
                            <div className="col-md-12">
                                <Breadcrumb />
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Update Topper Details</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background order-image">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center responsive-image-width"
                                                src={topperImageUrl}
                                                alt="First slide"
                                            />
                                        </ResponsiveEmbed>
                                        <label className="btn btn-primary upload-button mr-4" htmlFor="actual-btn">Upload</label>
                                        <input
                                            type="file"
                                            accept='image/jpg,image/jpeg,image/png'
                                            id="actual-btn"
                                            onChange={handleSelectImage}
                                            hidden
                                        />
                                        <p className='text-success font-weight-bold text-right'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <form className="needs-validation clearfix" noValidate="">
                                            <div className="form-row">
                                                <div className="col-md-12 mb-3">
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Topper Name <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className={errors?.TopperName ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                            name='TopperName'
                                                            {...register('TopperName')}
                                                            placeholder="Topper name"
                                                            defaultValue={TopperDetails.TopperName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={(!selectedStock || (selectedStock && selectedStock === 'OutOfStock')) ? "col-md-6 mb-3" : "col-md-4 mb-3"}>
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Price <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <input
                                                            type="number"
                                                            onWheel={() => document.activeElement.blur()}
                                                            min={0}
                                                            maxLength={4}
                                                            onInput={NumberValidation}
                                                            className={errors?.Price ? "form-control required-field" : "form-control"}
                                                            name='Price'
                                                            {...register('Price')}
                                                            placeholder="Price"
                                                            defaultValue={TopperDetails?.Price}
                                                        />
                                                        <button className={errors?.Price ? "RupeeSign required-field" : "RupeeSign"} disabled><i className="fas fa-rupee-sign"></i></button>
                                                    </div>
                                                </div>
                                                <div className={(!selectedStock || (selectedStock && selectedStock === 'OutOfStock')) ? "col-md-6 mb-3" : "col-md-4 mb-3"}>
                                                    <label htmlFor="validationCustom18" className='font-weight-bold'>Stock <span className='text-danger'>*</span></label>
                                                    <div className="input-group">
                                                        <select
                                                            className={errors?.Stock ? "form-control required-field" : "form-control"}
                                                            name='Stock' {...register('Stock')}
                                                            defaultValue={selectedStock}
                                                            onChange={handleStock}
                                                        >
                                                            <option value="OutOfStock">Out Of Stock</option>
                                                            <option value="InStock">In Stock</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {(selectedStock && selectedStock === 'InStock') &&
                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="validationCustom18" className='font-weight-bold'>Available Count <span className='text-danger'>*</span></label>
                                                        <div className="input-group">
                                                            <input
                                                                type="number"
                                                                onWheel={() => document.activeElement.blur()}
                                                                min={0}
                                                                maxLength={4}
                                                                onInput={NumberValidation}
                                                                className={errors?.AvailableCount ? "form-control required-field" : "form-control"}
                                                                name='AvailableCount'
                                                                {...register('AvailableCount')}
                                                                placeholder="e.g. 10"
                                                                defaultValue={TopperDetails?.AvailableCount}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {loader ? <CircleDotSpinner /> :
                                                <>
                                                    <button
                                                        className="btn btn-primary mr-3"
                                                        type="button"
                                                        onClick={handleSubmit(onSubmit)}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="submit"
                                                        onClick={() => setEdit(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}

export default Content;