import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/esm/react-router-dom";
import actions from "../../../../redux/Order/actions";
import helpdeskActions from '../../../../redux/Helpdesk/actions';
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "./Breadcrumb";
import Preloader from "../../../layouts/Preloader";
import Pagination from "../../../common/Pagination";
import TableSearch from "../../../common/TableSearch";
import CommonActions from '../../../../redux/Common/actions';
// import Alert from "../../../common/Alert";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    // eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [timer, setTimer] = useState("");
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(5);
    const [search2, setsearch2] = useState('');
    const [filteredData2, setFilteredData2] = useState([]);
    const [currentPage2, setCurrentPage2] = useState(0);
    const [postsPerPage2, setPostPerPage2] = useState(5);

    //for get orders list
    const List = useSelector(
        state => state.OrderReducer
    );

    // for get not respond orders list
    const NoResponseOrders = useSelector(
        state => state.HelpdeskReducer
    );

    const { response } = useSelector(
        state => state.commonReducer
    );

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: {
                alert: false,
                message: null,
                status: null
            }
        });
    };

    //for get search input value - above 5kg table
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //for get search input value - not respond table
    const handleSearchbar2 = (e) => {
        setsearch2(e.target.value.toLowerCase());
        setCurrentPage2(0);
    };

    useEffect(() => {
        //list for above5kg table
        if (List.OrderListByAbove5kg.message === 'No Orders') {
            setFilteredData([]);
        } else {
            setFilteredData(List.OrderListByAbove5kg.reverse().filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CakeType && val.CakeType.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.Weight && val.Weight.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [List, search]);

    useEffect(() => {
        //list for not respond table
        if (NoResponseOrders.NotRespondOrders.message === 'No Orders') {
            setFilteredData2([]);
        } else {
            setFilteredData2(NoResponseOrders.NotRespondOrders.filter(val => {
                if (search2 === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search2)) ||
                    // val?.CakeName.toLowerCase().includes(search2) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search2)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search2)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search2))||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search2))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [NoResponseOrders, search2]);

    // Get current posts - above 5kg table
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page - above 5kg table
    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    // Get current posts - not respond table
    const indexOfLastPost2 = currentPage2 * postsPerPage2;
    const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage2;
    const currentdata2 = filteredData2.slice(indexOfFirstPost2, indexOfLastPost2);

    // Change page - not respond table
    const paginate2 = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage2(pageNumber);
    };

    //for view deatils page
    const ViewDetails = (order) => {
        history.push('/helpdeskV-order-details', { order: order })
    };

    //dispatch above 5kg orders and not respond orders apis
    useEffect(() => {
        dispatch({ type: actions.GET_ORDER_LIST_BY_ABOVE_5KG, payload: 'y' });
        dispatch({ type: helpdeskActions.GET_NOT_RESPOND_ORDERS });
        //eslint-disable-next-line
    }, []);

    //loading
    useEffect(() => {
        if (List.OrderListByAbove5kg.length === 0 || NoResponseOrders.NotRespondOrders.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            if (response.alert) {
                setShowAlert(true);
                setTimer(setInterval(() => {
                    clearNotification()
                }, 4000))
            }
        }
        // eslint-disable-next-line
    }, [List, NoResponseOrders, response]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            {/* above 5kg orders list table start  */}
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Orders List - Above 5 KG And Premium Orders</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => setPostPerPage(e.target.value)}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="type here to search..." onChange={handleSearchbar} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Cake Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Order Type</th>
                                                                <th scope="col">Ordered Date</th>
                                                                <th scope="col">Delivery Date</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Cake Weight</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((order) =>
                                                                    <tr className="odd cursor" key={order._id} onClick={() => ViewDetails(order)}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td>{order.CakeName ? order.CakeName : 'Customised Cake'}</td>
                                                                        <td> {order.UserName}</td>
                                                                        <td>
                                                                        <span className={
                                                                                order.Above5KG === 'y' ? 'Regular' : 'Premium'
                                                                            }
                                                                            >
                                                                                {/* <span className={order.VendorName !== undefined ? '' :
                                                                                order.Above5KG === 'y' ? 'Regular' : 'Premium'
                                                                            }
                                                                            > */}
                                                                                {/* {order.VendorName !== undefined ? "--" : */}
                                                                                {order.Above5KG === 'y' ? 'Above 5Kg' : 'Premium order'}
                                                                                {/* } */}
                                                                            </span>
                                                                        </td>
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        <td><span className={(order.Status.toUpperCase()).replace(/ /g, '')}>{order.Status}</span></td>
                                                                        <td>{order.Weight}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <th scope="row">No Records Found</th>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <Pagination
                                            postsPerPage={postsPerPage}
                                            totalPosts={filteredData.length}
                                            paginate={paginate}
                                            PreviouPage={() => setCurrentPage(currentPage - 1)}
                                            NextPage={() => setCurrentPage(currentPage + 1)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                        />
                                    }
                                </div>
                            </div>
                            {/* above 5kg orders list table end  */}
                            {/* not respond orders list table start  */}
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Orders List - No Response from Vendors</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => setPostPerPage2(e.target.value)}>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="type here to search..." onChange={handleSearchbar2} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Cake Name</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date</th>
                                                                <th scope="col">Delivery Date</th>
                                                                {/* <th scope="col">Status</th> */}
                                                            </tr>
                                                        </thead>
                                                        {filteredData2.length > 0 ?
                                                            <tbody>
                                                                {currentdata2.map((order) =>
                                                                    <tr className="odd cursor" key={order._id} onClick={() => ViewDetails(order)}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td><span className={order.CakeName ? '' : 'font-weight-bold'}>{order.CakeName ? order.CakeName : 'Customised Cake'} </span></td>
                                                                        <td>
                                                                            <span className={order.VendorName  ? '' :
                                                                                order.Above5KG === 'y' ? 'Regular' : 'Premium'
                                                                            }
                                                                            >
                                                                                {order.VendorName  ? order.VendorName :
                                                                                    order.Above5KG === 'y' ? 'Above 5Kg' : 'Premium order'
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        <td> {order.UserName}</td>
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        {/* <td><span className={order.Status}>{order.Status}</span></td> */}
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <th scope="row">No Records Found</th>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData2.length > postsPerPage2 &&
                                        <Pagination
                                            postsPerPage={postsPerPage2}
                                            totalPosts={filteredData2.length}
                                            paginate={paginate2}
                                            PreviouPage={() => setCurrentPage2(currentPage2 - 1)}
                                            NextPage={() => setCurrentPage2(currentPage2 + 1)}
                                            currentPage={currentPage2}
                                            currentdata={currentdata2}
                                        />
                                    }
                                </div>
                            </div>
                            {/* not respond orders list table end  */}
                        </div>
                    </div>
                    {/* {showAlert &&
                        <Alert response={response} />
                    } */}
                </div>
            }
        </>
    )
};