import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => {

    //get logged in users details and isauthenticated
    const auth = useSelector((state) => state.authReducer);

    return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated ? (
                    <Redirect to={auth.route} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PublicRoute;



// import React, { useEffect } from "react";
// import { Route, Redirect, useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { CakeyPaths } from "../utils/cakeyPaths";

// const PublicRoute = ({ component: Component, ...rest }) => {

//     //get logged in users details and isauthenticated
//     const auth = useSelector((state) => state.authReducer);
//     const history = useHistory();
//     const pathName = useHistory().location.pathname;

//     useEffect(() => {
//         if (pathName) {
//             if (!CakeyPaths?.includes(pathName?.toLowerCase())) {
//                 console.log("dsgdsgdsg")
//                 history.push('/404');
//             }
//             else{
//                 history.push(pathName);
//             }
//         };
//          //eslint-disable-next-line
//     }, [pathName, CakeyPaths]);

//     return (
//         <Route
//             {...rest}
//             render={(props) =>
//                 auth.isAuthenticated ? (
//                     <Redirect to={auth.route} />
//                 ) : (
//                     <Component {...props} />
//                 )
//             }
//         />
//     );
// };

// export default PublicRoute;