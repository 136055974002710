import React, { useState, useEffect } from 'react';

export default function Flavour({ FlavoursList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (FlavoursList === undefined) {
            setList([]);
        } else {
            setList(FlavoursList);
        };
    }, [FlavoursList]);

    return (
        <div>
            {
                list.map((val, i) =>
                    <span key={i}>{(i ? ', ' : '') + val}</span>
                )
            }
        </div>
    )
};