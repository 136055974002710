import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import CircleDotSpinner from '../../../../common/CircleDotSpinner';
import { CouponGenerator } from '../../../../common/CouponGenerator';
import { useSelector } from 'react-redux';
import { PushNotification } from '../../../../common/PushNotification';
import { float2D } from '../../../../../utils/Validation';
// import { NumberValidation, float2D } from '../../../../../utils/Validation';

export default function RefundForMiscellaneousPopup(props) {

    const { showRefundForMiscellaneousPopup, handleCloseRefundFor15MinsCancelPopup, TicketDetails, OrderDetailsByTypeOfOrder,
        handleRefundPaymentForMiscellaneous, loader, CouponCodeList } = props;

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    let [couponList, setCouponList] = useState([]);
    let [validation, setValidation] = useState(false);
    let [couponCode, setCouponCode] = useState('');

    useEffect(() => {
        if (CouponCodeList.length === 0) {
            setCouponList([]);
        } else {
            if (CouponCodeList?.message === "No Records Found") {
                setCouponList([]);
            } else {
                setCouponList(CouponCodeList);
            };
        };
    }, [CouponCodeList]);

    const handleGenerateCoupon = async () => {
        const CouponCode = await CouponGenerator();
        if (couponList.length > 0) {
            let CouponValidate = couponList.find(val => val.Code === CouponCode);
            if (CouponValidate) {
                setValidation(true)
                setCouponCode('');
            } else {
                setValidation(false)
                setCouponCode(CouponCode)
            };
        } else {
            setCouponCode(CouponCode);
        };
    };

    const handleClose = (e) => {
        setCouponCode('');
        setValidation(false);
        handleCloseRefundFor15MinsCancelPopup(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let Data;
        let bodyContent;
        (TicketDetails?.RefundType === 'Cash') ? bodyContent = `Rs.${TicketDetails?.Refund_Amount} is Processed Successfully against Your Request(${TicketDetails?.Order_ID}) will be Credited in your Account Shortly` :
            bodyContent = `Received Coupon against Your Request(${TicketDetails?.Order_ID}) for Rs.${TicketDetails?.CouponValue} Valid for 6 Months`;
        if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: bodyContent,
            }
            PushNotification(pushMessageData);
        };
        if (TicketDetails?.RefundType === 'Cash') {
            Data = {
                RefundType: TicketDetails?.RefundType,
                Refund_Status: 'Success',
                UserID: TicketDetails?.UserID,
                User_ID: TicketDetails?.User_ID,
                Last_Intimate: ['HelpdeskC'],
                For_Display: `Rs.${TicketDetails?.Refund_Amount} is Processed Successfully against Your Request(${TicketDetails?.Order_ID}) will be Credited in your Account Shortly`,
            }
        } else {
            Data = {
                CouponCode: couponCode,
                CouponValue: TicketDetails?.CouponValue,
                CouponType: TicketDetails?.CouponType,
                UserID: TicketDetails?.UserID,
                User_ID: TicketDetails?.User_ID,
                Refund_Status: 'Coupon Generated',
                RefundType: TicketDetails?.RefundType,
                Last_Intimate: ['HelpdeskC'],
                For_Display: `Received Coupon against Your Request(${TicketDetails?.Order_ID}) for Rs.${TicketDetails?.CouponValue} Valid for 6 Months`,
            }
        };

        handleRefundPaymentForMiscellaneous(Data);
    };

    useEffect(() => {
        if (validation) {
            handleGenerateCoupon();
            setValidation(false);
        };
        //eslint-disable-next-line
    }, [validation]);

    return (
        <div>
            <Modal show={showRefundForMiscellaneousPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Payment Refund</h4>
                    {OrderDetailsByTypeOfOrder?.length === 0 ?
                        <div className="text-center mt-5 mb-5">
                            <p>Loading...</p>
                        </div> :
                        <>
                            {TicketDetails?.RefundType === 'Cash' &&
                                <>
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Amount</label>
                                        <div className="input-group">
                                            <input className='form-control' disabled defaultValue={float2D(TicketDetails?.Refund_Amount)} />
                                        </div>
                                    </div>
                                </>
                            }
                            {TicketDetails?.RefundType === 'Coupon' &&
                                <>
                                    {/* <div className="col-md-12">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="bold text-center">Refund Amount Details</h4>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Refund Amount</th>
                                                        <td><b>Rs. {float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) - parseFloat(TicketDetails?.Cancellation_Charges))}</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Generate Coupon Code<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='display-flex w-100'>
                                                <input type="text" className="form-control" value={couponCode || ''} disabled />
                                                <button className='add-button rounded disabled-add' onClick={handleGenerateCoupon} disabled={couponCode ? true : false}>Generate</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Type<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <div className='form-control'>Amount</div>
                                            {/* <select className='form-control' defaultValue='' onChange={(e) => setCouponType(e.target.value)}>
                                                        <option value='' disabled>--Select--</option>
                                                        <option value='Percentage'>Percentage</option>
                                                        <option value='Amount'>Amount</option>
                                                    </select> */}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Coupon Amount<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                type='number'
                                                className='form-control'
                                                value={TicketDetails?.CouponValue}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                disabled={((TicketDetails?.RefundType === 'Cash') || (TicketDetails?.RefundType === 'Coupon' && couponCode)) ? false : true}
                                onClick={handleSubmit}
                            >
                                Intimate to Customer Helpdesk
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div >
    )
};