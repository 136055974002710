import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import commonActions from '../../../redux/Common/actions';
import Preloader from '../../layouts/Preloader';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import ReactPagination from '../../common/React-Pagination';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const { UnclosedTicketsListByTypeOfUser, DailyUnclosedTicketsCount, Ticket_Response } = useSelector(state => state.TicketReducer);

    const auth = useSelector((state) => state.authReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);
    const [showReportsPopup, setShowReportsPopup] = useState(false);
    const [loader, setLoader] = useState(false);

    let [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'UT' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    let [endDate, setEndDate] = useState((Filtered_Dates?.Page === 'UT' && moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

    const TypeOfUser = auth?.token?.result?.TypeOfUser;

    useEffect(() => {
        if (TypeOfUser) {
            dispatch({
                type: actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: {
                    TypeOfUser: TypeOfUser?.replace(/ /g, ''),
                    StartDate: ((Filtered_Dates?.Page === 'UT' && Filtered_Dates?.StartDate) ? Filtered_Dates?.StartDate : moment().format('DD-MM-YYYY')),
                    EndDate: ((Filtered_Dates?.Page === 'UT' && Filtered_Dates?.EndDate) ? Filtered_Dates?.EndDate : 'today')
                }
            });
            if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate && Filtered_Dates?.Page === 'UT') {
                setButtonChange(true);
            };
        };
        //eslint-disable-next-line
    }, [TypeOfUser, Filtered_Dates]);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (UnclosedTicketsListByTypeOfUser?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(UnclosedTicketsListByTypeOfUser.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.TypeOfUser && val.TypeOfUser.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_By.Name && val.Ticket_Raised_By.Name.toLowerCase().includes(search)) ||
                    (val.CauseOfTicket && val.CauseOfTicket.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_On && val.Ticket_Raised_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [UnclosedTicketsListByTypeOfUser, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            dispatch({
                type: actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: {
                    TypeOfUser: TypeOfUser?.replace(/ /g, ''),
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: 'today'
                }
            });
        } else {
            if (startDate && endDate) {
                setButtonChange(true);
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'UT' } });
                dispatch({
                    type: actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
                    payload: {
                        TypeOfUser: TypeOfUser?.replace(/ /g, ''),
                        StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    }
                });
            };
        };
    };

    const ViewDetails = (e, details) => {
        e.preventDefault();
        let Path = (Page === 'HelpdeskC') ? '/Helpdesk-C-Ticket-Details' :
            (Page === 'Manager') ? '/Manager-Ticket-Details' : Page === 'HelpdeskV' ? "/helpdeskV-Ticket-Details" :
                '/Management-Ticket-Details';

        history.push(Path, { Id: details._id, Page: Page, Tab: 'UnclosedTickets' });
        // let Path = (Page === 'Manager') ? '/Manager-Unclosed-Ticket-Details' :
        //     Page === 'HelpdeskC' ? '/Helpdesk-C-Unclosed-Ticket-Details' :
        //         Page === 'Management' ? '/Management-Unclosed-Ticket-Details' : '/helpdeskV-Unclosed-Ticket-Details';
        // history.push(Path, { Id: details._id, Page: Page });
    };

    const handleShowReportsPopup = (e) => {
        e.preventDefault();
        dispatch({ type: actions.SET_DAILY_UNCLOSED_TICKETS_COUNT, payload: [] });
        setShowReportsPopup(true);
        dispatch({
            type: actions.GET_DAILY_UNCLOSED_TICKETS_COUNT,
            payload: { TypeOfUser: auth?.token?.result?.TypeOfUser?.replace(/ /g, ''), Email: auth?.token?.result?.Email }
        });
    };

    const handleSendReportToManager = (e) => {
        e.preventDefault();
        const data = {
            InternalUserID: auth?.token?.result?._id,
            InternalUser_ID: auth?.token?.result?.Id,
            Name: auth?.token?.result?.Name,
            Email: auth?.token?.result?.Email,
            TypeOfUser: auth?.token?.result?.TypeOfUser,
            Today_Tickets_Count: DailyUnclosedTicketsCount?.TotalTickets,
            Today_Closed_Tickets_Count: DailyUnclosedTicketsCount?.ClosedTickets,
            Total_Unclosed_Tickets_Count: DailyUnclosedTicketsCount?.UnclosedTickets
        };
        setLoader(true);
        dispatch({ type: actions.DAILY_TICKET_REPORTS_SEND_TO_MANAGER, payload: data });
    };

    const handleClose = () => {
        setShowReportsPopup(false);
        dispatch({ type: actions.SET_DAILY_UNCLOSED_TICKETS_COUNT, payload: [] });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            dispatch({
                type: actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: {
                    TypeOfUser: TypeOfUser?.replace(/ /g, ''),
                    StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
                }
            });
        } else {
            dispatch({
                type: actions.GET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: {
                    TypeOfUser: TypeOfUser?.replace(/ /g, ''),
                    StartDate: moment().format('DD-MM-YYYY'),
                    EndDate: 'today'
                }
            });
        }
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400) {
            setLoader(false);
        } else if (Ticket_Response.statusCode === 200) {
            setLoader(false);
            setShowReportsPopup(false);
        };
        // eslint-disable-next-line 
    }, [Ticket_Response]);

    useEffect(() => {
        (UnclosedTicketsListByTypeOfUser.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [UnclosedTicketsListByTypeOfUser]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Unclosed Tickets List</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate || ""}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate || ""}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 ">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className={(Page === 'Management' || Page === 'Manager') ? "col-xl-9 col-lg-9 col-md-7 col-sm-12" : "col-xl-5 col-lg-5 col-md-7 col-sm-12"}>
                                                    <div className={(Page === 'Management' || Page === 'Manager') ? 'dataTables_filter d-flex justify-content-end' : 'dataTables_filter'} id="data-table-4_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input
                                                                type="search"
                                                                className="form-control form-control-sm"
                                                                placeholder="search Id/Status..."
                                                                onChange={handleSearchbar}
                                                                aria-controls="data-table-4"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                                {(Page === 'Management' || Page === 'Manager') ?
                                                    <></> :
                                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className='d-flex justify-content-end'>
                                                            <Button
                                                                size='sm'
                                                                className='mt-0 btn-blue'
                                                                onClick={handleShowReportsPopup}
                                                            >
                                                                Send Daily Reports
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Ticket ID</th>
                                                                <th scope="col">Cause of Ticket</th>
                                                                <th scope="col">Ticket Raised On</th>
                                                                <th scope="col">Ticket Raised By</th>
                                                                <th scope="col">Ticket Raised For</th>
                                                                <th scope="col">Awaiting Response From</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr
                                                                        className="odd cursor"
                                                                        key={cake._id}
                                                                        onClick={(e) => ViewDetails(e, cake)}
                                                                    >
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CauseOfTicket === 'Fully Customized Cake' ? 'Fully Customised Cake' :
                                                                            cake.CauseOfTicket === 'Customized Cake through Vendor' ? 'Customised Cake through Vendor' :
                                                                                cake.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' ? 'Cakey Direct-Fully Customised Cake' :
                                                                                    cake.CauseOfTicket
                                                                        }
                                                                        </td>
                                                                        <td>{cake.Ticket_Raised_On}</td>
                                                                        <td>{cake.Ticket_Raised_By.Name}</td>
                                                                        <td>{cake.Ticket_Raised_For}</td>
                                                                        <td className='price-align3-row'><AwaitingResponseFrom Last_Intimate={cake.Last_Intimate} /></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={showReportsPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background modal-body-content">
                            <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel-body tabledata-background">
                                        <h5 className="bold text-center pb-3">Send Daily Reports to Manager</h5>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Today&apos;s Total Tickets</th>
                                                    <td className='font-weight-bold'>{DailyUnclosedTicketsCount?.TotalTickets}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Today&apos;s Closed Tickets</th>
                                                    <td className='font-weight-bold'>{DailyUnclosedTicketsCount?.ClosedTickets ? DailyUnclosedTicketsCount?.ClosedTickets : 'Loading...'}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Total Unclosed Tickets</th>
                                                    <td className='font-weight-bold'>{DailyUnclosedTicketsCount?.UnclosedTickets}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary shadow-none mr-3"
                                            onClick={handleSendReportToManager}
                                        >
                                            Send
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary shadow-none"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
};

function AwaitingResponseFrom(props) {

    const { Last_Intimate } = props;

    return (
        <>
            {
                Last_Intimate.map((val, i) =>
                    <span key={i}>{(i ? ', ' : '') +  (val === 'HelpdeskV' ? 'Vendor Helpdesk' : val === 'HelpdeskC' ? 'Customer Helpdesk' : val)}</span>
                )
            }
        </>
    )
};