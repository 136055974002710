import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/LoginHistoryAndWorkHours/actions';
// import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactPagination from '../../common/React-Pagination';

export default function Content(props) {

    const { Page, TypeOfUser } = props;

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);
    const [exportStatement, setExportStatement] = useState({
        Statement: [],
        filename: null
    });

    let [startDate, setStartDate] = useState();
    let [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const { HelpdeskWorkHours } = useSelector(state => state.LoginHistoryAndWorkHoursReducer);

    useEffect(() => {
        dispatch({
            type: actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
            payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY'), TypeOfUser: TypeOfUser }
        });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (HelpdeskWorkHours?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(HelpdeskWorkHours.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.InternalUser_ID && val.InternalUser_ID.toLowerCase().includes(search)) ||
                    (val.InternalUserName && val.InternalUserName.toLowerCase().includes(search)) ||
                    (val.LastLogin_At && val.LastLogin_At.toLowerCase().includes(search)) ||
                    (val.LastLogout_At && val.LastLogout_At.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [HelpdeskWorkHours, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({
                type: actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY'), TypeOfUser: TypeOfUser }
            });
        } else {
            if (startDate && endDate) {
                setButtonChange(true);
                dispatch({
                    type: actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
                    payload: {
                        StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                        TypeOfUser: TypeOfUser
                    }
                });
            };
        };
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            setButtonChange(true);
            dispatch({
                type: actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
                payload: {
                    StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
                    TypeOfUser: TypeOfUser
                }
            });
        } else {
            dispatch({
                type: actions.GET_HELPDESK_WORK_HOURS_WITH_DATE_RANGE,
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: moment().format('DD-MM-YYYY'), TypeOfUser: TypeOfUser }
            });
        }
    };

    const handleExport = () => {
        if (filteredData?.length > 0) {
            const Statement = [
                [
                    "User ID",
                    "User Name",
                    "Clock In At",
                    "Clock Out At",
                    "Worked Hours",
                ],
                [
                    " ", " ", " ", " ", " "
                ],
                ...filteredData?.map(details =>
                    [
                        details.InternalUser_ID,
                        details.InternalUserName,
                        moment(details.LastLogin_At, 'DD-MM-YYYY hh:mm A').format('DD MMM YYYY hh:mm a'),
                        moment(details.LastLogout_At, 'DD-MM-YYYY hh:mm A').format('DD MMM YYYY hh:mm a'),
                        (details.Hours === 0 && details.Minutes === 0) ? "0 min" : ((details.Hours === 0 ? '' : details.Hours === 1 ? details.Hours + ' hr' : details.Hours + ' hrs') + ' ' + (details.Minutes === 0 ? '' : details.Minutes === 1 ? details.Minutes + ' min' : details.Minutes + ' mins'))
                    ]
                ),
            ]
            Statement.reduce((prev, curr) => [prev, curr]);
            const data = Statement;
            let fileName;
            if (startDate && endDate) {
                fileName = `${TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk' : TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk' : TypeOfUser} Work Hours Report for ${moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} - ${moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}.csv`
            } else {
                fileName = `${TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk' : TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk' : TypeOfUser} Work Hours Report for ${moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') || moment().format('DD-MM-YYYY')}.csv`
            };
            setExportStatement({ Statement: data, filename: fileName });
        }
    };

    useEffect(() => {
        (HelpdeskWorkHours.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [HelpdeskWorkHours]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} TypeOfUser={TypeOfUser} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>{TypeOfUser === 'Helpdesk C' ? 'Customer Helpdesk' : 'Vendor Helpdesk'} Work Hours Report</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate || ""}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate || ""}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-4">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-4">
                                                    {filteredData?.length === 0 ?
                                                        <button className="btn btn-success add-button rounded export-report text-white font-weight-bold" disabled type="submit"> EXPORT REPORT</button> :
                                                        <button className="btn btn-success add-button rounded export-report" type="submit">
                                                            <CSVLink
                                                                data={exportStatement?.Statement}
                                                                filename={exportStatement?.filename}
                                                                className="text-white font-weight-bold"
                                                                target="_blank"
                                                                onClick={handleExport}
                                                            >
                                                                EXPORT REPORT
                                                            </CSVLink>
                                                        </button>
                                                    }
                                                </div>
                                                <div className="col-sm-12 col-md-4 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input type="search" className="form-control form-control-sm" placeholder="search Id/Status..." onChange={handleSearchbar} value={search} aria-controls="data-table-4" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">User ID</th>
                                                                <th scope="col">User Name</th>
                                                                <th scope="col">Clock In At</th>
                                                                <th scope="col">Clock Out At</th>
                                                                <th scope="col">Worked Hours</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{val.InternalUser_ID}</th>
                                                                        <td>{val.InternalUserName}</td>
                                                                        <td>{val.LastLogin_At}</td>
                                                                        <td>{val.LastLogout_At}</td>
                                                                        {val.Hours === 0 && val.Minutes === 0 ? <td>0 min</td> :
                                                                            <td>
                                                                                {val.Hours === 0 ? '' : val.Hours === 1 ? val.Hours + ' hr' : val.Hours + ' hrs'} {val.Minutes === 0 ? '' : val.Minutes === 1 ? val.Minutes + ' min' : val.Minutes + ' mins'}
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};