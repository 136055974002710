import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom/esm/react-router-dom";
import CustomizecakeActions from '../../../redux/Customize-Cake/actions';
import TicketsActions from '../../../redux/Tickets/actions';
import FlavourList from "../../sections/ArrayMapping/FlavourList";
import ImageSlider from "../../sections/Customize-Cake-Details/ImageSlider";
import CircleDotSpinner from "../../common/CircleDotSpinner";
import Preloader from "../../layouts/Preloader";
import Breadcrumb from "./Breadcrumb";
import { useForm } from "react-hook-form";
import { float2D } from "../../../utils/Validation";

export default function Content(props) {

    const { Page } = props;

    const state = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const Id = state.state.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showTicketRaisePopup, setShowTicketRaisePopup] = useState(false);
    const [loader, setLoader] = useState(false);

    const { CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    const { Ticket_Response } = useSelector(state => state.TicketReducer);

    const auth = useSelector((state) => state.authReducer);

    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch({ type: CustomizecakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        //eslint-disable-next-line
    }, [Id]);

    const handleClose = (e) => {
        e.preventDefault();
        history.push('/Helpdesk-C-Direct-Customized-Cake-Orders-List');
    };

    const handleRaiseTicketPopup = (e) => {
        e.preventDefault();
        setShowTicketRaisePopup(true);
    };

    const handleCloseTicketRaisePopup = () => {
        setShowTicketRaisePopup(false);
        reset();
    };

    const handleRaiseTicket = (data) => {
        let formData, Accepted_By, Ticket_Raised_For, Last_Intimate;
        (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
            Accepted_By = 'HelpdeskC_Accepted_By' : Accepted_By = 'HelpdeskV_Accepted_By';
        (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
            Ticket_Raised_For = 'Customer' : Ticket_Raised_For = 'Vendor';
        // (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ?
        //     Last_Intimate = ['HelpdeskC'] : Last_Intimate = ['HelpdeskV'];
        Last_Intimate = ['HelpdeskV', 'Manager'];
        let Ticket_Accessed_By = { HelpdeskV: 'y', HelpdeskC: 'y', Manager: 'y', Management: 'n', Accounts: 'n' }
        formData = {
            TypeOfUser: auth?.token?.result?.TypeOfUser,
            CauseOfTicket: 'Fully Customized Cake',
            TicketDescription: data.TicketDescription,
            TypeOfOrder: 'Customized Cake Order',
            OrderID: CustomizedCakeDeatils?._id,
            Order_ID: CustomizedCakeDeatils?.Id,
            Order_Status: CustomizedCakeDeatils?.Status,
            UserID: CustomizedCakeDeatils?.UserID,
            User_ID: CustomizedCakeDeatils?.User_ID,
            Ticket_Raised_For: Ticket_Raised_For,
            Last_Intimate: Last_Intimate,
            Ticket_Status: 'In Progress',
            Ticket_Raised_By: {
                ID: auth?.token?.result?._id,
                Name: auth?.token?.result?.Name,
                Email: auth?.token?.result?.Email,
                TypeOfUser: auth?.token?.result?.TypeOfUser
            },
            [Accepted_By]: {
                ID: auth?.token?.result?._id,
                Name: auth?.token?.result?.Name,
                Email: auth?.token?.result?.Email,
                TypeOfUser: auth?.token?.result?.TypeOfUser
            },
            Ticket_Accessed_By: Ticket_Accessed_By,
            // Ticket_Accessed_By: (auth?.token?.result?.TypeOfUser === 'Helpdesk C') ? {
            //     HelpdeskV: 'n', HelpdeskC: 'y', Manager: 'n', Management: 'n', Accounts: 'n'
            // } : {
            //     HelpdeskV: 'y', HelpdeskC: 'n', Manager: 'n', Management: 'n', Accounts: 'n'
            // }
        };
        setLoader(true);
        dispatch({ type: TicketsActions.RAISE_NEW_TICKET, payload: formData });
    };

    useEffect(() => {
        if (Ticket_Response.statusCode === 400 || Ticket_Response.statusCode === 200) {
            setLoader(false);
            setShowTicketRaisePopup(false);
            dispatch({ type: CustomizecakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        } 
        // else if (Ticket_Response.statusCode === 200) {
        //     setLoader(false);
        //     setShowTicketRaisePopup(false);
        //     dispatch({ type: CustomizecakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        // };
        // eslint-disable-next-line 
    }, [Ticket_Response]);

    useEffect(() => {
        (CustomizedCakeDeatils.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CustomizedCakeDeatils]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} />
                        </div>
                        {CustomizedCakeDeatils?.Images === undefined || CustomizedCakeDeatils?.Images?.length === 0 ?
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header tabledata-background">
                                        <h6>Customised cake Order Details</h6>
                                    </div>
                                </div>
                            </div> :
                            <div className="col-md-12">
                                <div className="ms-panel tabledata-background">
                                    <div className="ms-panel-header">
                                        <h6>CUSTOMISED CAKE ORDER DETAILS</h6>
                                    </div>

                                    <div className="ms-panel-body order-image">
                                        <ImageSlider image={CustomizedCakeDeatils?.Images} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CustomizedCakeDeatils?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td>{CustomizedCakeDeatils?.CakeName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{CustomizedCakeDeatils?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Order Date</th>
                                                <td>{CustomizedCakeDeatils?.Created_On}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6  col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{CustomizedCakeDeatils?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CustomizedCakeDeatils?.Status?.toUpperCase())?.replace(/ /g, '')}>{CustomizedCakeDeatils?.Status}</span></td>
                                            </tr>
                                            {CustomizedCakeDeatils?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {CustomizedCakeDeatils?.Theme &&
                                                <tr>
                                                    <th scope="row">Theme Name</th>
                                                    <td>{CustomizedCakeDeatils?.Theme}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.Tier &&
                                                <tr>
                                                    <th scope="row">Tier</th>
                                                    <td>{CustomizedCakeDeatils?.Tier} Tier</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><FlavourList FlavoursList={CustomizedCakeDeatils?.Flavour} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shape</th>
                                                <td>{CustomizedCakeDeatils?.Shape}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Weight</th>
                                                <td>{CustomizedCakeDeatils?.Weight}</td>
                                            </tr>
                                            {CustomizedCakeDeatils?.MessageOnTheCake &&
                                                <tr>
                                                    <th scope="row">Message on the Cake</th>
                                                    <td>{CustomizedCakeDeatils?.MessageOnTheCake}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.SpecialRequest &&
                                                <tr>
                                                    <th scope="row">Special Request</th>
                                                    <td>{CustomizedCakeDeatils?.SpecialRequest}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {(CustomizedCakeDeatils?.Status === "Price Approved" || CustomizedCakeDeatils?.Status === "Sent" || CustomizedCakeDeatils?.Status === "Ordered") &&

                            <>
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="section-title bold">VENDOR PRICE INFO</h4>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Product Count</th>
                                                        <td>{CustomizedCakeDeatils?.ItemCount || 1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Price per Kg</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Vendor_Price || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td>{CustomizedCakeDeatils?.Weight}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Extra Charges</th>
                                                        {CustomizedCakeDeatils?.ExtraCharges === '0' || CustomizedCakeDeatils?.ExtraCharges === undefined ?
                                                            <td>0.00</td> :
                                                            <td><span>{float2D(CustomizedCakeDeatils?.ExtraCharges || 0)}</span></td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Discount</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Discount || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Delivery Charge</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.DeliveryCharge || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td>
                                                            <span><b>{float2D(CustomizedCakeDeatils?.Vendor_Total || 0)}</b></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="section-title bold">PRICE INFO</h4>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Product Count</th>
                                                        <td>{CustomizedCakeDeatils?.ItemCount || 1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Price per Kg</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Price || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td>{CustomizedCakeDeatils?.Weight}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Extra Charges</th>
                                                        {CustomizedCakeDeatils?.ExtraCharges === '0' || CustomizedCakeDeatils?.ExtraCharges === undefined ?
                                                            <td>0.00</td> :
                                                            <td><span>{float2D(CustomizedCakeDeatils?.ExtraCharges || 0)}</span></td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Discount</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Discount || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Delivery Charge</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.DeliveryCharge || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">CGST</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Gst || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">SGST</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Sgst || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td>
                                                            <span><b>{float2D(parseFloat(CustomizedCakeDeatils?.Total || 0))}</b></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{CustomizedCakeDeatils?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{CustomizedCakeDeatils?.UserName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Mobile Number</th>
                                                <td>{CustomizedCakeDeatils?.UserPhoneNumber}</td>
                                            </tr>
                                            {CustomizedCakeDeatils?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{CustomizedCakeDeatils?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.Ticket_Raised === 'n' &&
                                                <button
                                                    className="btn btn-primary float-start mr-3"
                                                    type="submit"
                                                    disabled={CustomizedCakeDeatils?.Status === 'Cancelled' ? true : false}
                                                    onClick={handleRaiseTicketPopup}
                                                >
                                                    {(CustomizedCakeDeatils?.Ticket_Raised === 'n' && CustomizedCakeDeatils?.Status !== 'Cancelled') ? 'Raise Ticket' : 'Order Cancelled'}
                                                </button>
                                            }
                                            {!CustomizedCakeDeatils?.VendorID &&
                                                <button
                                                    className="btn btn-secondary float-start"
                                                    type="submit"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CustomizedCakeDeatils?.VendorID &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">VENDOR INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td>{CustomizedCakeDeatils?.Vendor_ID}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td>{CustomizedCakeDeatils?.VendorName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Main Mobile Number</th>
                                                    <td>{CustomizedCakeDeatils?.VendorPhoneNumber1}</td>
                                                </tr>
                                                {CustomizedCakeDeatils?.VendorPhoneNumber2 &&
                                                    <tr>
                                                        <th scope="row">Vendor Alternative Mobile Number</th>
                                                        <td>{CustomizedCakeDeatils?.VendorPhoneNumber2}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Vendor Address</th>
                                                    <td><span className="long-line-break">{CustomizedCakeDeatils?.VendorAddress}</span></td>
                                                </tr>
                                                {CustomizedCakeDeatils?.GoogleLocation &&
                                                    <tr>
                                                        <th scope="row">Google Location</th>
                                                        <td>
                                                            <b>Latitude</b> : {CustomizedCakeDeatils?.GoogleLocation?.Latitude} <br />
                                                            <b>Longitude</b> : {CustomizedCakeDeatils?.GoogleLocation?.Longitude}
                                                        </td>
                                                    </tr>
                                                }
                                                <button
                                                    className="btn btn-secondary float-start"
                                                    type="submit"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        <Modal className="modal-min" show={showTicketRaisePopup} onHide={handleCloseTicketRaisePopup} aria-labelledby="contained-modal-title-vcenter"
                            centered contentClassName="modal-body-scrollar">
                            <Modal.Body className="tabledata-background">
                                <button type="button" className="close" onClick={handleCloseTicketRaisePopup}><span aria-hidden="true">×</span></button>
                                <h1 className="text-center pb-2">Raise Ticket</h1>
                                <div>
                                    <div className="col-xl-12 col-lg-12 col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Ticket Description</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <textarea
                                                className={errors.TicketDescription ? 'form-control required-field text-capitalize' : 'form-control text-capitalize'}
                                                placeholder='Type here...'
                                                {...register('TicketDescription', { required: { value: true, message: 'required' } })}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-success text-center intimation-message">This will be auto intimated to Vendor Helpdesk and Manager</p>
                                    </div>
                                    {loader ?
                                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary shadow-none mr-3"
                                                onClick={handleSubmit(handleRaiseTicket)}
                                            >
                                                Raise Ticket
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary shadow-none"
                                                onClick={handleCloseTicketRaisePopup}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    }
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            }
        </>
    )
}