import React from 'react';
import Sidenavigation from '../../Accounts/Layouts/Sidenavigation';
import Topnavigation from '../../Accounts/Layouts/Topnavigation';
// import Payment from './Payment';
import RefundPayment from './RefundPayment';
import { Helmet } from "react-helmet";

export default function Content() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Payment</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <RefundPayment Page='Accounts' />
                {/* <Payment Page='Accounts' /> */}
                {/* page content end  */}

            </main>

        </div>
    );
};