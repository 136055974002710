import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Tickets/actions';
import commonActions from '../../../redux/Common/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import ReactPagination from '../../common/React-Pagination';

export default function Content(props) {

    const { Page } = props;

    // const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();
    const history = useHistory();

    const { TicketsListByTypeOfUser } = useSelector(state => state.TicketReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const auth = useSelector((state) => state.authReducer);

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);

    let [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'tickets' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    let [endDate, setEndDate] = useState((Filtered_Dates?.Page === 'tickets' && moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ?moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

    const TypeOfUser = auth?.token?.result?.TypeOfUser;

    useEffect(() => {
        if (TypeOfUser) {
            dispatch({
                type: actions.GET_TICKETS_LIST_BY_TYPE_OF_USER,
                // payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: '25-11-2022', EndDate: moment().format('DD-MM-YYYY') }
                payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: ((Filtered_Dates?.Page === 'tickets' && Filtered_Dates?.StartDate) ? Filtered_Dates?.StartDate : moment().format('DD-MM-YYYY')), EndDate: ((Filtered_Dates?.Page === 'tickets' && Filtered_Dates?.EndDate) ? Filtered_Dates?.EndDate : 'today') }
            });
        };
        if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate && Filtered_Dates?.Page === 'tickets') {
            setButtonChange(true);
        };
        //eslint-disable-next-line
    }, [TypeOfUser, Filtered_Dates]);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (TicketsListByTypeOfUser?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(TicketsListByTypeOfUser.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.CauseOfTicket && val.CauseOfTicket.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_On && val.Ticket_Raised_On.toLowerCase().includes(search)) ||
                    (val.Ticket_Raised_By.Name && val.Ticket_Raised_By.Name.toLowerCase().includes(search)) ||
                    (val.TypeOfUser && val.TypeOfUser.toLowerCase().includes(search)) ||
                    (val.Ticket_Status && val.Ticket_Status.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [TicketsListByTypeOfUser, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        if (buttonChange) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({
                type: actions.GET_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: moment().format('DD-MM-YYYY'), EndDate: 'today' }
            });
        } else {
            if (startDate && endDate) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'tickets' } });
                setButtonChange(true);
                dispatch({
                    type: actions.GET_TICKETS_LIST_BY_TYPE_OF_USER,
                    payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
                });
            };
        };
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        if (startDate && endDate) {
            dispatch({
                type: actions.GET_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
            });
        } else {
            dispatch({
                type: actions.GET_TICKETS_LIST_BY_TYPE_OF_USER,
                payload: { TypeOfUser: TypeOfUser.replace(/ /g, ''), StartDate: moment().format('DD-MM-YYYY'), EndDate: 'today' }
            });
        };
    };

    const ViewDetails = (e, details) => {
        e.preventDefault();
        let Path = (Page === 'HelpdeskC') ? '/Helpdesk-C-Ticket-Details' :
            (Page === 'Manager') ? '/Manager-Ticket-Details' : Page === 'HelpdeskV' ? "/helpdeskV-Ticket-Details" :
                '/Management-Ticket-Details';

        history.push(Path, { Id: details._id, Page: Page, Path: 'Tickets' });
    };

    // useEffect(() => {
    //     return (() => {
    //         if (pathName !== '/Helpdesk-C-Tickets-List') {
    //             dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null } });
    //         }
    //     });
    // }, []);

    useEffect(() => {
        (TicketsListByTypeOfUser.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [TicketsListByTypeOfUser]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Tickets List</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate || ''}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {/* <h6>Orders List</h6> */}
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="search Id/Status..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Ticket ID</th>
                                                                <th scope="col">Cause of Ticket</th>
                                                                <th scope="col">Ticket Raised On</th>
                                                                <th scope="col">Ticket Raised By</th>
                                                                <th scope="col">Ticket Raised For</th>
                                                                <th scope="col">Ticket Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData?.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr
                                                                        className={(cake.Last_Intimate?.includes(Page?.replace(/ /g, '')) && cake.Ticket_Status !== 'Closed') ?
                                                                            "odd cursor font-weight-bold" : "odd cursor"}
                                                                        key={cake._id}
                                                                        onClick={(e) => ViewDetails(e, cake)}
                                                                    >
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CauseOfTicket === 'Fully Customized Cake' ? 'Fully Customised Cake':
                                                                        cake.CauseOfTicket === 'Customized Cake through Vendor' ? 'Customised Cake through Vendor':
                                                                        cake.CauseOfTicket === 'Cakey Direct-Fully Customized Cake' ? 'Cakey Direct-Fully Customised Cake' :
                                                                        cake.CauseOfTicket
                                                                        }</td>
                                                                        <td>{cake.Ticket_Raised_On}</td>
                                                                        <td>{cake.Ticket_Raised_By.Name}</td>
                                                                        <td>{cake.Ticket_Raised_For}</td>
                                                                        <td className='price-align3-row'><span className={(cake.Ticket_Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Ticket_Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData?.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};