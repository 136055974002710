import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {

    const { page } = props;

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/Dashboard"><IoMdHome className='home' /> Home</Link></li>
                <li className="breadcrumb-item"><Link to={page === 'Admin' ? "/new-cakes-list" : "/helpdeskV-New-Cakes-List"}>Cakes List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Cake&apos;s Details</li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;