import { takeEvery, call, put, all } from 'redux-saga/effects';
import axios from 'axios';
import actions from './actions'
import { API_URL } from '../../utils/constants';

const CatagoryListSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ALL_CATEGORIES, getAllCatagories),
        yield takeEvery(actions.ADD_NEW_CATEGORY, addNewCategory),
        yield takeEvery(actions.ADD_NEW_SUBCATEGORY, addNewSubCategory),
        yield takeEvery(actions.DELETE_CATEGORY, deleteCategory),
        yield takeEvery(actions.DELETE_SUBCATEGORY, deleteSubCategory),
        yield takeEvery(actions.UPDATE_CATEGORY, UpdateCategory),

    ]);
};

//get all category list
const getAllCatagories = function* () {

    try {

        const result = yield call(() =>
            axios.get(`${API_URL}/category/list`)
        );

        yield put({
            type: actions.SET_ALL_CATEGORIES,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data)
    }
};

//add new category
const addNewCategory = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.post(`${API_URL}/category/new`, payload)
        );

        yield put({
            type: actions.ADD_CATEGORY_STATUS,
            payload: { AddCategoryStatus: result.data }
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

//add new subcateory
const addNewSubCategory = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(`${API_URL}/subcategory/new`, payload)
        );

        yield put({
            type: actions.ADD_SUBCATEGORY_STATUS,
            payload: { AddSubCategoryStatus: result.data }
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

//delete category
const deleteCategory = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.delete(`${API_URL}/category/delete/${payload}`)
        );

        yield put({
            type: actions.REMOVE_CATEGORY,
            payload: { RemoveCategoryStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    }
};

//delete subcategory
const deleteSubCategory = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(`${API_URL}/subcategory/delete/${payload.id}`, {
                Category: payload.Category
            })
        );

        yield put({
            type: actions.REMOVE_SUBCATEGORY,
            payload: { RemoveSubCategoryStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    }
};

//update category
const UpdateCategory = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(`${API_URL}/category/update/${payload.id}`, {
                Category: payload.Category
            })
        );

        yield put({
            type: actions.UPDATE_CATEGORY_STATUS,
            payload: { UpdateCategoryStatus: result.data }
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

export default CatagoryListSaga;