import actions from "./actions";

const initialState = {
    AllOrdersList: [],
    AllOrderWithDelivered: [],
    Ticket_Response: [],
    TicketsListByTypeOfUser: [],
    TicketDetails: [],
    UnclosedTicketsListByTypeOfUser: [],
    DailyUnclosedTicketsCount: [],
    DailyTicketReport: [],
    AllTicketsByEmail: [],
    OrderDetailsByTypeOfOrder: [],
    RejectedOrders: [],
    DashboardTicketsByTypeOfUser: [],
    AllTickets: [],
};

const TicketReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SET_ALL_ORDERS_LIST_BY_TYPE:
            return {
                ...state,
                AllOrdersList: action.payload
            }
        case actions.SET_ALL_ORDERS_LIST_WITH_DELIVERED_BY_TYPE:
            return {
                ...state,
                AllOrderWithDelivered: action.payload
            }
        case actions.TICKET_RESPONSE:
            return {
                ...state,
                Ticket_Response: action.payload
            }
        case actions.SET_TICKETS_LIST_BY_TYPE_OF_USER:
            return {
                ...state,
                TicketsListByTypeOfUser: action.payload
            }
        case actions.SET_TICKET_DETAILS:
            return {
                ...state,
                TicketDetails: action.payload
            }
        case actions.SET_UNCLOSED_TICKETS_LIST_BY_TYPE_OF_USER:
            return {
                ...state,
                UnclosedTicketsListByTypeOfUser: action.payload
            }
        case actions.SET_DAILY_UNCLOSED_TICKETS_COUNT:
            return {
                ...state,
                DailyUnclosedTicketsCount: action.payload
            }
        case actions.SET_DAILY_TICKET_REPORT_WITH_DATE_RANGE:
            return {
                ...state,
                DailyTicketReport: action.payload
            }
        case actions.SET_ALL_TICKETS_BY_EMAIL_AND_TYPEODUSER:
            return {
                ...state,
                AllTicketsByEmail: action.payload
            }
        case actions.SET_ORDER_DETAILS_BY_TYPEOFORDER:
            return {
                ...state,
                OrderDetailsByTypeOfOrder: action.payload
            }
        case actions.SET_REJECTED_ORDERS_LIST:
            return {
                ...state,
                RejectedOrders: action.payload
            }
        case actions.SET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER:
            return {
                ...state,
                DashboardTicketsByTypeOfUser: action.payload
            }
        case actions.SET_ALL_TICKETS_LIST:
            return {
                ...state,
                AllTickets: action.payload
            }
        default:
            return state;
    }
};

export default TicketReducer;