import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Vendor/actions';
import Preloader from '../../../layouts/Preloader';
import moment from 'moment';

export default function Content() {

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [date, setDate] = useState("");

    //get logged in users details
    const auth = useSelector((state) => state.authReducer);

    const Session = useSelector(state => state.VendorReducer);

    useEffect(() => {
        if (auth.token !== undefined) {
            const currentDate = moment().format('DD-MM-YYYY');
            setDate(moment().format('YYYY-MM-DD'));
            dispatch({ type: actions.GET_LOGIN_SESSION, payload: { id: auth?.token?.result?._id, date: currentDate } });
        }
        // eslint-disable-next-line
    }, [auth]);

    //validate the list is empty or not
    useEffect(() => {
        if (!Session.LoginSession) {
            setFilteredData([]);
        } else {
            setFilteredData(Session.LoginSession)
        };
    }, [Session]);

    const handleTableSearch = (e) => {
        setDate(e.target.value);
        const NewDate = e.target.value.split("-").reverse().join("-");
        if (auth?.token !== undefined) {
            dispatch({ type: actions.GET_LOGIN_SESSION, payload: { id: auth?.token?.result?._id, date: NewDate } });
        }
    };

    //loading
    useEffect(() => {
        if (Session.LoginSession.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };

    }, [Session]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Login History</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6"></div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Select Date :
                                                            <input type='date' maxLength={4} max={"9999-12-31"} className="form-control form-control-sm" value={date} aria-controls="data-table-4" onChange={handleTableSearch} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">S. No</th>
                                                                <th scope="col">Login Date</th>
                                                                <th scope="col">Login Time</th>
                                                                <th scope="col">Logout Date</th>
                                                                <th scope="col">Logout Time</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {filteredData.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{i+1}</th>
                                                                        <td>{val.Login_At.slice(0,10)}</td>
                                                                        <td>{val.Login_At.slice(11,20)}</td>
                                                                        <td>{val.Logout_At.slice(0,10)}</td>
                                                                        <td>{val.Logout_At.slice(11,20)}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <th scope="row" className="text-right">No Records Found</th>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};