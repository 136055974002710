import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/VendorOrderList/actions';
import Preloader from '../../layouts/Preloader';
import moment from 'moment';
import Breadcrumb from './Breadcrumb';

export default function Content() {

    const options = {
        responsive: true,
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Sales Count',
                    fontSize: 16,
                    fontColor: '#030',
                    fontStyle: 'bold'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Pincode',
                    fontSize: 16,
                    fontColor: '#030',
                    fontStyle: 'bold'
                }
            }]
        },
        legend: {
            labels: {
                fontSize: 20,
                fontColor: '#030',
                fontStyle: 'bold'
            }
        }
    }

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);

    let [data, setData] = useState();
    let [startDate, setStartDate] = useState(moment().subtract(1, "month").format('MM-YYYY'));
    let [endDate, setEndDate] = useState();
    let [filterType, setFilterType] = useState('month');

    const { SalesCountChartReport, VendorsPincodeList } = useSelector(state => state.VendorOrderReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_VENDORS_PINCODE_LIST });
        dispatch({
            type: actions.GET_SALES_COUNT_CHART_REPORT,
            payload: {
                StartDate: moment().subtract(1, "month").format('MM-YYYY'),
                EndDate: moment().subtract(1, "month").format('MM-YYYY'),
                Type: 'month'
            }
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (SalesCountChartReport?.message !== 'No Records Found' && VendorsPincodeList?.message !== 'No Records Found') {
            let list = {
                labels: VendorsPincodeList,
                datasets: SalesCountChartReport
            };
            setData(list);
        } else {
            setData();
        };
    }, [SalesCountChartReport, VendorsPincodeList]);

    const handleStartDate = (e) => {
        if (filterType === 'month') {
            setStartDate(moment(e.target.value, 'YYYY-MM').format('MM-YYYY'));
        } else {
            setStartDate(moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'));
        };
    };

    const handleEndDate = (e) => {
        if (filterType === 'month') {
            setEndDate(moment(e.target.value, 'YYYY-MM').format('MM-YYYY'));
        } else {
            setEndDate(moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'));
        };
    };

    const handleFilter = (e) => {
        e.preventDefault();
        if (filterType === 'month') {
            dispatch({
                type: actions.GET_SALES_COUNT_CHART_REPORT,
                payload: { StartDate: startDate, EndDate: endDate, Type: 'month' }
            });
        } else {
            dispatch({
                type: actions.GET_SALES_COUNT_CHART_REPORT,
                payload: { StartDate: startDate, EndDate: endDate, Type: 'date' }
            });
        };
    };

    const handleChangeFilterType = (e) => {
        setFilterType(e.target.value);
    };

    //loading
    useEffect(() => {
        (VendorsPincodeList.length === 0 || SalesCountChartReport.length === 0) ? setFetchSuccess(false) :
            setFetchSuccess(true);
    }, [VendorsPincodeList, SalesCountChartReport]);

    return (
        <div>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Sales Count Chart Based on Vendors Pincode</h6>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-4">
                                                    <select
                                                        className='form-control form-control-sm'
                                                        defaultValue='month'
                                                        onChange={handleChangeFilterType}
                                                    >
                                                        <option value='month'>Filter By Month</option>
                                                        <option value='date'>Filter By Date</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-12 col-md-8 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Select {filterType === 'month' ? 'Months' : 'Dates'} : </label>
                                                        <input
                                                            type={filterType === 'month' ? 'month' : 'date'}
                                                            max={filterType === 'month' ? moment().format('YYYY-MM') : moment().format('YYYY-MM-DD')}
                                                            className="form-control form-control-sm"
                                                            name='StartDate'
                                                            onChange={handleStartDate}
                                                            defaultValue={moment().subtract(1, "month").format('YYYY-MM')} />
                                                        <input
                                                            type={filterType === 'month' ? 'month' : 'date'}
                                                            max={filterType === 'month' ? moment().format('YYYY-MM') : moment().format('YYYY-MM-DD')}
                                                            min={filterType === 'month' ? moment(startDate, 'MM-YYYY').format('YYYY-MM') : moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')}
                                                            className="form-control form-control-sm"
                                                            name='EndDate'
                                                            onChange={handleEndDate}
                                                        />
                                                        <button
                                                            className='btn-success cursor filter-button ml-2'
                                                            disabled={(startDate && endDate) ? false : true}
                                                            onClick={handleFilter}
                                                        >
                                                            Filter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <Line
                                                data={data}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            }

        </div>
    )
};