import React from 'react';
import Breadcrumb from './Breadcrumb';
import FlavourList from '../../sections/ArrayMapping/FlavourList';
import { float2D } from '../../../utils/Validation';
import { ResponsiveEmbed } from 'react-bootstrap';

export default function CakeOrderDetails(props) {

    const { GetVendorOrderDetails, handleClose, Page } = props;

    return (
        <div className="row">
            <div className="col-md-12">
                <Breadcrumb Page={Page} />
            </div>
            <div className="col-md-12">
                <div className="ms-panel tabledata-background">
                    <div className="ms-panel-header">
                        <h6 >Order Details</h6>
                    </div>
                    {GetVendorOrderDetails?.Image &&
                        <div className="ms-panel-body order-image">
                            <ResponsiveEmbed aspectRatio='21by9'>
                                <img
                                    className="d-block align-center responsive-image-width"
                                    src={GetVendorOrderDetails?.Image}
                                    alt="cake"
                                />
                            </ResponsiveEmbed>
                        </div>
                    }
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel ms-panel-fh">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">CAKE INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                {(GetVendorOrderDetails?.ReasonForCancel && (GetVendorOrderDetails?.Status === 'Cancelled' || GetVendorOrderDetails?.Status === 'Rejected')) &&
                                    <tr className="text-danger">
                                        <th scope="row">Reason for Cancel</th>
                                        <td>{GetVendorOrderDetails?.ReasonForCancel}</td>
                                    </tr>
                                }
                                <tr>
                                    <th scope="row">Order ID</th>
                                    <td><b>{GetVendorOrderDetails?.Id}</b></td>
                                </tr>
                                <tr>
                                    <th scope="row">Cake ID</th>
                                    <td><b>{GetVendorOrderDetails?.Cake_ID}</b></td>
                                </tr>
                                <tr>
                                    <th scope="row">Cake Name</th>
                                    <td>{GetVendorOrderDetails?.CakeName ? GetVendorOrderDetails?.CakeName : 'Customised Cake'}</td>
                                </tr>
                                {GetVendorOrderDetails?.CakeCommonName &&
                                    <tr>
                                        <th scope="row">Cake Common Name</th>
                                        <td>{GetVendorOrderDetails?.CakeCommonName}</td>
                                    </tr>
                                }
                                <tr>
                                    <th scope="row">Egg or Eggless</th>
                                    <td>{GetVendorOrderDetails?.EggOrEggless}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Ordered Date</th>
                                    <td>{GetVendorOrderDetails?.Created_On}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Status</th>
                                    <td><span className={(GetVendorOrderDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{GetVendorOrderDetails?.Status}</span></td>
                                </tr>
                                {GetVendorOrderDetails?.Status_Updated_On !== undefined ?
                                    <tr>
                                        <th scope="row">Status Updated On</th>
                                        <td>{GetVendorOrderDetails?.Status_Updated_On}</td>
                                    </tr> :
                                    <tr>
                                        <th scope="row">Status Updated On</th>
                                        <td>{GetVendorOrderDetails?.Created_On}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">CAKE INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                {GetVendorOrderDetails?.Theme &&
                                    <tr>
                                        <th scope="row">Theme Name</th>
                                        <td>{GetVendorOrderDetails?.Theme}</td>
                                    </tr>
                                }
                                {GetVendorOrderDetails?.Tier &&
                                    <tr>
                                        <th scope="row">Tier</th>
                                        <td>{GetVendorOrderDetails?.Tier} Tier</td>
                                    </tr>
                                }
                                <tr>
                                    <th scope="row">Flavour</th>
                                    <td>
                                        <FlavourList
                                            FlavoursList={GetVendorOrderDetails?.Flavour}
                                        />
                                    </td>
                                </tr>
                                {GetVendorOrderDetails?.Shape?.Price === '0' ?
                                    <tr>
                                        <th scope="row">Shape</th>
                                        <td>{GetVendorOrderDetails?.Shape?.Name}</td>
                                    </tr> :
                                    <tr>
                                        <th scope="row">Shape</th>
                                        <td>{`${GetVendorOrderDetails?.Shape?.Name} - Additional Rs.${GetVendorOrderDetails?.Shape?.Price}`}</td>
                                    </tr>
                                }
                                {GetVendorOrderDetails?.MessageOnTheCake &&
                                    <tr>
                                        <th scope="row">Message on the Cake</th>
                                        <td>{GetVendorOrderDetails?.MessageOnTheCake}</td>
                                    </tr>
                                }
                                {GetVendorOrderDetails?.SpecialRequest &&
                                    <tr>
                                        <th scope="row">Special Request</th>
                                        <td>{GetVendorOrderDetails?.SpecialRequest}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {GetVendorOrderDetails?.Toppers?.TopperName ?
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">TOPPER INFO</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Topper Name</th>
                                        <td>{GetVendorOrderDetails?.Toppers?.TopperName}</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope="row">Topper Price</th>
                                        <td>Rs. {float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                        <div className="ms-panel-body tabledata-background">
                            <ResponsiveEmbed aspectRatio='21by9'>
                                <img
                                    className="d-block align-center w-50 h-50 responsive-image-width"
                                    src={GetVendorOrderDetails?.Toppers?.TopperImage}
                                    alt="First slide"
                                />
                            </ResponsiveEmbed>
                        </div>
                    </div>
                </div> : <></>
            }
            {GetVendorOrderDetails?.CustomizeCake === 'y' &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">VENDOR PRICE INFO</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Product Count</th>
                                        <td>{GetVendorOrderDetails?.ItemCount}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price per Kg</th>
                                        <td><span>{float2D(GetVendorOrderDetails?.NewPrice ? GetVendorOrderDetails?.NewPrice : GetVendorOrderDetails?.Vendor_Price)}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{GetVendorOrderDetails?.Weight}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Extra Charges</th>
                                        {GetVendorOrderDetails?.ExtraCharges === '0' || GetVendorOrderDetails?.ExtraCharges === undefined ?
                                            <td>0.00</td> :
                                            <td><span>{float2D(GetVendorOrderDetails?.NewExtraCharges ? GetVendorOrderDetails?.NewExtraCharges : GetVendorOrderDetails?.ExtraCharges)}</span></td>
                                        }
                                    </tr>
                                    {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                        <tr>
                                            <th scope="row">Topper Price</th>
                                            <td>{float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td><span>{float2D(GetVendorOrderDetails?.NewDiscount ? GetVendorOrderDetails?.NewDiscount : GetVendorOrderDetails?.Discount)}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td><span>{float2D(GetVendorOrderDetails?.NewDeliveryCharges ? GetVendorOrderDetails?.NewDeliveryCharges : GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                    </tr>
                                    {GetVendorOrderDetails?.TypeOfCustomizedCake === 'Cakey' ?
                                        <tr>
                                            <th scope="row">Total</th>
                                            <td><span><b>{float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span></td>
                                        </tr>
                                        :
                                        <tr>
                                            <th scope="row">Total</th>
                                            <td><span><b>{GetVendorOrderDetails?.NewTotal ? float2D(parseFloat(GetVendorOrderDetails?.NewTotal) - parseFloat(GetVendorOrderDetails?.NewTax)) : float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span></td>
                                        </tr>
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel ms-panel-fh">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">PRICE INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                <tr>
                                    <th scope="row">Product Count</th>
                                    <td>{GetVendorOrderDetails?.ItemCount}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Price</th>
                                    <td><span>{float2D(GetVendorOrderDetails?.NewPrice ? GetVendorOrderDetails?.NewPrice : GetVendorOrderDetails?.Price)}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row">Weight</th>
                                    <td>{GetVendorOrderDetails?.Weight}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Extra Charges</th>
                                    {GetVendorOrderDetails?.ExtraCharges === '0' || GetVendorOrderDetails?.ExtraCharges === undefined ?
                                        <td>0.00</td> :
                                        <td><span>{float2D(GetVendorOrderDetails?.NewExtraCharges ? GetVendorOrderDetails?.NewExtraCharges : GetVendorOrderDetails?.ExtraCharges)}</span>
                                        </td>
                                    }
                                </tr>
                                {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                    <tr>
                                        <th scope="row">Topper Price</th>
                                        <td>{float2D(GetVendorOrderDetails?.NewTopperCharges) ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                    </tr>
                                }
                                {GetVendorOrderDetails?.TypeOfCustomizedCake === 'Cakey' &&
                                    <tr>
                                        <th scope="row">Margin</th>
                                        <td><span>{float2D(GetVendorOrderDetails?.FinalMargin)}</span></td>
                                    </tr>

                                }
                                <tr>
                                    <th scope="row">Discount</th>
                                    <td><span>{float2D(GetVendorOrderDetails?.NewDiscount ? GetVendorOrderDetails?.NewDiscount : GetVendorOrderDetails?.Discount)}</span>
                                    </td>
                                </tr>
                                {(GetVendorOrderDetails?.CouponValue && !GetVendorOrderDetails?.NewTotal) &&
                                    <tr>
                                        <th scope="row">Coupon Value</th>
                                        <td>{float2D(GetVendorOrderDetails?.CouponValue)}</td>
                                    </tr>
                                }
                                <tr>
                                    <th scope="row">Delivery Charge</th>
                                    <td><span>{float2D(GetVendorOrderDetails?.NewDeliveryCharges ? GetVendorOrderDetails?.NewDeliveryCharges : GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row">CGST</th>
                                    <td><span>{float2D(GetVendorOrderDetails?.NewTax ? (GetVendorOrderDetails?.NewTax / 2) : GetVendorOrderDetails?.Gst)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">SGST</th>
                                    <td><span>{float2D(GetVendorOrderDetails?.NewTax ? (GetVendorOrderDetails?.NewTax / 2) : GetVendorOrderDetails?.Sgst)}</span></td>
                                </tr>
                                <tr>
                                    <th scope="row">Total</th>
                                    <td><span><b>{float2D(GetVendorOrderDetails?.NewTotal ? GetVendorOrderDetails?.NewTotal : GetVendorOrderDetails?.Total)}</b></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {GetVendorOrderDetails?.FinalCakeImage &&
                <div className="col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h6 className='text-uppercase font-weight-bold'>FINAL CAKE IMAGE</h6>
                        </div>
                        <div className='header-break-line'> <hr /> </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body order-image tabledata-background">
                                <ResponsiveEmbed aspectRatio='21by9'>
                                    <img
                                        className="d-block align-center responsive-image-width"
                                        src={GetVendorOrderDetails?.FinalCakeImage}
                                        alt="Cake"
                                    />
                                    </ResponsiveEmbed>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">DELIVERY INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                <tr>
                                    <th scope="row">Delivery Information</th>
                                    <td>{GetVendorOrderDetails?.DeliveryInformation}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Delivery Date</th>
                                    <td>{GetVendorOrderDetails?.DeliveryDate}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Delivery Session</th>
                                    <td>{GetVendorOrderDetails?.DeliverySession}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment Type</th>
                                    <td>{GetVendorOrderDetails?.PaymentType}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Payment Status</th>
                                    <td>{GetVendorOrderDetails?.PaymentStatus}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">CUSTOMER INFO</h4>
                        <table className="table ms-profile-information">
                            <tbody>
                                <tr>
                                    <th scope="row">Customer ID</th>
                                    <td>{GetVendorOrderDetails?.User_ID}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Customer Name</th>
                                    <td>{GetVendorOrderDetails?.UserName}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Customer Mobile Number</th>
                                    <td>{GetVendorOrderDetails?.UserPhoneNumber}</td>
                                </tr>
                                {GetVendorOrderDetails?.DeliveryAddress &&
                                    <tr>
                                        <th scope="row">Delivery Address</th>
                                        <td>
                                            <span className="long-line-break">{GetVendorOrderDetails?.DeliveryAddress}</span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {GetVendorOrderDetails?.VendorID &&
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">VENDOR INFO</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Vendor ID</th>
                                        <td>{GetVendorOrderDetails?.Vendor_ID}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Vendor Name</th>
                                        <td>{GetVendorOrderDetails?.VendorName}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Vendor Main Mobile Number</th>
                                        <td>{GetVendorOrderDetails?.VendorPhoneNumber1}</td>
                                    </tr>
                                    {GetVendorOrderDetails?.VendorPhoneNumber2 &&
                                        <tr>
                                            <th scope="row">Vendor Alternative Mobile Number</th>
                                            <td>{GetVendorOrderDetails?.VendorPhoneNumber2}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Vendor Address</th>
                                        <td>
                                            <span className="long-line-break">{GetVendorOrderDetails?.VendorAddress}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Google Location</th>
                                        <td>
                                            <b>Latitude</b> : {GetVendorOrderDetails?.GoogleLocation?.Latitude} <br />
                                            <b>Longitude</b> : {GetVendorOrderDetails?.GoogleLocation?.Longitude}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            <div className="col-xl-12 col-md-12">
                <div className="ms-panel">
                    <div className="ms-panel-body tabledata-background">
                        <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                        <hr />
                        <p>{GetVendorOrderDetails?.Description}</p>
                        {/* <hr /> */}
                        <button
                            className="btn btn-secondary float-start"
                            type="submit"
                            onClick={handleClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};