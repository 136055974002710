/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Admin/actions';
import CommonActions from '../../../redux/Common/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import { MobilenumberValidation } from '../../../utils/Validation';
import Breadcrumb from './Breadcrumb';
import { useForm } from "react-hook-form";
import Select from 'react-select';

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    let [selectedTypeOfUser, setSelectedTypeOfUser] = useState();

    const { register, reset, handleSubmit, formState: { errors }, setValue } = useForm();

    const { loader, response } = useSelector(state => state.commonReducer);

    const TypeOfUsersList = [
        { label: 'Vendor Helpdesk', value: 'Helpdesk V' },
        { label: 'Customer Helpdesk', value: 'Helpdesk C' },
        { label: 'Management', value: 'Management' },
        { label: 'Accounts', value: 'Accounts' },
        { label: 'Manager', value: 'Manager' },
        { label: 'Screen O', value: 'Screen O' },
        { label: 'Screen D', value: 'Screen D' },
    ];

    const handleSelctTypeOfUser = (e) => {
        setSelectedTypeOfUser(e);
        setValue('TypeOfUser', e?.value);
    };

    //for add new helpdesk member
    const handleAdd = (data) => {
        dispatch({ type: CommonActions.SET_LOADER, payload: true });
        let savedata = {
            Name: data.Name,
            Email: data.Email,
            Mobilenumber: data.Mobilenumber,
            TypeOfUser: data.TypeOfUser
        }
        dispatch({ type: actions.SAVE_ADMIN_REGISTER_INITIAL_USER, payload: savedata });
    };

    //for display alert for add
    useEffect(() => {
        if (response.status === "success") {
            reset({ Name: "", Email: "", Mobilenumber: "", TypeOfUser: "" })
            setSelectedTypeOfUser();
        };
        //eslint-disable-next-line
    }, [response.status]);

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb />
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header"><h6>Add  Member</h6></div>
                        <div className="ms-panel-body">
                            <form className="needs-validation clearfix" noValidate="">
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Name</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={errors.Name?.message ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                {...register('Name', { required: { value: true, message: 'Name is Required' } })}
                                                placeholder="Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Email</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className={errors.Email?.message ? "form-control required-field text-lowercase" : "form-control text-lowercase"}
                                                {...register('Email', {
                                                    required: 'Enter your e-mail',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'Enter a valid e-mail address',
                                                    }
                                                })}
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Mobile Number</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                onInput={MobilenumberValidation}
                                                className={errors.Mobilenumber?.message ? "form-control required-field" : "form-control"}
                                                {...register('Mobilenumber', { required: { value: true, message: 'Mobilenumber is Required' } })}
                                                placeholder="Mobile Number"
                                                maxLength={10}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-5">
                                        <label htmlFor="validationCustom18"><b>Type of User</b> <span className='text-danger'>*</span></label>
                                        <Select
                                            className={errors.TypeOfUser?.message ? "text-capitalize required-field" : "text-capitalize"}
                                            styles={customStyles}
                                            options={TypeOfUsersList}
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            {...register('TypeOfUser', { required: { value: true, message: 'Type Of User is Required' } })}
                                            onChange={handleSelctTypeOfUser}
                                            value={selectedTypeOfUser || ''}
                                        />
                                        {/* <div className="input-group">
                                            <select
                                                type="text"
                                                className={errors.TypeOfUser?.message ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                                {...register('TypeOfUser', { required: { value: true, message: 'Type Of User is Required' } })}
                                                placeholder="Type Of User"
                                            >
                                                <option value=""> select User Type</option>
                                                <option value="Helpdesk V">Helpdesk V</option>
                                                <option value="Helpdesk C">Helpdesk C</option>
                                                <option value="Management">Management</option>
                                                <option value="Accounts">Accounts</option>
                                                <option value="Manager">Manager</option>
                                                <option value="Screen O">Screen O</option>
                                                <option value="Screen D">Screen D</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>
                                {loader ? <CircleDotSpinner /> :
                                    <>
                                        <button
                                            className="btn btn-primary mr-3"
                                            type="submit"
                                            onClick={handleSubmit(handleAdd)}
                                        >
                                            Add
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            type="submit"
                                            onClick={() => history.push('/Admin-InternalUsers-List')}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
