import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'HelpdeskC' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/Helpdesk-C-Dashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Raise Ticket</li>
                </ol> :
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Raise Ticket</li>
                </ol>
            }
        </nav>
    );
};

export default Breadcrumb;