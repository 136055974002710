import axios from "axios";
import { CUSTOMER_PUSH_MESSAGE_KEY, VENDOR_PUSH_MESSAGE_KEY } from "../../utils/constants";
import { NOTIFICATION_API } from "../../utils/notificationApi";

export const PushNotification = (props) => {

    const { body, registration_ids, title, type } = props;

    let Token;

    if (type === 'vendor') {
        Token = VENDOR_PUSH_MESSAGE_KEY;
    } else {
        Token = CUSTOMER_PUSH_MESSAGE_KEY;
    };

    var data = JSON.stringify({
        "registration_ids": registration_ids,
        "notification": {
            "title": title,
            "body": body
        }
    });

    const config = {
        method: 'post',
        url: NOTIFICATION_API,
        headers: {
            'Authorization': Token,
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios(config);
};