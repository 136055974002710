import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, NavLink } from 'react-bootstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import Scrollbar from 'react-perfect-scrollbar';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux';
import MenuLink from '../../common/MenuLink';
import actions from '../../../redux/Auth/actions';
import actions2 from '../../../redux/ForgotPassword/actions';
import helpdeskActions from '../../../redux/Helpdesk/actions';
import commonAction from '../../../redux/Common/actions';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import { Modal } from 'react-bootstrap';
import { FaKey, FaPowerOff, FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';
import { IoNotifications } from 'react-icons/io5';

export default function Topnavigation() {

    const dispatch = useDispatch();
    const history = useHistory();

    const pathName = useHistory().location.pathname;

    const PasswordR = useRef();
    const ConfirmPasswordR = useRef();

    const [show, setShow] = useState(false);
    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    let [chatNotification, setChatNotification] = useState([]);

    //get logged in users details
    const { token } = useSelector(state => state.authReducer);

    //get status for change password
    const Status = useSelector(state => state.forgotpasswordReducer);

    const { InternalUsersNotifications, ChatNotificationsList } = useSelector(state => state.HelpdeskReducer);

    const LoggedIn_UserId = token?.result?._id;
    const LoggedIn_UserEmail = token?.result?.Email;

    useEffect(() => {
        dispatch({
            type: helpdeskActions.GET_INTERNAL_USERS_NOTIFICATIONS,
            payload: { Id: LoggedIn_UserId, TypeOfUser: 'Helpdesk V' }
        });
        dispatch({ type: helpdeskActions.GET_CHAT_NOTIFICATIONS_LIST, payload: LoggedIn_UserEmail });
        //eslint-disable-next-line
    }, [LoggedIn_UserId, LoggedIn_UserEmail]);

    const addsidenavigation = () => {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    const topbaropen = () => {
        $('#ms-nav-options').toggleClass('ms-slide-down');
    };

    useEffect(() => {
        if (ChatNotificationsList?.length === 0) {
            setChatNotification([]);
        } else {
            if (ChatNotificationsList?.message === 'No Records Found') {
                setChatNotification([]);
            } else {
                setChatNotification(ChatNotificationsList);
            }
        };
    }, [ChatNotificationsList]);

    const handleReloadNotification = (e) => {
        e.preventDefault();
        dispatch({
            type: helpdeskActions.GET_INTERNAL_USERS_NOTIFICATIONS,
            payload: { Id: LoggedIn_UserId, TypeOfUser: 'Helpdesk V' }
        });
    };

    const handleReloadChatNotification = (e) => {
        e.preventDefault();
        dispatch({ type: helpdeskActions.GET_CHAT_NOTIFICATIONS_LIST, payload: token?.result?.Email });
    };

    const handleClickChatNotification = (value) => {
        dispatch({
            type: helpdeskActions.UPDATE_CHATWITH_MEMBER,
            payload: { TypeOfUser: "Helpdesk V", ChatWith: value.Sent_By, Sender: value?.Receiver_Email, Consersation_Id: value?.Consersation_Id }
        });
        let setCurrentConversation = {
            Created_By: value?.Sent_By, Created_On: value?.Sent_On,
            Members: [value?.Receiver_Email, value?.Sent_By], _id: value?.Consersation_Id
        }
        dispatch({ type: commonAction.SET_CURRENT_CONVERSATION, payload: setCurrentConversation });
        dispatch({ type: helpdeskActions.REMOVE_CHAT_NOTIFICATION_BY_ID, payload: { Consersation_Id: value?.Consersation_Id, Receiver_Email: value?.Receiver_Email } });
        if (pathName !== '/helpdeskV-Dashoard-Chat') {
            history.push('/helpdeskV-Dashoard-Chat');
        }
    };

    //for logout
    const logout = () => {
        dispatch({ type: actions.UPDATE_AUTH_DETAILS, payload: { isAuthenticated: false } });
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload();
    };

    //for show forget password popup
    const handleChangePassword = (e) => {
        e.preventDefault();
        setShow(true);
    };

    //for close popup
    const handleClose = () => {
        setShow(false);
    };

    //for change password
    const handleChange = (e) => {
        e.preventDefault();
        setLoader(true);
        setMessage('');
        if (PasswordR.current.value && ConfirmPasswordR.current.value) {
            if (PasswordR.current.value === ConfirmPasswordR.current.value) {
                if (token !== undefined) {
                    dispatch({
                        type: actions2.CHANGE_PASSWORD,
                        payload: { id: token.result._id, Password: PasswordR.current.value }
                    });
                    // setTimeout(() => { dispatch({ type: actions2.CHANGE_PASSWORD_STATUS, payload: { ChangePasswordStatus: [] } }); }, 5000);
                }
            } else {
                setMessage("Password does not match");
                setLoader(false);
            }
        } else {
            setMessage('*required');
            setLoader(false);
        }
    };

    const handleClickNotification = (e, value) => {
        e.preventDefault();
        dispatch({
            type: helpdeskActions.REMOVE_INTERNAL_USER_NOTIFICATION_BY_NOTIFICATIONID,
            payload: value._id
        });
        if (value.TicketID) {
            history.push('/HelpdeskV-Ticket-Details', { Id: value.TicketID, Page: 'HelpdeskV' });
        } else if (value.OrderID && value.NotificationType === 'cakeorderrejected') {
            history.push('/helpdeskV-Vendor-Rejected-Order-Details', { Id: value.OrderID, Type: 'Cake Order' });
        } else if (value.OrderID && value.NotificationType === 'cakeydirectnormalorder') {
            history.push('/helpdeskV-Cakey-Direct-General-Orderdetails', { Id: value.OrderID })
        } else if (value.OrderID && value.NotificationType === 'cakedirectcustomizedcake') {
            history.push('/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails', { Id: value.OrderID });
        } else if (value.CustomizedCakeID && value.NotificationType === 'vendorcustomizedcake') {
            history.push('/helpdeskV-CustomizedCake-Order-through-Vendor-Details', { Id: value.CustomizedCakeID });
        } else if (value.OrderID && value.NotificationType === 'productorderrejected') {
            history.push('/helpdeskV-Vendor-Rejected-Order-Details', { Id: value.OrderID, Type: 'Product Order' });
        } else if (value.ProductID && (value.NotificationType === 'newcake' || value.NotificationType === 'updatedcake')) {
            history.push('/helpdeskV-Cake-Details', { page: 'newcakeslist', cakeId: value.ProductID });
        } else if (value.ProductID && (value.NotificationType === 'newproduct' || value.NotificationType === 'updatedproduct')) {
            history.push('/helpdeskV-Other-Product-Details', { page: 'newcakeslist', Id: value.ProductID });
        } else if (value.ProductID && (value.NotificationType === 'newhamper' || value.NotificationType === 'updatedhamper')) {
            history.push('/helpdeskV-Hampers-Details', { id: value.ProductID });
        }
    };

    const handleMarkAllAssRead = (e) => {
        e.preventDefault();
        dispatch({
            type: helpdeskActions.REMOVE_ALL_INTERNAL_USER_NOTIFICATION,
            payload: { Id: LoggedIn_UserId, TypeOfUser: 'Helpdesk V' }
        });
    };

    //for show alert if password updated
    useEffect(() => {
        if (Status?.ChangePasswordStatus?.statusCode === 400) {
            setMessage(Status?.ChangePasswordStatus?.message);
            setLoader(false);
        } else if (Status?.ChangePasswordStatus?.statusCode === 200) {
            setLoader(false);
            setShow(false);
        };
        // eslint-disable-next-line
    }, [Status]);

    return (
        <>
            <nav className="navbar ms-navbar tabledata-background">
                <div className="ms-aside-toggler ms-toggler pl-0" onClick={addsidenavigation}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                <div className="logo-sn logo-sm ms-d-block-sm">
                    <MenuLink className="pl-0 ml-0 text-center navbar-brand mr-0" to="/"><img src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" /></MenuLink>
                </div>
                <ul className="ms-nav-list ms-inline mb-0" id="ms-nav-options">
                    <li className="ms-nav-item dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} className='top-nav-link'>
                                <BsChatDotsFill className="top-nav-chat-icons" onClick={handleReloadChatNotification} />
                                {chatNotification?.length === 0 ?
                                    <></> :
                                    <span className='Chat-Notification'>
                                        <span className="badge badge-success style-badge chat-badge">.</span>
                                    </span>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notificationDropdown">
                                <div className="dropdown-menu-header">
                                    <h6 className="text-center mt-3 mb-3"><span className="text-disabled">Chat Notifications</span></h6>
                                </div>
                                <div className="dropdown-divider" />
                                <Scrollbar className="ms-scrollable ms-dropdown-list mb-3">
                                    {chatNotification?.length === 0 ?
                                        <Link to="#">
                                            <p className="text-center mt-4"><b>No Messages </b></p>
                                        </Link> :
                                        <>
                                            {chatNotification?.map((val, i) =>
                                                <div key={i}>
                                                    <div className="media cursor" onClick={() => handleClickChatNotification(val)}>
                                                        {/* <img className="mr-3" src='https://res.cloudinary.com/mindmadetech/image/upload/v1677319826/pngtree-vector-chat-icon-png-image_323830_zmn5rb.jpg' alt="notification" /> */}
                                                        <img className="mr-3" src='https://sugitechnologies.com/images/AppImages/pngtree-vector-chat-icon-png-image_323830_zmn5rb.jpg' alt="notification" />
                                                        <div className="media-body">
                                                            <p className="mt-0 mb-1 font-weight-bold">
                                                                {/* {val.Sent_By} */}
                                                                {val.SenderName}
                                                            </p>
                                                            <span className='notification-time'>You have {val.Count} unseen messages</span>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )}
                                        </>
                                    }
                                </Scrollbar>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="ms-nav-item dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} className='top-nav-link'>
                                <IoNotifications className="top-nav-icons" onClick={handleReloadNotification} />
                                {InternalUsersNotifications?.message === "No Records Found" ?
                                    <></> :
                                    <span className='Notification'>
                                        <span className="badge badge-success style-badge">.</span>
                                    </span>
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="notificationDropdown">
                                <div className="dropdown-menu-header">
                                    <h6 className="text-center mt-3 mb-3"><span className="text-disabled">Notifications</span></h6>
                                </div>
                                <div className="dropdown-divider" />
                                <Scrollbar className="ms-scrollable ms-dropdown-list mb-3">
                                    {InternalUsersNotifications?.message === "No Records Found" ?
                                        <Link to="#">
                                            <p className="text-center mt-4"><b>No Notification </b></p>
                                        </Link> :
                                        <>
                                            {InternalUsersNotifications?.map((val, i) =>
                                                <div key={i}>
                                                    <div
                                                        className="media cursor"
                                                        onClick={(e) => handleClickNotification(e, val)}
                                                    >
                                                        <img className="mr-3" src={val.Image ?? 'https://sugitechnologies.com/images/AppImages/images_x8xwjt.jpg'} alt="notification" />
                                                        <div className="media-body">
                                                            <p className="mt-0 mb-1 font-weight-bold">{val.For_Display}
                                                            </p>
                                                            <span className='notification-time'>{val.Status_Updated_On}</span>

                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )}
                                        </>
                                    }
                                </Scrollbar>
                                {InternalUsersNotifications?.message === "No Records Found" ? <></> :
                                    <p className='mark-as-read' onClick={handleMarkAllAssRead}>
                                        Mark All As Read
                                    </p>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    <li className="ms-nav-item ms-nav-user dropdown">
                        <Dropdown className="custom-dropdown">
                            <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                                {token !== undefined &&
                                    <div className='top-letter-border'>
                                        <p className="top-deopdown-letter">{(token?.result?.Name?.charAt(0))?.toUpperCase()}</p>
                                    </div>
                                }
                            </Dropdown.Toggle>
                            {token !== undefined &&
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right user-dropdown z-100" aria-labelledby="userDropdown">
                                    <div className="dropdown-menu-header">
                                        <img className="ms-img-round profile-image" src="https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" alt="people" />
                                        <h6 className="text-center mt-3"><span className="text-disabled ">{token.result.Name}</span></h6>
                                        <p className="text-center"><span className="text-disabled ">{token.result.Email}</span></p>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={handleChangePassword} to="/">
                                            <span><FaKey className="sidenavigationIcon" /> <span className='pl-3'>Change Password</span></span>
                                        </Link>
                                    </div>
                                    <div className="dropdown-divider" />
                                    <div className="dropdown-menu-footer">
                                        <Link className="media fs-14 p-2" onClick={logout} to="/">
                                            <span><FaPowerOff className="sidenavigationIcon" /> <span className='pl-3'>Logout</span></span>
                                        </Link>
                                    </div>
                                </Dropdown.Menu>
                            }
                        </Dropdown>
                    </li>
                </ul>
                <div className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler" onClick={topbaropen}>
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                    <span className="ms-toggler-bar bg-primary" />
                </div>
                <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                    centered contentClassName="modal-body-scrollar">
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                        <h1 className="text-center pb-2">Change Password</h1>
                        <div>
                            <label><b>New Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText1 === true ? "text" : "password"} ref={PasswordR} />
                                <button className='eye-button' onClick={() => setShowText1(!showText1)}>
                                    {!showText1 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <label><b>Confirm Password</b></label> <span className='text-danger'>*</span>
                            <div className="input-group">
                                <input className='form-control' type={showText2 === true ? "text" : "password"} ref={ConfirmPasswordR} />
                                <button className='eye-button' onClick={() => setShowText2(!showText2)}>
                                    {!showText2 ?
                                        <FaEye className='sidenavigationIcon' /> : <FaEyeSlash className='sidenavigationIcon' />
                                    }
                                </button>
                            </div>
                            <p className="text-danger">{message}</p>
                            {loader ?
                                <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary shadow-none mr-3" onClick={handleChange}>Change</button>
                                    <button type="submit" className="btn btn-secondary shadow-none" onClick={handleClose}>Cancel</button>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </nav >
        </>
    );
}
