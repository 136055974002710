import React, { useState, useEffect } from "react";
import VendorActions from '../../../../redux/Vendor/actions';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import CircleDotSpinner from "../../../common/CircleDotSpinner";

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: "110px"
    })
}

export default function HelpdeskVFullyCustomizedCakeSelectVendorPopup(props) {

    const { showHelpdeskVSelectVendorPopup, handleCloseHelpdeskVSelectVendor, vendorsList,
        ExistingVendorID, loader, handleSubmitCallConversation } = props;

    const dispatch = useDispatch();

    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState();
    const [vendorValidate, setVendorValidate] = useState(false);
    const [callConversation, setCallConversation] = useState('');

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    useEffect(() => {
        if (vendorsList.length === 0) {
            setVendors([]);
        } else {
            if (vendorsList.message === 'No Records Found') {
                setVendors([]);
            } else {
                let List = [];
                vendorsList.map(val => {
                    return List.push({
                        label: `${val.Id} - ${val.VendorName} - ${val.Pincode}`,
                        value: val
                    });
                });
                setVendors(List);
            }
        };
    }, [vendorsList]);

    useEffect(() => {
        dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: ExistingVendorID } });
        //eslint-disable-next-line
    }, [ExistingVendorID]);

    const handleselectedVendor = (e) => {
        setSelectedVendor(e);
        if (e?.value?._id === ExistingVendorID) {
            setVendorValidate(false);
        } else {
            setVendorValidate(true);
        };
    };

    const handleConfirmation = (e, button) => {
        e.preventDefault();
        setVendorValidate(false);
        if (button === 'No') {
            setSelectedVendor();
        };
    };

    const handleClose = (e) => {
        setSelectedVendor();
        setCallConversation('');
        setVendorValidate(false);
        handleCloseHelpdeskVSelectVendor(e);
    };

    const handleCloseConfirmation = (e) => {
        setVendorValidate(false);
    };

    const customFilter = (option, searchText) => {
        if (searchText) {
            if (option.data.value.Address?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.VendorName?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.Id?.toLowerCase().includes(searchText?.toLowerCase()) ||
                option.data.value.Pincode?.toLowerCase().includes(searchText?.toLowerCase())) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        };
    };

    return (
        <div>
            <Modal show={showHelpdeskVSelectVendorPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Update Call Conversation</h4>
                    <div className="form-row">
                        <div className="col-md-12">
                            <label className='float-left font-weight-bold'>Existing Vendor <span className='text-danger'>*</span></label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="form-control">{VendorDetails?.Id} - {VendorDetails?.VendorName} - {VendorDetails?.Pincode}</div>
                        </div>
                        <div className="col-md-12">
                            <label className='float-left font-weight-bold'>Select Vendor <span className='text-danger'>*</span></label>
                            <span>(Vendor Id - Vendor Name - Vendor Pincode)</span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <Select
                                styles={customStyles}
                                options={vendors}
                                closeMenuOnSelect={true}
                                isSearchable={true}
                                onChange={(e) => handleselectedVendor(e)}
                                value={selectedVendor || ''}
                                filterOption={customFilter}
                            />
                        </div>
                        {selectedVendor &&
                            <div className='col-md-12'>
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Selected Vendor's Details</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td><b>{selectedVendor?.value?.Id}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td><span className="long-line-break">{selectedVendor?.value?.VendorName}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Email</th>
                                                    <td><span className="long-line-break">{selectedVendor?.value?.Email}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Main Mobile Number</th>
                                                    <td>{selectedVendor?.value?.PhoneNumber1}</td>
                                                </tr>
                                                {selectedVendor?.value?.PhoneNumber2 &&
                                                    <tr>
                                                        <th scope="row">Alternative Mobile Number</th>
                                                        <td>{selectedVendor?.value?.PhoneNumber2}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Address</th>
                                                    <td>
                                                        <span className="long-line-break">
                                                            {selectedVendor?.value?.Address}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Pincode</th>
                                                    <td>
                                                        <span className="long-line-break">
                                                            {selectedVendor?.value?.Pincode}
                                                        </span>
                                                    </td>
                                                </tr>
                                                {selectedVendor?.value?.GoogleLocation &&
                                                    <tr>
                                                        <th scope="row">Google Location</th>
                                                        <td>
                                                            <b>Latitude</b> : {selectedVendor?.value?.GoogleLocation?.Latitude} <br />
                                                            <b>Longitude</b> : {selectedVendor?.value?.GoogleLocation?.Longitude}
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-12">
                            <label className='float-left font-weight-bold'>Enter Call Conversation Details <span className='text-danger'>*</span></label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <textarea
                                className="form-control text-capitalize"
                                placeholder="Type Here..."
                                onChange={(e) => setCallConversation(e.target.value)}
                            />
                        </div>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={(selectedVendor && callConversation) ? false : true}
                                className="btn btn-primary shadow-none mr-3"
                                onClick={(e) => handleSubmitCallConversation(e, selectedVendor, callConversation)}
                            >
                                Intimate to Manager
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <Modal show={vendorValidate} onHide={handleCloseConfirmation} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseConfirmation}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-4">Confirmation</h4>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <div className="font-weight-bold text-center">Are you sure you want to change the Vendor?</div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-primary shadow-none mr-3"
                            onClick={(e) => handleConfirmation(e, 'Yes')}
                        >
                            Yes
                        </button>
                        <button
                            type="submit"
                            className="btn btn-secondary shadow-none"
                            onClick={(e) => handleConfirmation(e, 'No')}
                        >
                            No
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}