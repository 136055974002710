const actions = {
    GET_ORDER_LIST: 'GET_ORDER_LIST',
    SET_ORDER_LIST: 'SET_ORDER_LIST',
    GET_ORDER_LIST_BY_ABOVE_5KG: 'GET_ORDER_LIST_BY_ABOVE_5KG',
    SET_ORDER_LIST_BY_ABOVE_5KG: 'SET_ORDER_LIST_BY_ABOVE_5KG',
    ABOVE_5KG_ORDER_ASSIGN: 'ABOVE_5KG_ORDER_ASSIGN',
    ABOVE_5KG_ORDER_ASSIGN_STATUS: 'ABOVE_5KG_ORDER_ASSIGN_STATUS',
    CHANGE_ORDER_VIEW_RESPONSE: 'CHANGE_ORDER_VIEW_RESPONSE',
    CHANGE_ORDER_RESPONSE: 'CHANGE_ORDER_RESPONSE',
    CANCEL_ORDER: 'CANCEL_ORDER',
    CANCEL_ORDER_STATUS: 'CANCEL_ORDER_STATUS',
    GET_DELIVERY_CHARGE: 'GET_DELIVERY_CHARGE',
    SET_DELIVERY_CHARGE: 'SET_DELIVERY_CHARGE',
    UPDATE_DELIVERY_CHARGE: 'UPDATE_DELIVERY_CHARGE',
    GET_ADMIN_STATEMENTOFACCOUNTS: 'GET_ADMIN_STATEMENTOFACCOUNTS',
    SET_ADMIN_STATEMENTOFACCOUNTS: 'SET_ADMIN_STATEMENTOFACCOUNTS',
    GET_VENDOR_STATEMENTOFACCOUNTS_LIST: 'GET_VENDOR_STATEMENTOFACCOUNTS_LIST',
    SET_VENDOR_STATEMENTOFACCOUNTS_LIST: 'SET_VENDOR_STATEMENTOFACCOUNTS_LIST',
    GET_VENDOR_STATEMENT_DETAILS: 'GET_VENDOR_STATEMENT_DETAILS',
    SET_VENDOR_STATEMENT_DETAILS: 'SET_VENDOR_STATEMENT_DETAILS',
    CREATE_STATEMENT: 'CREATE_STATEMENT',
    CREATE_STATEMENT_STATUS: 'CREATE_STATEMENT_STATUS',
    CHANGE_ORDER_VIEW_RESPONSE_BY_VENDOR: 'CHANGE_ORDER_VIEW_RESPONSE_BY_VENDOR',
    GET_TAX: 'GET_TAX',
    SET_TAX: 'SET_TAX',
    UPDATE_TAX: 'UPDATE_TAX',
    GET_UPDATE_PRODUCT_SHARE_PER_LIST: "GET_UPDATE_PRODUCT_SHARE_PER_LIST",
    SET_UPDATE_PRODUCT_SHARE_PER_LIST: "SET_UPDATE_PRODUCT_SHARE_PER_LIST",
    UPDATE_PRODUCT_SHARE_PER: "UPDATE_PRODUCT_SHARE_PER",
    GET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR: 'GET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR',
    SET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR: 'SET_VENDORS_NORMAL_CAKE_ORDERS_LIST_BY_SHOWTOVENDOR',
    ORDER_RESPONSE: 'ORDER_RESPONSE',
    CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE: 'CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE',
    CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE_BY_VENDOR: 'CHANGE_OTHERPRODUCT_ORDER_VIEW_RESPONSE_BY_VENDOR',
    CHANGE_HAMPER_ORDER_VIEW_RESPONSE_BY_VENDOR: 'CHANGE_HAMPER_ORDER_VIEW_RESPONSE_BY_VENDOR',
    CHANGE_CUSTOMIZEDCAKE_ORDER_VIEW_RESPONSE_BY_VENDOR: 'CHANGE_CUSTOMIZEDCAKE_ORDER_VIEW_RESPONSE_BY_VENDOR',

};

export default actions;