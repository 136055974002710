import React, { useEffect, useRef, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import HelpdeskAction from '../../../../redux/Helpdesk/actions';
import moment from 'moment';
import LastSeen from './lastseen';
import commonAction from '../../../../redux/Common/actions';
import { BiConversation } from 'react-icons/bi';
import { ColorArray } from '../../../common/ColorArray';
import { BsFillPersonFill } from 'react-icons/bs';
import { IoSendSharp } from 'react-icons/io5';

export default function ChatContent(props) {

    const { messages, setmessages, setShow, onlineusers } = props;

    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [inputSearch, setInputSearch] = useState("");
    const [conversation, Setconversation] = useState({});
    const [typing, setTyping] = useState({});
    const [loadmore, setLoadmore] = useState(false);
    const [conversationMembersList, setConversationMembersList] = useState([]);

    const { token } = useSelector(state => state.authReducer);

    const { socket, buffer, currentConversation } = useSelector(state => state.commonReducer);

    const { registeredUsers } = useSelector((state) => state.registeredUserReducer);

    const { conversationList, ChatMessage } = useSelector((state) => state.HelpdeskReducer);

    const GetMessage = (val) => {
        setmessages([]);
        setLoadmore(false);
        dispatch({
            type: HelpdeskAction.UPDATE_CHATWITH_MEMBER, payload: {
                TypeOfUser: "Helpdesk C",
                ChatWith: val?.Members?.find(val => val !== token?.result?.Email),
                Sender: token?.result?.Email,
                Consersation_Id: val._id
            }
        });
        dispatch({ type: commonAction.SET_CURRENT_CONVERSATION, payload: val });
        if (val._id) {
            dispatch({ type: HelpdeskAction.GET_MESSAGES, payload: { id: val._id, index: 10 } });
        }
    };

    useEffect(() => {
        if (token?.result?.Email) {
            dispatch({ type: HelpdeskAction.GET_CONVERSATION, payload: token?.result?.Email })
        }
        //eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        if (currentConversation) {
            Setconversation(currentConversation)
        }
    }, [currentConversation]);

    useEffect(() => {
        if (ChatMessage && ChatMessage.length !== 0) {
            setmessages([...ChatMessage])
        }
        //eslint-disable-next-line
    }, [ChatMessage]);

    const send = () => {
        if (message) {
            setmessages([...messages, {
                Consersation_Id: conversation._id,
                Sent_By_Id: token?.result?.Email,
                receiverId: conversation?.Members?.find(e => e !== token?.result?.Email),
                Message: message,
                status: "seen",
                Created_By: token?.result?.Email,
                Created_On: moment().format("DD-MM-YYYY hh:mm A")
            }])
            //eslint-disable-next-line
            let data2 = socket.emit("sendMessage",
                {
                    Consersation_Id: conversation._id,
                    Sent_By_Id: token?.result?.Email,
                    receiverId: conversation?.Members?.find(e => e !== token?.result?.Email),
                    Message: message,
                    Created_By: token?.result?.Email,
                    TypeOfUser: "User",
                }
            )
            setMessage("")
        }
    };

    useEffect(() => {
        //eslint-disable-next-line
        let data = socket?.on("Typing", users => {
            setTyping(users)
        })
    }, [socket]);

    useEffect(() => {
        if (conversationList?.length === 0) {
            setConversationMembersList([]);
        } else {
            // eslint-disable-next-line
            setConversationMembersList(conversationList.filter(val => {
                if (inputSearch) {
                    if ((val.Members?.find(e => e !== token?.result?.Email).toString()).toUpperCase().includes(inputSearch.toUpperCase()) ||
                        (((registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName)?.toUpperCase()?.includes(inputSearch.toUpperCase())) ||
                        ((registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.Id)?.toUpperCase()?.includes(inputSearch.toUpperCase())
                    ) {
                        return val;
                    }
                } else {
                    return val;
                }
            }));
        }
    }, [conversationList, registeredUsers, inputSearch, token]);

    const loadMore = (e) => {
        e.preventDefault();
        setLoadmore(true);
        dispatch({
            type: HelpdeskAction.GET_MESSAGES,
            payload: { id: conversation._id, index: (ChatMessage.length) + 10 }
        });
    };

    return (
        <>
            <div className="">
                <div className='row'>
                    <div className="col-xl-4 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body py-3 px-0">
                                <div className="ms-chat-container">
                                    <div className="ms-chat-header px-3">
                                        <div className="ms-chat-user-container media clearfix">
                                            <div className="ms-chat-status ms-status-online ms-chat-img mr-3 align-self-center">
                                                <span className='participants-first-letter' style={{ backgroundColor: ColorArray.find(val => val.Letter === token?.result?.Email?.substring(0, 1)?.toUpperCase())?.Color }}>
                                                    {token?.result?.Email?.substring(0, 1)?.toUpperCase()}
                                                </span>
                                                {/* <img src="../../assets/img/costic/customer-1.jpg" className="ms-img-round" alt="people" /> */}
                                            </div>
                                            <div className="media-body ms-chat-user-info mt-1">
                                                <h6>{token?.result?.Name}</h6>
                                                <small className='text-disabled '>{token?.result?.Email}</small>

                                            </div>
                                            <div className='cursor' onClick={() => setShow(true)}>
                                                <i className="material-icons" style={{ fontSize: "30px", marginRight: "8px" }}>person_add</i>
                                            </div>
                                        </div>
                                        <form className="ms-form my-3" method="post">
                                            <div className="ms-form-group my-0 mb-0 has-icon fs-14">
                                                <input type="search" className="ms-form-input w-100" placeholder="Search for People and Groups" onChange={(e) => setInputSearch(e.target.value)} value={inputSearch} /> <i className="flaticon-search text-disabled"></i>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="ms-chat-body">
                                        <ul className="">
                                            {conversationMembersList?.map(val =>
                                                // {conversationList.filter(el =>
                                                //     (el.Members?.find(e => e !== token?.result?.Email).toString()).toUpperCase().includes(inputSearch.toUpperCase())
                                                // ).map(val =>
                                                <li key={val._id} onClick={() => GetMessage(val)} className={`ms-chat-user-container ms-open-chat ${conversation._id === val._id ? "selected" : ""}  ms-deletable p-3 media clearfix cursor`}>
                                                    <div
                                                        className={onlineusers.find(e => (e.userId)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString()) ?
                                                            "ms-chat-status ms-status-online ms-chat-img mr-3 ms-has-new-msg align-self-center" :
                                                            "ms-chat-status ms-status-offline ms-has-new-msg ms-chat-img mr-3 align-self-center"
                                                        }
                                                    >
                                                        {(messages?.filter(el => el.Consersation_Id === val._id && el.status === "unseen")?.length ||
                                                            val.Unseen_Count?.find(el => el.Member === token?.result?.Email && el.Count > 0)) ?
                                                            <span className="msg-count">
                                                                {messages?.filter(el => el.Consersation_Id === val._id && el.status === "unseen")?.length || val.Unseen_Count?.find(el => el.Member === token?.result?.Email)?.Count}
                                                            </span> :
                                                            <></>
                                                        }
                                                        {((registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName) &&
                                                            (ColorArray.find(c => c.Letter === ((registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?? val?.Members?.find(e => e !== token?.result?.Email))?.substring(0, 1)?.toUpperCase())))?.Color) ?
                                                            <span className='participants-first-letter' style={{ backgroundColor: (ColorArray.find(c => c.Letter === ((registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?? val?.Members?.find(e => e !== token?.result?.Email))?.substring(0, 1)?.toUpperCase())))?.Color }}>
                                                                {(registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?? val?.Members?.find(e => e !== token?.result?.Email))?.substring(0, 1)?.toUpperCase()}
                                                            </span> :
                                                            <span><BsFillPersonFill className='person-fill-icon' /></span>
                                                        }
                                                    </div>
                                                    <div className="media-body ms-chat-user-info mt-1">
                                                        <h6>{(registeredUsers?.message !== 'No Records Found') && (registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?? registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.Id)}</h6>
                                                        {/* <h6>{(registeredUsers?.message !== 'No Records Found') && (registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?? val?.Members?.find(e => e !== token?.result?.Email))}</h6>  */}
                                                        <LastSeen onlineusers={onlineusers} email={val?.Members?.find(e => e !== token?.result?.Email)} active={onlineusers.find(e => e.userId === val?.Members?.find(e => e !== token?.result?.Email))} />
                                                        <p>{((registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName) && registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.Id)}</p>
                                                        {/* <p>{(registeredUsers?.find(el => (el.PhoneNumber)?.toString() === (val?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName) && val?.Members?.find(e => e !== token?.result?.Email)}</p> */}
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-xl-8 col-md-12 ${!conversation?._id ? "bg-white ms-chat-no-conversation" : ""}`}>
                        {conversation?._id ?
                            <div className="ms-panel ms-chat-conversations ms-widget ">
                                <div>
                                    <div className="ms-panel-header">
                                        <div className="ms-chat-header justify-content-between">
                                            <div className="ms-chat-user-container media clearfix">
                                                <div 
                                                // className={`ms-chat-status 
                                                //  ${onlineusers.find(e => e.userId === conversation?.Members?.find(e => e !== token?.result?.Email)) ? "ms-status-online" : "ms-status-offline"} 
                                                 
                                                //  ms-chat-img mr-3 align-self-center`
                                                // }

                                                // className={onlineusers.find(e => (e.userId)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString()) ?
                                                //     "ms-chat-status ms-status-online ms-chat-img mr-3 ms-has-new-msg align-self-center" :
                                                //     "ms-chat-status ms-status-offline ms-has-new-msg ms-chat-img mr-3 align-self-center"
                                                // }
                                                className={onlineusers.find(e => e.userId === conversation?.Members?.find(e => e !== token?.result?.Email)) ?  
                                                    "ms-chat-status ms-status-online ms-chat-img mr-3 ms-has-new-msg align-self-center" :
                                                    "ms-chat-status ms-status-offline ms-has-new-msg ms-chat-img mr-3 align-self-center"
                                                }
                                                >
                                                    {(registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?
                                                        <span className='participants-first-letter' style={{ backgroundColor: ColorArray.find(val => val.Letter === ((registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName)?.substring(0, 1)?.toUpperCase())?.Color }}>
                                                            {((registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName)?.substring(0, 1)?.toUpperCase()}
                                                        </span> :
                                                        <span className='no-participants-icon'><BsFillPersonFill className='person-fill-icon' /></span>
                                                    }
                                                    {/* <img src="../../assets/img/costic/customer-3.jpg" className="ms-img-round" alt="people" /> */}
                                                </div>
                                                <div className="media-body ms-chat-user-info mt-1">
                                                    <h6> {(registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName ?
                                                        (registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.UserName :
                                                        (registeredUsers?.message !== 'No Records Found') && (registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.Id ?
                                                            (registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.Id :
                                                            // (registeredUsers?.message !== 'No Records Found') && (registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.PhoneNumber ?
                                                            //     (registeredUsers?.message !== 'No Records Found') && registeredUsers?.find(el => (el?.PhoneNumber)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString())?.PhoneNumber :
                                                            conversation?.Members?.find(e => e !== token?.result?.Email)
                                                        )}</h6>
                                                    <span className="text-disabled fs-12">
                                                        {/* {onlineusers?.find(e => e.userId === conversation?.Members?.find(e => e !== token?.result?.Email)) ? "Active Now" : "offline"} */}
                                                        {onlineusers.find(e => (e.userId)?.toString() === (conversation?.Members?.find(e => e !== token?.result?.Email))?.toString()) ? "Active Now" : "Offline"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-panel-body ">
                                        {buffer ?
                                            <div className='load-more-message'>
                                                <span className="loader"></span>
                                            </div> :
                                            (ChatMessage.length > 9 ?
                                                <div className='load-more-message'>
                                                    <button onClick={(e) => loadMore(e)}>load more</button>
                                                </div> :
                                                <>
                                                </>
                                            )
                                        }
                                        {messages?.filter(e => e.Consersation_Id === conversation._id)?.map((val, index) =>
                                            <>
                                                {(index - 1) < 0 ?
                                                    (<div className="text-center">
                                                        <p className="ms-chat-time">{moment(val?.Created_On, 'DD-MM-YYYY hh:mm A', true).format("DD-MM-YYYY")}</p>
                                                    </div>
                                                    ) :
                                                    (messages[index - 1]?.Created_On && moment(messages[index - 1]?.Created_On, 'DD-MM-YYYY hh:mm A', true).format("DD-MM-YYYY") !== moment(val?.Created_On, 'DD-MM-YYYY hh:mm A', true).format("DD-MM-YYYY") ?
                                                        <div className="text-center">
                                                            <p className="ms-chat-time">{moment(val?.Created_On, 'DD-MM-YYYY hh:mm A', true).format("DD-MM-YYYY")}</p>
                                                        </div>
                                                        :
                                                        <></>
                                                    )
                                                }
                                                <div key={val._id} className={val.Sent_By_Id === token?.result?.Email ? "ms-chat-bubble ms-chat-message ms-chat-outgoing media clearfix" : "ms-chat-bubble ms-chat-message media ms-chat-incoming clearfix"}>
                                                    {/* <div className={`ms-chat-status ${onlineusers.find(e => e.userId === conversation?.Members?.find(e => e !== token?.result?.Email)) ? "ms-status-online" : "ms-status-offline"} ms-chat-img`}>
                                                        <img src="../../assets/img/costic/customer-3.jpg" className="ms-img-round" alt="people" />
                                                    </div> */}
                                                    <div className="media-body">
                                                        <div className="ms-chat-text">
                                                            <p>{val.Message}</p>
                                                        </div>
                                                        <p className="ms-chat-time">{moment(val?.Created_On, 'DD-MM-YYYY hh:mm A', true).format("hh:mm A")}</p>
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                        {(typing?.Sent_By_Id === conversation?.Members?.find(e => e !== token?.result?.Email) && typing?.typing) ?
                                            <div className="typing-indicator ms-chat-incoming">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div> :
                                            <></>
                                        }
                                        <div className="ps__rail-x" style={{ left: "0px", bottom: " 0px" }}><div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div></div><div className="ps__rail-y" style={{ top: "0px", height: "746px", right: "0px" }}><div className="ps__thumb-y" tabIndex="0" style={{ top: "0px", height: "678px" }}></div></div>
                                        {(!loadmore) &&
                                            <AlwaysScrollToBottom />
                                        }
                                    </div>
                                    <div className="ms-panel-footer pt-0">
                                        <div className="ms-chat-textbox">
                                            <form onSubmit={(e) => {
                                                e.preventDefault()
                                                send()
                                            }}>
                                                <ul className="ms-list-flex mb-0">

                                                    <li className="ms-chat-input">
                                                        <input
                                                            value={message}
                                                            onFocus={() => {
                                                                socket.emit("isTyping",
                                                                    {
                                                                        Sent_By_Id: token?.result?.Email,
                                                                        receiverId: conversation?.Members?.find(e => e !== token?.result?.Email),
                                                                        typing: true,
                                                                    }
                                                                )
                                                            }}
                                                            onBlur={() => {
                                                                socket.emit("isTyping",
                                                                    {
                                                                        Sent_By_Id: token?.result?.Email,
                                                                        receiverId: conversation?.Members?.find(e => e !== token?.result?.Email),
                                                                        typing: false,
                                                                    }
                                                                )
                                                            }}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            placeholder="Type here..."
                                                        />

                                                    </li>
                                                    <li>
                                                        <button
                                                            className="chat-message-Send-button"
                                                            type="submit"
                                                        >
                                                            <IoSendSharp className='send-button-icon' />
                                                            {/* Send */}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div>
                                <div className='no-conversation-selected'>
                                    <BiConversation className='conversation-icon' />
                                    <div className='conversation-class'>Choose a Conversation</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
};