import React from 'react';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage, currentdata }) => {

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    };

    return (
        <nav>
            <div className='row'>
                <div className="col-sm-12 col-md-5">
                    <div className="dataTables_info mt-3" id="data-table-4_info" role="status" aria-live="polite">
                        Showing {currentdata.length} of {totalPosts} entries
                    </div>
                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="dataTables_paginate paging_simple_numbers" id="data-table-4_paginate">
                        <ul className="pagination float-right mt-1">
                            {pageNumbers.map(number => (
                                <li key={number} className={currentPage === number ? "paginate_button page-item active " : "paginate_button page-item"}>
                                    <a onClick={(e) => paginate(e, number)} href="#paginate" aria-controls="data-table-4" data-dt-idx="1" tabIndex="0" className="page-link pagination-click" >
                                        {number}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Pagination;