import React, { useEffect, useState } from 'react';
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import actions from '../../../redux/Vendor/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import ReactPagination from '../../common/React-Pagination';

export default function Content() {

    const history = useHistory();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    //for get vendor list
    const { vendorsList } = useSelector((state) => state.VendorReducer);

    //dispatch for get vendor list
    useEffect(() => {
        dispatch({ type: actions.GET_ALL_VENDORS });
        // eslint-disable-next-line 
    }, []);

    //for get search input values
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //for validate the list is empty or not
    useEffect(() => {
        if (vendorsList?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(vendorsList.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.Email && val.Email.toLowerCase().includes(search)) ||
                    (val.PhoneNumber1 && val.PhoneNumber1.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [vendorsList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //for view details page
    function ViewVendor(user) {
        history.push('/admin-vendor-list-detail', { id: user._id });
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: actions.GET_ALL_VENDORS });
    };

    //loading
    useEffect(() => {
        if (vendorsList.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
        // eslint-disable-next-line
    }, [vendorsList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <h6>Vendors List</h6>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-4_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch
                                                    label='Search'
                                                    type="search"
                                                    placeholder="type here to search..."
                                                    onChange={handleSearchbar}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" >
                                                        <thead>
                                                            <tr>
                                                                <th>Vendor ID</th>
                                                                <th>Profile Image</th>
                                                                <th>Vendor Name</th>
                                                                <th>Email ID</th>
                                                                <th>Mobile Number</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((user, i) =>
                                                                    <tr className={user.Status === 'Blocked' ? 'cursor text-danger' : 'cursor'} onClick={() => ViewVendor(user)} key={i} >
                                                                        <th className='id-column' scope="row">{user.Id}</th>
                                                                        <td><img className='Avatar profile-image' src={user.ProfileImage ?? 'https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg'} alt="cake" /></td>
                                                                        <td>{user.VendorName}</td>
                                                                        <td>{user.Email}</td>
                                                                        <td>{user.PhoneNumber1}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={5} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
