import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../../common/MenuLink';
import Alert from '../../common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../common/dropdown';
import { FaTicketAlt, FaUsers, FaUserPlus } from 'react-icons/fa';
import { IoGift } from 'react-icons/io5';
import { MdDashboard, MdOutlineFreeCancellation } from 'react-icons/md';
import { RiCake2Fill } from 'react-icons/ri';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { TbReport } from 'react-icons/tb';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import { FaBook } from 'react-icons/fa'
import { GiNotebook } from 'react-icons/gi';
import { MdChat } from 'react-icons/md';

export default function Sidenavigation() {

    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && Filtered_Dates?.Page === 'tickets') && (pathName !== '/helpdeskV-Tickets-List' && pathName !== '/helpdeskV-Ticket-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'DO') && (pathName !== '/helpdeskV-Delivery-Orders-List' && pathName !== '/helpdeskV-Delivery-Order-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'UT') && (pathName !== '/helpdeskV-Unclosed-Tickets-List' && pathName !== '/helpdeskV-Ticket-Details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        }
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            <MenuLink to="/helpdeskVdashboard">
                                <MdDashboard className="sidenavigationIcon" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                        </li>
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-vendors-list">
                                <FaUsers className="sidenavigationIcon" /><span className='pl-3'>Vendors List</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-vendor-register">
                                <FaUserPlus className="sidenavigationIcon" /><span className='pl-3'>Vendor Registration</span>
                            </MenuLink>
                        </li >
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/helpdeskV-customize-cake-details' || pathName === '/helpdeskV-order-details' ||
                                    pathName === '/helpdeskV-vendor-details' || pathName === '/helpdeskV-Cake-Details' ||
                                    pathName === '/helpdeskV-Other-Product-Details' || pathName === '/helpdeskV-Hampers-Details' ||
                                    pathName === '/helpdeskV-Ticket-Details' || pathName === '/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails' ||
                                    pathName === '/helpdeskV-Cakey-Direct-General-Orderdetails' || pathName === '/helpdeskV-Unclosed-Ticket-Details' ||
                                    pathName === '/helpdeskV-Vendor-Rejected-Order-Details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/helpdeskV-CustomizedCake-Order-through-Vendor-Details' || pathName === '/helpdeskV-Delivery-Order-Details' ||
                                    pathName === '/helpdeskV-Cake-Update' ?
                                    '/helpdeskV-New-Cakes-List' : '#'}
                                className={(pathName === '/helpdeskV-New-Cakes-List' || pathName === '/helpdeskV-Approved-Cakes-List' ||
                                    pathName === '/helpdeskV-Cake-Details' || pathName === '/helpdeskV-Other-Product-Details' ||
                                    pathName === '/helpdeskV-Cake-Update') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <RiCake2Fill className="sidenavigationIcon" /><span className='pl-3'>Cakes &amp; Other Products</span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/helpdeskV-New-Cakes-List" >New Cakes &amp; Other Products</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/helpdeskV-Approved-Cakes-List" >Approved Cakes &amp; Other Products</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-hampers-list">
                                <IoGift className="sidenavigationIcon" /><span className='pl-3'>Hampers List</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Cakey-Direct-General-Orderlist">
                                <FaBook className="sidenavigationIcon" /><span className='pl-3'>Cakey Direct-General Orders</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Vendor-Rejected-Orders-List">
                                <MdOutlineFreeCancellation className="sidenavigationIcon" /><span className='pl-3'>Vendor Rejected Orders</span>
                            </MenuLink>
                        </li >
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/helpdeskV-customize-cake-details' || pathName === '/helpdeskV-order-details' ||
                                    pathName === '/helpdeskV-vendor-details' || pathName === '/helpdeskV-Cake-Details' ||
                                    pathName === '/helpdeskV-Other-Product-Details' || pathName === '/helpdeskV-Hampers-Details' ||
                                    pathName === '/helpdeskV-Ticket-Details' || pathName === '/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails' ||
                                    pathName === '/helpdeskV-Cakey-Direct-General-Orderdetails' || pathName === '/helpdeskV-Unclosed-Ticket-Details' ||
                                    pathName === '/helpdeskV-Vendor-Rejected-Order-Details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/helpdeskV-CustomizedCake-Order-through-Vendor-Details' || pathName === '/helpdeskV-Delivery-Order-Details' ||
                                    pathName === '/helpdeskV-Cake-Update' ?
                                    '/helpdeskV-Cakey-Direct-CustomizedCake-Orderlist' : '#'}
                                className={(pathName === '/helpdeskV-Cakey-Direct-CustomizedCake-Orderlist' || pathName === '/helpdeskV-CustomizedCake-Order-through-Vendor' ||
                                    pathName === '/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails' || pathName === '/helpdeskV-CustomizedCake-Order-through-Vendor-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <GiNotebook className="sidenavigationIcon" /><span className='pl-3'>Customised Cake Orders </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/helpdeskV-Cakey-Direct-CustomizedCake-Orderlist" >Cakey Direct Customised Cake Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/helpdeskV-CustomizedCake-Order-through-Vendor" >Customised Cake Orders Through Vendor</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/helpdeskV-customize-cake-details' || pathName === '/helpdeskV-order-details' ||
                                    pathName === '/helpdeskV-vendor-details' || pathName === '/helpdeskV-Cake-Details' ||
                                    pathName === '/helpdeskV-Other-Product-Details' || pathName === '/helpdeskV-Hampers-Details' ||
                                    pathName === '/helpdeskV-Ticket-Details' || pathName === '/helpdeskV-Cakey-Direct-CustomizedCake-Orderdetails' ||
                                    pathName === '/helpdeskV-Cakey-Direct-General-Orderdetails' || pathName === '/helpdeskV-Unclosed-Ticket-Details' ||
                                    pathName === '/helpdeskV-Vendor-Rejected-Order-Details' || pathName === '/admin-vendor-detail-edit' ||
                                    pathName === '/helpdeskV-CustomizedCake-Order-through-Vendor-Details' || pathName === '/helpdeskV-Delivery-Order-Details' ||
                                    pathName === '/helpdeskV-Cake-Update' ?
                                    '/helpdeskV-Tickets-List' : '#'}
                                className={(pathName === '/helpdeskV-Raise-Ticket' || pathName === '/helpdeskV-Tickets-List' ||
                                    pathName === '/helpdeskV-Ticket-Details' || pathName === '/helpdeskV-Unclosed-Tickets-List' ||
                                    pathName === '/helpdeskV-Unclosed-Ticket-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <FaTicketAlt className="sidenavigationIcon" /><span className='pl-3'>Tickets </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/helpdeskV-Raise-Ticket" >Raise Ticket</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/helpdeskV-Tickets-List" >Tickets List</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/helpdeskV-Unclosed-Tickets-List" >Unclosed Tickets List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Daily-Tickets-Report">
                                <TbReport className="sidenavigationIcon" /><span className='pl-3'>Daily Reports</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Delivery-Orders-List">
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders Based On Delivery Date</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/helpdeskV-Dashoard-Chat">
                                <MdChat className="sidenavigationIcon" /><span className='pl-3'>Chat</span>
                            </MenuLink>
                        </li >
                    </ul >
                </Scrollbar>
            </div >
        </>
    );
};