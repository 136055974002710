import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../redux/Admin/actions';
// import AdminEditProfileContent from "../AdminEditProfile/ContentWithLargeFileUpload";
import AdminEditProfileContent from "../AdminEditProfile/content";
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [edit, setedit] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    //for get the logged in users details
    const auth = useSelector(state => state.authReducer);

    //for get admin details
    const AdminDetails = useSelector(state => state.adminReducer);

    //dispatch for get admin details
    useEffect(() => {
        if (auth.token !== undefined) {
            dispatch({ type: actions.GET_ADMIN_DETAILS_BY_EMAIL, payload: auth?.token?.result?.Email });
        }
        // eslint-disable-next-line 
    }, [auth]);

    // for close edit page 
    const handleClose = () => {
        setedit(false);
        if (auth.token !== undefined) {
            dispatch({ type: actions.GET_ADMIN_DETAILS_BY_EMAIL, payload: auth?.token?.result?.Email });
        };
    };

    //loading
    useEffect(() => {
        if (AdminDetails.GetAdminDetailsbyEmail.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [AdminDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <>
                    {AdminDetails.GetAdminDetailsbyEmail.map((a) =>
                        <>
                            {edit === false ?
                                <div className="ms-content-wrapper">
                                    <div className="col-md-12">
                                        <Breadcrumb />
                                    </div>
                                    <div className="ms-profile-overview">
                                        <div className="ms-profile-cover">
                                            <img
                                                className="ms-profile-img"
                                                src={a.ProfileImage === undefined ? 'https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg' : a.ProfileImage}
                                                alt="people"
                                            />
                                            <div className="ms-profile-user-info">
                                                <h4 className="ms-profile-username text-white">{a.AdminName}</h4>
                                                <h2 className="ms-profile-role">{a.Email}</h2>
                                            </div>
                                            <div className="ms-profile-user-buttons">
                                                <button
                                                    className="btn btn-primary edit-profile"
                                                    onClick={() => setedit(true)}
                                                >
                                                    <i className="fas fa-pencil-alt" /> Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h2 className="section-title">Basic Information</h2>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Admin Name</th>
                                                        <td>{a.AdminName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Email</th>
                                                        <td>{a.Email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Password</th>
                                                        <td>{a.Password}</td>
                                                    </tr>
                                                </tbody>
                                                <button
                                                    className="btn btn-secondary "
                                                    type="submit"
                                                    onClick={() => history.push('/dashboard')}
                                                >
                                                    Close
                                                </button>
                                            </table>
                                        </div>
                                    </div>
                                </div > :
                                <AdminEditProfileContent
                                    closeEdit={handleClose}
                                    Details={a}
                                />
                            }
                        </>
                    )}
                </>
            }
        </>
    );
};

export default Content;