import React, { useState, useEffect } from 'react';

export default function Information({ InfoList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (InfoList === undefined) {
            setList([]);
        } else {
            setList(InfoList);
        };
    }, [InfoList]);

    return (
        <div>
            {
                list.map((val, i) =>
                    <div className="col-xl-12 col-md-12" key={i}>
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <div>
                                <span className='font-weight-bold mr-1'>Information: </span><span>{val.Information}</span>
                                </div>
                                <span className='font-weight-bold mr-1 mt-2'>Sent On: </span><span> {val.Created_On}</span>
                            </div>
                        </div>
                        <hr />
                    </div>
                )
            }
        </div>
    )
};