import actions from "./actions";
const initialState = {
    CategoryList: [],
    AddCategoryStatus: "",
    AddSubCategoryStatus: "",
    RemoveCategoryStatus: "",
    RemoveSubCategoryStatus: "",
    UpdateCategoryStatus: [],
};
const CategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_ALL_CATEGORIES:
            return {
                ...state,
                CategoryList: action.payload

            }
        case actions.ADD_CATEGORY_STATUS:
            return {
                ...state,
                AddCategoryStatus: action.payload.AddCategoryStatus

            }
        case actions.ADD_SUBCATEGORY_STATUS:
            return {
                ...state,
                AddSubCategoryStatus: action.payload.AddSubCategoryStatus

            }
        case actions.REMOVE_CATEGORY:
            return {
                ...state,
                RemoveCategoryStatus: action.payload.RemoveCategoryStatus

            }
        case actions.REMOVE_SUBCATEGORY:
            return {
                ...state,
                RemoveSubCategoryStatus: action.payload.RemoveSubCategoryStatus

            }
        case actions.UPDATE_CATEGORY_STATUS:
            return {
                ...state,
                UpdateCategoryStatus: action.payload.UpdateCategoryStatus

            }

        default:
            return state;
    }
}

export default CategoryReducer;