import registeredUserReducer from "./users/reducers";
import authReducer from "./Auth/AuthReducer";
import CakesReducer from './cake/reducers'
import forgotpasswordReducer from "./ForgotPassword/ForgotpasswordReducer";
import VendorReducer from "./Vendor/reducers";
import OrderReducer from "./Order/OrderReducer";
import adminReducer from "./Admin/AdminReducer";
import VendorOrderReducer from "./VendorOrderList/VendorOrderReducer";
import VendorCakeListReducer from "./VendorCakeList/VendorCakeListReducer";
import CategoryReducer from "./Category/CategoryReducer";
import CakeArrayReducer from "./CakeArray/Reducer";
import CustomizeCakeReducer from "./Customize-Cake/reducer";
import HelpdeskReducer from './Helpdesk/reducer';
import commonReducer from './Common/reducer';
import TopperReducer from './Topper/reducer';
import HamperOrderReducer from './HampersOrder/reducer';
import OtherProductReducer from './OtherProducts/reducer';
import SampleReducer from './Sample/reducer';
import TicketReducer from "./Tickets/reducer";
import LoginHistoryAndWorkHoursReducer from "./LoginHistoryAndWorkHours/reducer";
import AccountsReducer from "./Accounts/reducer";

const reducers = {
    registeredUserReducer,
    CakesReducer,
    authReducer,
    forgotpasswordReducer,
    VendorReducer,
    OrderReducer,
    adminReducer,
    VendorOrderReducer,
    VendorCakeListReducer,
    CategoryReducer,
    CakeArrayReducer,
    CustomizeCakeReducer,
    HelpdeskReducer,
    commonReducer,
    TopperReducer,
    HamperOrderReducer,
    OtherProductReducer,
    SampleReducer,
    TicketReducer,
    LoginHistoryAndWorkHoursReducer,
    AccountsReducer,
};


export default reducers;