import React, { useState, useEffect } from 'react';

export default function CakeTypeLists({ TypeList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (TypeList === undefined) {
            setList([]);
        } else {
            setList(TypeList);
        };
    }, [TypeList]);

    return (
        <div>
            {
                list.map((val, i) =>
                    <span key={i}>{(i ? ', ' : '') + val}</span>
                )
            }
        </div>
    )
};