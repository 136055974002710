import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';
// import { store } from "../../redux/store"
const HelpdeskSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_ORDERS_COUNT, getOrdersCount),
        yield takeEvery(actions.GET_NOT_RESPOND_ORDERS, getNotRespondOrders),
        yield takeEvery(actions.ADD_NEW_HELPDESK_MEMBER, AddNewHelpdeskMember),
        yield takeEvery(actions.GET_HELPDESK_MEMBERS_LIST, GetHelpdeskMembersList),
        yield takeEvery(actions.DELETE_HELPDESK_MEMBER, DeleteHelpdeskMember),
        yield takeEvery(actions.GET_DELIVERY_ORDERS_LIST, GetDeliveryOrdersList),
        yield takeEvery(actions.GET_CURRENT_ORDERS_LIST, GetCurrentOrdersList),
        yield takeEvery(actions.CREATE_CONVERSATION, createConversation),
        yield takeEvery(actions.GET_CONVERSATION, getConversation),
        yield takeEvery(actions.GET_MESSAGES, getMessagesByConvId),
        yield takeEvery(actions.GET_HELPDESK_V_USERS_LIST, getHelpDeskuserList),
        yield takeEvery(actions.GET_INTERNAL_USERS_NOTIFICATIONS, GetInternalUsersNotificatons),
        yield takeEvery(actions.REMOVE_INTERNAL_USER_NOTIFICATION_BY_NOTIFICATIONID, RemoveInernalUserNotificationByNotificationID),
        yield takeEvery(actions.REMOVE_ALL_INTERNAL_USER_NOTIFICATION, RemoveAllInernalUserNotification),
        yield takeEvery(actions.GET_LAST_SEEN_LIST_BY_ID, getlastSeenListById),
        yield takeEvery(actions.GET_HELPDESKV_DASHBOARD_COUNT, GetHelpdeskVDashboardCount),
        yield takeEvery(actions.GET_HELPDESKC_DASHBOARD_COUNT, GetHelpdeskCDashboardCount),
        yield takeEvery(actions.GET_MANAGER_DASHBOARD_COUNT, GetManagerDashboardCount),
        yield takeEvery(actions.UPDATE_CHATWITH_MEMBER, updateChatWithMember),
        yield takeEvery(actions.GET_CHAT_NOTIFICATIONS_LIST, getChatNotificationsList),
        yield takeEvery(actions.REMOVE_CHAT_NOTIFICATION_BY_ID, removeChatNotificationById),
        yield takeEvery(actions.GET_NOT_RESPOND_OTHER_PRODUCT_ORDERS, getNotRespondOtherProductOrders),
        yield takeEvery(actions.GET_NOT_RESPOND_HAMPER_ORDERS, getNotRespondHamperOrders),
        yield takeEvery(actions.GET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS, getNotRespondCustomizedCakeOrders),

    ]);
};
const getlastSeenListById = function* (data) {
    const { payload } = data
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/lastSeen/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_LAST_SEEN_LIST_BY_ID,
            payload: (result?.data) ? result?.data : []
        });

    } catch (err) {
        console.log(err.response.data);
    };
};
const getHelpDeskuserList = function* () {
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/internalUsers/helpdeskV/list`
            )
        );
        yield put({
            type: actions.SET_HELPDESK_V_USERS_LIST,
            payload: (result?.data?.result) ? result?.data?.result : []
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const getMessagesByConvId = function* (data) {

    yield put({ type: CommonActions.SET_BUFFER, payload: true });

    const { payload } = data;

    try {
        // debugger
        const result = yield call(() =>
            axios.get(
                `${API_URL}/messages/${payload.id}/${payload.index}`
            )
        );
        yield put({
            type: actions.SET_MESSAGES,
            payload: result?.data && result?.data?.length !== 0 ? result.data.map(val => ({ ...val, status: "seen" })) : []
        });

    } catch (err) {
        console.log(err.response.data);
    };
    yield put({ type: CommonActions.SET_BUFFER, payload: false });
};

const getConversation = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.get(
                `${API_URL}/conv/byId/${payload}`
            )
        );
        yield put({
            type: actions.SET_CONVERSATION,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const createConversation = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/conv/add`, payload
            )
        );
        yield put({ type: actions.GET_CONVERSATION, payload: payload?.Created_By });
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
            // yield put({
            //     type: actions.GET_CONVERSATION,
            //     payload: payload?.Created_By
            // });
        }
        // else {
        // };
        yield put({
            type: CommonActions.SET_CURRENT_CONVERSATION,
            payload: result?.data?.result
            // payload: store.getState().HelpdeskReducer?.conversationList?.find(el => el.Members.find(a => a === payload.senderId) && el.Members.find(a => a === payload.receiverId))
        });
        yield put({
            type: actions.GET_MESSAGES,
            payload: { id: result?.data?.result?._id, index: 10 }
            // payload: { id: (store.getState().HelpdeskReducer?.conversationList?.find(el => el.Members.find(a => a === payload.senderId) && el.Members.find(a => a === payload.receiverId)))?._id, index: 10 }
        });
        yield delay(2000);
        yield put({
            type: CommonActions.SET_ALERT,
            payload: { alert: false, message: null, status: null }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get orders count 
const getOrdersCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdesk/orders/count`
            )
        );
        yield put({
            type: actions.SET_ORDERS_COUNT,
            payload: { OrdersCount: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get not respond orders list
const getNotRespondOrders = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/order/listbyresponse`
            )
        );
        yield put({
            type: actions.SET_NOT_RESPOND_ORDERS,
            payload: { NotRespondOrders: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//add new helpdesk member
const AddNewHelpdeskMember = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/helpdesk/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.ADD_NEW_HELPDESK_MEMBER_STATUS,
            payload: { AddHelpdeskMemberStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get helpdesk members list
const GetHelpdeskMembersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdesk/list`
            )
        );
        yield put({
            type: actions.SET_HELPDESK_MEMBERS_LIST,
            payload: { HelpdeskMembersList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};
//delete helpdesk member
const DeleteHelpdeskMember = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/helpdesk/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({
            type: actions.DELETE_HELPDESK_MEMBER_STATUS,
            payload: { DeleteHelpdeskStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetDeliveryOrdersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdesk/deliveryOrders/list/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_DELIVERY_ORDERS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCurrentOrdersList = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdesk/currentOrders/list/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_CURRENT_ORDERS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetInternalUsersNotificatons = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/internalUsers/notificationList/${payload.TypeOfUser}/${payload.Id}`
            )
        );
        yield put({
            type: actions.SET_INTERNAL_USERS_NOTIFICATIONS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveInernalUserNotificationByNotificationID = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/internalUser/notification/delete/${payload}`
            )
        );
    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveAllInernalUserNotification = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/internalUser/notification/deleteAll/${payload.TypeOfUser}/${payload.Id}`
            )
        );
        yield put({
            type: actions.HELPDESK_RESPONSE,
            payload: result.data
        });
        yield put({
            type: actions.GET_INTERNAL_USERS_NOTIFICATIONS,
            payload: { Id: payload.Id, TypeOfUser: payload.TypeOfUser }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHelpdeskVDashboardCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdeskV/dashboardCount`
            )
        );
        yield put({
            type: actions.SET_HELPDESKV_DASHBOARD_COUNT,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetHelpdeskCDashboardCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/helpdeskC/dashboardCount`
            )
        );
        yield put({
            type: actions.SET_HELPDESKC_DASHBOARD_COUNT,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetManagerDashboardCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/Manager/dashboardCount`
            )
        );
        yield put({
            type: actions.SET_MANAGER_DASHBOARD_COUNT,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const updateChatWithMember = function* (data) {

    const { payload } = data;

    try {
        yield call(() =>
            axios.put(
                `${API_URL}/chatWith/update`, payload
            )
        );
        yield put({ type: actions.GET_CONVERSATION, payload: payload?.Sender });
        yield put({ type: actions.GET_CHAT_NOTIFICATIONS_LIST, payload: payload?.Sender });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getChatNotificationsList = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/chatNotifications/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_CHAT_NOTIFICATIONS_LIST,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const removeChatNotificationById = function* (data) {

    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/chatNotifications/delete/${payload.Consersation_Id}/${payload.Receiver_Email}`
            )
        );
        yield put({ type: actions.GET_CHAT_NOTIFICATIONS_LIST, payload: payload.Receiver_Email });
        yield put({ type: actions.GET_MESSAGES, payload: { id: payload.Consersation_Id, index: 10 } });
        yield put({ type: actions.GET_CONVERSATION, payload: payload.Receiver_Email });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getNotRespondOtherProductOrders = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/otherproduct/order/listbyresponse`
            )
        );
        yield put({
            type: actions.SET_NOT_RESPOND_OTHER_PRODUCT_ORDERS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const getNotRespondHamperOrders = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/hamper/order/listbyresponse`
            )
        );
        yield put({
            type: actions.SET_NOT_RESPOND_HAMPER_ORDERS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const getNotRespondCustomizedCakeOrders = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/customize/cake/listbyresponse`
            )
        );
        yield put({
            type: actions.SET_NOT_RESPOND_CUSTOMIZED_CAKE_ORDERS,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default HelpdeskSaga;