import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from './actions'
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const CakeListSaga = function* () {

    yield all([
        yield takeEvery(actions.Add_CAKES, AddCake),
        yield takeEvery(actions.GET_ALL_CAKES, getAllCakes),
        yield takeEvery(actions.GET_SINGLE_CAKEDETAILS, getCakeDetails),
        yield takeEvery(actions.UPDATE_CAKES, UpdateCake),
        yield takeEvery(actions.DELETE_CAKE, DeleteCake),
        yield takeEvery(actions.GET_ALL_CAKES_BY_STATUS, GetAllCakesByStatus),
        yield takeEvery(actions.APPROVE_CAKE, ApproveCake),
        yield takeEvery(actions.UPDATE_APPROVED_CAKES, UpdateApprovedCake),
        yield takeEvery(actions.GET_NEW_AND_UPDATED_CAKES_LIST, GetNewAndUpdatedCakesList),
        yield takeEvery(actions.SEND_INFORMATION, SendInformation),
        yield takeEvery(actions.GET_ADMIN_CAKES_LIST, GetAdminCakesList),
    ]);
};

//add new cake
const AddCake = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.post(
                `${API_URL}/cake/new`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.Add_CAKES_STATUS,
            payload: { addCakeStatus: result.data }
        });
        yield delay(2000);
        yield put({ type: actions.Add_CAKES_STATUS, payload: { addCakeStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });


    } catch (err) {
        console.log(err.response.data);
    };
};

//approve the new cakes by admin
const ApproveCake = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/cake/approve/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_SINGLE_CAKEDETAILS, payload: payload.id });
        yield put({
            type: actions.APPROVE_CAKE_STATUS,
            payload: { ApproveCakeStatus: result.data }
        });
        yield delay(2000);
        yield put({ type: actions.APPROVE_CAKE_STATUS, payload: { ApproveCakeStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
        // if (result.data.statusCode === 200) {
        //     localStorage.setItem('ApproveCakeStatus', 'true');
        // };
    } catch (err) {
        console.log(err.response.data)
    };
};

const UpdateApprovedCake = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/cake/approveupdatedcake/${payload.id}`, payload.data
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_SINGLE_CAKEDETAILS, payload: payload.id });
        // yield put({
        //     type: actions.UPDATE_APPROVED_CAKES_STATUS,
        //     payload: { ApproveUpdatedCakeStatus: result.data }
        // });
        yield delay(2000);
        // yield put({ type: actions.UPDATE_APPROVED_CAKES_STATUS, payload: { ApproveUpdatedCakeStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data)
    };
};

//get all cakes list
const getAllCakes = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/list`
            )
        );
        yield put({
            type: actions.SET_ALL_CAKES,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get all cakes by status
const GetAllCakesByStatus = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/listbystatus/${payload}`
            )
        );
        yield put({
            type: actions.SET_ALL_CAKES_BY_STATUS,
            payload: { CakesListByStatus: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetNewAndUpdatedCakesList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/newandupdatedcake`
            )
        );
        yield put({
            type: actions.SET_NEW_AND_UPDATED_CAKES_LIST,
            payload: { Newandupdatedcakes: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//get single cake's details
const getCakeDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/list/${payload}`
            )
        );
        yield put({
            type: actions.SET_SINGLE_CAKEDETAILS,
            payload: { CakeDetails: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//update cake details
const UpdateCake = function* (data) {
    const { payload } = data;
    const { history } = payload;
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/cake/update/${payload.reqParamid}`, payload.reqBody, config
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        history.push('/Vendor-cake-details', { cakeId: payload.reqParamid });
        yield put({
            type: actions.UPDATE_CAKES_STATUS,
            payload: { UpdatecakeStatus: result.data }
        });
        yield put({ type: actions.GET_SINGLE_CAKEDETAILS, payload: payload.reqParamid });
        yield delay(2000);
        yield put({ type: actions.UPDATE_CAKES_STATUS, payload: { UpdatecakeStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

//delete cake
const DeleteCake = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/cake/delete/${payload.id}`, payload
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({
            type: actions.SET_DELETE_CAKE_STATUS,
            payload: { DeleteCakeStatus: result.data }
        });
        yield put({ type: actions.GET_SINGLE_CAKEDETAILS, payload: payload.id });
        yield delay(2000);
        yield put({ type: actions.SET_DELETE_CAKE_STATUS, payload: { DeleteCakeStatus: [] } });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const SendInformation = function* (data) {
    const { payload } = data;

    try {

        const result = yield call(() =>
            axios.put(
                `${API_URL}/cake/sendInformation/${payload.id}`, payload.body
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_SINGLE_CAKEDETAILS, payload: payload.id });
        yield put({ type: actions.CAKE_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.CAKE_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAdminCakesList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/listforAdmin`
            )
        );
        yield put({
            type: actions.SET_ADMIN_CAKES_LIST,
            payload: { AdminCakesList: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default CakeListSaga;