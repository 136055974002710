import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import commonActions from '../../../redux/Common/actions';
import $ from 'jquery';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import MenuLink from '../../common/MenuLink';
import Alert from '../../common/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { FaTicketAlt } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';
import Dropdown from '../../common/dropdown';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { TbReport } from 'react-icons/tb';
import { MdChat } from 'react-icons/md';

export default function Sidenavigation() {

    const pathName = useHistory().location.pathname;

    const dispatch = useDispatch();

    const { response, Filtered_Dates } = useSelector(state => state.commonReducer);

    useEffect(() => {
        if (Filtered_Dates?.Page) {
            if ((((Filtered_Dates?.Page && Filtered_Dates?.Page === 'tickets') && (pathName !== '/Helpdesk-C-Tickets-List' && pathName !== '/Helpdesk-C-Ticket-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'DO') && (pathName !== '/Helpdesk-C-Orders-List' && pathName !== '/Helpdesk-C-Order-Details')) ||
                ((Filtered_Dates?.Page && Filtered_Dates?.Page === 'UT') && (pathName !== '/Helpdesk-C-Unclosed-Tickets-List' && pathName !== '/Helpdesk-C-Ticket-Details')))) {
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            }
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        }
        // else if((Filtered_Dates?.Page && Filtered_Dates?.Page === 'DO') && (pathName !== '/Helpdesk-C-Orders-List' && pathName !== '/Helpdesk-C-Order-Details')){
        //     dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
        // }
        //eslint-disable-next-line
    }, [Filtered_Dates?.Page, pathName]);

    function removeoverlay() {
        $('.ms-body').toggleClass('ms-aside-left-open');
        $('#ms-side-nav').toggleClass('ms-aside-open');
        $(".ms-aside-overlay.ms-overlay-left").toggleClass('d-block');
    };

    return (
        <>
            <Alert response={response} />
            <div>
                <div className="ms-aside-overlay ms-overlay-left ms-toggler" onClick={removeoverlay}></div>
                <div className="ms-aside-overlay ms-overlay-right ms-toggler"></div>
                <Scrollbar id="ms-side-nav" className="side-nav fixed ms-aside-scrollable ms-aside-left sidenav-background">
                    <div className="logo-sn ms-d-block-lg cakey-logo-backround">
                        <Link className="pl-0 ml-0 text-center" to="/">
                            <img className='cakey-logo' src='https://sugitechnologies.com/images/AppImages/cakeylogo_veylgq.svg' alt="logo" />
                        </Link>
                    </div>
                    <ul className="accordion ms-main-aside fs-14" id="side-nav-accordion">
                        <li className="menu-item">
                            <MenuLink to="/Helpdesk-C-Dashboard">
                                <MdDashboard className="sidenavigationIcon" /><span className='pl-3'>Dashboard </span>
                            </MenuLink>
                        </li>
                        {/* <li className="menu-item">
                            <MenuLink to="/Helpdesk-C-Orders-List">
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders</span>
                            </MenuLink>
                        </li >/Helpdesk-C-Direct-Customized-Cake-Orders-List' */}
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Helpdesk-C-Order-Details' || pathName === '/Helpdesk-C-Ticket-Details' ||
                                    pathName === '/Helpdesk-C-Unclosed-Ticket-Details' ||
                                    pathName === '/Helpdesk-C-Direct-Customized-Cake-Order-Details' ||
                                    pathName === '/Helpdesk-C-Cancelled-Orders-With-15Mins-Details' ?
                                    '/Helpdesk-C-Orders-List' : '#'}
                                className={(pathName === '/Helpdesk-C-Orders-List' || pathName === "/Helpdesk-C-Direct-Customized-Cake-Orders-List" ||
                                    pathName === '/Helpdesk-C-Order-Details' || pathName === '/Helpdesk-C-Direct-Customized-Cake-Order-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <BsFileEarmarkTextFill className="sidenavigationIcon" /><span className='pl-3'>Orders </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Orders-List" >Orders List Based On Delivery Date</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Direct-Customized-Cake-Orders-List" >Cakey Direct Customised Cake Orders</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Cancelled-Orders-With-15Mins-List" >Cancellation Within 15 Mins</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <Dropdown
                            head={<MenuLink
                                to={pathName === '/Helpdesk-C-Order-Details' || pathName === '/Helpdesk-C-Ticket-Details' ||
                                    pathName === '/Helpdesk-C-Unclosed-Ticket-Details' ||
                                    pathName === '/Helpdesk-C-Direct-Customized-Cake-Order-Details' ||
                                    pathName === '/Helpdesk-C-Cancelled-Orders-With-15Mins-Details' ?
                                    '/Helpdesk-C-Tickets-List' : '#'}
                                className={(pathName === "/Helpdesk-C-Raise-Ticket" || pathName === '/Helpdesk-C-Tickets-List' ||
                                    pathName === '/Helpdesk-C-Unclosed-Tickets-List' || pathName === '/Helpdesk-C-Ticket-Details' ||
                                    pathName === '/Helpdesk-C-Unclosed-Ticket-Details') ?
                                    "has-chevron active" : "has-chevron"}
                            // className="has-chevron"
                            >
                                <FaTicketAlt className="sidenavigationIcon" /><span className='pl-3'>Tickets </span>
                            </MenuLink>}
                            body={
                                <ul id="product" className='active' aria-labelledby="product" data-parent="#side-nav-accordion">
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Raise-Ticket" >Raise Ticket</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Tickets-List" >Tickets List</MenuLink>
                                    </li>
                                    <li>
                                        <MenuLink to="/Helpdesk-C-Unclosed-Tickets-List" >Unclosed Tickets List</MenuLink>
                                    </li>
                                </ul >
                            }
                        />
                        <li className="menu-item">
                            <MenuLink to="/Helpdesk-C-Daily-Tickets-Report">
                                <TbReport className="sidenavigationIcon" /><span className='pl-3'>Daily Reports</span>
                            </MenuLink>
                        </li >
                        <li className="menu-item">
                            <MenuLink to="/Helpdesk-C-Dashboard-Chat">
                                <MdChat className="sidenavigationIcon" /><span className='pl-3'>Chat</span>
                            </MenuLink>
                        </li >
                    </ul >
                </Scrollbar>
            </div >
        </>
    );
};