const actions =  {
    GET_ADMIN_HAMPER_ORDERS_LIST: 'GET_ADMIN_HAMPER_ORDERS_LIST',
    SET_ADMIN_HAMPER_ORDERS_LIST: 'SET_ADMIN_HAMPER_ORDERS_LIST',
    GET_HAMPER_DETAILS: 'GET_HAMPER_DETAILS',
    SET_HAMPER_DETAILS: 'SET_HAMPER_DETAILS',
    GET_VENDOR_HAMPER_ORDERS_LIST: 'GET_VENDOR_HAMPER_ORDERS_LIST',
    SET_VENDOR_HAMPER_ORDERS_LIST: 'SET_VENDOR_HAMPER_ORDERS_LIST',
    ACCEPT_HAMPER_ORDER: 'ACCEPT_HAMPER_ORDER',
    ACCEPT_HAMPER_ORDER_STATUS: 'ACCEPT_HAMPER_ORDER_STATUS',
    CANCEL_HAMPER_ORDER: 'CANCEL_HAMPER_ORDER',
    CANCEL_HAMPER_ORDER_STATUS: 'CANCEL_HAMPER_ORDER_STATUS',
    UPDATE_HAMPER_ORDER_STATUS: 'UPDATE_HAMPER_ORDER_STATUS',
    UPDATE_HAMPER_ORDER_STATUS_RESPONSE: 'UPDATE_HAMPER_ORDER_STATUS_RESPONSE',
}

export default actions;