import React from 'react';
import axios from 'axios';

export default function RefundPayment() {

    const HandleRefundPayment = async(e) => {
        e.preventDefault();
        const API_URL = 'http://localhost:3001/api';

        const Url = `${API_URL}/payment/refund`;
        const response = await axios.post(Url);
        const { data } = response;
        console.log(data);
    };

    return (
        <div className='text-center mt-5'>
            <h5 className='mt-5 cursor font-weight-bold' onClick={HandleRefundPayment}>
                Click Here to Refund Rs.5
            </h5>
        </div>
    )
}