import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Topper/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import { NumberValidation } from '../../../../utils/Validation';
import Breadcrumb from './Breadcrumb';
import { BiRupee } from 'react-icons/bi';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FileSizeValidation from '../../../common/FileSizeValidation';

const validationSchema = yup.object().shape({
    TopperName: yup.string().required('required'),
    Price: yup.string().required('required'),
    Stock: yup.string().required("required"),
    AvailableCount: yup.string().required("required"),
    TopperImage: yup.mixed().test('required', 'Please select a file', value => {
        return value && value.length;
    })
});

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);

    let [topperImage, setTopperImage] = useState();
    let [topperImageUrl, setTopperImageUrl] = useState();
    let [selectedStock, setSelectedStock] = useState();

    //get logged in vendor details
    const { token } = useSelector((state) => state.authReducer);

    const { AddNewTopperStatus } = useSelector(state => state.TopperReducer);

    const { register, handleSubmit, reset, formState, setValue } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    const handleSelectImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setTopperImage(topperImage = result?.FinalImages[0]);
                if (topperImage) {
                    setTopperImageUrl(topperImageUrl = URL.createObjectURL(topperImage));
                };
            }
        }
    };

    const handleStock = (e) => {
        setSelectedStock(e.target.value);
        (e.target.value === 'OutOfStock') && setValue('AvailableCount', 0);
    };

    const onSubmit = (data) => {
        setLoader(true);
        if (token !== undefined) {
            const formData = new FormData();

            formData.append('VendorID', token?.result?._id);
            formData.append('Vendor_ID', token?.result?.Id);
            formData.append('VendorName', token?.result?.VendorName);
            formData.append('TopperName', data.TopperName);
            formData.append('Price', data.Price);
            formData.append('Stock', data.Stock);
            formData.append('AvailableCount', data.AvailableCount);
            formData.append('file', topperImage);

            dispatch({ type: actions.ADD_NEW_TOPPER, payload: formData });
        }
    };

    //validate add cake status
    useEffect(() => {
        if (AddNewTopperStatus?.statusCode === 400) {
            setLoader(false);
        } else if (AddNewTopperStatus?.statusCode === 200) {
            setLoader(false);
            reset();
            setTopperImage();
            setTopperImageUrl();
        };
        // eslint-disable-next-line 
    }, [AddNewTopperStatus]);

    return (
        <div className="ms-content-wrapper">
            <div className="col-md-12">
                <Breadcrumb />
            </div>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-header"><h6>Add Topper</h6></div>
                        <div className="ms-panel-body">
                            <form className="needs-validation clearfix" noValidate="">
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom18"><b>Topper Name</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="text" className={errors?.TopperName ? "form-control required-field text-capitalize" : "form-control text-capitalize"} name='TopperName' {...register('TopperName')} placeholder="Topper Name" />
                                        </div>
                                    </div>
                                    <div className={(!selectedStock || (selectedStock && selectedStock === 'OutOfStock')) ? "col-md-6 mb-3" : "col-md-4 mb-3"}>
                                        <label htmlFor="validationCustom18"><b>Price</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.Price ? "form-control required-field" : "form-control"} name='Price' {...register('Price')} placeholder="Price" />
                                            <button className={errors?.Price ? "RupeeSign required-field" : "RupeeSign"} disabled><BiRupee className="sidenavigationIcon" /></button>
                                        </div>
                                    </div>
                                    <div className={(!selectedStock || (selectedStock && selectedStock === 'OutOfStock')) ? "col-md-6 mb-3" : "col-md-4 mb-3"}>
                                        <label htmlFor="validationCustom18"><b>Stock</b> <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className={errors?.Stock ? "form-control required-field" : "form-control"} name='Stock' {...register('Stock')} defaultValue='' onChange={handleStock}>
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="OutOfStock">Out Of Stock</option>
                                                <option value="InStock">In Stock</option>
                                            </select>
                                        </div>
                                    </div>
                                    {(selectedStock && selectedStock === 'InStock') &&
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom18"><b>Available Count</b> <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={4} onInput={NumberValidation} className={errors?.AvailableCount ? "form-control required-field" : "form-control"} name='AvailableCount' {...register('AvailableCount')} placeholder="e.g. 10" />
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Topper Image <span className='text-danger'>*</span></label>
                                        <div className="col-md-12 mb-3">
                                            <div className="custom-file">
                                                <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" name='TopperImage' {...register('TopperImage')} onChange={handleSelectImage} />
                                                <label className={errors?.TopperImage && !topperImage ? "custom-file-label required-field" : "custom-file-label"}>Upload Topper Image...</label>
                                            </div>
                                            <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                        </div>
                                    </div>
                                    {topperImageUrl &&
                                        <div className="col-md-12 mb-3">
                                            <div className="ms-panel-body tabledata-background">
                                                <img className="d-block align-center" src={topperImageUrl} alt="First slide" />
                                            </div>
                                        </div>
                                    }
                                </div>
                                {loader ? <CircleDotSpinner /> :
                                    <>
                                        <button
                                            className="btn btn-primary mr-3"
                                            type="submit"
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            type="submit"
                                            onClick={() => history.push('/Vendor-toppers-list')}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Content;