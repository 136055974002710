import React from 'react';
import { Modal } from "react-bootstrap";
import CircleDotSpinner from '../../../../common/CircleDotSpinner';
import { float2D } from '../../../../../utils/Validation';

export default function RefundFor15MinsCancelPopup(props) {

    const { showRefundFor15MinsCancelPopup, OrderDetailsByTypeOfOrder, handleCloseRefundFor15MinsCancelPopup,
        handleRefundPaymentFor15MinsCancel, loader } = props;

    const handleClose = (e) => {
        handleCloseRefundFor15MinsCancelPopup(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            RefundType: 'Cash',
            Refund_Amount: (parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue))
        };
        handleRefundPaymentFor15MinsCancel(data);
    };

    return (
        <div>
            <Modal show={showRefundFor15MinsCancelPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Payment Refund</h4>
                    {OrderDetailsByTypeOfOrder?.length === 0 ?
                        <div className="text-center mt-5 mb-5">
                            <p>Loading...</p>
                        </div> :
                        <>
                            <div className="col-md-12 mb-3">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Refund Amount<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <input className='form-control' disabled defaultValue={float2D(parseFloat(OrderDetailsByTypeOfOrder?.Total) + parseFloat(OrderDetailsByTypeOfOrder?.CouponValue || 0))} />
                                </div>
                            </div>
                        </>
                    }
                    <div>
                        <p className="text-success text-center intimation-message">This will be auto intimated to Customer Helpdesk</p>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleSubmit}
                            >
                                Update and Intimate
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div >
    )
};