import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import AccountsActions from '../../../../redux/Accounts/actions';
import { useDispatch, useSelector } from 'react-redux';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import TableSearch from '../../../common/TableSearch';
import ReactPagination from '../../../common/React-Pagination';
import { float2D } from '../../../../utils/Validation';

export default function Content() {

    const dispatch = useDispatch();

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);

    const { RefundReport } = useSelector(state => state.AccountsReducer);

    useEffect(() => {
        dispatch({ type: AccountsActions.GET_REFUND_REPORTS });
        //eslint-disable-next-line
    }, []);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (RefundReport.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            //eslint-disable-next-line
            setFilteredData(RefundReport.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Order_ID && val.Order_ID?.toLowerCase()?.includes(search)) ||
                    (val.TypeOfOrder && val.TypeOfOrder?.toLowerCase()?.includes(search)) ||
                    (val.UserName && val.UserName?.toLowerCase()?.includes(search)) ||
                    (val.OldTotal && (val.OldTotal)?.toString()?.toLowerCase()?.includes(search)) ||
                    (val.NewTotal && (val.NewTotal)?.toString()?.toLowerCase()?.includes(search)) ||
                    (val.Difference && (val.Difference)?.toString()?.toLowerCase()?.includes(search)) ||
                    (val.Refund_Amount && (val.Refund_Amount)?.toString()?.toLowerCase()?.includes(search)) ||
                    (val.RefundType && val.RefundType?.toLowerCase()?.includes(search)) ||
                    (val.Refund_Status && val.Refund_Status?.toLowerCase()?.includes(search)) ||
                    (val.Refund_On && val.Refund_On?.toLowerCase()?.includes(search)) ||
                    (val.Refund_By?.Name && val.Refund_By?.Name?.toLowerCase()?.includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [RefundReport, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: AccountsActions.GET_REFUND_REPORTS });
    };

    useEffect(() => {
        (RefundReport.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [RefundReport]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <h6>Refund Report</h6>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search here..." onChange={handleSearchbar} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Order Type</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Applied Coupon Value</th>
                                                                <th scope="col">Old Price</th>
                                                                <th scope="col">New Price</th>
                                                                <th scope="col">Cancellation Charges</th>
                                                                <th scope="col">Difference</th>
                                                                <th scope="col">Refund Amount</th>
                                                                <th scope="col">Refund Type</th>
                                                                <th scope="col">Refund Status</th>
                                                                <th scope="col">Coupon Value</th>
                                                                <th scope="col">Refund On/Updated On</th>
                                                                <th scope="col">Refund By</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((val, i) =>
                                                                    <tr className="odd" key={i}>
                                                                        <th className='id-column' scope="row">{val.Order_ID}</th>
                                                                        <td>{val.TypeOfOrder}</td>
                                                                        <td>{val.UserName}</td>
                                                                        <td className='accounts-refund-report-cancel'>{(val.AppliedCouponValue && parseFloat(val.AppliedCouponValue) > 0) ? float2D(val.AppliedCouponValue || 0) : '-'}</td>
                                                                        <td className='accounts-refund-report-price'>{(val.OldTotal && parseFloat(val.OldTotal) > 0) ? float2D(val.OldTotal) : '-'}</td>
                                                                        <td className='accounts-refund-report-price'>{(val.NewTotal && parseFloat(val.NewTotal) > 0) ? float2D(val.NewTotal || 0) : '-'}</td>
                                                                        <td className='accounts-refund-report-cancel'>{(val.Cancellation_Charges && parseFloat(val.Cancellation_Charges) > 0) ? float2D(val.Cancellation_Charges) : '-'}</td>
                                                                        <td className='accounts-refund-report-price'>{float2D(val.Difference || 0)}</td>
                                                                        <td className='accounts-refund-report-refund'>{float2D(val.Refund_Amount)}</td>
                                                                        <td>{val.RefundType ? val.RefundType : val.Refund_Status?.toLowerCase() === 'success' ? 'Cash' : 'Coupon'}</td>
                                                                        <td><span className={(val.Refund_Status?.toUpperCase())?.replace(/ /g, '')}>{val.Refund_Status}</span></td>
                                                                        <td>{val.CouponValue ? (val.CouponType === 'Amount' ? `Rs. ${val.CouponValue}` : `${val.CouponValue} %`) : "-"}</td>
                                                                        <td>{val.Refund_On}</td>
                                                                        <td className='price-align3-row'>{val.Refund_By?.Name ?? '-'}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={9} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};