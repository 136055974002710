import actions from "./actions";
import { takeEvery, put, all, call } from "redux-saga/effects";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const AccountsSagas = function* () {
    yield all([
        yield takeEvery(actions.GET_VENDORS_STATEMENTOFACCOUNTS_LIST, GetVendorStatementOfAccountsList),
        yield takeEvery(actions.GET_VENDOR_STATEMENTOFACCOUNTS_DETAILS, GetVendorStatementOfAccountsDetails),
        yield takeEvery(actions.GET_DAILY_VENDOR_PAYMENT_REPORTS, GetDailyVendorPaymentReportWithDateRangeFilter),
        yield takeEvery(actions.GET_COUPONCODE_LIST, GetCouponCodeList),
        yield takeEvery(actions.GET_CANCELLED_ORDERS_LIST, GetCancelledOrdersList),
        yield takeEvery(actions.GET_REFUND_REPORTS, GetRefundReports),
        yield takeEvery(actions.GET_ACCOUNTS_DASHBOARD_COUNT, GetAccountsDashboardCount),
        yield takeEvery(actions.GET_ACCOUNTS_INPROGRESS_TICKETS, GetAccountsInprogressTickets),
        yield takeEvery(actions.GET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS, getCancelledOrdersByCustomerWithin15Mins),
        yield takeEvery(actions.GET_SALES_REPORT_BY_DATE, getSalesReportByDate),
        yield takeEvery(actions.GET_SALES_REPORT_BY_MONTH, getSalesReportByMonth),
        yield takeEvery(actions.GET_MISCELLANEOUS_REFUND_REPORT, getMiscellaneousRefundReport),
    ])
};

const GetVendorStatementOfAccountsList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/accounts/vendorPayments/List`
            )
        );
        yield put({
            type: actions.SET_VENDORS_STATEMENTOFACCOUNTS_LIST,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

const GetVendorStatementOfAccountsDetails = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/accounts/vendorPayment/Details/${payload.Id}/${payload.Month}/${payload.Year}/${payload.TypeOfUser}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_STATEMENTOFACCOUNTS_DETAILS,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    }
};

const GetDailyVendorPaymentReportWithDateRangeFilter = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/accounts/vendorPayments/listwithDateRange/${payload.StartDate}/${payload.EndDate}`
            )
        );
        yield put({
            type: actions.SET_DAILY_VENDOR_PAYMENT_REPORTS,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCouponCodeList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/couponCode/List`
            )
        );
        yield put({
            type: actions.SET_COUPONCODE_LIST,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetCancelledOrdersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/orders/cancelled/list`
            )
        );
        yield put({
            type: actions.SET_CANCELLED_ORDERS_LIST,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetRefundReports = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/refundReport`
            )
        );
        yield put({
            type: actions.SET_REFUND_REPORTS,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAccountsDashboardCount = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/Accounts/dashboardCount`
            )
        );
        yield put({
            type: actions.SET_ACCOUNTS_DASHBOARD_COUNT,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAccountsInprogressTickets = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/Accounts/inprogressList`
            )
        );
        yield put({
            type: actions.SET_ACCOUNTS_INPROGRESS_TICKETS,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getCancelledOrdersByCustomerWithin15Mins = function* () {
    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/orders/cancelByCustomer/list`
            )
        );
        yield put({
            type: actions.SET_CANCELLED_ORDERS_BY_CUSTOMER_WITHIN_15MINS,
            payload: result.data
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getSalesReportByDate = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/accounts/salesReport/${payload}`)
        )
        yield put({
            type: actions.SET_SALES_REPORT_BY_DATE,
            payload: result?.data
        })

    } catch (err) {
        console.log(err.response.data)
    }
}
const getSalesReportByMonth = function* (data) {
    const { payload } = data;
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/accounts/salesReport/${payload.Month}/${payload.Year}`)
        )
        yield put({
            type: actions.SET_SALES_REPORT_BY_MONTH,
            payload: result?.data
        })

    } catch (err) {
        console.log(err.response.data)
    }
}

const getMiscellaneousRefundReport = function* () {

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/miscellaneous/refundReport`)
        )

        yield put({
            type: actions.SET_MISCELLANEOUS_REFUND_REPORT,
            payload: result?.data
        });
    } catch (err) {
        console.log(err.response.data)
    };
};

export default AccountsSagas;