import React, { useEffect } from 'react';
import Sidenavigation from '../../HelpdeskC/Layouts/Sidenavigation';
import Topnavigation from '../../HelpdeskC/Layouts/Topnavigation';
import { Helmet } from "react-helmet";
import Homecontent from '../Sections/Hepdesk-C-Dashboard/Home-Content';
import HelpDeskAction from '../../../redux/Helpdesk/actions';
import CommonActions from '../../../redux/Common/actions';
import { useDispatch, useSelector } from 'react-redux';

export default function HelpdeskCChat() {

    const dispatch = useDispatch();

    const { token } = useSelector(state => state.authReducer);

    const Email = token?.result?.Email;
    useEffect(() => {
        window.addEventListener("beforeunload", (event) => {
            if (Email) {
                dispatch({
                    type: HelpDeskAction.UPDATE_CHATWITH_MEMBER, payload: {
                        TypeOfUser: "Helpdesk C",
                        ChatWith: "null",
                        Sender: Email
                    }
                });
                dispatch({ type: CommonActions.SET_CURRENT_CONVERSATION, payload: {} });
            }
        });
        return () => {
            if (Email) {
                dispatch({
                    type: HelpDeskAction.UPDATE_CHATWITH_MEMBER, payload: {
                        TypeOfUser: "Helpdesk C",
                        ChatWith: "null",
                        Sender: Email
                    }
                });
                dispatch({ type: CommonActions.SET_CURRENT_CONVERSATION, payload: {} });
            }
        }
        //eslint-disable-next-line
    }, [Email]);

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Chat</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Homecontent />
                {/* page content end  */}

            </main>

        </div>
    );
};