import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import HelpdeskAction from '../../../../redux/Helpdesk/actions';
import { Modal } from "react-bootstrap";
import { ColorArray } from '../../../common/ColorArray';
import { BsFillPersonFill } from 'react-icons/bs';

export default function AddConversation(props) {

    const { setShow, show, 
        // onlineusers
     } = props;

    const dispatch = useDispatch();

    const { token } = useSelector(state => state.authReducer);

    const { registeredUsers } = useSelector(state => state.registeredUserReducer);

    const createConversation = (Email) => {
        if (token?.result?.Email && Email) {
            dispatch({
                type: HelpdeskAction.CREATE_CONVERSATION, payload: {
                    senderId: token?.result?.Email,
                    receiverId: (Email)?.toString(),
                    Created_By: token?.result?.Email,
                }
            });
            setShow(false)
        }
    };

    useEffect(() => {
        dispatch({ type: HelpdeskAction.GET_CONVERSATION, payload: token?.result?.Email });
        //eslint-disable-next-line
    }, [token]);

    return (
        <>
            <Modal className="modal-min" show={show} onHide={setShow} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={() => setShow(false)}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Create Conversation</h1>
                    <div>
                        <div className="">
                            <table id="data-table-1" className="table table-hover w-100 dataTable no-footer" role="grid" aria-describedby="data-table-1_info" style={{ width: "1052px" }}>
                                {registeredUsers?.message === 'No Records Found' ?
                                    <tbody>
                                        <tr role="row" className="odd">
                                            <div className='text-center font-weight-bold p-4'>No Customers Found</div>
                                        </tr>
                                    </tbody> :
                                    <tbody>
                                        {registeredUsers.map(val =>
                                            <tr role="row" className="odd cursor">
                                                <li onClick={() => createConversation(val.PhoneNumber)} className="ms-chat-user-container ms-open-chat  ms-deletable p-3 media clearfix">
                                                    {/* <div className={`ms-chat-status ms-has-new-msg ms-chat-img align-self-center`}> */}
                                                    {/* <div className={`ms-chat-status ${onlineusers.find(e => e.userId === val.PhoneNumber) ? "ms-status-online" : "ms-status-offline"}  ms-has-new-msg ms-chat-img mr-3 align-self-center`}> */}
                                                    <div className={`ms-has-new-msg ms-chat-img mr-3 align-self-center`}>
                                                        {(val?.UserName) ?
                                                            <span className='participants-first-letter2' style={{ backgroundColor: (ColorArray.find(c => c.Letter === (val.UserName?.substring(0, 1)?.toUpperCase())))?.Color }}>
                                                                {val.UserName?.substring(0, 1)?.toUpperCase()}
                                                            </span> :
                                                            <span><BsFillPersonFill className='person-fill-icon2' /></span>
                                                        }
                                                        {/* <img src="../../assets/img/costic/customer-2.jpg" className="ms-img-round" alt="people" /> */}
                                                    </div>
                                                    <div className="media-body ms-chat-user-info mt-1">
                                                        <h6>{val.UserName ?? val.Id}</h6>
                                                        <p>{val.UserName ? val.Id : ''}</p>
                                                        {/* <h6>{val.UserName ?? val.PhoneNumber}</h6>
                                                        <p>{val.UserName ? val.PhoneNumber : ''}</p> */}
                                                    </div>
                                                </li>
                                            </tr>
                                        )}
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
