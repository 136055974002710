import React from 'react';

export default function OrderCancellationByCustomer(props) {

    const {
        TicketDetails, state, handleAcceptTicket, auth, handleEnterInputsPopup,
        OrderDetailsByTypeOfOrder,
        handleShowCloseTicketPopup, handleIntimatetoAccountsForCustomerCancelPopup,
        handleEnterNotesForCustomerCancelPopup
    } = props;
    // handleIntimatePopup, loader, handleHelpdeskCNotes

    // const [notes, setNotes] = useState('');
    // const [notesValidate, setNotesValidate] = useState(false);

    // const handleNotes = (e) => {
    //     setNotes(e.target.value);
    //     (e.target.value?.length > 0) && setNotesValidate(false);
    // };

    // const handleUpdateNotes = (e) => {
    //     e.preventDefault();
    //     if (notes.length > 0) {
    //         setNotesValidate(false);
    //         handleHelpdeskCNotes(notes);
    //     } else {
    //         setNotesValidate(true);
    //     };
    // };

    return (
        <>
            {(!TicketDetails?.HelpdeskC_Accepted_By && state?.state?.Page === "HelpdeskC") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(!TicketDetails?.HelpdeskV_Accepted_By && state?.state?.Page === "HelpdeskV") &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleAcceptTicket}
                >
                    Accept
                </button>
            }
            {(TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV') &&
                (TicketDetails?.CauseOfTicket === 'Order Cancellation by Customer' || TicketDetails?.CauseOfTicket === 'Change Requests') && !TicketDetails?.Ticket_Approved_Status) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterInputsPopup}
                >
                    Enter Inputs
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.HelpdeskC_Notes && TicketDetails?.Ticket_Status !== 'Closed') &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleShowCloseTicketPopup}
                >
                    Close Ticket
                </button>
            }
            {(!TicketDetails?.HelpdeskC_Notes && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status &&
                ((TicketDetails?.Ticket_Approved_Status === 'Approved' && (OrderDetailsByTypeOfOrder?.PaymentStatus !== 'Paid' || TicketDetails?.Refund_Status !== 'Pending')) ||
                    (TicketDetails?.Ticket_Approved_Status === 'Not Approved' && TicketDetails?.Customer_Intimation === 'success'))) &&
                <button
                    className="btn btn-primary float-start mr-3"
                    type="submit"
                    onClick={handleEnterNotesForCustomerCancelPopup}
                >
                    Enter Notes
                    {/* {TicketDetails?.Ticket_Approved_Status === 'Not Approved' ? 'Intimate To Customer' :
                        'Enter Notes'} */}
                </button>
                // <>
                //     <div>
                //         <hr />
                //         <label htmlFor="validationCustom18"><b>Enter your Notes here</b> <span className='text-danger'>*</span></label>
                //         <div className="input-group">
                //             <textarea className={notesValidate ? 'form-control text-capitalize required-field' : 'form-control text-capitalize'} placeholder='Type here...' onChange={handleNotes} />
                //         </div>
                //         {loader ? <CircleDotSpinner /> :
                //             <span className='text-success cursor' onClick={handleUpdateNotes}><u>Click here to submit your notes</u></span>
                //         }
                //     </div><br />
                // </>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status && !TicketDetails?.Customer_Intimation) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatetoAccountsForCustomerCancelPopup}
                // disabled={(TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                //     TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status &&
                //     (TicketDetails?.Ticket_Approved_Status && (OrderDetailsByTypeOfOrder?.PaymentStatus !== 'Paid' || TicketDetails?.Refund_Status !== 'Pending'))) ? true : false}
                >
                    Intimate
                </button>
            }
            {/* {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status && !TicketDetails?.HelpdeskC_Notes) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                    disabled={(TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && !TicketDetails?.Ticket_Approved_Status &&
                        (TicketDetails?.Ticket_Approved_Status && (OrderDetailsByTypeOfOrder?.PaymentStatus !== 'Paid' || TicketDetails?.Refund_Status !== 'Pending'))) ? true : false}
                >
                    Intimate
                </button>
            }
            {(TicketDetails?.HelpdeskC_Accepted_By && TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status && !TicketDetails?.HelpdeskC_Notes) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                    disabled={(TicketDetails?.HelpdeskC_Accepted_By?.Email === auth?.token?.result?.Email &&
                        TicketDetails?.Last_Intimate.includes('HelpdeskC') && TicketDetails?.Ticket_Approved_Status &&
                        (TicketDetails?.Ticket_Approved_Status && (OrderDetailsByTypeOfOrder?.PaymentStatus !== 'Paid' || TicketDetails?.Refund_Status !== 'Pending'))) ? true : false}
                >
                    Intimate
                </button>
            }
            {
                (TicketDetails?.HelpdeskV_Accepted_By && TicketDetails?.HelpdeskV_Accepted_By?.Email === auth?.token?.result?.Email && TicketDetails?.Last_Intimate.includes('HelpdeskV')) &&
                <button
                    className="btn btn-success float-start mr-3"
                    type="submit"
                    onClick={handleIntimatePopup}
                >
                    Intimate
                </button>
            } */}
        </>
    )
}