import React, { useState, useEffect } from 'react';

export default function ProductContains({ ArrayList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (ArrayList === undefined) {
            setList([]);
        } else {
            setList(ArrayList);
        };
    }, [ArrayList]);

    return (
        <div>
            {
                list.map((val, i) =>
                    <div key={i}>
                        <span className='ml-2'><b> {i+1}. </b> {val}</span> <br />
                    </div>
                )
            }
        </div>
    )
};