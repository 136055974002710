import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { PushNotification } from "../../../common/PushNotification";

export default function UpdateCustomerApproval(props) {

    const { showUpdateCustomerApprovalPopup, handleCloseUpdateApprovalPopup,
        handleIntimateToCustomer, Ticket_Response, loader, UserDetails } = props;

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const handleClose = (e) => {
        reset();
        handleCloseUpdateApprovalPopup(e);
    };

    const handleUpdate = (data) => {
        if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let bodyContent;
            if(data.Ticket_Approved_Status && data.Ticket_Approved_Status === 'Approved'){
                bodyContent = 'Price Received for your Customised Cake Request'
            }else if(data.Ticket_Approved_Status && data.Ticket_Approved_Status === 'Not Approved') {
                bodyContent = 'Your Customised Cake is Cancelled as per your Request'
            }
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: bodyContent,
            }
            PushNotification(pushMessageData);
        }
        handleIntimateToCustomer(data);
    };

    useEffect(() => {
        if (Ticket_Response?.statusCode === 200) {
            reset();
        };
        //eslint-disable-next-line
    }, [Ticket_Response]);

    return (
        <>
            <Modal show={showUpdateCustomerApprovalPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">

                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Update Approval Status</h4>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Notes<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <textarea
                                    className={errors?.Input ? "form-control text-capitalize required-field" : "form-control text-capitalize"}
                                    type='text'
                                    placeholder="Enter Here..."
                                    {...register('Input', { required: { value: true, message: 'required' } })}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Customer Approved Status<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select
                                    className={errors?.Ticket_Approved_Status ? "form-control required-field" : "form-control"}
                                    {...register('Ticket_Approved_Status', { required: { value: true, message: 'required' } })}
                                    defaultValue=''
                                >
                                    <option value='' disabled>--Select--</option>
                                    <option value='Approved'>Approved</option>
                                    <option value='Not Approved'>Not Approved</option>
                                </select>
                            </div>
                        </div>
                        {/* {showField &&
                            <>
                                {CauseOfTicket === 'Order Cancellation by Customer' &&
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cancellation Charge<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                className={errors?.Cancellation_Charges ? "form-control required-field" : "form-control"}
                                                type='number'
                                                {...register('Cancellation_Charges')}
                                                defaultValue={0}
                                                onChange={handleChancellationCharges}
                                            />
                                        </div>
                                    </div>
                                }
                                {CauseOfTicket === 'Change Requests' &&
                                    <>
                                        {TicketDetails?.Flavour?.length > 0 &&
                                            <>
                                                <div className="col-md-12">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Existing Flavours Price Per Kg </label><br />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <FlavourListArray FlavoursList={OrderDetailsByTypeOfOrder?.Flavour} />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <FlavourList Flavours={JSON.stringify(TicketDetails?.Flavour)}
                                                        handleFlavourPrice={handleFlavourPrice}
                                                        flavourValidate={flavourValidate}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price for Flavour</label>
                                                    <div className="input-group">
                                                        <div
                                                            className={flavourCostDifference?.Validation ? "form-control required-field" : "form-control"}
                                                        >Rs. {flavourCostDifference?.Difference ? flavourCostDifference?.Difference : 0}</div>
                                                    </div>
                                                </div>

                                            </>
                                        }
                                        {TicketDetails?.Shape &&
                                            <>
                                                <div className="col-md-12">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Existing Shape Price Per Kg </label><br />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <div>
                                                        <span>{`${OrderDetailsByTypeOfOrder?.Shape?.Name} - Additional Rs.${OrderDetailsByTypeOfOrder?.Shape?.Price}/Kg`}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <ShapesList Shape={JSON.stringify(TicketDetails?.Shape)}
                                                        handleShapePrice={handleShapePrice}
                                                        shapeValidate={shapeValidate}
                                                    />
                                                </div>
                                                <div className="col-md-12 mb-3">
                                                    <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price for Shape</label>
                                                    <div className="input-group">
                                                        <div
                                                            className={shapeCostDifference?.Validation ? "form-control required-field" : "form-control"}
                                                        >Rs. {shapeCostDifference?.Difference ? shapeCostDifference?.Difference : 0}</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {(TicketDetails?.Flavour?.length > 0 || TicketDetails?.Shape) ?
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference in Price</label>
                                                <div className="input-group">
                                                    <div
                                                        className="form-control"
                                                    >Rs. {differenceInCost ? differenceInCost : 0}</div>
                                                </div>
                                            </div> :
                                            <div className="col-md-12 mb-3">
                                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Enter Difference in Price (Only Positive Price) <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <input
                                                        className={errors?.Difference_In_Price ? "form-control required-field" : 'form-control'}
                                                        {...register('Difference_In_Price')}
                                                        defaultValue={0} maxLength={4} min={0}
                                                        onInput={(e) => {
                                                            e.target.value = e.target.value?.replace(/[^0-9]/, '')
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value?.replace(/[^0-9]/g, '')) : null
                                                            }
                                                        }}
                                                        onChange={handleDifferenceInPrice}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        } */}
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleSubmit(handleUpdate)}
                            >
                                Intimate to Customer
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
};