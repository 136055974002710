import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from './actions'
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const VendorCakeListSaga = function* () {

    yield all([
        yield takeEvery(actions.GET_VENDOR_CAKE_LIST, VendorCakesListById),
        yield takeEvery(actions.GET_VENDOR_NOTIFICATION, GetVendorNotification),
        yield takeEvery(actions.REMOVE_NOTIFICATION_BY_ID, RemoveNotificationById),
        yield takeEvery(actions.REMOVE_NOTIFICATION_BY_VENDORID, RemoveNotificationByVendorId),
        yield takeEvery(actions.CREATE_BANKNAME, CreateBankName),
        yield takeEvery(actions.DELETE_BANKNAME, DeleteBankName),
        yield takeEvery(actions.GET_BANKNAME, GetBankName),
        yield takeEvery(actions.GET_ALL_CAKES_LIST_BY_VENDOR, GetAllCakesListByVendor),
    ]);
};

//get vendor cakes list by id
const VendorCakesListById = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/listbyId/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_CAKE_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};
const GetVendorNotification = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/vendors/notification/${payload}`
            )
        );
        yield put({
            type: actions.SET_VENDOR_NOTIFICATION,
            payload: { VendorNotification: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveNotificationById = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/vendors/deletenotificationbyId/${payload.id}`
            )
        );
        yield put({
            type: actions.GET_VENDOR_NOTIFICATION,
            payload: payload.VendorID
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const RemoveNotificationByVendorId = function* (data) {
    const { payload } = data;

    try {
        yield call(() =>
            axios.delete(
                `${API_URL}/vendors/deletenotification/${payload}`
            )
        );
        yield put({
            type: actions.GET_VENDOR_NOTIFICATION,
            payload: payload
        });

    } catch (err) {
        console.log(err.response.data);
    };

};

const CreateBankName = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.post(
                `${API_URL}/bankname/new`, { BankName: payload }
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield put({ type: actions.GET_BANKNAME });
        yield put({ type: actions.VENDORCAKELIST_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.VENDORCAKELIST_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const DeleteBankName = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.delete(
                `${API_URL}/bankname/delete/${payload}`
            )
        );
        if (result.data.statusCode === 200) {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        }
        yield put({ type: actions.GET_BANKNAME });
        yield put({ type: actions.VENDORCAKELIST_RESPONSE, payload: result.data });
        yield delay(2000);
        yield put({ type: actions.VENDORCAKELIST_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetBankName = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/bankname/list`
            )
        );
        yield put({
            type: actions.SET_BANKNAME,
            payload: { BankName: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const GetAllCakesListByVendor = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/cake/listbyVendorId/${payload}`
            )
        );
        yield put({
            type: actions.SET_ALL_CAKES_LIST_BY_VENDOR,
            payload: { VendorCakes: result.data }
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

export default VendorCakeListSaga;