import React from 'react';
import { Modal } from "react-bootstrap";
import CircleDotSpinner from '../../../../common/CircleDotSpinner';

export default function SendPaymentLinkPopup(props) {

    const { showPaymentListPopup, handleClosePaymentLinkPopup, TicketDetails,
        handleSendPaymentLink, loader, OrderDetailsByTypeOfOrder } = props;

    return (
        <div>
            <Modal show={showPaymentListPopup} onHide={handleClosePaymentLinkPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                {(OrderDetailsByTypeOfOrder?.PaymentType?.toLowerCase() === 'cash on delivery' || TicketDetails?.Difference_In_Price === 0) ?
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClosePaymentLinkPopup}><span aria-hidden="true">×</span></button>
                        <h4 className="text-center pb-2">Confirmation</h4>
                        <div>
                            <h6 className='text-center'>Are you sure you want to intimate to Customer?</h6>
                        </div>
                        {loader ?
                            <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSendPaymentLink}
                                >
                                    Yes
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClosePaymentLinkPopup}
                                >
                                    No
                                </button>
                            </div>
                        }
                    </Modal.Body> :
                    <Modal.Body className="tabledata-background modal-body-content">
                        <button type="button" className="close change-password-close" onClick={handleClosePaymentLinkPopup}><span aria-hidden="true">×</span></button>
                        <h4 className="text-center pb-2">Send Payment Link</h4>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Difference In Price<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <div className='form-control'>Rs. {TicketDetails?.Difference_In_Price}</div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className='float-left font-weight-bold' htmlFor="validationCustom18">Total Difference in Price With GST<span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <div className='form-control'>Rs. {TicketDetails?.Total_Difference_In_Price}</div>
                                </div>
                            </div>
                        </div>
                        {loader ?
                            <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button
                                    type="submit"
                                    className="btn btn-primary shadow-none mr-3"
                                    onClick={handleSendPaymentLink}
                                >
                                    Submit
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-secondary shadow-none"
                                    onClick={handleClosePaymentLinkPopup}
                                >
                                    Cancel
                                </button>
                            </div>
                        }
                    </Modal.Body>
                }
            </Modal>
        </div>
    )
};