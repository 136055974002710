import { takeEvery, call, put, all, delay } from 'redux-saga/effects';
import actions from "./actions";
import CommonActions from '../Common/actions';
import { API_URL } from '../../utils/constants';
import axios from 'axios';

const TicketSaga2 = function* () {

    yield all([
        yield takeEvery(actions.FULLY_CUSTOMIZED_CAKE_FINAL_PRICE_SEND_BY_MANAGER, FullyCustomizedCakeFinalPriceSendByManager),
        yield takeEvery(actions.UPDATE_CUSTOMER_APPROVAL_BY_HELPDESKC, UpdateCustomerApprovalByHelpdeskC),
        yield takeEvery(actions.HELPDESKV_CUSTOMIZED_CAKE_SELECT_VENDOR, HelpdeskVCustomizedCakeSelectVendor),
        yield takeEvery(actions.HELPDESKV_CUSTOMIZED_CAKE_UPDATE_VENDOR_APPROVE_STATUS, HelpdeskVCustomizedCakeUpdateVendorApproveStatus),
        yield takeEvery(actions.HELPDESKV_REMARKS, HelpdeskVRemarks),
        yield takeEvery(actions.VENDOR_CUSTOMIZED_CAKE_UPDATE_TAX_BY_HELPDESKV, VendorCustomizedCakeUpdateTaxByHelpdeskV),
        yield takeEvery(actions.INTIMATE_TO_CUSTOMER_FOR_PAYMENT, IntimateToCustomerForPayment),
        yield takeEvery(actions.INTIMATE_TO_VENDOR_FOR_VENDORCC_TO_ORDER, IntimateToVendorForVendorCCToOrder),
        yield takeEvery(actions.GET_REJECTED_ORDERS_LIST, GetRejectedOrdersList),
        yield takeEvery(actions.UPDATE_HELPDESKV_REMARKS_FOR_VENDOR_CANCEL, UpdateHelpdeskVRemarksForVendorCancel),
        yield takeEvery(actions.REFUND_FOR_CANCEL_WITHIN_15MINS, refundForCancelWithin15Mins),
        yield takeEvery(actions.INTIMATE_TO_CUSTOMER_FOR_CANCEL, intimateToCustomerForCancel),
        yield takeEvery(actions.GET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER, getDashboardTicketsListByTypeOfUser),
        yield takeEvery(actions.GET_ALL_TICKETS_LIST, getAllTicketsList),
        yield takeEvery(actions.INTIMATE_TO_VENDOR_FOR_CC_ORDERS, intimateToVendorForCCOrders),
        yield takeEvery(actions.INTIMATE_TO_ACCOUNTS_AND_CUSTOMER_FOR_CUSTOMER_CANCEL, intimateToAccountsAndCustomerForCustomerCancel),
        yield takeEvery(actions.MISCELLANEOUS_APPROVAL_BY_MANAGER, MiscellaneousApprovalByManager),
        yield takeEvery(actions.MISCELLANEOUS_REFUND_BY_ACCOUNTS, MiscellaneousRefundByAccounts),
        yield takeEvery(actions.HELPDESKC_REMARKS_FOR_MISCELLANEOUS, HelpdeskCRemarksForMiscellaneous),
    ]);
};

const FullyCustomizedCakeFinalPriceSendByManager = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/updateCustomizedCakePrice/Manager/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({
                type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
                payload: { Id: payload.OrderID, TypeOfOrder: payload.TypeOfOrder }
            });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateCustomerApprovalByHelpdeskC = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/updateCustomerApproval/HelpdeskC/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskVCustomizedCakeSelectVendor = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/helpdeskVcustomizedCake/selectVendor/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskVCustomizedCakeUpdateVendorApproveStatus = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/helpdeskVcustomizedCake/updateStatus/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskVRemarks = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/helpdeskVcustomizedCake/remarks/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const VendorCustomizedCakeUpdateTaxByHelpdeskV = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/vendorCustomizedCake/updateTax/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({
                type: actions.GET_ORDER_DETAILS_BY_TYPEOFORDER,
                payload: { Id: payload.OrderID, TypeOfOrder: payload.TypeOfOrder }
            });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const IntimateToCustomerForPayment = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/vendorCustomizedCake/customerIntimation/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const IntimateToVendorForVendorCCToOrder = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/vendorCustomizedCake/vendorIntimation/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const GetRejectedOrdersList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/rejectedOrders/list`
            )
        );
        yield put({
            type: actions.SET_REJECTED_ORDERS_LIST,
            payload: result.data
        });

    } catch (err) {
        console.log(err.response.data);
    };
};

const UpdateHelpdeskVRemarksForVendorCancel = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/updateHelpdeskVRemarks/VendorCancel/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const refundForCancelWithin15Mins = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/helpdesk/CancelWithin15Mins/Refund/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const intimateToCustomerForCancel = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/helpdesk/CancelWithin15Mins/intimateToCustomer/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getDashboardTicketsListByTypeOfUser = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/dashboardTickets/${payload.TypeOfUser}/${payload.Id}`
            )
        );
        yield put({ type: actions.SET_DASHBOARD_TICKETS_LIST_BY_TYPEOFUSER, payload: result.data });
    } catch (err) {
        console.log(err.response.data);
    };
};

const getAllTicketsList = function* () {

    try {
        const result = yield call(() =>
            axios.get(
                `${API_URL}/tickets/allList`
            )
        );
        yield put({ type: actions.SET_ALL_TICKETS_LIST, payload: result.data });
    } catch (err) {
        console.log(err.response.data);
    };
};

const intimateToVendorForCCOrders = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/intimateToVendor/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const intimateToAccountsAndCustomerForCustomerCancel = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/intimateToAccountsAndCustomer/customerCancel/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const MiscellaneousApprovalByManager = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/approvalByManager/miscellaneous/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const MiscellaneousRefundByAccounts = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/refundByAccounts/miscellaneous/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

const HelpdeskCRemarksForMiscellaneous = function* (data) {

    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.put(
                `${API_URL}/tickets/helpdeskCRemarks/miscellaneous/${payload.Id}`, payload.data
            )
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: actions.GET_TICKET_DETAILS, payload: payload.Id });
            yield put({ type: actions.TICKET_RESPONSE, payload: result.data });
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'success',
                }
            });
        } else {
            yield put({
                type: CommonActions.SET_ALERT,
                payload: {
                    alert: true,
                    message: result.data.message,
                    status: 'failed',
                }
            });
        };
        yield delay(2000);
        yield put({ type: actions.TICKET_RESPONSE, payload: [] });
        yield put({
            type: CommonActions.SET_ALERT,
            payload: {
                alert: false,
                message: null,
                status: null,
            }
        });
    } catch (err) {
        console.log(err.response.data);
    };
};

export default TicketSaga2;