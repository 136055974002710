import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import VendorActions from '../../../../redux/Vendor/actions';
import CircleDotSpinner from "../../../common/CircleDotSpinner";

export default function VendorApprovedStatusPopup(props) {

    const { handleCloseVendorApprovedStatusPopup, showUpdateVendorApprovedStatusPopup,
        TicketDetails, VendorDetails, handleUpdateVendorApprovalStatus, loader } = props;

    const dispatch = useDispatch();

    const { VendorDetails2 } = useSelector(state => state.VendorReducer);

    const [status, setStatus] = useState('');

    const handleUpdateStatus = (e) => {
        setStatus(e.target.value);
        if (e.target.value === 'Approved') {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS2, payload: TicketDetails?.Selected_Vendor?.VendorID })
        } else {
            dispatch({ type: VendorActions.SET_VENDOR_DETAILS2, payload: [] });
        };
    };

    const handleClose = (e) => {
        setStatus('');
        dispatch({ type: VendorActions.SET_VENDOR_DETAILS2, payload: [] });
        handleCloseVendorApprovedStatusPopup(e);
    };

    return (
        <div>
            <Modal show={showUpdateVendorApprovedStatusPopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Update Status</h4>
                    <div className="form-row">
                        {TicketDetails?.CauseOfTicket !== 'Cakey Direct-General Order' &&
                            <>
                                <div className="col-md-12">
                                    <label className='float-left font-weight-bold'>Existing Vendor <span className='text-danger'>*</span></label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-control">{VendorDetails?.Id} - {VendorDetails?.VendorName}</div>
                                </div>
                            </>
                        }
                        <div className="col-md-12">
                            <label className='float-left font-weight-bold'>Selected Vendor <span className='text-danger'>*</span></label>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="form-control">{TicketDetails?.Selected_Vendor?.Vendor_ID} - {TicketDetails?.Selected_Vendor?.VendorName}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Selected Vendor Approved Status<span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select
                                    className="form-control"
                                    // className={errors?.Ticket_Approved_Status ? "form-control required-field" : "form-control"}
                                    // {...register('Ticket_Approved_Status', { required: { value: true, message: 'required' } })}
                                    defaultValue=''
                                    onChange={handleUpdateStatus}
                                >
                                    <option value='' disabled>--Select--</option>
                                    <option value='Approved'>Approved</option>
                                    <option value='Change Vendor'>Change Vendor</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-success text-center intimation-message">{(status && status === 'Approved') ? 'This will be auto intimated to Customer Helpdesk,Vendor Helpdesk and Vendor' :
                            (status && status === 'Change Vendor') ? 'This will be auto intimated to Vendor Helpdesk' : ''}</p>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                disabled={status ? false : true}
                                className="btn btn-primary shadow-none mr-3"
                                onClick={(e) => handleUpdateVendorApprovalStatus(e, status, VendorDetails2)}
                            >
                                Submit
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleClose}
                            >
                                Cancel
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};