import React, { useEffect, useState } from "react"

export default function CakeDessertWeight({ option, className, handleCDWRemoveButton, handleCDWInputValue, disabledButton,
    handleCDWSelectButton, handleAddOtherWeight, cakeDessertWeightAndPriceList }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        if (option === undefined) {
            setList([]);
        } else {
            setList(option);
        };
    }, [option]);

    return (
        <div>
            <label htmlFor="validationCustom22" className='font-weight-bold'>Minimum Weight and Price Per Unit <span className='text-danger'>*</span></label>
            <div className="scrollable-list">
                {disabledButton?.length > 0 &&
                    <div className="display-flex mb-2 list-main justify-content-between">
                        <div className='font-weight-bold ml-2'>Weight</div>
                        <div className="font-weight-bold">Minimum Count</div>
                        <div className="font-weight-bold mr-2">Price per Unit</div>
                    </div>
                }
                {list.map((value, i) =>
                    <div key={i}>
                        {!disabledButton?.includes(i) ?
                            <>
                                <div className="display-flex mb-2 list-main">
                                    <div className={className}>{value}</div>
                                    <button
                                        className='add-button'
                                        value={value}
                                        onClick={(e) => handleCDWSelectButton(e, i)}
                                    > + </button>
                                </div>
                            </> :
                            <>
                                <div className="display-flex mb-2 list-main justify-content-between">
                                    <div className="display-flex">
                                        <button className='mr-1 minus-button' value={value} onClick={(e) => handleCDWRemoveButton(e, i)}> - </button>
                                        <div className={className}> {value}</div>
                                    </div>
                                    <div>
                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={3} onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            } else if (e.target.value < e.target.min) {
                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                            }
                                        }} className="price-input-others mr-1" name="MinCount" placeholder="e.g. 4" onChange={(e) => handleCDWInputValue(e, value)} />
                                    </div>
                                    <div className="mr-2">
                                        <input type="number" onWheel={() => document.activeElement.blur()} min={0} maxLength={5} onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            } else if (e.target.value < e.target.min) {
                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                            }
                                        }} className="price-input-others" name="PricePerUnit" placeholder="e.g. 400" onChange={(e) => handleCDWInputValue(e, value)} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                )}
                <div className="font-weight-bold text-center pb-2 pt-2 cursor border-top add-weight-hover" onClick={(e) => handleAddOtherWeight(e, 'Cake Dessert Weight Per Unit')}>Add New Minimum Weight</div>
            </div>
            {cakeDessertWeightAndPriceList?.map((val, i) =>
                <div className="list-array" key={i}>
                    {val.Weight} -  Min {val.MinCount ? val.MinCount : 0} Unit - Rs. {val.PricePerUnit? val.PricePerUnit : 0} / Unit
                </div>
            )}
        </div>
    )
};