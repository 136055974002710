import actions from "./actions";

const initialState = {
  CakesList: [],
  addCakeStatus: "",
  CakeDetails: [],
  UpdatecakeStatus: '',
  DeleteCakeStatus : [],
  CakesListByStatus : [],
  ApproveCakeStatus : [],
  Newandupdatedcakes : [],
  ApproveUpdatedCakeStatus: [],
  AdminCakesList: [],
  CakeResponse: [],
};

const CakesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ALL_CAKES:
      return {
        ...state,
        CakesList: action.payload

      }
    case actions.Add_CAKES_STATUS:
      return {
        ...state,
        addCakeStatus: action.payload.addCakeStatus
      }
    case actions.SET_SINGLE_CAKEDETAILS:
      return {
        ...state,
        CakeDetails: action.payload.CakeDetails
      }
    case actions.UPDATE_CAKES_STATUS:
      return {
        ...state,
        UpdatecakeStatus: action.payload.UpdatecakeStatus
      }
    case actions.SET_DELETE_CAKE_STATUS :
      return {
        ...state,
        DeleteCakeStatus : action.payload.DeleteCakeStatus
      }
      case actions.SET_ALL_CAKES_BY_STATUS :
      return {
        ...state,
        CakesListByStatus : action.payload.CakesListByStatus
      }
      case actions.APPROVE_CAKE_STATUS :
      return {
        ...state,
        ApproveCakeStatus : action.payload.ApproveCakeStatus
      }
      case actions.UPDATE_APPROVED_CAKES_STATUS :
      return {
        ...state,
        ApproveUpdatedCakeStatus : action.payload.ApproveUpdatedCakeStatus
      }
      case actions.SET_NEW_AND_UPDATED_CAKES_LIST:
            return {
                ...state,
                Newandupdatedcakes: action.payload.Newandupdatedcakes,
            };
      case actions.SET_ADMIN_CAKES_LIST:
            return {
                ...state,
                AdminCakesList: action.payload.AdminCakesList,
            };
      case actions.CAKE_RESPONSE:
            return {
                ...state,
                CakeResponse: action.payload,
            };
    
    default:
      return state;
  }
};

export default CakesReducer;