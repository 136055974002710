import actions from "./actions";

const initialState = {
    FlavourList: [],
    ShapeList: [],
    WeightList: [],
    CakeToppingsList: [],
    AddNewFlavourStatus: [],
    AddNewShapeStatus: [],
    ArticleList: [],
    AddNewArticleStatus: [],
    AddNewWeightStatus: [],
    DeleteFlavourStatus: [],
    DeleteShapeStatus: [],
    DeleteWeightStatus: [],
    DeleteArticleStatus: [],
    CakeType: [],
    Flavours: [],
    Shapes : [],
    CakeArrayResponse: [],
};

const CakeArrayReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_FLAVOUR_LIST:
            return {
                ...state,
                FlavourList: action.payload.FlavourList
            }
        case actions.SET_SHAPE_LIST:
            return {
                ...state,
                ShapeList: action.payload.ShapeList
            }
        case actions.SET_WEIGHT_LIST:
            return {
                ...state,
                WeightList: action.payload.WeightList
            }
        case actions.SET_CAKETOPPINGS_LIST:
            return {
                ...state,
                CakeToppingsList: action.payload.CakeToppingsList
            }
        case actions.ADD_NEW_FLAVOUR_STATUS:
            return {
                ...state,
                AddNewFlavourStatus: action.payload.AddNewFlavourStatus
            }
        case actions.ADD_NEW_SHAPE_STATUS:
            return {
                ...state,
                AddNewShapeStatus: action.payload.AddNewShapeStatus
            }
        case actions.SET_ARTICLE_LIST:
            return {
                ...state,
                ArticleList: action.payload.ArticleList
            }
        case actions.ADD_NEW_ARTICLE_STATUS:
            return {
                ...state,
                AddNewArticleStatus: action.payload.AddNewArticleStatus
            }
        case actions.ADD_NEW_WEIGHT_STATUS:
            return {
                ...state,
                AddNewWeightStatus: action.payload.AddNewWeightStatus
            }
        case actions.DELETE_FLAVOUR_STATUS:
            return {
                ...state,
                DeleteFlavourStatus: action.payload.DeleteFlavourStatus
            }
        case actions.DELETE_SHAPE_STATUS:
            return {
                ...state,
                DeleteShapeStatus: action.payload.DeleteShapeStatus
            }
        case actions.DELETE_WEIGHT_STATUS:
            return {
                ...state,
                DeleteWeightStatus: action.payload.DeleteWeightStatus
            }
        case actions.DELETE_ARTICLE_STATUS:
            return {
                ...state,
                DeleteArticleStatus: action.payload.DeleteArticleStatus
            }
        case actions.SET_CAKE_TYPE:
            return {
                ...state,
                CakeType: action.payload.CakeType
            }
        case actions.SET_ALL_FLAVOURS_LIST:
            return {
                ...state,
                Flavours: action.payload.Flavours
            }
        case actions.SET_ALL_SHAPES_LIST:
            return {
                ...state,
                Shapes: action.payload.Shapes
            }
        case actions.CAKE_ARRAY_RESPONSE:
            return {
                ...state,
                CakeArrayResponse: action.payload
            }
        default:
            return state;
    }
}

export default CakeArrayReducer;