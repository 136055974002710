import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import HelpdeskAction from '../../../../redux/Helpdesk/actions';
import moment from 'moment';

export default function LastSeen(props) {

    const { active, email } = props;

    const dispatch = useDispatch();

    const [count, setCount] = useState(0);
    const [lastSeen, setLastSeeen] = useState("");

    const { alseenListByType } = useSelector(state => state.HelpdeskReducer);

    useEffect(() => {
        if (count && !active) {
            setLastSeeen(moment().format('DD-MM-YYYY hh:mm A'));
            dispatch({ type: HelpdeskAction.GET_LAST_SEEN_LIST_BY_ID, payload: "Helpdesk V" })
            setCount(0)
        } else {
            setCount(1)
            setLastSeeen(alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.ClockOut_At ?
                alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.ClockOut_At === 'Active' ?
                    moment().format('DD-MM-YYYY hh:mm A') : alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.ClockOut_At :
                moment().format('DD-MM-YYYY hh:mm A'))
            // setLastSeeen(alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.LastLogout_At === 'Active' ?
            //     moment().format('DD-MM-YYYY hh:mm A') : alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.LastLogout_At)
        }
        //eslint-disable-next-line
    }, [active]);

    return (
        <span className="ms-chat-time mt-2">
            {!active ?
                (alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.ClockOut_At ?
                    alseenListByType?.find(el => (el?.InternalUserEmail === email) || (el?.Email === email))?.ClockOut_At === 'Active' ? 'Active' :
                        moment(lastSeen, 'DD-MM-YYYY hh:mm A').fromNow() : '')
                : "Active"}
        </span>
    );
};
