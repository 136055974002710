import React from 'react'
import { Helmet } from 'react-helmet'
import Sidenavigation from '../Layouts/Sidenavigation'
import Topnavigation from '../Layouts/Topnavigation'
import Content from '../../CommonFolders/Helpdesk-Login-History/Content'

export default function HelpdeskVLoginHistory(){
    return(
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Vendor Helpdesk Login History</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content Page='Management' TypeOfUser='Helpdesk V'/>
                {/* page content end  */}

            </main>

        </div>
    )
}