import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../redux/cake/actions';
import VendorActions from '../../../../redux/Vendor/actions';
import { useLocation, useHistory } from 'react-router-dom';
import FlavourList from '../../../sections/ArrayMapping/FlavourList';
import WeightList from '../../../sections/ArrayMapping/WeightList';
import { Modal } from 'react-bootstrap';
import CircleDotSpinner from '../../../common/CircleDotSpinner';
import CakeImageSlider from '../../../vendor/section/Vendor-Cake-Details/CakeImageSlider';
import Preloader from '../../../layouts/Preloader';
import CakeTypeLists from '../../../sections/ArrayMapping/CakeType';
import Information from '../../../sections/ArrayMapping/Information';
import ShapesWithWeightList from '../../../sections/ArrayMapping/ShapesWithWeightList';
import { float2D } from '../../../../utils/Validation';
import { PushNotification } from '../../../common/PushNotification';

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const CakeId = state?.state?.cakeId;

    const [show, setShow] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [sliderImages, setSliderImages] = useState([]);
    const [message, setMessage] = useState('');
    const [informationValidate, setInformationValidate] = useState('');

    const RatingsForVendor = useRef();
    const CakeCategory = useRef();
    const InformationRef = useRef();

    //get new cakes list
    const { CakeDetails, ApproveCakeStatus, CakeResponse } = useSelector(state => state.CakesReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    const VendorID = CakeDetails?.VendorID;

    const auth = useSelector(state => state.authReducer);

    useEffect(() => {
        dispatch({ type: actions.GET_SINGLE_CAKEDETAILS, payload: CakeId });
        // eslint-disable-next-line 
    }, [CakeId]);

    useEffect(() => {
        if (VendorID) {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: VendorID } });
        }
        // eslint-disable-next-line 
    }, [VendorID]);

    //approve new cake functionality
    const handleApprove = (e) => {
        e.preventDefault();
        setMessage('');
        if (RatingsForVendor.current.value && CakeCategory.current.value) {
            if (VendorDetails?.Notification_Id) {
                let pushMessageData = {
                    type: 'vendor',
                    registration_ids: [VendorDetails.Notification_Id],
                    title: 'Cakey',
                    body: "Your Cake is Approved",
                }
                PushNotification(pushMessageData);
            };
            const formdata = {
                RatingsForVendor: RatingsForVendor.current.value,
                CakeCategory: CakeCategory.current.value,
                Status: 'Approved'
            };
            setLoader(true);
            dispatch({ type: actions.APPROVE_CAKE, payload: { id: CakeDetails?._id, data: formdata } });
        } else {
            setMessage("All fields are mandatory");
        };
    };

    useEffect(() => {
        if (CakeDetails?.AdditionalCakeImages?.length > 0 || CakeDetails?.AdditionalCakeImages !== undefined) {
            setSliderImages([...CakeDetails?.AdditionalCakeImages, CakeDetails?.MainCakeImage]);
        } else {
            setSliderImages([CakeDetails?.MainCakeImage]);
        };
    }, [CakeDetails]);

    //approve cake status validation
    useEffect(() => {
        if (ApproveCakeStatus?.statusCode === 200 || ApproveCakeStatus?.statusCode === 400) {
            setLoader(false);
            setShow(false);
            setMessage('');
        };
        // eslint-disable-next-line 
    }, [ApproveCakeStatus]);

    const handleApproveValidate = (e) => {
        e.preventDefault();
        if (CakeDetails?.Status === 'New' || (CakeDetails?.Status === 'Updated' && !CakeDetails?.CakeCategory)) {
            setShow(true)
        } else {
            const data = {
                Status: 'Approved'
            };
            if (VendorDetails?.Notification_Id) {
                let pushMessageData = {
                    type: 'vendor',
                    registration_ids: [VendorDetails.Notification_Id],
                    title: 'Cakey',
                    body: "Your Updated Cake is Approved",
                }
                PushNotification(pushMessageData);
            };
            dispatch({ type: actions.UPDATE_APPROVED_CAKES, payload: { id: CakeDetails?._id, data: data } });
        }
    };

    const handleCloseApprovePopup = () => {
        setShow(false);
        setMessage('');
        RatingsForVendor.current.value = '';
        CakeCategory.current.value = '';
    };

    const handleSendInfo = (e) => {
        e.preventDefault();
        if (InformationRef.current.value) {
            setInformationValidate("");
            if (VendorDetails?.Notification_Id) {
                let pushMessageData = {
                    type: 'vendor',
                    registration_ids: [VendorDetails.Notification_Id],
                    title: 'Cakey',
                    body: "Information from Admin",
                }
                PushNotification(pushMessageData);
            };
            if (auth.token) {
                const formdata = {
                    Information: InformationRef.current.value,
                    Created_By: auth?.token?.result?.Name
                };
                setLoader(true);
                dispatch({ type: actions.SEND_INFORMATION, payload: { id: CakeDetails._id, body: formdata } });
            }
        } else {
            setInformationValidate("Type Information");
            setShowInfoPopup(true);
        }
    };

    const handleCloseInfoPopup = () => {
        setShowInfoPopup(false);
        InformationRef.current.value = '';
        setInformationValidate(false);
    };

    useEffect(() => {
        if (CakeResponse?.statusCode === 200 || CakeResponse?.statusCode === 400) {
            setShowInfoPopup(false);
            setInformationValidate(false);
            setLoader(false);
        };
    }, [CakeResponse]);

    const UpdateCakeDetails = () => {
        history.push('/helpdeskV-Cake-Update', { cake: CakeDetails, id: auth?.token?.result?._id, TypeOfUser: "Helpdesk V" });
    };

    const handleClose = () => {
        (state?.state?.page === 'newcakeslist') ? history.push('/helpdeskV-New-Cakes-List') :
            (state?.state?.page === 'cakeslist') ? history.push('/helpdeskV-Approved-Cakes-List') : history.push('/');
    };

    useEffect(() => {
        (CakeDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CakeDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel tabledata-background">
                                <div className="ms-panel-header">
                                    <h6 >Cake Details</h6>
                                </div>
                                <div className="ms-panel-body">
                                    <CakeImageSlider image={sliderImages} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CakeDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Common Name</th>
                                                <td><span className="long-line-break">{CakeDetails?.CakeCommonName}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Type</th>
                                                <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeType} /></span></td>
                                            </tr>
                                            {CakeDetails?.CakeSubType?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Cake SubType</th>
                                                    <td><span className="long-line-break"><CakeTypeLists TypeList={CakeDetails?.CakeSubType} /></span></td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Default Cake Egg Or Eggless</th>
                                                <td>{CakeDetails?.DefaultCakeEggOrEggless}</td>
                                            </tr>
                                            {CakeDetails?.DefaultCakeEggOrEggless !== 'Eggless' &&
                                                <tr>
                                                    <th scope="row">Is Eggless option available</th>
                                                    <td>{CakeDetails?.IsEgglessOptionAvailable === 'y' ? 'Yes' : 'No'}</td>
                                                </tr>
                                            }
                                            {(CakeDetails?.BasicEgglessCostPerKg && CakeDetails?.IsEgglessOptionAvailable === 'y') &&
                                                <tr>
                                                    <th scope="row">Basic Eggless Cake cost per kg</th>
                                                    <td>{`Rs.${CakeDetails?.BasicEgglessCostPerKg}`}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Basic Flavour</th>
                                                <td><span className="long-line-break">{CakeDetails?.BasicFlavour}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Weight</th>
                                                <td>{CakeDetails?.MinWeight}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Shape</th>
                                                <td>{CakeDetails?.BasicShape}</td>
                                            </tr>
                                            {CakeDetails?.CustomFlavourList?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Custom Flavour</th>
                                                    <td><span className="long-line-break"><FlavourList FlavoursList={CakeDetails?.CustomFlavourList} /></span></td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinWeightList?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Minimum Weight List</th>
                                                    <td><span className="long-line-break"><WeightList WeightLists={CakeDetails?.MinWeightList} /></span></td>
                                                </tr>
                                            }
                                            {CakeDetails?.CustomShapeList?.Info?.length > 0 &&
                                                <tr>
                                                    <th scope="row">Custom Shape</th>
                                                    <td><span className="long-line-break"><ShapesWithWeightList ShapesList={CakeDetails?.CustomShapeList} /></span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">PRICE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Price</th>
                                                <td>{`Rs.${float2D(CakeDetails?.BasicCakePrice)}`}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Discount</th>
                                                <td>{CakeDetails?.Discount}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Tax</th>
                                                <td>{CakeDetails?.Tax}%</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Created On</th>
                                                <td>{CakeDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Availability</th>
                                                <td><span className={(CakeDetails?.Stock?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Stock}</span></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CakeDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{CakeDetails?.Status}</span></td>
                                            </tr>
                                            {CakeDetails?.CakeCategory &&
                                                <tr>
                                                    <th scope="row">Cake Category</th>
                                                    <td><span className={CakeDetails?.CakeCategory?.replace(/ /g, '')}>{CakeDetails?.CakeCategory}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {CakeDetails?.CustomShapeList?.SampleImages?.length > 0 &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6 >Sample Images for Shapes</h6>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <CakeImageSlider image={CakeDetails?.CustomShapeList?.SampleImages} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">OTHER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Minimum Time for Delivery of Default Cake({CakeDetails?.MinWeight})</th>
                                                <td>{CakeDetails?.MinTimeForDeliveryOfDefaultCake}</td>
                                            </tr>
                                            {CakeDetails?.MinTimeForDeliveryOfABelow2KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time for Delivery of 0.5Kg to 2Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfABelow2KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA2to4KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time for Delivery of 2Kg to 4Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA2to4KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfA4to5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time for Delivery of 4Kg to 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfA4to5KgCake}</td>
                                                </tr>
                                            }
                                            {CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake &&
                                                <tr>
                                                    <th scope="row">Minimum Time for Delivery of above 5Kg Cake</th>
                                                    <td>{CakeDetails?.MinTimeForDeliveryOfAAbove5KgCake}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cake Base</th>
                                                <td>{CakeDetails?.CakeBase}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Cream</th>
                                                <td>{CakeDetails?.CakeCream}</td>
                                            </tr>
                                            {CakeDetails?.ButterCreamType &&
                                                <tr>
                                                    <th scope="row">Butter Cream Type</th>
                                                    <td>{CakeDetails?.ButterCreamType}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Best before</th>
                                                <td>{CakeDetails?.BestUsedBefore}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">To Be Stored In</th>
                                                <td>{CakeDetails?.ToBeStoredIn}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Minimum Time for Keep the Cake in Room Temperature</th>
                                                <td>{CakeDetails?.KeepTheCakeInRoomTemperature}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Theme Cake Possible</th>
                                                <td>{CakeDetails?.ThemeCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Toppers Possible</th>
                                                <td>{CakeDetails?.ToppersPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Customisation Possible</th>
                                                <td>{CakeDetails?.BasicCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Full Customisation Possibility</th>
                                                <td>{CakeDetails?.FullCustomisationPossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How good are you in this Cake</th>
                                                <td>{CakeDetails?.HowGoodAreYouWithTheCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">How many times have you Baked this Particular Cake</th>
                                                <td>{CakeDetails?.HowManyTimesHaveYouBakedThisParticularCake}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Is Tier Cake Possible</th>
                                                <td>{CakeDetails?.IsTierCakePossible === 'y' ? 'Yes' : 'No'}</td>
                                            </tr>
                                            {/* {Status?.CakeDetails?.IsTierCakePossible === 'y' &&
                                                <tr>
                                                    <th scope="row">Tier cake Minimum weight, Price and Minimum time for Delivery</th>
                                                    <td></td>
                                                </tr>
                                            } */}
                                        </tbody>
                                    </table>
                                    {/* {Status?.CakeDetails?.IsTierCakePossible === 'y' &&
                                        <div className="row">
                                            <TierCakeList TierCakeMinWeightAndPrice={Status?.CakeDetails?.TierCakeMinWeightAndPrice}
                                                MinTimeForDeliveryFortierCake={Status?.CakeDetails?.MinTimeForDeliveryFortierCake}
                                            />
                                        </div>
                                    } */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">VENDOR INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Vendor ID</th>
                                                <td>{CakeDetails?.Vendor_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Name</th>
                                                <td>{CakeDetails?.VendorName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Vendor Main Mobile Number</th>
                                                <td>{CakeDetails?.VendorPhoneNumber1}</td>
                                            </tr>
                                            {CakeDetails?.VendorPhoneNumber2 &&
                                                <tr>
                                                    <th scope="row">Vendor Alternative Mobile Number</th>
                                                    <td>{CakeDetails?.VendorPhoneNumber2}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Vendor Address</th>
                                                <td><span className="long-line-break">{CakeDetails?.VendorAddress}</span></td>
                                            </tr>
                                            {CakeDetails?.GoogleLocation &&
                                                <tr>
                                                    <th scope="row">Google Location</th>
                                                    <td>
                                                        <b>Latitude</b> : {CakeDetails?.GoogleLocation?.Latitude} <br />
                                                        <b>Longitude</b> : {CakeDetails?.GoogleLocation?.Longitude}
                                                    </td>
                                                </tr>
                                            }
                                            {CakeDetails?.RatingsForVendor &&
                                                <tr>
                                                    <th scope="row">Rating for the Vendor for this Particular Cake</th>
                                                    <td><span className={CakeDetails?.RatingsForVendor}>{CakeDetails?.RatingsForVendor}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CakeDetails?.OtherInstructions &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">OTHER INSTRUCTIONS FOR CUSTOMERS</h4>
                                        <hr />
                                        <p>{CakeDetails?.OtherInstructions}</p>
                                    </div>
                                </div>
                            </div>
                        }
                        {CakeDetails?.SendInformation?.length > 0 &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Information Sent</h4>
                                        <hr />
                                        <Information InfoList={CakeDetails?.SendInformation} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                                    <hr />
                                    <p>{CakeDetails?.Description}</p>
                                    <hr />
                                    {CakeDetails?.Status === 'New' || CakeDetails?.Status === 'Updated' ?
                                        <>
                                            <button
                                                className="btn btn-success float-start mr-3"
                                                type="submit"
                                                onClick={handleApproveValidate}
                                            >
                                                Approve
                                            </button>
                                            <button
                                                className="btn btn-primary float-start mr-3"
                                                type="button"
                                                onClick={() => setShowInfoPopup(true)}
                                            >
                                                Send Information
                                            </button>
                                            <button
                                                className="btn btn-warning mr-3"
                                                type="button"
                                                onClick={UpdateCakeDetails}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="btn btn-dark float-start"
                                                type="button"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </>
                                        :
                                        <button
                                            className="btn btn-dark float-start"
                                            type="button"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* approve new cake Confirmation popup */}
                    <Modal className="modal-min" show={show} onHide={handleCloseApprovePopup} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleCloseApprovePopup}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Confirmation</h1>
                            <div className="form-row">
                                {/* <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cake Type <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" ref={CakeType} placeholder="Cake Type" />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className='float-left font-weight-bold' htmlFor="validationCustom18">Cake Subtype <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <input type="text" className="form-control" ref={CakeSubType} placeholder="Cake Subtype" />
                            </div>
                        </div>
                        <div className="col-md-12 mb-1">
                            <label className='float-left' htmlFor="validationCustom18"><b>Toppers possible?</b> <span className='text-danger'>*</span></label>
                            <div className="input-group">
                                <select className="form-control" ref={ToppersPossible} defaultValue='' id="validationCustom22" >
                                    <option value='' disabled='disabled'>-- Select --</option>
                                    <option value="y">Yes</option>
                                    <option value="n">No</option>
                                </select>
                            </div>
                        </div> */}
                                <div className="col-md-12 mb-1">
                                    <label className='float-left' htmlFor="validationCustom18"><b>Rating for the vendor for this Particular Cake</b> <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select className="form-control" ref={RatingsForVendor} defaultValue='' id="validationCustom22" >
                                            <option value='' disabled='disabled'>-- Select --</option>
                                            <option value="Beginner">Beginner</option>
                                            <option value="Good">Good</option>
                                            <option value="Excellent">Excellent</option>
                                            <option value="Specialist">Specialist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-1">
                                    <label className='float-left' htmlFor="validationCustom18"><b>Cake Category</b> <span className='text-danger'>*</span></label>
                                    <div className="input-group">
                                        <select className="form-control" ref={CakeCategory} defaultValue='' id="validationCustom22" >
                                            <option value='' disabled='disabled'>-- Select --</option>
                                            <option value="Regular">Regular</option>
                                            <option value="Special">Special</option>
                                            <option value="Premium">Premium</option>
                                            <option value="Rare">Rare</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                <>
                                    <p className='text-danger'>{message}</p>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mr-3"
                                        onClick={handleApprove}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        onClick={handleCloseApprovePopup}
                                    >
                                        Cancel
                                    </button>
                                </>}
                        </Modal.Body>
                    </Modal>
                    <Modal className="modal-min" show={showInfoPopup} onHide={handleCloseInfoPopup} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="text-center tabledata-background">
                            <button type="button" className="close" onClick={handleCloseInfoPopup}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center pb-2">Send Information</h1>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    {/* <label className='float-left font-weight-bold' htmlFor="validationCustom18">Information <span className='text-danger'>*</span></label> */}
                                    <div className="input-group">
                                        <textarea
                                            type="text"
                                            row={7}
                                            className="form-control text-capitalize mt-1"
                                            ref={InformationRef}
                                            placeholder="Information for vendors"
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className='text-danger text-left'>{informationValidate}</p>
                            {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :

                                <>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mr-3"
                                        onClick={handleSendInfo}
                                    >
                                        Send
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-secondary"
                                        onClick={handleCloseInfoPopup}
                                    >
                                        Cancel
                                    </button>
                                </>
                            }
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    );
};