import actions from "./actions";

const initialState = {
  PinCodeList: [],
  SalesCount : [],
};

const SampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PINCODE_LIST:
      return {
        ...state,
        PinCodeList: action.payload
      }
    case actions.SET_MONTHWISE_SALES_COUNT:
      return {
        ...state,
        SalesCount: action.payload
      }
    default:
      return state;
  }
};

export default SampleReducer;