import React from 'react';
import { IoMdHome } from 'react-icons/io';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {

    const { Page } = props;

    return (
        <nav aria-label="breadcrumb">
            {Page === 'Manager' ?
                <ol className="breadcrumb pl-0">
                    <li className="breadcrumb-item">
                        <Link to="/Manager-Dashboard"><IoMdHome className='home' /> Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                        <Link to="/Manager-Unclosed-Ticket-List">Unclosed Tickets</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Ticket Details</li>
                </ol> :
                Page === 'HelpdeskC' ?
                    <ol className="breadcrumb pl-0">
                        <li className="breadcrumb-item">
                            <Link to="/Helpdesk-C-Dashboard"><IoMdHome className='home' /> Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/Helpdesk-C-Unclosed-Tickets-List">Unclosed Tickets</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Ticket Details</li>
                    </ol> :
                    Page === 'Management' ?
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item">
                                <Link to="/Management-Dashboard"><IoMdHome className='home' /> Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/Management-Unclosed-Tickets-List">Unclosed Tickets</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Ticket Details</li>
                        </ol> :
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item">
                                <Link to="/helpdeskVdashboard"><IoMdHome className='home' /> Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/helpdeskV-Unclosed-Tickets-List">Unclosed Tickets</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Ticket Details</li>
                        </ol>
            }
        </nav>
    );
};

export default Breadcrumb;