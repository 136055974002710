
import React, { useEffect, useState } from "react";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import actions from "../../../../redux/VendorOrderList/actions";
import OrderActions from "../../../../redux/Order/actions";
import UserActions from '../../../../redux/users/actions';
import CommonActions from '../../../../redux/Common/actions';
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import Preloader from "../../../layouts/Preloader";
import FlavourList from "../../../sections/ArrayMapping/FlavourList";
import Breadcrumb from "./Breadcrumb";
import ImageSlider from "../../../common/ImageSlider";
import { float2D } from "../../../../utils/Validation";
import FileSizeValidation from "../../../common/FileSizeValidation";
import { PushNotification } from "../../../common/PushNotification";

export default function Details() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.OrderId;
    const Page = state?.state?.page;

    const [loader, setLoader] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [reasonForCancel, setReasonForCancel] = useState("");

    let [show, setShow] = useState(false);
    let [show2, setShow2] = useState(false);
    let [showError, setShowError] = useState("");
    let [showErr, setShowErr] = useState('');
    let [UpdatedStatus, setUpdatedStatus] = useState("");
    let [selectedImage, setSelectedImage] = useState();
    let [selectedImageUrl, setSelectedImageUrl] = useState();

    //get logged in users details
    const { token } = useSelector(state => state.authReducer);

    //get status for update order status
    const { GetVendorOrderDetails, UpdateOrderStatus } = useSelector(state => state.VendorOrderReducer);

    const { CancelOrderStatus } = useSelector(state => state.OrderReducer);

    const { UserDetails } = useSelector(state => state.registeredUserReducer);

    const UserID = GetVendorOrderDetails?.UserID;

    //dispatch the get orders list api
    useEffect(() => {
        dispatch({ type: actions.GET_VENDOR_ORDER_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        if (UserID) {
            dispatch({ type: UserActions.GET_USER_DETAILS, payload: UserID });
        }
        // eslint-disable-next-line
    }, [UserID]);

    //change order details view status
    useEffect(() => {
        if (GetVendorOrderDetails.length !== 0) {
            if (GetVendorOrderDetails.Vendor_Response_Status !== 'seen') {
                // if (GetVendorOrderDetails.Vendor_Response_Status === 'unseen') {
                dispatch({
                    type: OrderActions.CHANGE_ORDER_VIEW_RESPONSE_BY_VENDOR,
                    payload: { id: GetVendorOrderDetails._id, response: 'seen' }
                });
            }
        };
        // eslint-disable-next-line
    }, [GetVendorOrderDetails]);

    const ExistingStatus = GetVendorOrderDetails?.Status;
    useEffect(() => {
        (ExistingStatus === "Accepted") ? setUpdatedStatus('Preparing') :
            (ExistingStatus === "Preparing") ? setUpdatedStatus('Ready') :
                (ExistingStatus === "Ready") ? setUpdatedStatus('Out For Delivery') :
                    (ExistingStatus === "Out For Delivery") ? setUpdatedStatus('Delivered') :
                        setUpdatedStatus('');

    }, [ExistingStatus]);

    //open status change popup
    const handleShow = () => { setShow(true) };

    //open cancel order popup
    const handleShow2 = () => {
        setShow2(true);
        setShowErr(false);
    };

    //close status change popup
    const handleClose = () => {
        setShow(false);
        setShow2(false);
        setShowErr(false);
        setSelectedImage();
        setSelectedImageUrl();
        setUpdatedStatus("");
    };

    //close the page
    const handleCancel = (e) => {
        e.preventDefault();
        (Page === 'orderlist') ? history.push("/Vendor-OrderList") : (Page === 'vendordashboard') ?
            history.push("/vendordashboard") : history.push("/orders-report");
    };

    const handleAddImage = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setShowError('');
                setSelectedImage(selectedImage = result?.FinalImages[0]);
                if (selectedImage) {
                    setSelectedImageUrl(selectedImageUrl = URL.createObjectURL(selectedImage));
                }
            }
        }
    };

    //update order status functionality
    const handleUpdateStatus = (e) => {
        e.preventDefault();
        if (UpdatedStatus === "" || UpdatedStatus === undefined || UpdatedStatus === null) {
            setShowError("Please select the Status");
        } else {
            setLoader(true);
            setShowError("");
            if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
                let pushMessageData = {
                    type: 'customer',
                    registration_ids: [UserDetails.Notification_Id],
                    title: 'Cakey',
                    body: `Your Order Status - ${UpdatedStatus}`,
                }
                PushNotification(pushMessageData);
            }
            if (UpdatedStatus?.toLowerCase() === 'ready') {
                if (selectedImage) {
                    const formData = new FormData();
                    formData.append('Status', UpdatedStatus);
                    formData.append('file', selectedImage);
                    formData.append('Status_Updated_By', GetVendorOrderDetails?.VendorID);
                    dispatch({
                        type: actions.UPDATE_ORDER_STATUS,
                        payload: { OrderId: GetVendorOrderDetails?._id, data: formData }
                    });
                } else {
                    setShowError("Please Upload the Image");
                    setLoader(false);
                }
            } else {
                const formData = new FormData();
                formData.append('Status', UpdatedStatus);
                formData.append('Status_Updated_By', GetVendorOrderDetails?.VendorID);
                dispatch({
                    type: actions.UPDATE_ORDER_STATUS,
                    payload: { OrderId: GetVendorOrderDetails?._id, data: formData }
                });
            }
        }
    }


    const handleCancelOrder = (e) => {
        e.preventDefault();
        if (!reasonForCancel) {
            setShowErr(true);
        } else {
            setShowErr(false);
            setLoader(true);
            dispatch({
                type: OrderActions.CANCEL_ORDER,
                payload: { VendorID: token?.result?._id, id: GetVendorOrderDetails?._id, ReasonForCancel: reasonForCancel }
            });
        }
    };

    //validate update order status 
    useEffect(() => {
        if (UpdateOrderStatus?.statusCode === 200 || UpdateOrderStatus?.statusCode === 400) {
            setLoader(false);
            setShow(false);
            setUpdatedStatus('');
            setSelectedImage();
            setSelectedImageUrl();
        };
        // eslint-disable-next-line
    }, [UpdateOrderStatus]);

    const handleAcceptOrder = (e) => {
        e.preventDefault();
        if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
            let pushMessageData = {
                type: 'customer',
                registration_ids: [UserDetails.Notification_Id],
                title: 'Cakey',
                body: 'Your Cake Order Accepted',
            }
            PushNotification(pushMessageData);
        }
        dispatch({ type: actions.ACCEPT_ORDER, payload: GetVendorOrderDetails?._id });
    };

    useEffect(() => {
        if (CancelOrderStatus?.statusCode === 200 || CancelOrderStatus?.statusCode === 400) {
            setLoader(false);
            setShow2(false);
        }
        // eslint-disable-next-line
    }, [CancelOrderStatus]);

    //loading
    useEffect(() => {
        (GetVendorOrderDetails.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [GetVendorOrderDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Vendor Order Details</h6>
                                    <span className="float-right add-banner-button">
                                        <button
                                            className="btn btn-success add-button rounded"
                                            disabled={GetVendorOrderDetails?.Status === 'New' || GetVendorOrderDetails?.Status === 'Assigned' ? false : true}
                                            type="submit"
                                            onClick={handleAcceptOrder}
                                        >
                                            Accept Order
                                        </button>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                {GetVendorOrderDetails?.Image &&
                                    <div className="ms-panel-body order-image tabledata-background">
                                        <ImageSlider image={[GetVendorOrderDetails?.Image]} />
                                        {/* <img
                                            className="d-block align-center"
                                            src={GetVendorOrderDetails?.Image}
                                            alt="Cake"
                                        /> */}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(GetVendorOrderDetails?.ReasonForCancel && (GetVendorOrderDetails?.Status === 'Rejected' || GetVendorOrderDetails?.Status === 'Cancelled')) &&
                                                <tr className="text-danger">
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{GetVendorOrderDetails?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Order ID</th>
                                                <td><b>{GetVendorOrderDetails?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{GetVendorOrderDetails?.Cake_ID}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td>{GetVendorOrderDetails?.CakeName ? GetVendorOrderDetails?.CakeName : 'Customized Cake'}</td>
                                            </tr>
                                            {GetVendorOrderDetails?.CakeCommonName &&
                                                <tr>
                                                    <th scope="row">Cake Common Name</th>
                                                    <td>{GetVendorOrderDetails?.CakeCommonName}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{GetVendorOrderDetails?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{GetVendorOrderDetails?.Created_On}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(GetVendorOrderDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{GetVendorOrderDetails?.Status}</span></td>
                                            </tr>
                                            {GetVendorOrderDetails?.Status_Updated_On ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{GetVendorOrderDetails?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{GetVendorOrderDetails?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {(!GetVendorOrderDetails?.NewExtraCharges || GetVendorOrderDetails?.MessageOnTheCake || GetVendorOrderDetails?.Tier || GetVendorOrderDetails?.SpecialRequest) &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">CAKE INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                {GetVendorOrderDetails?.Theme &&
                                                    <tr>
                                                        <th scope="row">Theme Name</th>
                                                        <td>{GetVendorOrderDetails?.Theme}</td>
                                                    </tr>
                                                }
                                                {GetVendorOrderDetails?.Tier &&
                                                    <tr>
                                                        <th scope="row">Tier</th>
                                                        <td>{GetVendorOrderDetails?.Tier} Tier</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Flavour</th>
                                                    <td><FlavourList FlavoursList={GetVendorOrderDetails?.Flavour} /></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Shape</th>
                                                    <td>{`${GetVendorOrderDetails?.Shape?.Name}-Additional Rs.${GetVendorOrderDetails?.Shape?.Price}/Kg`}</td>
                                                </tr>
                                                {GetVendorOrderDetails?.MessageOnTheCake &&
                                                    <tr>
                                                        <th scope="row">Message on the Cake</th>
                                                        <td>{GetVendorOrderDetails?.MessageOnTheCake}</td>
                                                    </tr>
                                                }
                                                {GetVendorOrderDetails?.SpecialRequest &&
                                                    <tr>
                                                        <th scope="row">Special Request</th>
                                                        <td>{GetVendorOrderDetails?.SpecialRequest}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }

                        {(GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">TOPPER INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Topper Name</th>
                                                    <td>{GetVendorOrderDetails?.Toppers?.TopperName}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th scope="row">Topper Price</th>
                                                    <td>Rs.{GetVendorOrderDetails?.NewTopperCharges ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="ms-panel-body tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img
                                                className="d-block align-center w-50 h-50 responsive-image-width"
                                                src={GetVendorOrderDetails?.Toppers?.TopperImage}
                                                alt="First slide"
                                            />
                                        </ResponsiveEmbed>
                                    </div>
                                </div>
                            </div>
                        }
                        {GetVendorOrderDetails?.Vendor_Price ?
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">PRICE INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{GetVendorOrderDetails?.ItemCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{GetVendorOrderDetails?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td><span>{GetVendorOrderDetails?.NewPrice ? float2D(GetVendorOrderDetails?.NewPrice) : float2D(GetVendorOrderDetails?.Vendor_Price)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Extra Charges</th>
                                                    <td><span>{GetVendorOrderDetails?.NewExtraCharges ? float2D(GetVendorOrderDetails?.NewExtraCharges) : float2D(GetVendorOrderDetails?.ExtraCharges)}</span></td>
                                                </tr>
                                                {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                                    <tr>
                                                        <th scope="row">Topper Price</th>
                                                        <td>{GetVendorOrderDetails?.NewTopperCharges ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td><span>{GetVendorOrderDetails?.NewDiscount ? float2D(GetVendorOrderDetails?.NewDiscount) : float2D(GetVendorOrderDetails?.Discount)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery Charge</th>
                                                    <td><span>{GetVendorOrderDetails?.NewDeliveryCharges ? float2D(GetVendorOrderDetails?.NewDeliveryCharges) : float2D(GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                                </tr>
                                                {GetVendorOrderDetails?.TypeOfCustomizedCake === 'Cakey' ?
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td><span><b>{float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span>
                                                        </td>
                                                    </tr>
                                                    :
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td><span><b>{GetVendorOrderDetails?.NewTotal ? float2D(parseFloat(GetVendorOrderDetails?.NewTotal) - parseFloat(GetVendorOrderDetails?.NewTax)) : float2D(GetVendorOrderDetails?.Vendor_Total)}</b></span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> :
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">PRICE INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Product Count</th>
                                                    <td>{GetVendorOrderDetails?.ItemCount}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price per Kg</th>
                                                    <td><span>{GetVendorOrderDetails?.NewPrice ? float2D(GetVendorOrderDetails?.NewPrice) : float2D(GetVendorOrderDetails?.Price)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{GetVendorOrderDetails?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Extra Charges</th>
                                                    {GetVendorOrderDetails?.ExtraCharges === '0' || GetVendorOrderDetails?.ExtraCharges === undefined ?
                                                        <td>0.00</td> :
                                                        <td><span>{GetVendorOrderDetails?.NewExtraCharges ? float2D(GetVendorOrderDetails?.NewExtraCharges) : float2D(GetVendorOrderDetails?.ExtraCharges)}</span></td>
                                                    }
                                                </tr>
                                                {(GetVendorOrderDetails?.NewTopperCharges || GetVendorOrderDetails?.Toppers?.TopperPrice) &&
                                                    <tr>
                                                        <th scope="row">Topper Price</th>
                                                        <td>{GetVendorOrderDetails?.NewTopperCharges ? float2D(GetVendorOrderDetails?.NewTopperCharges) : float2D(GetVendorOrderDetails?.Toppers?.TopperPrice)}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td><span>{GetVendorOrderDetails?.NewDiscount ? float2D(GetVendorOrderDetails?.NewDiscount) : float2D(GetVendorOrderDetails?.Discount)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery Charge</th>
                                                    <td><span>{GetVendorOrderDetails?.NewDeliveryCharges ? float2D(GetVendorOrderDetails?.NewDeliveryCharges) : float2D(GetVendorOrderDetails?.DeliveryCharge)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Total</th>
                                                    <td><span><b>{GetVendorOrderDetails?.NewTotal ? float2D(parseFloat(GetVendorOrderDetails?.NewTotal) - parseFloat(GetVendorOrderDetails?.NewTax)) : (float2D((parseFloat(GetVendorOrderDetails?.Total) + parseFloat(GetVendorOrderDetails?.CouponValue || 0)) - (parseFloat(GetVendorOrderDetails?.Gst) + parseFloat(GetVendorOrderDetails?.Sgst))))}</b></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                        {GetVendorOrderDetails?.FinalCakeImage &&
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h6 className='text-uppercase font-weight-bold'>FINAL CAKE IMAGE</h6>
                                    </div>
                                    <div className='header-break-line'> <hr /> </div>
                                    <div className="col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body order-image tabledata-background">
                                                <ResponsiveEmbed aspectRatio='21by9'>
                                                    <img
                                                        className="d-block align-center responsive-image-width"
                                                        src={GetVendorOrderDetails?.FinalCakeImage}
                                                        alt="Cake"
                                                    />
                                                </ResponsiveEmbed>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{GetVendorOrderDetails?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{GetVendorOrderDetails?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{GetVendorOrderDetails?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Type</th>
                                                <td>{GetVendorOrderDetails?.PaymentType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status</th>
                                                <td>{GetVendorOrderDetails?.PaymentStatus}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{GetVendorOrderDetails?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{GetVendorOrderDetails?.UserName}</td>
                                            </tr>
                                            {/* <tr>
                                                <th scope="row">Customer Phonenumber</th>
                                                <td>{GetVendorOrderDetails?.UserPhoneNumber}</td>
                                            </tr> */}
                                            {GetVendorOrderDetails?.DeliveryAddress &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{GetVendorOrderDetails?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE DESCRIPTION</h4>
                                    <hr />
                                    <p>{GetVendorOrderDetails?.Description ? GetVendorOrderDetails?.Description : 'No Description'}</p>

                                    {GetVendorOrderDetails?.Status === 'Preparing' || GetVendorOrderDetails?.Status === 'Accepted' ||
                                        GetVendorOrderDetails?.Status === 'Ready' || GetVendorOrderDetails?.Status === 'Out For Delivery' ?
                                        <button
                                            className="btn btn-primary float-start mr-3"
                                            type="submit"
                                            onClick={handleShow}
                                        >
                                            Update Status
                                        </button>
                                        : <></>
                                    }
                                    {((GetVendorOrderDetails?.Status === 'New' || GetVendorOrderDetails?.Status === 'Preparing' ||
                                        GetVendorOrderDetails?.Status === 'Accepted')) ?
                                        <>
                                            {(!GetVendorOrderDetails?.NewPrice) &&
                                                <button
                                                    className="btn btn-warning float-start mr-3"
                                                    type="submit"
                                                    onClick={handleShow2}
                                                >
                                                    Cancel Order
                                                </button>
                                            }
                                        </>
                                        : <></>

                                    }
                                    <button
                                        className="btn btn-secondary float-start"
                                        type="submit"
                                        onClick={handleCancel}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* update order status popup  */}
                    <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className="tabledata-background">
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center">Update Order Status</h1>
                            <div>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>Current Status</label>
                                        <div className="input-group">
                                            <div className="form-control">{GetVendorOrderDetails?.Status}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="validationCustom23" className='font-weight-bold'>New Status</label>
                                        <div className="input-group">
                                            {GetVendorOrderDetails?.Status === "Accepted" ?
                                                <div className="form-control">Preparing</div> :
                                                GetVendorOrderDetails?.Status === "Preparing" ?
                                                    <div className="form-control">Ready</div> :
                                                    GetVendorOrderDetails?.Status === "Ready" ?
                                                        <div className="form-control">Out For Delivery</div> :
                                                        GetVendorOrderDetails?.Status === "Out For Delivery" ?
                                                            <div className="form-control">Delivered</div> :
                                                            <></>
                                            }
                                        </div>
                                    </div>
                                    {GetVendorOrderDetails?.Status === "Preparing" &&
                                        <>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="validationCustom12" className='float-left font-weight-bold ml-2'> If order is ready you must upload a photo <span className='text-danger'>*</span></label>
                                                <div className="custom-file">
                                                    <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleAddImage} />
                                                    <label className="custom-file-label">Upload Image...</label>
                                                </div>
                                                <p className='text-success font-weight-bold float-left mt-1'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                            </div>
                                            <div className='display-flex'>
                                                <div className='display-flex'>
                                                    {selectedImageUrl !== undefined &&
                                                        <div className="imgPre">
                                                            <img width='90px' className='update-select-cake' src={selectedImageUrl} alt="Cake" />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="text-danger">{showError}</div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button

                                            type="submit" className="btn btn-primary shadow-none mr-3"
                                            onClick={handleUpdateStatus}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary shadow-none"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* cencel order popup  */}
                    <Modal className="modal-min" show={show2} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered contentClassName="modal-body-scrollar">
                        <Modal.Body className='tabledata-background'>
                            <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                            <h1 className="text-center mb-3">Cancel Order</h1>
                            <div>
                                <div>
                                    <label htmlFor="validationCustom24" className='font-weight-bold'>Reason For Cancel the Order - (Order Id : {GetVendorOrderDetails?.Id})<span className='text-danger'> *</span></label>
                                    <div className="input-group">
                                        <input type="text" className={showErr ? "required-field form-control text-capitalize" : "form-control text-capitalize"} name='ReasonForSuspend' id="validationCustom24" placeholder="Enter the reason..." onChange={(e) => setReasonForCancel(e.target.value)} />
                                        <p className='text-danger'>{showErr}</p>
                                    </div>
                                </div>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <div className="text-center">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={handleCancelOrder}
                                        >
                                            Cancel Order
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary ml-4"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </button>
                                    </div>
                                }

                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </>
    )
}