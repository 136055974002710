import React, { useEffect, useState } from 'react';
import Breadcrumb from './Breadcrumb';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../../redux/Order/actions';
import Preloader from '../../../layouts/Preloader';
import moment from 'moment';
import { CSVLink } from 'react-csv';

function Content() {

    const dispatch = useDispatch();

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [exportStatement, setExportStatement] = useState([]);
    const [date, setDate] = useState("");

    //get logged in users details
    const auth = useSelector((state) => state.authReducer);

    const OrdersList = useSelector(state => state.OrderReducer);

    useEffect(() => {
        if (auth.token !== undefined) {
            const Month = moment().format('DD-MM-YYYY');
            setDate(moment().format('YYYY-MM-DD'));
            dispatch({ type: actions.GET_VENDOR_STATEMENT_DETAILS, payload: { VendorID: auth.token.result._id, Month: Month.slice(3, 5), Year: Month.slice(6, 10) } });
        }
        // eslint-disable-next-line
    }, [auth]);

    //validate the list is empty or not
    useEffect(() => {
        if (OrdersList.VendorStatementDetails.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(OrdersList.VendorStatementDetails.result)
        };
    }, [OrdersList]);

    const handleTableSearch = (e) => {
        setDate(e.target.value);
        if (auth.token !== undefined) {
            dispatch({ type: actions.GET_VENDOR_STATEMENT_DETAILS, payload: { VendorID: auth.token.result._id, Month: e.target.value.slice(5, 7), Year: e.target.value.slice(0, 4) } });
        }
    };

    //loading
    useEffect(() => {
        if (OrdersList.VendorStatementDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };

    }, [OrdersList]);

    const handleExport = () => {
        const Statement = [
            [
                "Date",
                "Order ID",
                "Description",
                "Sales Amount",
                "Payment",
                "Pending Amount",
            ],
            [
                " ", " ", " ", " ", "Opening Balance:", OrdersList?.VendorStatementDetails?.OpeningBalance
            ],
            [
                " ", " ", " ", " ", "", " "
            ],
            ...filteredData?.map(details =>
                
                details.Type === 'Payment' ?
                    [
                        details.Date,
                        "-",
                        details.Type,
                        "-",
                        details.Total,
                        "-"
                    ] :
                    [
                        details.Date,
                        details.Order_ID,
                        details.Type,
                        details.Total,
                        "-",
                        details.Total
                    ]
            ),
            [
                " ", " ", " ", " ", "", " "
            ],
            [
                " ", " ", " ", " ", "Pending Payment:", OrdersList?.VendorStatementDetails?.ClosingBalance
            ],
        ]
        Statement.reduce((prev, curr) => [prev, curr]);
        const data = Statement;
        setExportStatement(data);
    };

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Statement Of Accounts</h6>
                                    <span className="float-right add-banner-button">
                                        <button className="btn btn-success add-button rounded" type="submit">
                                            <CSVLink
                                                data={exportStatement}
                                                filename={`statement of ${date.slice(5, 7)} - ${date.slice(0, 4)}.csv`}
                                                className="text-white font-weight-bold"
                                                target="_blank"
                                                onClick={handleExport}
                                            >
                                                EXPORT
                                            </CSVLink>
                                        </button>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6"></div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Select Month :
                                                            <input type='date' maxLength={4} max={"9999-12-31"} className="form-control form-control-sm" value={date} aria-controls="data-table-4" onChange={handleTableSearch} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Date</th>
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Sales Amount</th>
                                                                <th scope="col">Payment</th>
                                                                <th scope="col">Pending Amount</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr className="odd">
                                                                <th className='id-column' scope="row"></th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='font-weight-bold'>Opening Balance</td>
                                                                <td className='font-weight-bold'>{OrdersList.VendorStatementDetails?.OpeningBalance ? OrdersList.VendorStatementDetails?.OpeningBalance : '0'}</td>
                                                            </tr>
                                                            {filteredData.length > 0 ?
                                                                <>
                                                                    {filteredData.map((order) =>
                                                                        <> {order.Type === 'Payment' ?
                                                                            <tr className="odd" >
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td>----</td>
                                                                                <td className='font-weight-bold'>{order.Type}</td>
                                                                                <td>----</td>
                                                                                <td>{order.Total}</td>
                                                                                <td>----</td>
                                                                            </tr> :
                                                                            <tr className="odd">
                                                                                <th className='id-column' scope="row">{order.Date}</th>
                                                                                <td>{order.Order_ID}</td>
                                                                                <td>{order.Type}</td>
                                                                                <td>{order.Total}</td>
                                                                                <td>----</td>
                                                                                <td>{order.Total}</td>
                                                                            </tr>
                                                                        }
                                                                        </>
                                                                    )}
                                                                </> :
                                                                <tr>
                                                                    <td>----</td>
                                                                    <td>----</td>
                                                                    <td>----</td>
                                                                    <td>----</td>
                                                                    <td>----</td>
                                                                    <td>----</td>
                                                                </tr>
                                                            }
                                                            <tr className="odd">
                                                                <th className='id-column' scope="row"></th>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='font-weight-bold'>Total Pending Amount</td>
                                                                <td className='font-weight-bold'>{OrdersList.VendorStatementDetails?.ClosingBalance ? OrdersList.VendorStatementDetails?.ClosingBalance : '0'}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Content;