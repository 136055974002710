import React, { useState, useEffect } from 'react';
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import actions from '../../../redux/Topper/actions';
import VendorActions from '../../../redux/Vendor/actions';
import CommonActions from '../../../redux/Common/actions';
import Preloader from '../../layouts/Preloader';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import ProductContains from '../../vendor/section/Vendor-Hampers-Details/ProductContains';
import CakeImageSlider from '../../vendor/section/Vendor-Cake-Details/CakeImageSlider';
import Breadcrumb from './Breadcrumb';
import { float2D } from '../../../utils/Validation';
import FileSizeValidation from '../../common/FileSizeValidation';
import { PushNotification } from '../../common/PushNotification';

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.id;

    const [show, setShow] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [changeImageOption, setChangeImageOption] = useState('');
    const [message, setMessage] = useState('');
    const [selectedHamperImage, setSelectedHamperImage] = useState();
    const [selectedHamperImageUrl, setSelectedHamperImageUrl] = useState();
    const [loader, setLoader] = useState(false);

    let [sliderImages, setSliderImages] = useState([]);

    const { token } = useSelector(state => state.authReducer);

    const { HamperDetails, TopperReponse } = useSelector(state => state.TopperReducer);

    const { VendorDetails } = useSelector(state => state.VendorReducer);

    const VendorID = HamperDetails?.VendorID;
    const HamperImage = HamperDetails?.HamperImage;
    const AdditionalHamperImage = HamperDetails?.AdditionalHamperImage;

    useEffect(() => {
        dispatch({ type: actions.GET_HAMPERS_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    useEffect(() => {
        if (VendorID) {
            dispatch({ type: VendorActions.GET_VENDOR_DETAILS_BY_ID, payload: { Id: VendorID } });
        }
        // eslint-disable-next-line 
    }, [VendorID]);

    useEffect(() => {
        if (HamperImage) {
            if (AdditionalHamperImage?.length > 0 || AdditionalHamperImage !== undefined) {
                setSliderImages([...AdditionalHamperImage, HamperImage]);
            } else {
                setSliderImages([HamperImage]);
            };
        }
    }, [HamperImage, AdditionalHamperImage]);

    const handleApprovePopup = (e) => {
        e.preventDefault();
        setShow(true);
    };

    const handleSelectHamperImage = (e) => {
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                setSelectedHamperImage(result?.FinalImages[0]);
                if (result?.FinalImages[0]) {
                    setSelectedHamperImageUrl(URL.createObjectURL(result?.FinalImages[0]));
                };
            }
        }
    };

    const handleApproveHamper = (e) => {
        e.preventDefault();
        // setShow(false);
        if (token?.result) {
            if (VendorDetails?.Notification_Id) {
                let pushMessageData = {
                    type: 'vendor',
                    registration_ids: [VendorDetails.Notification_Id],
                    title: 'Cakey',
                    body: "Your Hamper is Approved",
                }
                PushNotification(pushMessageData);
            };
            if (changeImageOption === 'n') {
                setLoader(true);
                const formData = new FormData();
                formData.append('Status', 'Approved');
                formData.append('Status_Updated_By', token?.result?._id);
                dispatch({
                    type: actions.APPROVE_HAMPER,
                    payload: { id: HamperDetails?._id, data: formData }
                });
            } else {
                if (selectedHamperImage) {
                    setLoader(true);
                    const formData = new FormData();
                    formData.append('Status', 'Approved');
                    formData.append('Status_Updated_By', token?.result?._id);
                    formData.append('file', selectedHamperImage);
                    dispatch({
                        type: actions.APPROVE_HAMPER,
                        payload: { id: HamperDetails?._id, data: formData }
                    });
                } else {
                    setMessage('*required');
                }
            };
        };
    };

    const handleClose = () => {
        setShow(false);
        setChangeImageOption('');
        setMessage('');
        setSelectedHamperImage();
        setSelectedHamperImageUrl();
    };

    useEffect(() => {
        if (TopperReponse?.statusCode === 200 || TopperReponse?.statusCode === 400) {
            setShow(false);
            setLoader(false);
            setChangeImageOption('');
            setMessage('');
            setSelectedHamperImage();
            setSelectedHamperImageUrl();
        };
    }, [TopperReponse]);

    useEffect(() => {
        if (HamperDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [HamperDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6 >Hamper Details</h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <CakeImageSlider image={sliderImages} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">HAMPER INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Hamper ID</th>
                                                    <td><b>{HamperDetails?.Id}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Hamper Name</th>
                                                    <td><b>{HamperDetails?.HampersName}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Title</th>
                                                    <td>{HamperDetails?.Title}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Occasion</th>
                                                    <td>{HamperDetails?.Occasion}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Booking Start Date</th>
                                                    <td>{HamperDetails?.StartDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Booking End Date</th>
                                                    <td>{HamperDetails?.EndDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery Start Date</th>
                                                    <td>{HamperDetails?.DeliveryStartDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Delivery End Date</th>
                                                    <td>{HamperDetails?.DeliveryEndDate}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{HamperDetails?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Price</th>
                                                    <td>Rs.{float2D(HamperDetails?.Price)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Created On</th>
                                                    <td>{HamperDetails?.Created_On}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Status</th>
                                                    <td><span className={(HamperDetails?.Status?.toUpperCase())?.replace(/ /g, '')}>{HamperDetails?.Status}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">CONTAINS</h4>
                                        <hr />
                                        <ProductContains ArrayList={HamperDetails?.Product_Contains} />
                                    </div>
                                </div>
                            </div>
                            {HamperDetails?.ApproveImage &&
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="section-title bold">HAMPER APPROVED IMAGE</h4>
                                        </div>
                                        <div className="ms-panel-body tabledata-background order-image">
                                            <ResponsiveEmbed aspectRatio='21by9'>
                                                <img className="d-block align-center responsive-image-width" src={HamperDetails?.ApproveImage} alt="First slide" />
                                            </ResponsiveEmbed>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">VENDOR INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td>{HamperDetails?.Vendor_ID}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td>{HamperDetails?.VendorName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Main Mobile Number</th>
                                                    <td>{HamperDetails?.VendorPhoneNumber1}</td>
                                                </tr>
                                                {HamperDetails?.VendorPhoneNumber2 &&
                                                    <tr>
                                                        <th scope="row">Vendor Alternative Mobile Number</th>
                                                        <td>{HamperDetails?.VendorPhoneNumber2}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th scope="row">Vendor Address</th>
                                                    <td><span className="long-line-break">{HamperDetails?.VendorAddress}</span></td>
                                                </tr>
                                                {HamperDetails?.GoogleLocation &&
                                                    <tr>
                                                        <th scope="row">Google Location</th>
                                                        <td>
                                                            <b>Latitude</b> : {HamperDetails?.GoogleLocation?.Latitude} <br />
                                                            <b>Longitude</b> : {HamperDetails?.GoogleLocation?.Longitude}
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">HAMPER DESCRIPTION</h4>
                                        <hr />
                                        <p>{HamperDetails?.Description}</p>
                                        {HamperDetails?.Status !== 'Approved' &&
                                            <button
                                                className="btn btn-primary mr-3"
                                                type="button"
                                                onClick={handleApprovePopup}
                                            >
                                                Approve
                                            </button>
                                        }
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() => history.push('/Admin-hamper-list')}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                            centered contentClassName="modal-body-scrollar">
                            <Modal.Body className="text-center tabledata-background">
                                <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                                <h1 className="text-center pb-2">Approve Hamper</h1>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Change Hamper Image? <span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <select className="form-control" defaultValue='' id="validationCustom22" onChange={(e) => setChangeImageOption(e.target.value)}>
                                                <option value='' disabled='disabled'>-- Select --</option>
                                                <option value="y">Yes</option>
                                                <option value="n">No</option>
                                            </select>
                                        </div>
                                    </div>
                                    {changeImageOption === 'y' &&
                                        <div className="col-md-12 mb-3">
                                            <div className="input-group">
                                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Hamper Image <span className='text-danger'>*</span></label>
                                                <div className="col-md-12 mb-3">
                                                    <div className="custom-file">
                                                        <input type="file" accept='image/jpg,image/jpeg,image/png' className="custom-file-input" onChange={handleSelectHamperImage} />
                                                        <label className="custom-file-label text-left">Upload Image...</label>
                                                    </div>
                                                    <p className='text-success font-weight-bold text-left'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                                </div>
                                            </div>
                                            <div className='display-flex'>
                                                <div className='display-flex'>
                                                    {selectedHamperImageUrl !== undefined &&
                                                        <div className="imgPre">
                                                            <img
                                                                width='90px'
                                                                className='update-select-cake'
                                                                src={selectedHamperImageUrl}
                                                                alt="Cake"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <p className='text-danger text-left'>{message}</p>
                                {loader ? <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mr-3"
                                            onClick={handleApproveHamper}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </Modal.Body>
                        </Modal>
                        {/* <Modal className="modal-min" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Body className="tabledata-background">
                                <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                                <div>
                                    <h6 className='text-center mb-4'>Are you sure you want to approve the Hamper ?</h6>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary" onClick={handleApproveHamper} >Yes</button>
                                        <button type="submit" className="btn btn-secondary ml-4" onClick={handleClose} >No</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal> */}
                    </div>
                </div>
            }
        </>
    );
}

export default Content;