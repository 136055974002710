
import actions from "./actions";
// import io from 'socket.io-client'
const initialState = {
  response: {
    alert: false,
    message: null,
    status: null,
  },
  commonErrors: null,
  loader: false,
  socket: null,
  // socket: io("http://localhost:3001", { transports: ['websocket'], upgrade: false }),
  // socket: io("http://localhost:3001", { transports: ['websocket'], upgrade: false }),
  // socket: io("https://cakeybackend.onrender.com", { transports: ['websocket'], upgrade: false }),
  // socket: io("http://sugitechnologies.com:3001", { transports: ['websocket'], upgrade: false }),
  onlineusers: [],
  // socket: io("http://localhost:3001"),
  buffer: false,
  currentConversation: {},
  Invoice_Calculation: [],
  fileValidation: false,
  Filtered_Dates: {
    StartDate: null,
    EndDate: null,
    Page: null
  },
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.SET_CURRENT_CONVERSATION:
      return {
        ...state,
        currentConversation: action.payload
      }
    case actions.SET_BUFFER:
      return {
        ...state,
        buffer: action.payload
      }
    case actions.SET_ONLINE_USERS:
      return {
        ...state,
        onlineusers: action.payload
      }
    case actions.SET_LOADER:
      return {
        ...state,
        loader: action.payload
      }
    case actions.SET_ALERT:
      return {
        ...state,
        response: action.payload
      }
    case actions.SET_COMMON_ERRORS:
      return {
        ...state,
        commonErrors: action.payload
      }
    case actions.SET_INVOICE_CALCULATION:
      return {
        ...state,
        Invoice_Calculation: action.payload
      }
    case actions.SET_FILE_VALIDATION:
      return {
        ...state,
        fileValidation: action.payload
      }
    case actions.SET_SOCKET_URL:
      return {
        ...state,
        socket: action.payload
      }
    case actions.SET_START_AND_END_DATE:
      return {
        ...state,
        Filtered_Dates: action.payload
      }
    default:
      return state;
  }
};

export default commonReducer;