import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom/esm/react-router-dom";
import actions from "../../../../redux/Vendor/actions";
import OrderActions from '../../../../redux/Order/actions';
import Breadcrumb from "./Breadcrumb";
import { Modal, ResponsiveEmbed } from "react-bootstrap";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import FlavourList from "../../../sections/ArrayMapping/FlavourList";
// import ArticleList from "../../../sections/ArrayMapping/Articles";
import CommonActions from '../../../../redux/Common/actions';

// import Alert from "../../../common/Alert";

export default function Content() {

    const state = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [showAlert, setShowAlert] = useState(false);
    const [timer, setTimer] = useState("");
    const [show, setShow] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState([]);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');

    //for get vendors details
    const VendorsList = useSelector(
        state => state.VendorReducer
    );

    //for get logged in users details
    const auth = useSelector(
        state => state.authReducer
    );

    //for get assign status
    const Status = useSelector(
        state => state.OrderReducer
    );

    const { response } = useSelector(
        state => state.commonReducer
    );

    const clearNotification = () => {
        setShowAlert(false);
        clearInterval(timer);
        dispatch({
            type: CommonActions.SET_ALERT, payload: {
                alert: false,
                message: null,
                status: null
            }
        });
    };

    useEffect(() => {
        if (response.alert) {
            setShowAlert(true);
            setTimer(setInterval(() => {
                clearNotification()
            }, 4000))
        }
        // eslint-disable-next-line
    }, [response]);

    //dispatch the vendors list api
    useEffect(() => {
        dispatch({ type: actions.GET_ALL_VENDORS });
    }, [dispatch]);

    //for close the page
    const handleClose = () => {
        history.push('/helpdeskV-order-list');
    }

    //for open assign popup
    const handleAssignPopup = () => {
        setShow(true);
    };

    //for close the popup
    const handleCancel = () => {
        setShow(false);
    }

    //for get selected vendors details
    const handleChange = (e) => {
        if (e.target.value) {
            const selected = VendorsList?.vendorsList.filter(val => {
                return val._id === e.target.value
            })
            setSelectedVendor(selected[0])
        };
    };

    //assign functionality
    const handleAssign = () => {
        setLoader(true);
        setMessage('')
        if (selectedVendor.length !== 0) {
            if (VendorsList.vendorsList || auth.token) {
                dispatch({
                    type: OrderActions.ABOVE_5KG_ORDER_ASSIGN, payload: {
                        id: state.state.order._id,
                        VendorID: selectedVendor._id,
                        Vendor_ID: selectedVendor.Id,
                        VendorName: selectedVendor.VendorName,
                        VendorPhoneNumber1: selectedVendor.PhoneNumber1,
                        VendorPhoneNumber2: selectedVendor.PhoneNumber2,
                        VendorAddress: selectedVendor.Address,
                        GoogleLocation: selectedVendor.GoogleLocation,
                        Status: 'Assigned',
                        Status_Updated_By: auth.token.result._id,
                    }
                });
                setTimeout(() => { dispatch({ type: OrderActions.ABOVE_5KG_ORDER_ASSIGN_STATUS, payload: { Above5kgAssignStatus: [] } }); }, 3000);
            }
        } else {
            setMessage('Select any vendor');
        }
    };

    //not respond orders status update
    const handleChangeStatus = (e) => {
        e.preventDefault();
        dispatch({ type: OrderActions.CHANGE_ORDER_VIEW_RESPONSE, payload: { id: state.state.order._id, response: 'seen' } })
    };

    //for assign to vendors status update
    useEffect(() => {
        if (Status.Above5kgAssignStatus !== undefined) {
            setLoader(false);
            if (Status.Above5kgAssignStatus.statusCode === 200) {
                setMessage('');
                setShow(false);
                history.push('/helpdeskV-order-list');
                dispatch({ type: OrderActions.GET_ORDER_LIST_BY_ABOVE_5KG, payload: 'y' });
            } else {
                setMessage(Status.Above5kgAssignStatus.message);
            }
        };
    }, [Status, history, dispatch]);

    return (
        <div className="ms-content-wrapper">
            <div className="row">
                <div className="col-md-12">
                    <Breadcrumb />
                </div>
                <div className="col-md-12">
                    <div className="ms-panel tabledata-background">
                        <div className="ms-panel-header">
                            <h6 >Order Details</h6>
                        </div>
                        {state.state.order.Image &&
                            <div className="ms-panel-body order-image">
                                <ResponsiveEmbed aspectRatio='21by9'>
                                    <img className="d-block align-center responsive-image-width" src={state.state.order.Image} alt="cake" />
                                </ResponsiveEmbed>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Cake Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Cake ID</th>
                                        <td><b>{state.state.order.Cake_ID}</b></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Cake Name</th>
                                        <td><span className={state.state.order.CakeName ? '' : 'font-weight-bold'}>{state.state.order.CakeName ? state.state.order.CakeName : 'Customised Cake'}</span></td>
                                    </tr>
                                    {state.state.order.CakeCommonName &&
                                        <tr>
                                            <th scope="row">Cake Common Name</th>
                                            <td>{state.state.order.CakeCommonName}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Egg or EggLess</th>
                                        <td>{state.state.order.EggOrEggless}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Order Date</th>
                                        <td>{state.state.order.Created_On}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Status</th>
                                        <td><span className={(state.state.order.Status.toUpperCase()).replace(/ /g, '')}>{state.state.order.Status}</span></td>
                                    </tr>
                                    {state.state.order.Status_Updated_On ?
                                        <tr>
                                            <th scope="row">Status Updated On</th>
                                            <td>{state.state.order.Status_Updated_On}</td>
                                        </tr> :
                                        <tr>
                                            <th scope="row">Status Updated On</th>
                                            <td>{state.state.order.Created_On}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Cake Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    {state.state.order?.Theme &&
                                        <tr>
                                            <th scope="row">Theme Name</th>
                                            <td>{state.state.order?.Theme}</td>
                                        </tr>
                                    }
                                    {state.state.order?.Tier &&
                                        <tr>
                                            <th scope="row">Tier</th>
                                            <td>{state.state.order?.Tier} Tier</td>
                                        </tr>
                                    }
                                    <tr>
                                        <th scope="row">Flavour</th>
                                        <td><FlavourList FlavoursList={state.state.order.Flavour} /></td>
                                    </tr>
                                    {state.state.order.Shape.Price === '0' ?
                                        <tr>
                                            <th scope="row">Shape</th>
                                            <td>{state.state.order.Shape.Name}</td>
                                        </tr> :
                                        <tr>
                                            <th scope="row">Shape</th>
                                            <td>{`${state.state.order.Shape.Name} - Additional Rs.${state.state.order.Shape.Price}`}</td>
                                        </tr>
                                    }
                                    {/* <tr>
                                        <th scope="row">Articles</th>
                                        <td><ArticleList Articles={[state.state.order.Article]} /></td>
                                    </tr> */}
                                    <tr>
                                        <th scope="row">Weight</th>
                                        <td>{state.state.order.Weight}</td>
                                    </tr>
                                    {state.state.order.MessageOnTheCake &&
                                        <tr>
                                            <th scope="row">Message on the Cake</th>
                                            <td>{state.state.order.MessageOnTheCake}</td>
                                        </tr>
                                    }
                                    {state.state.order.SpecialRequest &&
                                        <tr>
                                            <th scope="row">Special Request</th>
                                            <td>{state.state.order.SpecialRequest}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {state.state.order.Toppers?.TopperName ?
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <h4 className="section-title bold">Topper Info</h4>
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Topper Name</th>
                                            <td>{state.state.order.Toppers.TopperName}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Topper Price</th>
                                            <td>Rs. {state.state.order.Toppers.TopperPrice}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="ms-panel-body tabledata-background">
                                <ResponsiveEmbed aspectRatio='21by9'>
                                    <img className="d-block align-center w-50 h-50 responsive-image-width" src={state.state.order.Toppers.TopperImage} alt="First slide" />
                                </ResponsiveEmbed>
                            </div>
                        </div>
                    </div> : <></>
                }
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel ms-panel-fh">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Price Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Item count</th>
                                        <td>{state.state.order.ItemCount}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price</th>
                                        <td><i className="fas fa-rupee-sign price-info" /><span>{state.state.order.Price}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Extra Charges</th>
                                        {state.state.order.ExtraCharges === '0' || state.state.order.ExtraCharges === undefined ?
                                            <td>0</td> :
                                            <td><i className="fas fa-rupee-sign price-info" /><span>{state.state.order.ExtraCharges}</span></td>
                                        }
                                    </tr>
                                    <tr>
                                        <th scope="row">Discount</th>
                                        <td><i className="fas fa-rupee-sign price-info" /><span>{state.state.order.Discount}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Charge</th>
                                        <td>{state.state.order.DeliveryCharge !== '0' && <i className="fas fa-rupee-sign price-info" />}<span>{state.state.order.DeliveryCharge}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">GST</th>
                                        <td><i className="fas fa-rupee-sign price-info" /><span>{state.state.order.Gst}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">SGST</th>
                                        <td><i className="fas fa-rupee-sign price-info" /><span>{state.state.order.Sgst}</span></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Total</th>
                                        <td><i className="fas fa-rupee-sign price-info" /><span><b>{state.state.order.Total}</b></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {state.state.order.FinalCakeImage &&
                    <div className="col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <h6 className='text-uppercase font-weight-bold'>Final Cake Image</h6>
                            </div>
                            <div className='header-break-line'> <hr /> </div>
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body order-image tabledata-background">
                                        <ResponsiveEmbed aspectRatio='21by9'>
                                            <img className="d-block align-center responsive-image-width" src={state.state.order.FinalCakeImage} alt="Cake" />
                                        </ResponsiveEmbed>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Delivery Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Delivery Information</th>
                                        <td>{state.state.order.DeliveryInformation}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Date</th>
                                        <td>{state.state.order.DeliveryDate}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Delivery Session</th>
                                        <td>{state.state.order.DeliverySession}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Payment Type</th>
                                        <td>{state.state.order.PaymentType}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Payment Status</th>
                                        <td>{state.state.order.PaymentStatus}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Customer Info</h4>
                            <table className="table ms-profile-information">
                                <tbody>
                                    <tr>
                                        <th scope="row">Customer ID</th>
                                        <td>{state.state.order.UserID}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Customer Name</th>
                                        <td>{state.state.order.UserName}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Customer Phonenumber</th>
                                        <td>{state.state.order.UserPhoneNumber}</td>
                                    </tr>
                                    {state.state.order.DeliveryAddress &&
                                        <tr>
                                            <th scope="row">Delivery Address</th>
                                            <td><span className="long-line-break">{state.state.order.DeliveryAddress}</span></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {state.state.order.Status !== 'New' &&
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel">
                            <div className="ms-panel-body tabledata-background">
                                <h4 className="section-title bold">Vendor Info</h4>
                                <table className="table ms-profile-information">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Vendor ID</th>
                                            <td>{state.state.order.VendorID}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Name</th>
                                            <td>{state.state.order.VendorName}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Vendor Main Phonenumber</th>
                                            <td>{state.state.order.VendorPhoneNumber1}</td>
                                        </tr>
                                        {state.state.order.VendorPhoneNumber2 &&
                                            <tr>
                                                <th scope="row">Vendor Alternative Phonenumber</th>
                                                <td>{state.state.order.VendorPhoneNumber2}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th scope="row">Vendor Address</th>
                                            <td><span className="long-line-break">{state.state.order.VendorAddress}</span></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Google Location</th>
                                            <td><b>Latitude</b> : {state.state.order.GoogleLocation?.Latitude} <br />
                                                <b>Longitude</b> : {state.state.order.GoogleLocation?.Longitude}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-xl-12 col-md-12">
                    <div className="ms-panel">
                        <div className="ms-panel-body tabledata-background">
                            <h4 className="section-title bold">Cake Description</h4>
                            <hr />
                            <p>{state.state.order.Description ? state.state.order.Description : 'No Description'}</p>
                            {state.state.order.Status === 'Assigned' &&
                                <button className="btn btn-primary float-start mr-3 disabled-assign" disabled type="submit" onClick={handleAssign} >Assigned</button>
                            }
                            {!state.state.order.VendorID &&
                                <button className="btn btn-primary float-start mr-3" type="submit" onClick={handleAssignPopup} >Assign</button>
                            }
                            {state.state.order.Vendor_Response_Status === 'no response' && state.state.order.VendorID ?
                                <button className="btn btn-primary float-start mr-3" type="submit" onClick={handleChangeStatus} >Change Status</button> : <></>
                            }
                            <button className="btn btn-secondary float-start" type="submit" onClick={handleClose} >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* assign to vendor popup  */}
            <Modal className="modal-min" show={show} onHide={handleCancel} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background">
                    <button type="button" className="close" onClick={handleCancel}><span aria-hidden="true">×</span></button>
                    <h1 className="text-center pb-2">Cake Order Assign</h1>
                    <div>
                        <label htmlFor="validationCustom23" className='font-weight-bold'>Vendors<span className="text-danger">*</span></label>
                        <div className="input-group">
                            {VendorsList?.vendorsList.length > 0 ?
                                <select className='form-control' defaultValue='-- select --' onChange={handleChange}>
                                    <option value='-- select --' disabled='disabled'>-- Select --</option>
                                    {VendorsList?.vendorsList.map((a, i) =>
                                        <option value={a._id} key={i}>{a.VendorName} - {a.Address}</option>
                                    )}
                                </select> :
                                <></>
                            }
                        </div>
                        <p className="text-danger">{message}</p>
                        {loader ?
                            <div className="login-spinner2"><CircleDotSpinner /></div> :
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary shadow-none" onClick={handleAssign} >Assign</button>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/* {showAlert &&
                <Alert response={response} />
            } */}
        </div>
    )
}