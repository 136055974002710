import React, { useEffect, useState } from "react";
import Breadcrumb from "./Breadcrumb";
import { Modal } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../redux/OtherProducts/actions";
import CommonActions from '../../../../redux/Common/actions';
import Preloader from "../../../layouts/Preloader";
import CakeDessertsWeightContent from "../Vendor-Other-Product-Details/CakeArray/CakeDessertWeight";
import MacaroonBox from "../Vendor-Other-Product-Details/CakeArray/MacaroonBox";
import FileSizeValidation from "../../../common/FileSizeValidation";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { DiscountValidation } from "../../../../utils/Validation";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
    ProductName: yup.string().required('required'),
    Description: yup.string().required('required'),
    Discount: yup.string().required('required'),
    Stock: yup.string().required('required'),
});

export default function Content() {

    const state = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()

    const auth = useSelector(state => state.authReducer);

    const { OtherProductDetails, OtherProductResponse } = useSelector(state => state.OtherProductReducer);

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [minWeightPerKg, setMinWeightPerKg] = useState('');
    const [minPricePerKg, setMinPricePerKg] = useState('');
    const [show2, setShow2] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [newWeight, setNewWeight] = useState('');
    const [newWeightUnit, setNewWeightUnit] = useState('g');
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [loader, setLoader] = useState(false);

    let [flavours, setFlavours] = useState('');
    let [flavourList, setFlavourList] = useState([]);
    let [disabledButton, setDisabledButton] = useState([]);
    let [disabledButton2, setDisabledButton2] = useState([]);
    let [CakeDessertsWeight, setCakeDessertsWeight] = useState(["200g", "250g", "300g", "350g", "400g", "450g", "500g"]);
    let [cakeDessertWeightAndPriceList, setCakeDessertWeightAndPriceList] = useState([]);
    let [macaroonsPiece, setMacaroonsPiece] = useState(['4', '6', '10']);
    let [macaroonsBoxPriceList, setMacaroonsBoxPriceList] = useState([]);
    let [productImage, setProductImage] = useState();
    let [additionalProductImage, setAdditionalProductImage] = useState([]);
    let [fieldErrors, setFieldErrors] = useState({
        Flavour: false, MinWeight: false, Price: false, ProductImage: false, ButtonClicked: false
    });

    const { register, handleSubmit, reset, formState } = useForm({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        dispatch({ type: actions.GET_OTHER_PRODUCT_DETAILS, payload: state?.state?.Id });
        // eslint-disable-next-line 
    }, [state?.state?.Id]);

    const handleAddFlavours = (e) => {
        e.preventDefault();
        setFlavours('');
        setFlavourList([...flavourList, flavours]);
        setFieldErrors(fieldErrors = { ...fieldErrors, Flavour: false });
        if (!fieldErrors['Flavour'] && !fieldErrors['MinWeight'] && !fieldErrors['Price'] && !fieldErrors['ProductImage']) {
            setMessage('');
        }
    };

    const RemoveAddedFlavoursName = (e, index) => {
        e.preventDefault();
        var splicedList = [...flavourList];
        if (index !== -1) {
            splicedList.splice(index, 1);
        };
        setFlavourList(splicedList);
        let errsObj = { ...fieldErrors };
        if (errsObj['ButtonClicked'] === true && splicedList?.length === 0) {
            errsObj['Flavour'] = true;
        };
        setFieldErrors(errsObj);
    };

    useEffect(() => {
        let CDWeight = ["200g", "250g", "300g", "350g", "400g", "450g", "500g"], DisabledButtonArray = [], PrictList = [];
        if (OtherProductDetails?.CakeSubType === 'Cake Dessert') {
            for (let i = 0; i < OtherProductDetails?.MinWeightPerUnit?.length; i++) {
                if (!CDWeight?.includes(OtherProductDetails?.MinWeightPerUnit[i]?.Weight)) {
                    CDWeight.push(OtherProductDetails?.MinWeightPerUnit[i]?.Weight);
                };
            };
            setCakeDessertsWeight(CDWeight);
            CDWeight.filter((val, i) => {
                // eslint-disable-next-line
                return OtherProductDetails?.MinWeightPerUnit?.filter(val2 => {
                    if (val === val2.Weight) {
                        DisabledButtonArray.push(i);
                        PrictList.push({
                            Weight: val2?.Weight, MinCount: val2?.MinCount, PricePerUnit: val2?.PricePerUnit,
                            index: i, Count_Value: true, Price_Value: true
                        });
                    };
                });
            });
            setDisabledButton(DisabledButtonArray);
            setCakeDessertWeightAndPriceList(PrictList);
        };
    }, [OtherProductDetails]);

    const handleCDWRemoveButton = (e, i) => {
        e.preventDefault();
        const array = [...disabledButton];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton(disabledButton = array);
        const array2 = [...cakeDessertWeightAndPriceList];
        let index2 = array2.findIndex(r => r?.index === i);
        if (index2 !== -1) {
            array2.splice(index2, 1);
        };
        setCakeDessertWeightAndPriceList(cakeDessertWeightAndPriceList = array2);
        if (array2.find(val => val?.MinCount || val?.PricePerUnit)) {
            setMessage('');
        };
    };

    const handleCDWInputValue = (e, value, index, field) => {
        e.preventDefault();
        let cakeDessertsArray = [...cakeDessertWeightAndPriceList];
        if (field === 'MC') {
            cakeDessertsArray[index]['MinCount'] = e.target.value;
            (!e.target.value && fieldErrors?.ButtonClicked) ?
                cakeDessertsArray[index]['Count_Value'] = false :
                cakeDessertsArray[index]['Count_Value'] = true;
        } else if (field === 'PPU') {
            cakeDessertsArray[index]['PricePerUnit'] = e.target.value;
            (!e.target.value && fieldErrors?.ButtonClicked) ?
                cakeDessertsArray[index]['Price_Value'] = false :
                cakeDessertsArray[index]['Price_Value'] = true;
        };
        setCakeDessertWeightAndPriceList(cakeDessertsArray);
        if (cakeDessertsArray?.find(val => val?.MinCount || val?.PricePerUnit)) {
            setMessage('');
        };
    };

    const handleCDWSelectButton = (e, i) => {
        e.preventDefault();
        const newPrice = { Weight: e.target.value, MinCount: '', PricePerUnit: '', index: i, Count_Value: true, Price_Value: true };
        setCakeDessertWeightAndPriceList([...cakeDessertWeightAndPriceList, newPrice]);
        setDisabledButton([...disabledButton, i]);
    };

    const handleAddOtherWeight = (e, field) => {
        e.preventDefault();
        setShow2(true);
        setFieldName(field);
        setNewWeight('');
    };

    useEffect(() => {
        let MPWeight = ['4', '6', '10'], DisabledButtonArray = [], PrictList = [];
        if (OtherProductDetails?.CakeSubType === 'macaroons' || OtherProductDetails?.CakeSubType === 'Cup Cake') {
            for (let i = 0; i < OtherProductDetails?.MinWeightPerBox?.length; i++) {
                if (!MPWeight?.includes(OtherProductDetails?.MinWeightPerBox[i]?.Piece)) {
                    MPWeight.push(OtherProductDetails?.MinWeightPerBox[i]?.Piece);
                };
            };
            setMacaroonsPiece(MPWeight);
            MPWeight.filter((val, i) => {
                // eslint-disable-next-line
                return OtherProductDetails?.MinWeightPerBox?.filter(val2 => {
                    if (val === val2.Piece) {
                        DisabledButtonArray.push(i);
                        PrictList.push({
                            Piece: val2?.Piece, MinCount: val2?.MinCount, PricePerBox: val2?.PricePerBox,
                            index: i, Count_Value: true, Price_Value: true,
                        });
                    };
                });
            });
            setDisabledButton2(DisabledButtonArray);
            setMacaroonsBoxPriceList(PrictList)
        };
    }, [OtherProductDetails]);

    const handleAddNew = (e) => {
        e.preventDefault();
        if (fieldName === 'Cake Dessert Weight Per Unit') {
            if (newWeight && newWeightUnit) {
                if (!CakeDessertsWeight?.find(val => val === (newWeight + newWeightUnit)?.toString())) {
                    setCakeDessertsWeight(CakeDessertsWeight = [...CakeDessertsWeight, newWeight + newWeightUnit])
                    let index = CakeDessertsWeight?.findIndex(val => val === (newWeight + newWeightUnit)?.toString());
                    setDisabledButton([...disabledButton, index]);
                    const newPrice = {
                        Weight: (newWeight + newWeightUnit)?.toString(), MinCount: '', PricePerUnit: '',
                        index: index, Count_Value: true, Price_Value: true
                    };
                    setCakeDessertWeightAndPriceList([...cakeDessertWeightAndPriceList, newPrice]);
                    setShowMessage('');
                    setShow2(false);
                } else {
                    setShowMessage("Weight Already Exist");
                };
            } else {
                setShowMessage("Enter new weight");
            };
        } else if (fieldName === `${OtherProductDetails?.CakeSubType} Pieces Per Box`) {
            if (newWeight) {
                if (!macaroonsPiece?.find(val => val === newWeight)) {
                    setMacaroonsPiece(macaroonsPiece = [...macaroonsPiece, newWeight]);
                    var index2 = macaroonsPiece?.findIndex(val => val === newWeight);
                    setDisabledButton2([...disabledButton2, index2]);
                    let newObj = {
                        Piece: newWeight, MinCount: '', PricePerBox: '',
                        index: index2, Count_Value: true, Price_Value: true
                    }
                    setMacaroonsBoxPriceList([...macaroonsBoxPriceList, newObj]);
                    setShowMessage('');
                    setShow2(false);
                } else {
                    setShowMessage('Macaroons Piece Already Exist');
                };
            } else {
                setShowMessage("Enter new piece")
            };
        };
    };

    const handleMPRemoveButton = (e, i) => {
        e.preventDefault();
        const array = [...disabledButton2];
        let index = array.findIndex(r => r === i);
        if (index !== -1) {
            array.splice(index, 1);
        };
        setDisabledButton2(disabledButton2 = array);
        const array2 = [...macaroonsBoxPriceList];
        let boxInd = array2.findIndex(r => r.index === i);
        if (boxInd !== -1) {
            array2.splice(boxInd, 1);
        };
        setMacaroonsBoxPriceList(array2);
        if (array2.find(val => val?.MinCount || val?.PricePerBox)) {
            setMessage('');
        };
    };

    const handleMPInputValue = (e, value, index, field) => {
        e.preventDefault();
        let macaroonsList = [...macaroonsBoxPriceList];
        if (field === 'MC') {
            macaroonsList[index]['MinCount'] = e.target.value;
            (!e.target.value && fieldErrors?.ButtonClicked === true) ?
                macaroonsList[index]['Count_Value'] = false :
                macaroonsList[index]['Count_Value'] = true;
        } else if (field === 'PPB') {
            macaroonsList[index]['PricePerBox'] = e.target.value;
            (!e.target.value && fieldErrors?.ButtonClicked === true) ?
                macaroonsList[index]['Price_Value'] = false :
                macaroonsList[index]['Price_Value'] = true;
        };
        setMacaroonsBoxPriceList(macaroonsList);
        if (macaroonsList.find(val => val?.MinCount || val?.PricePerBox)) {
            setMessage('');
        };
    };

    const handleMPSelectButton = (e, i) => {
        e.preventDefault();
        const newPrice = { Piece: e.target.value, MinCount: '', PricePerBox: '', index: i, Count_Value: true, Price_Value: true };
        setMacaroonsBoxPriceList([...macaroonsBoxPriceList, newPrice]);
        setDisabledButton2([...disabledButton2, i]);
    }

    const handleAddProductImages = (e) => {
        if (e.target.files.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
            } else {
                let files = result?.FinalImages;
                setFieldErrors(fieldErrors = { ...fieldErrors, ProductImage: false });
                if (!fieldErrors['Flavour'] && !fieldErrors['MinWeight'] && !fieldErrors['Price'] && !fieldErrors['ProductImage']) {
                    setMessage('');
                };
                setProductImage(productImage = { file: files[0], url: URL.createObjectURL(files[0]), type: 'new' });
            };
        };
    };

    function handleRemoveProductImage(e) {
        e.preventDefault();
        setProductImage();
        if (fieldErrors['ButtonClicked'] === true) {
            setFieldErrors({ ...fieldErrors, ProductImage: true });
        };
    };

    const handleAddAdditionalProductImages = (e) => {
        var selectedImages = [], files = [];
        if (e.target.files?.length !== 0) {
            let result = FileSizeValidation(e.target.files);
            files = result?.FinalImages;
            if (result.Validation === "false") {
                dispatch({ type: CommonActions.SET_FILE_VALIDATION, payload: true });
                if (files?.length > 0) {
                    for (let i = 0; i < files.length; i++) {
                        let fileDuplicateValidation = additionalProductImage.find(val => val?.file?.name === files[i].name &&
                            val?.file?.size === files[i].size && val?.file?.type === files[i].type && val?.file?.lastModified === files[i].lastModified);
                        if (!fileDuplicateValidation) {
                            selectedImages.push((files[i]));
                        };
                    };
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let fileDuplicateValidation = additionalProductImage.find(val => val?.file?.name === files[i].name &&
                        val?.file?.size === files[i].size && val?.file?.type === files[i].type && val?.file?.lastModified === files[i].lastModified);
                    if (!fileDuplicateValidation) {
                        selectedImages.push((files[i]));
                    }
                };
            };
            let additionImagesArray = [...additionalProductImage];
            for (let i = 0; i < selectedImages?.length; i++) {
                additionImagesArray.push({ file: selectedImages[i], url: URL.createObjectURL(selectedImages[i]), type: 'new' });
            };
            setAdditionalProductImage(additionImagesArray);
        };
    };

    function handleRemoveAdditionalProductImage(e, index) {
        e.preventDefault();
        var SelectedFile = [...additionalProductImage];
        if (index !== -1) {
            SelectedFile.splice(index, 1);
        };
        setAdditionalProductImage(additionalProductImage = SelectedFile);
    };

    const handleUpdateProduct = (data) => {
        if (auth.token) {
            const formData = new FormData();
            formData.append('ProductName', data?.ProductName);
            if (flavourList?.length > 0) {
                formData.append('Flavour', JSON.stringify(flavourList));
            };
            formData.append('Description', data?.Description);
            formData.append('Stock', data?.Stock);
            formData.append('Discount', data?.Discount);
            formData.append('VendorID', auth?.token?.result?._id);
            formData.append('Vendor_ID', auth?.token?.result?.Id);
            formData.append('VendorName', auth?.token?.result?.VendorName);
            formData.append('VendorPhoneNumber1', auth?.token?.result?.PhoneNumber1);
            formData.append('VendorPhoneNumber2', auth?.token?.result?.PhoneNumber2);
            formData.append('VendorAddress', auth?.token?.result?.Address);
            formData.append('GoogleLocation', JSON.stringify(auth?.token?.result?.GoogleLocation));
            (productImage?.type === 'old') ?
                formData.append('OldMainImageOfOtherProduct', JSON.stringify([productImage?.file])) :
                formData.append('NewMainOtherProductImage', productImage?.file);
            if (additionalProductImage?.length > 0) {
                let oldAdditionalImg = [], newAdditionalImg = [];
                for (let i = 0; i < additionalProductImage?.length; i++) {
                    (additionalProductImage[i]?.type === 'old') ?
                        oldAdditionalImg.push(additionalProductImage[i]?.file) :
                        newAdditionalImg.push(additionalProductImage[i]?.file);
                };
                formData.append('OldOtherProductAdditionalImages', JSON.stringify(oldAdditionalImg));
                for (let j = 0; j < newAdditionalImg?.length; j++) {
                    formData.append('NewOtherProductAdditionalImages', newAdditionalImg[j]);
                };
            };
            if (OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' || OtherProductDetails?.CakeSubType === 'Chocolates' ||
                OtherProductDetails?.CakeSubType === 'Tea Cake' || OtherProductDetails?.CakeSubType === 'Cheese Cake') {
                let errsObj = { ...fieldErrors };
                errsObj['ButtonClicked'] = true;
                (flavourList?.length > 0) ? errsObj['Flavour'] = false : errsObj['Flavour'] = true;
                (minWeightPerKg) ? errsObj['MinWeight'] = false : errsObj['MinWeight'] = true;
                (minPricePerKg) ? errsObj['Price'] = false : errsObj['Price'] = true;
                (productImage?.url) ? errsObj['ProductImage'] = false : errsObj['ProductImage'] = true;
                setFieldErrors(fieldErrors = errsObj);
                if (!fieldErrors['Flavour'] && !fieldErrors['MinWeight'] && !fieldErrors['Price'] && !fieldErrors['ProductImage']) {
                    setMessage('');
                    formData.append('MinWeightPerKg', JSON.stringify({ Weight: minWeightPerKg, PricePerKg: minPricePerKg }));
                    if (OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' ||
                        OtherProductDetails?.CakeSubType === 'Tea Cake' || OtherProductDetails?.CakeSubType === 'Cheese Cake') {
                        formData.append('Shape', data?.Shape);
                    };
                    setLoader(true);
                    dispatch({
                        type: actions.UPDATE_OTHER_PRODUCT,
                        payload: { id: OtherProductDetails?._id, data: formData, history: history }
                    });
                } else {
                    setMessage('*Required fields are missing');
                };
            } else if (OtherProductDetails?.CakeSubType === 'macaroons' || OtherProductDetails?.CakeSubType === 'Cup Cake') {
                let errsObj = { ...fieldErrors };
                errsObj['ButtonClicked'] = true;
                (flavourList?.length > 0) ? errsObj['Flavour'] = false : errsObj['Flavour'] = true;
                (productImage?.url) ? errsObj['ProductImage'] = false : errsObj['ProductImage'] = true;
                setFieldErrors(fieldErrors = errsObj);
                let macaroonArray = macaroonsBoxPriceList?.map((val) => {
                    if (!val.MinCount) { val['Count_Value'] = false };
                    if (!val.PricePerBox) { val['Price_Value'] = false };
                    return val;
                });
                setMacaroonsBoxPriceList(macaroonsBoxPriceList = macaroonArray);
                if (!fieldErrors['Flavour'] && !fieldErrors['ProductImage'] && macaroonsBoxPriceList?.length > 0 &&
                    !macaroonsBoxPriceList.find(val => !val?.MinCount || !val?.PricePerBox)) {
                    setMessage('');
                    let finalMacaroonsList = macaroonsBoxPriceList?.map(val => {
                        return {
                            Piece: val?.Piece,
                            MinCount: val?.MinCount,
                            PricePerBox: val?.PricePerBox
                        }
                    });
                    formData.append('MinWeightPerBox', JSON.stringify(finalMacaroonsList));
                    setLoader(true);
                    dispatch({
                        type: actions.UPDATE_OTHER_PRODUCT,
                        payload: { id: OtherProductDetails?._id, data: formData, history: history }
                    });
                } else {
                    if (macaroonsBoxPriceList?.length === 0) {
                        setMessage("*Minimum Pieces and Price Per Box is required");
                    } else {
                        setMessage('*Required fields are missing');
                    };
                };
            } else if (OtherProductDetails?.CakeSubType === 'Cake Dessert') {
                let errsObj = { ...fieldErrors };
                errsObj['ButtonClicked'] = true;
                (flavourList?.length > 0) ? errsObj['Flavour'] = false : errsObj['Flavour'] = true;
                (productImage?.url) ? errsObj['ProductImage'] = false : errsObj['ProductImage'] = true;
                setFieldErrors(fieldErrors = errsObj);
                let cakeDessertsArray = cakeDessertWeightAndPriceList?.map((val) => {
                    if (!val.MinCount) { val['Count_Value'] = false };
                    if (!val.PricePerUnit) { val['Price_Value'] = false };
                    return val;
                });
                setCakeDessertWeightAndPriceList(cakeDessertWeightAndPriceList = cakeDessertsArray);
                if (!fieldErrors['Flavour'] && !fieldErrors['ProductImage'] && cakeDessertWeightAndPriceList?.length > 0 &&
                    !cakeDessertWeightAndPriceList?.find(val => !val?.MinCount || !val?.PricePerUnit)
                ) {
                    setMessage('');
                    let finalCakeDessetsList = cakeDessertWeightAndPriceList?.map(val => {
                        return {
                            Weight: val?.Weight,
                            MinCount: val?.MinCount,
                            PricePerUnit: val?.PricePerUnit
                        }
                    });
                    formData.append('MinWeightPerUnit', JSON.stringify(finalCakeDessetsList));
                    setLoader(true);
                    dispatch({
                        type: actions.UPDATE_OTHER_PRODUCT,
                        payload: { id: OtherProductDetails?._id, data: formData, history: history }
                    });
                } else {
                    if (cakeDessertWeightAndPriceList?.length === 0) {
                        setMessage("*Minimum Weight and Price Per Unit is required");
                    } else {
                        setMessage('*Required fields are missing');
                    };
                };
            };
        };
    };

    const handleAddOtherMPBox = (e, field) => {
        e.preventDefault();
        setShow2(true);
        setFieldName(field);
    };

    const handleClose2 = () => {
        setShow2(false);
    };

    const handleClose = (e) => {
        e.preventDefault();
        history.push('/vendor-product-details', { Id: OtherProductDetails?._id });
    };

    useEffect(() => {
        if (OtherProductResponse?.statusCode === 200 || OtherProductResponse?.statusCode === 400) {
            setLoader(false);
        };
    }, [OtherProductResponse]);

    useEffect(() => {
        if (OtherProductDetails?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
            setFlavourList(OtherProductDetails?.Flavour);
            setMinWeightPerKg(OtherProductDetails?.MinWeightPerKg?.Weight);
            setMinPricePerKg(OtherProductDetails?.MinWeightPerKg?.PricePerKg);
            let data = {
                ProductName: OtherProductDetails?.ProductName,
                Discount: OtherProductDetails?.Discount,
                Stock: OtherProductDetails?.Stock,
                Description: OtherProductDetails?.Description,
                Shape: OtherProductDetails?.Shape
            };
            reset(data);
            setProductImage({ file: OtherProductDetails?.ProductImage[0], url: OtherProductDetails?.ProductImage[0], type: 'old' });
            if (OtherProductDetails?.AdditionalProductImages && OtherProductDetails?.AdditionalProductImages?.length > 0) {
                let additionalImages = [];
                for (let i = 0; i < OtherProductDetails?.AdditionalProductImages?.length; i++) {
                    additionalImages.push({ file: OtherProductDetails?.AdditionalProductImages[i], url: OtherProductDetails?.AdditionalProductImages[i], type: 'old' });
                };
                setAdditionalProductImage(additionalImages);
            };
        };
        // eslint-disable-next-line
    }, [OtherProductDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="col-md-12">
                        <Breadcrumb Id={state?.state?.Id} />
                    </div>
                    <div className="col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Update Product&apos;s Details ({OtherProductDetails?.Id})</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body tabledata-background">
                                <form className="needs-validation clearfix" noValidate="">
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom18" className='font-weight-bold'>Product Type <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control text-capitalize"
                                                    defaultValue={OtherProductDetails?.CakeSubType === 'macaroons' ? 'Macarons' : OtherProductDetails?.CakeSubType}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8 mb-3">
                                            <label htmlFor="validationCustom18" className='font-weight-bold'>Product Name <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className={errors?.ProductName ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                    name='ProductName'
                                                    {...register('ProductName')}
                                                    placeholder="Product Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 text-left">
                                            <label htmlFor="validationCustom22" className='font-weight-bold '>Flavour <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <div className="input-group">
                                                    <div className='display-flex w-100'>
                                                        <input
                                                            type="text"
                                                            className={fieldErrors?.Flavour ? "form-control required-field text-capitalize" : "form-control text-capitalize"}
                                                            placeholder="e.g. Vanilla"
                                                            value={flavours}
                                                            onChange={(e) => setFlavours(e.target.value)}
                                                        />
                                                        <button
                                                            className='add-button rounded disabled-add'
                                                            disabled={flavours?.length === 0 ? true : false}
                                                            onClick={handleAddFlavours}
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        {flavourList?.map((value, index) =>
                                                            <div key={index}>
                                                                <div className='list-map text-left'>
                                                                    {value}
                                                                    <button
                                                                        className='btn-secondary list-map-remove float-right'
                                                                        onClick={(e) => RemoveAddedFlavoursName(e, index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {(OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' || OtherProductDetails?.CakeSubType === 'Tea Cake' || OtherProductDetails?.CakeSubType === 'Cheese Cake') ?
                                            <div className="col-md-6 mb-3">
                                                <label htmlFor="validationCustom22" className='font-weight-bold'>Shape <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className="form-control"
                                                        defaultValue=''
                                                        name='Shape'
                                                        {...register('Shape')}
                                                    >
                                                        <option value='' disabled='disabled'>-- Select --</option>
                                                        <option value="Round">Round</option>
                                                        <option value="Square">Square</option>
                                                    </select>
                                                </div>
                                            </div> : <></>
                                        }
                                        {(OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' || OtherProductDetails?.CakeSubType === 'Tea Cake' ||
                                            OtherProductDetails?.CakeSubType === 'Cheese Cake' || OtherProductDetails?.CakeSubType === 'Chocolates') ?
                                            <div className="col-md-6 mb-3 text-left">
                                                <label htmlFor="validationCustom22" className='font-weight-bold '>Minimum Weight <span className='text-danger'>*</span></label>
                                                <div className="input-group">
                                                    <select
                                                        className={fieldErrors?.MinWeight ? "form-control required-field" : "form-control"}
                                                        value={minWeightPerKg}
                                                        onChange={(e) => {
                                                            setMinWeightPerKg(e.target.value);
                                                            if (!e?.target?.value && fieldErrors['ButtonClicked'] === true) {
                                                                setFieldErrors(fieldErrors = { ...fieldErrors, MinWeight: true });
                                                            } else {
                                                                setFieldErrors(fieldErrors = { ...fieldErrors, MinWeight: false });
                                                            };
                                                            if (!fieldErrors['Flavour'] && !fieldErrors['MinWeight'] && !fieldErrors['Price'] && !fieldErrors['ProductImage']) {
                                                                setMessage('');
                                                            };
                                                        }}
                                                    >
                                                        {(OtherProductDetails?.CakeSubType === 'Chocolates' || OtherProductDetails?.CakeSubType === 'Cookies') ?
                                                            <option value="250g">250g</option> :
                                                            <></>
                                                        }
                                                        <option value="500g">500g</option>
                                                        <option value="750g">750g</option>
                                                        <option value="1kg">1kg</option>
                                                        <option value="1.5kg">1.5kg</option>
                                                        <option value="2kg">2kg</option>
                                                        <option value="2.5kg">2.5kg</option>
                                                        <option value="3kg">3kg</option>
                                                    </select>
                                                </div>
                                            </div> :
                                            <></>
                                        }
                                        {(OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' || OtherProductDetails?.CakeSubType === 'Chocolates' ||
                                            OtherProductDetails?.CakeSubType === 'Tea Cake' || OtherProductDetails?.CakeSubType === 'Cheese Cake') ?
                                            <div className="col-md-6 mb-3 text-left">
                                                {(OtherProductDetails?.CakeSubType === 'Brownie' || OtherProductDetails?.CakeSubType === 'Cookies' || OtherProductDetails?.CakeSubType === 'Chocolates' ||
                                                    OtherProductDetails?.CakeSubType === 'Tea Cake' || OtherProductDetails?.CakeSubType === 'Cheese Cake') ?
                                                    <label htmlFor="validationCustom22" className='font-weight-bold'>Price/kg <span className='text-danger'>*</span></label> :
                                                    <></>
                                                }
                                                <div className="input-group">
                                                    <input
                                                        type="number"
                                                        onWheel={() => document.activeElement.blur()}
                                                        min={0}
                                                        maxLength={10}
                                                        onInput={(e) => {
                                                            if (e.target.value.length > e.target.maxLength) {
                                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                            } else if (e.target.value < e.target.min) {
                                                                e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                            }
                                                        }}
                                                        className={fieldErrors?.Price ? "form-control required-field" : "form-control"}
                                                        id="validationCustom24"
                                                        placeholder="e.g. 300"
                                                        defaultValue={minPricePerKg}
                                                        onChange={(e) => {
                                                            setMinPricePerKg(e.target.value);
                                                            if (!e?.target?.value && fieldErrors['ButtonClicked'] === true) {
                                                                setFieldErrors(fieldErrors = { ...fieldErrors, Price: true });
                                                            } else {
                                                                setFieldErrors(fieldErrors = { ...fieldErrors, Price: false });
                                                            };
                                                            if (!fieldErrors['Flavour'] && !fieldErrors['MinWeight'] && !fieldErrors['Price'] && !fieldErrors['ProductImage']) {
                                                                setMessage('');
                                                            };
                                                        }}
                                                    />
                                                    <button className="RupeeSign" disabled>Rs</button>
                                                </div>
                                            </div> :
                                            <></>
                                        }
                                        {(OtherProductDetails?.CakeSubType === 'Cake Dessert') &&
                                            <div className="col-md-6 mb-3 text-left">
                                                <CakeDessertsWeightContent
                                                    option={CakeDessertsWeight}
                                                    className='option-control'
                                                    handleCDWRemoveButton={handleCDWRemoveButton}
                                                    handleCDWInputValue={handleCDWInputValue}
                                                    handleCDWSelectButton={handleCDWSelectButton}
                                                    disabledButton={disabledButton}
                                                    handleAddOtherWeight={handleAddOtherWeight}
                                                    cakeDessertWeightAndPriceList={cakeDessertWeightAndPriceList}
                                                />
                                            </div>
                                        }
                                        {(OtherProductDetails?.CakeSubType === 'macaroons' || OtherProductDetails?.CakeSubType === 'Cup Cake') ?
                                            <div className="col-md-6 mb-3 text-left">
                                                <MacaroonBox
                                                    option={macaroonsPiece}
                                                    Name={OtherProductDetails?.CakeSubType}
                                                    className='option-control'
                                                    handleMPRemoveButton={handleMPRemoveButton}
                                                    handleMPInputValue={handleMPInputValue}
                                                    handleMPSelectButton={handleMPSelectButton}
                                                    disabledButton2={disabledButton2}
                                                    handleAddOtherMPBox={handleAddOtherMPBox}
                                                    macaroonsBoxPriceList={macaroonsBoxPriceList}
                                                />
                                            </div> :
                                            <></>
                                        }
                                        <div className="col-md-6 mb-3 text-left">
                                            <label htmlFor="validationCustom22" className='font-weight-bold '>Discount (%) <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="number"
                                                    onWheel={() => document.activeElement.blur()}
                                                    min={0}
                                                    maxLength={4}
                                                    onInput={DiscountValidation}
                                                    className={errors?.Discount ? "form-control required-field" : "form-control"}
                                                    name='Discount'
                                                    {...register('Discount')}
                                                    id="validationCustom25"
                                                    placeholder='e.g. 5'
                                                />
                                                <button className="RupeeSign" disabled>%</button>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 text-left">
                                            <label htmlFor="validationCustom22" className='font-weight-bold '>Stock <span className='text-danger'>*</span></label>
                                            <div className="input-group">
                                                <select
                                                    name='Stock'
                                                    className={errors?.Stock ? "form-control required-field" : "form-control"}
                                                    {...register('Stock')}
                                                >
                                                    <option value="OutOfStock">Out Of Stock</option>
                                                    <option value="InStock">In Stock</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body tabledata-background">
                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Product Image <span className='text-danger'>*</span></label>
                                <div className="col-md-12 mb-3">
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            accept='image/jpg,image/jpeg,image/png'
                                            className="custom-file-input"
                                            name='files'
                                            onChange={handleAddProductImages}
                                        />
                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Product Images...</label>
                                    </div>
                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    <p className="text-danger">{fieldErrors?.ProductImage ? "*Product Image is required" : ''}</p>
                                    <div className='display-flex'>
                                        <div className='display-flex'>
                                            {(productImage?.url) &&
                                                <div className="imgPre">
                                                    <img
                                                        width='90px'
                                                        className='update-select-cake'
                                                        src={productImage?.url}
                                                        alt="Cake"
                                                    />
                                                    <button
                                                        className='btn-secondary imgPreCloseBtn'
                                                        onClick={handleRemoveProductImage}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <label htmlFor="validationCustom12" className='font-weight-bold ml-2'> Add Additional Product Image</label>
                                <div className="col-md-12 mb-3">
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            accept='image/jpg,image/jpeg,image/png'
                                            className="custom-file-input"
                                            name='files'
                                            multiple
                                            onChange={handleAddAdditionalProductImages}
                                        />
                                        <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Product Images...</label>
                                    </div>
                                    <p className='text-success font-weight-bold'><i>* Supported Image Formats : .jpeg, .jpg, .png</i></p>
                                    <div className='display-flex'>
                                        <div className='display-flex'>
                                            {additionalProductImage?.map((value, index) =>
                                                <div className="imgPre" key={index}>
                                                    <img
                                                        width='90px'
                                                        className='update-select-cake'
                                                        src={value?.url}
                                                        alt="Cake"
                                                    />
                                                    <button
                                                        className='btn-secondary imgPreCloseBtn'
                                                        onClick={(e) => handleRemoveAdditionalProductImage(e, index)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-body tabledata-background">
                                <label htmlFor="validationCustom12" className='font-weight-bold'>Product Description (400 Characters Only) <span className='text-danger'>*</span></label>
                                <div className="input-group">
                                    <textarea
                                        rows={5}
                                        maxLength={400}
                                        className={errors?.Description ? "form-control required-field text-capitalize description-textarea" : "form-control text-capitalize description-textarea"}
                                        name='Description'
                                        {...register('Description')}
                                        placeholder="Message" />
                                </div>
                                {loader ?
                                    <CircleDotSpinner /> :
                                    <>
                                        <p className='text-success text-left mb-3'><b>Note : </b>If you want to update this product then it will be send to admin for Approval</p>
                                        <p className='text-danger'>{message}</p>
                                        <div className='cake-details-button'>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-3"
                                                onClick={handleSubmit(handleUpdateProduct)}
                                            >
                                                Update
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="modal-min"
                        show={show2}
                        onHide={handleClose2}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        contentClassName="modal-body-scrollar"
                    >
                        <Modal.Body className="text-center tabledata-background">
                            <button
                                type="button"
                                className="close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClose2();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                            <h1>Enter {fieldName === 'macaroons Pieces Per Box' ? 'Macarons Pieces Per Box' : fieldName}</h1>
                            <form>
                                <div className="col-md-12 mt-4 mb-3 px-md-2">
                                    <div className="input-group">
                                        <div className='display-flex w-100'>
                                            <input
                                                type="number"
                                                onWheel={() => document.activeElement.blur()}
                                                min={0}
                                                maxLength={5}
                                                onInput={(e) => {
                                                    if (e.target.value.length > e.target.maxLength) {
                                                        e.target.value = e.target.value.slice(0, e.target.maxLength)
                                                    } else if (e.target.value < e.target.min) {
                                                        e.target.value = !!e.target.value && Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null
                                                    }
                                                }}
                                                className="form-control"
                                                id="validationCustom24"
                                                onChange={(e) => setNewWeight(e.target.value)}
                                                placeholder={fieldName === 'Cake Dessert Weight Per Unit' ? "e.g. 700" : 'e.g. 15'}
                                                // placeholder={fieldName === 'Cake Dessert Weight Per Unit' ? "e.g. 700" : fieldName === 'macaroons Pieces Per Box' ? 'e.g. 15' : ''}
                                            />
                                            {(fieldName === 'Cake Dessert Weight Per Unit') ?
                                                <select
                                                    className="form-control w-50"
                                                    defaultValue='g'
                                                    id="validationCustom22"
                                                    onChange={(e) => setNewWeightUnit(e.target.value)}
                                                >
                                                    <option value="g">grams</option>
                                                    <option value="kg">kg</option>
                                                </select> :
                                                // (fieldName === 'macaroons Pieces Per Box') ?
                                                    <input value='Pieces' className="form-control w-50" disabled /> 
                                                    // :   <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <p className='text-danger text-left'>{showMessage}</p>
                                <button
                                    type="submit"
                                    onClick={handleAddNew}
                                    className="btn btn-primary shadow-none mr-3"
                                >
                                    Ok
                                </button>
                                <button
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClose2();
                                    }}
                                    className="btn btn-secondary shadow-none"
                                >
                                    Cancel
                                </button>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div >
            }
        </>
    )
};