import React from "react"
export default function EditImageUrl({ imagesurl, deleteFun, divClassName, display }) {

    // var imageurl = []

    // if (props.imagesurl === undefined) {

    // } else {
    //     imageurl = props.imagesurl
    // }
    return (
        <div className={divClassName} >
            {imagesurl.map((value) =>
                <div className="imgPre" key={value}>
                    <img width='70px' className='update-select-cake' src={value} alt='Cake' />
                    {display === 'sample' &&
                        <button className='btn-secondary imgPreCloseBtn' value={value} onClick={deleteFun}>X</button>
                    }
                </div>
            )}
        </div>
    )
}