import React from 'react';
import Sidenavigation from '../Layouts/Sidenavigation';
import Topnavigation from '../Layouts/Topnavigation';
import Content from '../../CommonFolders/Current-Orders-List/Content';
import { Helmet } from "react-helmet";

export default function ManagementCurrentOrdersList() {

    return (
        <div className="ms-body ms-aside-left-open ms-primary-theme ">
            {/* title */}
            <Helmet>
                <title>Current Orders</title>
            </Helmet>

            {/* sidebar start  */}
            <Sidenavigation />
            {/* sidebar end */}

            <main className="body-content">

                {/* Topnavigation start */}
                <Topnavigation />
                {/* Topnavigation end  */}

                {/* page content start  */}
                <Content Page='Management' />
                {/* page content end  */}

            </main>

        </div>
    );
};