import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AdminActions from '../../../redux/Admin/actions';
import Preloader from '../../layouts/Preloader';
import CircleDotSpinner from '../../common/CircleDotSpinner';
import Breadcrumb from './Breadcrumb';
import { useForm } from 'react-hook-form';
import { MobilenumberValidation } from '../../../utils/Validation';

function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.Id;

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [showUpdatePopup, setShowUpdatePopup] = useState(false);
    const [loader, setLoader] = useState(false);

    const { InternalUserDetails, AdminResponse } = useSelector(state => state.adminReducer);

    const { register, handleSubmit, formState: { errors } } = useForm();

    useEffect(() => {
        dispatch({ type: AdminActions.GET_INTERNAL_USER_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    const handleUpdatePopup = (e) => {
        e.preventDefault();
        setShowUpdatePopup(true);
    };

    const handleClose = () => { setShowUpdatePopup(false) };

    const handleUpdate = (data) => {
        const formData = {
            Name: data.Name,
            Mobilenumber: data.Mobilenumber,
        };
        setLoader(true);
        dispatch({
            type: AdminActions.UPDATE_INTERNAL_USER_DETAILS,
            payload: { Id: InternalUserDetails?._id, data: formData }
        });
    };

    useEffect(() => {
        if (AdminResponse?.statusCode === 200 || AdminResponse?.statusCode === 400) {
            setLoader(false);
            setShowUpdatePopup(false);
        };
    }, [AdminResponse]);

    useEffect(() => {
        if (InternalUserDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [InternalUserDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb />
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6 >Internal User&apos;s Details</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel ms-panel-fh">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">DETAILS</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Internal User ID</th>
                                                    <td><b>{InternalUserDetails?.Id}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">User Name</th>
                                                    <td>{InternalUserDetails?.Name}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Email</th>
                                                    <td>{InternalUserDetails?.Email}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Mobile Number</th>
                                                    <td>{InternalUserDetails?.Mobilenumber}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Type of User</th>
                                                    <td>{(InternalUserDetails?.TypeOfUser === 'Helpdesk V' ? 'Vendor Helpdesk' : (InternalUserDetails?.TypeOfUser === 'Helpdesk C') ? 'Customer Helpdesk' : InternalUserDetails?.TypeOfUser)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Created On</th>
                                                    <td>{InternalUserDetails?.Created_On}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button
                                            className="btn btn-primary mr-3"
                                            type="button"
                                            onClick={handleUpdatePopup}
                                        >
                                            Update
                                        </button>
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() => history.push('/Admin-InternalUsers-List')}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Modal className="modal-min" show={showUpdatePopup} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                            centered contentClassName="modal-body-scrollar">
                            <Modal.Body className="text-center tabledata-background">
                                <button type="button" className="close" onClick={handleClose}><span aria-hidden="true">×</span></button>
                                <h1 className="text-center pb-2">Update Internal User&apos;s Details</h1>
                                <div className="form-row">
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Email</label>
                                        <div className="input-group">
                                            <div className='form-control text-left'>{InternalUserDetails?.Email}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Name<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                className={errors?.Name ? 'form-control required-field' : 'form-control'}
                                                {...register('Name', { required: { value: true, message: 'required' } })}
                                                defaultValue={InternalUserDetails?.Name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label className='float-left font-weight-bold' htmlFor="validationCustom18">Mobile Number<span className='text-danger'>*</span></label>
                                        <div className="input-group">
                                            <input
                                                className={errors?.Mobilenumber ? 'form-control required-field' : 'form-control'}
                                                onInput={MobilenumberValidation}
                                                {...register('Mobilenumber', { required: { value: true, message: 'required' } })}
                                                defaultValue={InternalUserDetails?.Mobilenumber}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {loader ?
                                    <div className="login-spinner2"><CircleDotSpinner /></div> :
                                    <>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mr-3"
                                            onClick={handleSubmit(handleUpdate)}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-secondary"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </>
                                }
                            </Modal.Body>
                        </Modal>
                    </div >
                </div >
            }
        </>
    );
}

export default Content;