import React from "react";
import CircleDotSpinner from "../../../common/CircleDotSpinner";
import { Modal } from "react-bootstrap";
// import { PushNotification } from "../../../common/PushNotification";

export default function IntimationToManagerForMiscellaneous(props) {

    const { showIntimateToManagerForMiscellaneous, handleCloseIntimateToCustomerForChangeRequestPopup, loader,
        handleIntimateToManager } = props;

    // const handleUpdate = (e) => {
        // if (UserDetails?.Notification === 'y' && UserDetails.Notification_Id) {
        //     let pushMessageData = {
        //         type: 'customer',
        //         registration_ids: [UserDetails.Notification_Id],
        //         title: 'Cakey',
        //         body: 'Your Flavour/Shape Change Request Difference Amount Received',
        //     }
        //     PushNotification(pushMessageData);
        // };
        // handleIntimateToCustomerForChangeRequestApprove(e);
    // };

    return (
        <div>
            <Modal show={showIntimateToManagerForMiscellaneous} onHide={handleCloseIntimateToCustomerForChangeRequestPopup} aria-labelledby="contained-modal-title-vcenter"
                centered contentClassName="modal-body-scrollar">
                <Modal.Body className="tabledata-background modal-body-content">
                    <button type="button" className="close change-password-close" onClick={handleCloseIntimateToCustomerForChangeRequestPopup}><span aria-hidden="true">×</span></button>
                    <h4 className="text-center pb-2">Confirmation</h4>
                    <div>
                        <h6 className='text-center pb-2'>Are you sure do you want to intimate to Manager?</h6>
                    </div>
                    {loader ?
                        <div className="login-spinner2"><CircleDotSpinner /></div> :
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-primary shadow-none mr-3"
                                onClick={handleIntimateToManager}
                            >
                                Yes
                            </button>
                            <button
                                type="submit"
                                className="btn btn-secondary shadow-none"
                                onClick={handleCloseIntimateToCustomerForChangeRequestPopup}
                            >
                                No
                            </button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
};