import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/Helpdesk/actions';
import commonActions from '../../../redux/Common/actions';
import TableSearch from '../../common/TableSearch';
import Preloader from '../../layouts/Preloader';
import Breadcrumb from './Breadcrumb';
import moment from 'moment';
import ReactPagination from '../../common/React-Pagination';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../common/SortingOrders';

export default function Content(props) {

    const { Page } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const { CurrentOrdersList } = useSelector(state => state.HelpdeskReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [search, setsearch] = useState('');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [buttonChange, setButtonChange] = useState(false);

    let [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'CO' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');
    let [endDate, setEndDate] = useState((Filtered_Dates?.Page === 'CO' && moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.EndDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "A" },
    ]);

    useEffect(() => {
        dispatch({
            type: actions.GET_CURRENT_ORDERS_LIST,
            payload: { StartDate: ((Filtered_Dates?.Page === 'CO' && Filtered_Dates?.StartDate) ? Filtered_Dates?.StartDate : moment().format('DD-MM-YYYY')), EndDate: ((Filtered_Dates?.Page === 'CO' && Filtered_Dates?.EndDate) ? Filtered_Dates?.EndDate : 'today') }
            // payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: 'today' }
        });
        if (Filtered_Dates?.StartDate && Filtered_Dates?.EndDate && Filtered_Dates?.Page === 'CO') {
            setButtonChange(true);
        };
        //eslint-disable-next-line
    }, [Filtered_Dates]);

    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    useEffect(() => {
        if (CurrentOrdersList.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            setFilteredData(CurrentOrdersList.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.VendorName && val.VendorName.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search)) ||
                    (val.Created_On && val.Created_On.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [CurrentOrdersList, search]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    const handleSelectedDateRange = (e, Type) => {
        Type === 'StartDate' ? setStartDate(startDate = e.target.value) : setEndDate(endDate = e.target.value);
    };

    const handleFilterDateRange = (e) => {
        e.preventDefault();
        if (buttonChange) {
            setStartDate('');
            setEndDate(moment().format('YYYY-MM-DD'));
            setButtonChange(false);
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, EndDate: null, Page: null } });
            setSorting([{ table: 0, field: "OD", type: "A" }]);
            dispatch({
                type: actions.GET_CURRENT_ORDERS_LIST,
                // payload: { StartDate: '08-10-2022', EndDate: 'today' }
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: 'today' }
            });
        } else {
            if (startDate && endDate) {
                setButtonChange(true);
                dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'CO' } });
                dispatch({
                    type: actions.GET_CURRENT_ORDERS_LIST,
                    payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
                });
            };
        };
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        setSorting([{ table: 0, field: "OD", type: "A" }]);
        setsearch('');
        if (startDate && endDate) {
            setButtonChange(true);
            dispatch({
                type: actions.GET_CURRENT_ORDERS_LIST,
                payload: { StartDate: moment(startDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), EndDate: moment(endDate, 'YYYY-MM-DD').format('DD-MM-YYYY') }
            });
        } else {
            dispatch({
                type: actions.GET_CURRENT_ORDERS_LIST,
                payload: { StartDate: moment().format('DD-MM-YYYY'), EndDate: 'today' }
            });
        }
    };

    const ViewDetails = (e, details) => {
        e.preventDefault();
        let Type;
        let Path = (Page === 'Manager') ? '/Manager-Current-Order-Details' :
            Page === 'Accounts' ? '/Accounts-Current-Order-Details' : '/Management-Current-Order-Details';
        (details.CakeID) ? Type = 'Cake Order' : (details.HamperID) ? Type = 'Hamper Order' : Type = 'Product Order';
        history.push(Path, { Id: details._id, Type: Type });
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = filteredData;
        let result = SortingOrders({ type, field, filterData });
        setFilteredData(result);
    };

    useEffect(() => {
        (CurrentOrdersList.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
    }, [CurrentOrdersList]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb Page={Page} handlePageRefresh={handlePageRefresh} />
                            <div className="ms-panel">
                                <div className="ms-panel-header">
                                    <Row>
                                        <Col xl={5} lg={12} md={12} sm={12}>
                                            <h6>Orders List Based On Order Date</h6>
                                        </Col>
                                        <Col xl={7} lg={12} md={12} sm={12} className='d-flex justify-content-end'>
                                            <Row>
                                                <Col xl={1} lg={1} md={1} sm={1}></Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-4'
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={startDate || ''}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'StartDate')}
                                                    />
                                                </Col>
                                                <Col xl={4} lg={4} md={4} sm={4}>
                                                    <input
                                                        type='date'
                                                        className='form-control ml-3'
                                                        min={moment(startDate).format('YYYY-MM-DD')}
                                                        max={moment().format('YYYY-MM-DD')}
                                                        value={endDate || ''}
                                                        disabled={buttonChange}
                                                        onChange={(e) => handleSelectedDateRange(e, 'EndDate')}
                                                    />
                                                </Col>
                                                <Col xl={3} lg={3} md={3} sm={3}>
                                                    <Button
                                                        size='sm'
                                                        variant={buttonChange ? 'danger' : 'success'}
                                                        disabled={(startDate && endDate) ? false : true}
                                                        className={(startDate && endDate) ? 'form-control font-weight-bold' : 'form-control font-weight-bold cursor-notallowed'}
                                                        onClick={handleFilterDateRange}
                                                    >
                                                        {buttonChange ? 'Clear' : 'Filter'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <TableSearch label='Search' type="search" placeholder="search Id/Status..." onChange={handleSearchbar} value={search || ''} />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table id="data-table-5" className="table w-100 thead-primary dataTable no-footer table-hover" role="grid" aria-describedby="data-table-5_info" >
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Order Type</th>
                                                                <th scope="col">Vendor Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                {/* <th scope="col">Delivery Session</th> */}
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((cake) =>
                                                                    <tr className="odd cursor" key={cake._id} onClick={(e) => ViewDetails(e, cake)}>
                                                                        <th className='id-column' scope="row">{cake.Id}</th>
                                                                        <td>{cake.CakeID ? 'Cake Order' : cake.HamperID ? 'Hamper Order' : 'Product Order'}</td>
                                                                        <td>{cake.VendorName ?? '-'}</td>
                                                                        <td>{cake.UserName}</td>
                                                                        <td>{cake.Created_On}</td>
                                                                        <td>{cake.DeliveryDate}</td>
                                                                        {/* <td>{cake.DeliverySession}</td> */}
                                                                        <td className='price-align3-row'><span className={(cake.Status?.toUpperCase())?.replace(/ /g, '')}>{cake.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={7} className='font-weight-bold'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};