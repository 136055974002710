import React, { useState, useEffect } from 'react';
import Preloader from '../../../layouts/Preloader';
import { useHistory } from 'react-router-dom/esm/react-router-dom';
// import EditVendorProfileContent from '../EditVendorProfile/Content';
import { useSelector, useDispatch } from "react-redux";
import actions from '../../../../redux/Vendor/actions';
import Breadcrumb from '../../../vendor/section/vendorprofile/Breadcrumb';
import InstituteNameList from '../../../sections/AdminVendorListDetail/InstituteNameList';
import CakeTypeList from '../../../sections/AdminVendorListDetail/CakeTypeList';
import SpacialityCakeList from '../../../sections/AdminVendorListDetail/SpecialityCakeList';

function Content() {

    // const [edit, setedit] = useState(false);
    const [fetchSuccess, setFetchSuccess] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    //get logged in users details
    const { token } = useSelector(state => state.authReducer);

    //get vendor's details
    const { vendorsDetails } = useSelector(state => state.VendorReducer);
    // const VendorDetails = useSelector(state => state.VendorReducer);

    //dispatch the get vendor's details api
    useEffect(() => {
        dispatch({ type: actions.GET_SINGLE_VENDORS, payload: token?.result?.Email });
        // eslint-disable-next-line
    }, [token]);

    //close the page
    // const handleClose = (e) => {
    //     e.preventDefault();
    //     setedit(false);
    //     if (auth.token !== undefined) {
    //         dispatch({ type: actions.GET_SINGLE_VENDORS, payload: auth.token.result.Email });
    //     }
    // };

    //loading
    useEffect(() => {
        if (vendorsDetails.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        }
    }, [vendorsDetails]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <>
                    {vendorsDetails?.map((v, i) =>
                        <div key={i}>
                            {/* {edit === false ? */}
                            <div className="ms-content-wrapper">
                                <div className="col-md-12">
                                    <Breadcrumb />
                                </div>
                                <div className="ms-profile-overview">
                                    <div className="ms-profile-cover">
                                        <img className="ms-profile-img" src={v.ProfileImage === undefined ? "https://sugitechnologies.com/images/AppImages/istockphoto-1196083861-612x612_kbvicw.jpg" : v.ProfileImage} alt="people" />
                                        <div className="ms-profile-user-info">
                                            <h4 className="ms-profile-username text-white">{v.VendorName}</h4>
                                            <h2 className="ms-profile-role">{v.Email}</h2>
                                        </div>
                                        {/* <div className="ms-profile-user-buttons">
                                                <button className="btn btn-primary edit-profile" onClick={() => setedit(true)}> <i className="fas fa-pencil-alt" /> Edit</button>
                                            </div> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h2 className="section-title">BASIC INFORMATION</h2>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Register ID</th>
                                                            <td><b>{v.Id}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Email</th>
                                                            <td>{v.Email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Preferred Name on the App</th>
                                                            <td>{v.VendorName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Vendor Name</th>
                                                            <td>{v.PreferredNameOnTheApp}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Gender</th>
                                                            <td>{v.Gender}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Date of Birth</th>
                                                            <td>{v.DateOfBirth}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Egg or Eggless</th>
                                                            <td>{v.EggOrEggless}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Main Mobile Number</th>
                                                            <td>{v.PhoneNumber1}</td>
                                                        </tr>
                                                        {v.PhoneNumber2 &&
                                                            <tr>
                                                                <th scope="row">Alternative Mobile Number</th>
                                                                <td>{v.PhoneNumber2}</td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <th scope="row">Address Line 1</th>
                                                            <td><span className="long-line-break">{v.DoorNo}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Address Line 2</th>
                                                            <td><span className="long-line-break">{v.Address}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Pincode</th>
                                                            <td><span className="long-line-break">{v.Pincode}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h2 className="section-title">BANK DETAILS</h2>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Bank Name</th>
                                                            <td>{v.BankName}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Branch</th>
                                                            <td>{v.Branch}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Account Number</th>
                                                            <td>{v.AccountNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">IFSC Code</th>
                                                            <td>{v.IFSCCode}</td>
                                                        </tr>
                                                        {v.UPIId ?
                                                            <tr>
                                                                <th scope="row">UPI ID</th>
                                                                <td>{v.UPIId}</td>
                                                            </tr> : <></>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h2 className="section-title"> IDENTITY AND LICENSE DETAILS</h2>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Aadhaar Number</th>
                                                            <td>{v.AadhaarNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">PAN Number</th>
                                                            <td>{v.PANNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">FSSAI Number</th>
                                                            <td>{v.FSSAINumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">FSSAI Expiry Date</th>
                                                            <td>{v.FSSAIExpiryDate}</td>
                                                        </tr>
                                                        {v.GSTNumber ?
                                                            <tr>
                                                                <th scope="row">GST Number</th>
                                                                <td>{v.GSTNumber}</td>
                                                            </tr> : <></>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel ms-panel-fh">
                                            <div className="ms-panel-body tabledata-background">
                                                <h2 className="section-title">OTHER DETAILS</h2>
                                                <table className="table ms-profile-information">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Learning Type</th>
                                                            <td>{v.LearningType}</td>
                                                        </tr>
                                                        {v.InstitutionName.length > 0 &&
                                                            <tr>
                                                                <th scope="row">Institution Name</th>
                                                                <td><span className="long-line-break"><InstituteNameList NameList={v.InstitutionName} /></span></td>
                                                                {/* <td>{v.InstitutionName}</td> */}
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <th scope="row">Total Duration of Learning</th>
                                                            <td>{v.TotalDurationOfLearning}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Maximum Capacity to Bake in a Week (in kgs)</th>
                                                            <td>{v.HowManyCakesCanYouMakeInaWeek}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Current Average Sales Per Month (in kgs)</th>
                                                            <td>{v.CurrentAverageSalePerMonth}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Baking Experience</th>
                                                            <td>{v.YearsOfExperienceAsBaker}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Number of Days You Can Bake In a Week</th>
                                                            <td>{v.HowManyDaysCanYouWorkInaWeek}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Type Of Baker</th>
                                                            <td>{v.JobType}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Can You Bake Cakes Above 5kg </th>
                                                            <td>{v.CanYouMakeSingleCakeAbove5Kgs === 'y' ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Do You Make Tier Cake</th>
                                                            <td>{v.CanYouMakeTierCakes === 'y' ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Comfortable on Working With Mobile Apps</th>
                                                            <td>{v.AreYouFamiliarOnWorkingWithApps === 'y' ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Types of Cake/Other Product</th>
                                                            <td><span className="long-line-break"><CakeTypeList NameList={v.CakeTypesYouBake} /></span></td>
                                                            {/* <td>{v.CakeTypesYouBake[0]}</td> */}
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Your Speciality Cakes</th>
                                                            <td><span className="long-line-break"><SpacialityCakeList NameList={v.YourSpecialityCakes} /></span></td>
                                                            {/* <td>{v.YourSpecialityCakes}</td> */}
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Self Rating</th>
                                                            <td>{v?.Ratings} Star</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Do You Bake Regular Cake With Fondant Topper</th>
                                                            <td>{v.CanYouMakeARegularCakeWithFondantAsToppers === 'y' ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-md-12">
                                        <div className="ms-panel">
                                            <div className="ms-panel-body tabledata-background">
                                                <h2 className="section-title">ABOUT VENDOR</h2>
                                                <p>{v.Description}</p>
                                                <div className="registered-vendor-close">
                                                    <button className="btn btn-secondary " type="submit" onClick={() => history.push('/vendordashboard')} >Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            {/* :
                                <EditVendorProfileContent closeEdit={handleClose} Details={v} />
                            } */}
                        </div>
                    )}
                </>
            }
        </>
    );
};

export default Content;