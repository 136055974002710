const actions = {
    GET_VENDOR_ORDER_LIST:"GET_VENDOR_ORDER_LIST",
    SET_VENDOR_ORDER_LIST:"SET_VENDOR_ORDER_LIST",
    GET_VENDOR_ORDER_DETAILS : 'GET_VENDOR_ORDER_DETAILS',
    SET_VENDOR_ORDER_DETAILS : 'SET_VENDOR_ORDER_DETAILS',
    UPDATE_ORDER_STATUS :"UPDATE_ORDER_STATUS",
    SET_UPDATE_ORDER_STATUS : "SET_UPDATE_ORDER_STATUS",
    GET_VENDOR_ORDER_LIST_BY_STATUS: 'GET_VENDOR_ORDER_LIST_BY_STATUS',
    SET_VENDOR_ORDER_LIST_BY_STATUS: 'SET_VENDOR_ORDER_LIST_BY_STATUS',
    GET_VENDOR_LOGIN_SESSION_ORDERS: 'GET_VENDOR_LOGIN_SESSION_ORDERS', 
    SET_VENDOR_LOGIN_SESSION_ORDERS: 'SET_VENDOR_LOGIN_SESSION_ORDERS', 
    ACCEPT_ORDER: 'ACCEPT_ORDER',
    ACCEPT_ORDER_STATUS: 'ACCEPT_ORDER_STATUS',
    GET_SALES_COUNT_CHART_REPORT: 'GET_SALES_COUNT_CHART_REPORT',
    SET_SALES_COUNT_CHART_REPORT: 'SET_SALES_COUNT_CHART_REPORT',
    GET_VENDORS_PINCODE_LIST: 'GET_VENDORS_PINCODE_LIST',
    SET_VENDORS_PINCODE_LIST: 'SET_VENDORS_PINCODE_LIST',
}
export default actions;