import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';

function Breadcrumb() {

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb pl-0">
                <li className="breadcrumb-item"><Link to="/helpdeskVdashboard"><IoMdHome className='home'/> Home</Link></li>
                <li className="breadcrumb-item"><Link to="/helpdeskV-New-Cakes-List">Cakes List</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Cake Details</li>
            </ol>
        </nav>
    );
};

export default Breadcrumb;