import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import HamperActions from '../../../../redux/HampersOrder/actions';
import commonActions from '../../../../redux/Common/actions';
import Preloader from '../../../layouts/Preloader';
import Breadcrumb from "./Breadcrumb";
import ReactPagination from '../../../common/React-Pagination';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SortingOrders from '../../../common/SortingOrders';
import moment from 'moment';

export default function Ordertable() {

    const dispatch = useDispatch();
    const history = useHistory();

    //get logged in users details
    const { token } = useSelector((state) => state.authReducer);

    const { VendorHamperOrders } = useSelector(state => state.HamperOrderReducer);

    const { Filtered_Dates } = useSelector(state => state.commonReducer);

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [search, setsearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(10);
    const [startDate, setStartDate] = useState((Filtered_Dates?.Page === 'HO' && moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD')) ? moment(Filtered_Dates?.StartDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '');

    //field: OD or DD / type: A or D
    let [sorting, setSorting] = useState([
        { table: 0, field: "OD", type: "D" },
    ]);

    //dispatch apis
    useEffect(() => {
        dispatch({ type: HamperActions.GET_VENDOR_HAMPER_ORDERS_LIST, payload: token?.result?._id });
        // eslint-disable-next-line 
    }, [token]);

    //get search input value - above 5kg
    const handleSearchbar = (e) => {
        setsearch(e.target.value.toLowerCase());
        setCurrentPage(0);
    };

    //validate the list is empty or not - above 5kg
    useEffect(() => {
        if (VendorHamperOrders?.message === 'No Records Found') {
            setFilteredData([]);
        } else {
            let FilteredDataWithDate = [];
            if (Filtered_Dates?.Page === 'HO' && Filtered_Dates?.StartDate) {
                VendorHamperOrders.filter(val => {
                    if (moment(val.Created_On, 'DD-MM-YYYY hh:mm A').format('DD-MM-YYYY') === Filtered_Dates?.StartDate) {
                        return FilteredDataWithDate.push(val);
                    } else {
                        return null;
                    }
                });
            } else {
                FilteredDataWithDate = VendorHamperOrders;
            };
            setFilteredData(FilteredDataWithDate.filter(val => {
                if (search === '') {
                    return val;
                } else if ((val.Id && val.Id.toLowerCase().includes(search)) ||
                    (val.HampersName && val.HampersName.toLowerCase().includes(search)) ||
                    (val.UserName && val.UserName.toLowerCase().includes(search)) ||
                    (val.Status && val.Status.toLowerCase().includes(search)) ||
                    (val.DeliveryDate && val.DeliveryDate.toLowerCase().includes(search))
                ) {
                    return val;
                } else {
                    return null;
                }
            }))
        };
    }, [VendorHamperOrders, search, Filtered_Dates]);

    const indexOfFirstPost = (currentPage * postsPerPage) % filteredData?.length;
    const indexOfLastPost = indexOfFirstPost + postsPerPage;
    const currentdata = filteredData.slice(indexOfFirstPost, indexOfLastPost);

    // Change page
    const paginate = (event) => {
        setCurrentPage(event.selected);
    };

    const handleShowPerPage = (e) => {
        setPostPerPage(parseInt(e.target.value));
        setCurrentPage(0);
    };

    //view details page
    function ViewOrderDetails(order) {
        history.push('/vendor-hamper-order-details', { OrderId: order._id, page: 'list' });
    };

    const handleSelectedDate = (e) => {
        setStartDate(e.target.value);
        if (e.target.value) {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: moment(e.target.value, 'YYYY-MM-DD').format('DD-MM-YYYY'), Page: 'HO' } });
        } else {
            dispatch({ type: commonActions.SET_START_AND_END_DATE, payload: { StartDate: null, Page: null } });
        };
        setCurrentPage(0);
        let newArray = sorting;
        newArray[0].field = 'OD';
        newArray[0].type = 'D';
        setSorting(newArray);
        // if (e.target.value) {
        //     setFilteredData(VendorHamperOrders.filter(val => {
        //         if ((val.Created_On.slice(0, 10).split("-").reverse().join("-")) === e.target.value) {
        //             return val;
        //         } else {
        //             return null;
        //         }
        //     }));
        // } else {
        //     setFilteredData(VendorHamperOrders)
        // }
    };

    const handlePageRefresh = (e) => {
        e.preventDefault();
        dispatch({ type: HamperActions.GET_VENDOR_HAMPER_ORDERS_LIST, payload: token?.result?._id });
        setSorting([
            { table: 0, field: "OD", type: "D" }
        ]);
        setStartDate();
        setsearch('');
    };

    const handleSorting = (e, field, table) => {
        e.preventDefault();
        let type, filterData;
        (sorting[table]?.type === 'A') ? type = 'D' : type = 'A';
        let newArray = [...sorting];
        newArray[table].type = type;
        newArray[table].field = field;
        setSorting(newArray);
        filterData = filteredData;
        let result = SortingOrders({ type, field, filterData });
        setFilteredData(result);
    };

    //loading
    useEffect(() => {
        (VendorHamperOrders.length === 0) ? setFetchSuccess(false) : setFetchSuccess(true);
        // eslint-disable-next-line
    }, [VendorHamperOrders]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb handlePageRefresh={handlePageRefresh} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h6 className='text-uppercase font-weight-bold'>Hamper Orders List</h6>
                                    <span className="float-right add-banner-button col-sm-3 col-md-3 mb-2">
                                        <div id="data-table-4_filter" className="dataTables_filter">
                                            <input type='date' className="form-control mt-4" disabled={VendorHamperOrders?.message === 'No Records Found' ? true : false} maxLength={4} max={"9999-12-31"} aria-controls="data-table-4" onChange={handleSelectedDate} value={startDate || ''} />
                                        </div>
                                    </span>
                                </div>
                                <div className='header-break-line'> <hr /> </div>
                                <div className="ms-panel-body tabledata-background">
                                    <div className="table-responsive Scrollbar-Style">
                                        <div id="data-table-5_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="data-table-5_length">
                                                        <label>
                                                            Show
                                                            <select name="data-table-5_length" aria-controls="data-table-5" className="custom-select custom-select-sm form-control form-control-sm" onChange={handleShowPerPage}>
                                                                <option value="10">10</option>
                                                                <option value="25">25</option>
                                                                <option value="50">50</option>
                                                            </select> entries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6 mb-2">
                                                    <div id="data-table-4_filter" className="dataTables_filter">
                                                        <label className="font-weight-bold">Search :
                                                            <input
                                                                type='Search'
                                                                className="form-control form-control-sm"
                                                                placeholder="type here to search..."
                                                                onChange={handleSearchbar}
                                                                aria-controls="data-table-4"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <table className="table w-100 thead-primary dataTable no-footer table-hover">
                                                        <thead>
                                                            <tr role="row">
                                                                <th scope="col">Order ID</th>
                                                                <th scope="col">Hamper Name</th>
                                                                <th scope="col">Customer Name</th>
                                                                <th scope="col">Ordered Date <span className='cursor' onClick={(e) => handleSorting(e, 'OD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'OD' && sorting[0]?.type === 'D' ? 'sort-descending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Delivery Date <span className='cursor' onClick={(e) => handleSorting(e, 'DD', 0)}><BsArrowUp className={`sort-up-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'A' ? 'sort-ascending' : 'sort-false'}`} /><BsArrowDown className={`sort-down-arrow ${sorting[0]?.table === 0 && sorting[0]?.field === 'DD' && sorting[0]?.type === 'D' ? 'sort-ascending' : 'sort-false'}`} /></span></th>
                                                                <th scope="col">Status</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredData.length > 0 ?
                                                            <tbody>
                                                                {currentdata.map((order, i) =>
                                                                    <tr className={(order.Vendor_Response_Status === 'unseen' || order.Vendor_Response_Status === 'no response') ? 'odd cursor font-weight-bold' : 'odd cursor'} onClick={() => ViewOrderDetails(order)} key={i}>
                                                                        <th className='id-column' scope="row">{order.Id}</th>
                                                                        <td>{order.HampersName}</td>
                                                                        <td> {order.UserName}</td>
                                                                        <td> {order.Created_On}</td>
                                                                        <td>{order.DeliveryDate}</td>
                                                                        <td className='price-align3-row'><span className={(order.Status?.toUpperCase())?.replace(/ /g, '')}>{order.Status}</span></td>
                                                                    </tr>
                                                                )}
                                                            </tbody> :
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={6} className='font-weight-bold no-records-found'>No Records Found</td>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {filteredData.length > postsPerPage &&
                                        <ReactPagination
                                            paginate={paginate}
                                            pageCount={Math.ceil(filteredData?.length / postsPerPage)}
                                            currentPage={currentPage}
                                            currentdata={currentdata}
                                            totalPosts={filteredData.length}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};