import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/esm/react-router-dom";
import CustomizecakeActions from '../../../redux/Customize-Cake/actions';
import Breadcrumb from "./Breadcrumb";
import FlavourList from "../ArrayMapping/FlavourList";
import ImageSlider from "./ImageSlider";
import Preloader from '../../layouts/Preloader';
import { float2D } from "../../../utils/Validation";

export default function Content() {

    const dispatch = useDispatch();
    const history = useHistory();
    const state = useLocation();

    const Id = state?.state?.id;

    const [fetchSuccess, setFetchSuccess] = useState(false);

    //get order assign status 
    const { CustomizedCakeDeatils } = useSelector(state => state.CustomizeCakeReducer);

    useEffect(() => {
        dispatch({ type: CustomizecakeActions.GET_CUSTOMIZED_CAKE_DETAILS, payload: Id });
        // eslint-disable-next-line
    }, [Id]);

    //for close the details page
    const handleClose = () => {
        if (state?.state?.page === 'customizecake') {
            history.push("/Customize-cake-list");
        } else if (state?.state?.page === 'dashboard') {
            history.push("/dashboard");
        };
    };

    useEffect(() => {
        if (CustomizedCakeDeatils?.length === 0) {
            setFetchSuccess(false);
        } else {
            setFetchSuccess(true);
        };
    }, [CustomizedCakeDeatils]);

    return (
        <>
            {!fetchSuccess ?
                <Preloader fetchSuccess={fetchSuccess} /> :
                <div className="ms-content-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb page={state?.state?.page} />
                        </div>
                        {CustomizedCakeDeatils?.Images === undefined || CustomizedCakeDeatils?.Images.length === 0 ?
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header tabledata-background">
                                        <h6>Customised Cake Order Details</h6>
                                    </div>
                                </div>
                            </div> :
                            <div className="col-md-12">
                                <div className="ms-panel tabledata-background">
                                    <div className="ms-panel-header">
                                        <h6>Customised Cake Order Details</h6>
                                    </div>

                                    <div className="ms-panel-body order-image">
                                        <ImageSlider image={CustomizedCakeDeatils?.Images} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-xl-6 col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {(CustomizedCakeDeatils?.ReasonForCancel && (CustomizedCakeDeatils?.Status === 'Cancelled' || CustomizedCakeDeatils?.Status === 'Rejected')) &&
                                                <tr className='text-danger'>
                                                    <th scope="row">Reason for Cancel</th>
                                                    <td>{CustomizedCakeDeatils?.ReasonForCancel}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Cake ID</th>
                                                <td><b>{CustomizedCakeDeatils?.Id}</b></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cake Name</th>
                                                <td>{CustomizedCakeDeatils?.CakeName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Egg or Eggless</th>
                                                <td>{CustomizedCakeDeatils?.EggOrEggless}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Ordered Date</th>
                                                <td>{CustomizedCakeDeatils?.Created_On}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6  col-md-12">
                            <div className="ms-panel ms-panel-fh">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">DELIVERY INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Delivery Information</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryInformation}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Date</th>
                                                <td>{CustomizedCakeDeatils?.DeliveryDate}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Delivery Session</th>
                                                <td>{CustomizedCakeDeatils?.DeliverySession}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Status</th>
                                                <td><span className={(CustomizedCakeDeatils?.Status?.toUpperCase())?.replace(/ /g, '')}>{CustomizedCakeDeatils?.Status}</span></td>
                                            </tr>
                                            {CustomizedCakeDeatils?.Status_Updated_On !== undefined ?
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Status_Updated_On}</td>
                                                </tr> :
                                                <tr>
                                                    <th scope="row">Status Updated On</th>
                                                    <td>{CustomizedCakeDeatils?.Created_On}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CAKE INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            {CustomizedCakeDeatils?.Theme &&
                                                <tr>
                                                    <th scope="row">Theme Name</th>
                                                    <td>{CustomizedCakeDeatils?.Theme}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.Tier &&
                                                <tr>
                                                    <th scope="row">Tier</th>
                                                    <td>{CustomizedCakeDeatils?.Tier} Tier</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th scope="row">Flavour</th>
                                                <td><FlavourList FlavoursList={CustomizedCakeDeatils?.Flavour} /></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shape</th>
                                                <td>{CustomizedCakeDeatils?.Shape}</td>
                                            </tr>

                                            {CustomizedCakeDeatils?.MessageOnTheCake &&
                                                <tr>
                                                    <th scope="row">Message on the Cake</th>
                                                    <td>{CustomizedCakeDeatils?.MessageOnTheCake}</td>
                                                </tr>
                                            }
                                            {CustomizedCakeDeatils?.SpecialRequest &&
                                                <tr>
                                                    <th scope="row">Special Request</th>
                                                    <td>{CustomizedCakeDeatils?.SpecialRequest}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {(CustomizedCakeDeatils?.Status === "Price Approved" || CustomizedCakeDeatils?.Status === "Sent" || CustomizedCakeDeatils?.Status === "Ordered") &&
                            <>
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="section-title bold">VENDOR PRICE INFO</h4>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Product Count</th>
                                                        <td>{CustomizedCakeDeatils?.ItemCount || 1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Price per Kg</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Vendor_Price || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td>{CustomizedCakeDeatils?.Weight}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Discount</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Discount || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Delivery Charge</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.DeliveryCharge || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Extra Charges</th>
                                                        {CustomizedCakeDeatils?.ExtraCharges === '0' || CustomizedCakeDeatils?.ExtraCharges === undefined ?
                                                            <td>0.00</td> :
                                                            <td><span>{float2D(CustomizedCakeDeatils?.ExtraCharges || 0)}</span></td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td>
                                                            <span><b>{float2D(CustomizedCakeDeatils?.Vendor_Total || 0)}</b></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-md-12">
                                    <div className="ms-panel ms-panel-fh">
                                        <div className="ms-panel-body tabledata-background">
                                            <h4 className="section-title bold">PRICE INFO</h4>
                                            <table className="table ms-profile-information">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Product Count</th>
                                                        <td>{CustomizedCakeDeatils?.ItemCount || 1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Price per Kg</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Price || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Weight</th>
                                                        <td>{CustomizedCakeDeatils?.Weight}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Discount</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Discount || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Delivery Charge</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.DeliveryCharge || 0)}</span></td>
                                                    </tr>

                                                    <tr>
                                                        <th scope="row">Extra Charges</th>
                                                        {CustomizedCakeDeatils?.ExtraCharges === '0' || CustomizedCakeDeatils?.ExtraCharges === undefined ?
                                                            <td>0.00</td> :
                                                            <td><span>{float2D(CustomizedCakeDeatils?.ExtraCharges || 0)}</span></td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">CGST</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Gst || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">SGST</th>
                                                        <td><span>{float2D(CustomizedCakeDeatils?.Sgst || 0)}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Total</th>
                                                        <td>
                                                            <span><b>{float2D(parseFloat(CustomizedCakeDeatils?.Total || 0))}</b></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {/* {CustomizedCakeDeatils?.Status === 'Sent' || CustomizedCakeDeatils?.Status === 'Ordered' ||
                            CustomizedCakeDeatils?.Status === 'Price Approved' ?
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">Price Info</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Weight</th>
                                                    <td>{CustomizedCakeDeatils?.Weight}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Price Per Kg</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Vendor_Price)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Total</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Vendor_Total)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Final Price Per Kg</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Price)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Extra Charges</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.ExtraCharges)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">CGST</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Gst)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">SGST</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Sgst)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Discount</th>
                                                    <td>{float2D(CustomizedCakeDeatils?.Discount)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Total</th>
                                                    <td><b>{float2D(CustomizedCakeDeatils?.Total)}</b></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Invoice Sent On</th>
                                                    <td>{CustomizedCakeDeatils?.Invoice_Sent_On}</td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : <></>
                        } */}
                        <div className="col-xl-12 col-md-12">
                            <div className="ms-panel">
                                <div className="ms-panel-body tabledata-background">
                                    <h4 className="section-title bold">CUSTOMER INFO</h4>
                                    <table className="table ms-profile-information">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Customer ID</th>
                                                <td>{CustomizedCakeDeatils?.User_ID}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Name</th>
                                                <td>{CustomizedCakeDeatils?.UserName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Customer Mobile Number</th>
                                                <td>{CustomizedCakeDeatils?.UserPhoneNumber}</td>
                                            </tr>
                                            {CustomizedCakeDeatils?.DeliveryInformation.toLowerCase() !== 'pickup' &&
                                                <tr>
                                                    <th scope="row">Delivery Address</th>
                                                    <td><span className="long-line-break">{CustomizedCakeDeatils?.DeliveryAddress}</span></td>
                                                </tr>
                                            }
                                            {!CustomizedCakeDeatils?.VendorID &&
                                                <>
                                                    <button
                                                        className="btn btn-secondary float-start"
                                                        type="submit"
                                                        onClick={handleClose}
                                                    >
                                                        Close
                                                    </button>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {CustomizedCakeDeatils?.VendorID &&
                            <div className="col-xl-12 col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-body tabledata-background">
                                        <h4 className="section-title bold">VENDOR INFO</h4>
                                        <table className="table ms-profile-information">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Vendor ID</th>
                                                    <td>{CustomizedCakeDeatils?.Vendor_ID}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Name</th>
                                                    <td>{CustomizedCakeDeatils?.VendorName}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vendor Main Mobile Number</th>
                                                    <td>{CustomizedCakeDeatils?.VendorPhoneNumber1}</td>
                                                </tr>
                                                {CustomizedCakeDeatils?.VendorPhoneNumber2 &&
                                                    <tr>
                                                        <th scope="row">Vendor Alternative Mobile Number</th>
                                                        <td>{CustomizedCakeDeatils?.VendorPhoneNumber2}</td>
                                                    </tr>
                                                }
                                                {CustomizedCakeDeatils?.VendorAddress &&
                                                    <tr>
                                                        <th scope="row">Vendor Address</th>
                                                        <td><span className="long-line-break">{CustomizedCakeDeatils?.VendorAddress} </span></td>
                                                    </tr>
                                                }
                                                {CustomizedCakeDeatils?.GoogleLocation &&
                                                    <tr>
                                                        <th scope="row">Google Location</th>
                                                        <td>
                                                            <b>Latitude</b> : {CustomizedCakeDeatils?.GoogleLocation?.Latitude} <br />
                                                            <b>Longitude</b> : {CustomizedCakeDeatils?.GoogleLocation?.Longitude}
                                                        </td>
                                                    </tr>
                                                }
                                                <button
                                                    className="btn btn-secondary float-start"
                                                    type="submit"
                                                    onClick={handleClose}
                                                >
                                                    Close
                                                </button>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
};